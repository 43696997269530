/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/pipes/translate.pipe";
import * as i3 from "../../core/service/translate.service";
import * as i4 from "./status-counter.component";
import * as i5 from "../../../../luxtrust-cosi-api/api/session.service";
var styles_StatusCounterComponent = [i0.styles];
var RenderType_StatusCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusCounterComponent, data: {} });
export { RenderType_StatusCounterComponent as RenderType_StatusCounterComponent };
export function View_StatusCounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 36, "div", [["class", "status-counter-container"], ["id", "visiteDashboardStep5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["class", "status-counter status-counter-created"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterBy(_co.sessionStatusEnum.CREATED) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "status-counter-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-hourglass"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "status-counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(10, 0, null, null, 8, "a", [["class", "status-counter status-counter-completed"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterBy(_co.sessionStatusEnum.COMPLETED) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "status-counter-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "status-counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(19, 0, null, null, 8, "a", [["class", "status-counter status-counter-refused"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterBy(_co.sessionStatusEnum.DECLINED) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "status-counter-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 5, "div", [["class", "status-counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, ["", ""])), (_l()(), i1.ɵeld(25, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(26, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(28, 0, null, null, 8, "a", [["class", "status-counter status-counter-cancelled"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterBy(_co.sessionStatusEnum.CANCELED) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "div", [["class", "status-counter-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 0, "i", [["class", "fas fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 5, "div", [["class", "status-counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(33, null, ["", ""])), (_l()(), i1.ɵeld(34, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(35, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.statusCounter == null) ? null : _co.statusCounter.created) ? _co.statusCounter.created : "0"); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("DASHBOARD.STATUS_COUNTER.CREATED")); _ck(_v, 8, 0, currVal_1); var currVal_2 = (((_co.statusCounter == null) ? null : _co.statusCounter.completed) ? _co.statusCounter.completed : "0"); _ck(_v, 15, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("DASHBOARD.STATUS_COUNTER.COMPLETED")); _ck(_v, 17, 0, currVal_3); var currVal_4 = (((_co.statusCounter == null) ? null : _co.statusCounter.refused) ? _co.statusCounter.refused : "0"); _ck(_v, 24, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform("DASHBOARD.STATUS_COUNTER.REFUSED")); _ck(_v, 26, 0, currVal_5); var currVal_6 = (((_co.statusCounter == null) ? null : _co.statusCounter.canceled) ? _co.statusCounter.canceled : "0"); _ck(_v, 33, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 35, 0, i1.ɵnov(_v, 36).transform("DASHBOARD.STATUS_COUNTER.CANCELLED")); _ck(_v, 35, 0, currVal_7); }); }
export function View_StatusCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-counter", [], null, null, null, View_StatusCounterComponent_0, RenderType_StatusCounterComponent)), i1.ɵdid(1, 114688, null, 0, i4.StatusCounterComponent, [i5.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatusCounterComponentNgFactory = i1.ɵccf("app-status-counter", i4.StatusCounterComponent, View_StatusCounterComponent_Host_0, { reset: "reset" }, { filter: "filter" }, []);
export { StatusCounterComponentNgFactory as StatusCounterComponentNgFactory };
