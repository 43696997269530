import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {PatternService} from 'luxtrust-cosi-api/api/pattern.service';
import {StepPropertiesService} from 'luxtrust-cosi-api/api/stepProperties.service';
import {TagService} from 'luxtrust-cosi-api/api/tag.service';
import {CreatePatternPayload} from 'luxtrust-cosi-api/model/createPatternPayload';
import {PatternData} from 'luxtrust-cosi-api/model/patternData';
import {PatternFilter} from 'luxtrust-cosi-api/model/patternFilter';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepPropertyData} from 'luxtrust-cosi-api/model/stepPropertyData';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {IEService} from 'src/app/services/services/ie.service';
import {CloneUtils} from 'src/app/services/utils/clone.utils';
import {StepActions} from 'src/app/session/step/wizard/models/wizard.config';
import {ApiError} from '../../../../error/api-error.model';
import LanguageEnum = CreatePatternPayload.LanguageEnum;
import ChannelEnum = PatternFilter.ChannelEnum;
import {AlertService} from '../../../../services/services/alert-service';
import {NotificationTemplatesEditorComponent} from './notification-templates-editor/notification-templates-editor.component';
import {defaultNotificationRules, StepNotificationRules} from './step-notification-rules';

@Component({
  templateUrl: './wizard-step-notifications.component.html', styleUrls: ['./wizard-step-notifications.component.scss']
})
export class WizardStepNotificationsComponent implements OnInit, OnDestroy {

  public PatternFilterChannelEnum = ChannelEnum;
  session: SessionData;
  step: StepData;
  stepActions = StepActions;
  subscription: Subscription;
  patterns: PatternData[] = [];
  properties: StepPropertyData;
  isIE: boolean;
  notificationRules: StepNotificationRules[];
  patternRoleId: number;
  creatingPattern = false;
  savingRules = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly modal: NgbModal,
    private readonly patternService: PatternService,
    private readonly stepPropertiesService: StepPropertiesService,
    private readonly translateService: TranslateService,
    private readonly ieService: IEService,
    private readonly tagService: TagService
  ) {

  }

  ngOnInit() {
    this.isIE = this.ieService.checkIfIE();
    this.notificationRules = CloneUtils.cloneArray(defaultNotificationRules);
    this.subscription = this.route.parent.data.pipe().subscribe((data: Data) => {
      this.session = data['session'];
      this.step = data['step'];
      this.stepPropertiesService.getStepProperties(this.session.id, this.step.id).pipe(take(1)).subscribe((props: StepPropertyData) => {
        this.properties = props;
        this.fillNotificationRules();
      }, (error: ApiError) => this.alertService.errorApi(error));
      this.patternService.getAllNotificationPatternsStep(this.step.id).pipe(take(1)).subscribe(patterns => {
        this.patterns = patterns;
      }, (error: ApiError) => this.alertService.errorApi(error));
      this.tagService.getTagByAlias('PATTERN_ROLE').subscribe(tagData => {
        this.patternRoleId = tagData ? tagData.id : null;
      }, (error: ApiError) => this.alertService.errorApi(error));
    });
  }

  private fillNotificationRules() {
    if (this.properties && this.properties.preference) {
      this.notificationRules.forEach(rule => {
        const override = this.properties.preference.events.find(n => n.type === rule.value);
        if (override) {
          rule.isMail = override.mailForce ? true : (override.mailDisable ? false : undefined);
          rule.isSms = override.smsForce ? true : (override.smsDisable ? false : undefined);
          rule.isApp = override.appForce ? true : (override.appDisable ? false : undefined);
        }
      });
    }
  }

  hasPatternsBoundOn(event: number, channel: ChannelEnum): boolean {
    if (this.patterns) {
      return !!this.patterns.find(p => p.eventTypeId === event && p.channel === channel);
    }
    return false;
  }

  patternsBoundOn(event: number, channel: ChannelEnum): PatternData[] {
    return this.patterns.filter(p => p.eventTypeId === event && p.channel === channel);
  }

  createPattern(event: number, channel: ChannelEnum) {
    this.patternService.getBestSuitedNotificationPattern({
      channel, language: LanguageEnum[this.translateService.currentLang.toUpperCase()], eventTypeId: event
    }).toPromise().then(pattern => {
      this.creatingPattern = true;
      return this.patternService.createSpecificNotificationPattern({
        body: pattern && pattern.body || '',
        subject: pattern && pattern.subject || '',
        channel,
        eventTypeId: event,
        language: LanguageEnum[this.translateService.currentLang.toUpperCase()],
        stepId: this.step.id,
        tagId: 0
      }).toPromise().then(notifPattern => {
        this.patterns.push(notifPattern);
        this.editPattern(notifPattern.eventTypeId, notifPattern.channel);
      }).then(value => this.creatingPattern = false);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  editPattern(event: number, channel: ChannelEnum) {
    // const pattern: PatternData = this.patterns.find(p => p.eventTypeId === event && p.channel === channel);
    const patterns = this.patternsBoundOn(event, channel);
    const modalRef = this.modal.open(NotificationTemplatesEditorComponent, {
      size: 'lg', backdrop: false
    });
    modalRef.componentInstance.setData(this.step.id, patterns, event, channel, this.patternRoleId);
    modalRef.result.then((value) => {
      this.patternService.getAllNotificationPatternsStep(this.step.id).pipe(take(1))
        .subscribe(updatedPatterns => {
          this.patterns = updatedPatterns;
          // TODO FIX
          // const newPattern = updatedPatterns.find(p => p.id === pattern.id);
          // if (!newPattern.body && !newPattern.subject) {
          //   this.removePattern(event, channel);
          // }
        }, (error: ApiError) => this.alertService.errorApi(error));
    }, () => undefined);
  }

  removePattern(event: number, channel: ChannelEnum) {
    const pattern: PatternData = this.patterns.find(p => p.eventTypeId === event && p.channel === channel);
    this.patternService.deleteSpecificNotificationPattern(pattern.id).toPromise().then(() => {
      this.patterns.splice(this.patterns.findIndex(p => p.id === pattern.id), 1);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  saveRules() {
    this.properties = this.properties || {};
    this.properties.preference = this.properties.preference || {events: []};
    this.properties.preference.events = this.notificationRules.map(rule => ({
      type: rule.value,
      mailForce: rule.isMail === true,
      mailDisable: rule.isMail === false,
      smsForce: rule.isSms === true,
      smsDisable: rule.isSms === false,
      appForce: rule.isApp === true,
      appDisable: rule.isApp === false
    }));
    this.savingRules = true;
    this.stepPropertiesService.updateStepProperty(this.session.id, this.step.id, this.properties).pipe(take(1)).toPromise()
      .catch((error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.savingRules = false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleCollapse(stepNotificationRule: StepNotificationRules) {
    stepNotificationRule.isCollapsed = !stepNotificationRule.isCollapsed;
  }

  public isCollapsed(stepNotificationRule: StepNotificationRules) {
    return stepNotificationRule.isCollapsed || false;
  }

  updateMailValue(stepNotificationRule: StepNotificationRules, value: boolean) {
    stepNotificationRule.isMail = value;
    this.saveRules();
  }

  updateSmsValue(stepNotificationRule: StepNotificationRules, value: boolean) {
    stepNotificationRule.isSms = value;
    this.saveRules();
  }

  updateAppValue(stepNotificationRule: StepNotificationRules, value: boolean) {
    stepNotificationRule.isApp = value;
    this.saveRules();
  }

}
