import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input-day-number',
  templateUrl: './input-day-number.component.html',
  styleUrls: ['./input-day-number.component.scss']
})
export class InputDayNumberComponent implements OnInit {

  @Input() defaultValue: number;
  @Input() type: 'plus' | 'minus';
  @Input() min: number;
  @Input() max: number;
  @Input() metadataPage = false;
  @Input() notBefore;
  @Input() smallSizeInputDay = false;
  @Output() onChange: EventEmitter<Date> = new EventEmitter<Date>();
  constructor() {
  }

  ngOnInit() {
  }

  onInput(days) {
    if (days) {
      const newDate = new Date(0).setDate(new Date(0).getDate() + Number(days));
      this.onChange.emit(new Date(newDate));
    } else {
      this.onChange.emit(days);
    }
  }
}
