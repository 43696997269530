<div class="meta-card ml-0 row type-notification-content">
  <div class="text-center type-notification-title col-2">
    <div>{{ title | translate}}</div>
    <div class="switcher switcher-right size-label position-switcher custom-switcher">
      <input class="switcher-checkbox" id="actifCheckbox{{idSuffix()}}" type="checkbox" [checked]="!checkValueIs(false)"
        [disabled]="disabled" (click)="onSwitchClick()">
      <div class="switcher-toggle" [class.disabled]="disabled"></div>
    </div>
    <div>
      <label for="actifCheckbox{{idSuffix()}}" class="bottom-label">
        {{(checkValueIs(false) ? 'WIZARD.NOTIFICATIONS.DISABLED' : 'WIZARD.NOTIFICATIONS.ENABLED') | translate}}
      </label>
    </div>
  </div>
  <div class="type-notification-options row col-10" [ngClass]="{'ie_browser':isIE, 'disabled': checkValueIs(false)}">
    <div class="col-7">
      <form novalidate>
        <div class="row ml-4">
          <div class="col-6">
            <input id="notForced{{idSuffix()}}" type="radio" class="custom-control-input"
              (click)="onOptionUpdate(undefined)" [checked]="checkValueIs(undefined)" [disabled]="checkValueIs(false) || disabled">
            <label class="custom-control-label" for="notForced{{idSuffix()}}"
              [ngClass]="{'selected': checkValueIs(undefined)}">
              {{'WIZARD.NOTIFICATIONS.DO_NOT_FORCE' | translate}}
            </label>
          </div>
          <div class="col-6">
            <input id="forced{{idSuffix()}}" type="radio" class="custom-control-input" (click)="onOptionUpdate(true)"
              [checked]="checkValueIs(true)" [disabled]="checkValueIs(false) || disabled">
            <label class="custom-control-label" for="forced{{idSuffix()}}" [ngClass]="{'selected': checkValueIs(true)}">
              {{'WIZARD.NOTIFICATIONS.FORCE' | translate}}
            </label>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="hasPatternsBound; else createEmailPattern" class="col-5">
      <button (click)="editPattern()" class="btn btn-primary btn-edit" [disabled]="checkValueIs(false) || disabled">
        <i class="fa fa-edit icon"></i>
        <span class="btn-label">{{'WIZARD.NOTIFICATIONS.EDIT_PATTERN' | translate}}</span>
      </button>
    </div>
    <ng-template #createEmailPattern>
      <div class="col-5">
        <button (click)="createPattern()" class="btn btn-primary" [disabled]="checkValueIs(false) || disabled">
          <i class="fa fa-plus icon"></i>
          <span class="btn-label">{{'WIZARD.NOTIFICATIONS.CREATE_PATTERN' | translate}}</span>
        </button>
      </div>
    </ng-template>
  </div>
</div>
