import {registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatSelectModule} from '@angular/material';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  NgbCollapseModule,
  NgbModalModule,
  NgbModule,
  NgbPaginationConfig,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ApiModule} from 'luxtrust-cosi-api/api.module';
import {BASE_PATH} from 'luxtrust-cosi-api/variables';
import {QuillModule} from 'ngx-quill';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './dashboard/dashboard/dashboard.component';
import {ModalTemplateSessionComponent} from './dashboard/modal-template-session/modal-template-session.component';
import {StatusCounterComponent} from './dashboard/status-counter/status-counter.component';
import {NoTenantNameComponent} from './error/no-tenant-name/no-tenant-name.component';
import {InviteComponent} from './login/invite/invite.component';
import {LoginComponent} from './login/login/login.component';
import {LogoutComponent} from './login/logout/logout.component';
import {ModalOrelyLinkComponent} from './login/modal-orely-link/modal-orely-link.component';
import {ResetPasswordComponent} from './login/reset-password/reset-password.component';
import {SignupComponent} from './login/signup/signup.component';
import {NewSessionComponent} from './new-session/new-session.component';
import {initSentry, SentryErrorHandler} from "./sentry-error-handler";
import {BlobErrorHttpInterceptor} from './services/interceptor/blob-http-request.interceptor';
import {HttpRequestInterceptor} from './services/interceptor/http-request.interceptor';
import {ServiceModule} from './services/service.module';
import {ModalConfiguratorComponent} from './session/step/components/wizard-step-configurator/modal-configurator/modal-configurator.component';
import {StepModule} from './session/step/step.module';
import {WizardService} from './session/step/wizard/services/wizard.service';
import {SharedModule} from './shared/shared.module';
import {SignatureBookModule} from './signature-book/signature-book.module';
import {ValidationComponent} from './core/components/validation/validation.component';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeFr, 'FR');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeNl, 'NL');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}


@NgModule({
  declarations: [AppComponent,
    NewSessionComponent,
    NoTenantNameComponent,
    DashboardComponent,
    ModalTemplateSessionComponent,
    ModalConfiguratorComponent,
    ResetPasswordComponent,
    LoginComponent,
    LogoutComponent,
    SignupComponent,
    InviteComponent,
    ModalOrelyLinkComponent,
    StatusCounterComponent,
    ValidationComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]
      }
    }),
    NgbModule,
    AppRoutingModule,
    SignatureBookModule,
    SharedModule,
    ApiModule,
    ServiceModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbModalModule,
    NgbCollapseModule,
    NgxSpinnerModule, StepModule],
  providers: [NgbPaginationConfig,
    WizardService,
    {provide: BASE_PATH, useValue: environment.BASE_URL},
    {provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {
      provide: APP_INITIALIZER,
      useFactory: initSentry,
      deps: [HttpClient, BASE_PATH],
      multi: true
    },
    QuillModule],
  bootstrap: [AppComponent],
  entryComponents: [ModalTemplateSessionComponent, ModalOrelyLinkComponent, ModalConfiguratorComponent]
})
export class AppModule {
}
