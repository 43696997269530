/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EnduserData } from './enduserData';


/**
 * Signature workflow
 */
export interface WorkflowData { 
    /**
     * Whether user should be brought to step instead of signature
     */
    bringToStep?: boolean;
    /**
     * Document
     */
    documentId?: number;
    /**
     * Enduser object
     */
    enduser?: EnduserData;
    /**
     * Enduser
     */
    enduserId?: string;
    /**
     * Forbid this type
     */
    forbidDownload?: boolean;
    /**
     * Forbid this type
     */
    forbidPrint?: boolean;
    /**
     * Forbid this type
     */
    forbidView?: boolean;
    /**
     * If the signer is a circle the this property indicates in the requester is within the circle
     */
    meInCircle?: boolean;
    /**
     * Signature document id
     */
    signatureDocumentId?: number;
    /**
     * Signature
     */
    signatureId?: number;
    /**
     * Signature status
     */
    signatureStatus?: WorkflowData.SignatureStatusEnum;
    /**
     * Signed on
     */
    signedOn?: Date;
    /**
     * Parent signature
     */
    sourceSignatureId?: number;
    /**
     * Signature profile code
     */
    sprofileCode?: string;
    /**
     * Step
     */
    stepId?: number;
}
export namespace WorkflowData {
    export type SignatureStatusEnum = 'STARTED' | 'DECLINED' | 'SIGNED';
    export const SignatureStatusEnum = {
        STARTED: 'STARTED' as SignatureStatusEnum,
        DECLINED: 'DECLINED' as SignatureStatusEnum,
        SIGNED: 'SIGNED' as SignatureStatusEnum
    };
}
