import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from 'src/app/core/service/translate.service';
import { ItsmeAuthenticationService } from 'luxtrust-cosi-api/api/itsmeAuthentication.service';
import { AuthenticationResponse } from 'luxtrust-cosi-api/model/authenticationResponse';
import { take } from 'rxjs/operators';
import { AlertService } from '../../../../services/services/alert-service';
import { AppService } from '../../../../services/services/app.service';
import { ItsmeAuthServiceType, ItsmeService } from '../../../../services/services/itsme.service';
import { ItsmeAuthComponent } from '../itsme-auth.component';

@Component({
  templateUrl: './itsme-auth-login.component.html', styleUrls: ['./itsme-auth-login.component.scss']
})
export class ItsmeAuthLoginComponent extends ItsmeAuthComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected appService: AppService,
              protected alertService: AlertService,
              protected translate: TranslateService,
              protected itsmeService: ItsmeService,
              protected itsmeAuthenticationService: ItsmeAuthenticationService) {
    super(ItsmeAuthServiceType.LOGIN, route, router, appService, alertService, translate, itsmeService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected takeAction(redirectUri: string, itsmeCode: string) {
    this.itsmeAuthenticationService.itsmeLogin(itsmeCode, redirectUri)
      .pipe(take(1))
      .subscribe((response: AuthenticationResponse) => this.success('ALERT.SIGN_IN_SUCCESS', '/dashboard', response.accessToken),
        _ => this.failure('ALERT.SIGN_IN_FAILED', '/login', true));
  }

}
