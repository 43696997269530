/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PwcSessionData { 
    /**
     * Document identifier
     */
    documentId?: number;
    /**
     * Document name
     */
    documentName?: string;
    /**
     * Session
     */
    id?: number;
    /**
     * Session label (in the enduser language)
     */
    label?: string;
    manager?: boolean;
    preparerFirstName?: string;
    preparerLastName?: string;
    /**
     * True if the requester is the manager of the session
     */
    requesterManager?: boolean;
    /**
     * Fix me
     */
    signatureId?: number;
    /**
     * Signature status
     */
    signatureStatus?: PwcSessionData.SignatureStatusEnum;
    /**
     * Signed on
     */
    signedOn?: Date;
    signer?: boolean;
    signerFirstName?: string;
    signerLastName?: string;
    /**
     * Session status
     */
    status?: PwcSessionData.StatusEnum;
}
export namespace PwcSessionData {
    export type SignatureStatusEnum = 'STARTED' | 'DECLINED' | 'SIGNED';
    export const SignatureStatusEnum = {
        STARTED: 'STARTED' as SignatureStatusEnum,
        DECLINED: 'DECLINED' as SignatureStatusEnum,
        SIGNED: 'SIGNED' as SignatureStatusEnum
    };
    export type StatusEnum = 'CREATED' | 'STARTED' | 'COMPLETED' | 'DISABLED' | 'DECLINED' | 'CANCELED' | 'SUSPENDED';
    export const StatusEnum = {
        CREATED: 'CREATED' as StatusEnum,
        STARTED: 'STARTED' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        DISABLED: 'DISABLED' as StatusEnum,
        DECLINED: 'DECLINED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        SUSPENDED: 'SUSPENDED' as StatusEnum
    };
}
