import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/services/services/app.service';

@Component({
  selector: 'app-app-download-pdf',
  templateUrl: './app-download-pdf.component.html',
  styleUrls: ['./app-download-pdf.component.scss']
})
export class AppDownloadPdfComponent implements OnInit {
  signer;
  designer;
  watcher;
  admin;
  canUploadSignerPdf = false;
  canUploadWatcherPdf = false;
  canUploadDesignerPdf = false;
  canUploadADMINPdf = false;
  @Input() rightOfUser = [];
  constructor(private readonly appService: AppService) { }

  ngOnInit() {
    this.appService.getUser$().subscribe((params) => {
      if (params && params.entitlementNames) {
        this.signer = params.entitlementNames.find(right => right === 'SIGNER');
        this.designer = params.entitlementNames.find(right => right === 'DESIGNER');
        this.watcher = params.entitlementNames.find(right => right === 'WATCHER');
        this.admin = params.entitlementNames.find(right => right === 'ADMIN');
        this.checkRight();
      }
    });
  }
  checkRight() {
    if (this.admin) {
      this.canUploadADMINPdf = true;
    } else {
      if (this.designer) {
        this.canUploadDesignerPdf = true;
      } else {
        if (this.signer) {
          this.canUploadSignerPdf = true;
        } else {
          if (this.watcher) {
            this.canUploadWatcherPdf = true;
          }
        }
      }
    }
  }

  downloadPdf() {
    if (this.canUploadSignerPdf) {
      window.open('../../assets/pdf/DOCUMENT_SIGNATAIRE.pdf', '_blank');
    }
    if (this.canUploadDesignerPdf) {
      window.open('../../assets/pdf/DOCUMENT_DESIGNER.pdf', '_blank');
    }
    if (this.canUploadWatcherPdf) {
      window.open('../../assets/pdf/DOCUMENT_WATCHER.pdf', '_blank');
    }
    if (this.canUploadADMINPdf) {
      window.open('../../assets/pdf/DOCUMENT_ADMIN.pdf', '_blank');
    }
  }

}
