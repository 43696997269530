/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legal-motion-check-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../core/pipes/translate.pipe";
import * as i5 from "../../../core/service/translate.service";
import * as i6 from "./legal-motion-check-list.component";
import * as i7 from "../../../../../luxtrust-cosi-api/api/stepEnduser.service";
import * as i8 from "../../../../../luxtrust-cosi-api/api/stepLegalEnduser.service";
import * as i9 from "../../../services/services/alert-service";
var styles_LegalMotionCheckListComponent = [i0.styles];
var RenderType_LegalMotionCheckListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegalMotionCheckListComponent, data: {} });
export { RenderType_LegalMotionCheckListComponent as RenderType_LegalMotionCheckListComponent };
function View_LegalMotionCheckListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "switcher-toggle"]], null, null, null, null, null))], null, null); }
function View_LegalMotionCheckListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "switcher switcher-left ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "input", [["class", "switcher-checkbox"], ["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.check(_v.parent.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = ((_v.parent.context.$implicit.checked = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_5)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = i1.ɵinlineInterpolate(1, "", ("motion-m" + _v.parent.context.index), ""); var currVal_11 = _v.parent.context.$implicit.checked; _ck(_v, 6, 0, currVal_10, currVal_11); var currVal_12 = !_co.alreadySigned; _ck(_v, 10, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ("motion-m" + _v.parent.context.index), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.stepLegal.legal.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", ("motion-m" + _v.parent.context.index), ""); var currVal_3 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 8).ngClassValid; var currVal_8 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_LegalMotionCheckListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "switcher ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ("motion-m" + _v.parent.context.index), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.stepLegal.legal.title; _ck(_v, 2, 0, currVal_1); }); }
function View_LegalMotionCheckListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["class", "ml-0 motionList-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "form-group form-check pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_6)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "form-group form-check pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["class", "w-100 break-words"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSigner && !_co.alreadySigned); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.isSigner || (_co.isSigner && _co.alreadySigned)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", ("motion-m" + _v.context.index), ""); _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.stepLegal.legal.text; _ck(_v, 8, 0, currVal_3); }); }
function View_LegalMotionCheckListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "title-legal-notices"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [["class", "motionList"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_3)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mandatoryLegalMotion; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("LEGAL_MOTIONS.MANDATORY")); _ck(_v, 2, 0, currVal_0); }); }
function View_LegalMotionCheckListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "switcher-toggle"]], null, null, null, null, null))], null, null); }
function View_LegalMotionCheckListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "switcher switcher-left ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "input", [["class", "switcher-checkbox"], ["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.check(_v.parent.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = ((_v.parent.context.$implicit.checked = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_10)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = i1.ɵinlineInterpolate(1, "", ("motion-o" + _v.parent.context.index), ""); var currVal_11 = _v.parent.context.$implicit.checked; _ck(_v, 6, 0, currVal_10, currVal_11); var currVal_12 = !_co.alreadySigned; _ck(_v, 10, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ("motion-o" + _v.parent.context.index), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.stepLegal.legal.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", ("motion-o" + _v.parent.context.index), ""); var currVal_3 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 8).ngClassValid; var currVal_8 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_LegalMotionCheckListComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "switcher ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ("motion-o" + _v.parent.context.index), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.stepLegal.legal.title; _ck(_v, 2, 0, currVal_1); }); }
function View_LegalMotionCheckListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["class", "ml-0 motionList-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "form-group form-check pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_9)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_11)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "form-group form-check pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["class", "w-100 break-words"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSigner && !_co.alreadySigned); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.isSigner || (_co.isSigner && _co.alreadySigned)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", ("motion-m" + _v.context.index), ""); _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.stepLegal.legal.text; _ck(_v, 8, 0, currVal_3); }); }
function View_LegalMotionCheckListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "title-legal-notices"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [["class", "motionList"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_8)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.optionalLegalMotion; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("LEGAL_MOTIONS.OPTIONAL")); _ck(_v, 2, 0, currVal_0); }); }
function View_LegalMotionCheckListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_7)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mandatoryLegalMotion.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.optionalLegalMotion.length > 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LegalMotionCheckListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalMotionCheckListComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.legalMotions && (((_co.legalMotions == null) ? null : _co.legalMotions.length) !== 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LegalMotionCheckListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-legal-motion-check-list", [], null, null, null, View_LegalMotionCheckListComponent_0, RenderType_LegalMotionCheckListComponent)), i1.ɵdid(1, 573440, null, 0, i6.LegalMotionCheckListComponent, [i7.StepEnduserService, i8.StepLegalEnduserService, i9.AlertService], null, null)], null, null); }
var LegalMotionCheckListComponentNgFactory = i1.ɵccf("app-legal-motion-check-list", i6.LegalMotionCheckListComponent, View_LegalMotionCheckListComponent_Host_0, { sessionId: "sessionId", stepId: "stepId", legalMotions: "legalMotions", isSigner: "isSigner", alertWhenFilled: "alertWhenFilled", alreadySigned: "alreadySigned" }, { checked: "mandatoriesChecked" }, []);
export { LegalMotionCheckListComponentNgFactory as LegalMotionCheckListComponentNgFactory };
