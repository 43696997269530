/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TimestampRequestDto { 
    digest: string;
    digestAlgorithm: TimestampRequestDto.DigestAlgorithmEnum;
}
export namespace TimestampRequestDto {
    export type DigestAlgorithmEnum = 'SHA256' | 'SHA512';
    export const DigestAlgorithmEnum = {
        SHA256: 'SHA256' as DigestAlgorithmEnum,
        SHA512: 'SHA512' as DigestAlgorithmEnum
    };
}
