import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-edit',
  templateUrl: './modal-edit.component.html',
  styleUrls: ['./modal-edit.component.scss']
})
export class ModalEditComponent implements OnInit {

  @Input() title: string;
  @Input() content: string;
  @Input() close: string;
  @Input() action: string;

  @Output() onAction: EventEmitter<any> = new EventEmitter();

  comment: FormControl = new FormControl('');

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.comment.setValue(this.content);
  }
  handleAction() {
    const comment = this.comment.value.trim();
    if (comment) {
      this.onAction.emit(comment);
      this.activeModal.close();
    }
  }
}
