<app-tooltip [tooltipText]="'HELP.ORDER_CERTIFICATE' | translate"></app-tooltip>

<div class="fixed-container">
  <div class="center w6">
    <h1 class="page-title user-title">{{'ORDER.TITLE' | translate}}</h1>

    <form (ngSubmit)="order()" [formGroup]="orderForm">

      <div class="form-container">
        <!-- Holder information -->
        <div class="row fixed-md">

          <h5 class="card-title col-12"> {{'ORDER.HOLDER_INFORMATION.TITLE' | translate}} </h5>

          <div class="col-12" formGroupName="holder">
            <div class="form-row">
              <app-input-text [errors]="{
                                required: 'ORDER.HOLDER_INFORMATION.FIRST_NAME.REQUIRED' | translate
                              }"
                              [idPostfix]="'firstName'"
                              [label]="'ORDER.HOLDER_INFORMATION.FIRST_NAME.LABEL' | translate"
                              [ngClass]="{ 'is-invalid': submitted && holder.controls['firstName'].errors }"
                              [placeholder]="'ORDER.HOLDER_INFORMATION.FIRST_NAME.PLACEHOLDER' | translate"
                              autoFocus
                              class="col"
                              formControlName="firstName"
                              icon="user"
                              inputTextPosition="right"
                              type="text"></app-input-text>
            </div>
            <div class="form-row">
              <app-input-text [errors]="{
                                required: 'ORDER.HOLDER_INFORMATION.LAST_NAME.REQUIRED' | translate
                              }"
                              [idPostfix]="'surName'"
                              [label]="'ORDER.HOLDER_INFORMATION.LAST_NAME.LABEL' | translate"
                              [ngClass]="{ 'is-invalid': submitted && holder.controls['surName'].errors }"
                              [placeholder]="'ORDER.HOLDER_INFORMATION.LAST_NAME.PLACEHOLDER' | translate"
                              autoFocus
                              class="col"
                              formControlName="surName"
                              icon="user"
                              inputTextPosition="right"
                              type="text"></app-input-text>
            </div>
            <div class="form-row">
              <app-input-text [errors]="{
                                required: 'ORDER.HOLDER_INFORMATION.NOTIFY_EMAIL.REQUIRED' | translate
                              }"
                              [idPostfix]="'notifyEmail'"
                              [label]="'ORDER.HOLDER_INFORMATION.NOTIFY_EMAIL.LABEL' | translate"
                              [ngClass]="{ 'is-invalid': submitted && holder.controls['notifyEmail'].errors }"
                              [placeholder]="'ORDER.HOLDER_INFORMATION.NOTIFY_EMAIL.PLACEHOLDER' | translate"
                              autoFocus
                              class="col"
                              formControlName="notifyEmail"
                              icon="envelope"
                              inputTextPosition="right"
                              type="text"></app-input-text>
            </div>
            <div class="form-row">
              <app-input-text [errors]="{
                                required: 'ORDER.HOLDER_INFORMATION.PHONE_NUMBER.REQUIRED' | translate
                              }"
                              [idPostfix]="'phoneNumber'"
                              [label]="'ORDER.HOLDER_INFORMATION.PHONE_NUMBER.LABEL' | translate"
                              [ngClass]="{ 'is-invalid': submitted && holder.controls['phoneNumber'].errors }"
                              [placeholder]="'ORDER.HOLDER_INFORMATION.PHONE_NUMBER.PLACEHOLDER' | translate"
                              autoFocus
                              class="col"
                              formControlName="phoneNumber"
                              icon="phone"
                              inputTextPosition="right"
                              type="text"></app-input-text>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 mb-4">
        <button class="btn btn-primary" type="submit" [disabled]="submitting"> {{'ORDER.ORDER_CERTIFICATE' | translate}}</button>
      </div>

    </form>

  </div>
</div>
