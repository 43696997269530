import { Pipe, PipeTransform } from '@angular/core';
import findIndex from 'lodash/findIndex';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(filterList: any[], ...args: any[]): any {

    const allIndex = findIndex(filterList, filter => filter.key === 'All' || filter.key === 'Tout');

    if (!args[0] || allIndex === -1) {
      return filterList;
    }
    const all = filterList[allIndex];


    filterList = [all, ...filterList];
    filterList.splice(allIndex + 1, 1);
    return filterList;
  }
}
