import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from '../services/app.service';

@Injectable({providedIn: 'root'})
export class NotAuthGuard implements CanActivate {

  constructor(private router: Router, private appService: AppService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.appService.get()) {
      const tenantNameQueryParam = route.queryParamMap.get('tenantName');
      const tenantNameParam = route.paramMap.get('tenantName');
      if (tenantNameQueryParam || tenantNameParam) {
        return true;
      } else {
        this.router.navigate(['/no-tenant-name']);
        return false;
      }
    }

    this.router.navigate(['/']);
    return false;
  }
}
