import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from 'src/app/core/service/translate.service';
import { AuthenticationService } from 'luxtrust-cosi-api/api/authentication.service';
import { AlertService } from '../../services/services/alert-service';
import { AppService } from '../../services/services/app.service';
import { VisualIdentityService } from '../../services/services/visual-identity.service';
import { ValidatorHelper } from '../../services/validators/validators.helper';
import {REGEXES} from "../../app.constant";
import {ApiError} from '../../error/api-error.model';

@Component({
  templateUrl: './reset-password.component.html', styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  submitted = false;
  customLogo: string;
  errPassword: boolean;
  tenantName: string;
  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private authService: AuthenticationService,
              private appService: AppService,
              private translate: TranslateService,
              private alertService: AlertService,
              private router: Router,
              private visualIdentityService: VisualIdentityService) {
  }

  ngOnInit() {
    this.customLogo = this.visualIdentityService.logo;
    this.createForm();
    this.tenantName = this.route.snapshot.queryParams['tenantName'];
    const jwt = this.route.snapshot.queryParams['jwt'];
    if (jwt) {
      this.appService.refresh(jwt);
    }
  }

  createForm() {
    this.resetForm = this.formBuilder.group({
      password: ['',
        [Validators.required,
        Validators.pattern(REGEXES.PASSWORD.NUMERIC_CHARS),
        Validators.pattern(REGEXES.PASSWORD.SPECIAL_CHARS),
        Validators.pattern(REGEXES.PASSWORD.ALPHA_CASED_CHARS),
        Validators.minLength(6)]], confirmPassword: ['', [Validators.required, Validators.minLength(6)
      ]]
    }, {
      validator: ValidatorHelper.matchPassword
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      this.errPassword = true;
      return;
    }
    this.authService.resetPassword({ newPassword: this.resetForm.get('password').value }).subscribe(() => {
      this.alertService.success('ALERT.PASSWORD_RESET');
      this.appService.revoke().then(() => {
        this.router.navigateByUrl(`/login?tenantName=${this.tenantName}`);
        this.appService.logout();
      });
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.errPassword = true;
      setTimeout(() => {
        this.errPassword = false;
      }, 10000);
    });
  }

  // Needed to display error immediately if any
  markAsTouched(controlName: string) {
    const control = this.resetForm.get(controlName);
    control.markAsTouched();
  }
}
