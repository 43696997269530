<div class="legal-motion-card-container">
  <div class="col">
    <div class="row align-items-center justify-content-center">
      <div class="btn-group btn-spacing" role="group">
        <button (click)="handleOptional(stepLegalMotion)"
                [ngClass]="!stepLegalMotion.mandatory ? 'btn-secondary' : 'btn-outline-secondary'"
                [disabled]="disabled"
                class="btn btn-left"
                type="button">{{"WIZARD.LEGAL.OPTIONAL" | translate}}</button>
        <button (click)="handleMandatory(stepLegalMotion)"
                [ngClass]="stepLegalMotion.mandatory ? 'btn-secondary' : 'btn-outline-secondary'"
                [disabled]="disabled"
                class="btn btn-right"
                type="button">{{"WIZARD.LEGAL.MANDATORY" | translate}}</button>
        <button (click)="handleDeleteLegalMotion(stepLegalMotion)" [disabled]="disabled" class="btn-delete icon-button">
          <i aria-hidden="true" class="fa fa-trash-alt" [class.disabled]="disabled"></i>
        </button>
      </div>
    </div>
    <p class="mt-2" style="word-wrap: break-word;">{{stepLegalMotion.legal.text}}</p>
  </div>
</div>
