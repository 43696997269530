/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Notification (email, sms...) status information
 */
export interface NotificationData { 
    /**
     * Mail identifier
     */
    body?: string;
    /**
     * Mail cc
     */
    cc?: string;
    /**
     * JSON context
     */
    context?: string;
    /**
     * Notification creation date
     */
    createdOn?: Date;
    /**
     * Targeted user
     */
    enduserId?: string;
    /**
     * Mail type
     */
    eventChannel?: NotificationData.EventChannelEnum;
    /**
     * Event type id
     */
    eventType?: NotificationData.EventTypeEnum;
    /**
     * Mail identifier
     */
    id?: number;
    /**
     * Notification successful sending date
     */
    sentOn?: Date;
    /**
     * Notification start sending on
     */
    startSendingOn?: Date;
    /**
     * Sending status
     */
    status?: NotificationData.StatusEnum;
    /**
     * Mail subject
     */
    subject?: string;
    /**
     * Mail recipients
     */
    to?: string;
    /**
     * Send try count
     */
    tryCount?: number;
}
export namespace NotificationData {
    export type EventChannelEnum = 'EMAIL' | 'SMS' | 'APP' | 'OTHER';
    export const EventChannelEnum = {
        EMAIL: 'EMAIL' as EventChannelEnum,
        SMS: 'SMS' as EventChannelEnum,
        APP: 'APP' as EventChannelEnum,
        OTHER: 'OTHER' as EventChannelEnum
    };
    export type EventTypeEnum = 'AUDITTRAIL_CREATION' | 'AUDITTRAIL_COMMENTS_CREATION' | 'DOCUMENT_DECLINED' | 'DOCUMENT_REINSTATE' | 'EMAIL_TO_SEND' | 'ENROLMENT_REQUEST' | 'RESET_PASSWORD' | 'SESSION_CANCELED' | 'SESSION_COMPLETED' | 'SESSION_CREATED' | 'SESSION_DISABLED' | 'SESSION_RESUMED' | 'SESSION_STARTED' | 'SESSION_SUSPENDED' | 'SIGN_REMINDER' | 'SIGNATURE_SIGNED' | 'SIGNUP_SUCCESSFUL' | 'SMS_TO_SEND' | 'STEP_CANCELED' | 'STEP_COMMENT_ADDED' | 'STEP_COMMENT_TARGETED_ADDED' | 'STEP_COMPLETED' | 'STEP_CREATED' | 'STEP_DECLINED' | 'STEP_DELEGATION_FINISHED' | 'STEP_DELEGATION_STARTED' | 'STEP_DOCUMENT_ENDUSER_SEEN' | 'STEP_LEGAL_RESPONSE' | 'STEP_RESUMED' | 'STEP_STARTED' | 'STEP_SUSPENDED' | 'STEP_CANNOT_START' | 'STEP_ENDUSER_REPLACED' | 'VALIDATION_CODE' | 'AUTH_CODE' | 'ADMIN_USAGE' | 'CUSTOM_1000' | 'CUSTOM_1001' | 'CUSTOM_1002' | 'CUSTOM_1003' | 'CUSTOM_1004' | 'CUSTOM_1005' | 'CUSTOM_1006' | 'CUSTOM_1007' | 'CUSTOM_1008' | 'CUSTOM_1009';
    export const EventTypeEnum = {
        AUDITTRAILCREATION: 'AUDITTRAIL_CREATION' as EventTypeEnum,
        AUDITTRAILCOMMENTSCREATION: 'AUDITTRAIL_COMMENTS_CREATION' as EventTypeEnum,
        DOCUMENTDECLINED: 'DOCUMENT_DECLINED' as EventTypeEnum,
        DOCUMENTREINSTATE: 'DOCUMENT_REINSTATE' as EventTypeEnum,
        EMAILTOSEND: 'EMAIL_TO_SEND' as EventTypeEnum,
        ENROLMENTREQUEST: 'ENROLMENT_REQUEST' as EventTypeEnum,
        RESETPASSWORD: 'RESET_PASSWORD' as EventTypeEnum,
        SESSIONCANCELED: 'SESSION_CANCELED' as EventTypeEnum,
        SESSIONCOMPLETED: 'SESSION_COMPLETED' as EventTypeEnum,
        SESSIONCREATED: 'SESSION_CREATED' as EventTypeEnum,
        SESSIONDISABLED: 'SESSION_DISABLED' as EventTypeEnum,
        SESSIONRESUMED: 'SESSION_RESUMED' as EventTypeEnum,
        SESSIONSTARTED: 'SESSION_STARTED' as EventTypeEnum,
        SESSIONSUSPENDED: 'SESSION_SUSPENDED' as EventTypeEnum,
        SIGNREMINDER: 'SIGN_REMINDER' as EventTypeEnum,
        SIGNATURESIGNED: 'SIGNATURE_SIGNED' as EventTypeEnum,
        SIGNUPSUCCESSFUL: 'SIGNUP_SUCCESSFUL' as EventTypeEnum,
        SMSTOSEND: 'SMS_TO_SEND' as EventTypeEnum,
        STEPCANCELED: 'STEP_CANCELED' as EventTypeEnum,
        STEPCOMMENTADDED: 'STEP_COMMENT_ADDED' as EventTypeEnum,
        STEPCOMMENTTARGETEDADDED: 'STEP_COMMENT_TARGETED_ADDED' as EventTypeEnum,
        STEPCOMPLETED: 'STEP_COMPLETED' as EventTypeEnum,
        STEPCREATED: 'STEP_CREATED' as EventTypeEnum,
        STEPDECLINED: 'STEP_DECLINED' as EventTypeEnum,
        STEPDELEGATIONFINISHED: 'STEP_DELEGATION_FINISHED' as EventTypeEnum,
        STEPDELEGATIONSTARTED: 'STEP_DELEGATION_STARTED' as EventTypeEnum,
        STEPDOCUMENTENDUSERSEEN: 'STEP_DOCUMENT_ENDUSER_SEEN' as EventTypeEnum,
        STEPLEGALRESPONSE: 'STEP_LEGAL_RESPONSE' as EventTypeEnum,
        STEPRESUMED: 'STEP_RESUMED' as EventTypeEnum,
        STEPSTARTED: 'STEP_STARTED' as EventTypeEnum,
        STEPSUSPENDED: 'STEP_SUSPENDED' as EventTypeEnum,
        STEPCANNOTSTART: 'STEP_CANNOT_START' as EventTypeEnum,
        STEPENDUSERREPLACED: 'STEP_ENDUSER_REPLACED' as EventTypeEnum,
        VALIDATIONCODE: 'VALIDATION_CODE' as EventTypeEnum,
        AUTHCODE: 'AUTH_CODE' as EventTypeEnum,
        ADMINUSAGE: 'ADMIN_USAGE' as EventTypeEnum,
        CUSTOM1000: 'CUSTOM_1000' as EventTypeEnum,
        CUSTOM1001: 'CUSTOM_1001' as EventTypeEnum,
        CUSTOM1002: 'CUSTOM_1002' as EventTypeEnum,
        CUSTOM1003: 'CUSTOM_1003' as EventTypeEnum,
        CUSTOM1004: 'CUSTOM_1004' as EventTypeEnum,
        CUSTOM1005: 'CUSTOM_1005' as EventTypeEnum,
        CUSTOM1006: 'CUSTOM_1006' as EventTypeEnum,
        CUSTOM1007: 'CUSTOM_1007' as EventTypeEnum,
        CUSTOM1008: 'CUSTOM_1008' as EventTypeEnum,
        CUSTOM1009: 'CUSTOM_1009' as EventTypeEnum
    };
    export type StatusEnum = 'CREATED' | 'TO_SEND' | 'SENDING' | 'SENT' | 'FAILED';
    export const StatusEnum = {
        CREATED: 'CREATED' as StatusEnum,
        TOSEND: 'TO_SEND' as StatusEnum,
        SENDING: 'SENDING' as StatusEnum,
        SENT: 'SENT' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum
    };
}
