import {Injectable} from '@angular/core';

@Injectable()
export class LanguagesService {

  languages: string[] = ['en', 'fr'];

  set(languages: string[]) {
    this.languages = languages;
  }
}
