<div class="modal-header">
  <h4 class="modal-title"
      id="modal-basic-title">{{'USER_PROFILE.LUXTRUST.FIND_SSN' | translate}}</h4>
  <button (click)="activeModal.close()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <iframe *ngIf="iFrameVisible" [src]="orelyAuthUrl" frameborder="0" height="700" name="orely"
          scrolling="yes" target="_parent"
          width="100%"></iframe>
</div>
<div class="modal-footer">
</div>

