import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './modal-comment.component.html'
})
export class ModalCommentComponent {

  @Input() title: string;
  @Input() label: string;
  @Input() close: string;
  @Input() action: string;
  @Input() placeholder: string;

  @Output() onAction: EventEmitter<any> = new EventEmitter();

  comment: FormControl = new FormControl('');

  constructor(public activeModal: NgbActiveModal) {
  }

  handleAction() {
    const comment = this.comment.value.trim();
    if (comment) {
      this.onAction.emit(comment);
    }
  }
}
