import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DelegateService} from 'luxtrust-cosi-api/api/delegate.service';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {CreateEnduserDelegatePayload} from 'luxtrust-cosi-api/model/createEnduserDelegatePayload';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {EnduserDelegateData} from 'luxtrust-cosi-api/model/enduserDelegateData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';
import {AppService} from '../../../../services/services/app.service';
import {ModalNewSignerComponent} from '../../modal-new-signer/modal-new-signer.component';

@Component({
  templateUrl: './modal-delegate.component.html', styleUrls: ['./modal-delegate.component.scss']
})
export class ModalDelegateComponent implements OnInit, OnDestroy {

  sessionId: number;
  stepId: number;
  public stepEnduserIds: number[];
  displaySwitcher: boolean;

  @Output() output: EventEmitter<any> = new EventEmitter();

  delegateForm: FormGroup;
  formSub: Subscription;
  public delegatesProvider: {key: string, value: number}[];
  public selectedDelegate: EnduserData;
  public stepOnly: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private readonly modal: NgbModal,
    private readonly appService: AppService,
    private readonly stepEnduserService: StepEnduserService,
    private readonly alertService: AlertService,
    private readonly enduserService: EnduserService,
    private readonly delegateService: DelegateService
  ) {}

  ngOnInit() {
    this.stepOnly = false;
    this.delegatesProvider = [];
    this.delegateForm = this.formBuilder.group({
      delegate: [''],
      stepOnly: false
    });
    this.formSub = this.delegateForm.valueChanges.subscribe(values => {
      if (values.delegate && (!this.selectedDelegate || (this.selectedDelegate && values.delegate.value !== this.selectedDelegate.id))) {
        this.enduserService.getEnduser(values.delegate.value).subscribe(
          enduser => this.setDelegate(enduser),
          (error: ApiError) => this.alertService.errorApi(error)
        );
      }
      if (this.displaySwitcher) {
        this.stepOnly = !!values.stepOnly;
      } else {
        this.stepOnly = true;
      }
    });
  }

  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  setDelegate(delegate: EnduserData) {
    this.selectedDelegate = delegate;
  }

  delegatesProvider$ = (filter: string) => {
    return this.enduserService.searchEnduser(
      undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined, undefined, undefined, filter)
      .pipe(map(users =>
        users
          .filter(enduser => (!this.selectedDelegate || this.selectedDelegate.id !== enduser.id))
          .filter(enduser => !this.stepEnduserIds.some(stepEnduserId => enduser.id === stepEnduserId))
          .map((signer: EnduserData) => this.formatEnduserDisplay(signer))
      ));
  }

  private formatEnduserDisplay(signer: EnduserData) {
    const email = signer.email ? signer.email : '';
    const key = signer.lastName ?  `${signer.firstName} ${signer.lastName} (${email})` : '' ;
    return {
      key,
      value : signer.id
    };
  }

  reloadProviders(filter: string) {
    this.delegatesProvider$(filter).subscribe(delegates => {
      this.delegatesProvider = delegates;
    });
  }

  updateNbOfRequiredSignersInCircle(event) {
    this.selectedDelegate['expected'] = event.nb;
  }

  removeDelegate() {
    this.selectedDelegate = undefined;
  }

  openInviteUserModal() {
    const modalRef = this.modal.open(ModalNewSignerComponent, { backdrop: false });
    modalRef.componentInstance.title = 'STEP.DELEGATE_MODAL.ADD_TITLE';
    modalRef.componentInstance.newSignerModalResult.subscribe((invitedDelegate: EnduserData) => {
      this.selectedDelegate = invitedDelegate;
    });
  }

  handleAction() {
    let promise: Promise<any>;
    if (!this.selectedDelegate.id) {
      promise = this.createInvitedDelegateEnduser().then((enduserData: EnduserData) =>
        this.createDelegate(enduserData)
      );
    } else {
      promise = this.createDelegate(this.selectedDelegate);
    }
    promise
      .then(() => this.output.emit(this.appService.getCurrentUserId()))
      .catch((error: ApiError) => this.alertService.errorApi(error));
  }

  private createInvitedDelegateEnduser(): Promise<EnduserData> {
    return this.stepEnduserService.createStepEnduser(this.sessionId, this.stepId, {
      createEnduserPayload: {
        userId: this.selectedDelegate.email,
        email: this.selectedDelegate.email,
        lastName: this.selectedDelegate.lastName,
        firstName: this.selectedDelegate.firstName,
        phone: this.selectedDelegate.phone,
        organisationId: this.appService.getUser().organisation.organisationId,
        businessName: this.selectedDelegate.organisation.name,
        directoryAlias: this.selectedDelegate.directoryAlias
      },
      signer: true,
      expected: this.selectedDelegate['expected']
    }).toPromise().then((stepEnduser: StepEnduserData) => {
      this.alertService.success('STEP.DELEGATE_MODAL.ADD_SUCCESS');
      return stepEnduser.enduser;
    });
  }

  private createDelegate(delegateEnduserData: EnduserData): Promise<EnduserDelegateData> {
    let delegatePayload: CreateEnduserDelegatePayload = {
      delegateId: delegateEnduserData.id
    };
    // Either one or the other
    if (this.stepOnly) {
      delegatePayload = { ...delegatePayload, stepId: this.stepId };
    } else {
      delegatePayload = { ...delegatePayload, sessionId: this.sessionId };
    }
    return this.delegateService.createEnduserDelegate(delegatePayload).toPromise()
      .then((enduserDelegateData: EnduserDelegateData) => {
        this.alertService.success('STEP.DELEGATE_MODAL.DELEGATION_SUCCESS');
        return enduserDelegateData;
      });
  }
}
