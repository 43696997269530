<div
  class="enduser-card"
  [ngClass]="{
    'enduser-card-editing': idReplace === enduser.id && replaceMode,
    'other-enduser-card-editing': idReplace !== enduser.id && replaceMode
  }"
>
  <div class="d-flex justify-content-center align-items-center">
    <div class="flex-grow-1 left">
      <p class="big-text">{{(enduser.circle) ? patchCircleName(enduser.lastName) : enduser.firstName
          + ' ' + enduser.lastName}}<span *ngIf="!enduser.enrolled">&nbsp;<i class="far fa-check-circle"></i>
      </span><span *ngIf="appService.currentEnduser.directoryAlias !== enduser.directoryAlias" style="font-size: 0.65rem; color: darkslategrey"> <i>({{enduser.directoryAlias}})</i></span>
      </p>
      <span *ngIf="enduser.email" class="small-text"><i>{{enduser.email}}</i></span>
    </div>

    <select #signers
            class="custom-droplist"
            id="id-number-signer"
            *ngIf="enduser.circle && !disabled && !disabledSelectCircle"
            (change)="updateNbOfSigners()"
            [(ngModel)]="expected"
            matNativeControl required>
            <option *ngFor="let nb of tabTest.slice(1)"
                    [selected]="nb === expected">{{nb}}</option>
    </select>
    <div class="flex-grow-0">
      <button (click)="replaceEnduser()"
              *ngIf="canReplace && !disabled"
              [ngClass]="{
              'btn-replace-active': idReplace === enduser.id && replaceMode,
              'btn-replace': !replaceMode || idReplace !== enduser.id
              }"
              class="icon-button"
              type="button"
              [ngbTooltip]="'MODAL_TEMPLATE_SESSION.REPLACE_USER' | translate"
              >
        <i aria-hidden="true" class="fa fa-edit icon"></i>
      </button>
    </div>
    <div class="flex-grow-0 w-trash">
      <button (click)="deleteEnduser()"
              *ngIf="!disabled && (idReplace !== enduser.id || !canReplace)&& !isConfigAndCanJustReplace()"
              class="btn-delete icon-button"
              type="button">
        <i aria-hidden="true" class="fa fa-trash"></i>
      </button>
    </div>

  </div>
</div>
