<div class="document-list">
  <div class="document-list-action">
    <div class="document-list-action-content">
      <div class="input-group">
        <input (keyup)='keyUp.next($event.target.value)'
               [placeholder]="'SIGNATURE_BOOK.SEARCH' | translate" class="form-control"
               type="text">
        <button class="btn btn-primary" *ngIf="sortAsc" (click)="sortAsc=false">
          <i class="fa fa-sort-amount-up"></i>
        </button>
        <button class="btn btn-primary" *ngIf="!sortAsc" (click)="sortAsc=true">
          <i class="fa fa-sort-amount-down"></i>
        </button>
      </div>
    </div>
  </div>
  <!--
  <div class="switcher switcher-right ml-0 size-label align-switcher document-list-action">
    <input (click)="filterDocumentByStatus()"
           [(value)]="listOfDocumentIsFilter"
           #filterByStatus
           class="switcher-checkbox"
           id="filter-document"
           type="checkbox">
    <div class="switcher-toggle"></div>
    <label for="filter-document">{{'SIGNATURE_BOOK.SEARCH_FILTER' | translate}}</label>
  </div>
  -->
  <!-- A list of document cards -->
  <div *ngIf="_signatureBook"
        infinite-scroll
       [scrollWindow]="false"
       [infiniteScrollDistance]="scrollDistance"
       [infiniteScrollThrottle]="throttle"
       (scrolled)="onScrollDown($event)"
       class="document-list-result">
    <div *ngFor="let item of _signatureBook.items; index as i" class="document-list-result-content">
      <document-card (click)="documentSelected.emit(item)"
                     (moveToCommentSection)="moveToCommentSection.emit(item)"
                     [createdOn]="item.createdOn"
                     [documentName]="item.document.name"
                     [isActive]="isSelected(item)"
                     [numberAnnotation]="item.additionalSession.annotationNumber"
                     [numberComment]="item.additionalSession.commentNumber"
                     [priority]="item.additionalSession.priority"
                     [sessionName]="item.sessionName"
                     [signatures]="item.signatureBookSignerList"
                     [stepId] ="item.stepId"
                     [sessionId]= "item.sessionId"
                     [status]="item.status"
                     [alternateColor]="blueGreenArray[i]"
                     [stepName]="item.stepName">
      </document-card>
      <div class="padding-card"></div>
    </div>
  </div>
</div>
