/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Rejection to update (null values ignored)
 */
export interface UpdateRejectionPayload { 
    /**
     * Comment
     */
    comment?: string;
    /**
     * Document
     */
    documentId?: number;
    /**
     * Document status
     */
    status?: UpdateRejectionPayload.StatusEnum;
}
export namespace UpdateRejectionPayload {
    export type StatusEnum = 'STARTED' | 'SIGNED' | 'DECLINED';
    export const StatusEnum = {
        STARTED: 'STARTED' as StatusEnum,
        SIGNED: 'SIGNED' as StatusEnum,
        DECLINED: 'DECLINED' as StatusEnum
    };
}
