/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Addressee } from './addressee';


export interface PatternFilter { 
    addressee?: Addressee;
    channel?: PatternFilter.ChannelEnum;
    eventTypeId?: number;
    language?: PatternFilter.LanguageEnum;
    sessionId?: number;
    stepId?: number;
    tagId?: number;
    tagValue?: string;
}
export namespace PatternFilter {
    export type ChannelEnum = 'EMAIL' | 'SMS' | 'APP' | 'OTHER';
    export const ChannelEnum = {
        EMAIL: 'EMAIL' as ChannelEnum,
        SMS: 'SMS' as ChannelEnum,
        APP: 'APP' as ChannelEnum,
        OTHER: 'OTHER' as ChannelEnum
    };
    export type LanguageEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LanguageEnum = {
        EN: 'EN' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        DE: 'DE' as LanguageEnum,
        PT: 'PT' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        NL: 'NL' as LanguageEnum,
        SP: 'SP' as LanguageEnum
    };
}
