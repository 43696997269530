import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {AuthenticationService} from 'luxtrust-cosi-api/api/authentication.service';
import {EnduserPreferenceService} from 'luxtrust-cosi-api/api/enduserPreference.service';
import {ItsmeAuthenticationService} from 'luxtrust-cosi-api/api/itsmeAuthentication.service';
import {PlatformService} from 'luxtrust-cosi-api/api/platform.service';
import {TenantService} from 'luxtrust-cosi-api/api/tenant.service';
import {ConfigData} from 'luxtrust-cosi-api/model/configData';
import {AuthCodePayload} from 'luxtrust-cosi-api/model/authCodePayload';
import {ItsmeAuthorizationDto} from 'luxtrust-cosi-api/model/itsmeAuthorizationDto';
import {BASE_PATH} from 'luxtrust-cosi-api/variables';
import {Observer} from 'rxjs';
import {take} from 'rxjs/operators';
import {SprofileWrapperService} from 'src/app/services/services/sprofile-wrapper.service';
import {environment} from '../../../environments/environment';
import {CONFIG, REGEXES} from '../../app.constant';
import {ApiError} from '../../error/api-error.model';
import {AlertService} from '../../services/services/alert-service';
import {AppService} from '../../services/services/app.service';
import {ConfigurationService} from '../../services/services/configuration.service';
import {ItsmeAuthServiceType, ItsmeService} from '../../services/services/itsme.service';
import {NavigationService} from '../../services/services/navigation.service';
import {VisualIdentityService} from '../../services/services/visual-identity.service';

@Component({
  templateUrl: './login.component.html', styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotEmailFormGroup: FormGroup;
  authCodeFormGroup: FormGroup;

  selectedAuthProvider: string;
  orelyAuthUrl: SafeResourceUrl;

  @ViewChild('orelyIframe', { read: ElementRef, static: false }) orelyIframe: ElementRef;
  @ViewChild('authCodeModal', { read: TemplateRef, static: false }) authCodeModal: TemplateRef<any>;

  tenantName: string;
  customLogo: string;
  errPasswordUsername: boolean;
  forgotEmailValid = false;  
  isAuthenticating = false;
  baWithEmail = false;
  baWithSMS = false;

  passwordEnabled: boolean;
  luxtrustEnabled: boolean;
  itsmeEnabled: boolean;
  mcEnabled: boolean;
  samlEnabled: boolean;
  azureEnabled: boolean;
  azureEnrEnabled: boolean;
  emailEnabled: boolean;
  smsEnabled: boolean;
  selfRegisterEnabled: boolean;
  // Auth factor enabled
  factorsEnabled: number;
  mainConfigAuth: string;
  // defaultPageAuth = false;
  mainLoginMessageContent: string;
  mainLoginMessageNature: string;
  mainLoginMessageTitle: string;
  supportPhoneNumber: string;

  constructor(public appService: AppService,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private configurationService: ConfigurationService,
              private authService: AuthenticationService,
              private translate: TranslateService,
              private itsmeAuthenticationService: ItsmeAuthenticationService,
              private itsmeService: ItsmeService,
              private domSanitizer: DomSanitizer,
              private modalService: NgbModal,
              private visualIdentityService: VisualIdentityService,
              private endUserPreferenceService: EnduserPreferenceService,
              private navigationService: NavigationService,
              private platformService: PlatformService,
              private tenantService: TenantService,
              @Inject(BASE_PATH) public basePath: string,
              private changeDetectorRef: ChangeDetectorRef,
              private sprofileService: SprofileWrapperService) {
  }



  get emailNotificationObserver(): Observer<any> {
    return {
      next: () => this.displayAlertSuccess('ALERT.PASSWORD_RESET_LINK_SENT'),
      error: (error: ApiError) => this.alertService.errorApiWithCustomMessage(`${error.error.code} : ${this.forgotEmailFormGroup.value.email}`, error),
      complete: () => {}
    };
  }

  private loadEndUserIfPossible(sessionId: number, stepId: number): void {
    if (this.appService.isLoggedIn()) {
      if (sessionId && stepId) {
        this.sprofileService.getSprofileList();
        this.appService.loadEnduser().then(() => this.navigationService.goToFirstStartedStepOrDocument(sessionId, stepId));
      } else if (sessionId) {
        this.appService.loadEnduser().then(() => this.navigationService.goToDocumentOrFirstStartedStepOrSession(sessionId));
      } else {
        this.leaveLoginPage();
      }
    }
  }

  private setDefaultLoginForm(): void {
    if (this.passwordEnabled && this.mainConfigAuth === 'ba') {
      this.selectedAuthProvider = 'ba';
    } else if (this.luxtrustEnabled && this.mainConfigAuth === 'luxtrust') {
      this.selectedAuthProvider = 'luxtrust';
    } else if (this.itsmeEnabled && this.mainConfigAuth === 'itsme') {
      this.selectedAuthProvider = 'itsme';
    } else if (this.samlEnabled && this.mainConfigAuth === 'gardian') {
      this.selectedAuthProvider = 'gardian';
    } else if (this.mcEnabled && this.mainConfigAuth === 'mc') {
      this.selectedAuthProvider = 'mc';
    } else if (this.azureEnabled && this.mainConfigAuth === 'azure') {
      this.selectedAuthProvider = 'azure';
    } else if (this.azureEnrEnabled && this.mainConfigAuth === 'azureenr') {
      this.selectedAuthProvider = 'azureenr';
    } else if (this.emailEnabled && this.mainConfigAuth === 'email') {
      this.selectedAuthProvider = 'email';
    } else if (this.smsEnabled && this.mainConfigAuth === 'sms') {
      this.selectedAuthProvider = 'sms';
    } else {
      this.selectedAuthProvider = 'none';
    }
  }

  private configureLoginPage() {    
    let platformConfig: ConfigData;
    // FIXME BDX Find a better solution than always fetching configuration, find a solution with configuration.service again
    this.platformService.getConfig().toPromise()
      .then((loadedPlatformConfig) => {
        platformConfig = loadedPlatformConfig;
        return this.tenantService.getTenantConfig(this.tenantName).toPromise();
      }).then((tenantConfig) => {              
        this.mainConfigAuth = tenantConfig[CONFIG.TENANT.AUTH.MAIN_KEY];
        if (!this.mainConfigAuth) {
          this.mainConfigAuth = 'ba';
        } else if (this.mainConfigAuth === 'email') {
          this.baWithEmail = true;
        } else if (this.mainConfigAuth === 'sms') {
          this.baWithSMS = true;
        }
        const authPossibilities: string[] = tenantConfig[CONFIG.TENANT.AUTH.KEY].split('|');
        this.factorsEnabled = authPossibilities.length;
        this.passwordEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.PASSWORD);
        this.luxtrustEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.ORELY);
        this.itsmeEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.ITSME);
        this.mcEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.MOBILE_CONNECT);
        this.samlEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.GARDIAN);
        this.azureEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.AZURE);
        this.azureEnrEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.AZUREENR);
        this.emailEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.EMAIL);
        this.smsEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.SMS);
        this.selfRegisterEnabled = !!+tenantConfig[CONFIG.TENANT.IS_SELF_REGISTER_ACTIVE.KEY];
        const content: string = 'login-message-content-' + this.translate.currentLang.toUpperCase();
        const title: string = 'login-message-title-' + this.translate.currentLang.toUpperCase();
        this.mainLoginMessageContent = tenantConfig[content];
        this.mainLoginMessageTitle = tenantConfig[title];
        this.mainLoginMessageNature = tenantConfig[CONFIG.TENANT.LOGIN_MESSAGE.NATURE];
        this.supportPhoneNumber = tenantConfig[CONFIG.TENANT.LOGIN_PAGE_SUPPORT_PHONE_NUMBER.KEY] ?
          tenantConfig[CONFIG.TENANT.LOGIN_PAGE_SUPPORT_PHONE_NUMBER.KEY]
          : this.translate.instant('LOGIN.SUPPORT.PHONE');
      }).catch(() => {
        this.passwordEnabled = true;
        this.luxtrustEnabled = true;
        this.itsmeEnabled = platformConfig.itsmeAuthActive;
        this.mcEnabled = platformConfig.mcAuthActive;
        this.samlEnabled = platformConfig.saml2AuthActive;
        this.azureEnabled = platformConfig.azureActive;
        this.selfRegisterEnabled = platformConfig.selfRegisterActive;
      }).then(() => {
        this.setDefaultLoginForm();
      });
  }

  ngOnInit() {
    this.tenantName = this.route.snapshot.queryParams['tenantName'];
    this.orelyAuthUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.ORELY_URL + '/auth/login?tenantName='+this.tenantName);

    this.visualIdentityService.logo$.subscribe(logo => {
      this.customLogo = logo;
    });

    this.configureLoginPage();

    // first, try to login with a provided jwt in the url
    const jwt = this.route.snapshot.queryParams['jwt'];
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (jwt) {
      this.appService.refresh(jwt);
      this.loadEndUserIfPossible(sessionId, stepId);
    }

    const error = this.route.snapshot.queryParams['error'];
    if (error) {
      this.alertService.error(error);
    }

    // if the user cannot be logged in with JWT in url, create the login form

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]], password: ['', [Validators.required]]
    });
    this.forgotEmailFormGroup = this.formBuilder.group({
      email: ['', [
          Validators.required,
          Validators.email,
          Validators.pattern(REGEXES.EMAIL)
        ]
      ]
    });
    this.authCodeFormGroup = this.formBuilder.group({
      authCode: ['', [Validators.required, Validators.pattern(REGEXES.AUTH_CODE)]]
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.isAuthenticating = false;
      return;
    }
    if (this.baWithEmail || this.baWithSMS) {
      const channel : AuthCodePayload.ChannelEnum = this.baWithEmail ? AuthCodePayload.ChannelEnum.EMAIL : AuthCodePayload.ChannelEnum.SMS;
      this.authService.authenticateUserWithCode({
        tenantName: this.tenantName,
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
        channel,
      }).pipe(take(1)).subscribe((response: any) => {
        this.alertService.success('LOGIN.AUTH_CODE.ALERT_SENT');
        this.openModal(this.authCodeModal);
      }, (err) => {
        console.log(err);
        this.alertService.error('LOGIN.AUTH_CODE.ALERT_FAILED');
      });      
     this.openModal(this.authCodeModal);
    } else {
      this.isAuthenticating = true;
      this.loginForm.markAllAsTouched();

      this.authService.authenticateUser({
        tenantName: this.tenantName, username: this.loginForm.value.username, password: this.loginForm.value.password
      }).pipe(take(1)).subscribe((response: any) => {
        this.authenticationSuccess(response.access_token);
        this.errPasswordUsername = false;
        this.sprofileService.getSprofileList();
        this.isAuthenticating = false;
      }, () => {
        this.isAuthenticating = false;
        this.authenticationFailure();
        this.errPasswordUsername = true;
        setTimeout(() => {
          this.errPasswordUsername = false;
        }, 10000);
      });
    }
  }

  onAuthCodeSubmit() {
    const authCode: string = this.authCodeFormGroup.value.authCode.trim();
    const channel : AuthCodePayload.ChannelEnum = this.baWithEmail ? AuthCodePayload.ChannelEnum.EMAIL : AuthCodePayload.ChannelEnum.SMS;    
    
    this.authService.verifyCode({
      tenantName: this.tenantName,
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
      channel,
      code: authCode
    }).pipe(take(1)).subscribe((response: any) => {
      this.authenticationSuccess(response.access_token);
      this.errPasswordUsername = false;
      this.sprofileService.getSprofileList();
      this.isAuthenticating = false;
      this.onAuthCodeModalDismiss();
    }, () => {
      this.alertService.error('LOGIN.AUTH_CODE.INCORRECT');
    });
  }

  onAuthCodeModalDismiss() {
    this.authCodeFormGroup.reset();
    this.modalService.dismissAll('normal');
  }

  @HostListener('window:message', ['$event'])
  iFrameEventListener(event: MessageEvent) {
    const data = event.data;
    if (data) {
      if (data.access_token && data.flowStatus === 'success') {
        this.appService.refresh(data.access_token);
        this.leaveLoginPage();
      } else {
        if (data.flowStatus === 'USER_CLICKED_CANCELLED') {
          this.handleOrelyLoginFailure(undefined);
        } else if (data.flowStatus === 'FAILURE_SSN_NOT_LINKED_WITH_USER') {
          this.handleOrelyLoginFailure('(LOGIN).ORELY.ERRORS.FAILURE_SSN_NOT_LINKED_WITH_USER');
        } else if (data.flowStatus === 'FAILURE_SSN_NOT_REGISTERED') {
          this.handleOrelyLoginFailure('LOGIN.ORELY.ERRORS.FAILURE_SSN_NOT_REGISTERED');
        }
      }
    }
  }

  openModal(content: TemplateRef<any>) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false });
  }

  sendEmailNotification() {
    this.forgotEmailFormGroup.markAllAsTouched();
    if (!this.forgotEmailFormGroup.valid) {
      return;
    }
    this.authService.emailPasswordReset(this.forgotEmailFormGroup.value.email, this.tenantName)
      .pipe(take(1)).subscribe(this.emailNotificationObserver);
    this.modalService.dismissAll('normal');
  }

  displayAlertSuccess(alertKey: string) {
    this.alertService.success(alertKey);
  }

  authWithBa() {
    this.selectedAuthProvider = 'ba';
    this.baWithEmail = false;
    this.baWithSMS = false;
  }

  authWithItsme() {
    const redirectUri = this.itsmeService.getRedirectUrlForAuth(ItsmeAuthServiceType.LOGIN);
    // this.itsmeAuthorizationUrl(redirectUri, ItsmeAuthServiceType.LOGIN)
    this.itsmeAuthenticationService.itsmeAuthorizationUrl(ItsmeAuthServiceType.LOGIN, redirectUri)
      .pipe(take(1))
      .subscribe((dto: ItsmeAuthorizationDto) => window.location.href = dto.url, (error: ApiError) => this.alertService.errorApi(error));
  }

  authWithMc() {
    window.location.href = this.basePath + '/api/mc/auth/start_discovery?role=login';
  }

  authWithSamlv2() {
    window.location.href = this.basePath + '/saml/auth/login';
  }

  authWithAzure() {
    window.location.href = this.basePath + '/api/azuread/secure/aad/' + this.tenantName;
  }

  authWithEmail() {
    this.selectedAuthProvider = 'email';
    this.baWithEmail = true;
    this.baWithSMS = false;
  }

  authWithSMS() {
    this.selectedAuthProvider = 'sms';
    this.baWithSMS = true;
    this.baWithEmail = false;
  }

  onRegister() {
    if (this.tenantName) {
      this.router.navigate(['/signup', this.tenantName]);
    } else {
      this.router.navigate(['/signup']);
    }
  }

  private leaveLoginPage() {
    Promise.all(
      [this.appService.loadEnduser(), this.endUserPreferenceService.getEnduserPreferences(this.appService.getCurrentUserId()).toPromise()])
      .then(([_, prefs]) => {
        this.visualIdentityService.loadVisualIdentityWhenLogged();
        if (prefs.preferences.autoSignatureBook) {
          this.router.navigate(['/signature-book']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
  }

  private authenticationSuccess(response: string) {
    this.appService.refresh(response);
    this.alertService.success('ALERT.SIGN_IN_SUCCESS');
    this.leaveLoginPage();
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];

    this.loadEndUserIfPossible(sessionId, stepId);
  }

  private authenticationFailure() {
    this.appService.revoke();
    this.appService.logout();
    if (this.tenantName) {
      this.router.navigateByUrl(`/login?tenantName=${this.tenantName}`);
    } else {
      this.router.navigateByUrl(`/no-tenant-name`);
    }
  }

  private handleOrelyLoginFailure(errorKey: string) {
    if (errorKey) {
      this.alertService.error(errorKey);
    }
    this.selectedAuthProvider = this.mainConfigAuth;
    // FIXME This should be done using iframe reloading with a postMessage
    window.location.reload();
  }

  checkAndValidate(event: any) {
    this.forgotEmailValid = event.target.value.match(new RegExp(REGEXES.EMAIL));
  }
}
