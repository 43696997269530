import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/core/service/translate.service';
import { EnduserCircleService, EnduserData, StepEnduserService } from 'luxtrust-cosi-api';
import { PlaceholderService } from 'luxtrust-cosi-api/api/placeholder.service';
import { StepEndUserDocumentService } from 'luxtrust-cosi-api/api/stepEndUserDocument.service';
import { DocumentData } from 'luxtrust-cosi-api/model/documentData';
import { SignaturePlaceholderData } from 'luxtrust-cosi-api/model/signaturePlaceholderData';
import { SprofileData } from 'luxtrust-cosi-api/model/sprofileData';
import { StepDocumentEnduserData } from 'luxtrust-cosi-api/model/stepDocumentEnduserData';
import { StepDocumentEndUserPayload } from 'luxtrust-cosi-api/model/stepDocumentEndUserPayload';
import { StepEnduserData } from 'luxtrust-cosi-api/model/stepEnduserData';
import { take } from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import { AlertService } from '../../../services/services/alert-service';
import { WizardDocumentStoreService } from '../../../session/step/services/wizard-document-store.service';

@Component({
  selector: 'app-endusers-document-table',
  templateUrl: './endusers-document-table.component.html',
  styleUrls: ['./endusers-document-table.component.scss']
})
export class EndusersDocumentTableComponent implements OnInit, OnChanges {

  @Input() title = '';
  @Input() noResultFound = '';
  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() stepEndUsers: StepEnduserData[] = [];
  @Input() documents: DocumentData[] = [];
  @Input() sprofileList: SprofileData[] = [];

  forbidDownloads: { [id: string]: boolean } = {};
  forbidViews: { [id: string]: boolean } = {};
  forbidPrints: { [id: string]: boolean } = {};
  bringsToStep: { [id: string]: boolean } = {};
  sprofiles: { [id: string]: string } = {};
  expected: { [id: string]: number } = {};

  acroformNameList: SignaturePlaceholderData[] = [];

  selecting = false;
  settingAcroform = false;

  constructor(private stepEndUserDocumentService: StepEndUserDocumentService,
              private translateService: TranslateService,
              private placeholderService: PlaceholderService,
              private alertService: AlertService,
              private wizardDocumentStoreService: WizardDocumentStoreService,
              private circleEnduserService: EnduserCircleService,
              private stepEnduserService: StepEnduserService) {
  }

  ngOnChanges() {
    this.initExpected();
    this.stepEndUsers.forEach(element => {
      element.enduser['nbs'] = [1];
      this.getNbOfPotentialSigners(element.enduser);
    });
    if (this.documents.length) {
      this.getAcroformNameList();
      for (let i = 0; i < this.documents.length; i++) {
        for (let j = 0; j < this.stepEndUsers.length; j++) {
          this.sprofiles[
          this.stepEndUsers[j].enduser.id + '-' +
          this.documents[i].id] = this.sprofiles[this.stepEndUsers[j].enduser.id + '-' +
          this.documents[i].id] || this.documents[i].sprofileCode;
        }
      }
    }
  }

  ngOnInit() {
    this.getDocuments();
  }

  initExpected() {
    this.stepEndUsers.forEach(step => {
      this.documents.forEach(doc => {
        this.expected[step.enduser.id + '-' + doc.id] = step.expected;
      });
    });
  }

  getDocuments() {
    this.stepEndUserDocumentService.getStepEnduserDocument(this.sessionId, this.stepId).pipe(take(1))
    .subscribe((stepDocumentEnduserDataList: StepDocumentEnduserData[]) => stepDocumentEnduserDataList.forEach(
      (stepDocumentEnduserData: StepDocumentEnduserData) => {
        this.sprofiles[stepDocumentEnduserData.enduserId + '-' + stepDocumentEnduserData.documentId] = stepDocumentEnduserData.sprofileCode;
        this.forbidDownloads[stepDocumentEnduserData.enduserId + '-' + stepDocumentEnduserData.documentId] = stepDocumentEnduserData.forbidDownload;
        this.forbidViews[stepDocumentEnduserData.enduserId + '-' + stepDocumentEnduserData.documentId] = stepDocumentEnduserData.forbidView;
        this.forbidPrints[stepDocumentEnduserData.enduserId + '-' + stepDocumentEnduserData.documentId] = stepDocumentEnduserData.forbidPrint;
        this.bringsToStep[stepDocumentEnduserData.enduserId + '-' + stepDocumentEnduserData.documentId] = stepDocumentEnduserData.bringToStep;
      }), (error: ApiError) => this.alertService.errorApi(error));
    this.getAcroformNameList();
  }

  setAcroform(id, enduserId, event) {
    const name = event.target.value;
    this.settingAcroform = true;
    let promise;
    if (this.acroformNameList.length > 0) {
      const acroform = this.acroformNameList.find(acroform_ => acroform_.documentId === id && acroform_.enduserId === enduserId);
      if (acroform && acroform.name.indexOf(":circle-") === -1) {
        promise = this.placeholderService.updateSignaturePlaceholder(id, this.sessionId, this.stepId, {
          acroform: false, name: acroform.name, newName: name, enduserId: enduserId
        }).toPromise().then(() => {this.getDocuments(); });
      } else  {
        promise = this.placeholderService.createSignaturePlaceholder(id, this.sessionId, this.stepId, {
          acroform: false, name: name, enduserId: enduserId
        }).toPromise();
      }
    } else {
      promise = this.placeholderService.createSignaturePlaceholder(id, this.sessionId, this.stepId, {
        acroform: false, name: name, enduserId: enduserId
      }).toPromise();
    }
    promise
      .then(() => this.getDocuments())
      .catch((error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.settingAcroform = false);
  }

  getAcroformNameList(): any {
    if (this.acroformNameList.length > 0) {
      return;
    }
    this.acroformNameList = [];
    this.documents.forEach((document) => {
      if (document.stepId === this.stepId) {
        this.placeholderService.getSignaturePlaceholderList(
          document.id, this.sessionId, this.stepId
        ).toPromise().then((res) => {
          res.forEach(acroform => {
            this.acroformNameList.push(acroform);
          });
        }).catch((error: ApiError) => {
          this.alertService.errorApi(error);
        });
      }
    });
  }

  selected(stepEndUserId: any, documentId: number, event?: any ) {
    const key = stepEndUserId + '-' + documentId;
    const sprofileCode = event ? event.target.value : this.sprofiles[key];
    const payload = <StepDocumentEndUserPayload>{
      documentId: documentId,
      enduserId: stepEndUserId,
      sprofileCode: sprofileCode,
      forbidDownload: this.forbidDownloads[stepEndUserId + '-' + documentId],
      forbidView: this.forbidViews[stepEndUserId + '-' + documentId],
      forbidPrint: this.forbidPrints[stepEndUserId + '-' + documentId],
      bringToStep: this.bringsToStep[stepEndUserId + '-' + documentId],
      expected: this.expected[stepEndUserId + '-' + documentId]
    };
    this.selecting = true;
    this.stepEnduserService.partialUpdateStepEnduser(stepEndUserId, this.sessionId, this.stepId, {
      signer: true,
      expected: this.expected[stepEndUserId + '-' + documentId]
    }).toPromise();

    this.stepEndUserDocumentService.updateStepEnduserDocument(this.sessionId, this.stepId, payload)
      .toPromise()
      .then(() => {
        this.alertService.success('WIZARD.SIGNERS.UPDATE_SUCCESS');
        this.sprofiles[stepEndUserId + '-' + documentId] = sprofileCode || this.sprofiles[stepEndUserId + '-' + documentId];
      }, (error) => this.alertService.errorApiWithCustomMessage('WIZARD.SIGNERS.UPDATE_ERROR', error))
      .then(value => this.selecting = false);
  }

  displayAcroform(documentId: number, enduserId: string) {
    if (this.acroformNameList) {
      const acroform = this.acroformNameList.find(acroform_ => acroform_.documentId === documentId && acroform_.enduserId === enduserId);
      // the generated default acroform name should be removed when back will do that
      const s = acroform ? (acroform.name ? acroform.name : (acroform.documentId + '-' + acroform.enduserId)) : '';
      return s;
    } else {
      return '';
    }
  }

  getNbOfPotentialSigners(enduser: EnduserData) {
    enduser['nbs'] = [1];
    if (enduser.circle) {
      this.circleEnduserService.getCircleEnduserList(enduser.id).toPromise().then((endusers) => {
        enduser['nbs'] = this.initTable(endusers.length);
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    }
  }

  initTable(max: number) {
    const list = [];
    for (let i = 1; i <= max; i++) {
      list.push(i);
    }
    return list;
  }
}
