import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from 'src/app/core/service/translate.service';
import {AdditionalSessionData} from 'luxtrust-cosi-api/model/additionalSessionData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {AppService} from '../../services/services/app.service';

@Component({
  selector: 'app-table-dashboard', templateUrl: './table-dashboard.component.html', styleUrls: ['./table-dashboard.component.scss']
})
export class TableDashboardComponent implements OnInit {
  @Input() sessions: SessionData[];
  @Input() additionalSessions: AdditionalSessionData[];
  @Input() disabled: boolean;

  @Output() openSession: EventEmitter<number> = new EventEmitter();
  @Output() deleteSession: EventEmitter<SessionData> = new EventEmitter();
  @Output() disableSession: EventEmitter<SessionData> = new EventEmitter();

  constructor(public appService: AppService, public translateService: TranslateService) {
  }

  ngOnInit() {
  }

  getCommentCount(session: SessionData): number {
    const foundAdditionalSession = this.additionalSessions.find(ads => ads && ads.id === session.id);
    if (foundAdditionalSession) {
      return foundAdditionalSession.commentNumber;
    }
    // tslint:disable-next-line:no-null-keyword
    return null;
  }

  getDeclined(session: SessionData): any[] {
    const foundAdditionalSession = this.additionalSessions.find(ads => ads && ads.id === session.id);
    if (foundAdditionalSession && foundAdditionalSession.declinedList) {
      return foundAdditionalSession.declinedList;
    }
    return [];
  }

  getPriority(session: SessionData): number {
    const foundAdditionalSession = this.additionalSessions.find(ads => ads && ads.id === session.id);
    if (foundAdditionalSession) {
      return foundAdditionalSession.priority;
    }
    // tslint:disable-next-line:no-null-keyword
    return null;
  }

  getRequesterManager(session: SessionData): boolean {
    const foundRequesterManager = this.sessions.find(ads => ads && ads.requesterManager === session.requesterManager);
    if (foundRequesterManager) {
      return foundRequesterManager.requesterManager;
    }
    // tslint:disable-next-line:no-null-keyword
    return null;
  }

  getOwnerName(sessionId) {
    const additionalData = this.additionalSessions.find(addSess => addSess.id === sessionId);
    return (additionalData && additionalData.owner) ? additionalData.owner.firstName + ' ' + additionalData.owner.lastName + ' - ' : '';
  }

  emitEditSession(sessionId) {
    if (!this.disabled)
      this.openSession.emit(sessionId)
  }
}
