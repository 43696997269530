import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-items-table', templateUrl: './items-table.component.html', styleUrls: ['./items-table.component.scss']
})
export class ItemsTableComponent {

  @Input() noResult: string;
  @Input() items: any[] = [];
  @Input() borderBottom = true;
  @Input() singleList: boolean;
  @Input() canAdd = false;
  @Input() canReplace = false;
  @Input() isConfigurator = false;
  @Input() enableInviteNewSigner = true;
  @Output() openModal: EventEmitter<boolean> = new EventEmitter;

  @ContentChild('itemsCards', {static: false}) itemsTemplate: TemplateRef<any>;

  onOpenModal() {
      this.openModal.emit();
  }

}
