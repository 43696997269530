import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({providedIn: 'root'})
export class InviteGuard implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const tenantNameQueryParam = route.queryParamMap.get('tenantName');
    const tenantNameParam = route.paramMap.get('tenantName');
    if (tenantNameQueryParam || tenantNameParam) {
      return true;
    } else {
      this.router.navigate(['/no-tenant-name']);
      return false;
    }
  }
}
