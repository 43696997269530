import {Injectable} from '@angular/core';

/**
 * Service used to manipulate URL directly using DOM.
 * This is a workaround.
 */
@Injectable({providedIn: 'root'})
export class UrlService {

  getQueryParamByName(name: string): string {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp('^(?:.*[&\\?]' + encodeURIComponent(name).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
        '$1'
      )
    );
  }

}
