/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./card.component";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleCollapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "cursor": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "i", [["class", "fa float-right collapsible-control"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.collapsible ? "pointer" : "default")); _ck(_v, 2, 0, currVal_0); var currVal_2 = "fa float-right collapsible-control"; var currVal_3 = (_co.collapsed ? "fa-chevron-right" : "fa-chevron-down"); _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "card-body"]], [[2, "collapse", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "customPadding": 0 }), i1.ɵdid(7, 16384, null, 0, i3.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collapsible; _ck(_v, 2, 0, currVal_0); var currVal_3 = "card-body"; var currVal_4 = _ck(_v, 6, 0, _co.customPadding); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = _co.collapsed; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = true; var currVal_2 = !i1.ɵnov(_v, 7).collapsed; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i4.CardComponent, [], null, null)], null, null); }
var CardComponentNgFactory = i1.ɵccf("app-card", i4.CardComponent, View_CardComponent_Host_0, { collapsible: "collapsible", title: "title", collapsed: "collapsed", customPadding: "customPadding" }, { collapse: "collapse" }, ["*"]);
export { CardComponentNgFactory as CardComponentNgFactory };
