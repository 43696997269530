<div class="form-group">
  <label *ngIf="label"
         [for]="id">{{label}}</label>
  <div class="input-group">
    <input #input
           (blur)="hasOnBlur ? onTouched($event.target.value) : null"
           (change)="(hasOnBlur || hasOnInput) ? null : onChange($event.target.value)"
           (input)="hasOnInput ? onChange($event.target.value) : null"
           [attr.aria-describedby]="id+'Hint'"
           [attr.aria-label]="placeholder"
           [attr.maxlength]="maxLength"
           [attr.tabindex]="tabindex"
           [disabled]="disabled"
           id="id{{idPostfix}}"
           [placeholder]="placeholder ? placeholder : ''"
           [type]="type"
           [value]="value? value : ngControl.value"
           autocomplete='off'
           class="form-control">
    <div *ngIf="icon"
         class="input-group-append">
      <div (click)="handleClick()" *ngIf="handleNavigation()"
           [ngClass]="{'input-group-text cursor-pointer' : handleNavigation()}">
        <i aria-hidden="true" class="fas fa-{{icon}}"></i>
      </div>
      <span *ngIf="!handleNavigation()" class="input-group-text">
        <i aria-hidden="true" class="fas fa-{{icon}}"></i>
      </span>
    </div>
  </div>
  <div *ngIf="ngControl.touched && ngControl.invalid; else showHint"
       class="invalid-feedback">
    {{getFirstError()}}
  </div>
  <ng-template #showHint>
    <small *ngIf="hint"
           [id]="id+'Hint'"
           class="form-text text-muted">{{hint}}</small>
  </ng-template>
</div>
