import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

/**
 * Service used to shara data between module or component.
 */
@Injectable()
export class DataService {

  /** The tenant used by the current user.*/
  private tenantName: string;

  /**
   * Default constructor.
   * @param route service used to retreive the tenant name present in the URL.
   */
  constructor(private route: ActivatedRoute) {
  }

  /**
   * Set the tenantName of the current connected user.
   * @param name
   */
  setTenantName(name: string): void {
    this.tenantName = name;
  }

  /**
   * Return the tenant value. If the tenant is not defined, check in the URL to retrieve the value of the tenant and set
   * it under #tenantName.
   *
   * @return the tenant value or empty string if tenant is not available.
   */
  getTenantName(): string {
    if (!this.tenantName) {
      this.tenantName = this.route.snapshot.queryParams['tenantName'];
    }
    return (this.tenantName) ? this.tenantName : '';
  }
}
