import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import * as introJs from 'intro.js/intro.js';
import find from 'lodash/find';
import remove from 'lodash/remove';
import uniqWith from 'lodash/uniqWith';
import {SignatureWorkflowService, StepEnduserService, StepRuleOrderService} from 'luxtrust-cosi-api';
import {AnnotationService} from 'luxtrust-cosi-api/api/annotation.service';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {PlaceholderService} from 'luxtrust-cosi-api/api/placeholder.service';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SprofileService} from 'luxtrust-cosi-api/api/sprofile.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {CreateDocumentPayload} from 'luxtrust-cosi-api/model/createDocumentPayload';
import {CreateSessionPayload} from 'luxtrust-cosi-api/model/createSessionPayload';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {ValidatorHelper} from 'src/app/services/validators/validators.helper';
import {AutocompleteComponent} from 'src/app/shared/components/form/autocomplete/autocomplete.component';
import {environment} from '../../environments/environment';
import {CONFIG} from '../app.constant';
import {ApiError} from '../error/api-error.model';
import {AlertService} from '../services/services/alert-service';
import {AppService} from '../services/services/app.service';
import {ConfigurationService} from '../services/services/configuration.service';
import {NavigationService} from '../services/services/navigation.service';
import {Strings} from '../services/utils/strings';
import {EnduserItemComponent} from '../shared/components/enduser-item/enduser-item.component';
import {ModalNewSignerComponent} from '../shared/components/modal-new-signer/modal-new-signer.component';
import {ModalPdfConsumerComponent} from '../shared/components/modal-pdf/modal-pdf-consumer.component';
import {WizardDocumentStoreService} from '../session/step/services/wizard-document-store.service';

@Component({
  templateUrl: './new-session.component.html', styleUrls: ['./new-session.component.scss']
})
export class NewSessionComponent extends ModalPdfConsumerComponent implements OnInit, OnDestroy {

  newSessionForm: FormGroup;
  @ViewChild('sprofilesAutocomplete', { static: false }) sprofilesAutocomplete: AutocompleteComponent<SprofileData>;
  @ViewChild(EnduserItemComponent, { static: false }) child: EnduserItemComponent;
  readonly FILE_SIZE_LIMIT: number;
  introJSCreateSession = introJs();
  numberSigner = 0;
  canAddSigner = true;
  public signersProvider: {key: string, value: number}[];
  public watchersProvider: {key: string, value: number}[];
  public sprofileProvider: {key: string, value: string}[];
  public selectedSignersArray: EnduserData[];
  public selectedWatchersArray: EnduserData[];
  private sprofileList: SprofileData[];
  public loadingSprofile = true;
  public watchersDisabled = false;
  public autoSigner = false;
  public autoSessionName = false;
  public sprofileFormatPades: SprofileData[] = [];
  public canAddDocument = true;
  formSub: Subscription;
  showSort = false;
  sortedUsers = false;
  listSignerWithoutOrder: EnduserData[] = [];
  submitting = false;

  constructor(public appService: AppService,
              public modal: NgbModal,
              public annotationService: AnnotationService,
              public placeholderService: PlaceholderService,
              private router: Router,
              private formBuilder: FormBuilder,
              private sessionService: SessionService,
              private stepService: StepService,
              public documentService: DocumentService,
              public enduserService: EnduserService,
              public alertService: AlertService,
              private translate: TranslateService,
              private spinnerService: NgxSpinnerService,
              public stepEnduserService: StepEnduserService,
              private stepRuleOrderService: StepRuleOrderService,
              private sproService: SprofileService,
              private configurationService: ConfigurationService,
              private navigationService: NavigationService,
              public signatureWorkflowService: SignatureWorkflowService,
              public wizardDocumentStoreService: WizardDocumentStoreService) {
    super(modal, appService, annotationService, alertService, documentService, placeholderService, stepEnduserService, signatureWorkflowService, wizardDocumentStoreService);

    this.FILE_SIZE_LIMIT = environment.FILE_SIZE_LIMIT;

  }

  ngOnInit() {
    this.configurationService.tenantConfig
      .then(tenantConfig => {
        const watchersDisabled: string = tenantConfig[CONFIG.TENANT.QUICK_SESSION.DISABLE_WATCHERS.KEY];
        this.watchersDisabled = new Strings(watchersDisabled).asBoolean();
        const autoSigner: string = tenantConfig[CONFIG.TENANT.QUICK_SESSION.AUTO_SIGNER.KEY];
        this.autoSigner = new Strings(autoSigner).asBoolean();
        const autoSessionName: string = tenantConfig[CONFIG.TENANT.QUICK_SESSION.AUTO_SESSION_NAME.KEY];
        this.autoSessionName = new Strings(autoSessionName).asBoolean();
        let sessionName_ = '';
        if (this.autoSessionName) {
          sessionName_ = 'Session: ' + new Date(Date.now()).toISOString();
        }
        this.initTuto();
        this.initTuto();
        this.createProviders();
        this.initArrays();
        this.initForm(sessionName_);

        this.sprofileList = [];
        if (this.appService.currentEnduser == undefined) {
          this.enduserService.getCurrentEnduser().toPromise()
            .then(enduser => {
              if (this.autoSigner) {
                this.addSigner(enduser); // fixme: to be fixed by Intech, to be removed (ask Bob first)
              }
            }, () => undefined);
        } else {
          if (this.autoSigner) {
            this.addSigner(this.appService.currentEnduser); // fixme: to be fixed by Intech, to be removed (ask Bob first)
          }
        }

        this.formSub = this.newSessionForm.valueChanges.subscribe(values => {
          if (values.selectedSigner && !find(this.selectedSignersArray, (signer => values.selectedSigner.value === signer.id))) {
            this.enduserService.getEnduser(values.selectedSigner.value).subscribe(enduser => this.addSigner(enduser), (error: ApiError) => this.alertService.errorApi(error));
          }

          if (values.selectedWatcher && !find(this.selectedWatchersArray, (watcher => values.selectedWatcher.value === watcher.id))) {
            this.enduserService.getEnduser(values.selectedWatcher.value).subscribe(enduser => this.addWatcher(enduser), (error: ApiError) => this.alertService.errorApi(error));
          }
        });
      });
    super.ngOnInit();
  }

  initArrays() {
    this.selectedSignersArray = [];
    this.selectedWatchersArray = [];
  }

  initForm(sessionName: string) {
    this.newSessionForm = this.formBuilder.group({
      sessionName: ['', [Validators.required, ValidatorHelper.noWhitespace]],
      selectedSigner: [''],
      selectedWatcher: [''],
      newSignerFirstName: [''],
      newSignerLastName: [''],
      newSignerEmail: [''],
      newSignerConfirmEmail: [''],
      newSignerOrganisation: [''],
      documents: ['', [ValidatorHelper.fileSizeLimitValidator(this.FILE_SIZE_LIMIT)]],
      selectedDocuments: this.formBuilder.array([], Validators.required),
      selectedSProfile: ['', [Validators.required]]
    }, {
    });
    this.newSessionForm.get('sessionName').setValue(sessionName);
  }

  createProviders() {
    this.signersProvider = [];
    this.watchersProvider = [];
    this.sprofileProvider$().subscribe(sprofiles => {
      this.sprofileProvider = sprofiles;
      let orely = find(this.sprofileProvider, ((sprofile) => sprofile.value === 'ORELY_PADES'));
      if (!orely && sprofiles.length > 0) {
        orely = sprofiles[0];
      }
      this.newSessionForm.get('selectedSProfile').setValue(orely);
      this.loadingSprofile = false;
    });
  }

  reloadProviders(label: string, filter: string) {
    if (label === 'S') {
      this.signersProvider$(filter).subscribe(signers => {
        this.signersProvider = signers;
      });
    }
    if (label === 'W') {
      this.watchersProvider$(filter).subscribe(watchers => {
        this.watchersProvider = watchers;
      });
    }
  }

  introJSCreateASessionSetup() {
    this.introJSCreateSession.setOptions({
      exitOnOverlayClick: false,
      overlayOpacity: 0.5,
      prevLabel: this.translate.instant('NEW_SESSION.TUTORIAL.PREVLABEL'),
      nextLabel: this.translate.instant('NEW_SESSION.TUTORIAL.NEXTLABEL'),
      skipLabel: this.translate.instant('NEW_SESSION.TUTORIAL.SKIPLABEL'),
      doneLabel: this.translate.instant('NEW_SESSION.TUTORIAL.DONELABEL'),
      steps: [
        {
          intro: this.translate.instant('NEW_SESSION.TUTORIAL.INTRO')
        },
        {
          element: '#createSessionStep2',
          position: 'top',
          intro: this.translate.instant('NEW_SESSION.TUTORIAL.FIRSTSTEP')
        },
        {
          element: '#createSessionStep3',
          position: 'top',
          intro: this.translate.instant('NEW_SESSION.TUTORIAL.SECONDSTEP')
        },
        {
          disableInteraction: true,
          element: '#createSessionStep4',
          position: 'top',
          intro: this.translate.instant('NEW_SESSION.TUTORIAL.FOURSTEP')
        },
        {
          element: '#createSessionStep5',
          position: 'top',
          intro: this.translate.instant('NEW_SESSION.TUTORIAL.THIRDSTEP')
        },
        {
          element: '#createSessionStep6',
          position: 'top',
          intro: this.translate.instant('NEW_SESSION.TUTORIAL.FIFTHSTEP')
        },
        {
          element: '#createSessionStep7',
          intro: this.translate.instant('NEW_SESSION.TUTORIAL.SIXTHSTEP')
        },
        {
          element: '#createSessionStep8',
          intro: this.translate.instant('NEW_SESSION.TUTORIAL.SEVENSTEP')
        }
      ]
    });

  }

  get selectedWatchers(): EnduserData[] {
    return this.selectedWatchersArray;
  }

  get selectedDocumentsArray(): FormArray {
    return this.newSessionForm.get('selectedDocuments') as FormArray;
  }

  get selectedDocuments(): File[] {
    return this.selectedDocumentsArray.controls.map(c => c.value['document']);
  }

  selectedSigners(): EnduserData[] {
    let signers = [];
    this.selectedSignersArray.forEach(selectedSigner => {
      if (selectedSigner.circle) {
        if (!selectedSigner['expected']) {
          selectedSigner['expected'] = 1;
        }
          for (let i = 1; i < parseInt(selectedSigner['expected']) + 1; i++) {
            signers.push(this.createSignerCircle(selectedSigner, i));
          }
      } else {
        signers.push(selectedSigner);
      }
    });
    return signers;
  }

  /// SIGNERS
  signersProvider$ = (filter: string) => {
    return this.enduserService.searchEnduser(undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined, undefined,
      undefined, filter).pipe(map(users => users.filter(enduser => !this.selectedWatchersArray.some(
      watcher => watcher.id === enduser.id) && !this.selectedSignersArray.some(selectedSigner => selectedSigner.id === enduser.id)).map(signer => {
      let key =  signer.firstName ? signer.firstName : '';
      key += signer.lastName ? (key ? ' ' : '') + signer.lastName : '';
      key += signer.email ? (key ? ' ' : '') + ' (' + signer.email + ')' : '';
      key += signer.directoryAlias !== this.appService.currentEnduser.directoryAlias ? (key ? ' \'' : '') + signer.directoryAlias + '\'' : '';
      return {
        key,
        value : signer.id
      };
    })));
  }

  addSigner(signer: EnduserData) {
    if (this.canAddSigner) {
      this.selectedSignersArray.push(signer);
      this.listSignerWithoutOrder.push(signer);
    }
    this.numberSigner = this.selectedSignersArray.length;
  }

  removeSigner(signerToRemove: EnduserData) {
    remove(this.selectedSignersArray, (signer: EnduserData) => {
      if (signer.id && signerToRemove.id) {
        return signer.id === signerToRemove.id;
      }
      return signer.email === signerToRemove.email;
    });

    remove(this.listSignerWithoutOrder, (signer: EnduserData) => {
      if (signer.id && signerToRemove.id) {
        return signer.id === signerToRemove.id;
      }
      return signer.email === signerToRemove.email;
    });

    this.filesAcroformsPositions.forEach((positions, i) => {
      positions.forEach((positionsMetadata, idx) => {
        positionsMetadata.forEach((metadata, idx2) => {
          if (metadata.signer === signerToRemove) {
            this.filesAcroformsPositions.get(i)[idx].splice(idx2, 1);
          }
        });
      });
    });
  }

  updateNbOfRequiredSignersInCircle(event) {
    const circle = event.user;
    const nb = event.nb;
    const update = this.selectedSignersArray.find((signer) => signer.id === circle.id);
    update['expected'] = nb;
  }

  /// WATCHERS
  watchersProvider$ = (filter: string) => {
    return this.enduserService.searchEnduser(undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, filter).pipe(map(users => users.filter(
      enduser => enduser.id !== this.appService.getCurrentUserId() && !this.selectedWatchersArray.some(
        watcher => watcher.id === enduser.id) && !this.selectedSignersArray.some(signer => signer.id === enduser.id)).map(watcher => {
      const email = watcher.email ? watcher.email : '';
      const key = watcher.lastName ?  watcher.firstName + ' ' + watcher.lastName + ' (' + email + ')' : '' ;
      return {
        key,
        value : watcher.id
      };
    })));
  }

  addWatcher(watcher: EnduserData) {
    this.selectedWatchersArray.push(watcher);
  }

  removeWatcher(watcherToRemove: EnduserData) {
    remove(this.selectedWatchersArray, (watcher) => watcher.id === watcherToRemove.id);
  }

  getDocumentFileNameError(): string {
    const errors = this.newSessionForm.get('documents').errors;
    return errors && errors.sizeLimit && errors.sizeLimit.filename;
  }

  addDocuments(files: File[]) {
    this.canAddDocument = true;
    this.selectedDocumentsArray.markAllAsTouched();
    const errors = this.newSessionForm.get('documents').errors;
    if (!errors || (Object.keys(errors).length === 1 && this.newSessionForm.get('documents').hasError('atLeastOne'))) {
      files.forEach(f => {
        if (!this.selectedDocumentsArray.getRawValue().some((fs: { document: File }) => fs.document.name === f.name)) {
          this.selectedDocumentsArray.push(this.formBuilder.group({
            document: [f]
          }));
        }
      });
      this.checkFormatOfDocuments();
    }
  }

  removeDocument(document: File, index: number) {
    this.selectedDocumentsArray.markAllAsTouched();
    this.selectedDocumentsArray.removeAt(index);
    this.filesAcroformsPositions.delete(document.name);
    this.checkFormatOfDocuments();
  }

  /// SPROFILE
  sprofileProvider$ = () => this.sproService.getSprofileList().pipe(map(sprofileList => sprofileList.filter(s => s.format !== 'OTHER').map(sprofile => {
    if (sprofile.format === 'PADES') {this.sprofileFormatPades.push(sprofile); }
    this.sprofileList.push(sprofile);
    const key = sprofile.label;
    const value = sprofile.code;
    return {
      key,
      value
    };
  })))

  initTuto() {
    this.translate.get('NEW_SESSION.TUTORIAL.INTRO').subscribe((res) => {
      this.introJSCreateASessionSetup();
    });
    this.translate.onLangChange.subscribe(() => {
      this.introJSCreateASessionSetup();
    });
  }

  launchTuto() {
    this.introJSCreateSession.start();
  }

  onSubmit() {
    this.introJSCreateSession.exit();

    this.submitting= true;
    this.spinnerService.show();
    const payload: CreateSessionPayload = {
      label: this.newSessionForm.get('sessionName').value.trim(), simple: true
    };
    this.sessionService.createSession(payload).toPromise().then(session => {
      return this.stepService.getStepList(session.id).toPromise().then(steps => {
        const step = steps.find(stepF => stepF.category === StepData.CategoryEnum.OPERATION);
        this.stepId = step.id;
        this.sessionId = session.id;
        const sprofileSelected = find(this.sprofileList, (sprofile) => sprofile.code === this.newSessionForm.get('selectedSProfile').value.value);
        Promise.all(this.createDocuments(sprofileSelected)
          .concat(...this.createSigners())
          .concat(...this.createWatchers())).then(() => {
          return this.stepService.startStep(this.sessionId, this.stepId).toPromise().then(() => {
            this.navigationService.goToFirstStartedStepOrDocument(this.sessionId, this.stepId);
            this.spinnerService.hide();
            this.submitting= false;
            if (this.showSort) {
              this.orderUsers(this.selectedSignersArray);
            }
          });
        }).catch((error: ApiError) => {
          console.error(error);
          this.alertService.errorApi(error);
          this.sessionService.deleteSession(session.id).toPromise().catch(() => undefined);
          this.submitting= false;
          this.spinnerService.hide();
        });
      });
    }).catch(() => {
      this.router.navigate(['/dashboard']);
      this.submitting= false;
      this.spinnerService.hide();
    });
  }

  orderUsers(users: EnduserData[]) {
    this.stepRuleOrderService.updateStepEnduserOrder(this.sessionId, this.stepId, {
      orders: users.map((u, i) => ({
        enduserId: u.id, orderIndex: i
      }))
    }).subscribe();
  }

  openModalNewSigner() {
    const modalRef = this.modal.open(ModalNewSignerComponent, { backdrop: false });
    modalRef.componentInstance.newSignerModalResult.subscribe((result: EnduserData) => {
      if (!this.selectedSignersArray.some(selectedSigner => selectedSigner.email === result.email)) {
        this.selectedSignersArray.push(result);
        this.listSignerWithoutOrder.push(result);
      } else {
        this.alertService.error('NEW_SESSION.SIGNERS.NEW_SIGNERS.ERRORS.EXISTING_EMAIL');
      }
    });
  }

  addAcroform(file: File) {
    this.previewFile(file, file.name);
  }

  private createSigners(): Promise<any>[] {
    return uniqWith(this.selectedSigners(), (a,b) => {
      return a.id && b.id && a.id === b.id;
    }).map(signer => this.createSigner(signer));
  }

  private createSigner(enduser: EnduserData): Promise<any> {
    if (enduser.id && enduser.id !== this.appService.getCurrentUserId()) {
      return this.stepEnduserService.createStepEnduser(this.sessionId, this.stepId, {
        enduserId: enduser.id, signer: true, expected: enduser['expected']
      }).toPromise();
    } else if (enduser.id) {
      return this.stepEnduserService.partialUpdateStepEnduser(enduser.id, this.sessionId, this.stepId, {
        signer: true,
        expected: enduser['expected']
      }).toPromise();
    } else {
      return this.stepEnduserService.createStepEnduser(this.sessionId, this.stepId, {
        createEnduserPayload: {
          userId: enduser.email,
          email: enduser.email,
          lastName: enduser.lastName,
          firstName: enduser.firstName,
          phone: enduser.phone,
          organisationId: enduser.organisation.organisationId,
          businessName: enduser.organisation.name,
          directoryAlias: enduser.directoryAlias
        },
        signer: true,
        expected: enduser['expected']
      }).toPromise().then((result) => {
        for (const signer of this.selectedSignersArray) {
          if (this.isEgalEnduser(signer, enduser)) {
            signer.id = result.enduser.id;
          }
        }
      });
    }
  }

  private isEgalEnduser(signer: EnduserData, enduser: EnduserData): boolean {
    return (signer.email === enduser.email && signer.lastName === enduser.lastName
      && signer.firstName === enduser.firstName && signer.phone === enduser.phone);
  }

  private createWatchers(): Promise<any>[] {
    return this.selectedWatchers.map(watcher => this.createWatcher(watcher));
  }

  private createWatcher(enduser: EnduserData): Promise<any> {
    if (enduser.id !== this.appService.getCurrentUserId()) {
      return this.stepEnduserService.createStepEnduser(this.sessionId, this.stepId, {
        enduserId: enduser.id, signer: false
      }).toPromise();
    } else {
      return this.stepEnduserService.partialUpdateStepEnduser(enduser.id, this.sessionId, this.stepId, {
        signer: false
      }).toPromise();
    }
  }

  private createDocuments(sprofile: SprofileData): Promise<any>[] {
    return [new Promise((resolve, reject) => {
      this.createDocuments_(sprofile, this.selectedDocuments, resolve, reject);
    })];

  }

  /**
   * Recursive function, to invoke the document creation endpoint in order, and ensure document order presentation by ordering by ID
   * See https://signed.atlassian.net/browse/CI-954
   */
  private createDocuments_(sprofile: SprofileData, documents, resolve, reject): void {
      this.createDocument(sprofile, documents[0]).then(() => {
        const slice = documents.slice(1, documents.length);
        if(slice.length) {
          this.createDocuments_(sprofile, slice, resolve, reject);
        }
        else{
          resolve();
        }
      }).catch(reject);
  }


  private createDocument(sprofile: SprofileData, file: File): Promise<any> {
    // upload original and create orely pades
    const docObservable = this.documentService.uploadDocument(this.sessionId, this.stepId, file).toPromise().then((document: DocumentData) => {
      const createDocumentPayload: CreateDocumentPayload = {
        sourceDocumentId: document.id, sprofileCode: sprofile.code
      };
      return this.documentService.createDocument(this.sessionId, this.stepId, createDocumentPayload).toPromise();
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });

    // add acroforms if necessary
    if (this.hasAcroformForName(file.name)) {
      return docObservable.then(((padesDoc: DocumentData) => this.createAcroforms(file, padesDoc.id)
        .toPromise()
        .then(value => this.hasAnnotationForName(file.name) ? this.createAnnotation(this.sessionId, this.stepId, padesDoc, file).toPromise() : value)));
    } else {
      return docObservable.then(
        (padesDoc: DocumentData) => this.hasAnnotationForName(file.name) ? this.createAnnotation(this.sessionId, this.stepId, padesDoc, file)
          .toPromise() : padesDoc);
    }
  }

  public isFilePDF(fileName: string) {
    return fileName.split('.').pop().toLowerCase() === 'pdf';
  }

  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
    super.ngOnDestroy();
  }

  canUploadDocument(fileName: string): boolean {
    return this.sprofileHasFormatPades() && this.isFilePDF(fileName)
      || !this.sprofileHasFormatPades();
  }

  checkFormatOfDocuments() {
    this.canAddDocument = true;
    if (this.sprofileHasFormatPades) {
      for (let i = 0 ; i < this.selectedDocumentsArray.value.length ; i++) {
        if (!this.canUploadDocument(this.selectedDocumentsArray.value[i].document.name)) {
          this.canAddDocument = false;
          break;
        }
      }
    }
  }

  sprofileHasFormatPades(): boolean {
    return !!this.sprofileFormatPades.find(sprofile => sprofile.code === this.newSessionForm.get('selectedSProfile').value.value);
  }

  noUp(u: EnduserData) {
    const index = this.selectedSignersArray.findIndex(su => su.id === u.id);
    return index === 0;
  }

  noDown(u: EnduserData) {
    const index = this.selectedSignersArray.findIndex(su => su.id === u.id);
    return index >= this.selectedSignersArray.length - 1;
  }

  up(u: EnduserData) {
    const index = this.selectedSignersArray.findIndex(su => su.id === u.id);
    this.selectedSignersArray.splice(index - 1, 0, this.selectedSignersArray.splice(index, 1)[0]);
    this.sortedUsers = true;
  }

  down(u: EnduserData) {
    const index = this.selectedSignersArray.findIndex(su => su.id === u.id);
    this.selectedSignersArray.splice(index + 1, 0, this.selectedSignersArray.splice(index, 1)[0]);
    this.sortedUsers = true;
  }

  unsort() {
    this.showSort = false;
    this.sortedUsers = false;
    this.selectedSignersArray = [];
    this.selectedSignersArray = [...this.listSignerWithoutOrder];
    this.numberSigner = this.selectedSignersArray.length;
  }

  sort() {
    this.showSort = true;
  }

  updateField(value: string) {
    this.newSessionForm.get('sessionName').setValue(value);
  }
}
