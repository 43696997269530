import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

import {Alert, AlertType} from '../../../error/alert.model';
import {AlertService} from '../../../services/services/alert-service';

@Component({
  selector: 'alert', templateUrl: './alert.component.html', styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  constructor(private alertService: AlertService, private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.subscription = this.alertService.getAlerts().subscribe((alert: Alert) => {
      if (alert) {
        switch (alert.type) {
          case AlertType.SUCCESS:
            this.toastrService.success(alert.message);
            break;
          case AlertType.ERROR:
            this.toastrService.error(alert.reference, alert.message);
            break;
          case AlertType.INFO:
            this.toastrService.info(alert.message);
            break;
          case AlertType.WARNING:
            this.toastrService.warning(alert.message);
            break;
          default:
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
