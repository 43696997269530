export class Alert {
  type: AlertType;
  message: string;
  reference: string;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  SUCCESS, ERROR, INFO, WARNING
}
