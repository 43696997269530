/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tutoriel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tutoriel.component";
var styles_TutorielComponent = [i0.styles];
var RenderType_TutorielComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TutorielComponent, data: {} });
export { RenderType_TutorielComponent as RenderType_TutorielComponent };
export function View_TutorielComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "tutoButton"], ["id", "tutoButtonId"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.launchTuto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-book-open"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showTuto; _ck(_v, 0, 0, currVal_0); }); }
export function View_TutorielComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tutoriel", [], null, null, null, View_TutorielComponent_0, RenderType_TutorielComponent)), i1.ɵdid(1, 114688, null, 0, i2.TutorielComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TutorielComponentNgFactory = i1.ɵccf("app-tutoriel", i2.TutorielComponent, View_TutorielComponent_Host_0, {}, { launchTutoEmit: "launchTutoEmit" }, []);
export { TutorielComponentNgFactory as TutorielComponentNgFactory };
