<span *ngFor=" let signer of signers let i = index" class="d-inline-block mr-2">
  <span class="box-info mt-2 p-2 d-flex justify-content-center align-items-center">
    <span class="align-self-center text-secondary flex-grow-1 left">
      {{'USER_PROFILE.SIGNATURE_DELEGATION.DELEGATED_TO' | translate}}<span
      class="text-body">{{(signer.circle) ? patchCircleName(signer.lastName) : signer.firstName
        + ' ' + signer.lastName}}</span> <br/>
      {{'USER_PROFILE.SIGNATURE_DELEGATION.FROM' | translate}} <span
      class="text-body">{{dates[i].from}}</span> {{'USER_PROFILE.SIGNATURE_DELEGATION.TO' | translate}}
      <span class="text-body">{{dates[i].to}}</span>.
    </span>
     <div class="flex-grow-0">
    <button (click)="deleteDelegateEvent.emit(i)" [disabled]="disabled"
            class="btn btn-sm btn-danger align-self-center ml-2" type="button">
      <i aria-hidden="true" class="fa fa-trash"></i>
    </button>
     </div>
  </span>
</span>
