import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService, UpdateOrganisationPayload } from 'luxtrust-cosi-api';
import { REGEXES } from 'src/app/app.constant';
import { AlertService } from 'src/app/services/services/alert-service';
import {ApiError} from "../../../error/api-error.model";

@Component({
    templateUrl: './modal-new-organisation.component.html', styleUrls: ['./modal-new-organisation.component.scss']
})
export class ModalNewOrganisationComponent implements OnInit {

    private readonly directoryAlias: string;
    public newOrganisationForm: FormGroup;

    constructor(private readonly organisationService: OrganisationService,
                private readonly formBuilder: FormBuilder,
                private readonly activeModal: NgbActiveModal,
                private readonly alertService: AlertService) {
    }

    ngOnInit() {
        this.newOrganisationForm = this.formBuilder.group({
            organizationName: ['', [Validators.required]],
            organizationEmail: ['', [Validators.pattern(REGEXES.EMAIL)]
            ]
          });
    }
    onSubmit() {
        const organizationNewName: UpdateOrganisationPayload = {
            name: this.newOrganisationForm.get('organizationName').value,
            directoryAlias: this.directoryAlias,
            emailSender: this.newOrganisationForm.get('organizationEmail').value
        };
        this.organisationService.createOrganisation(organizationNewName).toPromise().then(() => {
            this.alertService.success('ORGANIZATION.CREATE_ORGANIZATION');
            this.activeModal.close('add organization');
        }).catch((error: ApiError) => {
          this.alertService.errorApi(error);
        });
    }

    hasEmailError(): boolean {
      return this.newOrganisationForm.controls.organizationEmail.hasError('pattern');
    }

    onClose() {
        this.activeModal.close('Cross click');
    }

}
