<div *ngIf="isBefore" class="error-message">{{'DOCUMENT.MESSAGE_DATE_BEFORE' | translate}}
  {{isBeforeOrAfterDate | date: 'short' : undefined : translateService.currentLang}}</div>
<div *ngIf="isAfter" class="error-message">{{'DOCUMENT.MESSAGE_DATE_AFTER' | translate}}
  {{isBeforeOrAfterDate | date: 'short' : undefined : translateService.currentLang}}</div>


<div class="positionButtonLeft">
  <button (click)="goDocuments()" class="btn btn-primary mr-2 mt-2 text-truncate positionLeft" type="button" *ngIf="displayGoToTheDocument"
          [disabled]="disabled || signing || rejecting || reinstating || cancelling || continuing || suspending">
    {{'SIGNATURE_BOOK.GO_TO_DOCUMENT'| translate}}
    <i aria-hidden="true" class="fa fa-lg fa-fw fa-arrow-right"></i>
  </button>
  <button (click)="onDownloadDocument()" *ngIf="!disableDownloadDoc" class="btn btn-primary mr-2 mt-2"
          [disabled]="disabled || signing || rejecting || reinstating || cancelling || continuing || suspending">
    <span class="fa fa-cloud-download-alt mr-2"></span>
    <span title="{{'DOCUMENT.DOWNLOAD' | translate}}"> {{'DOCUMENT.DOWNLOAD' | translate}} </span>
  </button>

  <input id="reuploadDoc" [disabled]="disabled || signing || rejecting || reinstating || cancelling || continuing || suspending"
         (change)="reupload($event)" class="input-file" type="file"/>
  <label for="reuploadDoc" *ngIf="enableReplaceDocument && displayReuploadDoc && displayAfterNoRedirection && document.revised === 0"
         class="btn btn-primary mr-2 mt-2" [class.disabled]="disabled || signing || rejecting || reinstating || cancelling || continuing || suspending">
    <span class="fa fa-cloud-upload-alt mr-2"></span>
    <span title="{{'DOCUMENT.REUPLOAD' | translate}}"> {{'DOCUMENT.REUPLOAD' | translate}} </span>
  </label>

  <span *ngIf="enableSignatures()">
    <button id="id-position-signature" (click)="addAcroform.emit()"
            class="btn btn-primary mr-2 mt-2 ">
        <i aria-hidden="true" class="fa fa-file-signature  mr-2"></i>
      {{'WIZARD.DOCUMENTS.SIGNATURE' | translate}}
        </button>
  </span>
</div>

<div *ngIf="displayAfterNoRedirection">
  <ng-container *ngFor="let action of actions">
    <div class="doc-action-message error-message link-error-message"
         *ngIf="(action === 'SIGN' || action === 'VISA') && (hideSign && (!isAfter && !isBefore))" (click)="goToMissingMetadata()">
      <i class="fas fa-info-circle mr-1"></i>{{'DOCUMENT.MISSING_MANDATORY' | translate}}</div>
    <button (click)="handleActions(action)"[hidden]="((action === 'SIGN' || action === 'VISA') && (hideSign))
                      || ((action === 'SIGN' || action === 'VISA' || action === 'REJECT') && (isAfter || isBefore))"
            [disabled]="disabled || signing || rejecting || reinstating || cancelling || continuing || suspending
            || ((action === 'SIGN' || action === 'VISA') && signDisabled) || (action === 'REJECT' && rejectDisabled)"
            class="btn mr-2 mt-2 desktop" [ngClass]=" {'sign' : action === 'SIGN' || action === 'VISA' || action === 'REINSTATE' ||  action === 'CONTINUE',
            'reject': action === 'REJECT',
            'cancel' : action === 'CANCEL',
            'suspend': action === 'SUSPEND'}"
            id="{{action + '-desktop'}}">{{'DOCUMENT.ACTION.' + action | translate}}</button>
  </ng-container>
</div>
<div class="doc-action-message link-message-info mt-2 pr-3" *ngIf="hasDocumentsToView" (click)="goToDocumentsToView()">
  <i class="fas fa-info-circle mr-1"></i>{{'DOCUMENT.GO_TO_DOCUMENTS_TO_VIEW' | translate}}
</div>
<div class="doc-action-message link-message-info mt-2 pr-3" *ngIf="hasDocumentsToSign" (click)="goToDocumentsToSign()">
  <i class="fas fa-info-circle mr-1"></i>{{'DOCUMENT.GO_TO_DOCUMENTS_TO_SIGN' | translate}}
</div>

<div class="row w-100">
  <div  *ngIf="isRejectedOnce"  class="text-left text-xs-center danger mt-2" [ngClass]="canCancelAndSuspendSession ? 'col-6' : 'info-position'">
    {{'DOCUMENT.REJECTED_ONCE' | translate}}
  </div>
  <div  *ngIf="!isRejectedOnce && mustWaitOthers" class="text-left text-xs-center danger mt-2" [ngClass]="canCancelAndSuspendSession ? 'col-6' : 'info-position'">
    {{'DOCUMENT.MUST_WAIT' | translate}}
  </div>
  <div *ngIf="!isRejectedOnce && !mustWaitOthers && canCancelAndSuspendSession" class="col-6">
  </div>
  <div class="has-metadata-message mt-2 pr-0" *ngIf="hasTags" [ngClass]="canCancelAndSuspendSession ? 'col-6' : 'info-position text-left'">
    <i class="fas fa-info-circle mr-1"></i>{{'DOCUMENT.HAS_METADATA' | translate}}
  </div>
</div>



<app-modal [opened]="openOrNotModalCancel || openOrNotModalSuspend"
           [titleModal]="(openOrNotModalCancel ? 'DOCUMENT.CONFIRM_CANCEL' : 'DOCUMENT.CONFIRM_SUSPEND') | translate"
           [design]="'custom'"
           [disabled]="cancelling || suspending"
           [messageModal]="(openOrNotModalCancel ? 'DOCUMENT.MESSAGE_MODAL_CANCEL' : 'DOCUMENT.MESSAGE_MODAL_SUSPEND') | translate"
           (delete)=" openOrNotModalCancel ? cancel() : suspend()"
           (closeModal)="openOrNotModalCancel ? openOrNotModalCancel = false : openOrNotModalSuspend = false">
</app-modal>
