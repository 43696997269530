import { SignatureBookService } from 'luxtrust-cosi-api/api/signatureBook.service';
import { SignatureBookData } from 'luxtrust-cosi-api/model/signatureBookData';
import { from } from 'rxjs';
import { filter as filterRx, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { AlertService } from '../../services/services/alert-service';
import { AppService } from '../../services/services/app.service';
import { Store } from '../models/store';
import * as i0 from "@angular/core";
import * as i1 from "../../../../luxtrust-cosi-api/api/signatureBook.service";
import * as i2 from "../../services/services/app.service";
import * as i3 from "../../services/services/alert-service";
export class SignatureBookStoreService extends Store {
    constructor(signatureBookService, appService, alertService) {
        super();
        this.signatureBookService = signatureBookService;
        this.appService = appService;
        this.alertService = alertService;
        this.pageSizeInit = 10;
        this.offsetInit = 0;
    }
    init() {
        this.fetch();
    }
    fetch(search) {
        if (!this.getAll() || (this.getAll() && this.getAll().enableCallWhenScrolling)) {
            let limit = this.pageSizeInit;
            let offset = this.offsetInit;
            if (this.getAll() && this.getAll().offset) {
                offset = this.getAll().offset;
            }
            if (this.getAll() && this.getAll().limit) {
                limit = this.getAll().limit;
            }
            let text = '';
            if (this.getAll() && this.getAll().text) {
                text = this.getAll().text;
            }
            let asc = false;
            if (this.getAll() && this.getAll().asc) {
                asc = this.getAll().asc;
            }
            const filter = {
                id: this.appService.getCurrentUserId(), limit: limit, offset: offset, text: text, asc: asc
            };
            this.getData(filter).pipe(mergeMap((signatureBookData) => from(signatureBookData)), filterRx((signatureBookData) => {
                return signatureBookData.status === SignatureBookData.StatusEnum.STARTED || signatureBookData.status === SignatureBookData.StatusEnum.DECLINED;
            }), toArray(), tap((res) => {
                let items = res;
                if (!search && this.getAll() && this.getAll().items) {
                    items = [...this.getAll().items, ...res];
                }
                this.store(Object.assign({}, this.getAll(), { limit: limit, offset: offset + limit, items: items }));
                this.getAll().enableCallWhenScrolling = !(res.length < this.getAll().limit);
            })).toPromise().catch((error) => this.alertService.errorApi(error));
        }
    }
    reset() {
        this.store(undefined);
    }
    search(text, sortAsc) {
        this.getAll().enableCallWhenScrolling = true;
        this.getAll().offset = 0;
        this.getAll().text = text;
        this.getAll().asc = sortAsc;
        this.fetch(true);
    }
    removeItem(itemToRemove) {
        const tempItems = this.getAll().items;
        const index = tempItems.findIndex((item) => item.document.id === itemToRemove.document.id);
        if (index > -1) {
            tempItems.splice(index, 1);
            this.store(Object.assign({}, this.getAll(), { items: tempItems }));
        }
    }
    incrementCommentCounter(sessionId, stepId) {
        let items = this.getAll().items;
        const allIndexToUpdate = [];
        items = items.filter((item, index) => {
            if (item.sessionId === sessionId && stepId === stepId) {
                allIndexToUpdate.push(index);
            }
        });
        allIndexToUpdate.forEach(index => {
            items[index].additionalSession.commentNumber++;
        });
        this.store(Object.assign({}, this.getAll(), { items: items }));
    }
    changeDocumentStatus(itemToRemove, status) {
        const items = [...this.getAll().items];
        const index = items.findIndex((signatureData) => signatureData.document.id === itemToRemove.document.id);
        if (index > -1) {
            const currentUserIndex = this.currentSignerDataIndex(items[index].signatureBookSignerList);
            if (currentUserIndex > -1) {
                items[index].signatureBookSignerList[currentUserIndex].signatureStatus = status;
            }
        }
        this.store(Object.assign({}, this.getAll(), { items: items }));
    }
    getData(filter) {
        return this.signatureBookService.getSignatureBookList(filter).pipe(take(1));
    }
    currentSignerDataIndex(signersData) {
        return signersData.findIndex((signerData) => signerData.stepEnduser.enduser.id === this.appService.getCurrentUserId());
    }
}
SignatureBookStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignatureBookStoreService_Factory() { return new SignatureBookStoreService(i0.ɵɵinject(i1.SignatureBookService), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.AlertService)); }, token: SignatureBookStoreService, providedIn: "root" });
