<div class="d-flex justify-content-center" [ngClass]="{'ms-ie-fake-mb': isIE}">
  <div class="flex-grow-1">
    <form class="row">

      <div id="visiteDashboardStep6" [formGroup]="searchForm" class="col-11">
        <!-- Search input -->
        <div class="form-row">
          <app-input-text [hasOnInput]="true"
                          [idPostfix]="'searchInput'"
                          [id]="'searchInput'"
                          [placeholder]="'DASHBOARD.SEARCH.PLACEHOLDER' | translate"
                          autoFocus
                          class="col"
                          formControlName="searchInput"
                          icon="search"></app-input-text>
        </div>
      </div>

      <div class="col-11 mb-3" *ngIf="!template">
        <div (click)="toggleAdvancedSearch()"
             class="advanced-search">
          {{'DASHBOARD.ADVANCED_SEARCH' | translate}}
          <i id="visiteDashboardStep7" *ngIf="!advanceSearch" class="fas fa-chevron-down"></i>
          <i *ngIf="advanceSearch" class="fas fa-chevron-up"></i>
        </div>
        <ng-container *ngIf="advanceSearch">
          <div class="flex-grow-1 mt-2">

            <div [formGroup]="searchForm" class="col-12 pl-0 pr-0">
              <!-- Signature Status dropdown -->
              <div class="form-row" *ngIf="statusSignatureProvider">

                <app-autocomplete-custom [placeholder]="'DASHBOARD.SEARCH.STATUS.TITLE' | translate"
                                         [datasProviders]="statusSignatureProvider"
                                         [isFilter]="true"
                                         class="col"
                                         formControlName="signatureStatus"
                                         icon="id-card">

                </app-autocomplete-custom>
              </div>
            </div>

            <div [formGroup]="searchForm" class="col-12 pl-0 pr-0">
              <!-- Sessions Status dropdown -->
              <div class="form-row" *ngIf="statusProvider">

                <app-autocomplete-custom [placeholder]="'DASHBOARD.SEARCH.STATUS.SESSION_TITLE' | translate"
                                         [datasProviders]="statusProvider"
                                         [isFilter]="true"
                                         class="col"
                                         formControlName="sessionStatus"
                                         icon="id-card">

                </app-autocomplete-custom>

              </div>
            </div>

            <div [formGroup]="searchForm" class="col-12 pl-0 pr-0">
              <!-- Priority dropdown -->
              <div class="form-row" *ngIf="priorityProvider">

                <app-autocomplete-custom [placeholder]="'DASHBOARD.SEARCH.PRIORITY.TITLE' | translate"
                                         [datasProviders]="priorityProvider"
                                         [isFilter]="true"
                                         class="col"
                                         formControlName="priority"
                                         icon="id-card">

                </app-autocomplete-custom>

              </div>
            </div>
            <div class="col-12 pl-0 pr-0">
              <div class="form-row">
                <app-date-time-picker (ngModelChange)="beforeDate()"
                                      [(ngModel)]="createdBefore"
                                      [dateOnly]="true"
                                      [disabled]="template"
                                      [maxDate]="createdBeforeMax"
                                      [placeholder]="'DASHBOARD.SEARCH.BEFORE' | translate"
                                      [value]="createdBefore ? createdBefore : ''"
                                      class="col"
                                      [idPostfix]="'beforeDate'"
                                      name="beforeDate"></app-date-time-picker>
              </div>
            </div>
            <div class="col-12 pl-0 pr-0">
              <div class="form-row">
                <app-date-time-picker (ngModelChange)="afterDate()"
                                      [(ngModel)]="createdAfter"
                                      [dateOnly]="true"
                                      [disabled]="template"
                                      [maxDate]="createdAfterMax"
                                      [placeholder]="'DASHBOARD.SEARCH.AFTER' | translate"
                                      [placement]="'bottom-right'"
                                      [value]="createdAfter ? createdAfter : ''"
                                      class="col"
                                      [idPostfix]="'afterDate'"
                                      name="afterDate"></app-date-time-picker>
              </div>
            </div>
            <div [formGroup]="searchForm" class="col-12 pl-0 pr-0">
              <div class="form-row">
                <app-input-text
                                [hasOnInput]="true"
                                [idPostfix]="'searchDoc'"
                                [id]="'documentNameInput'"
                                [placeholder]="'DASHBOARD.SEARCH.DOCUMENT' | translate"
                                class="col"
                                formControlName="documentName"
                                icon="file"></app-input-text>
              </div>
            </div>
            <div [formGroup]="searchForm" class="col-12 pl-0 pr-0">
              <div class="form-row" *ngIf="tagProvider">

                <app-autocomplete-custom [placeholder]="'DASHBOARD.SEARCH.META' | translate"
                                         [datasProviders]="tagProvider"
                                         [isFilter]="true"
                                         class="col"
                                         formControlName="stepMeta"
                                         icon="key">

                </app-autocomplete-custom>

              </div>
            </div>
            <div [formGroup]="searchForm" class="col-12 pl-0 pr-0">
              <div class="form-row">
                <app-input-text
                  [hasOnInput]="true"
                  [idPostfix]="'stepMeta'"
                  [id]="'stepMetaValueInput'"
                  [placeholder]="'DASHBOARD.SEARCH.META_VALUE' | translate"
                  class="col"
                  formControlName="stepMetaValue"
                  icon="tag"></app-input-text>
              </div>
            </div>
            <div [formGroup]="searchForm" class="col-12 pl-0 pr-0">
              <div class="form-row">
                <app-endusers-table
                  [search]="true"
                  (addEnduser)="addUsers($event)"
                  (outputSearch)="onSearchUsers($event)"
                  (removeEnduser)="selectedUser = undefined;
                    sessionFilter.enduserIds = undefined; filterChangedEvent.emit(sessionFilter)"
                  [availableEndusers]="availableUsers"
                  [loaderStatus]="statusUsers"
                  [noResult]="'WIZARD.USERS.NO_RESULT' | translate"
                  [placeholder]="'WIZARD.USERS.SEARCH' | translate"
                  [selectedEndusers]="selectedUser && [selectedUser] || []"
                  [singleList]="true"
                  [title]="'USER.USER' | translate"
                  class="col input-size"
                ></app-endusers-table>
              </div>
            </div>
          </div>

        </ng-container>
      </div>

    </form>

    <div *ngIf="appService.userHasEntitlement(appService.ent.manage_template)" [ngClass]="{'ms-ie-left': isIE}">
      <div class="switcher switcher-right ml-0 size-label position-switcher">
        <input (change)="onTemplateChanged()"
               [(ngModel)]="template"
               [ngModelOptions]="{standalone: true}"
               class="switcher-checkbox"
               id="checTemplate"
               type="checkbox">
        <div class="switcher-toggle"></div>
        <label for="checTemplate">{{'DASHBOARD.SEARCH.TEMPLATE' | translate}}</label>
      </div>

      <input
        #importTemplate
        id="importTemplateId"
        (change)="doImport($event.target.files)"
        [multiple]="false"
        accept="application/json,.json"
        style="display: none;"
        type="file"
        [disabled]="submitting"
      >
      <label for="importTemplateId" class="btn btn-primary" [class.disabled]="submitting"><i class="fa fa-upload"></i> {{'IMPORT' | translate}}</label>

    </div>
    <!-- TODO set the right entitlement -->
    <div *ngIf="appService.userHasEntitlement(appService.ent.get_audit_trail)" [ngClass]="{'ms-ie-left': isIE}">
      <input
        #retrieveAuditTrails
        id="retrieveAuditTrailsId"
        (change)="doRetrieveAuditTrails($event.target.files)"
        [multiple]="false"
        style="display: none;"
        type="file"
      >
      <label for="retrieveAuditTrailsId" class="btn btn-primary"><i class="fa fa-upload"></i> {{'RETRIEVE_AUDIT_TRAILS' | translate}}</label>
    </div>
  </div>
</div>
