/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * New user information
 */
export interface SignupPayload { 
    /**
     * A map of attributes related to the enduser
     */
    attributes?: { [key: string]: string; };
    /**
     * Organisation name
     */
    businessName?: string;
    /**
     * Email
     */
    email?: string;
    /**
     * First name
     */
    firstName?: string;
    /**
     * Last name
     */
    lastName?: string;
    /**
     * Locale code
     */
    localeCode?: SignupPayload.LocaleCodeEnum;
    /**
     * Password
     */
    password?: string;
    /**
     * Phone number
     */
    phone?: string;
    /**
     * Initial preferences of the user
     */
    preferences?: string;
    /**
     * Tenant name if known
     */
    tenantName?: string;
    /**
     * Username
     */
    username?: string;
}
export namespace SignupPayload {
    export type LocaleCodeEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LocaleCodeEnum = {
        EN: 'EN' as LocaleCodeEnum,
        FR: 'FR' as LocaleCodeEnum,
        DE: 'DE' as LocaleCodeEnum,
        PT: 'PT' as LocaleCodeEnum,
        IT: 'IT' as LocaleCodeEnum,
        NL: 'NL' as LocaleCodeEnum,
        SP: 'SP' as LocaleCodeEnum
    };
}
