<div *ngIf="legalMotions && legalMotions?.length !== 0">

  <ng-container *ngIf="mandatoryLegalMotion.length > 0">
    <h2 class="title-legal-notices">{{'LEGAL_MOTIONS.MANDATORY' | translate}}</h2>
    <hr>
    <ul class="motionList">
      <li *ngFor="let motion of mandatoryLegalMotion; let i = index" class="ml-0 motionList-item">
        <!-- FIXME when enduser available on SignatureBookData -->
        <div class="form-group form-check pl-0">
          <div class="switcher switcher-left ml-0" *ngIf="isSigner && !alreadySigned">
            <label for="{{'motion-m' + i}}">{{motion.stepLegal.legal.title}}</label>
            <input (change)="check(motion)"
                   [(ngModel)]=motion.checked
                   class="switcher-checkbox"
                   id="{{'motion-m' + i}}"
                   name="{{'motion-m' + i}}"
                   type="checkbox">
            <div *ngIf="!alreadySigned" class="switcher-toggle"></div>
          </div>
          <div class="switcher ml-0" *ngIf="!isSigner || isSigner && alreadySigned">
            <label for="{{'motion-m' + i}}"> {{motion.stepLegal.legal.title}}</label>
          </div>
        </div>
        <div class="form-group form-check pl-0">
          <label for="{{'motion-m' + i}}" class="w-100 break-words">{{motion.stepLegal.legal.text}}</label>
        </div>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="optionalLegalMotion.length > 0">
    <h2 class="title-legal-notices">{{'LEGAL_MOTIONS.OPTIONAL' | translate}}</h2>
    <hr>
    <ul class="motionList">
      <li *ngFor="let motion of optionalLegalMotion; let i = index" class="ml-0 motionList-item">
        <div class="form-group form-check pl-0">
          <div class="switcher switcher-left ml-0" *ngIf="isSigner && !alreadySigned">
            <label for="{{'motion-o' + i}}"> {{motion.stepLegal.legal.title}}</label>
            <input (change)="check(motion)"
                   [(ngModel)]=motion.checked
                   class="switcher-checkbox"
                   id="{{'motion-o' + i}}"
                   name="{{'motion-o' + i}}"
                   type="checkbox">
            <div *ngIf="!alreadySigned" class="switcher-toggle"></div>
          </div>
          <div class="switcher ml-0" *ngIf="!isSigner || isSigner && alreadySigned">
            <label for="{{'motion-o' + i}}"> {{motion.stepLegal.legal.title}}</label>
          </div>
        </div>
        <div class="form-group form-check pl-0">
          <label for="{{'motion-m' + i}}" class="w-100 break-words">{{motion.stepLegal.legal.text}}</label>
        </div>
      </li>
    </ul>
  </ng-container>
</div>
