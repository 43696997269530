<div *ngIf="stepLegalEndusers" class="document-container">

  <!-- Legal motions -->
  <ng-container *ngIf="selectedSignatureBook.document?.typology !== 'ORIGINAL'">
    <app-legal-motion-check-list
      (mandatoriesChecked)="onCheckLegalNotices($event)"
      *ngIf="stepLegalEndusers.length > 0"
      [alertWhenFilled]="true"
      [alreadySigned]="alreadySignedStep"
      [isSigner]="true"
      [legalMotions]="stepLegalEndusers"
      [sessionId]="selectedSignatureBook.sessionId"
      [stepId]="selectedSignatureBook.stepId">
    </app-legal-motion-check-list>
  </ng-container>

  <div class="mb-5"></div>

  <!-- Document action, sign, reject, ... -->
  <div class="text-right alignSentenceReject">
    <app-document-actions *ngIf="step"
                          (addAcroform)="addAcroform(this.selectedSignatureBook.document, page, true)"
                          [displayReuploadDoc]="currentStepEnduser.manager"
                          [canLoadSignature]="!isSignDisabled && !alreadySignedStep && step?.skipAcroforms"
                          (downloadDocument)="downloadDocument()"
                          (afterAction)="notifyWhenActionOnDocument($event)"
                          [disabled]="reuploading"
                          [document]="selectedSignatureBook.document"
                          [sessionId]="selectedSignatureBook.sessionId"
                          [displayGoToTheDocument]="true"
                          (reuploadDocEvent)="onReuploadDoc($event)"
                          [signDisabled]="isSignDisabled  || isRejectedOnce   || (step?.skipAcroforms && !acroformDefined )"
                          [signatureId]="currentSignerData.signatureId"
                          [signatureStatus]="currentSignerData.signatureStatus"
                          [sprofile]="sprofileCode"
                          [stepEnduser]="currentStepEnduser"
                          [step]="step"
                          [stepStatus]="selectedSignatureBook.status"
                          [hideSign]="missingMandatoryValues?.length > 0"
                          [hasTags]="hasNonSystemTags"
                          [isAfter]="isAfter"
                          [isBefore]="isBefore"
                          [isBeforeOrAfterDate]="isBeforeOrAfterDate"
                          [isRejectedOnce]="isRejectedOnce"
                          [documentRejectActionButtonEnabled]="documentRejectActionButtonEnabled"
                          [documentCancelActionButtonEnabled]="documentCancelActionButtonEnabled"
                          [documentSuspendActionButtonEnabled]="documentSuspendActionButtonEnabled">
    </app-document-actions>
  </div>

  <div class="mb-3"></div>

  <!-- Show the PDF -->
  <app-pdf-viewer
    *ngIf="annotations && currentSigner "
    (dataOnDocument)="dataOnDocument($event)"
    (changePage)="changePage($event)"
    [annotations]="annotations"
    [fileToUpload]="fileToUpload"
    [document]="selectedSignatureBook.document"
    [forbidReUpload]="!currentStepEnduser.manager"
    [sessionId]="selectedSignatureBook.sessionId"
    [action]="action"
    [stepId]="selectedSignatureBook.stepId">
  </app-pdf-viewer>

  <div class="mb-4"></div>

  <!--Step Tags -->
  <step-metadata *ngIf = "step?.tags?.length > 0"
                 [stepTags]="step?.tags"
                 [stepId]="selectedSignatureBook.stepId"
                 [sessionId]="selectedSignatureBook.sessionId"
                 [alreadySigned]="alreadySignedStep"
  ></step-metadata>

  <div class="mb-4"></div>

  <app-comment-list id="commentSection" *ngIf="selectedSignatureBook?.stepId && selectedSignatureBook?.sessionId"
                    [sessionId]="selectedSignatureBook?.sessionId"
                    [refreshComment]="refreshComment"
                    (addNewComment)="notifyWhenAddComment()"
                    (removeComment)="removeComment()"
                    [step]="step">
  </app-comment-list>

  <div class="mb-4"></div>
</div>
