/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Information needed to create a step
 */
export interface CreateStepPayload { 
    /**
     * Indicates if the step is started automatically. Default value is false
     */
    auto?: boolean;
    /**
     * Step label
     */
    label?: string;
    /**
     * Alias of the rule to use for gate
     */
    ruleAlias?: string;
    /**
     * Rule to use for gate
     */
    ruleIndex?: number;
    /**
     * Rule replacements
     */
    ruleReplacements?: any;
    /**
     * Rule tag map (parameters)
     */
    ruleTagMap?: any;
    /**
     * Whether to skip acroform creation. Default value is false
     */
    skipAcroforms?: boolean;
    /**
     * Bulk step profile
     */
    sprofileCode?: string;
    /**
     * Step type
     */
    type?: CreateStepPayload.TypeEnum;
    /**
     * Step position on X axis
     */
    x?: number;
    /**
     * Step position on Y axis
     */
    y?: number;
}
export namespace CreateStepPayload {
    export type TypeEnum = 'END' | 'START' | 'GATE' | 'PREPARATION' | 'STEP_OPERATION' | 'SIGNATURE' | 'VALIDATION';
    export const TypeEnum = {
        END: 'END' as TypeEnum,
        START: 'START' as TypeEnum,
        GATE: 'GATE' as TypeEnum,
        PREPARATION: 'PREPARATION' as TypeEnum,
        STEPOPERATION: 'STEP_OPERATION' as TypeEnum,
        SIGNATURE: 'SIGNATURE' as TypeEnum,
        VALIDATION: 'VALIDATION' as TypeEnum
    };
}
