<div *ngIf="showTitle" class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'DOCUMENT.ANNOTATION_TITLE' | translate}}</h4>
</div>

<div class="modal-body">
  <div class="text-center mb-2 form-group">
    <label *ngIf="showDescription" class="float-left"
           for="annotation">{{'DOCUMENT.ANNOTATION_DESCRIPTION' | translate}}</label>
    <textarea [(ngModel)]="text" [disabled]="readOnly" class="form-control" id="annotation"
              maxlength="255"
              rows="5"></textarea>
    <div *ngIf="!readOnly" class="mt-2 text-right small" style="font-style: italic"> {{255
    - text.length - (text.split('\r?\n').length
        - 1)}} {{'DOCUMENT.REMAINING_CHARACTERS' | translate}}</div>
  </div>
</div>
<div class="modal-footer">
  <div *ngIf="!readOnly" class="text-center mb-3">
    <button (click)="activeModal.close(text)" [disabled]="!text.length" class="btn btn-primary mr-3"
            type="button">{{'DOCUMENT.ANNOTATION_ANNOTATE' | translate}}</button>
    <button (click)="deleteAnnotation()" class="btn btn-danger mr-3"
            type="button">{{'DOCUMENT.ANNOTATION_DELETE' | translate}}</button>
    <button (click)="activeModal.close(undefined)" class="btn mr-3"
            type="button">{{'DOCUMENT.ANNOTATION_CANCEL' | translate}}</button>
  </div>
  <div *ngIf="readOnly">
    <button (click)="activeModal.close(undefined)" class="btn mr-3 btn-sm"
            type="button">{{'DOCUMENT.ANNOTATION_CLOSE_MODAL' | translate}}</button>
  </div>
</div>
