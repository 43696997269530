/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-delegate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../core/pipes/translate.pipe";
import * as i3 from "../../../../core/service/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "./button-delegate.component";
import * as i7 from "../../../../services/services/app.service";
var styles_ButtonDelegateComponent = [i0.styles];
var RenderType_ButtonDelegateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonDelegateComponent, data: {} });
export { RenderType_ButtonDelegateComponent as RenderType_ButtonDelegateComponent };
function View_ButtonDelegateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.delegated ? i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("STEP.DELEGATED")) : i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("STEP.DELEGATE"))); _ck(_v, 1, 0, currVal_0); }); }
function View_ButtonDelegateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "button", [["class", "ml-2 mb-1 mt-1 btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_co.delegated ? _co.openDelegationModal() : undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 212992, null, 0, i5.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i5.NgbTooltipConfig, i1.NgZone, i4.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { disableTooltip: [0, "disableTooltip"], ngbTooltip: [1, "ngbTooltip"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-people-arrows"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonDelegateComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "ml-2 mb-1 mt-1 btn"; var currVal_2 = (_co.delegated ? "btn-outline-success" : "btn-primary"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.textButton; var currVal_4 = (_co.delegated ? i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform("STEP.DELEGATED")) : i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 5).transform("STEP.DELEGATE"))); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = _co.textButton; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.delegated; _ck(_v, 0, 0, currVal_0); }); }
export function View_ButtonDelegateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonDelegateComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.delegationEnable; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ButtonDelegateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button-delegate", [], null, null, null, View_ButtonDelegateComponent_0, RenderType_ButtonDelegateComponent)), i1.ɵdid(1, 573440, null, 0, i6.ButtonDelegateComponent, [i5.NgbModal, i7.AppService], null, null)], null, null); }
var ButtonDelegateComponentNgFactory = i1.ɵccf("button-delegate", i6.ButtonDelegateComponent, View_ButtonDelegateComponent_Host_0, { context: "context", textButton: "textButton", session: "session", step: "step", stepEnduserIds: "stepEnduserIds" }, { delegateCreated: "delegateCreated" }, []);
export { ButtonDelegateComponentNgFactory as ButtonDelegateComponentNgFactory };
