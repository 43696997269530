import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
import { share } from 'rxjs/operators';

export class EventBus<T> {
  private observable: Observable<T>;
  private observer: Observer<T>;

  constructor() {
    this.observable = new Observable<T>(obs => (this.observer = obs)).pipe(share());

    const subTemp: Subscription = this.subscribe(() => {});
    subTemp.unsubscribe();
  }

  public publish(value: T) {
    this.observer.next(value);
  }

  public subscribe(callback: (T) => void): Subscription {
    return this.observable.subscribe(callback);
  }
}
