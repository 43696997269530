<app-tooltip [tooltipText]="'HELP.USER_PROFILE' | translate"></app-tooltip>
<app-tutoriel (launchTutoEmit)="launchTuto()"></app-tutoriel>

<div class=" user-profile fixed-container">
  <div class="fixed-md">
    <h1 class="page-title user-title">{{'USER_PROFILE.TITLE' | translate}}</h1>
    <div class="form-row">
      <div id="visiteProfilStep1" class="user_informations">
        <h2>{{'USER_PROFILE.USER_INFORMATION.TITLE' | translate}}</h2>
        <div class="app-input-text">
          <div class="form-group">
            <app-input-loader [status]="'success'"></app-input-loader>
            <div class="input-group">
              <input [disabled]="true" [placeholder]="'USER_PROFILE.USER_INFORMATION.EMAIL' | translate"
                [value]="currentlyLoggedUser?.email" class="form-control" type="text" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <i aria-hidden="true" class="far fa-fw fa-envelope"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form [formGroup]="updateNumberPhoned">
          <div class="app-input-text">
            <div class="form-group">
              <app-input-loader [status]="'success'"></app-input-loader>
              <div class="input-group">
                <input #phoneNumber [placeholder]="'USER_PROFILE.USER_INFORMATION.PHONE' | translate"
                  formControlName="phoneNumber" class="form-control" type="text" (input)='updatePhone()' />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i aria-hidden="true" class="fa fa-fw fa-phone"></i>
                  </div>
                </div>
              </div>
              <span class="invalid-feedback customError"
                *ngIf="updateNumberPhoned.invalid">{{getErrorMessageUpdatePhoneNumber()}}</span>
            </div>
          </div>
          <div class="position">
            <button class="design btn btn-primary mb-2" (click)="editPhoneNumber()" [disabled]="updatingPhoneNumber"
              *ngIf="updateNumberPhoned.status === 'DISABLED'">{{'USER_PROFILE.USER_INFORMATION.EDIT_PHONE' | translate}}</button>
            <button class="design btn btn-primary mb-2" (click)="updatePhoneNumber()" [disabled]="updatingPhoneNumber"
              *ngIf="updateNumberPhoned.status !== 'DISABLED'">{{'USER_PROFILE.USER_INFORMATION.UPDATE_PHONE' | translate}}</button>
          </div>
        </form>
      </div>

      <hr class="separator">

      <h2>{{'USER_PROFILE.SIGNATURE_DELEGATION.TITLE' | translate}}</h2>
      <div id="visiteProfilStep2" class="delegate_informations">
        <div class="app-input-text">
          <div class="form-group">
            <app-input-loader [status]="'success'"></app-input-loader>
            <div class="input-group">
              <input (focus)="onFocusSignerInput(inputSigner)" (keydown)="signerKeydown($event)"
                (ngModelChange)="onChangeSignerInput($event)" [ngModel]="inputSigner"
                [placeholder]="'USER_PROFILE.SIGNATURE_DELEGATION.INPUT_DELEGATE' | translate" autocomplete="off"
                class="form-control" id="inputSigner" name="inputSigner" size="10" type="text" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <i aria-hidden="true" class="far fa-fw fa-user"></i>
                </div>
              </div>
            </div>
            <div [class.open]="inputSignerOpen" class="search-results">
              <ul class="list-group list-unstyled">
                <li *ngIf="!availableSigners || availableSigners.length === 0" class="empty">
                  {{'USER_PROFILE.SIGNATURE_DELEGATION.NO_RESULT' | translate}}
                </li>
                <li *ngFor="let signer of availableSigners; let i = index" [class.active]="i === activeSignerIndex"
                  class="row list-group-item result">
                  <a (click)="addSigner(signer)" class="col-md-10">
                    {{signer.firstName && (signer.firstName + ' ' + signer.lastName)
                || signer.lastName}}
                    <ng-container *ngIf="signer.email"> -<i>{{signer.email}}</i></ng-container>
                  </a>
                </li>
              </ul>
              <button (click)="inputSignerOpen = false" class="btn btn-sm btn-primary float-right" id="btn-close-user"
                type="button">
                &times;&nbsp;{{'USER_PROFILE.SIGNATURE_DELEGATION.CLOSE' | translate}}
              </button>
            </div>
          </div>
        </div>
        <div class="app-input-text">
          <app-date-time-picker [(ngModel)]="delegatedFrom" [default]="defaultDate"
            [leftLabel]="'USER_PROFILE.SIGNATURE_DELEGATION.FROM' | translate"
            [minHours]="minHours" [minMinutes]="minMinutes" [minDelta]="1"
            (errorDateBefore)="controlErrorDateBefore($event)"
            [maxDate]="(delegatedUntil) ? toDateStruct(delegatedUntil) : undefined" [minDate]="now"
            [idPostfix]="'defaultDate'" placeholder="Select start date & time"></app-date-time-picker>
        </div>
        <div class="app-input-text">
          <app-date-time-picker [(ngModel)]="delegatedUntil" [default]="defaultWeekDate" [idPostfix]="'defaultWeekDate'"
            [leftLabel]="'USER_PROFILE.SIGNATURE_DELEGATION.TO' | translate"
            [minDate]="delegatedFrom ? toDateStruct(delegatedFrom) : tomorrow" placeholder="Select end date & time">
          </app-date-time-picker>
        </div>
        <!-- Wrong period notification -->
        <ng-container *ngIf="wrongPeriodNotification">
          <p class="text-danger">
            {{'USER_PROFILE.SIGNATURE_DELEGATION.BAD_PERIOD' | translate}}
          </p>
        </ng-container>
        <!-- Wrong time notification -->
        <ng-container *ngIf="timeNotCorrectNotification">
          <p class="text-danger">
            {{'USER_PROFILE.SIGNATURE_DELEGATION.BAD_TIME' | translate}}
          </p>
        </ng-container>
        <div *ngIf="showDescription">
          <app-card-info (deleteDelegateEvent)="deleteDelegate($event)" [dates]="dates" [signers]="signersEnduser" [disabled]="removingDelegate">
          </app-card-info>
        </div>
        <span *ngIf="errorDateBefore" class="text-danger form-text">{{'USER_PROFILE.SIGNATURE_DELEGATION.TOOEARLY' | translate}}</span>
        <button (click)="addDelegate()" [disabled]="!(delegatedFrom && delegatedUntil && signerDelegated) || errorDateBefore || addingDelegate"
          class="btn btn-primary mr-1 mr-xl-1 mr-sm-1 mr-lg-1 mr-md-1 mt-2 btn-block" id="add-delegate-button">
          {{'USER_PROFILE.SIGNATURE_DELEGATION.ADD' | translate}}
        </button>
      </div>
    </div>

    <hr class="separator">

    <div class="form-row">
      <h2>{{'USER_PROFILE.WATERMARK.TITLE' | translate}}</h2>
      <div class="app-input-text">
        <div id="visiteProfilStep3" class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text"><i aria-hidden="true" class="fa fa-file fa-fw"></i>
              </div>
            </div>
            <div class="custom-file input-group-prepend">
              <input (change)="onWatermarkChange($event)" accept="image/jpeg,image/png"
                class="custom-file-input input-group-text" id="input-document" type="file" />
              <label class="custom-file-label" for="input-document">{{newWatermarkFile ? newWatermarkFile.name
                  : 'USER_PROFILE.WATERMARK.TITLE' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="app-input-text">
        <ng-container *ngIf="currentWatermark && !newWatermarkFile">
          <label for="currentWatermark">{{'USER_PROFILE.WATERMARK.CURRENT_WATERMARK_TITLE' | translate}}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <img alt="current_user_watermark" class="watermark" id="currentWatermark"
                src="{{'data:image/png;base64,' + currentWatermark.image}}">
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="newWatermarkFile">
          <label for="newWatermark">{{'USER_PROFILE.WATERMARK.NEW_WATERMARK_TITLE' | translate}}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <img [src]="newWatermarkPreview || '//:0'" alt="current_user_watermark" class="watermark"
                id="newWatermark">
            </div>
          </div>
        </ng-container>
      </div>
      <div class="app-input-text">
        <div *ngIf="currentWatermark && !newWatermarkFile" class="mt-3 d-flex">
          <button (click)="removeWatermark()" class="btn btn-danger flex-grow-1 mr-2" [disabled]="removingWatermark">
            {{'USER_PROFILE.WATERMARK.DELETE_SSN' | translate}}
          </button>
          <div class="flex-grow-1 ml-2">&nbsp;</div>
        </div>
        <div *ngIf="newWatermarkFile" class="mt-3 d-flex" role="group">
          <button (click)="uploadWatermark()" [disabled]="newWatermarkFile === undefined || uploadingWatermark"
            class="btn btn-primary flex-grow-1 mr-2">
            {{'USER_PROFILE.WATERMARK.CONFIRM_UPLOAD' | translate}}
          </button>
          <button (click)="newWatermarkFile = undefined" [disabled]="newWatermarkFile === undefined"
            class="btn btn-danger flex-grow-1 ml-2">
            {{'USER_PROFILE.WATERMARK.CANCEL_UPLOAD' | translate}}
          </button>
        </div>
      </div>

      <hr class="separator">
      <div id="visiteProfilStep4" class="luxTrustInfos">
        <h2 class="mt-3">{{'USER_PROFILE.LUXTRUST.TITLE' | translate}}</h2>
        <div class="app-input-text">
          <div class="form-group">
            <label for="ssn">{{'USER_PROFILE.LUXTRUST.SSN' | translate}}</label>
            <div class="input-group">
              <input [disabled]="true" [value]="currentSsn" class="form-control"
                id="ssn" style="margin-right: 10px" />
              <span class="input-group-btn input-group-append">
                <button (click)="openOrelyModal()" *ngIf="!currentSsn"
                  class="btn btn-primary input-group-text">{{'USER_PROFILE.LUXTRUST.FIND_SSN' | translate}}</button>
                <button (click)="deleteSsn()" *ngIf="currentSsn"
                  class="btn btn-danger input-group-text">{{'USER_PROFILE.LUXTRUST.UNBIND_SSN' | translate}}</button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--<label *ngIf="itsmeAuthorization$ | async" class="mt-2">
        {{'USER_PROFILE.ITSME.TITLE' | translate}}
      </label>
      <div *ngIf="itsmeAuthorization$ | async as itsmeAuthorization" class="app-input-text mb-5">
        <a [href]="itsmeAuthorization.url" class="btn btn-primary btn-block btn-itsme">
          <img alt="itsme" class="mr-1" height="20" src="assets/img/itsme-logo-white.png" width="20">
          <ng-container *ngIf="itsmeInfo.hasAccount; else link">{{'USER_PROFILE.ITSME.UNLINK' | translate}}
          </ng-container>
          <ng-template #link>{{'USER_PROFILE.ITSME.LINK' | translate}}</ng-template>
        </a>
      </div>-->
    </div>

    <hr class="separator">

    <h2 class="mt-2">{{'USER_PROFILE.LUXTRUST.PASSWORD.TITLE' | translate}}</h2>
    <div class="app-input-text" id="visiteProfilStep5">

      <form [formGroup]="resetForm">
        <div class="form-group">
          <label for="p-current-password">{{'USER_PROFILE.LUXTRUST.PASSWORD.CURRENT_PASSWORD' | translate}}</label>
          <div class="input-group">
            <input formControlName="currentPassword" class="form-control" id="p-current-password"
              (change)="detectChangePassord()" [type]="currentPasswordVisible ? 'text' : 'password'"
              autocomplete="none" />
            <button (mousedown)="showCurrentPassword(true)" (mouseup)="showCurrentPassword(false)">
              <i aria-hidden="true" class="fa" [ngClass]="currentPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </div>
          <span class="invalid-feedback" *ngIf="eventInputPassword">{{getErrorMessageCurrentPassword()}}</span>
        </div>

        <div class="form-group">
          <label for="p-new1-password">{{'USER_PROFILE.LUXTRUST.PASSWORD.NEW_PASSWORD' | translate}}</label>

          <div class="input-group">
            <input formControlName="password" class="form-control" id="p-new1-password"
              [type]="newPasswordVisible ? 'text' : 'password'" autoComplete="none"
              (change)="markAsTouched('password')" (focusin)="displayRulesForValidationPassword()"/>
            <button (mousedown)="showNewPassword(true)" (mouseup)="showNewPassword(false)">
              <i aria-hidden="true" class="fa" [ngClass]="newPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </div>
          <div class="password-rules mt-2" *ngIf="displayValidationNewPassword">
            <label>{{'USER_PROFILE.LUXTRUST.PASSWORD.FORMAT.TEXT' | translate}}</label>
            <label [ngClass]="newPasswordFieldColorIf('noAlphabeticChar')">
              <i class="fa" [ngClass]="newPasswordFieldIconIf('noAlphabeticChar')"></i>
              {{'USER_PROFILE.LUXTRUST.PASSWORD.FORMAT.ALPHABETIC_CHAR' | translate}}
            </label>
            <label [ngClass]="newPasswordFieldColorIf('noNumberChar')">
              <i class="fa" [ngClass]="newPasswordFieldIconIf('noNumberChar')"></i>
              {{'USER_PROFILE.LUXTRUST.PASSWORD.FORMAT.NUMBER' | translate}}
            </label>
            <label [ngClass]="newPasswordFieldColorIf('noSpecialChar')">
              <i class="fa" [ngClass]="newPasswordFieldIconIf('noSpecialChar')"></i>
              {{'USER_PROFILE.LUXTRUST.PASSWORD.FORMAT.SPECIAL_CHAR' | translate}}
            </label>
            <label [ngClass]="newPasswordFieldColorIf('minlength')">
              <i class="fa" [ngClass]="newPasswordFieldIconIf('minlength')"></i>
              {{'USER_PROFILE.LUXTRUST.PASSWORD.FORMAT.MIN_LENGTH' | translate}}
            </label>
            <label [ngClass]="newPasswordFieldColorIf('maxlength')">
              <i class="fa" [ngClass]="newPasswordFieldIconIf('maxlength')"></i>
              {{'USER_PROFILE.LUXTRUST.PASSWORD.FORMAT.MAX_LENGTH' | translate}}
            </label>
          </div>
        </div>

        <div class="form-group">
          <label for="p-new2-password">{{'USER_PROFILE.LUXTRUST.PASSWORD.VALIDATE_PASSWORD' | translate}}</label>

          <div class="input-group">
            <input class="form-control" id="p-new2-password" [type]="confirmPasswordVisible ? 'text' : 'password'"
              formControlName="confirmPassword" autoComplete="none" />
            <button (mousedown)="showConfirmPassword(true)" (mouseup)="showConfirmPassword(false)">
              <i aria-hidden="true" class="fa" [ngClass]="confirmPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </div>
          <div class="password-rules mt-2">
            <label [ngClass]="confirmPasswordFieldColorIf('matchPassword')">
              <i class="fa" [ngClass]="confirmPasswordFieldIconIf('matchPassword')"></i>
              {{'USER_PROFILE.LUXTRUST.PASSWORD.MATCH' | translate}}
            </label>
          </div>
        </div>
        <div class="position mb-5">
          <button (click)="changePassword()" [disabled]="resetForm.invalid || changingPassword"
            class="btn btn-primary">{{'USER_PROFILE.LUXTRUST.PASSWORD.CHANGE_PASSWORD' | translate}}</button>
        </div>
      </form>

    </div>
  </div>
</div>
