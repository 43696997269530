import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  SessionData,
  SessionService,
  SignatureBookFilter,
  SignatureBookService,
  StepData,
  StepService
} from 'luxtrust-cosi-api';
import { SignatureBookData } from 'luxtrust-cosi-api/model/signatureBookData';
import { SignatureData } from 'luxtrust-cosi-api/model/signatureData';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import { AppService } from '../../../services/services/app.service';
import { SignatureBookState, SignatureBookStoreService } from '../../services/signature-book-store.service';

@Component({
  templateUrl: './signature-book.component.html', styleUrls: ['./signature-book.component.scss']
})
export class SignatureBookComponent implements OnInit, OnDestroy {

  sub: Subscription;
  signatureBook: SignatureBookState;
  currentSignatureBook: SignatureBookData;
  showDocument: boolean;
  loading: boolean;
  session: SessionData;
  step: StepData;
  searchtext: string;
  debouncer: Subject<string> = new Subject();
  sortAsc: boolean = false;

  constructor(private signatureBookStoreService: SignatureBookStoreService,
              private appService: AppService,
              private sessionService: SessionService,
              private stepService: StepService,
              private router: Router,
              private signatureBookService: SignatureBookService,
              private alertService: AlertService
  ) {
    this.signatureBookStoreService.init();
    this.registerInputDebouncer();
  }

  get currentUserId(): number {
    return this.appService.getCurrentUserId();
  }

  ngOnInit() {
    this.loading = false;
    this.populate();
  }

  registerInputDebouncer() {
    this.debouncer.pipe(debounceTime(300))
      .subscribe((value) => this.signatureBookStoreService.search(value, this.sortAsc));
  }

  populate() {
    this.sub = this.signatureBookStoreService.getAll$().subscribe(signatureBook => {
      this.signatureBook = signatureBook;
      // Select the first document if it exist
      if (signatureBook && signatureBook.items && signatureBook.items.length > 0) {
        this.setCurrentDocument(this.signatureBook.items[0]);
      } else if (signatureBook) {
        this.loading = true;
      }
    });
  }

  getSessionAndStep() {
    forkJoin( this.sessionService.getSession(this.currentSignatureBook.sessionId),
      this.stepService.getStep(this.currentSignatureBook.sessionId, this.currentSignatureBook.stepId))
      .subscribe( ([session , step]) => {
      this.session = session;
      this.step = step;
      this.loading = true;
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  setCurrentDocument(signatureBook: SignatureBookData) {
    this.currentSignatureBook = signatureBook;
    this.getSessionAndStep();
  }

  incrementStepCommentOnStore() {
    this.signatureBook.items.forEach(signatureBook => {
      if (signatureBook.stepId === this.currentSignatureBook.stepId) {
        signatureBook.additionalSession.commentNumber++;
      }});
    this.signatureBookStoreService.incrementCommentCounter(this.currentSignatureBook.sessionId, this.currentSignatureBook.stepId);
  }

  handleRemoveDocument(signatureBook: SignatureBookData) {
    this.signatureBookStoreService.removeItem(signatureBook);
    this.selectFirtSignerBook();
  }

  handleRejectDocument(signatureBook: SignatureBookData) {
    this.signatureBookStoreService.changeDocumentStatus(signatureBook, SignatureData.StatusEnum.DECLINED);
  }

  handleReinstateDocument(signatureBook: SignatureBookData) {
    this.signatureBookStoreService.changeDocumentStatus(signatureBook, SignatureData.StatusEnum.STARTED);
  }
  handleCancelDocument(signatureBook: SignatureBookData) {
    const item = [];
    this.signatureBook.items.forEach(document => {
      if (signatureBook.document.id !== document.document.id) {
        item.push(document);
      }
    });
    this.signatureBook.items = item;
    this.selectFirtSignerBook();
  }

  searchDocument(text: string) {
    this.searchtext = text;
    this.debouncer.next(this.searchtext);
  }

  onSort(asc){
    this.sortAsc = asc;
    this.signatureBookStoreService.search(this.searchtext, this.sortAsc);
  }

  selectFirtSignerBook() {
    if (this.signatureBook.items.length > 0) {
      this.currentSignatureBook = this.signatureBook.items[0];
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.debouncer.complete();
    this.signatureBookStoreService.reset();

  }

  onFilter(event) {
    if (event) {
      this.signatureBookService.getSignatureBookList({documentStatus: SignatureBookFilter.DocumentStatusEnum.SIGNED})
        .subscribe(signatureBook => {
          this.signatureBook.items = signatureBook;
          if (this.searchtext) {
            this.searchDocument(this.searchtext);
          }
        }, (error: ApiError) => this.alertService.errorApi(error));
    }
  }

  removeComment() {
    this.signatureBook.items.forEach(signatureBook => {
      if (signatureBook.stepId === this.currentSignatureBook.stepId) {
        signatureBook.additionalSession.commentNumber--;
      }
    });
  }

  onScrollDown () {
    this.signatureBookStoreService.fetch();
  }

  moveToCommentSection() {
    document.getElementById('commentSection').scrollIntoView();
  }

}
