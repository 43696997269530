/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthenticationResponse } from '../model/authenticationResponse';
import { ItsmeAuthorizationDto } from '../model/itsmeAuthorizationDto';
import { ItsmeInfoDto } from '../model/itsmeInfoDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ItsmeAuthenticationService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get the authorization url for itsme authentication
     * 
     * @param service service
     * @param redirectUri URI on which the user will be redirected when getting the authorization code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itsmeAuthorizationUrl(service: 'LOGIN' | 'SIGN_UP' | 'LINK' | 'UNLINK', redirectUri?: string, observe?: 'body', reportProgress?: boolean): Observable<ItsmeAuthorizationDto>;
    public itsmeAuthorizationUrl(service: 'LOGIN' | 'SIGN_UP' | 'LINK' | 'UNLINK', redirectUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItsmeAuthorizationDto>>;
    public itsmeAuthorizationUrl(service: 'LOGIN' | 'SIGN_UP' | 'LINK' | 'UNLINK', redirectUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItsmeAuthorizationDto>>;
    public itsmeAuthorizationUrl(service: 'LOGIN' | 'SIGN_UP' | 'LINK' | 'UNLINK', redirectUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (service === null || service === undefined) {
            throw new Error('Required parameter service was null or undefined when calling itsmeAuthorizationUrl.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (redirectUri !== undefined && redirectUri !== null) {
            queryParameters = queryParameters.set('redirect-uri', <any>redirectUri);
        }
        if (service !== undefined && service !== null) {
            queryParameters = queryParameters.set('service', <any>service);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ItsmeAuthorizationDto>(`${this.basePath}/api/itsme/auth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Make the user able to get information regarding his itsme account
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itsmeInfo(observe?: 'body', reportProgress?: boolean): Observable<ItsmeInfoDto>;
    public itsmeInfo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItsmeInfoDto>>;
    public itsmeInfo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItsmeInfoDto>>;
    public itsmeInfo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ItsmeInfoDto>(`${this.basePath}/api/itsme/auth/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send the authorization code for linking itsme account with an existing enduser
     * 
     * @param code Authorization code received when processing authorization
     * @param redirectUri URI on which the user will be redirected after authenticating with itsme
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itsmeLink(code?: string, redirectUri?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public itsmeLink(code?: string, redirectUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public itsmeLink(code?: string, redirectUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public itsmeLink(code?: string, redirectUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (redirectUri !== undefined && redirectUri !== null) {
            queryParameters = queryParameters.set('redirect-uri', <any>redirectUri);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/itsme/auth/link`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send the authorization code for itsme authentication
     * 
     * @param code Authorization code received when processing authorization
     * @param redirectUri URI on which the user will be redirected after authenticating with itsme
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itsmeLogin(code?: string, redirectUri?: string, observe?: 'body', reportProgress?: boolean): Observable<AuthenticationResponse>;
    public itsmeLogin(code?: string, redirectUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthenticationResponse>>;
    public itsmeLogin(code?: string, redirectUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthenticationResponse>>;
    public itsmeLogin(code?: string, redirectUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (redirectUri !== undefined && redirectUri !== null) {
            queryParameters = queryParameters.set('redirect-uri', <any>redirectUri);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<AuthenticationResponse>(`${this.basePath}/api/itsme/auth/login`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send the authorization code for refreshing itsme account with an existing enduser
     * 
     * @param code Authorization code received when processing authorization
     * @param redirectUri URI on which the user will be redirected after authenticating with itsme
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itsmeRefresh(code?: string, redirectUri?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public itsmeRefresh(code?: string, redirectUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public itsmeRefresh(code?: string, redirectUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public itsmeRefresh(code?: string, redirectUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (redirectUri !== undefined && redirectUri !== null) {
            queryParameters = queryParameters.set('redirect-uri', <any>redirectUri);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/itsme/auth/refresh`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send the authorization code for itsme signup
     * 
     * @param code Authorization code received when processing authorization
     * @param redirectUri URI on which the user will be redirected after authenticating with itsme
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itsmeSignup(code?: string, redirectUri?: string, observe?: 'body', reportProgress?: boolean): Observable<AuthenticationResponse>;
    public itsmeSignup(code?: string, redirectUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthenticationResponse>>;
    public itsmeSignup(code?: string, redirectUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthenticationResponse>>;
    public itsmeSignup(code?: string, redirectUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (redirectUri !== undefined && redirectUri !== null) {
            queryParameters = queryParameters.set('redirect-uri', <any>redirectUri);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<AuthenticationResponse>(`${this.basePath}/api/itsme/auth/signup`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlink itsme account from an existing enduser
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itsmeUnlink(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public itsmeUnlink(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public itsmeUnlink(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public itsmeUnlink(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/itsme/auth/unlink`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
