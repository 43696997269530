/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthCodePayload } from '../model/authCodePayload';
import { CredentialsPayload } from '../model/credentialsPayload';
import { EnduserData } from '../model/enduserData';
import { IdData } from '../model/idData';
import { InvitationPayload } from '../model/invitationPayload';
import { PlatformAuthenticationData } from '../model/platformAuthenticationData';
import { PlatformAuthenticationPayload } from '../model/platformAuthenticationPayload';
import { PlatformResolvedHostnameData } from '../model/platformResolvedHostnameData';
import { RedirectView } from '../model/redirectView';
import { ResetPasswordPayload } from '../model/resetPasswordPayload';
import { SignupPayload } from '../model/signupPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthenticationService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * User authentication exchange
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authenticateExchangeUserJwt(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public authenticateExchangeUserJwt(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public authenticateExchangeUserJwt(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public authenticateExchangeUserJwt(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/auth/exchange-remote`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Platform authentication
     * 
     * @param platformAuthenticationPayload Request for proof
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authenticatePlatform(platformAuthenticationPayload?: PlatformAuthenticationPayload, observe?: 'body', reportProgress?: boolean): Observable<PlatformAuthenticationData>;
    public authenticatePlatform(platformAuthenticationPayload?: PlatformAuthenticationPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlatformAuthenticationData>>;
    public authenticatePlatform(platformAuthenticationPayload?: PlatformAuthenticationPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlatformAuthenticationData>>;
    public authenticatePlatform(platformAuthenticationPayload?: PlatformAuthenticationPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PlatformAuthenticationData>(`${this.basePath}/auth/validate`,
            platformAuthenticationPayload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User authentication
     * 
     * @param credential credential
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authenticateUser(credential: CredentialsPayload, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public authenticateUser(credential: CredentialsPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public authenticateUser(credential: CredentialsPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public authenticateUser(credential: CredentialsPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (credential === null || credential === undefined) {
            throw new Error('Required parameter credential was null or undefined when calling authenticateUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/auth`,
            credential,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User authentication forward
     * 
     * @param jwt JWT to exchange
     * @param redirectUrl Return URL with new JWT and claims
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authenticateUserJwt(jwt?: string, redirectUrl?: string, observe?: 'body', reportProgress?: boolean): Observable<RedirectView>;
    public authenticateUserJwt(jwt?: string, redirectUrl?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RedirectView>>;
    public authenticateUserJwt(jwt?: string, redirectUrl?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RedirectView>>;
    public authenticateUserJwt(jwt?: string, redirectUrl?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (jwt !== undefined && jwt !== null) {
            queryParameters = queryParameters.set('jwt', <any>jwt);
        }
        if (redirectUrl !== undefined && redirectUrl !== null) {
            queryParameters = queryParameters.set('redirectUrl', <any>redirectUrl);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RedirectView>(`${this.basePath}/auth/forward`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User authentication with code
     * 
     * @param authCodePayload authCodePayload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authenticateUserWithCode(authCodePayload: AuthCodePayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authenticateUserWithCode(authCodePayload: AuthCodePayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authenticateUserWithCode(authCodePayload: AuthCodePayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authenticateUserWithCode(authCodePayload: AuthCodePayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (authCodePayload === null || authCodePayload === undefined) {
            throw new Error('Required parameter authCodePayload was null or undefined when calling authenticateUserWithCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth-with-code`,
            authCodePayload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validate and send email to reset password
     * 
     * @param email User email
     * @param tenantName Tenant to search in
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailPasswordReset(email?: string, tenantName?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailPasswordReset(email?: string, tenantName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailPasswordReset(email?: string, tenantName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailPasswordReset(email?: string, tenantName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tenantName !== undefined && tenantName !== null) {
            queryParameters = queryParameters.set('tenantName', <any>tenantName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/auth/password/email`,
            email,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Jwt authentication substitution
     * 
     * @param jwt JWT to exchange
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jwtSubstitute(jwt?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public jwtSubstitute(jwt?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public jwtSubstitute(jwt?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public jwtSubstitute(jwt?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (jwt !== undefined && jwt !== null) {
            queryParameters = queryParameters.set('jwt', <any>jwt);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/auth/jwt-substitute`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Signup through an invitation
     * 
     * @param payload Invitation creation data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public newUserInvitationSignup(payload?: InvitationPayload, observe?: 'body', reportProgress?: boolean): Observable<IdData>;
    public newUserInvitationSignup(payload?: InvitationPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IdData>>;
    public newUserInvitationSignup(payload?: InvitationPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IdData>>;
    public newUserInvitationSignup(payload?: InvitationPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IdData>(`${this.basePath}/api/auth/invitation`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Signup
     * 
     * @param payload New user information
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public newUserSignup(payload?: SignupPayload, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public newUserSignup(payload?: SignupPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public newUserSignup(payload?: SignupPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public newUserSignup(payload?: SignupPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EnduserData>(`${this.basePath}/api/auth/signup`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Refresh a JWT
     * 
     * @param authenticated 
     * @param authorities0Authority 
     * @param credentials 
     * @param details 
     * @param principal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshJwt(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, principal?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public refreshJwt(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, principal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public refreshJwt(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, principal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public refreshJwt(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, principal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (authenticated !== undefined && authenticated !== null) {
            queryParameters = queryParameters.set('authenticated', <any>authenticated);
        }
        if (authorities0Authority !== undefined && authorities0Authority !== null) {
            queryParameters = queryParameters.set('authorities[0].authority', <any>authorities0Authority);
        }
        if (credentials !== undefined && credentials !== null) {
            queryParameters = queryParameters.set('credentials', <any>credentials);
        }
        if (details !== undefined && details !== null) {
            queryParameters = queryParameters.set('details', <any>details);
        }
        if (principal !== undefined && principal !== null) {
            queryParameters = queryParameters.set('principal', <any>principal);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<string>(`${this.basePath}/api/auth/refresh`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset current password
     * 
     * @param payload Payload with new password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPassword(payload?: ResetPasswordPayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPassword(payload?: ResetPasswordPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPassword(payload?: ResetPasswordPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPassword(payload?: ResetPasswordPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/auth/reset`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resolve a signed domain
     * 
     * @param domain Domain to resolve
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resolveSignedDomain(domain?: string, observe?: 'body', reportProgress?: boolean): Observable<PlatformResolvedHostnameData>;
    public resolveSignedDomain(domain?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlatformResolvedHostnameData>>;
    public resolveSignedDomain(domain?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlatformResolvedHostnameData>>;
    public resolveSignedDomain(domain?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (domain !== undefined && domain !== null) {
            queryParameters = queryParameters.set('domain', <any>domain);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PlatformResolvedHostnameData>(`${this.basePath}/auth/domains`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Revoke a JWT
     * 
     * @param authenticated 
     * @param authorities0Authority 
     * @param credentials 
     * @param details 
     * @param principal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokeJwt(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, principal?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public revokeJwt(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, principal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public revokeJwt(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, principal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public revokeJwt(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, principal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (authenticated !== undefined && authenticated !== null) {
            queryParameters = queryParameters.set('authenticated', <any>authenticated);
        }
        if (authorities0Authority !== undefined && authorities0Authority !== null) {
            queryParameters = queryParameters.set('authorities[0].authority', <any>authorities0Authority);
        }
        if (credentials !== undefined && credentials !== null) {
            queryParameters = queryParameters.set('credentials', <any>credentials);
        }
        if (details !== undefined && details !== null) {
            queryParameters = queryParameters.set('details', <any>details);
        }
        if (principal !== undefined && principal !== null) {
            queryParameters = queryParameters.set('principal', <any>principal);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/auth/revoke`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify auth code
     * 
     * @param authCodePayload authCodePayload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyCode(authCodePayload: AuthCodePayload, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public verifyCode(authCodePayload: AuthCodePayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public verifyCode(authCodePayload: AuthCodePayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public verifyCode(authCodePayload: AuthCodePayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (authCodePayload === null || authCodePayload === undefined) {
            throw new Error('Required parameter authCodePayload was null or undefined when calling verifyCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/verify-code`,
            authCodePayload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
