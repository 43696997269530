/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../document-card/document-card.component.ngfactory";
import * as i3 from "../document-card/document-card.component";
import * as i4 from "../../../core/service/translate.service";
import * as i5 from "../../../../../luxtrust-cosi-api/api/document.service";
import * as i6 from "../../../services/services/alert-service";
import * as i7 from "@angular/router";
import * as i8 from "../../../services/services/app.service";
import * as i9 from "ngx-infinite-scroll";
import * as i10 from "@angular/common";
import * as i11 from "../../../core/pipes/translate.pipe";
import * as i12 from "./document-list.component";
var styles_DocumentListComponent = [i0.styles];
var RenderType_DocumentListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentListComponent, data: {} });
export { RenderType_DocumentListComponent as RenderType_DocumentListComponent };
function View_DocumentListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.sortAsc = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-sort-amount-up"]], null, null, null, null, null))], null, null); }
function View_DocumentListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.sortAsc = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-sort-amount-down"]], null, null, null, null, null))], null, null); }
function View_DocumentListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "document-list-result-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "document-card", [], null, [[null, "click"], [null, "moveToCommentSection"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.documentSelected.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("moveToCommentSection" === en)) {
        var pd_1 = (_co.moveToCommentSection.emit(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DocumentCardComponent_0, i2.RenderType_DocumentCardComponent)), i1.ɵdid(2, 114688, null, 0, i3.DocumentCardComponent, [i4.TranslateService, i5.DocumentService, i6.AlertService, i7.Router, i8.AppService], { documentName: [0, "documentName"], priority: [1, "priority"], numberComment: [2, "numberComment"], numberAnnotation: [3, "numberAnnotation"], stepName: [4, "stepName"], stepId: [5, "stepId"], sessionName: [6, "sessionName"], sessionId: [7, "sessionId"], signatures: [8, "signatures"], createdOn: [9, "createdOn"], isActive: [10, "isActive"], alternateColor: [11, "alternateColor"], status: [12, "status"] }, { moveToCommentSection: "moveToCommentSection" }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "padding-card"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.document.name; var currVal_1 = _v.context.$implicit.additionalSession.priority; var currVal_2 = _v.context.$implicit.additionalSession.commentNumber; var currVal_3 = _v.context.$implicit.additionalSession.annotationNumber; var currVal_4 = _v.context.$implicit.stepName; var currVal_5 = _v.context.$implicit.stepId; var currVal_6 = _v.context.$implicit.sessionName; var currVal_7 = _v.context.$implicit.sessionId; var currVal_8 = _v.context.$implicit.signatureBookSignerList; var currVal_9 = _v.context.$implicit.createdOn; var currVal_10 = _co.isSelected(_v.context.$implicit); var currVal_11 = _co.blueGreenArray[_v.context.index]; var currVal_12 = _v.context.$implicit.status; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
function View_DocumentListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "document-list-result"], ["infinite-scroll", ""]], null, [[null, "scrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolled" === en)) {
        var pd_0 = (_co.onScrollDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4866048, null, 0, i9.InfiniteScrollDirective, [i1.ElementRef, i1.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], infiniteScrollThrottle: [1, "infiniteScrollThrottle"], scrollWindow: [2, "scrollWindow"] }, { scrolled: "scrolled" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentListComponent_4)), i1.ɵdid(3, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scrollDistance; var currVal_1 = _co.throttle; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co._signatureBook.items; _ck(_v, 3, 0, currVal_3); }, null); }
export function View_DocumentListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { filterByStatus: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "document-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "document-list-action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "document-list-action-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "input", [["class", "form-control"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.keyUp.next($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i11.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentListComponent_1)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentListComponent_2)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentListComponent_3)), i1.ɵdid(12, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.sortAsc; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.sortAsc; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co._signatureBook; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("SIGNATURE_BOOK.SEARCH")); _ck(_v, 5, 0, currVal_0); }); }
export function View_DocumentListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "document-list", [], null, null, null, View_DocumentListComponent_0, RenderType_DocumentListComponent)), i1.ɵdid(1, 245760, null, 0, i12.DocumentListComponent, [i7.Router, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentListComponentNgFactory = i1.ɵccf("document-list", i12.DocumentListComponent, View_DocumentListComponent_Host_0, { currentUserId: "currentUserId", signatureBook: "signatureBook", stepid: "stepid", sessionId: "sessionId", selectedSignatureBook: "selectedSignatureBook" }, { documentSelected: "documentSelected", moveToCommentSection: "moveToCommentSection", onSearch: "onSearch", filterStatusDocument: "filterStatusDocument", onSort: "onSort", onScroll: "onScroll" }, []);
export { DocumentListComponentNgFactory as DocumentListComponentNgFactory };
