import { OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from 'src/app/core/service/translate.service';

import { take } from 'rxjs/operators';
import { AlertService } from '../../../services/services/alert-service';
import { AppService } from '../../../services/services/app.service';
import { ItsmeAuthServiceType, ItsmeService } from '../../../services/services/itsme.service';

export abstract class ItsmeAuthComponent implements OnInit {

  protected constructor(protected readonly service: ItsmeAuthServiceType,
                        protected route: ActivatedRoute,
                        protected router: Router,
                        protected appService: AppService,
                        protected alertService: AlertService,
                        protected translate: TranslateService,
                        protected itsmeService: ItsmeService) {
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(take(1))
      .subscribe((params: ParamMap) => {
        const itsmeCode = params.get('code');
        if (itsmeCode) {
          const redirectUri = this.itsmeService.getRedirectUrlForAuth(this.service);
          this.takeAction(redirectUri, itsmeCode);
        } else {
          this.failure('ALERT.ITSME_CODE_INVALID', '/login', true);
        }
      });
  }

  protected abstract takeAction(redirectUri: any, itsmeCode: string): void;

  protected success(message: string, redirectPath: string, accessToken?: string) {
    if (accessToken) {
      this.appService.refresh(accessToken);
      this.appService.loadEnduser();
    }
    this.alertService.success(message);
    this.router.navigate([redirectPath]);
  }

  protected failure(message: string, redirectPath: string, logout: boolean = false) {
    if (logout) {
      this.appService.revoke();
      this.appService.logout();
    }
    this.alertService.error(message);
    this.router.navigate([redirectPath]);
  }

}
