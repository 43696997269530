/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Notification pattern
 */
export interface PatternData { 
    /**
     * Body of the notification
     */
    body?: string;
    /**
     * Type of template (SMS, EMAIL, ...)
     */
    channel?: PatternData.ChannelEnum;
    _default?: boolean;
    /**
     * Type of event of the notification pattern
     */
    eventTypeId?: number;
    /**
     * Id of pattern
     */
    id?: number;
    /**
     * Language of the message template
     */
    language?: PatternData.LanguageEnum;
    /**
     * Id of the session that the template should override (used for override) 
     */
    sessionId?: number;
    sessionOverridden?: boolean;
    /**
     * Id of the step where the template will override another (used for override)
     */
    stepId?: number;
    stepOverridden?: boolean;
    /**
     * Subject of the notification
     */
    subject?: string;
    /**
     * Id of the tag
     */
    tagId?: number;
    /**
     * Value of the tag
     */
    tagValue?: string;
}
export namespace PatternData {
    export type ChannelEnum = 'EMAIL' | 'SMS' | 'APP' | 'OTHER';
    export const ChannelEnum = {
        EMAIL: 'EMAIL' as ChannelEnum,
        SMS: 'SMS' as ChannelEnum,
        APP: 'APP' as ChannelEnum,
        OTHER: 'OTHER' as ChannelEnum
    };
    export type LanguageEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LanguageEnum = {
        EN: 'EN' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        DE: 'DE' as LanguageEnum,
        PT: 'PT' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        NL: 'NL' as LanguageEnum,
        SP: 'SP' as LanguageEnum
    };
}
