import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WatermarkService} from 'luxtrust-cosi-api/api/watermark.service';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {WatermarkData} from 'luxtrust-cosi-api/model/watermarkData';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';

@Component({
  templateUrl: './modal-signer.component.html'
})
export class ModalSignerComponent {
  // come from: PdfPositionCapturerComponent
  @Input() assignedEndUsers: EnduserData[];
  @Input() endUsers: EnduserData[];
  selectingSigner = false;

  constructor(
    public modal: NgbActiveModal,
    private watermarkService: WatermarkService,
    private alertService: AlertService
  ) {}

  selectSigner(signer: EnduserData) {
    this.selectingSigner = true;
    this.watermarkService.getWatermarkList(undefined, signer.id, WatermarkData.TypeEnum.USER).toPromise().then(watermarks => {
      const watermark = watermarks.find(watermark_ => watermark_.targetId === signer.id && watermark_.image !== undefined);
      this.modal.close({
        identity: signer, watermark: watermark && watermark.image
      });
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    }).then(value => this.selectingSigner=false);
  }
}
