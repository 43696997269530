import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lt-text-truncate', templateUrl: './text-truncate.component.html', styleUrls: []
})
export class TextTruncateComponent implements OnInit {

  readonly XS_BREAKPOINT: number = 576; // bootstrap xtra-small width breakpoint
  readonly SM_BREAKPOINT: number = 768; // bootstrap small width breakpoint
  readonly MD_BREAKPOINT: number = 992; // bootstrap medium width breakpoint
  readonly LG_BREAKPOINT: number = 1200; // bootstrap large width breakpoint
  readonly XL_BREAKPOINT: number = 1700; // custom extra large width breakpoint

  @Input() label: string;

  @Input() xsMaxCharacters?: number;
  @Input() smMaxCharacters?: number;
  @Input() mdMaxCharacters?: number;
  @Input() lgMaxCharacters?: number;
  @Input() xlMaxCharacters?: number;
  @Input() xxlMaxCharacters?: number;

  screenSize: number;

  ngOnInit() {
    this.screenSize = window.innerWidth;
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.screenSize = window.innerWidth;
  }

}
