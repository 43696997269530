import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {SignatureService} from 'luxtrust-cosi-api/api/signature.service';
import {CreateValidationCodePayload} from 'luxtrust-cosi-api/model/createValidationCodePayload';
import {SealWithCodePayload} from 'luxtrust-cosi-api/model/sealWithCodePayload';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {ErrorBusinessCode} from '../../../error/error-business-code.enum';
import {SprofileEnum} from '../../../services/constants/signature.constant';
import {AlertService} from '../../../services/services/alert-service';

@Component({
  templateUrl: './modal-sign-with-code.component.html'
})
export class ModalSignWithCodeComponent implements OnInit, OnDestroy {

  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() signatureId: number;
  @Input() sprofile: string;
  @Output() generationEvent: EventEmitter<{message: string, params: Object}> = new EventEmitter<{message: string, params: Object}>();
  @ViewChild('inputValidationCode', {
    read: ElementRef, static: false
  }) inputValidationCode: ElementRef;
  channel: CreateValidationCodePayload.ValidationCodeChannelEnum;
  form: FormGroup;
  submitted = false;
  signatureFailed = false;
  canGenerateCode = false;
  private subscriptionGeneration: Subscription;
  private subscriptionValidation: Subscription;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private alertService: AlertService,
              private signatureService: SignatureService) {
  }

  get validationCode() {
    return this.form.get('validationCode');
  }

  ngOnInit() {
    this.form = this.createFormGroup();
    this.channel = this.getValidationCodeChannel();
    this.generateCode();
  }

  ngOnDestroy() {
    if (this.subscriptionGeneration) {
      this.subscriptionGeneration.unsubscribe();
    }
    if (this.subscriptionValidation) {
      this.subscriptionValidation.unsubscribe();
    }
  }

  generateCode() {
    if (this.subscriptionGeneration) {
      this.subscriptionGeneration.unsubscribe();
    }
    this.subscriptionGeneration = this.signatureService.generateValidationCodeSignature(this.sessionId, this.signatureId, this.stepId,
      <CreateValidationCodePayload>{
        validationCodeChannel: this.channel
      }).pipe(take(1))
    .subscribe(
      () => this.generationEvent.emit({message: 'VALIDATION_CODE.GENERATION_SUCCESS', params: {channel: this.channel}}),
      () => this.activeModal.close({status: 'FAILED', message : 'VALIDATION_CODE.GENERATION_FAILED'}));
  }

  validateCode() {
    if (this.form.invalid) {
      return;
    }

    this.submitted = true;
    this.signatureFailed = false;
    const validationCode: string = this.form.value.validationCode.trim();
    if (validationCode) {
      if (this.subscriptionValidation) {
        this.subscriptionValidation.unsubscribe();
      }
      this.subscriptionValidation = this.signatureService.applyValidationCodeSignature(this.sessionId, this.signatureId, this.stepId,
        <SealWithCodePayload>{
          code: validationCode
        }).pipe(take(1))
      .subscribe(
        () => this.activeModal.close({status: 'SUCCEEDED', message: 'ALERT.SIGNATURE_SUCCESS'}),
        (error: ApiError) => {
          this.alertService.errorApi(error);
          if (Object.values(ErrorBusinessCode).some(currentCode => currentCode === error.error.code)) {
            this.signatureFailed = true;
          }
          this.submitted = false;
        });
    }
  }

  updateCanGenerateCodeFlag(val: EventListener) {
    this.canGenerateCode = val.length === 0
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      validationCode: new FormControl('', [Validators.required, Validators.pattern('^\\s*(([0-9]\\s*){6})\\s*')])
    });
  }

  private getValidationCodeChannel(): CreateValidationCodePayload.ValidationCodeChannelEnum {
    if (this.sprofile === SprofileEnum.PADES_SEAL_SMS) {
      return CreateValidationCodePayload.ValidationCodeChannelEnum.SMS;
    } else if (this.sprofile === SprofileEnum.PADES_SEAL_EMAIL || this.sprofile === SprofileEnum.PADES_SEAL_IMAGE_EMAIL) {
      return CreateValidationCodePayload.ValidationCodeChannelEnum.EMAIL;
    } else {
      this.activeModal.close({ status: 'FAILED', message: 'VALIDATION_CODE.CHANNEL_NOT_SUPPORTED'});
    }
  }

}
