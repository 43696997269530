<app-full-screen mode="container-s">
  <div class="reset-pswd-container">
    <div class="d-flex justify-content-center" *ngIf="customLogo">
      <!-- luxtrust image -->
      <img class="main-logo align-self-center mb-5"
           src="data:image/png;base64,{{customLogo}}">
    </div>


    <form (ngSubmit)="onSubmit()"
          [formGroup]="resetForm"
          class="row">

      <div class="col-12">
        <!-- password input -->
        <div class="form-row">
          <app-input-text (focusout)="markAsTouched('password')"
                          [errors]="{
                              required: 'SIGN_UP.PASSWORD.ERRORS.REQUIRED' | translate,
                              minlength: 'SIGN_UP.PASSWORD.ERRORS.VALID' | translate,
                              pattern: 'SIGN_UP.PASSWORD.ERRORS.FORMAT' | translate
                            }"
                          [hasOnInput]="true"
                          [placeholder]="'SIGN_UP.PASSWORD.PLACEHOLDER' | translate"
                          autoFocus
                          idPostfix="resetPwd"
                          class="col"
                          formControlName="password"
                          icon="key"
                          type="password"></app-input-text>
        </div>

        <!-- User password 2 input -->
        <div class="form-row">
          <app-input-text (focusout)="markAsTouched('confirmPassword')"
                          [errors]="{
                              required: 'SIGN_UP.PASSWORD.ERRORS.REQUIRED' | translate,
                              minlength: 'SIGN_UP.PASSWORD.ERRORS.VALID' | translate,
                              pattern: 'SIGN_UP.PASSWORD.ERRORS.FORMAT' | translate,
                              matchPassword: 'SIGN_UP.CONFIRM_PASSWORD.ERRORS.MATCH' | translate
                            }"
                          [hasOnInput]="true"
                          [placeholder]="'LOGIN.RETYPE_PASSWORD' | translate"
                          idPostfix="confirm-resetPwd"
                          class="col"
                          formControlName="confirmPassword"
                          icon="key"
                          type="password"></app-input-text>
        </div>
      </div>

      <!-- connect button -->
      <div class="col-12 d-flex">
        <button class="btn btn-primary flex-grow-1"
                type="submit">{{'LOGIN.UPDATE_PASSWORD' | translate}}</button>
      </div>
    </form>

  </div>
</app-full-screen>

