import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(string: string, args: {max: number}): string {
    const max = args.max || 20;
    if (string) {
      return (string.length > max) ? string.substr(0, max) + '...' : string;
    }
  }

}
