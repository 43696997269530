import * as Sentry from '@sentry/browser';
import {ErrorHandler, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BrowserOptions} from "@sentry/browser/dist/backend";


export function initSentry(http: HttpClient, basePath: string) {
  return () => {
    return http.get<BrowserOptions>(basePath + '/api/sentry/config')
      .toPromise()
      .then((resp) => {
        if (resp.dsn) {
          Sentry.init(resp);
        }
      });
  };
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}
