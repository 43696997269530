import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from 'src/app/core/service/translate.service';
import { ItsmeAuthenticationService } from 'luxtrust-cosi-api/api/itsmeAuthentication.service';
import { take } from 'rxjs/operators';
import { AlertService } from '../../../../services/services/alert-service';
import { AppService } from '../../../../services/services/app.service';
import { ItsmeAuthServiceType, ItsmeService } from '../../../../services/services/itsme.service';
import { ItsmeAuthComponent } from '../itsme-auth.component';

@Component({
  templateUrl: './itsme-auth-link.component.html', styleUrls: ['./itsme-auth-link.component.scss']
})
export class ItsmeAuthLinkComponent extends ItsmeAuthComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected appService: AppService,
              protected alertService: AlertService,
              protected translate: TranslateService,
              protected itsmeService: ItsmeService,
              protected itsmeAuthenticationService: ItsmeAuthenticationService) {
    super(ItsmeAuthServiceType.LINK, route, router, appService, alertService, translate, itsmeService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected takeAction(redirectUri: string, itsmeCode: string) {
    this.itsmeAuthenticationService.itsmeLink(itsmeCode, redirectUri)
      .pipe(take(1))
      .subscribe(() => this.success('ALERT.ITSME_LINK_SUCCESS', '/profile'),
        _ => this.failure('ALERT.ITSME_LINK_FAILED', '/profile'));
  }

}
