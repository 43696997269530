/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalSessionData } from './additionalSessionData';
import { DocumentData } from './documentData';
import { SignatureBookSignerData } from './signatureBookSignerData';


/**
 * Signaturebook details
 */
export interface SignatureBookData { 
    /**
     * Additional data from the session
     */
    additionalSession?: AdditionalSessionData;
    /**
     * Creation date
     */
    createdOn?: Date;
    /**
     * Document details
     */
    document?: DocumentData;
    /**
     * Id of the session
     */
    sessionId?: number;
    /**
     * Name of the session
     */
    sessionName?: string;
    /**
     * Details about the signer
     */
    signatureBookSignerList?: Array<SignatureBookSignerData>;
    /**
     * Session status
     */
    status?: SignatureBookData.StatusEnum;
    /**
     * Id of the step
     */
    stepId?: number;
    /**
     * Name of the step
     */
    stepName?: string;
}
export namespace SignatureBookData {
    export type StatusEnum = 'CREATED' | 'STARTED' | 'COMPLETED' | 'DISABLED' | 'DECLINED' | 'CANCELED' | 'SUSPENDED';
    export const StatusEnum = {
        CREATED: 'CREATED' as StatusEnum,
        STARTED: 'STARTED' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        DISABLED: 'DISABLED' as StatusEnum,
        DECLINED: 'DECLINED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        SUSPENDED: 'SUSPENDED' as StatusEnum
    };
}
