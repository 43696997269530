import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PlatformService} from 'luxtrust-cosi-api/api/platform.service';
import {TenantService} from 'luxtrust-cosi-api/api/tenant.service';
import {ConfigData} from 'luxtrust-cosi-api/model/configData';

/**
 * Service used to initialize and get preloaded configurations.
 */
@Injectable()
export class ConfigurationService {

  platformConfig: Promise<ConfigData>;
  tenantConfig: Promise<{ [key: string]: any; }>;

  constructor(private router: Router,
              private tenantService: TenantService,
              private platformService: PlatformService
  ) {
  }

  initPlatformConfiguration() {
    this.platformConfig = this.platformService.getConfig().toPromise();
  }

  initTenantConfiguration(tenantName: string) {
    if (tenantName) {
      this.tenantConfig = this.tenantService.getTenantConfig(tenantName).toPromise()
        .then((configuration: { [key: string]: any; }) => this.sanitizeTenantConfigurationKeys(configuration))
        .catch(() => {
          this.router.navigate(['/no-tenant-name']);
          throw new Error('Cannot get tenant configuration: Unknown tenant');
        });
    } else {
      this.tenantConfig = Promise.reject('Cannot get tenant configuration: No tenant information');
    }
  }

  private sanitizeTenantConfigurationKeys(configuration: { [key: string]: any; }) {
    const sanitizedConfiguration = {};
    // tslint:disable-next-line:forin
    for (const configurationKey of Object.keys(configuration)) {
      sanitizedConfiguration[configurationKey.trim()] = configuration[configurationKey];
    }
    return sanitizedConfiguration;
  }
}
