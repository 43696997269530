import { Component, OnDestroy, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { TranslateService } from 'src/app/core/service/translate.service';
import { UpdateStepTagPayload } from 'luxtrust-cosi-api';
import { StepTagService } from 'luxtrust-cosi-api/api/stepTag.service';
import { TagService } from 'luxtrust-cosi-api/api/tag.service';
import { SessionData } from 'luxtrust-cosi-api/model/sessionData';
import { StepData } from 'luxtrust-cosi-api/model/stepData';
import { TagData } from 'luxtrust-cosi-api/model/tagData';
import { Subscription } from 'rxjs';
import TypeEnum = TagData.TypeEnum;
import {REGEXES} from '../../../../app.constant';
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';
import { StepActions } from '../../wizard/models/wizard.config';


@Component({
  templateUrl: './wizard-step-metadata.component.html', styleUrls: ['./wizard-step-metadata.component.scss']
})
export class WizardStepMetadataComponent implements OnInit, OnDestroy {

  session: SessionData;
  step: StepData;
  stepActions = StepActions;
  subscription: Subscription;
  tagTemplates: TagData[] = [];
  templates: TagData[] = [];
  tags: TagData[] = [];
  show = [];

  tagId: number;
  tagValue: string;
  encrypt = false;
  readOnly = false;
  configurator = true;
  selectedTag: TagData;
  TypeEnum = TypeEnum;
  disabled = false;
  inputDatetimeFormat: string;
  showSort = false;
  sortedTag = true;
  tagsProvider: { key: string, value: number }[];
  tagsForm: FormGroup;
  formSub: Subscription;
  inputTagValue: {key: string, value: number};
  deletingTag = false;
  addingTag = false;

  constructor(private readonly route: ActivatedRoute,
              private readonly alertService: AlertService,
              private readonly tagService: TagService,
              private readonly translateService: TranslateService,
              private formBuilder: FormBuilder,
              private readonly stepTagService: StepTagService) {
    this.prepareLang(this.translateService.currentLang);
  }

  ngOnInit() {
    this.initForm();
    this.inputTagValue = {value: undefined, key: ''};
    this.manageValuesFormChanged();
    this.subscription = this.route.parent.data.pipe().subscribe((data: Data) => {
      this.session = data['session'];
      this.step = data['step'];
      Promise.all([
        this.tagService.getTags().toPromise().then((tagTemplates: TagData[]) => this.templates = tagTemplates),
        this.stepTagService.getStepTagList(this.session.id, this.step.id).toPromise().then((tags: TagData[]) => {
          this.tags = tags;
          this.showSort = this.tags.some(tag => tag.orderIndex > 0);
          if (!this.showSort) {this.sortedTag = false; }
        })
      ]).then(() => {
        this.filterTemplates();
        this.initData();
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    });
  }

  private initData() {
    this.tagsProvider = this.tagTemplates.map(tag => {
      return {
        key: tag.label,
        value: tag.id
      };
    });
  }

  private initForm() {
    this.tagsForm = this.formBuilder.group({
      tag: [undefined]
    });
  }

  private manageValuesFormChanged() {
    this.formSub = this.tagsForm.valueChanges.subscribe(values => {
      if (values.tag && values.tag.value && this.inputTagValue.value !== values.tag.value) {
        this.setDefaultValue(values.tag.value);
        this.tagId = values.tag.value;
        this.inputTagValue = {value: values.tag.value,  key: values.tag.key};
      }
    });
  }

  addTag() {
    if (!this.tagId) { return; }
    this.addingTag = true;
    this.stepTagService.createStepTag(this.session.id, this.step.id, {
      tagId: this.tagId, value: this.tagValue, encrypt: this.encrypt, readOnly: this.readOnly, configurator: this.configurator
    }).toPromise().then(() => {
      this.tagId = undefined;
      this.inputTagValue = {value: undefined, key: ''};
      return this.stepTagService.getStepTagList(this.session.id, this.step.id).toPromise().then((tags: TagData[]) => {
        this.tags = tags;
        this.filterTemplates();
        this.initData();
        this.tagValue = '';
        this.readOnly = false;
        this.encrypt = false;
        this.configurator = true;
        this.selectedTag = undefined;
      });
    }).catch((error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.addingTag = false);
  }

  tagValueIsTooLong(tag: TagData): boolean {
    if (tag && tag.type === TypeEnum.LINK && tag.value && tag.value.length > 512) {
      return true;
    } else if ( tag && tag.type !== TypeEnum.LINK && tag.defaultValue &&  tag.defaultValue.length > 255) {
      return true;
    }
    return false;
  }

  deleteTag(tag: TagData) {
    this.deletingTag = true;
    this.stepTagService.deleteStepTag1(this.session.id, this.step.id, tag.id).toPromise()
    .then(() => this.stepTagService.getStepTagList(this.session.id, this.step.id).toPromise())
    .then((tags: TagData[]) => {
      this.selectedTag = undefined;
      this.tags = tags;
      this.filterTemplates();
      this.initData();
    }).catch((error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.deletingTag = false);
  }

  filterTemplates() {
    this.tagTemplates = this.templates.filter(t => this.tags.map(tag => tag.alias).indexOf(t.alias) < 0);
  }

  setDefaultValue(id) {
    this.selectedTag = undefined;
    const currentTag = Object.assign({}, this.tagTemplates.find(tag => tag.id === id));
    if (currentTag) {
      this.selectedTag = currentTag;
      this.tagValue = currentTag.defaultValue ? currentTag.defaultValue : this.tagValue;
      this.encrypt = false;
      this.readOnly = false;
      this.configurator = true;
    }
  }

  setTagValue(tag: TagData) {
    if (!tag.value) {
      this.readOnly = false;
    }
    this.tagValue = tag.value;
  }

  prepareLang(lang: string) {
    switch (lang) {
      case 'fr':
      case 'FR':
        this.inputDatetimeFormat = 'dd/MM/yyyy HH:mm';
        break;
      default:
        this.inputDatetimeFormat = 'MM/dd/yyyy HH:mm';
        break;
    }
  }

  createTooltip(tag: TagData): string {
    const encrypted = tag.encrypted ? this.translateService.instant('WIZARD.TAGS.ENCRYPT') : '';
    const readOnly = tag.readOnly ? this.translateService.instant('WIZARD.TAGS.READONLY') : '';
    const mandatory = tag.mandatory ? this.translateService.instant('TAGS.REQUIRED') : '';
    const flagsArray = [encrypted, readOnly, mandatory].filter(flag => flag.length > 0);
    return flagsArray.join(', ');
  }

  up(tag: TagData) {
    const index = this.tags.findIndex(tagByIndex => tagByIndex.id === tag.id);
    this.sortedTag = true;
    this.tags[index - 1] = this.tags.splice(index, 1, this.tags[index - 1])[0];
    this.updateOrderIndexTag(this.tags);
  }

  down(tag: TagData) {
    const index = this.tags.findIndex(tagByIndex => tagByIndex.id === tag.id);
    this.sortedTag = true;
    this.tags[index + 1] = this.tags.splice(index, 1, this.tags[index + 1])[0];
    this.updateOrderIndexTag(this.tags);
  }

  updateOrderIndexTag(tags: TagData[]) {
    tags.forEach((tag, index) => {
      const payload = this.createUpdateOrderPayload(tag, index);
      this.stepTagService.updateStepTag(this.session.id, this.step.id, payload).toPromise();
    });
  }

  noUp(tag: TagData) {
    const index = this.tags.findIndex(tagByIndex => tagByIndex.id === tag.id);
    return this.disabled || index === 0;
  }

  noDown(tag: TagData) {
    const index = this.tags.findIndex(tagByIndex => tagByIndex.id === tag.id);
    return this.disabled || index >= this.tags.length - 1;
  }
  unsort() {
    this.tags.forEach(tag => {
      const payload = this.createUpdateOrderPayload(tag, 0);
      this.stepTagService.updateStepTag(this.session.id, this.step.id, payload).toPromise();
      this.sortedTag = false;
    });
    this.tags = this.sortModel();
  }

  private sortModel() {
    return this.tags.sort((a, b) => {
      return (new Date(a.id) as any) - (new Date(b.id) as any);
    });
  }

  private createUpdateOrderPayload(tag: TagData, index: number): UpdateStepTagPayload {
    return {
      orderIndex: index,
      tagId: tag.id,
      value: tag.value,
      encrypt: tag.encrypted,
      readOnly: tag.readOnly,
      configurator: tag.configurator
    } as UpdateStepTagPayload;
  }

  isTagInError(tag: TagData): boolean {
    if (tag && tag.type === TypeEnum.LINK) {
      const linkValue = tag.defaultValue.split('|')[1];
      if (linkValue) {
        return !REGEXES.LINK.test(linkValue);
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSub.unsubscribe();
  }

}
