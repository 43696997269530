import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {SignatureService} from 'luxtrust-cosi-api/api/signature.service';
import {Subscription} from 'rxjs/index';
import {take} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {SprofileEnum} from '../../../services/constants/signature.constant';
import {AlertService} from '../../../services/services/alert-service';

@Component({
  templateUrl: './modal-apply-visa.component.html'
})
export class ModalApplyVisaComponent implements OnInit, OnDestroy {

  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() signatureId: number;
  @Input() sprofile: string;
  submitted = false;
  signatureFailed = false;
  // Subscriptions
  private subscriptionApply: Subscription;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private alertService: AlertService,
              private signatureService: SignatureService) {
  }

  ngOnInit() {
    if (this.sprofile.indexOf('VISA') === 0 && this.sprofile.indexOf('C2S') === 0 && this.sprofile.indexOf('TIMESTAMP') === 0) {
      this.activeModal.close({status: 'FAILED', message : 'ALERT.BAD_SPROFILE'});
    }
  }

  ngOnDestroy() {
    if (this.subscriptionApply) {
      this.subscriptionApply.unsubscribe();
    }
  }

  applyVisa() {
    this.submitted = true;
    this.signatureFailed = false;
    if (this.subscriptionApply) {
      this.subscriptionApply.unsubscribe();
    }
    this.subscriptionApply = this.signatureService.applySignature(this.sessionId, this.signatureId, this.stepId, {}).pipe(take(1))
    .subscribe(() => this.activeModal.close({status: 'SUCCEEDED', message: 'ALERT.SIGNATURE_SUCCESS'}), (error: ApiError) => {
      this.alertService.errorApi(error);
      this.signatureFailed = true;
      this.submitted = false;
    });
  }

}
