import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from 'src/app/core/service/translate.service';

@Component({
  templateUrl: './modal-create-template.component.html'
})
export class ModalCreateTemplateComponent implements AfterViewInit, OnInit {

  form: FormGroup;
  submitted = false;

  @Input() templateNameText: string;
  @Output() inProgress: EventEmitter<void> = new EventEmitter();
  @ViewChild('templateName', {
    read: ElementRef, static: false
  }) inputTemplateName: ElementRef;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      templateName: new FormControl(this.templateNameText, [Validators.required, Validators.maxLength(255)])
    });
    this.submitted = false;
  }

  ngAfterViewInit() {
    this.inputTemplateName.nativeElement.focus();
  }

  createTemplate() {
    if (!this.form.controls.templateName.errors) {
      this.inProgress.emit();
      this.submitted = true;
      this.activeModal.close(this.form.value.templateName.trim());
    }
  }

  getErrorMessageDateDebut() {
    return this.form.controls.templateName.hasError('required') ? this.translateService.instant('MODAL_CREATE_TEMPLATE.NAME_REQUIRED') :
    this.form.controls.templateName.hasError('maxlength') ? this.translateService.instant('MODAL_CREATE_TEMPLATE.INVALID_MAX_LENGTH') :
      '';
  }

}
