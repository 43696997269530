import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {throwError, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {SprofileWrapperService} from '../../../services/services/sprofile-wrapper.service';

@Injectable()
export class SprofileResolver implements Resolve<SprofileData[]> {

  constructor(
    private readonly alertService: AlertService,
    private readonly sprofileService: SprofileWrapperService
  ) {}

  resolve(): Observable<SprofileData[]> | Promise<SprofileData[]> | SprofileData[] {
    return this.sprofileService.getSprofileList()
      .pipe(catchError((error: ApiError) => {
        this.alertService.errorApi(error);
        return throwError(error);
      }));
  }
}
