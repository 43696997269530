import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdditionalSessionDataService } from './api/additionalSessionData.service';
import { AnnotationService } from './api/annotation.service';
import { AuditTrailService } from './api/auditTrail.service';
import { AuthenticationService } from './api/authentication.service';
import { CircleOfEndusersService } from './api/circleOfEndusers.service';
import { CommentService } from './api/comment.service';
import { DelegateService } from './api/delegate.service';
import { DelegateAdminService } from './api/delegateAdmin.service';
import { DirectSignService } from './api/directSign.service';
import { DocumentService } from './api/document.service';
import { DocumentStorageService } from './api/documentStorage.service';
import { DocumentTagService } from './api/documentTag.service';
import { DocumentsignatureService } from './api/documentsignature.service';
import { EnduserService } from './api/enduser.service';
import { EnduserCircleService } from './api/enduserCircle.service';
import { EnduserPreferenceService } from './api/enduserPreference.service';
import { EntitlementService } from './api/entitlement.service';
import { EventsService } from './api/events.service';
import { FlowersService } from './api/flowers.service';
import { HealthControllerService } from './api/healthController.service';
import { ItsmeAuthenticationService } from './api/itsmeAuthentication.service';
import { ItsmeSignatureService } from './api/itsmeSignature.service';
import { LabelService } from './api/label.service';
import { LegalNoticeService } from './api/legalNotice.service';
import { LogControllerService } from './api/logController.service';
import { MicrosoftAzureAdService } from './api/microsoftAzureAd.service';
import { MobileConnectAuthenticationService } from './api/mobileConnectAuthentication.service';
import { NexuSignatureService } from './api/nexuSignature.service';
import { OrelyAuthenticationService } from './api/orelyAuthentication.service';
import { OrelyAuthenticationIframeService } from './api/orelyAuthenticationIframe.service';
import { OrelySignatureService } from './api/orelySignature.service';
import { OrganisationService } from './api/organisation.service';
import { PatternService } from './api/pattern.service';
import { PlaceholderService } from './api/placeholder.service';
import { PlatformService } from './api/platform.service';
import { RetrieveAuditTrailFromDocumentApiControllerService } from './api/retrieveAuditTrailFromDocumentApiController.service';
import { SearchService } from './api/search.service';
import { SentryConfigControllerService } from './api/sentryConfigController.service';
import { SessionService } from './api/session.service';
import { SessionEnduserService } from './api/sessionEnduser.service';
import { SessionTagService } from './api/sessionTag.service';
import { SessionTemplateService } from './api/sessionTemplate.service';
import { SessionV1Service } from './api/sessionV1.service';
import { SessionsignatureService } from './api/sessionsignature.service';
import { SharepointService } from './api/sharepoint.service';
import { SignatureService } from './api/signature.service';
import { SignatureBookService } from './api/signatureBook.service';
import { SignatureWorkflowService } from './api/signatureWorkflow.service';
import { SlbControllerService } from './api/slbController.service';
import { SprofileService } from './api/sprofile.service';
import { StatisticService } from './api/statistic.service';
import { StepService } from './api/step.service';
import { StepEndUserDocumentService } from './api/stepEndUserDocument.service';
import { StepEnduserService } from './api/stepEnduser.service';
import { StepLegalEnduserService } from './api/stepLegalEnduser.service';
import { StepLegalMotionService } from './api/stepLegalMotion.service';
import { StepLinkService } from './api/stepLink.service';
import { StepPropertiesService } from './api/stepProperties.service';
import { StepRemindersService } from './api/stepReminders.service';
import { StepRuleOrderService } from './api/stepRuleOrder.service';
import { StepRuleValidationService } from './api/stepRuleValidation.service';
import { StepTagService } from './api/stepTag.service';
import { TagService } from './api/tag.service';
import { TemplateToEntitlementMappingService } from './api/templateToEntitlementMapping.service';
import { TenantService } from './api/tenant.service';
import { TimestampApiControllerService } from './api/timestampApiController.service';
import { ValidateApiControllerService } from './api/validateApiController.service';
import { ValidationService } from './api/validation.service';
import { WatermarkService } from './api/watermark.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdditionalSessionDataService,
    AnnotationService,
    AuditTrailService,
    AuthenticationService,
    CircleOfEndusersService,
    CommentService,
    DelegateService,
    DelegateAdminService,
    DirectSignService,
    DocumentService,
    DocumentStorageService,
    DocumentTagService,
    DocumentsignatureService,
    EnduserService,
    EnduserCircleService,
    EnduserPreferenceService,
    EntitlementService,
    EventsService,
    FlowersService,
    HealthControllerService,
    ItsmeAuthenticationService,
    ItsmeSignatureService,
    LabelService,
    LegalNoticeService,
    LogControllerService,
    MicrosoftAzureAdService,
    MobileConnectAuthenticationService,
    NexuSignatureService,
    OrelyAuthenticationService,
    OrelyAuthenticationIframeService,
    OrelySignatureService,
    OrganisationService,
    PatternService,
    PlaceholderService,
    PlatformService,
    RetrieveAuditTrailFromDocumentApiControllerService,
    SearchService,
    SentryConfigControllerService,
    SessionService,
    SessionEnduserService,
    SessionTagService,
    SessionTemplateService,
    SessionV1Service,
    SessionsignatureService,
    SharepointService,
    SignatureService,
    SignatureBookService,
    SignatureWorkflowService,
    SlbControllerService,
    SprofileService,
    StatisticService,
    StepService,
    StepEndUserDocumentService,
    StepEnduserService,
    StepLegalEnduserService,
    StepLegalMotionService,
    StepLinkService,
    StepPropertiesService,
    StepRemindersService,
    StepRuleOrderService,
    StepRuleValidationService,
    StepTagService,
    TagService,
    TemplateToEntitlementMappingService,
    TenantService,
    TimestampApiControllerService,
    ValidateApiControllerService,
    ValidationService,
    WatermarkService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
