import {Injectable} from '@angular/core';
import {SignatureBookService} from 'luxtrust-cosi-api/api/signatureBook.service';
import {SignatureBookData} from 'luxtrust-cosi-api/model/signatureBookData';
import {SignatureBookSignerData} from 'luxtrust-cosi-api/model/signatureBookSignerData';
import {SignatureData} from 'luxtrust-cosi-api/model/signatureData';
import {from} from 'rxjs';
import {filter as filterRx, mergeMap, take, tap, toArray} from 'rxjs/operators';
import {ApiError} from '../../error/api-error.model';
import {AlertService} from '../../services/services/alert-service';
import {AppService} from '../../services/services/app.service';
import {Store} from '../models/store';
import {SignatureBookFilter} from "../../../../luxtrust-cosi-api";

export interface SignatureBookState {
  items?: SignatureBookData[];
  limit?: number;
  documentsCounter?: number;
  offset?: number;
  pages?: number;
  maxPages?: number;
  text?: string; // c.f sessionFilter object
  enableCallWhenScrolling?: boolean;
  asc: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SignatureBookStoreService extends Store<SignatureBookState> {

  pageSizeInit = 10;
  offsetInit = 0;

  constructor(
    private signatureBookService: SignatureBookService,
    private appService: AppService,
    private alertService: AlertService
  ) {
    super();
  }

  init() {
    this.fetch();
  }

  fetch(search?: boolean) { // text if search
    if (!this.getAll() || (this.getAll() && this.getAll().enableCallWhenScrolling)) {
    let limit = this.pageSizeInit;
    let offset = this.offsetInit;

    if (this.getAll() && this.getAll().offset) {
      offset = this.getAll().offset;
    }

    if (this.getAll() && this.getAll().limit) {
      limit = this.getAll().limit;
    }

    let text = '';
    if (this.getAll() && this.getAll().text) {
      text = this.getAll().text;
    }
    let asc = false;
    if (this.getAll() && this.getAll().asc) {
      asc = this.getAll().asc;
    }

    const filter = {
      id: this.appService.getCurrentUserId(), limit: limit, offset: offset, text: text, asc: asc
    };
    this.getData(filter).pipe(mergeMap((signatureBookData: SignatureBookData[]) => from(signatureBookData)),
      filterRx((signatureBookData: SignatureBookData) => {
        return signatureBookData.status === SignatureBookData.StatusEnum.STARTED || signatureBookData.status === SignatureBookData.StatusEnum.DECLINED;
      }), toArray(), tap((res: SignatureBookData[]) => {
        let items = res;
        if (!search && this.getAll() && this.getAll().items) {
          items = [... this.getAll().items, ...res];
        }

        this.store({
          ...this.getAll(), limit: limit, offset: offset + limit, items: items
        });
        this.getAll().enableCallWhenScrolling =  !(res.length < this.getAll().limit);
      })).toPromise().catch((error: ApiError) => this.alertService.errorApi(error));
  }
  }

  reset() {
    this.store(undefined);
  }

  search(text: string, sortAsc: boolean) {
    this.getAll().enableCallWhenScrolling = true;
    this.getAll().offset = 0;
    this.getAll().text = text;
    this.getAll().asc = sortAsc;

    this.fetch(true);
  }

  removeItem(itemToRemove: SignatureBookData) {

    const tempItems = this.getAll().items;
    const index = tempItems.findIndex((item: SignatureBookData) => item.document.id === itemToRemove.document.id);

    if (index > -1) {
      tempItems.splice(index, 1);
      this.store({
        ...this.getAll(), items: tempItems
      });
    }
  }

  incrementCommentCounter(sessionId, stepId) {
    let items = this.getAll().items;

    const allIndexToUpdate = [];

    items = items.filter((item, index) => {
      if (item.sessionId === sessionId && stepId === stepId) {
        allIndexToUpdate.push(index);
      }
    });

    allIndexToUpdate.forEach(index => {
      items[index].additionalSession.commentNumber++;
    });

    this.store({
      ...this.getAll(), items: items
    });
  }

  changeDocumentStatus(itemToRemove: SignatureBookData, status: SignatureData.StatusEnum) {
    const items = [...this.getAll().items];
    const index = items.findIndex((signatureData: SignatureBookData) => signatureData.document.id === itemToRemove.document.id);

    if (index > -1) {
      const currentUserIndex: number = this.currentSignerDataIndex(items[index].signatureBookSignerList);
      if (currentUserIndex > -1) {
        items[index].signatureBookSignerList[currentUserIndex].signatureStatus = status;
      }
    }

    this.store({
      ...this.getAll(), items: items
    });
  }

  private getData(filter: SignatureBookFilter) {
    return this.signatureBookService.getSignatureBookList(filter).pipe(take(1));
  }

  private currentSignerDataIndex(signersData: SignatureBookSignerData[]): number {
    return signersData.findIndex(
      (signerData: SignatureBookSignerData) => signerData.stepEnduser.enduser.id === this.appService.getCurrentUserId());
  }
}
