/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EnduserPreferenceData } from './enduserPreferenceData';


/**
 * User preferences
 */
export interface PreferenceData { 
    /**
     * Lang
     */
    lang?: PreferenceData.LangEnum;
    /**
     * Serialized preferences (class fro JSON)
     */
    preferences?: EnduserPreferenceData;
}
export namespace PreferenceData {
    export type LangEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LangEnum = {
        EN: 'EN' as LangEnum,
        FR: 'FR' as LangEnum,
        DE: 'DE' as LangEnum,
        PT: 'PT' as LangEnum,
        IT: 'IT' as LangEnum,
        NL: 'NL' as LangEnum,
        SP: 'SP' as LangEnum
    };
}
