import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import flattenDeep from 'lodash/flattenDeep';
import {SessionService, SprofileService} from 'luxtrust-cosi-api';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {EnduserCircleService} from 'luxtrust-cosi-api/api/enduserCircle.service';
import {SignatureWorkflowService} from 'luxtrust-cosi-api/api/signatureWorkflow.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {StepRemindersService} from 'luxtrust-cosi-api/api/stepReminders.service';
import {StepTagService} from 'luxtrust-cosi-api/api/stepTag.service';
import {CircleEnduserData} from 'luxtrust-cosi-api/model/circleEnduserData';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {WorkflowData} from 'luxtrust-cosi-api/model/workflowData';
import {WorkflowFilter} from 'luxtrust-cosi-api/model/workflowFilter';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/internal/operators';
import {SprofileKey} from 'src/app/services/enum/sprofile-key';
import {Objects} from 'src/app/services/utils/objects';
import {Strings} from 'src/app/services/utils/strings';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {DownloadService} from '../../../services/services/download.service';
import {SprofileWrapperService} from '../../../services/services/sprofile-wrapper.service';
import {ButtonDelegateContext} from '../../../shared/components/session-delegate/button/button-delegate.component';
import {ModalConfiguratorComponent} from '../components/wizard-step-configurator/modal-configurator/modal-configurator.component';
import {WizardService} from '../wizard/services/wizard.service';
import TypologyEnum = DocumentData.TypologyEnum;

@Component({
  templateUrl: './step.component.html', styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit, OnDestroy {

  SignatureStatusEnum = WorkflowData.SignatureStatusEnum;
  session: SessionData;
  step: StepData;

  workflows: {
    signatureDocument?: DocumentData,
    documents: DocumentData[],
    workflow: WorkflowData,
    mustSign: boolean
  }[] = [];
  documents: DocumentData[];

  workflowsAggregated: any[] = [];
  viewDocuments: DocumentData[];
  profiles: SprofileData[] = [];
  endusers: { [id: number]: EnduserData } = {};
  stepEndusers: StepEnduserData[];
  stepEnduserIds: number[];
  readonly stepStatusEnum = StepData.StatusEnum;
  alreadySigned = false;
  private routeSubscription: Subscription;
  comToParapheur = false;
  canSeeFlowChart: boolean;

  currentUserManager = false;

  comeFromMissingTag = false;
  comeFromDocumentsToView = false;
  currentStepEnduser: StepEnduserData;
  @ViewChild('metadataSection', {
    read: ElementRef, static: false
  }) metadataSection;
  @ViewChild('appendicesSection', {
    read: ElementRef, static: false
  }) appendicesSection;
  configEnabled: boolean;
  reminderEnabled: boolean;
  isWatcher = false;
  readonly buttonDelegateContext = ButtonDelegateContext.STEP;
  canSeeSprofile = true;
  documentCancelActionButtonEnabled = true;
  documentSuspendActionButtonEnabled = true;
  openOrNotModalCancel = false;
  openOrNotModalSuspend = false;

  suspending = false;
  cancelling = false;
  continuing = false;
  addingReminders = false;
  downloading = false;

  constructor(private route: ActivatedRoute,
              private modal: NgbModal,
              private appService: AppService,
              public translateService: TranslateService,
              private stepService: StepService,
              private stepEnduserService: StepEnduserService,
              private sprofileService: SprofileWrapperService,
              private enduserService: EnduserService,
              private documentService: DocumentService,
              private workflowService: SignatureWorkflowService,
              private downloadService: DownloadService,
              private stepTagService: StepTagService,
              private wizardService: WizardService,
              private enduserCircleService: EnduserCircleService,
              private alertService: AlertService,
              private sessionService: SessionService,
              private sprofilService: SprofileService,
              private stepRemindersService: StepRemindersService) {
  }

  get checkStepActions() {
    return (this.step.status === this.stepStatusEnum.SKIPPED || this.step.status === this.stepStatusEnum.SUSPENDED || this.step.status === this.stepStatusEnum.CANCELED);
  }

  ngOnInit() {
    this.checkSessionStorage();
    this.canSeeFlowChart = !this.appService.userHasEntitlement(this.appService.ent.forbid_session);
    this.initializeData();
    this.wizardService.sessionIsTemplate = false;
  }

  private checkSessionStorage() {
    if (sessionStorage.getItem('comToParapheur')) {
      if (sessionStorage.getItem('comToParapheur') === 'true') {
        this.comToParapheur = true;
      }
    }
    if (sessionStorage.getItem('comeFromMissingTag') && sessionStorage.getItem('comeFromMissingTag') === 'true') {
      this.comeFromMissingTag = true;
      sessionStorage.removeItem('comeFromMissingTag');
    }
    if (sessionStorage.getItem('comeFromDocumentsToView') && sessionStorage.getItem('comeFromDocumentsToView') === 'true') {
      this.comeFromDocumentsToView = true;
      sessionStorage.removeItem('comeFromDocumentsToView');
    }
  }

  private initializeData() {
    this.sprofileService.getSprofileList().toPromise().then(profiles => {
      this.profiles = profiles;
      this.routeSubscription = this.route.data.subscribe((data: Data) => {
        this.session = data['session'];
        this.step = data['step'];
        this.getSprofileValues();
        this.stepTagService.getStepTagList(this.session.id, this.step.id).subscribe((tagList) => {
          this.step.tags = tagList;
          if (tagList.find(tag => tag.alias === 'SIGNATURE_STATUS_SPROFILE_DISABLED')) {
            this.canSeeSprofile = false;
          }
        }, (error: ApiError) => this.alertService.errorApi(error));
        this.checkConfigEnabled();
        this.checkReminderEnabled();
        this.route.parent.params.subscribe(() => {
          this.documentService.getAllDocuments(this.session.id, this.step.id).pipe(take(1)).subscribe(documents => {
            this.viewDocuments = documents.filter(d => d.typology === TypologyEnum.TOVIEW);
          }, (error: ApiError) => this.alertService.errorApi(error));
        });

        const workflowFilter = {enduserId: this.appService.getCurrentUserId()} as WorkflowFilter;
        Promise.all([
          this.stepEnduserService.stepEnduserList(this.session.id, this.step.id).toPromise(),
          this.workflowService.getWorkflowList(this.session.id, this.step.id).toPromise()
            .then(ws => ws.filter(workflow => workflow.sprofileCode !== null)),
          this.workflowService.searchWorkflows(this.session.id, this.step.id, workflowFilter).toPromise()
        ]).then(([stepEndusers, workflowsUnsorted, userWorkflows]) => {
          this.stepEndusers = stepEndusers;
          this.stepEnduserIds = stepEndusers.map(stepEnduser => stepEnduser.enduser.id);
          const signedDocs = userWorkflows.find(wf => wf.signatureStatus !== 'STARTED');
          if (signedDocs) {
            this.alreadySigned = true;
          }
          stepEndusers.forEach(stepEnduser => {
            this.endusers[stepEnduser.enduser.id] = stepEnduser.enduser;
            if (stepEnduser.enduser.id === this.appService.getCurrentUserId() && stepEnduser.manager) {
              this.currentUserManager = true;
            }
          });

          const currentUserId = this.appService.getCurrentUserId();
          const promises: Promise<CircleEnduserData[]>[] = this.stepEndusers.map(stepEnduser => {
            if (stepEnduser.enduser.circle) {
              return this.enduserCircleService.getCircleEnduserList(stepEnduser.enduser.id).toPromise();
            }
            return Promise.resolve([{enduserId: stepEnduser.enduser.id}]);
          });
          Promise.all(promises).then(stepEndusersInformation => {
            const flattenStepEndusersInformation: CircleEnduserData[] = flattenDeep(stepEndusersInformation);
            const existingStepEnduserInformation = flattenStepEndusersInformation.find(current => current.enduserId === currentUserId);
            if (existingStepEnduserInformation) {
              const id = existingStepEnduserInformation.circleId ? existingStepEnduserInformation.circleId : existingStepEnduserInformation.enduserId;
              return this.stepEnduserService.getStepEndUser(id, this.session.id, this.step.id).subscribe((enduser) => {
                this.currentStepEnduser = enduser;
                this.isWatcher = enduser.watcher && !enduser.manager && !enduser.signer;
              });
            }
          }).catch((error: ApiError) => this.alertService.errorApi(error) );

          const workflows = this.sortWorkflowsCurrentEnduserFirst(workflowsUnsorted);
          workflows.forEach(workflow => {
            if (workflow.sourceSignatureId) {
              const previousWkf = this.workflows.find(w => w.workflow.signatureId === workflow.sourceSignatureId);
              if (previousWkf) {
                this.extractSignatureDocument(workflow).pipe(take(1)).subscribe((document: DocumentData) => {
                  previousWkf.documents.push(document);
                }, (error: ApiError) => this.alertService.errorApi(error));
              }
              return;
            }

            if (!this.endusers[workflow.enduserId] && !(+workflow.enduserId < 0)) {
              // this.enduserService.getEnduser(workflow.enduserId).pipe(take(1))
              //   .subscribe((enduser: EnduserData) => this.endusers[workflow.enduserId] = enduser);
              this.endusers[workflow.enduser.id] = workflow.enduser;
            }
            const wkf = {
              signatureDocument: undefined, documents: [], workflow: workflow, mustSign: false, sprofileLabel: ''
            };
            let documentObs: Observable<DocumentData>;
            const profile = this.profiles.find(p => p.code === workflow.sprofileCode);
            if (profile.detached) {
              documentObs = this.documentService.getDocument(workflow.documentId, this.session.id, this.step.id);
              this.extractSignatureDocument(workflow).pipe(take(1)).subscribe(
                (document: DocumentData) => wkf.signatureDocument = document,
                (error: ApiError) => this.alertService.errorApi(error)
              );
            } else {
              documentObs = this.extractSignatureDocument(workflow);
            }

            documentObs.pipe(take(1)).subscribe((document: DocumentData) => {
              wkf.documents.push(document);
              wkf.mustSign = this.userMustSign(workflow, userWorkflows, workflows);
              this.workflows.push(wkf);
              this.workflowsAggregated = this.getWorkflows();
              setTimeout(() => {
                if (this.comeFromMissingTag) {
                  this.scrollToMetadataSection();
                }
                if (this.comeFromDocumentsToView) {
                  this.scrollToAppendicesSection();
                }
              });
            }, (error: ApiError) => this.alertService.errorApi(error));
          });
        }).catch((error: ApiError) => {
          this.alertService.errorApi(error);
        });
        this.documentService.getAllDocuments(this.session.id, this.step.id).pipe(take(1)).subscribe(documents => {
          this.viewDocuments = documents.filter(d => d.typology === TypologyEnum.TOVIEW);
          this.documents = documents;
        }, (error: ApiError) => this.alertService.errorApi(error));
      });
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  download(id: number, name: string) {
    this.downloading = true;
    this.documentService.downloadDocument(id, this.session.id, this.step.id).toPromise().then(res =>
      this.downloadService.download(res, name)
    ).catch(() => {
      this.alertService.warning('STEP.NO_DOCUMENT');
    })
      .then(value => this.downloading = false);
  }

  isSigned(workflows: WorkflowData[]) {
    return workflows.every(workflow => workflow.signatureStatus === WorkflowData.SignatureStatusEnum.SIGNED);
  }

  isRejected(workflows: WorkflowData[]): boolean {
    return workflows.some(workflow => workflow.signatureStatus === WorkflowData.SignatureStatusEnum.DECLINED);
  }

  addReminder() {
    this.addingReminders = true;
    Promise.all(
      this.workflows
        .filter(currentWorkflow => currentWorkflow.workflow.signatureStatus !== 'SIGNED')
        .map(currentWorkflow => {
          const reminderDate = new Date(Date.now() + 10000);
          return this.stepRemindersService.updateStepReminders(this.session.id, this.step.id, ({
            remindOn: reminderDate, enduserId: currentWorkflow.workflow.enduserId
          })).toPromise();
        })
    ).then((results) => {
      this.alertService.success('STEP.REMINDERS_CREATED');
    }).catch((apiError: ApiError) =>
      this.alertService.errorApi(apiError)
    ).then(value => this.addingReminders = false);
  }

  refreshStepEndusers() {
    this.workflows = [];
    this.workflowsAggregated = [];
    this.initializeData();
  }

  getWorkflows() {
    return this.workflows.reduce((rWorkflows, workflow) => {
      if (!rWorkflows.find(rWorkflow => rWorkflow.document.id === workflow.documents[0].id)) {
        rWorkflows.push({
          document: workflow.documents[0], users: this.workflows.reduce((acc2, w2) => {
            if (w2.workflow.documentId === workflow.documents[0].id) {
              const sprofile = this.profiles.find(p => p.code === w2.workflow.sprofileCode);
              acc2.push({
                ...w2.workflow, mustSign: w2.mustSign, sprofileLabel: sprofile.label
              });
            }
            return acc2;
          }, [])
        });
      }
      return rWorkflows;
    }, []);
  }

  linkToDocument(workflow: any) {
    const wf = this.getCurrentUserForDocument(workflow.users);
    if (wf) {
      return ['workflow', wf.signatureId];
    }
    return ['workflow', workflow.users[0].signatureId, 'as', workflow.users[0].enduserId];
  }

  linkToAnnex(document: DocumentData) {
    return ['workflow', 'document', 'view', document.id];
  }

  getCurrentUserForDocument(users: WorkflowData[]) {
    return users.find(u => u.enduserId === this.appService.getCurrentUserId());
  }

  /**
   * Return if the user who have @userWorkflows and should @workflow must sign or not.
   * If one of workflow on allWorkFlows is rejected the user should not sign it.
   * @param workflow
   * @param userWorkflows
   * @param allWorkFlows
   */
  private userMustSign(workflow: WorkflowData, userWorkflows: WorkflowData[], allWorkFlows: WorkflowData[]) {
    return workflow.signatureStatus !== WorkflowData.SignatureStatusEnum.SIGNED && userWorkflows.some(
      w => w.enduserId === workflow.enduserId && w.stepId === workflow.stepId && w.signatureId === workflow.signatureId) && !this.isRejected(
      allWorkFlows);
  }

  private extractSignatureDocument(workflow: WorkflowData): Observable<DocumentData> {
    return this.documentService.getDocument(workflow.signatureDocumentId, this.session.id, this.step.id);
  }

  private sortWorkflowsCurrentEnduserFirst(workflows: WorkflowData[]): WorkflowData[] {
    const currentEnduserId = this.appService.getCurrentUserId();
    return workflows.filter((workflow: WorkflowData) => (workflow.enduserId === currentEnduserId || workflow.meInCircle))
      .concat(workflows.filter((workflow: WorkflowData) => (workflow.enduserId !== currentEnduserId && !workflow.meInCircle)));
    // fixme: .sort(w => w.sourceSignatureId ? +1 : -1); // Child signatures at the end
  }

  public getCircleDisplay(enduser: EnduserData) {
    const stepEnduser = this.stepEndusers.find(currentStepEnduser => currentStepEnduser.enduser.id === enduser.id);
    const expectedSignerNb: number = stepEnduser.expected;
    const signersText = `${expectedSignerNb} ${this.translateService.instant(expectedSignerNb > 1 ? 'STEP.SIGNERS' : 'STEP.SIGNER')}`;
    if (this.isDelegation(enduser)) {
      return `${enduser.lastName.replace(/{[(\w+\-)]+}/g, '')}`;
    }
    return `${enduser.lastName.replace(/{[(\w+\-)]+}/g, '')} (${signersText})`;
  }

  scrollToMetadataSection() {
    this.metadataSection.nativeElement.scrollIntoView();
  }

  scrollToAppendicesSection() {
    this.appendicesSection.nativeElement.scrollIntoView();
  }

  checkConfigEnabled() {
    this.stepService.getStepConfiguration(this.session.id, this.step.id).subscribe(config => {
      this.configEnabled = config.managerConfig;
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  checkReminderEnabled() {
    this.reminderEnabled = true;
    this.stepTagService.getStepTagList(this.session.id, this.step.id).subscribe(tagList => {
      if (tagList.find(tag => tag.alias === 'REMINDER_BUTTON_DISABLE')) { // fixme: to be moved
        this.reminderEnabled = false;
      }
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  openConfigModal() {
    const modalConfiguratorRef = this.modal.open(ModalConfiguratorComponent, {backdrop: false, size: 'lg'});
    modalConfiguratorRef.componentInstance.session = this.session;
    modalConfiguratorRef.componentInstance.step = this.step;
    modalConfiguratorRef.componentInstance.currentUserId = this.currentStepEnduser.enduser.id;
    modalConfiguratorRef.componentInstance.comingFromStep = true;
    modalConfiguratorRef.result.then(() => {
      this.refreshStepEndusers();
    });

  }

  suspend() {
    this.suspending = true;
    forkJoin([this.stepService.suspendStep(this.session.id, this.step.id), this.sessionService.suspendSession(this.session.id)]).pipe(take(1))
      .toPromise()
      .then(() => this.initStepData(), (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.suspending = false);
  }

  cancel() {
    this.cancelling = true;
    forkJoin([this.stepService.cancelStep(this.session.id, this.step.id), this.sessionService.cancelSession(this.session.id)]).pipe(take(1))
      .toPromise()
      .then(() => this.initStepData(), (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.cancelling = false);
  }

  continue() {
    this.continuing = true;
    forkJoin([this.stepService.goonStep(this.session.id, this.step.id), this.sessionService.goonSession(this.session.id)]).pipe(take(1))
      .toPromise()
      .then(() => this.initStepData(), (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.continuing = false);
  }

  initStepData() {
    this.stepService.getStep(this.session.id, this.step.id).toPromise().then(step => {
      this.step = step;
      this.openOrNotModalCancel = false;
      this.openOrNotModalSuspend = false;
    });
  }

  openModalCancel() {
    this.openOrNotModalCancel = true;
  }

  openModalSuspend() {
    this.openOrNotModalSuspend = true;
  }

  getSprofileValues() {
    this.sprofilService.getSprofileKeyvalValList(this.step.id).toPromise()
      .then(object => {
        let value = new Objects(object).valueForKey(SprofileKey.DOCUMENT_CANCEL_BUTTON_ACTION_ENABLED);
        if (value) {
          this.documentCancelActionButtonEnabled = new Strings(value).asBoolean();
        }
        value = new Objects(object).valueForKey(SprofileKey.DOCUMENT_SUSPEND_BUTTON_ACTION_ENABLED);
        if (value) {
          this.documentSuspendActionButtonEnabled = new Strings(value).asBoolean();
        }
      });
  }

  isStepStarted() {
    return this.step.status === StepData.StatusEnum.STARTED;
  }

  isStepSuspend() {
    return this.step.status === StepData.StatusEnum.SUSPENDED;
  }

  isDelegation(enduser: EnduserData) {
    return enduser.lastName.substring(0, 4) === 'P/O:';
  }

  get workflowsAggregatedSorted() {
    if(this.workflowsAggregated) {
      return this.workflowsAggregated.sort((a, b) => a.document.originalDocumentId > b.document.originalDocumentId ? 1 : -1);
    }
    return [];
  }

  get viewDocumentsSorted() {
    if(this.viewDocuments) {
      return this.viewDocuments.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
    }
    return [];
  }

}
