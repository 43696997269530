/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Enduser to be created
 */
export interface CreateEnduserPayload { 
    /**
     * Attributes of the enduser
     */
    attributes?: { [key: string]: string; };
    /**
     * Organisation name
     */
    businessName?: string;
    /**
     * Directory alias, if null the requester's directory is used otherwise the provided directory alias must be part of the requester's directories
     */
    directoryAlias?: string;
    /**
     * Email
     */
    email?: string;
    /**
     * First name
     */
    firstName?: string;
    /**
     * Last name
     */
    lastName?: string;
    /**
     * Locale code
     */
    localeCode?: CreateEnduserPayload.LocaleCodeEnum;
    /**
     * Organisation id
     */
    organisationId?: number;
    /**
     * Phone number
     */
    phone?: string;
    /**
     * Preferences of the enduser
     */
    preferences?: string;
    /**
     * User id in the specific directory, if set, the directory alias must be too
     */
    userId?: string;
}
export namespace CreateEnduserPayload {
    export type LocaleCodeEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LocaleCodeEnum = {
        EN: 'EN' as LocaleCodeEnum,
        FR: 'FR' as LocaleCodeEnum,
        DE: 'DE' as LocaleCodeEnum,
        PT: 'PT' as LocaleCodeEnum,
        IT: 'IT' as LocaleCodeEnum,
        NL: 'NL' as LocaleCodeEnum,
        SP: 'SP' as LocaleCodeEnum
    };
}
