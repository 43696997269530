import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import xml2js from 'xml2js';
import {TranslateService} from 'src/app/core/service/translate.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidatorHelper} from 'src/app/services/validators/validators.helper';
import {AlertService} from '../../../services/services/alert-service';
import {ValidateApiControllerService} from 'luxtrust-cosi-api/api/validateApiController.service';
import {ApiError} from "../../../error/api-error.model";
import {environment} from '../../../../environments/environment';

// MODELS
class Signature {
  validationStatus: string;
  signatureQuality: string;
  signer: string;
  claimedSigningTime: string;  
}

class ValidationReport {
  validationStatus: string;
  signatures: Signature[];
}

@Component({
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss']
  })
export class ValidationComponent implements OnInit {

  // CONSTS
  readonly FILE_SIZE_LIMIT: number;
  readonly SUPPORTED_FORMAT: string;
  readonly NOT_AVAILABLE: string;

  // VARS
  validationForm: FormGroup;
  placeHolder: string;
  showResult: boolean;
  file: Blob;
  validationReport: ValidationReport; 

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private validationService: ValidateApiControllerService) {
      this.FILE_SIZE_LIMIT = environment.FILE_SIZE_LIMIT;
      this.SUPPORTED_FORMAT = 'application/pdf';
      this.NOT_AVAILABLE = 'N/A';      
      this.showResult = false;
      this.validationReport = {
        validationStatus: '',
        signatures: []
      };
  }

  ngOnInit() {    
    this.translate.stream('VALIDATION.FORM.PLACEHOLDER').subscribe(result => this.placeHolder = result);
    this.validationForm = this.formBuilder.group({      
      file: ['', [
        Validators.required,
        ValidatorHelper.fileSizeLimitValidator(this.FILE_SIZE_LIMIT),        
        ValidatorHelper.fileTypeAcceptedValidator([this.SUPPORTED_FORMAT])
      ]]
    }, {});
  }
  
  updateInput(event: EventListener) {    
    if (event && event.length > 0) {      
      this.placeHolder = event[0].name;
    }    
  }

  getFilename() {
    if (this.validationForm.value 
      && this.validationForm.value.file
      && this.validationForm.value.file.length > 0) {
      return this.validationForm.value.file[0].name;
    } else return '';
  }

  onSubmit() {
    this.spinnerService.show();
    // call service here and submit,    
    const payload : Blob = this.validationForm.value.file[0];
    this.validationService.validate(payload).toPromise().then(responseFile => {
      this.file = responseFile;
      const reader = new FileReader();
      reader.readAsText(responseFile);
      reader.onload = (e: any) => {
        const response = reader.result;
        this.showResult = true;              
        xml2js.parseString(response, (err, verifyResponse) => {
          this.spinnerService.hide();
          // getting validation report
          let vr : any = {};
          let signatures: Signature[] = [];
          let indicationId = '';
          const optionalOutputs = verifyResponse["dss:VerifyResponse"] ? verifyResponse["dss:VerifyResponse"]["dss:OptionalOutputs"] : {};
          if (optionalOutputs && optionalOutputs.length > 0) {
            vr = optionalOutputs[0]["luxtrustvr:ValidationReport"] ? optionalOutputs[0]["luxtrustvr:ValidationReport"][0] : {};
          }

          // check if signatures exist 
          let formatReport = vr["luxtrustvr:FormatReport"] ? vr["luxtrustvr:FormatReport"][0] : {};
          if (formatReport["luxtrustvr:Indication"] && formatReport["luxtrustvr:Indication"].length > 0) {
            indicationId = formatReport["luxtrustvr:Indication"][0]["luxtrustvr:IndicationId"][0];
          }

          if (indicationId === 'no.signatures.found') {
            this.validationReport.signatures = undefined;
          } else {
            // getting signatures
            if (vr && vr["luxtrustvr:PAdESReport"] && ["luxtrustvr:PAdESReport"].length > 0) {
              signatures = vr["luxtrustvr:PAdESReport"][0]["luxtrustvr:SignatureReport"];
            }
            signatures.map((signature) => {
              let validationStatus = signature["luxtrustvr:ValidationStatus"] && signature["luxtrustvr:ValidationStatus"].length > 0 ? 
                signature["luxtrustvr:ValidationStatus"][0] : this.NOT_AVAILABLE;
                let signatureQuality = signature["luxtrustvr:LegalLevel"] && signature["luxtrustvr:LegalLevel"].length > 0 ?
                signature["luxtrustvr:LegalLevel"][0] : this.NOT_AVAILABLE;
              let signer = signature["luxtrustvr:Signer"] && signature["luxtrustvr:Signer"].length > 0 ?
                signature["luxtrustvr:Signer"][0] : this.NOT_AVAILABLE;
              let claimedSigningTime = signature["luxtrustvr:ClaimedSigningTime"] && signature["luxtrustvr:ClaimedSigningTime"].length > 0 ?
                signature["luxtrustvr:ClaimedSigningTime"][0] : this.NOT_AVAILABLE;
              let s : Signature = {
                validationStatus: validationStatus,
                signatureQuality: signatureQuality,
                signer: this.extractCN(signer),
                claimedSigningTime: this.getDate(claimedSigningTime),
              }
              this.validationReport.signatures.push(s);
            })
          }
          // set validation report status
          this.validationReport.validationStatus = vr["luxtrustvr:FormatReport"] && vr["luxtrustvr:FormatReport"].length > 0 ?
          vr["luxtrustvr:FormatReport"][0]["luxtrustvr:ValidationStatus"][0] : this.NOT_AVAILABLE;
        });
      }
    }).catch((error: ApiError) => {      
      this.alertService.error('VALIDATION.ERROR');
      this.spinnerService.hide();
      this.onReset();
      console.error(error);
    });         
  }

  onReset() {        
    this.validationForm.controls['file'].setValue([]);
    this.validationForm.controls['file'].setErrors(null);
    this.validationForm.markAsUntouched();    
    this.placeHolder = this.translate.instant('VALIDATION.FORM.PLACEHOLDER');    
  }

  onDownload() {
    const filename = `validation-report-${this.getDate(null)}.xml`;
    const blob = new Blob([this.file], {type: 'application/xml'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE
      window.navigator.msSaveOrOpenBlob(blob, filename);
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      //Chrome & Firefox
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  onClose() {
    this.onReset();
    this.validationReport = {
      validationStatus: '',
      signatures: []
    };
    this.showResult = false;
  }

  private extractCN(dn: string) : string {
    if (dn && dn !== '' && dn !== this.NOT_AVAILABLE) {
      const elements = dn.split(',');
      const element = elements.find(e => e.trim().substring(0, 3) === 'CN=');
      const chuncks = element ? element.split('='): [];
      return chuncks.length > 1 ? chuncks[1].trim() : dn;
    }
    return dn;
  }

  private getDate(str: string) : string {
    if (!str || str.length === 0) {
      return (new Date().toISOString()).substring(0, 10);
    }
    const dates = str.split('T');
    if (dates.length === 2) {
      const part1 = dates[0];
      const part2 = dates[1].split('.')[0];
      return `${part1}  ${part2}`;
    }
    return str;
  }

}
