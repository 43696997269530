/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Signature watermark
 */
export interface WatermarkData { 
    /**
     * Watermark identifier
     */
    id?: number;
    /**
     * Watermark image
     */
    image?: string;
    /**
     * Watermark name
     */
    name?: string;
    /**
     * Target identifier
     */
    targetId?: string;
    /**
     * Watermark type
     */
    type?: WatermarkData.TypeEnum;
}
export namespace WatermarkData {
    export type TypeEnum = 'USER' | 'ORGANISATION' | 'SYSTEM';
    export const TypeEnum = {
        USER: 'USER' as TypeEnum,
        ORGANISATION: 'ORGANISATION' as TypeEnum,
        SYSTEM: 'SYSTEM' as TypeEnum
    };
}
