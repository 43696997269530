import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/services/app.service';

@Component({
    templateUrl: './logout.component.html'
  })
export class LogoutComponent implements OnInit {
    constructor(private readonly appService: AppService, private router: Router) { }
    ngOnInit() {
        this.appService.revoke().then(() => {
            this.router.navigate(['/login', this.appService.getUser().tenantName]);
            this.appService.logout();
        });
    }
}
