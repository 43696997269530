/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateStepEnduserPayload } from '../model/createStepEnduserPayload';
import { StepEnduserData } from '../model/stepEnduserData';
import { UpdateStepEnduserPayload } from '../model/updateStepEnduserPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StepEnduserService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Link enduser and step
     * 
     * @param sessionId Session
     * @param stepId Step
     * @param payload Step enduser to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStepEnduser(sessionId: number, stepId: number, payload?: CreateStepEnduserPayload, observe?: 'body', reportProgress?: boolean): Observable<StepEnduserData>;
    public createStepEnduser(sessionId: number, stepId: number, payload?: CreateStepEnduserPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepEnduserData>>;
    public createStepEnduser(sessionId: number, stepId: number, payload?: CreateStepEnduserPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepEnduserData>>;
    public createStepEnduser(sessionId: number, stepId: number, payload?: CreateStepEnduserPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createStepEnduser.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling createStepEnduser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<StepEnduserData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/endusers`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlink enduser and step
     * 
     * @param enduserId Enduser
     * @param sessionId Session
     * @param stepId Step
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStepEnduser(enduserId: string, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteStepEnduser(enduserId: string, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteStepEnduser(enduserId: string, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteStepEnduser(enduserId: string, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling deleteStepEnduser.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteStepEnduser.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling deleteStepEnduser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/endusers/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get step enduser
     * 
     * @param enduserId Enduser
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the enduser belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStepEndUser(enduserId: string, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<StepEnduserData>;
    public getStepEndUser(enduserId: string, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepEnduserData>>;
    public getStepEndUser(enduserId: string, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepEnduserData>>;
    public getStepEndUser(enduserId: string, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling getStepEndUser.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getStepEndUser.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getStepEndUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StepEnduserData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/endusers/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update step-enduser
     * 
     * @param enduserId Enduser
     * @param sessionId Session
     * @param stepId Step
     * @param payload Step enduser to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateStepEnduser(enduserId: string, sessionId: number, stepId: number, payload?: UpdateStepEnduserPayload, observe?: 'body', reportProgress?: boolean): Observable<StepEnduserData>;
    public partialUpdateStepEnduser(enduserId: string, sessionId: number, stepId: number, payload?: UpdateStepEnduserPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepEnduserData>>;
    public partialUpdateStepEnduser(enduserId: string, sessionId: number, stepId: number, payload?: UpdateStepEnduserPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepEnduserData>>;
    public partialUpdateStepEnduser(enduserId: string, sessionId: number, stepId: number, payload?: UpdateStepEnduserPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling partialUpdateStepEnduser.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling partialUpdateStepEnduser.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling partialUpdateStepEnduser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<StepEnduserData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/endusers/${encodeURIComponent(String(enduserId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace step-enduser
     * 
     * @param enduserId Old enduser
     * @param newEnduserId New enduser
     * @param sessionId Session
     * @param stepId Step
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public replaceStepEnduser(enduserId: string, newEnduserId: string, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public replaceStepEnduser(enduserId: string, newEnduserId: string, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public replaceStepEnduser(enduserId: string, newEnduserId: string, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public replaceStepEnduser(enduserId: string, newEnduserId: string, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling replaceStepEnduser.');
        }

        if (newEnduserId === null || newEnduserId === undefined) {
            throw new Error('Required parameter newEnduserId was null or undefined when calling replaceStepEnduser.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling replaceStepEnduser.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling replaceStepEnduser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/endusers/${encodeURIComponent(String(enduserId))}/${encodeURIComponent(String(newEnduserId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of step endusers
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the endusers belong
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stepEnduserList(sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<StepEnduserData>>;
    public stepEnduserList(sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StepEnduserData>>>;
    public stepEnduserList(sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StepEnduserData>>>;
    public stepEnduserList(sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling stepEnduserList.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling stepEnduserList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StepEnduserData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/endusers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of step endusers involved in preparation step
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the endusers belong
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stepEnduserListInvolvedInPreparation(sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<StepEnduserData>>;
    public stepEnduserListInvolvedInPreparation(sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StepEnduserData>>>;
    public stepEnduserListInvolvedInPreparation(sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StepEnduserData>>>;
    public stepEnduserListInvolvedInPreparation(sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling stepEnduserListInvolvedInPreparation.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling stepEnduserListInvolvedInPreparation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StepEnduserData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/endusers/preparation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
