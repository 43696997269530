/**
 * SProfile keys for key-values.
 * Used to add properties to SProfiles.
 */
export enum SprofileKey {
  DOCUMENT_REJECT_BUTTON_ACTION_ENABLED = 'action.reject',
  DOCUMENT_CANCEL_BUTTON_ACTION_ENABLED = 'action.cancel',
  DOCUMENT_SUSPEND_BUTTON_ACTION_ENABLED = 'action.suspend',
  WORKFLOW_MULTIPLE_DOCUMENT_COUNT_DISABLED = 'multi-document.count.disabled'
}
