export class CloneUtils {

  static cloneArray<T>(array: T[]): T[] {
    const newArray: T[] = [];
    array.forEach(item => {
      newArray.push(this.cloneObject(item));
    });
    return newArray;
  }

  static cloneObject<T>(object: T): T {
    return Object.assign({}, object);
  }

  static cloneStringArray(array: string[]): string[] {
    return [...array];
  }

}
