<ng-container>
  <label *ngIf="mutual" class="title">{{title}}</label>

  <app-items-table [items]="documents" [noResult]="noResult" [singleList]="true">
    <div *ngIf="!mutual" action>
      <label *ngIf="!isConfigurator"  class="title">{{title}}</label>
      <button *ngIf="isConfigurator && canAdd" (click)="addDocumentWithConfirmation()" [disabled]="disabled"
               class="btn btn-primary w-100">{{'WIZARD.DOCUMENTS.ADD_DOCUMENT' | translate}}</button>
      <div>
        <input  #uploadField (change)="uploadDocument($event)"
                [accept]="acceptedMimeTypes"
                [id]="id" [disabled]="disabled"
                class="input-file" multiple
                type="file"/>
        <label *ngIf="!isConfigurator" [for]="id" class="btn btn-primary btn-block" [class.disabled]="disabled">
          {{buttonLabel}}
        </label>
        <app-input-loader [status]="status"></app-input-loader>
      </div>
    </div>

    <ng-template #itemsCards let-document>
      <app-document-card
        (onAddAcroform)="onAddAcroform.emit(document)"
        (onClone)="onClone.emit($event)"
        (onDelete)="onDelete.emit($event)"
        (onMutual)="onMutual.emit($event)"
        (onMutualAppendixIn)="onMutualAppendixIn.emit($event)"
        (onMutualDocumentIn)="onMutualDocumentIn.emit($event)"
        (onCloneInConfigurator)="onCloneInConfigurator.emit($event)"
        (onSprofile)="updateDocument(document, $event)"
        (onFailed)="onFailed.emit($event)"
        (openModal)="openModal.emit($event)"
        (onReuploadDoc)="reuploadDocument(document, $event)"
        *ngIf="document && (signingUsers || signersByDocuments)"
        [annexe]="annexe"
        [signers]="signingUsers"
        [signersByDocuments]="signersByDocuments"
        [disabled]="disabled"
        [canSeeSprofile]="canChangeSprofile"
        [document]="document"
        [isLoading]="isLoading"
        [hasFailed]="hasFailed"
        [mutual]="mutual"
        [signatures]="signatures[document.name]"
        [session]="session"
        [step]="step"
        [isConfigurator]="isConfigurator"
        [canClone]="canClone"
        [canDelete]="canDelete()"
        [canUpdate]="canUpdate"
        [hasAtLeast1Signature]="hasAtLeast1Signature"
        [hasStepTagPositionSignatureLocation]="hasStepTagPositionSignatureLocation"
        [sprofiles]="sprofiles">
      </app-document-card>
    </ng-template>
  </app-items-table>

  <div *ngIf="!isConfigurator" class="custom-margin"></div>
</ng-container>
