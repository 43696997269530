<div #header class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'MODAL_DISABLE_SESSION.TITLE' | translate}}</h4>
  <button (click)="activeModal.dismiss()" class="close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body break-words">
  {{'MODAL_DISABLE_SESSION.MESSAGE' | translate:{sessionName: session.label} }}
</div>

<div class="modal-footer">
  <button (click)="delete()" class="btn btn-primary"
          type="button">{{'MODAL_DISABLE_SESSION.DISABLE' | translate}}</button>
  <button (click)="activeModal.close()" class="btn btn-cancel"
          type="button">{{'MODAL_DISABLE_SESSION.CLOSE' | translate}}</button>
</div>
