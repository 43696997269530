<div class="modal-header">
  <label class="modal-title" id="modal-basic-title" >
   {{ 'MODAL_CONFIRMATION_SIGNATURE.TITLE' | translate}}
  </label>
</div>
<div class="modal-body">
  <div class="valid-sign">
      <img src="assets/icons/file_doc_to_sign.svg" style="width:100%; max-width: 125px">
  </div>
</div>
<div class="modal-footer">
  <div class="text-center mb-3">
    <button (click)="close('ok')" class="btn btn-primary" type="button">
      OK
    </button>
  </div>
</div>
