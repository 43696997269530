import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from 'src/app/core/service/translate.service';

import {FlowersService} from 'luxtrust-cosi-api/api/flowers.service';
import {CertificateOrderPayload} from 'luxtrust-cosi-api/model/certificateOrderPayload';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';

@Component({
  templateUrl: './order.component.html', styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  orderForm: FormGroup;
  submitted = false;
  userExternalId: string;
  submitting = false;

  constructor(private translateService: TranslateService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private appService: AppService,
              private flowersService: FlowersService) {
  }

  get holder() {
    return this.orderForm.get('holder') as FormGroup;
  }

  ngOnInit() {
    this.orderForm = this.formBuilder.group({
      holder: this.formBuilder.group({
        firstName: [{value: '', disabled: true}, Validators.required],
        surName: [{value: '', disabled: true}, Validators.required],
        notifyEmail: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', [Validators.required]]
      })
    });

    this.appService.getUser$().subscribe((enduser: EnduserData) => {
      if (enduser) {
        this.holder.setValue({
          firstName: enduser.firstName,
          surName: enduser.lastName,
          notifyEmail: enduser.email,
          phoneNumber: enduser.phone
        });
        this.userExternalId = enduser.id;
      }
    });
  }

  order() {
    this.submitted = true;
    if (!this.orderForm.invalid) {
      this.submitting = true;
      const item = this.orderForm.value;
      const certificatePayload: CertificateOrderPayload = {
        items: [item]
      };

      item.userExternalId = this.userExternalId;

      this.flowersService.createFlowersOrder(certificatePayload).subscribe(() => {
        this.alertService.success('ORDER.SUCCESS');
        this.submitting = false;
      }, (error: ApiError) => {
        this.submitting = false;
        this.alertService.errorApiWithCustomMessage('ORDER.ERROR', error);
      });
    }
  }
}
