<div class="modal-header">
  <h4 class="modal-title">{{'MODAL_CREATE_TEMPLATE.CREATE_TEMPLATE' | translate}}</h4>
  <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form (ngSubmit)="createTemplate()" [formGroup]="form">
  <div class="modal-body">
    <div class="form-group">
      <label for="validationCodeInput">{{'MODAL_CREATE_TEMPLATE.TITLE' | translate}}</label>
      <input #templateName
             [value]="templateNameText"
             aria-describedby="templateNameHelp"
             class="form-control"
             formControlName="templateName"
             id="templateNameInput"
             placeholder="{{'MODAL_CREATE_TEMPLATE.INPUT' | translate}}"
             type="text">
             <span class="text-danger">{{getErrorMessageDateDebut()}}</span>
      <div *ngIf="form.errors">
        <small
          *ngIf="(templateName.dirty || templateName.touched) && templateName.hasError('required')"
          class="text-danger form-text">
          {{'MODAL_CREATE_TEMPLATE.INVALID_REQUIRED' | translate}}
        </small>
        <small
          *ngIf="(templateName.dirty || templateName.touched) && templateName.hasError('maxlength')"
          class="text-danger form-text">
          {{'MODAL_CREATE_TEMPLATE.INVALID_MAX_LENGTH' | translate}}
        </small>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button [disabled]="submitted || form.invalid" class="btn btn-primary"
            type="submit">{{'MODAL_CREATE_TEMPLATE.SUBMIT' | translate}}</button>
    <button (click)="activeModal.dismiss()" class="btn btn-outline-secondary"
            type="button">{{'MODAL_CREATE_TEMPLATE.CANCEL' | translate}}</button>
  </div>
</form>
