/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EnduserData } from './enduserData';


/**
 * Comment on a step
 */
export interface CommentData { 
    /**
     * Creation date
     */
    createdOn?: Date;
    /**
     * Creator enduser data
     */
    enduser?: EnduserData;
    /**
     * Creator enduser
     */
    enduserId?: string;
    /**
     * Comment
     */
    id?: number;
    /**
     * Modification date
     */
    modifiedOn?: Date;
    /**
     * Specific text
     */
    specificText?: string;
    /**
     * Step
     */
    stepId?: number;
    /**
     * Step name
     */
    stepLabel?: string;
    /**
     * Comment content
     */
    text?: string;
    /**
     * Comment type
     */
    type?: CommentData.TypeEnum;
}
export namespace CommentData {
    export type TypeEnum = 'SIMPLE' | 'REJECTION' | 'REINSTATEMENT' | 'REUPLOAD' | 'REMINDER';
    export const TypeEnum = {
        SIMPLE: 'SIMPLE' as TypeEnum,
        REJECTION: 'REJECTION' as TypeEnum,
        REINSTATEMENT: 'REINSTATEMENT' as TypeEnum,
        REUPLOAD: 'REUPLOAD' as TypeEnum,
        REMINDER: 'REMINDER' as TypeEnum
    };
}
