<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'DOCUMENT.CHOOSE_SIGNER' | translate}}</h4>
</div>
<div class="modal-body text-center">
  <button (click)="selectSigner(endUser)" *ngFor="let endUser of assignedEndUsers;let i=index"
          class="mr-2 mb-2 btn btn-success" [id]="'id-assigned-user-'+i" [disabled]="selectingSigner">
    <i aria-hidden="true"
       class="fa fa-user-circle-o"></i>
    <span *ngIf="!endUser.circle">{{endUser?.firstName}} {{endUser?.lastName}}
    ({{endUser?.email}})</span>
    <span *ngIf="endUser.circle">{{endUser?.lastName}}
      ({{endUser?.email}})</span>
  </button>
  <button (click)="selectSigner(endUser)" *ngFor="let endUser of endUsers;let i=index"
          class="mr-2 mb-2 btn btn-primary" [id]="'id-user-'+i" [disabled]="selectingSigner">
    <i aria-hidden="true"
       class="fa fa-user-circle-o"></i>
    <span *ngIf="!endUser.circle">{{endUser?.firstName}} {{endUser?.lastName}}
      ({{endUser?.email}})</span>
    <span *ngIf="endUser.circle">{{endUser?.lastName}}
      ({{endUser?.email}})</span>
  </button>
</div>
<div class="modal-footer">
  <button (click)="modal.close()" class="btn btn-outline-danger" [disabled]="selectingSigner" type="button">
    {{'DOCUMENT.CANCEL_ACTIONS' | translate}}
  </button>
</div>
