import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AppService} from '../services/app.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  static URL_AUTH_SPECIFIC = '/auth';
  private urlToReplace: string;

  constructor(
    private router: Router,
    private appService: AppService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.appService.isLoggedIn()) {
      if (this.urlToReplace) {
        this.router.navigate([this.urlToReplace]);
        this.urlToReplace = null;
      }
      return true;
    }

    if (route.fragment) {
      this.appService.refresh(route.fragment);
      this.router.navigate([state.url.replace(/[?#].*$/, '')]);
      return true;
    }

    this.urlToReplace = state.url.replace(/[?#].*$/, '');

    if (route.queryParams['tenantName']) {
      if (route.routeConfig.path === 'reset') {
        return true;
      } else {
        this.router.navigateByUrl(`/login?tenantName=${route.queryParams['tenantName']}`);
      }
    } else {
      this.router.navigateByUrl(`/no-tenant-name`);
    }
    return false;
  }
}
