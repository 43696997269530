import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {environment} from '../../../environments/environment';

@Injectable()
export class OrelyService {

  private basePath: string;

  constructor(private httpClient: HttpClient) {
    this.basePath = environment.ORELY_URL;
  }

  sign(businessId: string, enduserId: any): Observable<string> {
    return this.httpClient.get(this.signUrlStr(businessId, enduserId), {
      headers: {
        Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8'
      }, responseType: 'text'
    });
  }

  private signUrlStr(businessId: string, enduserId: any): string {
    return `${this.basePath}/sign/${businessId}/${enduserId}`;
  }
}
