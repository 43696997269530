import * as i0 from "@angular/core";
/**
 * Service used to manipulate URL directly using DOM.
 * This is a workaround.
 */
export class UrlService {
    getQueryParamByName(name) {
        return decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(name).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));
    }
}
UrlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UrlService_Factory() { return new UrlService(); }, token: UrlService, providedIn: "root" });
