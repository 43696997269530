/**
 * Utils class handling strings transformations.
 * Fluent.
 */
export class Strings {

  private readonly stringValue: string;

  constructor(stringValue: string) {
    this.stringValue = stringValue;
  }

  asBoolean(): boolean {
    return this.stringValue === undefined ? false :
      this.stringValue === null ? false :
      this.stringValue === 'true' ? true :
      this.stringValue === 'false' ? false :
        Number.isNaN(parseInt(this.stringValue, 10)) ? true :
          !!parseInt(this.stringValue, 10);
  }
}
