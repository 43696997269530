import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {RouterModule} from '@angular/router';
import {NgbCollapseModule, NgbModalModule, NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxMaskModule} from 'ngx-mask';
import {QuillModule} from 'ngx-quill';
import {ColorPickerComponent} from '../admin/color-picker/color-picker.component';
import {TemplateBuilderComponent} from '../admin/notifications/template-builder/template-builder.component';
import {CoreModule} from '../core/core.module';
import {SearchComponent} from '../dashboard/search/search.component';
import {TableDashboardComponent} from '../dashboard/table-dashboard/table-dashboard.component';
import {AlertComponent} from './components/alert/alert.component';
import {AnnotationViewerComponent} from './components/annotation-viewer/annotation-viewer.component';
import {AppDownloadPdfComponent} from './components/app-download-pdf/app-download-pdf.component';
import {BackBtnComponent} from './components/back-btn/back-btn.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {CardInfoComponent} from './components/card-info/card-info.component';
import {CardComponent} from './components/card/card.component';
import {ChartComponent} from './components/chart/chart.component';
import {CommentListComponent} from './components/comment-list/comment-list.component';
import {CommentsCardComponent} from './components/comments-card/comments-card.component';
import {DateTimePickerComponent} from './components/date-time-picker/date-time-picker.component';
import {DocumentActionsComponent} from './components/document-actions/document-actions.component';
import {DocumentCardComponent} from './components/document-card/document-card.component';
import {DocumentItemComponent} from './components/document-item/document-item.component';
import {DocumentTableComponent} from './components/document-table/document-table.component';
import {EnduserItemComponent} from './components/enduser-item/enduser-item.component';
import {EndusersDocumentTableComponent} from './components/endusers-document-table/endusers-document-table.component';
import {EndusersTableComponent} from './components/endusers-table/endusers-table.component';
import {FormModule} from './components/form/form.module';
import {InputDayNumberComponent} from './components/input-day-number/input-day-number.component';
import {InputLoaderComponent} from './components/input-loader/input-loader.component';
import {ItemsTableComponent} from './components/items-table/items-table.component';
import {LegalMotionCardComponent} from './components/legal-motion-card/legal-motion-card.component';
import {LegalMotionCheckListComponent} from './components/legal-motion-check-list/legal-motion-check-list.component';
import {MetadataSelectorComponent} from './components/metadata-selector/metadata-selector.component';
import {ModalAnnotationComponent} from './components/modal-annotation/modal-annotation.component';
import {ModalApplyVisaComponent} from './components/modal-apply-visa/modal-apply-visa.component';
import {ModalCloneSessionComponent} from './components/modal-clone-session/modal-clone-session.component';
import {ModalCommentComponent} from './components/modal-comment/modal-comment.component';
import {ModalConfirmSignatureComponent} from './components/modal-confirm-signature/modal-confirm-signature.component';
import {ModalCreateSessionComponent} from './components/modal-create-session/modal-create-session.component';
import {ModalCreateTemplateComponent} from './components/modal-create-template/modal-create-template.component';
import {ModalDeleteSessionComponent} from './components/modal-delete-session/modal-delete-session.component';
import {ModalDisableSessionComponent} from './components/modal-disable-session/modal-disable-session.component';
import {ModalEditComponent} from './components/modal-edit/modal-edit.component';
import {ModalManageTagListComponent} from './components/modal-manage-tag-list/modal-manage-tag-list.component';
import {ModalNewOrganisationComponent} from './components/modal-new-organisation/modal-new-organisation.component';
import {ModalNewSignerComponent} from './components/modal-new-signer/modal-new-signer.component';
import {ModalOrelyLoginComponent} from './components/modal-orely-login/modal-orely-login.component';
import {ModalOrelyComponent} from './components/modal-orely/modal-orely.component';
import {ModalPdfComponent} from './components/modal-pdf/modal-pdf.component';
import {ModalSignWithCodeComponent} from './components/modal-sign-with-code/modal-sign-with-code.component';
import {ModalSignerComponent} from './components/modal-signer/modal-signer.component';
import {ModalTenantDirectoriesComponent} from './components/modal-tenant-directories/modal-tenant-directories.component';
import {ModalYesNoComponent} from './components/modal-yes-no/modal-yes-no.component';
import {ModalComponent} from './components/modal/modal.component';
import {OrderComponent} from './components/order/order.component';
import {PdfViewerComponent} from './components/pdf-viewer/pdf-viewer.component';
import {ButtonDelegateComponent} from './components/session-delegate/button/button-delegate.component';
import {ModalDelegateComponent} from './components/session-delegate/modal/modal-delegate.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {StepMetadataComponent} from './components/step-metadata/step.metadata.component';
import {TemplateEditorOutputPanelComponent} from './components/template-editor/template-editor-output-panel/template-editor-output-panel.component';
import {TemplateEditorComponent} from './components/template-editor/template-editor.component';
import {TextTruncateComponent} from './components/text-truncate/text-truncate.component';
import {ToggleSwitchComponent} from './components/toggle-switch/toggle-switch.component';
import {TooltipComponent} from './components/tooltip/tooltip.component';
import {TutorielComponent} from './components/tutoriel/tutoriel.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {PipesModule} from './pipes/pipes.module';
import {AppModule} from "../app.module";
import {SessionTitlePipe} from "./pipes/session-title.pipe";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        NgbCollapseModule,
        NgbModule,
        NgxMaskModule.forRoot(),
        NgbModalModule,
        CoreModule,
        FormModule,
        PipesModule,
        QuillModule,
        MatSelectModule,
        RouterModule
    ], declarations: [
    AlertComponent,
    ModalPdfComponent,
    InputLoaderComponent,
    ItemsTableComponent,
    DocumentCardComponent,
    LegalMotionCardComponent,
    DocumentTableComponent,
    EndusersTableComponent,
    EnduserItemComponent,
    EndusersDocumentTableComponent,
    TooltipComponent,
    ModalOrelyComponent,
    ModalSignWithCodeComponent,
    ModalApplyVisaComponent,
    ModalConfirmSignatureComponent,
    ModalCreateSessionComponent,
    CommentListComponent,
    ModalCommentComponent,
    DocumentActionsComponent,
    SearchComponent,
    CardInfoComponent,
    ModalComponent,
    ModalDeleteSessionComponent,
    ModalDisableSessionComponent,
    OrderComponent,
    AnnotationViewerComponent,
    TextTruncateComponent,
    LegalMotionCheckListComponent,
    SpinnerComponent,
    ModalAnnotationComponent,
    PdfViewerComponent,
    TableDashboardComponent,
    ModalOrelyLoginComponent,
    ModalSignerComponent,
    DocumentItemComponent,
    ModalCreateTemplateComponent,
    ModalSignerComponent,
    ColorPickerComponent,
    DateTimePickerComponent,
    AppDownloadPdfComponent,
    TemplateBuilderComponent,
    BackBtnComponent,
    UserProfileComponent,
    ModalNewSignerComponent,
    ModalNewOrganisationComponent,
    CardComponent,
    StepMetadataComponent,
    MetadataSelectorComponent,
    CommentsCardComponent,
    StepMetadataComponent,
    TutorielComponent,
    ModalTenantDirectoriesComponent,
    TutorielComponent,
    ToggleSwitchComponent,
    InputDayNumberComponent,
    ChartComponent,
    ModalEditComponent,
    ModalYesNoComponent,
    ModalManageTagListComponent,
    AppDownloadPdfComponent,
    BreadcrumbComponent,
    TemplateEditorComponent,
    TemplateEditorOutputPanelComponent,
    ModalConfirmSignatureComponent,
    ModalCloneSessionComponent,
    ButtonDelegateComponent,
    ModalDelegateComponent,
    SessionTitlePipe
  ], exports: [
    CoreModule,
    ModalCloneSessionComponent,
    ButtonDelegateComponent,
    ModalDelegateComponent,
    AlertComponent,
    ModalPdfComponent,
    InputLoaderComponent,
    ItemsTableComponent,
    DocumentCardComponent,
    LegalMotionCardComponent,
    DocumentTableComponent,
    EndusersTableComponent,
    EnduserItemComponent,
    EndusersDocumentTableComponent,
    TooltipComponent,
    AppDownloadPdfComponent,
    CommentListComponent,
    DocumentActionsComponent,
    SearchComponent,
    ModalCommentComponent,
    DocumentTableComponent,
    CardInfoComponent,
    ModalComponent,
    ModalDeleteSessionComponent,
    ModalDisableSessionComponent,
    TextTruncateComponent,
    OrderComponent,
    LegalMotionCheckListComponent,
    AnnotationViewerComponent,
    SpinnerComponent,
    ModalAnnotationComponent,
    PdfViewerComponent,
    ModalAnnotationComponent,
    TableDashboardComponent,
    ModalOrelyLoginComponent,
    ModalSignerComponent,
    DocumentItemComponent,
    ModalCreateTemplateComponent,
    ModalSignerComponent,
    ColorPickerComponent,
    DateTimePickerComponent,
    FormModule,
    PipesModule,
    BackBtnComponent,
    UserProfileComponent,
    CardComponent,
    StepMetadataComponent,
    MetadataSelectorComponent,
    StepMetadataComponent,
    TutorielComponent,
    StepMetadataComponent,
    CommentsCardComponent,
    ModalTenantDirectoriesComponent,
    InputDayNumberComponent,
    ChartComponent,
    BreadcrumbComponent,
    TemplateEditorComponent,
    TemplateEditorOutputPanelComponent,
    ButtonDelegateComponent,
    ModalDelegateComponent,
    SessionTitlePipe],
  entryComponents: [
    EnduserItemComponent,
    ModalYesNoComponent,
    ModalManageTagListComponent,
    ModalComponent,
    ModalPdfComponent,
    ModalOrelyComponent,
    ModalSignWithCodeComponent,
    ModalApplyVisaComponent,
    ModalCreateSessionComponent,
    ModalCommentComponent,
    ModalDeleteSessionComponent,
    ModalDisableSessionComponent,
    ModalAnnotationComponent,
    ModalCreateTemplateComponent,
    ModalOrelyLoginComponent,
    ModalSignerComponent,
    ModalConfirmSignatureComponent,
    TemplateBuilderComponent,
    ModalNewSignerComponent,
    ModalNewOrganisationComponent,
    ModalTenantDirectoriesComponent,
    ModalEditComponent,
    ModalCloneSessionComponent,
    ModalDelegateComponent
  ]
})
export class SharedModule {
}
