<button #toolTipButton (click)="popupTip()" *ngIf="!showTip" class="helpButton">
  <i class="fas fa-info"></i>
</button>

<div *ngIf="showTip" class="r-background"></div>

<div *ngIf="showTip">
  <div #toolTipWindow class="tooltipBox">
    <button (click)="popupTip()" *ngIf="showTip" class="closeBtn"><i class="fas fa-times"></i>
    </button>
    <div [innerHTML]="text | safeHtml"></div>
  </div>
</div>
