import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe as NgxTranslatePipe, TranslateService as NgxTranslateService} from '@ngx-translate/core';
import {TranslateService} from 'src/app/core/service/translate.service';

/**
 * Pipe used to translate HTML. This pipe is based on the tenant name to translate HTML.
 */
@Pipe({
  name: 'translate',
  pure: false // required to update the value when the promise is resolved
})
export class TranslatePipe extends NgxTranslatePipe implements PipeTransform {

  /**
   * Default constructor.
   * @param translate the {@link NgxTranslateService} used by the parent class.
   * @param ref the {@link ChangeDetectorRef} used by the parent class.
   * @param translateService the COSI {@link TranslateService} used by COSi to translate key using tenant information.
   */
  constructor(ref: ChangeDetectorRef, private translateService: TranslateService) {
    super(translateService, ref);
  }

  /**
   * Get the value of the translation key using the tenant of the user.
   * @param query the translation key.
   * @param args arguments to be injected in the translation if needed.
   */
  transform(query: string, ...args: any[]): any {
    const tenantTranslationKey = this.translateService.getTranslationKeyWithTenant(query);
    if (tenantTranslationKey === query) {
      return super.transform(query, ...args);
    } else {
      const tenantTranslationResult = super.transform(tenantTranslationKey, ...args);
      return (tenantTranslationKey === tenantTranslationResult) ? super.transform(query, ...args) : tenantTranslationResult;
    }
  }
}
