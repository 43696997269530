/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-file.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./input-file.component";
var styles_InputFileComponent = [i0.styles];
var RenderType_InputFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputFileComponent, data: {} });
export { RenderType_InputFileComponent as RenderType_InputFileComponent };
function View_InputFileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_1); }); }
function View_InputFileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fas fa-", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_InputFileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFirstError(); _ck(_v, 1, 0, currVal_0); }); }
function View_InputFileComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [["class", "form-text text-muted"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "Hint"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.hint; _ck(_v, 1, 0, currVal_1); }); }
function View_InputFileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputFileComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hint; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InputFileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputFileComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["input", 1]], null, 0, "input", [["autocomplete", "none"], ["class", "custom-file-input"], ["type", "file"]], [[8, "accept", 0], [1, "tabindex", 0], [8, "id", 0], [8, "multiple", 0]], [[null, "blur"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.onTouched(_co.getFileListAsArray($event.target.files)) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        _co.onChange(_co.getFileListAsArray($event.target.files));
        var pd_1 = (_co.clearInput() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["class", "form-control"], ["tabindex", "-1"], ["type", "text"]], [[8, "placeholder", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "span", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputFileComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(11, null, ["\u00A0", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputFileComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showHint", 2]], null, 0, null, View_InputFileComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); var currVal_6 = _co.icon; _ck(_v, 10, 0, currVal_6); var currVal_8 = (_co.ngControl.touched && _co.ngControl.invalid); var currVal_9 = i1.ɵnov(_v, 14); _ck(_v, 13, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.accept == null) ? null : _co.accept.join(",")); var currVal_2 = _co.tabindex; var currVal_3 = _co.id; var currVal_4 = (_co.multiple ? "multiple" : null); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.placeholder; _ck(_v, 6, 0, currVal_5); var currVal_7 = _co.browseLabel; _ck(_v, 11, 0, currVal_7); }); }
export function View_InputFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input-file", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_InputFileComponent_0, RenderType_InputFileComponent)), i1.ɵdid(1, 49152, null, 0, i3.InputFileComponent, [[8, null]], null, null)], null, null); }
var InputFileComponentNgFactory = i1.ɵccf("app-input-file", i3.InputFileComponent, View_InputFileComponent_Host_0, { id: "id", label: "label", placeholder: "placeholder", icon: "icon", hint: "hint", disabled: "disabled", errors: "errors", navigateOnClick: "navigateOnClick", value: "value", browseLabel: "browseLabel", multiple: "multiple", accept: "accept", tabindex: "tabindex", leaveEmptyValue: "leaveEmptyValue" }, {}, []);
export { InputFileComponentNgFactory as InputFileComponentNgFactory };
