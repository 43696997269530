<div *ngIf="opened" class="custom-modal">
  <div  class="custom-modal-content" [ngClass]="{'padding': design === 'basic'}">
    <div #header class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    </div>
    <div class="modal-body"  [ngClass]="{'customDesign pb-5': design === 'custom'}">
      <span *ngIf="messageModal.length > 0" class="float-left pb-3 w-100 break-words">{{messageModal}} <span *ngIf="varModal">"{{varModal}}"</span>?</span>
      <ng-content></ng-content>
    </div>

    <div [ngClass]="design === 'custom' ? 'footerCustom' : 'modal-footer'">
      <button (click)="closeModal.emit()" class="btn"
      [ngClass]="design === 'custom' ? 'btn-light mr-1 paddingRight' : 'btn-danger mr-5'"
      type="button">{{ (design === 'custom' ? 'DOCUMENT.ANNULATION'  : 'CIRCLE.NO') | translate}}</button>
      <button (click)="delete.emit()" [disabled]="disabled" class="btn btn-primary"
             [ngClass]="{'mr-1': design === 'custom'}"
              type="button">{{ (design === 'custom' ? 'DOCUMENT.CONFIRM'  : 'CIRCLE.YES') | translate}}</button>
    </div>
  </div>
</div>