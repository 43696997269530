import {Injectable} from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateParser,
  TranslateService as NgxTranslateService,
  TranslateStore
} from '@ngx-translate/core';
import {DataService} from './data.service';

/**
 * Service used to translate HTML. This service take in account the tenant name of the user and display the right translation
 * linked to the tenant if needed.
 */
@Injectable()
export class TranslateService extends NgxTranslateService {

  /**
   * Default constructor.
   * @param store
   * @param currentLoader
   * @param compiler
   * @param parser
   * @param missingTranslationHandler
   * @param dataService
   */
  constructor(store: TranslateStore, currentLoader: TranslateLoader, compiler: TranslateCompiler, parser: TranslateParser,
              missingTranslationHandler: MissingTranslationHandler, private dataService: DataService) {
    super(store, currentLoader, compiler, parser, missingTranslationHandler, true, false);
  }

  /**
   * Returns a translation instantly from the internal state of loaded translation. Take the tenant in account for the translation.
   * All rules regarding the current language, the preferred language of even fallback languages will be used except any promise handling.
   */
  instant(key: string | Array<string>, interpolateParams?: Object): any {
    const tenantTranslationKey = this.getKeyWithTenant(key);
    if (tenantTranslationKey === key) {
      // console.log('empty tenant : ' + tenantTranslationKey);
      return super.instant(key, interpolateParams);
    } else {
      const tenantTranslationResult = super.instant(tenantTranslationKey, interpolateParams);
      // console.log('tenant : ' + tenantTranslationResult);
      return (tenantTranslationKey !== tenantTranslationResult) ? tenantTranslationResult : super.instant(key, interpolateParams);
    }
  }

  /**
   * Add at the beginning of the parameter #key, the tenant name in uppercase.
   * @param key the translation key.
   * @private
   */
  private getKeyWithTenant(key: string | Array<string>): string | Array<string> {
    return Array.isArray(key) ? key.map(value => this.getTranslationKeyWithTenant(value)) : this.getTranslationKeyWithTenant(key);
  }

  /**
   * Add at the beginning of the parameter #key, the tenant name in uppercase.
   * @param key the translation key.
   * @private
   *
   * @return the key prefixed by the tenant if the tenant is not empty otherwise the key.
   */
  getTranslationKeyWithTenant(key: string): string {
    const tenantUpperCase = this.dataService.getTenantName().toUpperCase();
    return (tenantUpperCase) ? tenantUpperCase + '.' + key : key;
  }
}
