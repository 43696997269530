import { HttpClient } from '@angular/common/http';
import { NexuSignatureService } from 'luxtrust-cosi-api/api/nexuSignature.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../luxtrust-cosi-api/api/nexuSignature.service";
export class NexuService {
    constructor(httpClient, signatureService) {
        this.httpClient = httpClient;
        this.signatureService = signatureService;
    }
    sign(businessId) {
        return (this.config ? Promise.resolve(this.config) : this.signatureService.getNexuConfig().toPromise()
            .then(nexuConfiguration => this.config = nexuConfiguration && nexuConfiguration)).then(config => {
            return this.getCertificateChain(config).then(clientCertificate => {
                const body = {
                    businessId: businessId,
                    base64Chain: clientCertificate.response.certificateChain,
                    keyId: clientCertificate.response.keyId,
                    base64Cert: clientCertificate.response.certificate,
                    tokenId: clientCertificate.response.tokenId.id
                };
                return this.signatureService.handleNexuSignature(body).toPromise().then(signatureInformation => {
                    return this.doClientSign(config, signatureInformation.signatureRequest)
                        .then(clientSignatureInformation => {
                        const body2 = {
                            certificate: clientSignatureInformation.response.certificate,
                            certificateChain: clientSignatureInformation.response.certificateChain,
                            signatureAlgorithm: clientSignatureInformation.response.signatureAlgorithm,
                            signatureValue: clientSignatureInformation.response.signatureValue,
                            signingDate: new Date(signatureInformation.signingDate)
                        };
                        return this.signatureService.handleNexuSignatureCallback(businessId, body2).toPromise();
                    });
                });
            });
        });
    }
    getCertificateChain(config) {
        return this.httpClient.get(config.scheme + '://localhost:' + config.port + NexuService.URL_NEXU_SPECIFIC + 'certificates', {
            withCredentials: false
        }).toPromise();
    }
    doClientSign(config, signatureRequest) {
        return this.httpClient.post(config.scheme + '://localhost:' + config.port + NexuService.URL_NEXU_SPECIFIC + 'sign', signatureRequest, {
            withCredentials: false
        }).toPromise();
    }
}
NexuService.URL_NEXU_SPECIFIC = '/rest/';
NexuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NexuService_Factory() { return new NexuService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NexuSignatureService)); }, token: NexuService, providedIn: "root" });
