/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-editor-output-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../core/pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./template-editor-output-panel.component";
var styles_TemplateEditorOutputPanelComponent = [i0.styles];
var RenderType_TemplateEditorOutputPanelComponent = i1.ɵcrt({ encapsulation: 3, styles: styles_TemplateEditorOutputPanelComponent, data: {} });
export { RenderType_TemplateEditorOutputPanelComponent as RenderType_TemplateEditorOutputPanelComponent };
export function View_TemplateEditorOutputPanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "code-output-content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), (((_co.patternData == null) ? null : _co.patternData.body) ? ((_co.patternData == null) ? null : _co.patternData.body) : null))); _ck(_v, 1, 0, currVal_0); }); }
export function View_TemplateEditorOutputPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-editor-output-panel", [], null, null, null, View_TemplateEditorOutputPanelComponent_0, RenderType_TemplateEditorOutputPanelComponent)), i1.ɵdid(1, 49152, null, 0, i4.TemplateEditorOutputPanelComponent, [], null, null)], null, null); }
var TemplateEditorOutputPanelComponentNgFactory = i1.ɵccf("app-template-editor-output-panel", i4.TemplateEditorOutputPanelComponent, View_TemplateEditorOutputPanelComponent_Host_0, { patternData: "patternData" }, {}, []);
export { TemplateEditorOutputPanelComponentNgFactory as TemplateEditorOutputPanelComponentNgFactory };
