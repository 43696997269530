import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import find from 'lodash/find';
import {AnnotationService} from 'luxtrust-cosi-api/api/annotation.service';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {PlaceholderService} from 'luxtrust-cosi-api/api/placeholder.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {Annotation} from 'luxtrust-cosi-api/model/annotation';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SignaturePlaceholderData} from 'luxtrust-cosi-api/model/signaturePlaceholderData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {SignatureWorkflowService, UpdateDocumentPayload} from '../../../../../luxtrust-cosi-api';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {WizardDocumentState} from '../../../session/step/model/wizard-document-state';
import {SignerDetails} from '../document-item/document-item.component';
import {ModalPdfComponent} from './modal-pdf.component';
import {WizardDocumentStoreService} from '../../../session/step/services/wizard-document-store.service';
import {ModalEditComponent} from '../modal-edit/modal-edit.component';
import {OnDestroy, OnInit} from "@angular/core";

export abstract class ModalPdfConsumerComponent implements OnInit, OnDestroy{

  filesAcroformsPositions = new Map<string, { x: number, y: number, height: number, width: number, signerId: any, signer: EnduserData, name: string }[][]>();
  fileNameToAnnotations = new Map<string, [{ x: number, y: number, page: number, idx: number, text: string, scale: number }]>();
  currentStepEnduser: StepEnduserData;
  acroformDefined = false;
  sessionId: number;
  stepId: number;
  signers: EnduserData[];
  // When Step tag POSITION_SIGNATURE_LOCATION
  signersByDocument: { document: DocumentData, signers: EnduserData[] }[];
  hasStepTagPositionSignatureLocation = false;
  documents: WizardDocumentState;
  creatingAcroform = false;
  creatingAnnotation = false;
  deletingDocument = false;
  deletingAppendix = false;
  updatingDocument = false;
  uploadingDocument = false;
  uploadingAppendix = false;
  updatingDocumentName = false;

  constructor(public modal: NgbModal,
              public appService: AppService,
              public annotationService: AnnotationService,
              protected alertService: AlertService,
              public documentService: DocumentService,
              public placeholderService: PlaceholderService,
              public stepEnduserService: StepEnduserService,
              public signatureWorkflowService: SignatureWorkflowService,
              public wizardDocumentStoreService: WizardDocumentStoreService) {
  }


  private documentsSubscription: Subscription

  ngOnDestroy(): void {
    if(this.documentsSubscription){
      this.documentsSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.documentsSubscription = this.wizardDocumentStoreService.getAll$().subscribe(it => {
      this.refreshDocumentData();
    })
  }

  abstract selectedSigners(document?: DocumentData): EnduserData[];

  // TODO : Refactor : Fusion des 2 methodes
  getAssignedSigners(fileName: string): EnduserData[] {
    const alreadyAssigned: EnduserData[] = [];
    if (this.filesAcroformsPositions.has(fileName)) {
      this.filesAcroformsPositions.get(fileName)
        .forEach(value => {
          value.forEach(nestedValue => {
            const circleInfo = nestedValue.name || nestedValue.signer['acroFormName'];
            if (!circleInfo || !circleInfo.includes(nestedValue.signer.id.toString())) {
              alreadyAssigned.push(nestedValue.signer);
            }
          });
        });
    }
    return alreadyAssigned;
  }

  getAssignedCircles(fileName: string): EnduserData[] {
    const alreadyAssigned: EnduserData[] = [];
    if (this.filesAcroformsPositions.has(fileName)) {
      this.filesAcroformsPositions.get(fileName)
        .forEach(value => {
          value.forEach(nestedValue => {
            const circleInfo = nestedValue.name || nestedValue.signer['acroFormName'];
            if (circleInfo && circleInfo.includes(nestedValue.signer.id.toString())) {
              const newEnduser = JSON.parse(JSON.stringify(nestedValue.signer));
              if (!newEnduser['acroFormName']) {
                newEnduser['acroFormName'] = nestedValue.name;
              }
              alreadyAssigned.push(newEnduser);
            }
          });
        });
    }
    return alreadyAssigned;
  }

  getSignersForDocuments(documents: WizardDocumentState) {
    if (!this.signersByDocument) {
      this.signersByDocument = [];
    }
    documents.documentToSign.filter(document => document.stepId === this.stepId).forEach(doc => {
      this.getSigners(doc);
    });
  }

  refreshDocumentData() {
    this.documents = this.wizardDocumentStoreService.getAll();
    if (this.hasStepTagPositionSignatureLocation) {
      this.signersByDocument = [];
      this.getSignersForDocuments(this.documents);
    } else {
      this.showSignersAllDocuments();
    }
  }

  showSignersAllDocuments() {
    this.fillSignerList();
    if (this.documents && this.documents.documentToSign) {
      this.documents.documentToSign.filter(document => document.stepId === this.stepId).forEach(doc => {
        this.showSigners(doc);
      });
    }
  }

  fillSignerList(): void {
    // Implemented only in modal-configurator-documents
  }

  uploadDocument(files: FileList | File[]) {
    this.uploadingDocument = true;
    this.wizardDocumentStoreService.uploadDocument(this.sessionId, this.stepId, files, () => {
      this.documents = this.wizardDocumentStoreService.getAll();
      this.uploadingDocument = false;
    });
  }

  uploadAppendix(files: FileList) {
    this.uploadingAppendix = true;
    this.wizardDocumentStoreService.uploadAppendix(this.sessionId, this.stepId, files, () => {
      this.documents = this.wizardDocumentStoreService.getAll();
      this.uploadingAppendix = false;
    });
  }

  updateDocumentName(document: DocumentData) {
    const modalEdit = this.openModal(document);

    // Removing the file extension from the name input
    const dotIndex = document.name.lastIndexOf('.');
    const ext = document.name.substr(dotIndex);
    modalEdit.componentInstance.content = document.name.substr(0, dotIndex);

    modalEdit.componentInstance.onAction.pipe(take(1)).subscribe((commentText: string) => {
      const updateDocumentPayload: UpdateDocumentPayload = {

        // Giving new name to the files with their extensions
        name: commentText + ext, cascadingName: commentText + ext
      };
      this.updatingDocumentName = true;
      this.wizardDocumentStoreService.updateDocumentName(this.sessionId, this.stepId, document.id, updateDocumentPayload).then(() => {
        this.documents = this.wizardDocumentStoreService.getAll();
        this.showSignersAllDocuments();
        this.updatingDocumentName = false;
      });
    });
  }


  updateDocument(data: { id: number, updateDocumentPayload: UpdateDocumentPayload }) {
    this.updatingDocument = true;
    this.wizardDocumentStoreService.updateDocument(this.sessionId, this.stepId, data.id, data.updateDocumentPayload).then(value => this.updatingDocument = false);
  }

  openModal(document: DocumentData) {
    const newName = this.modal.open(ModalEditComponent, {backdrop: false});
    newName.componentInstance.title = 'MODAL_EDIT.TITLE';
    newName.componentInstance.content = document.name;
    newName.componentInstance.close = 'MODAL_EDIT.CANCEL';
    newName.componentInstance.action = 'MODAL_EDIT.OK';

    return newName;
  }


  pad(n: number) {
    return String(Math.floor(n)).padStart(6, ' ');
  }

  previewFile(file: Blob, fileName: string, document?: DocumentData, page?): Promise<boolean> {
    const modalRef = this.modal.open(ModalPdfComponent, {
      windowClass: 'xlModal', backdrop: false
    });
    modalRef.componentInstance.filesAcroformsPositions = this.filesAcroformsPositions;
    modalRef.componentInstance.file = file;
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.page = page;
    modalRef.componentInstance.annotations = this.fileNameToAnnotations.get(fileName) || [];
    modalRef.componentInstance.assignedSigners = this.getAssignedSigners(fileName);
    modalRef.componentInstance.assignedCircles = this.getAssignedCircles(fileName);
    modalRef.componentInstance.signers = this.selectedSigners(document)
      .filter(signer => !modalRef.componentInstance.assignedSigners.some(assignedSigner => (assignedSigner.id && signer.id && assignedSigner.id === signer.id) || assignedSigner.email && signer.email && assignedSigner.email === signer.email))
      .filter(signer => !modalRef.componentInstance.assignedCircles.some(signerCircle => signerCircle['acroFormName'] === signer['acroFormName'] || (!signer['acroFormName'] && signerCircle.id === signer.id)));
    return modalRef.result.then(value => {
      if (value) {
        const acroforms = value.positions;
        const annotations = value.annotations;
        if (annotations) {
          this.fileNameToAnnotations.set(fileName, annotations);
        }
        if (acroforms.positions.length !== 0) {
          acroforms.positions.forEach((acrofNewList, numberPageNew) => {
            acrofNewList.forEach((acrofNew) => {
              const newName = acrofNew.signer['acroFormName'];
              acrofNew.name = newName;
              const fap = this.filesAcroformsPositions.get(fileName);
              if (fap) {
                fap.forEach((oldSignaturesPositions, numberPageOld) => {
                  oldSignaturesPositions.forEach((acrofOld, indexOld) => {
                    let movingOldSignature = false;
                    // Check for signers into Circle
                    const oldName = acrofOld.name;
                    if (newName && newName.toString().includes(acrofNew.signer.id.toString())) {
                      if (newName === oldName) {
                        movingOldSignature = true;
                      }
                    } else {
                      if (!acrofNew.signerId && !acrofOld.signerId) {
                        if (acrofNew.signer.email === acrofOld.signer.email) {
                          movingOldSignature = true;
                        }
                      } else if (acrofNew.signerId === acrofOld.signerId) {
                        movingOldSignature = true;
                      }
                    }
                    if (movingOldSignature) {
                      // Si on déplace une ancienne signature
                      if (fap[numberPageOld]) {
                        fap[numberPageOld].splice(indexOld, 1); // Suppression de l'ancienne signature qui a été modifié
                        // BOB fap[numberPageNew][indexOld] = acrofNew; // Ajout de la signature modifiée
                      }
                    }
                  });
                });
              } else {
                this.filesAcroformsPositions.set(fileName, []);
                // BOB this.filesAcroformsPositions.set(name, acroforms.positions);
                // BOB fap = this.filesAcroformsPositions.get(name);

              }
              if (!this.filesAcroformsPositions.get(fileName)[numberPageNew]) {
                this.filesAcroformsPositions.get(fileName)[numberPageNew] = [];
              }
              this.filesAcroformsPositions.get(fileName)[numberPageNew].push(acrofNew); // Ajout de la signature modifiée
            });
          });
          return true;
        }
      }
    }, () => false);
  }

  hasAcroformForName(fileName: string) {
    return this.filesAcroformsPositions.has(fileName) && this.filesAcroformsPositions.get(fileName).length !== 0;
  }

  hasAnnotationForName(fileName: string) {
    return this.fileNameToAnnotations.has(fileName) && this.fileNameToAnnotations.get(fileName)
      .filter(annotation => annotation).length !== 0;
  }

  createAnnotation(sessionId: number, stepId: number, padesDoc: DocumentData, file: File, fileName?: string) {
    const annotations: Annotation[] = [];
    this.fileNameToAnnotations.get(fileName || file.name).forEach(annotation => {
      annotations.push({
        idx: Number(annotation.idx),
        page: annotation.page,
        text: annotation.text,
        x: annotation.x,
        y: annotation.y,
        scale: annotation.scale
      });
    });
    return this.annotationService.createAnnotation(padesDoc.id, sessionId, stepId, {
      annotations: annotations
    });
  }

  addAcroform(document: DocumentData, page?, checkAcroform?: boolean) {
    this.creatingAcroform = true;
    this.documentService.downloadDocument(document.id, this.sessionId, this.stepId).toPromise().then((fileResource: Blob) => {
      this.previewFile(fileResource, document.name, document, page).then(previewDone => {
        if (previewDone) {
          if (this.hasAcroformForName(document.name)) {
            this.createAcroforms(undefined, document.id, document.name, true).pipe(take(1)).toPromise()
              .then((value:any) => this.creatingAcroform = false)
              .catch((error: ApiError) => {
                this.creatingAcroform = false;
                this.alertService.errorApi(error);
              });
            if (checkAcroform) {
              this.checkAcroform(document);
            }
          }
          if (this.hasAnnotationForName(document.name)) {
            this.creatingAnnotation = true;
            this.createAnnotation(this.sessionId, this.stepId, document, undefined, document.name).pipe(take(1)).toPromise()
              .then((value:any) => this.creatingAcroform = false)
              .catch((error: ApiError) => {
                this.creatingAcroform = false;
                this.alertService.errorApi(error);
              });
          }
        } else {
          this.creatingAcroform = false;
        }
      });
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  createAcroforms(pdf: File, documentId: number, pdfName?: string, deleteFirst = false): Observable<any> {
    const coordinates = {};
    this.filesAcroformsPositions.get(pdfName || pdf.name).forEach((value1, index) => {
      coordinates[index] = value1;
      value1.forEach(item => {
        item.signerId = item.signer.id;
        if(item.name && !item.name.includes(":circle-")){
          delete item.name;
        }
      });
    });
    if (deleteFirst) {
      return this.placeholderService.deleteAcroforms(documentId, this.sessionId, this.stepId)
        .pipe(switchMap(() => this.placeholderService.createAcroforms(documentId, this.sessionId, this.stepId, {
          coordinates: coordinates
        })));
    } else {
      return this.placeholderService.createAcroforms(documentId, this.sessionId, this.stepId, {
        coordinates: coordinates
      });
    }
  }

  checkAcroform(document: DocumentData) {
    if (find(this.signers, (signer => signer.id === this.currentStepEnduser.enduser.id))) {
      if (find(this.filesAcroformsPositions.get(document.name)[1], (acrof => acrof.signer.id === this.currentStepEnduser.enduser.id && acrof.x))) {
        this.acroformDefined = true;
      }
    } else {
      let tempAcrofDefined = true;
      let signerList;
      if (!this.hasStepTagPositionSignatureLocation) {
        signerList = this.signers;
      } else {
        signerList = find(this.signersByDocument, (docSigners) => docSigners.document.id === document.id).signers;
      }
      for (let i = 0; i < signerList.length; i++) {
        if (!find(this.filesAcroformsPositions.get(document.name)[1], (acroform => acroform.signer.id === signerList[i].id))) {
          tempAcrofDefined = false;
          break;
        }
        this.acroformDefined = tempAcrofDefined;
      }
    }
  }

  getSignersDetails(fileName: string) {
    interface SignerInfo {
      firstName: string;
      lastName: string;
      email: string;
      circle: boolean;
    }

    const result: SignerDetails[] = [];
    const pageNumberToSigners: any[][] = [];

    if (!this.filesAcroformsPositions.has(fileName)) {
      return [];
    }
    this.filesAcroformsPositions.get(fileName).forEach((value, index) => {
      const signersInfo: SignerInfo[] = [];
      value.forEach(nestedValue => {
        if (nestedValue.signer) {
          signersInfo.push({
            firstName: nestedValue.signer.firstName,
            lastName: nestedValue.signer.lastName,
            email: nestedValue.signer.email,
            circle: nestedValue.signer.circle,
          });
        }
      });
      pageNumberToSigners[index] = signersInfo;
    });

    const signersToPageNumber = new Map<SignerInfo, number[]>();

    pageNumberToSigners.forEach((value, index) => {
      value.forEach(nestedValue => {
        if (!signersToPageNumber.has(nestedValue)) {
          signersToPageNumber.set(nestedValue, []);
        }
        const array = signersToPageNumber.get(nestedValue);
        array.push(index);
        signersToPageNumber.set(nestedValue, array);
      });
    });

    signersToPageNumber.forEach((value, key) => {
      result.push({
        signer: key, pages: value.join(' ,')
      });
    });
    return result;
  }

  deleteDocument(document: DocumentData) {
    this.filesAcroformsPositions.set(document.name, new Array());
    this.deletingDocument = true;
    this.wizardDocumentStoreService.deleteDocument(this.sessionId, this.stepId, document).then(value => this.deletingDocument = false);
  }

  deleteAppendix(document: DocumentData) {
    this.deletingAppendix = true;
    this.wizardDocumentStoreService.deleteAppendix(this.sessionId, this.stepId, document).then(value => this.deletingAppendix = false);
  }

  putSignersIntoFAP(placeholderList: SignaturePlaceholderData[], document: DocumentData, signerList: EnduserData[]) {
    if (!signerList) {
      signerList = this.signers;
    }
    const signaturesPerPage = new Array(placeholderList.reduce((p, s) => p < s.page ? s.page : p, 1) + 2);
    placeholderList.forEach(s => {
      signaturesPerPage[s.page] = signaturesPerPage[s.page] || [];
      if (this.currentStepEnduser.enduser.id === s.enduserId || this.currentStepEnduser.manager) {
        let signer = signerList.find(se => se.id === s.enduserId);
        if (s.name && s.name.includes(s.enduserId.toString())) {
          const signerIntoSignerList = signerList.find(se => se['acroFormName'] === s.name);
          if (signerIntoSignerList) {
            signer = signerIntoSignerList;
          }
        }
        signaturesPerPage[s.page].push({
          name: s.name,
          x: s.left,
          y: s.top,
          height: s.height,
          width: s.width,
          signerId: s.enduserId,
          signer: signer
        });
      }
    });
    this.filesAcroformsPositions.set(document.name, signaturesPerPage);
  }

  showSigners(document: DocumentData, signerList?: EnduserData[], _placeholderList?) {
    if (_placeholderList) {
      this.putSignersIntoFAP(_placeholderList, document, signerList);
    } else {
      this.placeholderService.getSignaturePlaceholderList(document.id, this.sessionId, this.stepId).toPromise().then(placeholderList => {
        this.putSignersIntoFAP(placeholderList, document, signerList);
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    }
  }

  createSignerCircle(circle: EnduserData, count: number): EnduserData {
    const newSignerCircle: EnduserData = JSON.parse(JSON.stringify(circle));
    newSignerCircle['acroFormName'] = newSignerCircle.id + ':circle-' + count;
    return newSignerCircle;
  }

  putSignerCircleIntoList(expected: number, enduser: EnduserData, signerList: EnduserData[]) {
    for (let i = 1; i < expected + 1; i++) {
      const newSignerCircle = this.createSignerCircle(enduser, i);
      signerList.push(newSignerCircle);
    }
  }

  checkCurrentUserIntoStepEnduserList(stepEndusersList: StepEnduserData[]) {
    // Check if the currentUser is directly implied in a stepEnduserData (signer, manager or watcher).
    this.currentStepEnduser = stepEndusersList.find((stepEnduser: StepEnduserData) => this.appService.getCurrentUserId() === stepEnduser.enduser.id);
  }

  getSigners(document: DocumentData) {
    let listSignersToUse: EnduserData[];
    if (this.hasStepTagPositionSignatureLocation) {
      let signerByDocument = find(this.signersByDocument, (docSigners) => docSigners.document.id === document.id);
      if (!signerByDocument) {
        signerByDocument = {document: document, signers: []};
        listSignersToUse = signerByDocument.signers;
        this.signersByDocument.push(signerByDocument);
      }
    } else {
      this.signers = [];
      listSignersToUse = this.signers;
    }
    let justOneSigner = false;
    if (this.currentStepEnduser && this.currentStepEnduser.signer && !this.currentStepEnduser.manager) {
      listSignersToUse.push(this.currentStepEnduser.enduser);
      justOneSigner = true;
    }

    const placeHolderList = this.placeholderService.getSignaturePlaceholderList(document.id, this.sessionId, this.stepId);
    let workflows = undefined;
    let stepEndusers = undefined;
    if (!this.hasStepTagPositionSignatureLocation) {
      workflows = this.signatureWorkflowService.getWorkflowList(this.sessionId, this.stepId);
      stepEndusers = of(stepEndusers);
    } else {
      stepEndusers = this.stepEnduserService.stepEnduserListInvolvedInPreparation(this.sessionId, this.stepId);
      workflows = of(workflows);
    }
    forkJoin({placeholderList: placeHolderList, workflows: workflows, stepEndusers: stepEndusers})
      .subscribe((data) => {
        const placeholderList = data.placeholderList;
        const workflows = data.workflows;
        const stepEndusers: StepEnduserData[] = data.stepEndusers as StepEnduserData[];
        if (justOneSigner) {
          const placeholder = placeholderList.find(_placeholder => this.currentStepEnduser.enduser.id === _placeholder.enduserId);
          if (placeholder && placeholder.left) {
            this.acroformDefined = true;
            this.showSigners(document, listSignersToUse, placeholderList);
          }
        } else {
          let countAcroformDefined = 0;
          let managerIsInCircleSigner = false;
          placeholderList.forEach((placeholder) => {
            if (!this.hasStepTagPositionSignatureLocation) {
              this.getSignersWithoutPSL(workflows, placeholderList, placeholder, listSignersToUse, managerIsInCircleSigner, countAcroformDefined, document);
            } else {
              this.getSignersWithPSL(stepEndusers, placeholderList, placeholder, listSignersToUse, managerIsInCircleSigner, countAcroformDefined, document);
            }
          });
        }
      }, (error: ApiError) => this.alertService.errorApi(error));
  }

  getSignersWithoutPSL(workflows, placeholderList: SignaturePlaceholderData[], placeholder: SignaturePlaceholderData,
                       listSignersToUse: EnduserData[], managerIsInCircleSigner: boolean, countAcroformDefined: number, document: DocumentData
  ) {
    workflows.filter(it => it.enduserId === placeholder.enduserId).map(it => it.enduser).map((enduser: EnduserData) => {
      this.stepEnduserService.getStepEndUser(enduser.id, this.sessionId, this.stepId).subscribe(stepEnduser => {
        this.getSignersForManagerBySigner(placeholderList, stepEnduser, placeholder, listSignersToUse, managerIsInCircleSigner, countAcroformDefined, document);
      })
    });
  }

  getSignersWithPSL(stepEndusers: StepEnduserData[], placeholderList: SignaturePlaceholderData[], placeholder: SignaturePlaceholderData,
                    listSignersToUse: EnduserData[], managerIsInCircleSigner: boolean, countAcroformDefined: number, document: DocumentData
  ) {
    stepEndusers.filter(stepEnduser => stepEnduser.signer && stepEnduser.enduser.id === placeholder.enduserId).forEach(stepEnduser => {
      this.getSignersForManagerBySigner(placeholderList, stepEnduser, placeholder, listSignersToUse, managerIsInCircleSigner, countAcroformDefined, document);
    });
  }

  getSignersForManagerBySigner(placeholderList, stepEnduser, placeholder, listSignersToUse, managerIsInCircleSigner, countAcroformDefined, document) {
    const enduser = stepEnduser.enduser;
    if (enduser.circle && !placeholder.name) {
      // Cercle pas encore placé
      for (let i = 1; i < stepEnduser.expected + 1; i++) {
        listSignersToUse.push(this.createSignerCircle(enduser, i));
      }
    } else {
      if (this.currentStepEnduser.manager) {
        if (!find(listSignersToUse, (signer) => signer.id === enduser.id)) {
          listSignersToUse.push(enduser);
          if (this.currentStepEnduser.enduser.circle) {
            managerIsInCircleSigner = true;
            this.acroformDefined = false;
          }
        }
        if (placeholder.left) {
          countAcroformDefined++;
        }
      }
    }
    this.getSignersEnd(countAcroformDefined, document, listSignersToUse, placeholderList, managerIsInCircleSigner);
  }

  getSignersEnd(countAcroformDefined, document: DocumentData, signerList: EnduserData[], placeholderList: SignaturePlaceholderData[], managerIsInCircleSigner) {
    if ((this.hasStepTagPositionSignatureLocation || managerIsInCircleSigner) &&
      countAcroformDefined === signerList.length) {
      this.acroformDefined = true;
    }
    this.showSigners(document, signerList, placeholderList);

  }
}
