import {Component, ElementRef, Input, Optional, Self, ViewChild} from '@angular/core';

import {NgControl} from '@angular/forms';
import {Router} from '@angular/router';
import {FormElement} from '../form.element';

@Component({
  selector: 'app-input-text', templateUrl: './input-text.component.html', styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent extends FormElement<string> {

  @ViewChild('input', {static: true}) input: ElementRef;

  @Input() type: 'text' | 'password' | 'email' | 'phone' = 'text';
  @Input() maxLength = '1023';

  @Input() hasOnBlur: boolean;
  @Input() hasOnInput: boolean;
  @Input() tabindex: number;
  @Input() idPostfix = '';

  constructor(@Self() @Optional() public ngControl: NgControl, public router: Router) {
    super(ngControl);
  }

  onClick() {
    this.input.nativeElement.focus();
  }

  handleNavigation() {
    if (this.navigateOnClick.commands.length > 0) {
      return true;
    }
    return false;
  }

  handleClick() {
    this.router.navigate(this.navigateOnClick.commands, this.navigateOnClick.params);
  }
}
