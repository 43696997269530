import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { initSentry } from "./sentry-error-handler";
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeFr, 'FR');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeNl, 'NL');
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}
const ɵ0 = environment.BASE_URL, ɵ1 = initSentry;
export class AppModule {
}
export { ɵ0, ɵ1 };
