import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {OrganisationService} from 'luxtrust-cosi-api/api/organisation.service';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SessionEnduserService} from 'luxtrust-cosi-api/api/sessionEnduser.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepPropertiesService} from 'luxtrust-cosi-api/api/stepProperties.service';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepPropertyData} from 'luxtrust-cosi-api/model/stepPropertyData';
import {UpdateStepPayload} from 'luxtrust-cosi-api/model/updateStepPayload';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {ApiError} from "../../../../error/api-error.model";
import {AlertService} from '../../../../services/services/alert-service';
import {AppService} from '../../../../services/services/app.service';
import {DateTimeModel, NgbDateTimeStruct} from '../../../../shared/components/date-time-picker/date-time.model';
import {Status} from '../../../../shared/components/input-loader/input-loader.component';
import {ModalCreateTemplateComponent} from '../../../../shared/components/modal-create-template/modal-create-template.component';
import {StepActions} from '../../wizard/models/wizard.config';
import {WizardService} from '../../wizard/services/wizard.service';
import {SessionTagService, TagData, TagService} from "../../../../../../luxtrust-cosi-api";
import {ConfigurationService} from "../../../../services/services/configuration.service";
import {CONFIG} from "../../../../app.constant";

@Component({
  templateUrl: './wizard-step-general.component.html', styleUrls: ['./wizard-step-general.component.scss']
})
export class WizardStepGeneralComponent implements OnInit, OnDestroy {

  timeOut;
  debouncer: Subject<string> = new Subject();

  session: SessionData;
  step: StepData;
  stepProperties: StepPropertyData;

  labelStatus: Status;
  signatureDateNotBeforeStatus: Status;
  signatureDateNotAfterStatus: Status;
  priorityStatus: Status;
  stepActions = StepActions;
  wizardStepActionDisabled = false;

  signNotBefore: NgbDateTimeStruct;
  signNotAfter: NgbDateTimeStruct;

  selectedManager: EnduserData[] = [];
  availableManager: EnduserData[] = [];
  loaderStatusManager: Status;
  stepNameError = false;
  stepNameLengthLimit = false;

  showSmallerThanError = false;
  minDay: number;
  maxDay: number;
  showDayPicker = false;

  @ViewChild('labelInput', {static: true}) labelInput: ElementRef;
  notBefore: any;

  public organisationProvider: { key: string, value: number }[] = [];
  public currentOrganisation: { key: string, value: number };
  public organisationForm: FormGroup;
  private organisationSubscription: Subscription;
  public confidentialityForm;

  generatingTemplate = false;

  constructor(public router: Router,
              public appService: AppService,
              public wizardService: WizardService,
              private route: ActivatedRoute,
              private modal: NgbModal,
              private configurationService: ConfigurationService,
              private alertService: AlertService,
              private spinnerService: NgxSpinnerService,
              private sessionService: SessionService,
              private stepService: StepService,
              private stepPropertiesService: StepPropertiesService,
              private enduserService: EnduserService,
              private sessionEnduserService: SessionEnduserService,
              private sessionTagService: SessionTagService,
              private tagService: TagService,
              private organisationService: OrganisationService,
              private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.route.parent.data.pipe(take(1)).subscribe((data: Data) => {
      this.session = data['session'];
      this.step = data['step'];
      this.stepProperties = data['stepProperties'];
      this.signNotBefore = <any>this.stepProperties.signNotBefore;
      this.signNotAfter = <any>this.stepProperties.signNotAfter;

      this.minDay = this.signNotBefore ? this.diffDate(this.stepProperties.signNotBefore) + 1 : 0;
      this.maxDay = this.signNotAfter ? this.diffDate(this.stepProperties.signNotAfter) - 1 : null;

      this.sessionEnduserService.getSessionManagerList(this.session.id).toPromise()
        .then(users =>
          this.selectedManager = users.filter(e => e.enduser.id !== this.appService.getCurrentUserId()).map(u => u.enduser))
        .catch((error: ApiError) => {
          if (error.status === 403) {
            this.router.navigate(['/session', this.session.id, 'step', this.step.id]);
          }
        });
      this.sessionService.getSession(this.step.sessionId).toPromise()
        .then(session => {
          if (session.organisationId) {
            this.organisationService.getOrganisation(session.organisationId).toPromise()
                .then(organisation => {
                  this.currentOrganisation = {
                    key: organisation.name,
                    value: organisation.organisationId
                  };
                  this.organisationForm.get('selectedOrganisation').setValue(this.currentOrganisation);
                });
          }

          this.configurationService.tenantConfig.then(tenantConfig => {
            if(tenantConfig[CONFIG.TENANT.ENABLE_CONFIDENTIALITY_LEVEL.KEY]){
              this.tagService.getTagByAlias('CONFIDENTIALITY_LEVEL').subscribe(tag => {
                const confidentialityLevelTagId = tag.id;

                this.sessionTagService.getSessionTagList(session.id).subscribe(sessionTagData => {
                  const confidentialityLevelTags = sessionTagData.filter(it => it.alias === 'CONFIDENTIALITY_LEVEL');
                  if(confidentialityLevelTags.length) {
                    this.createConfidentialityForm(confidentialityLevelTags[0], session);
                  }
                  else{
                    this.sessionTagService.createSessionTag(session.id, {
                      tagId: confidentialityLevelTagId,
                      value: 'C1'
                    }).subscribe(it => {
                      this.createConfidentialityForm(it, session);
                    });
                  }}
                );
              }, () => {
                // Swallow the error, feature is not activated
              });
            }
          });
        });
    });

    this.stepService.getStep(this.session.id, this.step.id).toPromise().then(dataStep =>
      this.step.auto = dataStep.auto
    ).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
    setTimeout(() => this.labelInput.nativeElement.select());

    this.showDayPicker = (this.isBeforeNow(this.signNotAfter) || this.isBeforeNow(this.signNotBefore));
    if (!this.signNotBefore && !this.signNotAfter && this.session.templateId) {
      this.showDayPicker = true;
    }

    this.initOrganisationForm();
    this.manageOrganisationFormChanged();
  }

  private createConfidentialityForm(tag: TagData, session: SessionData) {
    const initValue = tag.value;
    this.confidentialityForm = this.formBuilder.group({
      confidentialityLevel: [initValue]
    });
    this.confidentialityForm.valueChanges.subscribe(it => {
      this.sessionTagService.updateSessionTag(session.id, {
        tagId: tag.id,
        value: it.confidentialityLevel
      }).subscribe();
    });
  }

  ngOnDestroy() {
    this.debouncer.complete();
  }

  private initOrganisationForm() {
    this.organisationForm = this.formBuilder.group({
      selectedOrganisation: ['', [Validators.required]]
    });
  }

  reloadProvider(filter: string) {
    const directory = this.appService.getUser().directoryAlias;
    this.organisationService.searchOrganisations(directory, undefined,  1000, undefined, undefined, filter).subscribe(orgas => {
      this.organisationProvider = orgas.map(orga => {
        return {
          key: orga.name,
          value: orga.organisationId
        };
      });
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  private manageOrganisationFormChanged() {
    this.organisationSubscription = this.organisationForm.valueChanges.subscribe(values => {
      if (values.selectedOrganisation && this.session.organisationId !== parseInt(values.selectedOrganisation.value, 10)) {
        this.sessionService.partialSessionUpdate(this.session.id, { organisationId: parseInt(values.selectedOrganisation.value, 10) }).toPromise()
          .then((updatedSession) => this.session.organisationId = updatedSession.organisationId);
      }
    });
  }

  onSearchManager(searchEnduser: string) {
    if (searchEnduser && searchEnduser.trim()) {
      this.enduserService.searchEnduser(undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        searchEnduser).subscribe(endusers => {
        this.availableManager = endusers.filter(e => e.id !== this.appService.getCurrentUserId());
      }, (error: ApiError) => this.alertService.errorApi(error));
    } else {
      this.availableManager = [];
    }
  }

  addManager(manager: EnduserData) {
    this.loaderStatusManager = Status.LOAD;
    this.sessionEnduserService.createSessionManager(this.session.id, {
      enduserId: manager.id
    }).pipe(take(1)).subscribe(() => {
      this.selectedManager.push(manager);
      this.loaderStatusManager = Status.SUCCESS;
    }, () => {
      this.loaderStatusManager = Status.ERROR;
    });
  }

  removeManager(manager: EnduserData) {
    this.sessionEnduserService.deleteSessionManager(manager.id, this.session.id).pipe(take(1)).subscribe(() => {
      const index: number = this.selectedManager.findIndex((item: EnduserData) => item.id === manager.id);
      if (index > -1) {
        this.selectedManager.splice(index, 1);
      }
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  handleInputLabel(value: string) {
    this.stepNameError = false;
    this.stepNameLengthLimit = false;
    const trimmedValue = value.trim();

    // checks for empty label and length limitations
    if (trimmedValue !== '') {
      if (trimmedValue.length > 255) {
        this.stepNameLengthLimit = true;
      }
    } else {
      this.stepNameError = true;
    }

    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      if (!this.stepNameError) {
        if (!this.stepNameLengthLimit) {
          this.updateStepLabel(trimmedValue);
        }
      }
    }, 1000);
  }

  handleInputAutoStart() {
    this.wizardStepActionDisabled = true;
    this.stepService.partialUpdateStep(this.session.id, this.step.id, {auto: this.step.auto}).pipe(take(1))
      .subscribe(() => {
        this.wizardStepActionDisabled = false;
      }, () => {
        this.wizardStepActionDisabled = false;
      });
  }

  handleInputSkipAcro() {
    this.wizardStepActionDisabled = true;
    this.stepService.partialUpdateStep(this.session.id, this.step.id, {skipAcroforms: this.step.skipAcroforms}).pipe(take(1))
      .subscribe(() => {
        this.wizardStepActionDisabled = false;
      }, () => {
        this.wizardStepActionDisabled = false;
      });
  }

  handleDate(dateType: string) {
    setTimeout(() => {
      let notBefore = new Date(<any>this.signNotBefore), notAfter = new Date(<any>this.signNotAfter);
      if (this.signNotBefore && !this.signNotAfter) {
        if (notBefore.getTime() < Date.now()) {
          notBefore = new Date(new Date().getTime());
          this.signNotBefore = DateTimeModel.fromLocalString(notBefore.toISOString());
          if (!this.signNotAfter && notAfter < notBefore) {
            this.signNotAfter = undefined;
          }
          return;
        }
      } else if (!this.signNotBefore && this.signNotAfter) {
        if (notAfter.getTime() < Date.now()) {
          notAfter = new Date(new Date().getTime());
          this.signNotAfter = DateTimeModel.fromLocalString(notAfter.toISOString());
          return;
        }
      } else {
        if (notBefore.getTime() < Date.now() && this.signNotBefore) {
          notBefore = new Date(new Date().getTime());
          this.signNotBefore = DateTimeModel.fromLocalString(notBefore.toISOString());
          return;
        }
        if (notAfter.getTime() < Date.now() && this.signNotAfter) {
          notAfter = new Date(new Date().getTime());
          this.signNotAfter = DateTimeModel.fromLocalString(notAfter.toISOString());
          return;
        }
        if (notAfter < notBefore) {
          this.signNotAfter = DateTimeModel.fromLocalString(notBefore.toISOString());
          return;
        }
      }

      this.stepProperties.signNotBefore = notBefore;
      this.stepProperties.signNotAfter = notAfter;
      if (dateType === 'signnotBefore' && this.signNotBefore) {
        this.signatureDateNotBeforeStatus = Status.LOAD;
      }
      if (dateType === 'signnotAfter' && this.signNotAfter) {
        this.signatureDateNotAfterStatus = Status.LOAD;
      }
      this.wizardStepActionDisabled = true;
      this.stepPropertiesService.updateStepProperty(this.session.id, this.step.id, this.stepProperties).subscribe(() => {

        if (dateType === 'signnotBefore' && this.signNotBefore) {
          this.signatureDateNotBeforeStatus = Status.SUCCESS;
        }
        if (dateType === 'signnotAfter' && this.signNotAfter) {
          this.signatureDateNotAfterStatus = Status.SUCCESS;
        }
        this.wizardStepActionDisabled = false;
        dateType = '';
      }, () => {
        this.signatureDateNotBeforeStatus = Status.ERROR;
        this.signatureDateNotAfterStatus = Status.ERROR;
        this.wizardStepActionDisabled = false;
      });
    });
  }

  handleTemplateDate(date: Date, dateType: string) {
    this.showSmallerThanError = false;
    // const orDate = new Date(+0);

    if (dateType === 'signNotBefore') {
      this.stepProperties.signNotBefore = date;
      this.minDay = this.diffDate(this.stepProperties.signNotBefore) + 1;

    } else if (dateType === 'signNotAfter') {
      this.stepProperties.signNotAfter = date;
      this.maxDay = this.diffDate(this.stepProperties.signNotAfter) !== 0 ? this.diffDate(this.stepProperties.signNotAfter) - 1 : 0;
    }

    if ((this.stepProperties.signNotAfter && this.stepProperties.signNotBefore)
      && (this.diffDate(this.stepProperties.signNotAfter) <= this.diffDate(this.stepProperties.signNotBefore))) {
      if (this.diffDate(this.stepProperties.signNotAfter) === 0 && this.diffDate(this.stepProperties.signNotBefore) === 0 && this.session.templateId) {
        this.stepPropertiesService.updateStepProperty(this.session.id, this.step.id, this.stepProperties).subscribe(() => {
        }, () => {
          this.signatureDateNotBeforeStatus = Status.ERROR;
          this.signatureDateNotAfterStatus = Status.ERROR;
        });
      } else {
        this.showSmallerThanError = true;
      }
    } else {
      this.stepPropertiesService.updateStepProperty(this.session.id, this.step.id, this.stepProperties).subscribe(() => {
      }, () => {
        this.signatureDateNotBeforeStatus = Status.ERROR;
        this.signatureDateNotAfterStatus = Status.ERROR;
      });
    }

  }

  diffDate(defaultVal): number {
    if (defaultVal) {
      return Number(Math.ceil(Math.abs(defaultVal - new Date(0).getTime()) / (1000 * 60 * 60 * 24)));
    } else {
      return null;
    }
  }

  isBeforeNow(d: any): boolean {
    const date = new Date(d);
    const nowDate = new Date();
    const diff = nowDate > date;
    return diff;
  }

  clearBefore() {
    this.signNotBefore = undefined;
    (<any>document.getElementById('iddt-before')).value = '';
    this.handleDate('signnotBefore');
  }

  clearAfter() {
    this.signNotAfter = undefined;
    (<any>document.getElementById('iddt-after')).value = '';
    this.handleDate('signnotAfter');
  }

  handlePriority(value: number) {
    this.stepProperties.priority = value;
    this.priorityStatus = Status.LOAD;
    this.wizardStepActionDisabled = true;

    this.stepPropertiesService.updateStepProperty(this.session.id, this.step.id, this.stepProperties).pipe(take(1)).subscribe(() => {
      this.priorityStatus = Status.SUCCESS;
      this.wizardStepActionDisabled = false;
    }, () => {
      this.priorityStatus = Status.ERROR;
      this.wizardStepActionDisabled = false;
    });
  }

  generateTemplate() {
    if (this.stepProperties.signNotBefore) {
      this.stepProperties.signNotBefore = this.calculateDateInterval(this.stepProperties.signNotBefore);
    }
    if (this.stepProperties.signNotAfter) {
      this.stepProperties.signNotAfter = this.calculateDateInterval(this.stepProperties.signNotAfter);
    }
    const modalRef = this.modal.open(ModalCreateTemplateComponent, {backdrop: false});
    modalRef.componentInstance.templateNameText = this.session.label;
    this.generatingTemplate = true;
    modalRef.componentInstance.inProgress.subscribe(() => this.spinnerService.show());
    modalRef.result.then(value => {
      this.sessionService.partialSessionUpdate(this.session.id, {label: value}).toPromise().then(() => {
        return this.sessionService.makeSessionTemplate(this.session.id).toPromise().then(() => {
          if (this.stepProperties.signNotBefore && this.stepProperties.signNotAfter) {
            this.stepPropertiesService.updateStepProperty(this.session.id, this.step.id, this.stepProperties);
          }
          this.signNotBefore = <any>this.stepProperties.signNotBefore;
          this.signNotAfter = <any>this.stepProperties.signNotAfter;
          this.alertService.success('ALERT.TEMPLATE_SAVE');
          this.generatingTemplate = false;
          this.spinnerService.hide();
          this.wizardService.sessionIsTemplate = true;
        });
      }).catch((error: ApiError) => {
        this.generatingTemplate = false;
        this.alertService.errorApi(error);
      });
    }, () => this.generatingTemplate = false);
  }

  private calculateDateInterval(date: Date): Date {
    const dayDifference = Math.ceil(Math.abs(date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    return new Date(new Date(0).setDate(new Date(0).getDate() + dayDifference));
  }

  /**
   * Update the step label
   * @param label
   */
  private updateStepLabel(label) {
    const updatedStep: UpdateStepPayload = {
      label: label.trim()
    };

    this.labelStatus = Status.LOAD;
    this.wizardStepActionDisabled = true;

    this.stepService.partialUpdateStep(this.session.id, this.step.id, updatedStep).pipe(take(1))
      .subscribe(() => {
        this.labelStatus = Status.SUCCESS;
        this.wizardStepActionDisabled = false;
        this.step.label = label;
      }, () => {
        this.labelStatus = Status.ERROR;
        this.wizardStepActionDisabled = false;
      });
  }
}
