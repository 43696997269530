<div class="form-group">
  <div class="switcher switcher-right">
    <input #input
           (change)="onSwitch($event.target.checked)"
           [attr.aria-describedby]="id+'Hint'"
           [checked]="ngControl.value === true ? 'checked': null"
           [disabled]="disabled"
           [id]="id"
           class="switcher-checkbox"
           type="checkbox">
    <div class="switcher-toggle"></div>
    <label [for]="id">{{label}}</label>
  </div>
  <div *ngIf="ngControl.touched && ngControl.invalid; else showHint"
       class="invalid-feedback">
    {{getFirstError()}}
  </div>
  <ng-template #showHint>
    <small *ngIf="hint"
           [id]="id+'Hint'"
           class="form-text text-muted">{{hint}}</small>
  </ng-template>
</div>
