/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Sessions sorting filter (null values ignored)
 */
export interface SortingFilter { 
    /**
     * True for ascending, false for descending
     */
    asc?: boolean;
    /**
     * Field to use for sorting
     */
    field?: SortingFilter.FieldEnum;
}
export namespace SortingFilter {
    export type FieldEnum = 'CREATED' | 'MODIFIED' | 'PRIORITY';
    export const FieldEnum = {
        CREATED: 'CREATED' as FieldEnum,
        MODIFIED: 'MODIFIED' as FieldEnum,
        PRIORITY: 'PRIORITY' as FieldEnum
    };
}
