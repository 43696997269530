import {Component} from '@angular/core';

import {FormElement} from '../form.element';

@Component({
  selector: 'app-switcher', templateUrl: './switcher.component.html', styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent extends FormElement<boolean> {

  onSwitch(value: boolean) {
    this.onChange(value);
    this.onTouched(value);
  }
}
