import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationExtras, Router} from '@angular/router';
import {TranslateService} from 'src/app/core/service/translate.service';
import cssVars from 'css-vars-ponyfill';
import {PlatformService} from 'luxtrust-cosi-api/api/platform.service';
import {CONFIG} from './app.constant';
import {AlertService} from './services/services/alert-service';
import {AppService} from './services/services/app.service';
import {ConfigurationService} from './services/services/configuration.service';
import {LanguagesService} from './services/services/languages.service';
import {UrlService} from './services/services/url.service';
import {VisualIdentityService} from './services/services/visual-identity.service';

@Component({
  selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private alertService: AlertService,
              public translateService: TranslateService,
              private urlService: UrlService,
              private languagesService: LanguagesService,
              private appService: AppService,
              private visualIdentityService: VisualIdentityService,
              private platformService: PlatformService,
              private configurationService: ConfigurationService,
              private titleService: Title
  ) {
    // FIXME ALERT
    this.appService.config$ = this.platformService.getConfig().toPromise()
      .then(config => this.appService.config = config && config);
    const oldNavigate = this.router.navigate;
    this.router.navigate = (function (commands, navigationExtras: NavigationExtras) {
      navigationExtras = navigationExtras || {};
      navigationExtras.queryParams = navigationExtras.queryParams || {};
      navigationExtras.queryParams['tenantName'] = appService.getUser() && appService.getUser().tenantName || undefined;
      navigationExtras.queryParamsHandling = '';
      return oldNavigate.call(this, commands, navigationExtras);
    }).bind(this.router);

    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang(languagesService.languages[0]);

    const userLang = translateService.getBrowserLang();

    // the lang to use, if the lang isn't available, it will use the default one
    if (languagesService.languages.some(lang => lang === userLang)) {
      translateService.use(userLang);
    }
  }

  ngOnInit() {
    cssVars();
    this.appService.loadEnduser();
    const tenantName = this.urlService.getQueryParamByName('tenantName');
    this.configurationService.initPlatformConfiguration();
    this.configurationService.initTenantConfiguration(tenantName);
    this.configurationService.tenantConfig.then(tenantConfig => {
      this.appService.initHandlers(tenantConfig[CONFIG.TENANT.FRONTEND_TIMEOUT_MINUTES.KEY]);
      this.setDocumentTitle(tenantConfig);
      const tenantLanguageList = tenantConfig[CONFIG.TENANT.SUPPORTED_LANGUAGE_LIST.KEY];
      const languages = tenantLanguageList ? tenantLanguageList.split('|') : undefined;
      if (languages && languages.length !== 0) {
        this.languagesService.set(languages);
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang(languages[0]);

        const userLang = this.translateService.getBrowserLang();

        // the lang to use, if the lang isn't available, it will use the default one
        if (languages.some(lang => lang === userLang)) {
          this.translateService.use(userLang);
        }
      }
    });
    this.visualIdentityService.initVisualIdentity(tenantName);

  }

  private setDocumentTitle(tenantConfig: { [key: string]: any; }) {
    const documentTitleConfig = tenantConfig[CONFIG.TENANT.FRONTEND_DOCUMENT_TITLE.KEY];
    if (documentTitleConfig) {
      this.titleService.setTitle(documentTitleConfig.trim());
    }
  }
}
