import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class InviteGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate(route, state) {
        const tenantNameQueryParam = route.queryParamMap.get('tenantName');
        const tenantNameParam = route.paramMap.get('tenantName');
        if (tenantNameQueryParam || tenantNameParam) {
            return true;
        }
        else {
            this.router.navigate(['/no-tenant-name']);
            return false;
        }
    }
}
InviteGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InviteGuard_Factory() { return new InviteGuard(i0.ɵɵinject(i1.Router)); }, token: InviteGuard, providedIn: "root" });
