import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NexuSignatureService} from 'luxtrust-cosi-api/api/nexuSignature.service';
import {FinalizeSignatureRequest} from 'luxtrust-cosi-api/model/finalizeSignatureRequest';
import {FinalizeSignatureResponse} from 'luxtrust-cosi-api/model/finalizeSignatureResponse';
import {NexuConfiguration} from 'luxtrust-cosi-api/model/nexuConfiguration';
import {SignatureInformationRequest} from 'luxtrust-cosi-api/model/signatureInformationRequest';
import {SignatureRequest} from 'luxtrust-cosi-api/model/signatureRequest';
import {TokenId} from 'luxtrust-cosi-api/model/tokenId';

export interface SignatureResponse {
  response: {
    certificate: string; certificateChain: string[]; signatureAlgorithm: any; signatureValue: string;
  };
}

export interface ClientCertificate {
  response: {
    tokenId: TokenId; keyId: string; certificate: string; certificateChain: string[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class NexuService {

  static URL_NEXU_SPECIFIC = '/rest/';
  private config: NexuConfiguration;

  constructor(protected httpClient: HttpClient, private signatureService: NexuSignatureService) {
  }

  sign(businessId: string): Promise<FinalizeSignatureResponse> {
    return (this.config ? Promise.resolve(this.config) : this.signatureService.getNexuConfig().toPromise()
    .then(nexuConfiguration => this.config = nexuConfiguration && nexuConfiguration)).then(config => {
      return this.getCertificateChain(config).then(clientCertificate => {
        const body: SignatureInformationRequest = {
          businessId: businessId,
          base64Chain: clientCertificate.response.certificateChain,
          keyId: clientCertificate.response.keyId,
          base64Cert: clientCertificate.response.certificate,
          tokenId: clientCertificate.response.tokenId.id
        };
        return this.signatureService.handleNexuSignature(body).toPromise().then(signatureInformation => {
          return this.doClientSign(config, signatureInformation.signatureRequest)
            .then(clientSignatureInformation => {
              const body2: FinalizeSignatureRequest = {
                certificate: clientSignatureInformation.response.certificate,
                certificateChain: clientSignatureInformation.response.certificateChain,
                signatureAlgorithm: clientSignatureInformation.response.signatureAlgorithm,
                signatureValue: clientSignatureInformation.response.signatureValue,
                signingDate: new Date(signatureInformation.signingDate)
              };
              return this.signatureService.handleNexuSignatureCallback(businessId, body2).toPromise();
          });
        });
      });
    });
  }

  private getCertificateChain(config: NexuConfiguration): Promise<ClientCertificate> {
    return this.httpClient.get<ClientCertificate>(
      config.scheme + '://localhost:' + config.port + NexuService.URL_NEXU_SPECIFIC + 'certificates', {
        withCredentials: false
      }).toPromise();
  }

  private doClientSign(config: NexuConfiguration, signatureRequest: SignatureRequest): Promise<SignatureResponse> {
    return this.httpClient.post<SignatureResponse>(config.scheme + '://localhost:' + config.port + NexuService.URL_NEXU_SPECIFIC + 'sign',
      signatureRequest, {
        withCredentials: false
      }).toPromise();
  }

}
