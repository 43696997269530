import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatternService } from 'luxtrust-cosi-api/api/pattern.service';
import { PatternData } from 'luxtrust-cosi-api/model/patternData';
import { AlertService } from 'src/app/services/services/alert-service';
import { ApiError } from '../../../error/api-error.model';

@Component({
  templateUrl: './template-builder.component.html', styleUrls: ['./template-builder.component.scss']
})
export class TemplateBuilderComponent {

  verticalMode = false;
  darkMode = false;
  saving = false;

  patternData: PatternData;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly patternService: PatternService,
    private readonly alertService: AlertService
  ) {
  }

  setPattern(patternData: PatternData) {
    this.patternData = patternData;
  }

  onClose() {
    this.activeModal.close();
  }

  onInput(patternData: PatternData) {
    this.patternData = patternData;
  }

  save() {
    let promise: Promise<PatternData>
    this.saving = true;
    if (this.patternData.stepId || this.patternData.sessionId) {
      promise = this.patternService.updateSpecificNotificationPattern(this.patternData.id, {
        subject: this.patternData.subject, body: this.patternData.body, tagValue: this.patternData.tagValue
      }).toPromise();
    } else {
      promise = this.patternService.updateGenericNotificationPattern(this.patternData.id, {
        subject: this.patternData.subject, body: this.patternData.body
      }).toPromise();
    }
    promise.then(() => {
      this.alertService.success('MODAL_TEMPLATE_BUILDER.SUCCESS');
      this.activeModal.close(true);
    }, (error: ApiError) => {
      this.saving = false;
      this.alertService.errorApiWithCustomMessage('MODAL_TEMPLATE_BUILDER.ERROR', error)
    });
  }
}
