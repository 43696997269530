/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Document { 
    /**
     * File content in a byte array format
     */
    fileContent?: string;
    /**
     * Name of the file to sign
     */
    fileName?: string;
    /**
     * Hash computation algorithm (to define with the client)
     */
    hashAlgo?: Document.HashAlgoEnum;
    /**
     * Hash of the file sent (RSA signed - Public key must be shared with LuxTrust Signature Platform - optional)
     */
    signedFileHash?: string;
}
export namespace Document {
    export type HashAlgoEnum = 'SHA-1' | 'SHA-256' | 'MD5';
    export const HashAlgoEnum = {
        SHA1: 'SHA-1' as HashAlgoEnum,
        SHA256: 'SHA-256' as HashAlgoEnum,
        MD5: 'MD5' as HashAlgoEnum
    };
}
