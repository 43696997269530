import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SessionService, SessionTemplateService } from 'luxtrust-cosi-api';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {StepRuleOrderService} from 'luxtrust-cosi-api/api/stepRuleOrder.service';
import {CreateStepEnduserPayload} from 'luxtrust-cosi-api/model/createStepEnduserPayload';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {take} from 'rxjs/operators';
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';
import {AppService} from '../../../../services/services/app.service';
import {Status} from '../../../../shared/components/input-loader/input-loader.component';
import {StepEnduserComponent} from '../../model/stepEnduser.component';
import {StepActions} from '../../wizard/models/wizard.config';

@Component({
  templateUrl: './wizard-step-users.component.html', styleUrls: ['./wizard-step-users.component.scss']
})

export class WizardStepUsersComponent extends StepEnduserComponent implements OnInit {

  session: SessionData;
  step: StepData;

  stepActions = StepActions;

  stepEndusersSorted = false;

  availableAddressee: EnduserData[];
  availableManager: EnduserData[];

  loaderStatusAddressee: Status;
  loaderStatusUsers: Status;
  loaderStatusManager: Status;
  removingUser = false;
  removingAddresseeOrManager = false;

  replaceMode: {manager: boolean, signer: boolean, watcher: boolean};

  constructor(protected appService: AppService,
              private route: ActivatedRoute,
              protected enduserService: EnduserService,
              protected stepEnduserService: StepEnduserService,
              private sessionService: SessionService,
              private templateService: SessionTemplateService,
              private stepRuleOrderService: StepRuleOrderService,
              protected alertService: AlertService,
              protected modalService: NgbModal) {
    super(modalService, appService, stepEnduserService, alertService, enduserService);
  }

  ngOnInit() {
    this.route.parent.data.pipe().subscribe((data: Data) => {
      this.session = data['session'];
      this.sessionId = this.session.id;
      this.step = data['step'];
    });
    this.getStepEndusers();
  }

  getStepEndusers() {
    this.stepEnduserService.stepEnduserList(this.session.id, this.step.id).pipe(take(1))
    .subscribe((stepEndusers: StepEnduserData[]) => {
      this.stepEndusers = stepEndusers.sort((a, b) => a.orderIndex - b.orderIndex);
      this.stepEndusersSorted = stepEndusers.some(se => se.orderIndex !== stepEndusers[0].orderIndex);
      this.getSelectedUsers();
      this.getSelectedAddressee();
      this.getSelectedManager();
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  getAvailableAddressee(searchEnduser) {
    if (searchEnduser && searchEnduser.trim()) {
      this.enduserService.searchEnduser(undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        searchEnduser).subscribe(endusers => {
        this.availableAddressee = this.filterAvailableAddressee(endusers);
      }, (error: ApiError) => this.alertService.errorApi(error));
    } else {
      this.availableAddressee = [];
    }
  }

  getAvailableManager(searchEnduser) {
    if (searchEnduser && searchEnduser.trim()) {
      this.enduserService.searchEnduser(undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        searchEnduser).subscribe(endusers => {
        this.availableManager = this.filterAvailableManager(endusers);
      }, (error: ApiError) => this.alertService.errorApi(error));
    } else {
      this.availableManager = [];
    }
  }

  onSearchAddressee(searchEndusers) {
    this.getAvailableAddressee(searchEndusers);
  }

  onSearchManager(searchEndusers) {
    this.getAvailableManager(searchEndusers);
  }

  onUpdateExpected(event) {
    const circle = event.user;
    const nb = event.nb;
    this.stepEnduserService.partialUpdateStepEnduser(circle.id, this.session.id, this.step.id, {
      signer: true,
      expected: nb
    }).toPromise();
  }

  addAddressee(addressee: EnduserData) {
    this.loaderStatusAddressee = Status.LOAD;

    const createStepEnduserPayload: CreateStepEnduserPayload = {
      enduserId: addressee.id, manager: false, signer: false
    };
    this.stepEnduserService.createStepEnduser(this.session.id, this.step.id, createStepEnduserPayload).subscribe(() => {
      this.selectedAddressee.push(addressee);
      this.loaderStatusAddressee = Status.SUCCESS;
    }, () => {
      this.loaderStatusAddressee = Status.ERROR;
    });
  }

  orderUsers(users: EnduserData[]) {
    this.loaderStatusUsers = Status.LOAD;

    this.stepRuleOrderService.updateStepEnduserOrder(this.session.id, this.step.id, {
      orders: users.map((u, i) => ({
        enduserId: u.id, orderIndex: i
      }))
    }).subscribe(() => {
      this.loaderStatusUsers = Status.SUCCESS;
    }, (error) => {
      this.alertService.errorApi(error);
      this.loaderStatusUsers = Status.ERROR;
    });
  }

  unsortUsers(users: EnduserData[]) {
    this.loaderStatusUsers = Status.LOAD;

    this.stepRuleOrderService.updateStepEnduserOrder(this.session.id, this.step.id, {
      orders: users.map(u => ({
        enduserId: u.id, orderIndex: 0
      }))
    }).subscribe(() => {
      this.loaderStatusUsers = Status.SUCCESS;
    }, (error) => {
      this.alertService.errorApi(error);
      this.loaderStatusUsers = Status.ERROR;
    });
  }

  addUsers(user: EnduserData) {
    this.loaderStatusUsers = Status.LOAD;
    const createStepEnduserPayload: CreateStepEnduserPayload = this.createPayloadForSigner(user).stepEnduserPayload;
    this.stepEnduserService.createStepEnduser(this.session.id, this.step.id, createStepEnduserPayload).subscribe((result) => {
        this.selectedUsers.push(result.enduser);
        this.loaderStatusUsers = Status.SUCCESS;
      }, (error: ApiError) => {
      this.loaderStatusUsers = Status.ERROR;
      this.alertService.errorApi(error);
      });
  }

  addManager(manager: EnduserData) {
    this.loaderStatusManager = Status.LOAD;

    const alreadySigner = this.selectedUsers.find(enduser => enduser.id === manager.id);

    const createStepEnduserPayload: CreateStepEnduserPayload = {
      enduserId: manager.id, signer: alreadySigner ? true : false, manager: true
    };

    this.stepEnduserService.createStepEnduser(this.session.id, this.step.id, createStepEnduserPayload).subscribe(() => {
        this.selectedManager.push(manager);
        this.loaderStatusManager = Status.SUCCESS;
      }, () => {
        this.loaderStatusManager = Status.ERROR;
      }
    );
  }

  removeAddressee(addressee: EnduserData) {
      this.removeAddresseeOrManager(addressee, this.selectedAddressee);
    }

    removeAddresseeOrManager(enduser: EnduserData, listTosplice: EnduserData[]) {
    this.removingAddresseeOrManager = true;
      this.stepEnduserService.deleteStepEnduser(enduser.id, this.session.id, this.step.id).toPromise().then(() => {
        const index: number = listTosplice.findIndex((item: EnduserData) => item.id === enduser.id);
        if (index > -1) {
          listTosplice.splice(index, 1);
        }
      }, (error: ApiError) => this.alertService.errorApi(error))
        .then(value => this.removingAddresseeOrManager = false);
    }

  removeUsers(user: EnduserData) {
    if (user.id === this.appService.getCurrentUserId()) {
      const createStepEnduserPayload: CreateStepEnduserPayload = {
        enduserId: user.id, signer: false
      };
      this.removingUser = true;
      this.stepEnduserService.createStepEnduser(this.session.id, this.step.id, createStepEnduserPayload)
        .toPromise()
        .catch((error: ApiError) => this.alertService.errorApi(error)).then(value => this.removingUser = false);
      const index: number = this.selectedUsers.findIndex((item: EnduserData) => item.id === user.id);
      if (index > -1) {
        this.selectedUsers.splice(index, 1);
      }
    } else {
      // normal case - related to quick fix
      if (this.selectedManager.find(manager => manager.id === user.id)) {
        this.removingUser = true;
        this.stepEnduserService.partialUpdateStepEnduser(user.id, this.session.id, this.step.id, {
          signer: false,
          manager: true
        }).toPromise().then(() => {
          this.getStepEndusers();
          const index: number = this.selectedUsers.findIndex((item: EnduserData) => item.id === user.id);
          if (index > -1) {
            this.selectedUsers.splice(index, 1);
          }
        }).catch((error: ApiError) => {
          this.alertService.errorApi(error);
        }).then(value => this.removingUser = true);
      } else {
        this.removingUser = true;
        this.stepEnduserService.deleteStepEnduser(user.id, this.session.id, this.step.id).toPromise().then(() => {
          const index: number = this.selectedUsers.findIndex((item: EnduserData) => item.id === user.id);
          if (index > -1) {
            this.selectedUsers.splice(index, 1);
          }
        }).catch((error: ApiError) => {
          this.alertService.errorApi(error);
        })
          .then(value => this.removingUser = false);
      }
    }
  }

  removeManager(manager: EnduserData) {
    if (this.selectedUsers.find(user => user.id === manager.id)) {
      this.removingAddresseeOrManager = true;
      this.stepEnduserService.partialUpdateStepEnduser(manager.id, this.session.id, this.step.id, {
        manager: false
      }).toPromise().then(() => {
        this.getStepEndusers();
        const index: number = this.selectedManager.findIndex((item: EnduserData) => item.id === manager.id);
        if (index > -1) {
          this.selectedManager.splice(index, 1);
        }
        this.removingAddresseeOrManager = false;
      });
    } else {
      this.removeAddresseeOrManager(manager, this.selectedManager);
    }
  }

  private filterAvailableAddressee(endusers: EnduserData[]) {
    return endusers.filter((enduser: EnduserData) => {
      return enduser.id !== this.appService.getCurrentUserId() && !this.selectedAddressee.some(
        (addressee: EnduserData) => enduser.id === addressee.id) && !this.selectedUsers.some(
        (user: EnduserData) => enduser.id === user.id) && !this.selectedManager.some((user: EnduserData) => enduser.id === user.id);
    });
  }

  private filterAvailableManager(endusers: EnduserData[]) {
    return endusers.filter((enduser: EnduserData) => {
      return !this.selectedAddressee.some((addressee: EnduserData) => enduser.id === addressee.id) && !this.selectedManager.some(
        (user: EnduserData) => enduser.id === user.id) && enduser.id !== this.appService.getCurrentUserId();
    });
  }

  changeReplaceMode(type: string) {
    if (type === 'S') {
      this.replaceMode = {manager: false, signer: true, watcher : false};
    } else if (type === 'M') {
      this.replaceMode = {manager: true, signer: false, watcher : false};
    } else if (type === 'W') {
      this.replaceMode = {manager: false, signer: false, watcher : true};
    }
  }
}
