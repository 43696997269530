import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {CommentService} from 'luxtrust-cosi-api/api/comment.service';
import {CommentData} from 'luxtrust-cosi-api/model/commentData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {take} from 'rxjs/operators';
import {quillConfig} from '../../../../services/enum/quill-config';
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';
import {StepActions} from '../../wizard/models/wizard.config';
import {WizardService} from '../../wizard/services/wizard.service';

@Component({
  templateUrl: './wizard-step-comments.component.html', styleUrls: ['./wizard-step-comments.component.scss']
})
export class WizardStepCommentsComponent implements OnInit {

  quillConfig = quillConfig;
  session: SessionData;
  step: StepData;
  stepActions = StepActions;
  comment: string;
  commentData: CommentData [] = [];
  addingComment = false;
  removingComment = false;
  updatingComment = false;

  constructor(private route: ActivatedRoute,
              private commentService: CommentService,
              public wizardService: WizardService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.route.parent.data.pipe(take(1)).subscribe((data: Data) => {
      this.session = data['session'];
      this.step = data['step'];
    });
    this.getComments();
  }

  addComment() {
    if (this.comment && this.comment.trim()) {
      this.addingComment = true;
      this.commentService.createComment(this.session.id, this.step.id, {
        type: CommentData.TypeEnum.SIMPLE, text: this.comment
      })
        .pipe(take(1))
        .toPromise()
        .then((comment: CommentData) => {
          this.commentData.push(comment);
          this.comment = '';
        }, (error: ApiError) => this.alertService.errorApiWithCustomMessage('STEP.COMMENT.ERROR.CREATE_COMMENT', error))
        .then(value => this.addingComment = false);
    }
  }

  getComments() {
    this.commentService.searchComments(this.session.id, this.step.id).toPromise().then(comments => {
      this.commentData = comments.filter(comment => comment.stepId === this.step.id);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  deleteComment(event) {
    this.removingComment = true;
    this.commentService.deleteComment(event.id, this.session.id , this.step.id)
      .pipe(take(1))
      .toPromise()
      .then(() => {
        const index = this.commentData.findIndex(com => com.id === event.id);
        this.commentData.splice(index, 1);
      }, (error: ApiError) => this.alertService.errorApiWithCustomMessage('STEP.COMMENT.ERROR.DELETE_COMMENT', error))
      .then(value => this.removingComment = false);
  }

  editComment(event) {
    if (event.text.trim()) {
      this.updatingComment = true;
      this.commentService.updateComment(event.commemtId, this.session.id , this.step.id, {
        text: event.text
      })
        .pipe(take(1))
        .toPromise()
        .then((comment: CommentData) => {
          this.commentData.find(com => com.id === comment.id).text = comment.text;
        }, (error: ApiError) => this.alertService.errorApiWithCustomMessage('STEP.COMMENT.ERROR.EDIT_COMMENT', error))
        .then(value => this.updatingComment = false);
    }
  }
}
