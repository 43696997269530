/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./template-editor-output-panel/template-editor-output-panel.component.ngfactory";
import * as i3 from "./template-editor-output-panel/template-editor-output-panel.component";
import * as i4 from "../../../core/pipes/translate.pipe";
import * as i5 from "../../../core/service/translate.service";
import * as i6 from "@angular/common";
import * as i7 from "./template-editor.component";
var styles_TemplateEditorComponent = [i0.styles];
var RenderType_TemplateEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplateEditorComponent, data: {} });
export { RenderType_TemplateEditorComponent as RenderType_TemplateEditorComponent };
function View_TemplateEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_TemplateEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-arrows-alt-h"]], null, null, null, null, null))], null, null); }
function View_TemplateEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-arrows-alt-v"]], null, null, null, null, null))], null, null); }
function View_TemplateEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "far fa-moon"]], null, null, null, null, null))], null, null); }
function View_TemplateEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-moon"]], null, null, null, null, null))], null, null); }
function View_TemplateEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-editor-output-panel", [], null, null, null, i2.View_TemplateEditorOutputPanelComponent_0, i2.RenderType_TemplateEditorOutputPanelComponent)), i1.ɵdid(1, 49152, null, 0, i3.TemplateEditorOutputPanelComponent, [], { patternData: [0, "patternData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.patternData; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TemplateEditorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("MODAL_TEMPLATE_BUILDER.OUTPUT_PLACEHOLDER_VERTICAL")); _ck(_v, 1, 0, currVal_0); }); }
function View_TemplateEditorComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("MODAL_TEMPLATE_BUILDER.OUTPUT_PLACEHOLDER_HORIZONTAL")); _ck(_v, 0, 0, currVal_0); }); }
function View_TemplateEditorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "code-output-empty"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateEditorComponent_8)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["horizontalMode", 2]], null, 0, null, View_TemplateEditorComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.verticalMode; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_TemplateEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["subjectInput", 1]], null, 1, "input", [["class", "modal-title-input"], ["type", "text"]], [[8, "value", 0], [8, "placeholder", 0]], [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onSubjectInput(i1.ɵnov(_v, 2).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateEditorComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 27, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 26, "div", [["class", "code-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "vertical": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 10, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "button", [["class", "to-vertical"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.verticalMode = !_co.verticalMode) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateEditorComponent_2)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateEditorComponent_3)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 4, "button", [["class", "darkmode"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.darkMode = !_co.darkMode) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateEditorComponent_4)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateEditorComponent_5)), i1.ɵdid(21, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "code-output"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateEditorComponent_6)), i1.ɵdid(24, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateEditorComponent_7)), i1.ɵdid(26, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 6, "div", [["class", "code-input"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(29, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(30, { "darkMode": 0 }), (_l()(), i1.ɵeld(31, 0, [["bodyInput", 1]], null, 2, "textarea", [], [[8, "value", 0], [8, "placeholder", 0]], [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onBodyInput(i1.ɵnov(_v, 31).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService]), (_l()(), i1.ɵted(-1, null, ["      "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.displayCloseButton; _ck(_v, 5, 0, currVal_2); var currVal_3 = "code-content"; var currVal_4 = _ck(_v, 10, 0, _co.verticalMode); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_5 = _co.verticalMode; _ck(_v, 14, 0, currVal_5); var currVal_6 = !_co.verticalMode; _ck(_v, 16, 0, currVal_6); var currVal_7 = _co.darkMode; _ck(_v, 19, 0, currVal_7); var currVal_8 = !_co.darkMode; _ck(_v, 21, 0, currVal_8); var currVal_9 = ((_co.patternData == null) ? null : _co.patternData.body); _ck(_v, 24, 0, currVal_9); var currVal_10 = !((_co.patternData == null) ? null : _co.patternData.body); _ck(_v, 26, 0, currVal_10); var currVal_11 = "code-input"; var currVal_12 = _ck(_v, 30, 0, _co.darkMode); _ck(_v, 29, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.patternData == null) ? null : _co.patternData.subject); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform("MODAL_TEMPLATE_BUILDER.TITLE")), ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_13 = ((_co.patternData == null) ? null : _co.patternData.body); var currVal_14 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 31, 1, i1.ɵnov(_v, 32).transform("MODAL_TEMPLATE_BUILDER.CODE_PLACEHOLDER")), ""); _ck(_v, 31, 0, currVal_13, currVal_14); }); }
export function View_TemplateEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-editor", [], null, null, null, View_TemplateEditorComponent_0, RenderType_TemplateEditorComponent)), i1.ɵdid(1, 49152, null, 0, i7.TemplateEditorComponent, [], null, null)], null, null); }
var TemplateEditorComponentNgFactory = i1.ɵccf("app-template-editor", i7.TemplateEditorComponent, View_TemplateEditorComponent_Host_0, { displayCloseButton: "displayCloseButton", patternData: "patternData" }, { inputEvent: "inputEvent", closeEvent: "closeEvent" }, []);
export { TemplateEditorComponentNgFactory as TemplateEditorComponentNgFactory };
