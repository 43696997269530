import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { LegalNoticeService } from 'luxtrust-cosi-api/api/legalNotice.service';
import { StepLegalMotionService } from 'luxtrust-cosi-api/api/stepLegalMotion.service';
import { LegalMotion } from 'luxtrust-cosi-api/model/legalMotion';
import { SessionData } from 'luxtrust-cosi-api/model/sessionData';
import { StepData } from 'luxtrust-cosi-api/model/stepData';
import { StepLegalMotion } from 'luxtrust-cosi-api/model/stepLegalMotion';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { KEYBOARD } from 'src/app/app.constant';
import { IEService } from 'src/app/services/services/ie.service';
import { StepActions } from 'src/app/session/step/wizard/models/wizard.config';
import { Status } from 'src/app/shared/components/input-loader/input-loader.component';
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';

@Component({
  templateUrl: './wizard-step-legal.html', styleUrls: ['./wizard-step-legal.scss']
})
export class WizardStepLegal implements OnInit, OnDestroy {

  @ViewChild('searchList', {
    read: ElementRef, static: false
  }) searchList;

  debouncer: Subject<string> = new Subject();

  session: SessionData;
  step: StepData;
  legalMotions: LegalMotion[] = [];
  selectedStepLegalMotions: StepLegalMotion[] = [];
  availableLegalMotions: LegalMotion[] = [];

  searchListOpen = false;
  inputSearch: string;
  loaderStatus: Status;

  stepActions = StepActions;
  subscription: Subscription;
  activeLegalMotionIndex = -1;
  private isIE = false;
  checkBrowserInput = false;
  checkBrowserFocus = false;

  handlingAddLegalMotion = false;
  handlingUpdateLegalMotion = false;
  handlingDeleteLegalMotion = false;

  constructor(
    private readonly legalMotionService: LegalNoticeService,
    private readonly route: ActivatedRoute,
    private readonly stepLegalMotionService: StepLegalMotionService,
    private readonly alertService: AlertService,
    private readonly ieService: IEService
  ) {
    this.registerInputDebouncer();
  }

  /**
   * listen where the user is clicking. If he is clicking outside this component
   * the list box will close if it's opened.
   * @param targetElement
   */
  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    const insideSearchList = this.searchList.nativeElement.contains(targetElement);

    if (!insideSearchList) {
      this.onBlurSearch();
    }
  }

  ngOnInit() {
    this.isIE = this.ieService.checkIfIE();
    this.subscription = this.route.parent.data.pipe().subscribe((data: Data) => {
      this.session = data['session'];
      this.step = data['step'];
      this.load();
    });
  }

  load() {
    this.legalMotionService.getListLegalNotices().pipe(take(1)).subscribe((legalMotions: LegalMotion[]) => {
      this.legalMotions = legalMotions;
    }, (error: ApiError) => this.alertService.errorApi(error));
    this.stepLegalMotionService.getStepLegalNotices(this.session.id, this.step.id).pipe(take(1))
    .subscribe((legalMotions: StepLegalMotion[]) => {
      this.selectedStepLegalMotions = legalMotions;
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  ngOnDestroy() {
    this.debouncer.complete();
    this.subscription.unsubscribe();
  }

  filterAvailableLegalMotion(value: string) {
    let availableLegalMotions = this.legalMotions.filter(legalMotion => {
      return !this.selectedStepLegalMotions.some((stepLegalMotion: StepLegalMotion) => stepLegalMotion.legal.id === legalMotion.id);
    });

    if (value.trim()) {
      availableLegalMotions = availableLegalMotions.filter((item: LegalMotion) => {
        return item.text.toLowerCase().match(value.toLowerCase()) || item.alias.toLowerCase()
        .match(value.toLowerCase()) || item.title.toLowerCase().match(value.toLowerCase());
      });
    }

    this.availableLegalMotions = availableLegalMotions;
  }

  registerInputDebouncer() {
    this.debouncer.pipe(debounceTime(300))
    .subscribe((value) => this.filterAvailableLegalMotion(value));
  }

  handleInputChange(value: string) {
    this.debouncer.next(value);
    if (this.isIE) {
      if (this.checkBrowserInput) {
        this.searchListOpen = true;
      }
      this.checkBrowserInput = true;
    } else {this.searchListOpen = true; }
    this.filterAvailableLegalMotion(value);
  }

  onFocusSearch() {
    if (this.isIE) {
      if (this.checkBrowserFocus) {
        this.searchListOpen = true;
      }
      this.checkBrowserFocus = true;
    } else {this.searchListOpen = true; }
    this.filterAvailableLegalMotion('');
  }

  onBlurSearch() {
    this.activeLegalMotionIndex = 0;
    this.searchListOpen = false;
    this.inputSearch = '';
  }

  handleOnAddLegalMotion(legal: LegalMotion) {
    this.loaderStatus = Status.LOAD;
    this.inputSearch = '';
    this.handlingAddLegalMotion = true;
    this.stepLegalMotionService.createStepLegalNotice(this.session.id, this.step.id, {
      legalId: legal.id
    }).toPromise().then(() => {
      this.loaderStatus = Status.SUCCESS;
      this.selectedStepLegalMotions.push(<StepLegalMotion>{
        check: false, legal: legal, mandatory: false, stepId: this.step.id
      });
      this.filterAvailableLegalMotion('');
      this.searchListOpen = false;
    }, (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.handlingAddLegalMotion = false );
  }

  handleOnRemoveLegalMotion(stepLegal: StepLegalMotion) {
    this.handlingDeleteLegalMotion = true;
    this.stepLegalMotionService.deleteStepLegalNotice(stepLegal.legal.id, this.session.id, this.step.id).pipe(take(1))
      .toPromise()
      .then(() => this.refreshSelectedLegalMotions(stepLegal, false), (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.handlingDeleteLegalMotion = false);
  }

  handleUpdateLegalMotion(stepLegalMotion: StepLegalMotion) {
    this.handlingUpdateLegalMotion = true;
    this.loaderStatus = Status.LOAD;
    this.stepLegalMotionService.updateStepLegalNotice(stepLegalMotion.legal.id, this.session.id, this.step.id, {
      mandatory: stepLegalMotion.mandatory
    }).toPromise().then(() => {
      this.refreshSelectedLegalMotions(stepLegalMotion, true);
      this.loaderStatus = Status.SUCCESS;
    }, (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.handlingUpdateLegalMotion = false);
  }

  legalMotionKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEYBOARD.ARROW_UP:
        if (this.activeLegalMotionIndex > -1) {
          this.activeLegalMotionIndex--;
        }
        break;
      case KEYBOARD.ARROW_DOWN:
        if (this.activeLegalMotionIndex < this.availableLegalMotions.length - 1) {
          this.activeLegalMotionIndex++;
        }
        break;
      case KEYBOARD.ENTER:
        if (this.activeLegalMotionIndex !== -1) {
          this.handleOnAddLegalMotion(this.availableLegalMotions[this.activeLegalMotionIndex]);
        }
        break;
    }
  }

  private refreshSelectedLegalMotions(stepLegalMotion: StepLegalMotion, shouldUpdate: boolean) {
    const index = this.selectedStepLegalMotions.findIndex(item => item.legal.id === stepLegalMotion.legal.id);
    if (index > -1 && index < this.selectedStepLegalMotions.length) {
      if (shouldUpdate) {
        this.selectedStepLegalMotions.splice(index, 1, stepLegalMotion);
      } else {
        this.selectedStepLegalMotions.splice(index, 1);
      }
    }
  }
}
