/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FinalizeSignatureRequest } from '../model/finalizeSignatureRequest';
import { FinalizeSignatureResponse } from '../model/finalizeSignatureResponse';
import { NexuConfiguration } from '../model/nexuConfiguration';
import { SignatureInformationRequest } from '../model/signatureInformationRequest';
import { SignatureInformationResponse } from '../model/signatureInformationResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NexuSignatureService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get Nexu configuration request
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNexuConfig(observe?: 'body', reportProgress?: boolean): Observable<NexuConfiguration>;
    public getNexuConfig(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NexuConfiguration>>;
    public getNexuConfig(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NexuConfiguration>>;
    public getNexuConfig(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<NexuConfiguration>(`${this.basePath}/nexu/config`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Handle Nexu signature
     * 
     * @param request Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handleNexuSignature(request: SignatureInformationRequest, observe?: 'body', reportProgress?: boolean): Observable<SignatureInformationResponse>;
    public handleNexuSignature(request: SignatureInformationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignatureInformationResponse>>;
    public handleNexuSignature(request: SignatureInformationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignatureInformationResponse>>;
    public handleNexuSignature(request: SignatureInformationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling handleNexuSignature.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SignatureInformationResponse>(`${this.basePath}/nexu/sign`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Handle Nexu signature callback
     * 
     * @param businessId Flow ID
     * @param finalizeSignatureRequest Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handleNexuSignatureCallback(businessId: string, finalizeSignatureRequest: FinalizeSignatureRequest, observe?: 'body', reportProgress?: boolean): Observable<FinalizeSignatureResponse>;
    public handleNexuSignatureCallback(businessId: string, finalizeSignatureRequest: FinalizeSignatureRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FinalizeSignatureResponse>>;
    public handleNexuSignatureCallback(businessId: string, finalizeSignatureRequest: FinalizeSignatureRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FinalizeSignatureResponse>>;
    public handleNexuSignatureCallback(businessId: string, finalizeSignatureRequest: FinalizeSignatureRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling handleNexuSignatureCallback.');
        }

        if (finalizeSignatureRequest === null || finalizeSignatureRequest === undefined) {
            throw new Error('Required parameter finalizeSignatureRequest was null or undefined when calling handleNexuSignatureCallback.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<FinalizeSignatureResponse>(`${this.basePath}/nexu/sign/${encodeURIComponent(String(businessId))}`,
            finalizeSignatureRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
