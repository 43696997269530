import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';

import {FormModule} from '../shared/components/form/form.module';
import {PipesModule} from '../shared/pipes/pipes.module';
import {HeaderComponent} from './components/header/header.component';
import {ItsmeAuthLinkComponent} from './components/itsme-auth/itsme-auth-link/itsme-auth-link.component';
import {ItsmeAuthLoginComponent} from './components/itsme-auth/itsme-auth-login/itsme-auth-login.component';
import {ItsmeAuthSignupComponent} from './components/itsme-auth/itsme-auth-signup/itsme-auth-signup.component';
import {ItsmeAuthUnlinkComponent} from './components/itsme-auth/itsme-auth-unlink/itsme-auth-unlink.component';
import {ItsmeSignatureComponent} from './components/itsme-signature/itsme-signature.component';
import {ModalEventNotificationsComponent} from './components/notifications/modal-event-notifications.component';
import {CoreRoutingModule} from './core-routing.module';
import {FullScreenComponent} from './layouts/full-screen/full-screen.component';
import {FilterPipe} from './pipes/filter.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {TranslatePipe} from './pipes/translate.pipe';
import {DataService} from './service/data.service';
import {TranslateService} from './service/translate.service';

@NgModule({
  imports: [CommonModule, TranslateModule, FormsModule, NgbTooltipModule, ReactiveFormsModule, FormModule, CoreRoutingModule, PipesModule],
  declarations: [FullScreenComponent,
    HeaderComponent,
    ModalEventNotificationsComponent,
    ItsmeAuthLoginComponent,
    ItsmeAuthSignupComponent,
    ItsmeAuthLinkComponent,
    ItsmeAuthUnlinkComponent,
    ItsmeSignatureComponent,
    SafeHtmlPipe, FilterPipe, TranslatePipe],
  exports: [FullScreenComponent, HeaderComponent, TranslateModule, SafeHtmlPipe, FilterPipe, TranslatePipe],
  entryComponents: [ModalEventNotificationsComponent],
  providers: [TranslateService, DataService]
})
export class CoreModule {
}
