import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlaceholderService } from 'luxtrust-cosi-api/api/placeholder.service';
import { WatermarkService } from 'luxtrust-cosi-api/api/watermark.service';
import { AppService } from 'src/app/services/services/app.service';
import { DownloadService } from 'src/app/services/services/download.service';
import { IEService } from 'src/app/services/services/ie.service';
import {AlertService} from '../../../services/services/alert-service';
import { ModalAnnotationComponent } from '../modal-annotation/modal-annotation.component';
import { PdfPositionCapturerComponent } from '../pdf-viewer/pdf-position-capturer.component';

declare var DigitalSignature: any;

declare function require(path: string);

@Component({
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalPdfComponent extends PdfPositionCapturerComponent implements OnInit {

  @Input() file: Blob;
  @Input() fileName: string;
  filesAcroformsPositions; // comes from ModalPdfConsumerComponent
  @ViewChild('signature', {
    read: ElementRef, static: false
  }) signatureContainer: ElementRef;
  public slider: ElementRef;
  @ViewChild('slider', {
    read: ElementRef, static: false
  }) set content(content: ElementRef) {
    if (content) {
      this.slider = content;
    }
  }
  @ViewChild('sliderLabel', {
    read: ElementRef, static: false
  }) sliderLabel: ElementRef;

  signerNumber = 0;
  annotationEnabled = false;
  annotations: [{ x: number, y: number, page: number, idx: number, text: string, scale: number }];
  page;
  isIE = false;

  constructor(public appService: AppService,
              public activeModal: NgbActiveModal,
              public modal: NgbModal,
              public watermarkService: WatermarkService,
              public placeholderService: PlaceholderService,
              private downloadService: DownloadService,
              private readonly ieService: IEService,
              public alertService: AlertService
  ) {
    super(appService, modal, watermarkService, placeholderService, alertService);
  }

  static scrollSmoothFromModal(top: number, left: number) {
    document.getElementsByTagName('ngb-modal-window').item(0).scrollBy({
      top: top, left: left, behavior: 'smooth'
    });
  }

  ngOnInit() {
    this.isIE = this.ieService.checkIfIE();
    const fileReader: FileReader = new FileReader();
    fileReader.onload = () => {
      const config = {
        file: fileReader.result,
        onLoadPdf: (a) => {
          this.digitalSignatureLoaded(a, this.filesAcroformsPositions, this.fileName, this.page);
        },
        onComplete: (a) => this.onComplete(a),
        annotations: this.annotations ? this.groupByPageNumber(this.annotations) : {},
        annotationPicture: require('../../../../assets/img/chat-bubble.png')
      };
      DigitalSignature.build(this.signatureContainer.nativeElement, config);
    };
    fileReader.readAsArrayBuffer(this.file);
    this.signerNumber = this.signers.length + this.assignedSigners.length + this.assignedCircles.length;

  }

  onComplete(dataURL: any) {
    const blob = ModalPdfComponent.dataURLToBlob(dataURL);
    if (blob) {
      this.downloadService.download(blob, this.fileName);
    }
  }

  disableSignatureSetSlider(img: any) {
    const y = document.getElementsByClassName('temp-canvas').item(0).getBoundingClientRect().height / 2 - img.height / 2;
    ModalPdfComponent.scrollSmoothFromModal(y, 0);
  }

  disableSignatureResetSlider() {
    this.disableSignature = true;
    this.resetSlider();
    ModalPdfComponent.scrollSmoothFromModal(-10000000, 0);
  }

  onSliderChange($event) {
    this.sliderLabel.nativeElement.innerHTML = $event.target.value + ' %';
    this.digitalSignature.scaleTempImage($event.target.value);
  }

  resetSlider() {
    this.slider.nativeElement.value = 100;
    this.sliderLabel.nativeElement.innerHTML = '100 %';
  }

  reset() {
    this.resetSignature();
    this.disableSignature = true;
    this.resetSlider();
  }

  getImagesPositions() {
    return {
      positions: {
        positions: this.digitalSignature.getImagesPositions(), numberOfPages: this.digitalSignature.getTotalPages()
      }, annotations: this.annotations
    };
  }

  toggleAnnotation() {
    if (this.annotationEnabled) {
      this.digitalSignature.disableAnnotation();
    } else {
      this.digitalSignature.enableAnnotation();
    }
    this.annotationEnabled = !this.annotationEnabled;
  }

  @HostListener('window:message', ['$event']) annotationEventListener(event: MessageEvent) {
    const data = event.data;
    if (data) {
      const annotation = {
        x: data.annotation.x,
        y: data.annotation.y,
        page: data.annotation.page,
        idx: data.annotation.idx,
        text: this.annotations.some(annotation_ => data.annotation.idx === annotation_.idx) ? this.annotations.filter(
          annotation_ => data.annotation.idx === annotation_.idx)[0].text : '',
        scale: data.annotation.scale
      };
      const modalRef = this.modal.open(ModalAnnotationComponent, {
        size: 'lg', centered: true, backdrop: false
      });
      modalRef.componentInstance.text = annotation.text;
      modalRef.componentInstance.annotationId = annotation.idx;
      modalRef.result.then(result => {
        if ((!result && data.type === 'ANNOTATION_CREATED') || (result && result.action === 'ANNOTATION_DELETE')) {
          this.digitalSignature.deleteAnnotation(annotation.idx);
          this.annotations.splice(this.annotations.findIndex(a => annotation.idx === a.idx), 1);
        } else if (result) {
          annotation.text = result;
          if (data.type === 'ANNOTATION_CREATED') {
            this.annotations.push(annotation);
          } else {
            this.replaceAnnotationText(annotation.idx, annotation.text);
          }
        }
      });
    }
  }

  close() {
    return this.activeModal.close();
  }

  save() {
    return this.activeModal.close(this.getImagesPositions());
  }

  private groupByPageNumber(annotations: { x: number, y: number, page: number, idx: number, text: string }[]) {
    const annotationToDraw = {};
    const pageNumberToAnnotation = new Map<number, { x: number, y: number, page: number, idx: number, text: string }[]>();
    annotations.forEach(annotation => {
      if (pageNumberToAnnotation.has(annotation.page)) {
        const pageAnnotations = pageNumberToAnnotation.get(annotation.page);
        pageAnnotations.push(annotation);
        pageNumberToAnnotation.set(annotation.page, pageAnnotations);
      } else {
        pageNumberToAnnotation.set(annotation.page, [annotation]);
      }
    });
    pageNumberToAnnotation.forEach((annotation, page: number) => annotationToDraw[page] = annotation);
    return annotationToDraw;
  }

  private replaceAnnotationText(annotationId: number, text: string) {
    this.annotations.forEach(annotation => {
      if (annotation.idx === annotationId) {
        annotation.text = text;
      }
    });
  }
}
