<div *ngIf="currentUser">
  <div class="modal-header">
    <button *ngIf="!comingFromSession && !comingFromStep" (click)="openModalDeleteSession()" class="close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="flex-center text-center flex-column configurator">

    <div class="container-title-config">
      <h3 class="text-left flex-title" [ngClass]="{'align-title': comingFromSession || comingFromStep || configByStep.length === 1}"> {{'MODAL_TEMPLATE_SESSION.CONFIGURATOR.TITLE' | translate }} </h3>
      <div [ngClass]="{'config-step': !comingFromSession && !comingFromStep && configByStep.length > 1}">
        <mat-form-field *ngIf="!comingFromSession && !comingFromStep && !oneStepConfigurator" class="formFieldCustom formSelectStep text-center">
          <mat-select [ngModel]="step.id" (ngModelChange)="changePage($event)" [disabled]="hasErrorWithLinkTag">
            <mat-option *ngFor="let config of configByStep, let i = index" [value]="config.stepId"
            >{{config.stepName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <h5 tooltipClass="custom-tooltip" placement='right'[ngbTooltip]="step.label" class="labelStep pr-4"*ngIf="comingFromSession || comingFromStep || oneStepConfigurator">{{step.label| truncate: {max: 20} }}</h5>
      </div>
    </div>

    <div *ngIf="currentUser">
      <hr>
      <div *ngIf="canSeeSigners()">
        <h5 class="mb-2 text-left"> {{'WIZARD.CONFIG.USERS' | translate}}</h5>
        <div *ngIf="currentStepEndusers && stepTagList">
          <app-modal-configurator-signers
            [sessionId]="session.id"
            [step]="step"
            [signer]="this.currentUser.signer"
            [manager]="this.currentUser.manager"
            [configStep]="getConfig()"
            [stepEndusers]="currentStepEndusers"
            [stepTagList]="stepTagList"
            [page]="page"
            (sortMode)="detectSortMode($event)"
            (outputAddUser)="addUser($event)"
            (outputOrderSigner)="orderSigner()"
            (outputUpdateSigner)="updateCircleSigner($event)"
            (outputDeleteUser)="deleteUser($event)"
            (orderAfterReplace)="orderAfterReplace($event)"
          >
          </app-modal-configurator-signers>
        </div>
        <hr>
      </div>
      <div *ngIf="canSeeDocuments()">
        <h5 class="mb-2 text-left"> Documents </h5>
        <div *ngIf="currentStepEndusers && stepTagList">
          <app-modal-configurator-documents
            [session]="session"
            [step]="step"
            [signer]="this.currentUser.signer"
            [manager]="this.currentUser.manager"
            [configStep]="getConfig()"
            [stepEndusers]="currentStepEndusers"
            [stepTagList]="stepTagList"
            [oneStepConfigurator]="oneStepConfigurator"
          >
          </app-modal-configurator-documents>
        </div>
        <hr>
      </div>
      <div *ngIf="canSeeMetadatas(step.id) && step && session">
        <h5 class="mb-2 text-left" *ngIf="step.tags.length > 0"> {{'WIZARD.CONFIG.METADATA' | translate}}  </h5>
        <app-modal-configurator-metadatas
          [sessionId]="session.id"
          (controlValueTagMandatory)="controlValueTagMandatory($event)"
          (updateTags) = updateTags();
          (errorLinkTag)="checkErrorLinkTag($event)"
          [step]="step"
        >
        </app-modal-configurator-metadatas>
        <hr *ngIf="step.tags.length > 0">
      </div>

      <ng-container *ngIf="configuratorCreationMode">
        <span class="text-danger form-text" *ngIf="!hasVisitedAllStepWithAccess && page === configByStep.length - 1 && configByStep.length > 2">
          {{'WIZARD.CONFIG.MANDATORY_TAGS_CHECK_STEP' | translate: {stepNoVisited: listStepWithAccessNovisited } }}
        </span>
        <span class="text-danger form-text" *ngIf="page === configByStep.length - 1 && listNameStepWithError.length === 1">
          {{'WIZARD.CONFIG.MANDATORY_TAGS_STEP_ERROR' | translate: {stepWithError: listNameStepWithError } }}
        </span>
        <span class="text-danger form-text" *ngIf="page === configByStep.length - 1 && listNameStepWithError.length > 1">
          {{'WIZARD.CONFIG.MANDATORY_TAGS_STEPS_ERROR' | translate: {stepWithError: listNameStepWithError } }}
        </span>
      </ng-container>
      <span class="text-danger form-text" *ngIf="hasErrorWithLinkTag">
          {{'WIZARD.CONFIG.LINK_TAG_ERROR' | translate}}
        </span>

      <div class="navButton">
        <button (click)="previousStep()"
                *ngIf="!comingFromSession && !comingFromStep && page !== 0"
                [disabled]="hasErrorWithLinkTag || deletingSession"
                class="btn btn-primary btn-min-width float-left" type="submit" >
          {{'WIZARD.PREVIOUS_STEP' | translate}}
        </button>

        <div class="float-right">
          <button (click)="closeModal(false)"
                  *ngIf=" !comingFromSession && !comingFromStep && page === configByStep.length - 1"
                  [disabled]="hasErrorWithLinkTag || disabledSaveAndCreate || deletingSession"
                  class="btn btn-primary btn-min-width" type="submit">
            {{'WIZARD.SAVE' | translate}}
          </button>

          <div class="float-right">
            <button (click)="closeConfigurator()"
                    [disabled]="hasErrorWithLinkTag || tagMandatoryHasNoValue || deletingSession"
                    *ngIf=" comingFromSession || comingFromStep"
                    class="btn btn-primary btn-min-width" type="submit">
              {{'WIZARD.CLOSE' | translate}}
            </button>

            <button (click)="closeModal(true)"
                    id="start-button"
                    *ngIf="!comingFromSession && !comingFromStep &&page === configByStep.length - 1"
                    [disabled]="hasErrorWithLinkTag || disabledSaveAndCreate || tagMandatoryHasNoValue || !canStartSession() || deletingSession"
                    class="btn btn-primary btn-min-width btn-success btn-start" type="submit">
              <i class="fas fa-play"></i> {{'WIZARD.START' | translate}}
            </button>
          </div>

          <button (click)="nextStep()"
                  *ngIf="!comingFromSession && !comingFromStep && page !== configByStep.length-1 "
                  [disabled]="hasErrorWithLinkTag || deletingSession" class="btn btn-primary btn-min-width float-right"
                  type="submit">{{'WIZARD.NEXT_STEP' | translate}} </button>
        </div>
      </div>
    </div>
  </div>
</div>
