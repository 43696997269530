/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Validation code to create
 */
export interface CreateValidationCodePayload { 
    /**
     * Channel to use to send the validation code
     */
    validationCodeChannel?: CreateValidationCodePayload.ValidationCodeChannelEnum;
}
export namespace CreateValidationCodePayload {
    export type ValidationCodeChannelEnum = 'SMS' | 'EMAIL';
    export const ValidationCodeChannelEnum = {
        SMS: 'SMS' as ValidationCodeChannelEnum,
        EMAIL: 'EMAIL' as ValidationCodeChannelEnum
    };
}
