export const defaultConfig = {
  manager: {
    users: {
      replace: true,
      add: true,
      clone: true
    },
    documents: {
      replace: true,
      add: true,
      clone: true,
      sprofile: true
    },
    metadata: {
      update: true
    }
  },
  signer: {
    users: {
      replace: true,
      add: true,
      clone: true
    },
    documents: {
      replace: true,
      add: true,
      clone: true,
      sprofile: true
    },
    metadata: {
      update: true
    }
  }
};
