import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {PlaceholderService} from 'luxtrust-cosi-api/api/placeholder.service';
import {WatermarkService} from 'luxtrust-cosi-api/api/watermark.service';
import {AnnotationData} from 'luxtrust-cosi-api/model/annotationData';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {WorkflowData} from 'luxtrust-cosi-api/model/workflowData';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {PdfPositionCapturerComponent} from './pdf-position-capturer.component';
import TypologyEnum = DocumentData.TypologyEnum;

const annotationIcon = 'assets/img/chat-bubble.png';

declare var DigitalSignature: any;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PdfViewerComponent extends PdfPositionCapturerComponent implements OnInit, OnChanges {

  @Input() forbidDownload;
  @Input() forbidReUpload;
  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() workflows: WorkflowData[];
  @Input() document: DocumentData;
  @Input() annotations: AnnotationData[];
  @Input() askPosition = false;
  @Input() disabledAnnotation = false;
  @Input() disabledDownload = false;
  @Input() action = '';
  @Output() init = new EventEmitter();

  @Input()
  set fileToUpload(file: File) {
    if (file) {
      this.reuploadDocument(file);
    }
  }

  @Output() dataOnDocument = new EventEmitter<any>();
  @ViewChild('documentContainer', {
    read: ElementRef, static: false
  }) documentContainer: ElementRef;
  pdfBlob: Blob;
  isLoading = false;
  isRejectedOnce = false;

  // fixme: to be fixed by Intech, to be removed

  constructor(public appService: AppService,
              public modal: NgbModal,
              public watermarkService: WatermarkService,
              private documentService: DocumentService,
              public alertService: AlertService,
              public placeholderService: PlaceholderService
  ) {
    super(appService, modal, watermarkService, placeholderService, alertService);
  }

  static annotationPerPage(annotations) {
    const res = {};
    for (let i = 0; i < annotations; i++) {
      res[annotations[i].page] = res[annotations[i].page] || [];
      res[annotations[i].page].push(annotations[i]);
    }
    return Object.getOwnPropertyNames(res).map(k => res[k]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( (changes['document'] && !changes['document'].isFirstChange())
      || (changes['action'] && !changes['action'].isFirstChange())
    ) {
      if (!this.isLoading) {
        this.loadDocument();
      }
    }
  }

  ngOnInit(): void {
    this.loadDocument();
  }

  loadDocument() {
    if (this.document.typology !== TypologyEnum.SIGNATURE) {
      this.isLoading = true;
      this.documentService.downloadDocument(this.document.id, this.sessionId, this.stepId).toPromise().then((fileResource: Blob) => {
        const fileReader: FileReader = new FileReader();
        fileReader.onload = () => {
          const config = this.pdfConfig(fileReader.result);
          DigitalSignature.build(this.documentContainer.nativeElement, config);
        };
        fileReader.readAsArrayBuffer(fileResource);
        this.isLoading = false;
        this.pdfBlob = fileResource;
        this.dataOnDocument.emit({ blob: this.pdfBlob, document: this.document });
        this.init.emit(true);
      }, () => {
        this.isLoading = false;
        this.init.emit(true);
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    }
    this.isRejectedOnce = this.workflows ?
      this.workflows.some(workflow =>
        workflow.signatureStatus === WorkflowData.SignatureStatusEnum.DECLINED
        && <any> this.document.id === workflow.documentId
      ) : false;
    }

  pdfConfig(pdf) {
    const config = {
      file: pdf, onLoadPdf: (a) => this.digitalSignatureLoaded(a, undefined, undefined), clickedEnabled: false
    };
    if (!this.disabledAnnotation) {
      return {
        ...config,
        annotations: PdfViewerComponent.annotationPerPage(this.annotations),
        annotationReadOnly: true,
        annotationPicture: annotationIcon
      };
    }
    return config;
  }

  reuploadDocument(file: File) {
    this.documentService.reuploadDocument(this.document.id, this.sessionId, this.stepId, file)
      .toPromise().then(() => {
        this.alertService.success('DOCUMENT.REUPLOAD_SUCCESS');
        return this.documentService.getDocument(this.document.id, this.sessionId, this.stepId)
          .toPromise().then((document: DocumentData) => {
            this.document = document;
            this.loadDocument();
          });
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
  }

  disableSignatureSetSlider(img: any) {
  }

  disableSignatureResetSlider() {
    this.disableSignature = true;
  }
}
