<div class="sidebar-document-item">
  <div [ngClass]="{'active': isActive, 'alternate-color': alternateColor}" class="document">

    <!-- Document header container the name of the documents and comment priority annotation numbers -->
    <div class="document-header mb-3">
      <div class="document-title">
        <div class="item-grow ellipse">
          <span class="fa fa-file-o ml-3 mr-1"></span>
          <span class="ellipse"> {{documentName}} </span>
        </div>
        <div class="mr-4"></div>
        <div class="item-no-grow">
          <div class="text-nowrap icon-info">
            <!-- Priority icon -->
            <span *ngIf="priority > 0" ngbTooltip="{{'SIGNATURE_BOOK.PRIORITY' | translate}} {{priority}}">
              <ng-container [ngSwitch]="priority">
                <span *ngSwitchCase="0" class="far fa-star"></span>
                <span *ngSwitchCase="1" class="fas fa-star-half-alt"></span>
                <span *ngSwitchCase="2" class="fas fa-star"></span>
                <span *ngSwitchCase="3" class="fas fa-exclamation"></span>
              </ng-container>
            </span>
            <!-- Annotation icon -->
            <span *ngIf="numberAnnotation > 0"
                  ngbTooltip="{{'SIGNATURE_BOOK.ANNOTATION' | translate}} {{numberAnnotation}}">
              <span class="fa fa-comment"></span>
            </span>
            <!-- Comment icon -->
            <span *ngIf="numberComment > 0" (click)="goToComment()"
                  ngbTooltip="{{'SIGNATURE_BOOK.COMMENT' | translate}} {{numberComment}}">
              <span class="fa fa-align-left"></span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="document-content">
      <!-- Collapsable users list -->
      <div class="document-users mb-3">
        <div class="d-flex mb-2">
          <div class="item-grow">
            <span class="bold gray-dark"> {{'SIGNATURE_BOOK.USERS' | translate}} </span>
            <span *ngIf="isUserCountDisplayed" class="bold gray-dark"> ({{getActualDisplayedSignaturesNb}}/{{signatures.length}}) </span>
          </div>
        </div>

        <div class="separator"></div>

        <!-- Enduser info -->
        <!-- at last two first users-->
        <div class="pl-4">
          <div *ngFor="let signer of twoFirstSigner">
            <i *ngIf="signer.signatureStatus === SIGNATURE_STATUS.SIGNED" class="fas fa-check icon-signed"></i>
            <i *ngIf="signer.signatureStatus === SIGNATURE_STATUS.DECLINED" class="fas fa-times icon-declined"></i>
            <i *ngIf="signer.signatureStatus === SIGNATURE_STATUS.STARTED" class="fas fa-clock icon-started"></i>
            <span> {{'SIGNATURE_BOOK.SIGNATURE_STATUS.' + userSignatureStatus(
                signer) | translate}} </span>
            <span class="gray-light">{{
              (signer.stepEnduser.enduser.circle)
                  ? signer.stepEnduser.enduser.lastName.replace(circleRegexp, '')
                  : signer.stepEnduser.enduser.firstName + ' ' + signer.stepEnduser.enduser.lastName
              }}</span>
          </div>
        </div>

        <!-- the rest of the users-->
        <div *ngIf="usersExpanded" class="pl-4">
          <div *ngFor="let signer of offsetTowFirstSigner">
            <i *ngIf="signer.signatureStatus === SIGNATURE_STATUS.SIGNED" class="fas fa-check icon-signed"></i>
            <i *ngIf="signer.signatureStatus === SIGNATURE_STATUS.DECLINED" class="fas fa-times icon-declined"></i>
            <i *ngIf="signer.signatureStatus === SIGNATURE_STATUS.STARTED" class="fas fa-clock icon-started"></i>
            <span> {{'SIGNATURE_BOOK.SIGNATURE_STATUS.' + userSignatureStatus(
                signer) | translate}} </span>
            <span class="gray-light">{{
              (signer.stepEnduser.enduser.circle)
                  ? signer.stepEnduser.enduser.lastName.replace(circleRegexp, '')
                  : signer.stepEnduser.enduser.firstName + ' ' + signer.stepEnduser.enduser.lastName
              }}</span>
          </div>
        </div>

        <div (click)="usersExpanded = !usersExpanded; $event.stopPropagation();" class="d-flex mb-2">
          <div *ngIf="signatures.length > 2" class="item-no-grow text-center w-100">
            <span *ngIf="!usersExpanded" class="fas fa-angle-double-down w-100"></span>
            <span *ngIf="usersExpanded" class="fas fa-angle-double-up w-100 selected"></span>
          </div>
        </div>

        <div class="separator"></div>
      </div>

      <!-- Session and Step info -->
      <div class="session-step-info">
        <!-- Step name and collapser -->
        <div class="d-flex mb-2">
          <div class="item-grow ellipse" >
            <span class="bold gray-dark">{{'SIGNATURE_BOOK.SESSION_NAME' | translate}}</span>
            <span (click)="goToTheCurrentStep()" class="session-Link">
              {{sessionName}}
              <span *ngIf="isStepNameDiffSessionName">({{stepName}})</span>
            </span>

          </div>
        </div>
      </div>

      <div *ngIf="viewDocuments && viewDocuments.length > 0">
        <div class="item-grow">
          <span class="bold gray-dark"> {{'SIGNATURE_BOOK.ATTACHMENTS' | translate}} </span>
        </div>
        <div class="separator"></div>

        <div *ngFor="let viewDocument of viewDocuments; let index = index">
          <span>{{viewDocument.name | truncate:{max: 45} }}</span>
        </div>
      </div>


      <!-- Document info status and date -->
      <div class="document-info">
        <div class="item date date-right">
          <span class="bold gray-dark"> {{'SIGNATURE_BOOK.DATE' | translate}} </span><span
            class="gray-light">{{createdOn | date: 'short' : undefined : translateService.currentLang}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
