import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {throwError, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApiError} from '../error/api-error.model';
import {AlertService} from '../services/services/alert-service';

@Injectable()
export class SessionResolver implements Resolve<SessionData> {

  constructor(
    private alertService: AlertService,
    private sessionService: SessionService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SessionData> | Promise<SessionData> | SessionData {
    const sessionId: number = +route.params['sessionId'];
    return this.sessionService.getSession(sessionId)
      .pipe(catchError((error: ApiError) => {
        this.alertService.errorApi(error);
        return throwError(error);
      }));
  }
}
