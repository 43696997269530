<div *ngIf="appService.config?.showSpinner" [ngClass]="{
    'load': localStatus === STATUS.LOAD,
    'success': localStatus === STATUS.SUCCESS,
    'error': localStatus === STATUS.ERROR}" class="loader">
  <div class="loader-bars">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
  <span class="success-icon"><i class="fas fa-check"></i></span>
  <span class="error-icon"><i class="fas fa-times"></i></span>
</div>
