/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./text-truncate.component";
var styles_TextTruncateComponent = [];
var RenderType_TextTruncateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextTruncateComponent, data: {} });
export { RenderType_TextTruncateComponent as RenderType_TextTruncateComponent };
function View_TextTruncateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(0, i1.SlicePipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.label == null) ? null : _co.label.length) > _co.xsMaxCharacters) ? (i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.label, 0, _co.xsMaxCharacters)) + "...") : _co.label); _ck(_v, 1, 0, currVal_0); }); }
function View_TextTruncateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(0, i1.SlicePipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.label == null) ? null : _co.label.length) > _co.smMaxCharacters) ? (i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.label, 0, _co.smMaxCharacters)) + "...") : _co.label); _ck(_v, 1, 0, currVal_0); }); }
function View_TextTruncateComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(0, i1.SlicePipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.label == null) ? null : _co.label.length) > _co.mdMaxCharacters) ? (i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.label, 0, _co.mdMaxCharacters)) + "...") : _co.label); _ck(_v, 1, 0, currVal_0); }); }
function View_TextTruncateComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(0, i1.SlicePipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.label == null) ? null : _co.label.length) > _co.lgMaxCharacters) ? (i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.label, 0, _co.lgMaxCharacters)) + "...") : _co.label); _ck(_v, 1, 0, currVal_0); }); }
function View_TextTruncateComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(0, i1.SlicePipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.label == null) ? null : _co.label.length) > _co.xlMaxCharacters) ? (i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.label, 0, _co.xlMaxCharacters)) + "...") : _co.label); _ck(_v, 1, 0, currVal_0); }); }
function View_TextTruncateComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(0, i1.SlicePipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.label == null) ? null : _co.label.length) > _co.xxlMaxCharacters) ? (i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.label, 0, _co.xxlMaxCharacters)) + "...") : _co.label); _ck(_v, 1, 0, currVal_0); }); }
export function View_TextTruncateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextTruncateComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextTruncateComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextTruncateComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextTruncateComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextTruncateComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextTruncateComponent_6)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.screenSize < _co.XS_BREAKPOINT); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.XS_BREAKPOINT <= _co.screenSize) && (_co.screenSize < _co.SM_BREAKPOINT)); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.SM_BREAKPOINT <= _co.screenSize) && (_co.screenSize < _co.MD_BREAKPOINT)); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.MD_BREAKPOINT <= _co.screenSize) && (_co.screenSize < _co.LG_BREAKPOINT)); _ck(_v, 7, 0, currVal_3); var currVal_4 = ((_co.LG_BREAKPOINT <= _co.screenSize) && (_co.screenSize < _co.XL_BREAKPOINT)); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.XL_BREAKPOINT <= _co.screenSize); _ck(_v, 11, 0, currVal_5); }, null); }
export function View_TextTruncateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lt-text-truncate", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TextTruncateComponent_0, RenderType_TextTruncateComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextTruncateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextTruncateComponentNgFactory = i0.ɵccf("lt-text-truncate", i2.TextTruncateComponent, View_TextTruncateComponent_Host_0, { label: "label", xsMaxCharacters: "xsMaxCharacters", smMaxCharacters: "smMaxCharacters", mdMaxCharacters: "mdMaxCharacters", lgMaxCharacters: "lgMaxCharacters", xlMaxCharacters: "xlMaxCharacters", xxlMaxCharacters: "xxlMaxCharacters" }, {}, []);
export { TextTruncateComponentNgFactory as TextTruncateComponentNgFactory };
