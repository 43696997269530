import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {RetrieveAuditTrailFromDocumentApiControllerService, SessionService} from 'luxtrust-cosi-api';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {TagService} from 'luxtrust-cosi-api/api/tag.service';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SessionFilter} from 'luxtrust-cosi-api/model/sessionFilter';
import {SortingFilter} from 'luxtrust-cosi-api/model/sortingFilter';
import {debounceTime} from 'rxjs/operators';
import {ApiError} from 'src/app/error/api-error.model';
import {EnumHelper} from 'src/app/services/enum/enum.helper';
import {PriorityEnum} from 'src/app/services/enum/priority.enum';
import {AlertService} from 'src/app/services/services/alert-service';
import {AppService} from 'src/app/services/services/app.service';
import {IEService} from 'src/app/services/services/ie.service';
import {Status} from 'src/app/shared/components/input-loader/input-loader.component';
import {AlertType} from "../../error/alert.model";
import {DownloadService} from "../../services/services/download.service";

@Component({
  selector: 'session-search', templateUrl: './search.component.html', styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {
  private static readonly ONE_DAY_MILLIS = 24 * 60 * 60 * 1000;

  @Input() sessionFilter: SessionFilter;
  @Input() searchSession = false;
  @Output() filterChangedEvent = new EventEmitter<SessionFilter>();
  @Output() templateChanged = new EventEmitter<boolean>();
  @Output() listSessionChanged = new EventEmitter<boolean>();
  @ViewChild('importTemplate', {read: ElementRef, static: false}) importTemplate;
  @ViewChild('retrieveAuditTrails', {read: ElementRef, static: false}) retrieveAuditTrails;
  searchForm: FormGroup;
  advanceSearch = false;
  template = false;
  createdBefore: string;
  createdAfter: string;
  availableUsers: EnduserData[];
  selectedUser: EnduserData;
  createdBeforeMax: NgbDateStruct = null;
  createdAfterMax: NgbDateStruct = null;

  statusProvider: { key: string, value: SessionFilter.StatusEnum }[];
  priorityProvider: { key: string, value: number }[];
  tagProvider: { key: string, value: number }[] = [];
  statusSignatureProvider: { key: string, value: SessionFilter.SignatureStatusEnum }[];
  statusUsers = Status.SUCCESS;
  submitting = false;

  isIE = false;

  constructor(public appService: AppService,
              private translate: TranslateService,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private tagService: TagService,
              private sessionService: SessionService,
              private enduserService: EnduserService,
              private retrieveAuditTrailFromDocumentApiControllerService: RetrieveAuditTrailFromDocumentApiControllerService,
              private downloadService: DownloadService,
              private ieService: IEService) {
  }

  ngOnInit() {
    this.isIE = this.ieService.checkIfIE();
    if (this.isIE) {
      this.adaptEntriesIE();
    }
    this.createProviders();
    this.searchForm = this.formBuilder.group({
      searchInput: [''], signatureStatus: [''], sessionStatus: [''], priority: [''], documentName: [''], stepMeta: [''], stepMetaValue: ['']
    });
    this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe(values => {
      this.sessionFilter.text = values.searchInput;
      this.sessionFilter.documentName = values.documentName;
      values.signatureStatus ? this.sessionFilter.signatureStatus = values.signatureStatus.value : null;
      values.sessionStatus ? this.sessionFilter.status = values.sessionStatus.value : null;
      values.priority ? this.sessionFilter.priority = values.priority.value : null;
      if (values.stepMeta && values.stepMetaValue) {
        this.sessionFilter.tagId = values.stepMeta.value;
        this.sessionFilter.tagValue = values.stepMetaValue;
      } else {
        this.sessionFilter.tagId = null;
        this.sessionFilter.tagValue = null;
      }

      this.filterChangedEvent.emit(this.sessionFilter);
    });
    this.recalculateDatesRestrictions();
    if (sessionStorage.getItem('stateTemplateDashboard')) {
      if (sessionStorage.getItem('stateTemplateDashboard') === 'true') {
        this.template = true;
        this.disableSearchForm();
      } else {
        this.enableSearchForm();
      }
    }
  }

  ngOnChanges(){
    if(this.searchSession && this.template) {
      this.template = false;
      this.onTemplateChanged();
      this.searchSession = false;
    }
  }

  onSearchUsers(searchEnduser) {
    if (searchEnduser && searchEnduser.trim()) {
      this.enduserService.searchEnduser(undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        searchEnduser).subscribe(endusers => {
          this.availableUsers = endusers;
        }, (error: ApiError) => this.alertService.errorApi(error));
    } else {
      this.availableUsers = [];
    }
  }

  addUsers(user: EnduserData) {
    this.enduserService.getAdminEnduser(user.id).toPromise().then(enduser => {
      this.selectedUser = enduser;
      this.sessionFilter.enduserIds = [enduser.id];
      this.filterChangedEvent.emit(this.sessionFilter);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  adaptEntriesIE() {
    if (!Object.entries) {
      Object.entries = (obj) => {
        const ownProps = Object.keys(obj);
        let i = ownProps.length;
        const resArray = new Array(i); // preallocate the Array
        while (i--) {
          resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }
        return resArray;
      };
    }
  }

  createProviders() {
    setTimeout(() => {
      this.translate.get('DASHBOARD.SEARCH.STATUS.ITEM').subscribe(() => {
        this.statusProvider = Object.entries(SessionFilter.StatusEnum)
          .map(([key, value]) => ({
            key: this.translate.instant('DASHBOARD.SEARCH.STATUS.ITEM.' + key), value
          }));
        this.statusProvider = [{
          key: this.translate.instant('DASHBOARD.SEARCH.STATUS.ITEM.ALL'),
          value: undefined
        }, ...this.statusProvider];

        this.statusSignatureProvider = Object.entries(SessionFilter.SignatureStatusEnum)
          .map(([key, value]) => ({
            key: this.translate.instant('DASHBOARD.SEARCH.STATUS.ITEM.' + key), value
          }));
        this.statusSignatureProvider = [{
          key: this.translate.instant('DASHBOARD.SEARCH.STATUS.ITEM.ALL'),
          value: undefined
        }, ...this.statusSignatureProvider];

        this.priorityProvider = EnumHelper.toKeyValueArray(PriorityEnum)
          .map(v => ({
            key: this.translate.instant('DASHBOARD.SEARCH.PRIORITY.ITEM.' + v.key), value: v.value
          }));
        this.priorityProvider = [{
          key: this.translate.instant('DASHBOARD.SEARCH.PRIORITY.ITEM.ALL'),
          value: undefined
        }, ...this.priorityProvider];

        this.tagService.getTags('body').subscribe(tags => {
          this.tagProvider = tags.map(tag => {
            return {
              key: tag.alias, value: tag.id
            };
          });
          this.tagProvider = [{
            key: this.translate.instant('DASHBOARD.SEARCH.PRIORITY.ITEM.ALL'),
            value: undefined}, ...this.tagProvider];
        }, (error: ApiError) => this.alertService.errorApi(error));
      });
    }, 500);
  }

  beforeDate() {
    setTimeout(() => {
      if (this.createdBefore) {
        this.sessionFilter.before = new Date(this.createdBefore);
        this.filterChangedEvent.emit(this.sessionFilter);
      } else {
        this.sessionFilter.before = undefined;
        this.filterChangedEvent.emit(this.sessionFilter);
      }
      this.recalculateDatesRestrictions();
    });
  }

  afterDate() {
    setTimeout(() => {
      if (this.createdAfter) {
        this.sessionFilter.after = new Date(this.createdAfter);
        this.filterChangedEvent.emit(this.sessionFilter);
      } else {
        this.sessionFilter.after = undefined;
        this.filterChangedEvent.emit(this.sessionFilter);
      }
      this.recalculateDatesRestrictions();
    });
  }

  onTemplateChanged() {
    this.templateChanged.emit(this.template);
    if (this.template) {
      this.disableSearchForm();
    } else {
      this.enableSearchForm();
    }
    const stateTemplate = this.template.toString();
    sessionStorage.setItem('stateTemplateDashboard', stateTemplate);
    this.searchForm.controls['searchInput'].setValue('');
  }

  disableSearchForm() {
    this.searchForm.disable();
    this.searchForm.controls.searchInput.enable();
  }

  enableSearchForm() {
    this.searchForm.enable();
    if (((this.searchForm.controls.stepMeta && this.searchForm.controls.stepMeta.value) !== undefined)
      && (this.searchForm.controls.stepMeta.value && !this.searchForm.controls.stepMeta.value.value)) {
      this.searchForm.controls.stepMetaValue.disable();
    }
  }

  toggleAdvancedSearch() {
    this.advanceSearch = !this.advanceSearch;
    if (!this.advanceSearch) {
      this.searchForm.reset();
      this.createdAfter = undefined;
      this.createdBefore = undefined;
      this.sessionFilter = <SessionFilter>{
        text: '', sortingFilters: [<SortingFilter>{
          field: 'CREATED', asc: false
        }]
      };
    }
  }

  toDateStruct(val): NgbDateStruct {
    return {
      year: new Date(val).getFullYear(), month: new Date(val).getMonth() + 1, day: new Date(val).getDate()
    };
  }

  private recalculateDatesRestrictions() {
    const today = new Date();
    const filterBeforeDate = this.createdBefore ? new Date(this.createdBefore) : null;

    if (!this.createdBeforeMax) {
      this.createdBeforeMax = this.toDateStruct(today);
      this.createdBeforeMax ? this.createdBeforeMax.day += 1 : null;
    }

    if (filterBeforeDate && filterBeforeDate.getTime() < (today.getTime() - SearchComponent.ONE_DAY_MILLIS)) {
      this.createdAfterMax = this.toDateStruct(filterBeforeDate);
      this.createdAfterMax.day -= 1;
    } else {
      this.createdAfterMax = this.toDateStruct(today);
      this.createdAfterMax ? this.createdAfterMax.day -= 1 : null;
    }
  }

  doImport(files: FileList) {
    if (files && files[0]) {
      this.submitting = true;
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl: string = <any>reader.result;
        const base64 = dataUrl.split(',')[1];
        try {
          const data = JSON.parse(this.b64DecodeUnicode(base64));
          this.sessionService.sessionImport(data).toPromise().then(() => {
            this.listSessionChanged.emit();
            this.alertService.success('DASHBOARD.SEARCH.UPLOAD_TEMPLATE');
            this.submitting = false;
          }).catch((error: ApiError) => {
            this.alertService.errorApi(error);
            this.submitting = false;
          });
        } catch (error) {
          this.submitting = false;
          this.alertService.error('IMPORT_ERROR');
        }
      };
      reader.readAsDataURL(files[0]);
    }
    this.importTemplate.nativeElement.value = '';
  }

  // Following https://stackoverflow.com/a/30106551/1448419
  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  doRetrieveAuditTrails(files: any) {
    if (files && files[0]) {
      this.retrieveAuditTrailFromDocumentApiControllerService.retrieveAuditTrailFromDocument(files[0]).subscribe(data => {
        this.downloadService.download(data, 'audit-trails.zip', 'application/zip');
      }, (error: ApiError) => {
        if(error.status === 404){
          this.alertService.alert(AlertType.WARNING, this.translate.instant("ALERT.NO_AUDIT_TRAIL_FOR_THAT_FILE"));
        }
        else{
          this.alertService.errorApi(error);
        }
      })
    }
    this.retrieveAuditTrails.nativeElement.value = '';
  }
}
