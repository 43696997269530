import {BehaviorSubject, Observable} from 'rxjs';

/**
 * TODO: This class should be on the core module (case it's global stuff) but due to the bad design of the app the class  is locate here.
 */

export abstract class Store<T> {
  private state$: BehaviorSubject<T> = new BehaviorSubject(undefined);

  getAll = (): T => this.state$.getValue();

  getAll$ = (): Observable<T> => this.state$.asObservable();

  store = (nextState: T) => this.state$.next(nextState);
}
