import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {StepPropertiesService} from 'luxtrust-cosi-api/api/stepProperties.service';
import {StepRemindersService} from 'luxtrust-cosi-api/api/stepReminders.service';
import {CreateReminderPayload} from 'luxtrust-cosi-api/model/createReminderPayload';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {ReminderData} from 'luxtrust-cosi-api/model/reminderData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {StepPropertyData} from 'luxtrust-cosi-api/model/stepPropertyData';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';
import {DateTimeModel} from '../../../../shared/components/date-time-picker/date-time.model';
import {StepActions} from '../../wizard/models/wizard.config';

@Component({
  templateUrl: './wizard-step-reminders.component.html', styleUrls: ['./wizard-step-reminders.component.scss']
})
export class WizardStepRemindersComponent implements OnInit, OnDestroy {

  session: SessionData;
  step: StepData;
  stepActions = StepActions;
  subscription: Subscription;

  properties: StepPropertyData;
  reminders: ReminderData[] = [];
  reminderOnceDate: string;
  signerIds = {};
  frequency: string;
  start: string;
  signers: EnduserData[] = [];
  defaultDateTime: DateTimeModel;
  addingReminder = false;
  deletingReminder = false;
  deletingAllReminder = false;

  constructor(private route: ActivatedRoute,
              private stepRemindersService: StepRemindersService,
              private stepEnduserService: StepEnduserService,
              private alertService: AlertService,
              private stepPropertiesService: StepPropertiesService) {
  }

  ngOnInit() {
    this.subscription = this.route.parent.data.pipe().subscribe((data: Data) => {
      this.session = data['session'];
      this.step = data['step'];

      this.stepEnduserService.stepEnduserList(this.session.id, this.step.id).pipe(take(1))
        .subscribe((stepEndusers: StepEnduserData[]) => {
          this.signers = stepEndusers.filter(e => e.signer).map(e => e.enduser);
          this.signers.forEach(signer => this.signerIds[signer.id] = signer);
        }, (error: ApiError) => this.alertService.errorApi(error));
      this.stepPropertiesService.getStepProperties(this.session.id, this.step.id).pipe(take(1)).subscribe((props: StepPropertyData) => {
        this.properties = props;
      }, (error: ApiError) => this.alertService.errorApi(error));
      this.reloadReminders();
    });
    this.defaultDateTime = DateTimeModel.fromLocalString(new Date((new Date().getTime() + 60 * 60 * 1000)).toLocaleString());
  }

  addReminder() {
    const reminderDate = new Date(<any>this.reminderOnceDate);
    this._addReminder((id) =>  ({
      remindOn: reminderDate, enduserId: id
    }));
  }

  addRecurrentReminder() {
    const distanceMillis = parseInt(this.frequency, 10) * 60 * 60 * 1000;
    const remindStartOn = new Date(parseInt(this.start, 10) * 24 * 60 * 60 * 1000);
    this._addReminder((id) =>  ({
      distanceMillis: distanceMillis, remindStartFrom: remindStartOn, enduserId: id
    }));
  }

  private _addReminder(payloadFunction: (id: string) => CreateReminderPayload) {
    this.addingReminder = true;
    const signers = Object.getOwnPropertyNames(this.signerIds).filter(id => this.signerIds[id]);
    Promise.all(
      signers.map(id => {
        return this.stepRemindersService.updateStepReminders(this.session.id, this.step.id, payloadFunction(id)).toPromise()
          .catch((apiError: ApiError) => {
            this.addingReminder=false;
            this.alertService.errorApi(apiError);
          });
      })
    ).then(() => {
      this.addingReminder=false;
      this.reloadReminders();
    });
  }

  deleteReminder(reminder: ReminderData) {
    this.deletingReminder = true;
    this.stepRemindersService.deleteStepReminder(+reminder.enduserId, new Date(reminder.sendOn).toISOString(), this.session.id, this.step.id).pipe(take(1))
      .subscribe(() => {
        this.deletingReminder = false;
        this.reloadReminders();
      }, (error: ApiError) => {
        this.deletingReminder = false;
        this.alertService.errorApi(error);
      });
  }

  deleteAllReminders() {
    this.deletingAllReminder = true;
    this.stepRemindersService.deleteAllStepReminder(this.session.id, this.step.id).pipe(take(1))
      .subscribe(() => {
        this.deletingAllReminder = false;
        this.reloadReminders();
      }, (error: ApiError) => {
        this.deletingAllReminder = false;
        this.alertService.errorApi(error);
      });
  }

  reloadReminders() {
    this.stepRemindersService.getStepReminders(this.session.id, this.step.id).pipe(take(1))
      .subscribe((rems: ReminderData[]) => {
        this.reminders = rems;
        this.frequency = undefined;
        this.start = undefined;
        this.reminders.forEach(rem => {
          const date = +new Date(rem.sendOn);
          (<any>rem).color = [255, 238, 204];
          (<any>rem).startingDate = new Date(rem.sendOn).getFullYear() === 1970;
          (<any>rem).day = Math.ceil(date / 24 / 60 / 60 / 1000) - 1;
          (<any>rem).hour = this.getHour(rem.sendOn);
        });
      }, (error: ApiError) => this.alertService.errorApi(error));
  }

  getSignerName(enduserId: any) {
    const signer = this.signers.find(s => s.id === enduserId);
    return signer ? (signer.firstName && (signer.firstName + ' ' + signer.lastName) || signer.lastName) : enduserId;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  noSigner() {
    return Object.getOwnPropertyNames(this.signerIds).length === 0;
  }

  getHour(date): string {
    return '& ' + date.substring(11, 16);
  }
}
