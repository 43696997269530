/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Auth code payload
 */
export interface AuthCodePayload { 
    /**
     * Code channel
     */
    channel?: AuthCodePayload.ChannelEnum;
    /**
     * Auth code
     */
    code?: string;
    /**
     * Directory alias
     */
    directoryAlias?: string;
    /**
     * Password
     */
    password?: string;
    /**
     * Tenant name
     */
    tenantName?: string;
    /**
     * Username
     */
    username?: string;
}
export namespace AuthCodePayload {
    export type ChannelEnum = 'SMS' | 'EMAIL';
    export const ChannelEnum = {
        SMS: 'SMS' as ChannelEnum,
        EMAIL: 'EMAIL' as ChannelEnum
    };
}
