/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../text-truncate/text-truncate.component.ngfactory";
import * as i2 from "../text-truncate/text-truncate.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "../../../core/pipes/translate.pipe";
import * as i6 from "../../../core/service/translate.service";
import * as i7 from "./annotation-viewer.component";
var styles_AnnotationViewerComponent = [];
var RenderType_AnnotationViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AnnotationViewerComponent, data: {} });
export { RenderType_AnnotationViewerComponent as RenderType_AnnotationViewerComponent };
function View_AnnotationViewerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "dropdown-item text-truncate"], ["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAnnotationFromDropDown(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "lt-text-truncate", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TextTruncateComponent_0, i1.RenderType_TextTruncateComponent)), i0.ɵdid(2, 114688, null, 0, i2.TextTruncateComponent, [], { label: [0, "label"], xsMaxCharacters: [1, "xsMaxCharacters"], smMaxCharacters: [2, "smMaxCharacters"], mdMaxCharacters: [3, "mdMaxCharacters"], lgMaxCharacters: [4, "lgMaxCharacters"], xlMaxCharacters: [5, "xlMaxCharacters"] }, null), (_l()(), i0.ɵted(3, null, [" (Page ", ") "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.text; var currVal_1 = _co.maxAnnotationCharacter; var currVal_2 = _co.maxAnnotationCharacter; var currVal_3 = _co.maxAnnotationCharacter; var currVal_4 = _co.maxAnnotationCharacter; var currVal_5 = _co.maxAnnotationCharacter; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_6 = _v.context.$implicit.page; _ck(_v, 3, 0, currVal_6); }); }
function View_AnnotationViewerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "d-inline-block"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(1, 737280, null, 3, i3.NgbDropdown, [i0.ChangeDetectorRef, i3.NgbDropdownConfig, i4.DOCUMENT, i0.NgZone, i0.ElementRef, i0.Renderer2, [2, i3.ɵm]], null, null), i0.ɵqud(335544320, 1, { _menu: 0 }), i0.ɵqud(335544320, 2, { _menuElement: 0 }), i0.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 5, "button", [["aria-haspopup", "true"], ["class", "btn btn-primary dropdown-toggle"], ["id", "dropDownAnnotation"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i0.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i0.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[3, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-comment"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), i0.ɵpid(131072, i5.TranslatePipe, [i0.ChangeDetectorRef, i6.TranslateService]), (_l()(), i0.ɵeld(11, 0, [[2, 0]], null, 4, "div", [["aria-labelledby", "dropDownAnnotation"], ["class", "dropdown-menu"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 16384, [[1, 4]], 1, i3.NgbDropdownMenu, [i3.NgbDropdown], null, null), i0.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AnnotationViewerComponent_2)), i0.ɵdid(15, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_6 = _co.annotations; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("DOCUMENT.SEE_ANNOTATION")); _ck(_v, 9, 0, currVal_2); var currVal_3 = true; var currVal_4 = i0.ɵnov(_v, 12).dropdown.isOpen(); var currVal_5 = i0.ɵnov(_v, 12).placement; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_AnnotationViewerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AnnotationViewerComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.annotations && (_co.annotations.length !== 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AnnotationViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-annotation-viewer", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).annotationEventListener($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AnnotationViewerComponent_0, RenderType_AnnotationViewerComponent)), i0.ɵdid(1, 114688, null, 0, i7.AnnotationViewerComponent, [i3.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnnotationViewerComponentNgFactory = i0.ɵccf("app-annotation-viewer", i7.AnnotationViewerComponent, View_AnnotationViewerComponent_Host_0, { currentPage: "currentPage", annotations: "annotations" }, { loadPage: "loadPage" }, []);
export { AnnotationViewerComponentNgFactory as AnnotationViewerComponentNgFactory };
