import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import {REGEXES} from '../../app.constant';

export class ValidatorHelper {

  static matchPassword(formGroup: FormGroup) {
    const password = formGroup.get('password').value; // to get value in input tag
    const confirmPassword = formGroup.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      formGroup.get('confirmPassword').setErrors({matchPassword: true});
    }
  }

  static passwordIsValid(formControl: FormControl) {
    const MIN_LENGTH = 6;
    const value = formControl.value;
    if (!value || value.length === 0) {
      return undefined;
    }
    const errors = {};
    if (value.length < MIN_LENGTH) {
      errors['minlength'] = true;
    }
    if (!REGEXES.PASSWORD.SPECIAL_CHARS.test(value)) {
      errors['noSpecialChar'] = true;
    }
    if (!REGEXES.PASSWORD.ALPHA_CASED_CHARS.test(value)) {
      errors['noAlphabeticChar'] = true;
    }
    if (!REGEXES.PASSWORD.NUMERIC_CHARS.test(value)) {
      errors['noNumberChar'] = true;
    }
    return errors;
  }

  static fileTypeAcceptedValidator(fileTypeAccepted: string[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const isFileWithWrongType = Object.values<File>(control.value).some(file => !fileTypeAccepted.some(fta => fta === file.type));
      return control.value && isFileWithWrongType ? {supportedFormats: true} : undefined;
    };
  }

  static fileSizeLimitValidator(fileSizeLimit: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const fileExceededLimit: File = Object.values<File>(control.value).find((file: File) => file.size > fileSizeLimit);
      return control.value && fileExceededLimit ? {sizeLimit: {filename: fileExceededLimit.name}} : undefined;
    };
  }

  static delegating(validate: (control: AbstractControl) => boolean, errorKey: string): ValidatorFn {
    return control => {
      if (validate(control)) {
        const errors: ValidationErrors = {};
        errors[errorKey] = true;
        return errors;
      }
      return undefined;
    };
  }

  static noWhitespace(formControl: FormControl) {
    const isValid = (formControl.value || '').trim().length !== 0;
    return isValid ? undefined : { 'whitespace': true };
  }

}
