<div class="modal-header">
  <h4 class="modal-title">{{'MODAL_VISA.TITLE' | translate}}</h4>
  <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  {{'MODAL_VISA.BODY' | translate}}
</div>

<div class="modal-footer">
  <button (click)="applyVisa()" [disabled]="submitted" class="btn btn-primary"
          type="submit">{{'MODAL_VISA.YES' | translate}}</button>
  <button (click)="activeModal.dismiss()" class="btn btn-outline-secondary"
          type="button">{{'MODAL_VISA.NO' | translate}}</button>
</div>
