import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './modal-annotation.component.html'
})
export class ModalAnnotationComponent {

  text: string;
  readOnly = false;
  annotationId: number;
  showTitle = true;
  showDescription = true;

  constructor(public activeModal: NgbActiveModal) {
  }

  deleteAnnotation() {
    this.activeModal.close({
      action: 'ANNOTATION_DELETE'
    });
  }
}
