/**
 * Utils class handling ID generation.
 */
export class Ids {

  static generateId() {
    return 'id_' + Math.random().toString(36).substr(2, 16);
  }

}
