import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {

  @Input() switchValue: string = '';
  @Input() disabled: boolean = false;
  @Input() canBeNull: boolean = false;
  @Output() onChange = new EventEmitter<string>();

  constructor() { }

  setValue(value) {
    if(!this.disabled) {
      this.switchValue = value;
      this.onChange.emit(value);
    }
  }
}
