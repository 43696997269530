/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./input-loader.component";
import * as i4 from "../../../services/services/app.service";
var styles_InputLoaderComponent = [i0.styles];
var RenderType_InputLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputLoaderComponent, data: {} });
export { RenderType_InputLoaderComponent as RenderType_InputLoaderComponent };
function View_InputLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "loader"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "load": 0, "success": 1, "error": 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "loader-bars"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "inner one"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "inner two"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "inner three"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "success-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fas fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "error-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "loader"; var currVal_1 = _ck(_v, 3, 0, (_co.localStatus === _co.STATUS.LOAD), (_co.localStatus === _co.STATUS.SUCCESS), (_co.localStatus === _co.STATUS.ERROR)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_InputLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.appService.config == null) ? null : _co.appService.config.showSpinner); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InputLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input-loader", [], null, null, null, View_InputLoaderComponent_0, RenderType_InputLoaderComponent)), i1.ɵdid(1, 573440, null, 0, i3.InputLoaderComponent, [i4.AppService], null, null)], null, null); }
var InputLoaderComponentNgFactory = i1.ɵccf("app-input-loader", i3.InputLoaderComponent, View_InputLoaderComponent_Host_0, { status: "status" }, {}, []);
export { InputLoaderComponentNgFactory as InputLoaderComponentNgFactory };
