/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./switcher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./switcher.component";
var styles_SwitcherComponent = [i0.styles];
var RenderType_SwitcherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SwitcherComponent, data: {} });
export { RenderType_SwitcherComponent as RenderType_SwitcherComponent };
function View_SwitcherComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFirstError(); _ck(_v, 1, 0, currVal_0); }); }
function View_SwitcherComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [["class", "form-text text-muted"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "Hint"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.hint; _ck(_v, 1, 0, currVal_1); }); }
function View_SwitcherComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SwitcherComponent_3)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hint; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SwitcherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "switcher switcher-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["input", 1]], null, 0, "input", [["class", "switcher-checkbox"], ["type", "checkbox"]], [[1, "aria-describedby", 0], [8, "checked", 0], [8, "disabled", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onSwitch($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "switcher-toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SwitcherComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showHint", 2]], null, 0, null, View_SwitcherComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.ngControl.touched && _co.ngControl.invalid); var currVal_7 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "Hint"); var currVal_1 = ((_co.ngControl.value === true) ? "checked" : null); var currVal_2 = _co.disabled; var currVal_3 = _co.id; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.id; _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.label; _ck(_v, 5, 0, currVal_5); }); }
export function View_SwitcherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-switcher", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_SwitcherComponent_0, RenderType_SwitcherComponent)), i1.ɵdid(1, 49152, null, 0, i3.SwitcherComponent, [[8, null]], null, null)], null, null); }
var SwitcherComponentNgFactory = i1.ɵccf("app-switcher", i3.SwitcherComponent, View_SwitcherComponent_Host_0, { id: "id", label: "label", placeholder: "placeholder", icon: "icon", hint: "hint", disabled: "disabled", errors: "errors", navigateOnClick: "navigateOnClick", value: "value" }, {}, []);
export { SwitcherComponentNgFactory as SwitcherComponentNgFactory };
