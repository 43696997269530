import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export enum ItsmeAuthServiceType {
  LOGIN = 'LOGIN', SIGN_UP = 'SIGN_UP', LINK = 'LINK', UNLINK = 'UNLINK'
}

export enum ItsmeServiceSignatureEnum {
  IDENTIFICATION = 'IDENTIFICATION', SIGN = 'SIGN'
}

@Injectable()
export class ItsmeService {

  private readonly ITSME_PATH = '/itsme';

  constructor(private router: Router) {
  }

  /**
   * Returns the URL used to redirect to itsme page
   *
   * @param service service to use
   */
  getRedirectUrlForAuth(service: ItsmeAuthServiceType): string {
    if (service === null || service === undefined) {
      throw new Error('Required parameter service was null or undefined when calling getRedirectUrlForAuth.');
    } else {
      const baseUrl = window.location.href.replace(this.router.url, '') + this.ITSME_PATH;
      switch (service) {
        case ItsmeAuthServiceType.LOGIN:
          return baseUrl + '/auth';
        case ItsmeAuthServiceType.SIGN_UP:
          return baseUrl + '/signup';
        case ItsmeAuthServiceType.LINK:
          return baseUrl + '/link';
        case ItsmeAuthServiceType.UNLINK:
          return baseUrl + '/unlink';
        default:
          return undefined;
      }
    }
  }

  /**
   * Returns the URL used to redirect to itsme page
   *
   */
  getRedirectUrlForSignature(service: ItsmeServiceSignatureEnum): string {
    return window.location.href.replace(this.router.url, '') + '/itsme/sign?service=' + service;
  }

}
