<app-tooltip [tooltipText]="'HELP.STEP_RESUME' | translate"></app-tooltip>

<div class="container">
  <div class="step-general-actions">
    <nav aria-label="breadcrumb"
         class="mt-4">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [queryParamsHandling]="'preserve'"
             [routerLink]="(comToParapheur ? ['/signature-book'] : ['/session', session?.id || 0])"
             [title]="comToParapheur ? ('DASHBOARD.SIGNATURE_BOOK' | translate) : (session | sessionTitle)"><i
            aria-hidden="true"
            class="fa fa-code-fork mr-1"></i><i
            class="fab fa-usb mr-2"></i>
            <lt-text-truncate [label]="comToParapheur ? ('DASHBOARD.SIGNATURE_BOOK' | translate) : (session | sessionTitle)"
                              [lgMaxCharacters]="25"
                              [mdMaxCharacters]="33"
                              [smMaxCharacters]="33"
                              [xlMaxCharacters]="30"
                              [xsMaxCharacters]="23"></lt-text-truncate>
          </a>
        </li>

        <li aria-current="page"
            class="breadcrumb-item active"><i
          class="fas fa-map-marker-alt mr-2"></i>
          <lt-text-truncate [label]="session?.label !== step?.label
          ? step?.label : '(' + ('STEP.BREADCRUMB' | translate) + ') ' +  step?.label"
                            [lgMaxCharacters]="25"
                            [mdMaxCharacters]="33"
                            [smMaxCharacters]="33"
                            [xlMaxCharacters]="30"
                            [xsMaxCharacters]="23"
                            [title]="session?.label !== step?.label
          ? step?.label : '(' + ('STEP.BREADCRUMB' | translate) + ') ' +  step?.label"></lt-text-truncate>
        </li>
      </ol>
      <!-- <app-breadcrumb *ngIf="session"
        [comingFromStep]="true"
        [stepLabel]="step?.label"
        [session]="session"
      ></app-breadcrumb> -->
    </nav>
    <div class="buttons_actions">
      <button
        *ngIf="currentStepEnduser?.manager && configEnabled && step.status == 'STARTED'"
        class="ml-2 mb-1 mt-1 btn btn-primary"
        (click)="openConfigModal()">{{'MODAL_CONFIGURATOR.TITLE' | translate }}
      </button>
      <button
        *ngIf="currentUserManager && (step.status == 'STARTED' || step.status == 'DECLINED') && reminderEnabled"
        (click)="addReminder()" [disabled]="cancelling || suspending || addingReminders"
        class="ml-2 mb-1 mt-1 btn btn-primary"
        id="id-trigger-reminders"
        type="button">{{'STEP.REMINDERS' | translate }}
      </button>

      <button-delegate
        [context]="buttonDelegateContext"
        [session]="session"
        [step]="step"
        [stepEnduserIds]="stepEnduserIds"
        (delegateCreated)="refreshStepEndusers()"
      >
      </button-delegate>
      <button
        (click)="openModalCancel()" [disabled]="cancelling || suspending || addingReminders"
        *ngIf="documentCancelActionButtonEnabled && currentUserManager && isStepStarted()"
        class="ml-2 mb-1 mt-1 btn cancel"
        type="button">{{'STEP.CANCEL' | translate }}
      </button>

      <button
        (click)="openModalSuspend()" [disabled]="cancelling || suspending || addingReminders"
        *ngIf="documentSuspendActionButtonEnabled && currentUserManager && isStepStarted()"
        class="ml-2 mb-1 mt-1 btn suspend"
        type="button">{{'STEP.SUSPEND' | translate }}
      </button>

      <button
        (click)="continue()" [disabled]="continuing"
        *ngIf="currentUserManager && isStepSuspend()"
        class="ml-2 mb-1 mt-1 btn reinstate"
        type="button">{{'STEP.CONTINUE' | translate }}
    </button>

    </div>
  </div>

  <!-- Documents to sign -->
  <app-card *ngIf="workflows.length !== 0"
            [collapsible]="true"
            [title]="'STEP.DOCUMENTS_SIGNED' | translate">
    <ul class="list-group">
      <li *ngFor="let workflow of workflowsAggregatedSorted"
          class="list-group-item">
        <div class="steps-docs-btns mb-2">
          <button (click)="download(workflow.document.id, workflow.document.name)"
                  [disabled]="getCurrentUserForDocument(workflow.users)?.forbidDownload || downloading"
                  class="ml-2 mb-1 mt-1 btn btn-primary text-truncate download-btn"
                  type="button">
            <i aria-hidden="true"
               class="fa fa-lg fa-fw fa-cloud-download-alt mr-2"></i>{{workflow.document.name}}
          </button>
          <button [queryParamsHandling]="'preserve'" [routerLink]="linkToDocument(workflow)"
                  class="ml-2 mb-1 mt-1 btn btn-primary float-right text-truncate"
                  type="button">
            {{'STEP.GO_TO_DOCUMENT'| translate}}
            <i aria-hidden="true"
               class="fa fa-lg fa-fw fa-arrow-right"></i>
          </button>
        </div>
        <table class="table">
          <thead>
          <tr>
            <th class="d-inline-block col-9"
                scope="col">{{'STEP.USER' | translate}}</th>
            <th class="d-inline-block col-3"
                scope="col">{{'STEP.STATUS' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of workflow.users">
            <td *ngIf="user.enduser" class="d-inline-block col-9">
              {{
              user.enduser.circle
                ? getCircleDisplay(user.enduser)
                : user.enduser.firstName + ' ' + user.enduser.lastName
              }}
              <span *ngIf="user.enduser.email">
                  -
                  <i>{{user.enduser.email}}</i>
              </span>
              <span *ngIf="user.enduser.circle && !isDelegation(user.enduser)">
                  -
                  <i class="fas fa-users"></i>
              </span>
            </td>
            <td *ngIf="!user.enduser" class="d-inline-block col-9">
              <i>{{'STEP.UNKNOWN_YET' | translate}}</i>
            </td>
            <td class="d-inline-block col-3">
              <ng-container [ngSwitch]="user.signatureStatus">
                <ng-container *ngSwitchCase="SignatureStatusEnum.SIGNED">
                  <i aria-hidden="true"
                     class="fa fa-check fa-fw text-success mr-1"></i>
                  <span class="d-none d-sm-inline">{{'STEP.SIGNED' | translate}}
                    <p class="sprofile_label" *ngIf="canSeeSprofile">({{user.sprofileLabel}})</p> {{user?.signedOn | date: 'short' : undefined : translateService.currentLang}}</span>
                </ng-container>

                <ng-container *ngSwitchCase="SignatureStatusEnum.DECLINED">
                  <i aria-hidden="true"
                     class="fa fa-times-circle fa-fw text-danger mr-1"></i>
                  <span class="d-none d-sm-inline">{{'STEP.DECLINED' | translate}}</span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <i aria-hidden="true"
                     class="fa fa-exclamation-triangle fa-fw text-warning mr-1"></i>
                  <ng-container *ngIf="checkStepActions; else default">
                      <span *ngIf="step.status===stepStatusEnum.SUSPENDED"
                            class="d-none d-sm-inline">{{'STEP.SUSPENDED' | translate}}</span>
                    <span *ngIf="step.status===stepStatusEnum.CANCELED"
                          class="d-none d-sm-inline">{{'STEP.CANCELLED' | translate}}</span>
                    <span *ngIf="step.status===stepStatusEnum.SKIPPED"
                          class="d-none d-sm-inline">{{'STEP.SKIPPED' | translate}}</span>
                  </ng-container>

                  <ng-template #default>
                      <span *ngIf="step.status===stepStatusEnum.STARTED"
                            class="d-none d-sm-inline">
                            {{'STEP.WAITING' | translate}} <p class="sprofile_label" *ngIf="canSeeSprofile">({{user.sprofileLabel}})</p> </span>
                  </ng-template>
                </ng-container>
              </ng-container>

            </td>
          </tr>
          </tbody>
        </table>
      </li>
    </ul>
  </app-card>

  <!-- Documents to view -->
  <app-card #appendicesSection *ngIf="viewDocuments && viewDocuments.length !== 0"
            [collapsible]="true"
            [title]="'STEP.DOCUMENTS_VIEWED' | translate">

    <ul class="list-group">
      <li *ngFor="let viewDocument of viewDocumentsSorted"
          class="list-group-item">
        <button (click)="download(viewDocument.id, viewDocument.name)"
                class="ml-2 mb-1 mt-1 btn btn-primary"
                type="button">
          <i aria-hidden="true"
             class="fa fa-lg fa-fw fa-cloud-download-alt mr-2"></i>{{viewDocument.name}}
        </button>
        <button [queryParamsHandling]="'preserve'" [routerLink]="linkToAnnex(viewDocument)"
                class="ml-2 mb-1 mt-1 btn btn-primary float-right text-truncate"
                type="button">
          {{'STEP.GO_TO_DOCUMENT'| translate}}
          <i aria-hidden="true"
             class="fa fa-lg fa-fw fa-arrow-right"></i>
        </button>
        <span class="text-primary float-right" style="margin-top: 8px;">
            <i aria-hidden="true"
               class="fa fa-eye"></i>
        </span>
      </li>
    </ul>
  </app-card>
  <div class="mb-4"></div>

  <!-- Tags -->
  <step-metadata #metadataSection *ngIf="step?.tags?.length > 0 && currentStepEnduser"
                 [stepTags]="step.tags"
                 [stepId]="step.id"
                 [sessionId]="session.id"
                 [isWatcher]="isWatcher"
                 [disableAllMetadata]="step.status === stepStatusEnum.COMPLETED
                                    || step.status === stepStatusEnum.SUSPENDED
                                    || step.status === stepStatusEnum.DECLINED
                                    || step.status === stepStatusEnum.CANCELED"
                 [alreadySigned]="alreadySigned"
  ></step-metadata>

  <div class="mb-4"></div>
  <!-- Comments section -->
  <app-comment-list *ngIf="session && step"
                    [sessionId]="session?.id"
                    [step]="step"></app-comment-list>
  <div class="mb-4"></div>
</div>


<app-modal [opened]="openOrNotModalCancel || openOrNotModalSuspend"
           [titleModal]="(openOrNotModalCancel ? 'DOCUMENT.CONFIRM_CANCEL' : 'DOCUMENT.CONFIRM_SUSPEND') | translate"
           [design]="'custom'"
           [messageModal]="(openOrNotModalCancel ? 'DOCUMENT.MESSAGE_MODAL_CANCEL' : 'DOCUMENT.MESSAGE_MODAL_SUSPEND') | translate"
           (delete)=" openOrNotModalCancel ? cancel() : suspend()"
           (closeModal)="openOrNotModalCancel ? openOrNotModalCancel = false : openOrNotModalSuspend = false">
</app-modal>
