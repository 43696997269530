<div [ngClass]="mode"
     class="full-screen container">

  <h1 *ngIf="title"
      class="mt-3">{{title}}
    <h6 *ngIf="icon && tooltip"
        [ngbTooltip]="tooltip | translate"
        class="fa fa-{{icon}}">&nbsp;</h6>
  </h1>

  <div class="row">
    <div class="col-12 col-lg mt-3">
      <ng-content select="[top-panel]"></ng-content>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-3 mt-3">
      <ng-content select="[left-panel]"></ng-content>
    </div>

    <div class="col-12 col-lg mt-3">
      <ng-content></ng-content>
    </div>

    <div class="col-12 col-lg-3 mt-3">
      <ng-content select="[right-panel]"></ng-content>
    </div>
  </div>
</div>
