/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/alert/alert.component.ngfactory";
import * as i3 from "./shared/components/alert/alert.component";
import * as i4 from "./services/services/alert-service";
import * as i5 from "ngx-toastr";
import * as i6 from "./core/components/header/header.component.ngfactory";
import * as i7 from "./core/components/header/header.component";
import * as i8 from "./services/services/app.service";
import * as i9 from "./services/services/visual-identity.service";
import * as i10 from "../../luxtrust-cosi-api/api/enduser.service";
import * as i11 from "@angular/router";
import * as i12 from "../../luxtrust-cosi-api/api/enduserPreference.service";
import * as i13 from "./services/services/languages.service";
import * as i14 from "@ngx-translate/core";
import * as i15 from "./services/services/configuration.service";
import * as i16 from "@ng-bootstrap/ng-bootstrap";
import * as i17 from "./services/services/notifier.service";
import * as i18 from "./services/services/sprofile-wrapper.service";
import * as i19 from "./app.component";
import * as i20 from "./core/service/translate.service";
import * as i21 from "./services/services/url.service";
import * as i22 from "../../luxtrust-cosi-api/api/platform.service";
import * as i23 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[8, "lang", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "alert", [], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(2, 245760, null, 0, i3.AlertComponent, [i4.AlertService, i5.ToastrService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-header", [], null, [["document", "mouseover"], ["document", "mouseout"]], function (_v, en, $event) { var ad = true; if (("document:mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).globalMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:mouseout" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).globalMouseOut($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i1.ɵdid(4, 114688, null, 0, i7.HeaderComponent, [i8.AppService, i9.VisualIdentityService, i10.EnduserService, i11.Router, i11.ActivatedRoute, i12.EnduserPreferenceService, i13.LanguagesService, i14.TranslateService, i15.ConfigurationService, i16.NgbModal, i17.NotifierService, i18.SprofileWrapperService, i1.NgZone], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i11.RouterOutlet, [i11.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translateService.currentLang; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i19.AppComponent, [i11.Router, i4.AlertService, i20.TranslateService, i21.UrlService, i13.LanguagesService, i8.AppService, i9.VisualIdentityService, i22.PlatformService, i15.ConfigurationService, i23.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i19.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
