<div class="document-card">
  <div class="container">
    <div class="col">
      <div class="row title">
        <label class="label" title="{{'WIZARD.DOCUMENTS.RENAME_FILE' | translate}}">
          <lt-text-truncate
            [label]="document.name"
            [xlMaxCharacters]="xlMaxCharacters"
            [lgMaxCharacters]="lgMaxCharacters"
            [mdMaxCharacters]="mdMaxCharacters"
            [smMaxCharacters]="smMaxCharacters"
            [xsMaxCharacters]="xsMaxCharacters">
          </lt-text-truncate>
          <button *ngIf="!document.shared" [disabled]="disabled"
                  (click)="openModal.emit(document)" class="btn-add icon-button button-acro" type="button">
            <i aria-hidden="true" class="fa fa-edit icon"></i>
          </button>
        </label>
        <input [id]="id" (change)="onReuploadDocument($event)" class="input-file" type="file" [disabled]="disabled"/>
        <label [for]="id" *ngIf="(!document.shared && canUpdate)  && !hasAtLeast1Signature"
               class="btn btn-primary mr-4 button_clone btn-height"
               [ngClass]="{'btn-margin-right': !(!document.shared && canAddAcroform), 'disabled': disabled}">
          <i aria-hidden="true" class="fas fa-upload pr-1"></i>
          {{'MODAL_REUPLOAD.BTN_REUPLOAD' | translate}}
        </label>

        <button id="id-position-signatures" *ngIf="enableSignatures()" (click)="onAddAcroform.emit()"
                class="btn btn-primary mr-4  btn-height btn-margin-right" [disabled]="disabled">
          <i aria-hidden="true" class="fas fa-file-signature pr-1"></i>
          {{'WIZARD.DOCUMENTS.SIGNATURE' | translate}}
        </button>
      </div>

      <!-- Sprofile -->
      <div *ngIf="!annexe && !mutual && canSeeSprofile" class="sprofile">
        <div class="select-input" [ngClass]="{'select-input-ie': isIE}">
          <div class="select-input-i">
            <div class="input-group-text" for="input-sprofile">
              <i aria-hidden="true" class="fa fa-file-pdf"></i>
            </div>
          </div>
          <div class="select-input-r">
            <select (change)="onSprofile.emit($event.target.value)" [(ngModel)]="selectedSprofile"
                    class="custom-select select-input-r-select" id="input-sprofile">
              <option *ngFor="let sprofile of sprofiles | orderBy : ['label'] " [value]="sprofile.code"> {{sprofile.label}} </option>
            </select>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!mutual">  <!-- the condition is inverted! (if not to sign/view) -->
        <div
          class="row align-items-center"
          [ngClass]="{'justify-content-end': !isIE || (isConfigurator && isIE),'positionButtonIE': !displaySwitcherMutual && isIE}"
        >
          <div *ngIf="displaySwitcherMutual && !isConfigurator" class="switcher switcher-right mr-auto mt-3">
            <!-- show or not MUTUAL toggle -->
            <ng-container>
              <label for="mutual-switch-{{document.id}}">
                {{'WIZARD.DOCUMENTS.MUTUALLABEL' | translate}}
              </label>
              <input #inputMutual (change)="change()" [(ngModel)]="document.parentIsMutual" [disabled]="isLoading || disabled"
                     class="switcher-checkbox" id="mutual-switch-{{document.id}}" type="checkbox">
              <div class="switcher-toggle" [class.disabled]="isLoading || disabled"></div>
            </ng-container>
          </div>
          <button *ngIf="!isConfigurator && canClone" (click)="!isLoading && onClone.emit(document)" [disabled]="isLoading || disabled"
                  class="btn btn-primary mt-2 mr-4 button_clone">
            {{'WIZARD.DOCUMENTS.CLONE' | translate}}
          </button>
          <button *ngIf="isConfigurator && canClone" (click)="uploadNewFile(document)" [disabled]="isLoading || disabled"
                  class="btn btn-primary mt-2 mr-4 button_clone">
            {{'WIZARD.DOCUMENTS.CLONE_CONFIGURATOR' | translate}}
          </button>
          <input
            *ngIf="isConfigurator && canClone"
            #uploadField (change)="uploadDocumentClone($event)"
            [accept]="acceptedMimeTypes"
            [id]="id"
            [disabled]="disabled"
            class="input-file"
            multiple="false"
            type="file"/>
          <button (click)="!isLoading && onDelete.emit(document)"
                  *ngIf="!usedInOtherSteps && canDelete && !hasAtLeast1Signature"
                  [disabled]="isLoading || disabled"
                  class="btn btn-danger mt-2  mr-4 button_delete">
            {{'WIZARD.DOCUMENTS.DELETE' | translate}}
          </button>
        </div>
        <div class="warning_message">
          <span *ngIf="checkForPositionSignatureLocation()" class="position-text">{{'WIZARD.DOCUMENTS.WARNING_MESSAGE_BAD_DOC_PSL' | translate}}</span>
          <span *ngIf="checkIfNotSharedWithPositionSignatureLocation() && !checkForPositionSignatureLocation()" class="position-text">{{'WIZARD.DOCUMENTS.WARNING_MESSAGE_NOT_SHARED_PSL' | translate}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="mutual">
        <button (click)="onMutualAppendixIn.emit({document: document, isMutual: !document.parentIsMutual})"
                [disabled]="disabled" class="btn btn-primary pull-right" style="float: right;">
          {{'WIZARD.DOCUMENTS.TAKE_APPENDIX' | translate}}
        </button>
        <button (click)="onMutualDocumentIn.emit({document: document, isMutual: !document.parentIsMutual})"
                [disabled]="disabled" class="btn btn-primary pull-right"
                style="float: right; margin-right: 15px;">
          {{'WIZARD.DOCUMENTS.TAKE' | translate}}

        </button>
      </ng-container>

      <ng-container *ngFor="let signerDetail of signatures">
        <p class="row mt-2 col-12">
          <span *ngIf="!signerDetail.signer.circle" style="font-weight: bold; font-size: 0.8rem">
            {{signerDetail.signer.firstName + ' ' + signerDetail.signer.lastName}}
          </span>
          <span *ngIf="signerDetail.signer.circle" style="font-weight: bold; font-size: 0.8rem">
            {{signerDetail.signer.lastName}}
          </span>
          <span *ngIf="signerDetail.pages > 0" style="font-size: 0.8rem">
            &nbsp;{{'NEW_SESSION.DOCUMENTS.SIGNER_DETAILS'| translate}} {{signerDetail.pages}}
          </span>
          <span *ngIf="signerDetail.pages == 0" style="font-size: 0.8rem">
            &nbsp;{{'NEW_SESSION.DOCUMENTS.STATIC_ACROFORM'| translate}}
          </span>
        </p>
      </ng-container>
    </div>
  </div>
</div>
