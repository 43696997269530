/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard-step-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./wizard-step-button.component";
import * as i4 from "../../services/wizard.service";
var styles_WizardStepButtonComponent = [i0.styles];
var RenderType_WizardStepButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardStepButtonComponent, data: {} });
export { RenderType_WizardStepButtonComponent as RenderType_WizardStepButtonComponent };
function View_WizardStepButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary btn-min-width"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleNextStepButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_1); }); }
function View_WizardStepButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary btn-min-width"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePreviousStepButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_1); }); }
export function View_WizardStepButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardStepButtonComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardStepButtonComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.action; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.StepActions.NEXT; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.StepActions.PREVIOUS; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_WizardStepButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wizard-step-button", [], null, null, null, View_WizardStepButtonComponent_0, RenderType_WizardStepButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.WizardStepButtonComponent, [i4.WizardService], null, null)], null, null); }
var WizardStepButtonComponentNgFactory = i1.ɵccf("app-wizard-step-button", i3.WizardStepButtonComponent, View_WizardStepButtonComponent_Host_0, { label: "label", action: "action", disabled: "disabled" }, { onAction: "onAction" }, []);
export { WizardStepButtonComponentNgFactory as WizardStepButtonComponentNgFactory };
