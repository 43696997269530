/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comments-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-quill/ngx-quill.ngfactory";
import * as i3 from "ngx-quill";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../../core/pipes/translate.pipe";
import * as i7 from "../../../core/service/translate.service";
import * as i8 from "@angular/forms";
import * as i9 from "./comments-card.component";
import * as i10 from "../../../../../luxtrust-cosi-api/api/comment.service";
import * as i11 from "../../../services/services/alert-service";
var styles_CommentsCardComponent = [i0.styles];
var RenderType_CommentsCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentsCardComponent, data: {} });
export { RenderType_CommentsCardComponent as RenderType_CommentsCardComponent };
function View_CommentsCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "quill-editor", [["id", "comment-text-add"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.addedComment = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_QuillEditorComponent_0, i2.RenderType_QuillEditorComponent)), i1.ɵdid(1, 1753088, null, 0, i3.QuillEditorComponent, [i1.ElementRef, i4.DomSanitizer, i5.DOCUMENT, i1.PLATFORM_ID, i1.Renderer2, i1.NgZone, i3.QUILL_CONFIG_TOKEN], { modules: [0, "modules"], placeholder: [1, "placeholder"] }, null), i1.ɵpid(131072, i6.TranslatePipe, [i1.ChangeDetectorRef, i7.TranslateService]), i1.ɵprd(1024, null, i8.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.QuillEditorComponent]), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.QuillEditorComponent]), i1.ɵdid(5, 671744, null, 0, i8.NgModel, [[8, null], [6, i8.NG_VALIDATORS], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(7, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.quillConfig; var currVal_8 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform("STEP.COMMENT.TYPE_COMMENT_HERE")); _ck(_v, 1, 0, currVal_7, currVal_8); var currVal_9 = _co.addedComment; _ck(_v, 5, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_CommentsCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [["class", "mt-2 ml-3"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comment.text; _ck(_v, 0, 0, currVal_0); }); }
function View_CommentsCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "icon-button edit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editAddedComment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-pencil-alt"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); }); }
function View_CommentsCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "icon-button edit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editComment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-check"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); }); }
function View_CommentsCardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "icon-button remove "]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearComment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); }); }
export function View_CommentsCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "comments-card-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentsCardComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "row justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentsCardComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "btn-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentsCardComponent_3)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentsCardComponent_4)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentsCardComponent_5)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "remove icon-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteComment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash-alt"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTextArea; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.showTextArea; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.showTextArea; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.showTextArea; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.showTextArea; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.disabled; _ck(_v, 14, 0, currVal_5); }); }
export function View_CommentsCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-comments-card", [], null, null, null, View_CommentsCardComponent_0, RenderType_CommentsCardComponent)), i1.ɵdid(1, 49152, null, 0, i9.CommentsCardComponent, [i10.CommentService, i11.AlertService], null, null)], null, null); }
var CommentsCardComponentNgFactory = i1.ɵccf("app-comments-card", i9.CommentsCardComponent, View_CommentsCardComponent_Host_0, { comment: "comment", sessionId: "sessionId", stepId: "stepId", disabled: "disabled" }, { onDelete: "onDelete", onUpdate: "onUpdate" }, []);
export { CommentsCardComponentNgFactory as CommentsCardComponentNgFactory };
