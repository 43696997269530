<div #header class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{fileName}}</h4>
  <button (click)="save()" id="save" [hidden]="!disableSignature" class="mr-2 mb-2 btn btn-success">
    <i aria-hidden="true"
       class="fa fa-lg fa-check-circle"></i>&nbsp;{{'DOCUMENT.SAVE' | translate}}
  </button>
  <button (click)="close()" [hidden]="!disableSignature" class="mr-2 mb-2 btn btn-danger">
    <i aria-hidden="true"
       class="fa fa-lg fa-times"></i>&nbsp;{{'DOCUMENT.CANCEL' | translate}}
  </button>
</div>

<div class="modal-body">

  <div [hidden]="!disableSignature" class="text-center mb-2">
    <button (click)="loadSignatureImage()" [disabled]="loadingSignature"
            id="add-signature"
            class="btn btn-primary mr-2 mb-1">
      <i aria-hidden="true" class="fa fa-lg fa-upload"></i>
      {{'DOCUMENT.ADD_SIGNATURE' | translate}}
    </button>
<!--    fixme: make it configurable -->
<!--    <button (click)="toggleAnnotation()" class="btn btn-primary mr-2 mb-1">-->
<!--      <i [ngClass]="{'fa-ban' : annotationEnabled, 'fa-comment' : !annotationEnabled}"-->
<!--         aria-hidden="true"-->
<!--         class="fa fa-lg"></i>-->
<!--      {{(annotationEnabled ? 'DOCUMENT.DISABLE_ANNOTATION'-->
<!--        : 'DOCUMENT.ENABLE_ANNOTATION') | translate}}-->
<!--    </button>-->
  </div>

  <div [ngClass]="{'sticky-top sticky-pdf-bar': !disableSignature}" id="sticky-bar">
    <div class="text-center mb-2" style="padding: 5px">
      <button (click)="loadFirstPage()" [disabled]="shouldDisablePrevious()"
              class="mr-2 mb-2 btn btn-secondary" ngbTooltip="{{'DOCUMENT.FIRST_PAGE' | translate}}"
              placement="bottom"><i
        aria-hidden="true" class="fa fa-lg fa-step-backward"></i></button>
      <button (click)="loadPreviousPage()" [disabled]="shouldDisablePrevious()"
              class="mr-2 mb-2 btn btn-secondary"
              ngbTooltip="{{'DOCUMENT.PREVIOUS_PAGE' | translate}}"
              placement="bottom"><i
        aria-hidden="true" class="fa fa-lg fa-chevron-left"></i></button>
      <button (click)="loadNextPage()" [disabled]="shouldDisableNext()"
              class="mr-2 mb-2 btn btn-secondary" ngbTooltip="{{'DOCUMENT.NEXT_PAGE' | translate}}"
              placement="bottom"><i aria-hidden="true"
                                    class="fa fa-lg fa-chevron-right"></i></button>
      <button (click)="loadLastPage()" [disabled]="shouldDisableNext()"
              class="mr-2 mb-2 btn btn-secondary" ngbTooltip="{{'DOCUMENT.LAST_PAGE' | translate}}"
              placement="bottom"><i aria-hidden="true"
                                    class="fa fa-lg fa-step-forward"></i></button>
    </div>

    <div class="text-center mb-2">
      <button (click)="saveSignature()" [hidden]="disableSignature" id="save-signature" [disabled]="savingSignature"
              class="mr-2 mb-2 btn btn-secondary btn-success">{{'DOCUMENT.SAVE_SIGNATURE' | translate}}</button>
      <button (click)="cancelSignature()" [hidden]="disableSignature" id="cancel-signature" [disabled]="savingSignature"
              class="mr-2 mb-2 btn btn-danger">{{'DOCUMENT.CANCEL_SIGNATURE' | translate}}</button>
    </div>

    <hr *ngIf="!disableSignature">

    <div *ngIf="!disableSignature" class="text-center mb-2 resizer">
      <p style="font-weight: bold">{{'DOCUMENT.INCREASE_SIZE' | translate}}</p>
      <button #sliderLabel [disabled]="true" class="mr-2 btn btn-secondary"
              id="sliderLabel">{{(slider?.value ? slider?.value : '100') + ' %'}}
      </button>
      <input #slider (input)="onSliderChange($event)" class="custom-range" id="slider" max="175" min="25"
        name="scale-signature" type="range" value="100" *ngIf="!isIE"/>
      <input #slider (change)="onSliderChange($event)" class="custom-range" id="slider" max="175" min="25"
        name="scale-signature" type="range" value="100" *ngIf="isIE"/>
    </div>
  </div>

  <div class="signature-pad" id="signature-pad">
    <div #signature class="signature-pad--body" id="signature"></div>
  </div>
</div>
