import { Component, Input, OnInit } from '@angular/core';
import { StepTagService } from 'luxtrust-cosi-api/api/stepTag.service';
import { TagData } from 'luxtrust-cosi-api/model/tagData';
import { UpdateStepTagPayload } from 'luxtrust-cosi-api/model/updateStepTagPayload';

@Component({
  selector: 'step-metadata', templateUrl: './step.metadata.component.html', styleUrls: ['./step.metadata.component.scss']
})
export class StepMetadataComponent implements OnInit {

  @Input() stepTags: TagData [];
  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() alreadySigned: boolean;
  @Input() disableAllMetadata = false;
  @Input() isWatcher = false;

  constructor(private stepTagService: StepTagService) {}
  ngOnInit(): void {
  }

  updateStepTagMeta(tagItem: TagData) {
      const body: UpdateStepTagPayload = {
        tagId: tagItem.id, value: tagItem.value, encrypt: tagItem.encrypted, orderIndex: tagItem.orderIndex, configurator: tagItem.configurator
      } as UpdateStepTagPayload;
      this.stepTagService.updateStepTag(this.sessionId, this.stepId, body).subscribe();
  }

  trackSteptag(index: number) {
    return index;
  }

}
