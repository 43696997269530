<div *ngIf="annotations && annotations.length !== 0" class="d-inline-block" ngbDropdown>
  <button class="btn btn-primary" id="dropDownAnnotation" ngbDropdownToggle>
    <i aria-hidden="true" class="fa fa-comment"></i> {{'DOCUMENT.SEE_ANNOTATION' | translate}}
  </button>
  <div aria-labelledby="dropDownAnnotation" class="dropdown-menu" ngbDropdownMenu>
    <button (click)="openAnnotationFromDropDown(annotation)" *ngFor="let annotation of annotations"
            class="dropdown-item text-truncate"
            style="cursor: pointer">
      <lt-text-truncate
        [label]="annotation.text"
        [lgMaxCharacters]="maxAnnotationCharacter"
        [mdMaxCharacters]="maxAnnotationCharacter"
        [smMaxCharacters]="maxAnnotationCharacter"
        [xlMaxCharacters]="maxAnnotationCharacter"
        [xsMaxCharacters]="maxAnnotationCharacter"
      >
      </lt-text-truncate>
      (Page {{annotation.page}})
    </button>
  </div>
</div>
