import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {SprofileService} from 'luxtrust-cosi-api/api/sprofile.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {forkJoin, Subscription} from 'rxjs';
import TypologyEnum = DocumentData.TypologyEnum;
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';
import {StepActions} from '../../wizard/models/wizard.config';

@Component({
  templateUrl: './wizard-step-signature.component.html', styleUrls: ['./wizard-step-signature.component.scss']
})
export class WizardStepSignatureComponent implements OnInit, OnDestroy {

  stepActions = StepActions;
  stepEndUsers: StepEnduserData[] = [];
  documents: DocumentData[] = [];
  sessionId: number;
  stepId: number;
  sprofileList: SprofileData[] = [];
  // FIXME BDX This is ugly as hell and should be handled generically (keyvals in general) with types, and an array of keyvals not a JSON object
  sprofileKeys: string[] = [];
  sprofileKeyValues: { [key: string]: any };
  sprofileValues = {};
  updatingSprofile = false;
  private subscription: Subscription;

  constructor(private stepEndUserService: StepEnduserService,
              private alertService: AlertService,
              private documentService: DocumentService,
              private route: ActivatedRoute,
              private sprofileService: SprofileService) {
    this.sprofileService.getSprofileKeyvalList().toPromise().then(sprofileKeyValues => {
      this.sprofileKeyValues = sprofileKeyValues;
      this.sprofileKeys = Object.getOwnPropertyNames(sprofileKeyValues);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  ngOnInit() {
    this.subscription = this.route.parent.data.subscribe((data: Data) => {
      this.sessionId = data['session'].id;
      this.stepId = data['step'].id;
      this.sprofileList = data['sprofiles'];

      const documents$ = this.documentService.getDocumentList(this.sessionId, this.stepId);
      const stepEndUsers$ = this.stepEndUserService.stepEnduserList(this.sessionId, this.stepId);
      forkJoin(stepEndUsers$, documents$).subscribe(([stepEndUsers, documents]) => {
        this.stepEndUsers = stepEndUsers.filter((stepEndUser: StepEnduserData) => stepEndUser.signer);
        this.documents = documents.filter(document_ => document_.typology === TypologyEnum.SPROFILE);
      }, (error: ApiError) => this.alertService.errorApi(error));

      // FIXME BDX Also ugly as hell
      this.sprofileService.getSprofileKeyvalValList(this.stepId).toPromise().then(keyvals => {
        Object.getOwnPropertyNames(keyvals).forEach(key => {
          if (!this.sprofileKeys.some((existingKey: string) => existingKey === key)) {
            this.sprofileKeys.push(key);
          }
          this.sprofileValues[key] = keyvals[key];
        });
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateSprofileKeyVal(key: string) {
    this.updatingSprofile = true;
    this.sprofileService.setSprofileKeyvalVal(this.stepId, {
      key: key, value: this.sprofileValues[key], sprofileCode: this.documents[0].sprofileCode // fixme: something less generic?
    }).toPromise().catch((error: ApiError) => this.alertService.errorApi(error)).then(value => this.updatingSprofile = false);
  }
}
