<div  class="w-100">
  <div class="d-flex">
    <div class="input-day-number input-group">
      <input #inputDayNumber
             (input)="onInput($event.target.value)"
             [max]="max"
             class="form-control"
             [ngClass]="{'sizeInputMetadataPage': smallSizeInputDay}"
             [min]="min"
             [value]="defaultValue"
             type="number">
      <span>{{'WIZARD.GENERAL.DAYS' | translate}}</span>
    </div>
    <div (click)="inputDayNumber.value = '';onInput(null)" class="clearBtn icon-button" [ngClass]="{'changePaddingMetadata': metadataPage}">
      <i class="fas fa-backspace"></i>
    </div>
  </div>
</div>
