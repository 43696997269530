/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./itsme-auth-signup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/translate.pipe";
import * as i3 from "../../../service/translate.service";
import * as i4 from "./itsme-auth-signup.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../services/services/app.service";
import * as i7 from "../../../../services/services/alert-service";
import * as i8 from "../../../../services/services/itsme.service";
import * as i9 from "../../../../../../luxtrust-cosi-api/api/itsmeAuthentication.service";
var styles_ItsmeAuthSignupComponent = [i0.styles];
var RenderType_ItsmeAuthSignupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItsmeAuthSignupComponent, data: {} });
export { RenderType_ItsmeAuthSignupComponent as RenderType_ItsmeAuthSignupComponent };
export function View_ItsmeAuthSignupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/img/itsme-logo.png"], ["style", "width:100%; max-width: 125px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ITSME.LOADING")); _ck(_v, 5, 0, currVal_0); }); }
export function View_ItsmeAuthSignupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ItsmeAuthSignupComponent_0, RenderType_ItsmeAuthSignupComponent)), i1.ɵdid(1, 114688, null, 0, i4.ItsmeAuthSignupComponent, [i5.ActivatedRoute, i5.Router, i6.AppService, i7.AlertService, i3.TranslateService, i8.ItsmeService, i9.ItsmeAuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItsmeAuthSignupComponentNgFactory = i1.ɵccf("ng-component", i4.ItsmeAuthSignupComponent, View_ItsmeAuthSignupComponent_Host_0, {}, {}, []);
export { ItsmeAuthSignupComponentNgFactory as ItsmeAuthSignupComponentNgFactory };
