/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../core/pipes/translate.pipe";
import * as i2 from "../../../core/service/translate.service";
import * as i3 from "@angular/common";
import * as i4 from "./modal-orely-login.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@angular/platform-browser";
var styles_ModalOrelyLoginComponent = [];
var RenderType_ModalOrelyLoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalOrelyLoginComponent, data: {} });
export { RenderType_ModalOrelyLoginComponent as RenderType_ModalOrelyLoginComponent };
function View_ModalOrelyLoginComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "iframe", [["frameborder", "0"], ["height", "700"], ["name", "orely"], ["scrolling", "yes"], ["target", "_parent"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orelyAuthUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_ModalOrelyLoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalOrelyLoginComponent_1)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "modal-footer"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.iFrameVisible; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("USER_PROFILE.LUXTRUST.FIND_SSN")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ModalOrelyLoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).iFrameEventListener($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ModalOrelyLoginComponent_0, RenderType_ModalOrelyLoginComponent)), i0.ɵdid(1, 49152, null, 0, i4.ModalOrelyLoginComponent, [i5.NgbActiveModal, i6.DomSanitizer], null, null)], null, null); }
var ModalOrelyLoginComponentNgFactory = i0.ɵccf("ng-component", i4.ModalOrelyLoginComponent, View_ModalOrelyLoginComponent_Host_0, {}, {}, []);
export { ModalOrelyLoginComponentNgFactory as ModalOrelyLoginComponentNgFactory };
