<ng-container [ngSwitch]="action">
  <button (click)="handleNextStepButton()"
          *ngSwitchCase="StepActions.NEXT"
          [disabled]="disabled"
          class="btn btn-primary btn-min-width"
          type="button">
    {{label}}
    <!--    <i class="fa fa fa-fw fa-arrow-right" aria-hidden="true"></i>-->
  </button>

  <button (click)="handlePreviousStepButton()"
          *ngSwitchCase="StepActions.PREVIOUS"
          [disabled]="disabled"
          class="btn btn-primary btn-min-width"
          type="button">
    <!--    <i class="fa fa fa-fw fa-arrow-left" aria-hidden="true"></i>-->
    {{label}}
  </button>
</ng-container>
