import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StepLegalMotion} from 'luxtrust-cosi-api/model/stepLegalMotion';

@Component({
  selector: 'app-legal-motion-card', templateUrl: './legal-motion-card.component.html', styleUrls: ['./legal-motion-card.component.scss']
})
export class LegalMotionCardComponent {

  @Input() stepLegalMotion: StepLegalMotion;
  @Input() disabled:boolean;

  @Output() onDelete: EventEmitter<StepLegalMotion> = new EventEmitter();
  @Output() onUpdate: EventEmitter<StepLegalMotion> = new EventEmitter();

  open = false;

  handleDeleteLegalMotion(legalMotion: StepLegalMotion) {
    this.onDelete.emit(legalMotion);
  }

  handleOptional(legalMotion: StepLegalMotion) {
    legalMotion.mandatory = false;
    this.onUpdate.emit(legalMotion);
  }

  handleMandatory(legalMotion: StepLegalMotion) {
    legalMotion.mandatory = true;
    this.onUpdate.emit(legalMotion);
  }
}
