import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {take} from 'rxjs/internal/operators';
import {AppService} from '../../../../services/services/app.service';
import {ModalDelegateComponent} from '../modal/modal-delegate.component';

export enum ButtonDelegateContext {
  SESSION, STEP, DOCUMENT
}

@Component({
  selector: 'button-delegate', templateUrl: './button-delegate.component.html', styleUrls: ['./button-delegate.component.scss']
})
export class ButtonDelegateComponent implements OnChanges {

  @Input() public context: ButtonDelegateContext;
  @Input() public textButton = true;
  @Input() public session: SessionData;
  @Input() public step: StepData;
  @Input() public stepEnduserIds: number[];

  @Output() public delegateCreated: EventEmitter<void> = new EventEmitter();

  /**
   * Potential delegateId for the current page.
   * 0: I can delegate
   * -1: I cannot delegate (main reason is the missing stepTag enabling the feature)
   * n > 0:
   *   - n === my enduser ID: I have delegated to someone
   *   - n !== my enduser ID: Someone has delegated to me
   */
  private delegateId: number;

  /**
   * Used to display/hide modal switcher
   */
  private delegationEnabledForSession = false;
  private delegationEnabledForStep = false;

  /**
   * Used to display/hide the button itself
   */
  delegationEnable = false;

  /**
   * Delegation has already been done by the current authenticated user
   */
  delegated = false;

  constructor(
    private readonly modal: NgbModal,
    private readonly appService: AppService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeButton();
  }

  private initializeButton() {
    this.extractDelegateId();
    this.checkDelegated();
    this.checkDelegationEnabled();
  }

  private extractDelegateId() {
    // FIXME When session will be considered for this button, we'll need to rethink logic of this button
    //  Some checks are oriented for steps only
    if (this.session) {
      // @ts-ignore
      // FIXME
      this.delegationEnabledForSession = this.isDelegationEnabled(this.session.delegatorId);
      if (this.context === ButtonDelegateContext.SESSION) {
        // @ts-ignore
        // FIXME
        this.delegateId = this.session.delegatorId;
      }
    }
    if (this.step) {
      this.delegationEnabledForStep = this.isDelegationEnabled(this.step.delegatorId);
      if (this.context === ButtonDelegateContext.STEP || this.context === ButtonDelegateContext.DOCUMENT) {
        this.delegateId = this.step.delegatorId;
      }
    }
  }

  checkDelegated() {
    if (this.delegateId) {
      this.delegated = this.delegateId === this.appService.getUser().id;
    } else {
      this.delegated = false;
    }
  }

  checkDelegationEnabled() {
    this.delegationEnable = this.isDelegationEnabled(this.delegateId);
  }

  private isDelegationEnabled(delegatorId: number) {
    return delegatorId >= 0;
  }

  openDelegationModal() {
    const modalRef = this.modal.open(ModalDelegateComponent, { backdrop: false, centered: true });
    const modalDelegate = modalRef.componentInstance as ModalDelegateComponent;
    modalDelegate.stepId = this.step ? this.step.id : undefined;
    modalDelegate.stepEnduserIds = this.stepEnduserIds;
    modalDelegate.sessionId = this.session.id;
    modalDelegate.displaySwitcher = this.delegationEnabledForSession && this.delegationEnabledForStep;
    modalDelegate.output.pipe(take(1)).subscribe((result: number) => {
      if (result) {
        if (this.delegationEnabledForStep) {
          this.step.delegatorId = result;
          this.delegated = true;
        } else if (this.delegationEnabledForSession) {
          // @ts-ignore
          // FIXME
          this.session.delegatorId = result;
          this.delegated = true;
        }
      }
      modalRef.close();
      this.delegateCreated.emit();
    });
  }
}
