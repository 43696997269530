import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AppService} from '../services/app.service';
import {EntitlementGuard} from './entitlement.guard';

@Injectable({providedIn: 'root'})
export class ParapheurGuard extends EntitlementGuard {

  constructor(protected router: Router, protected appService: AppService) {
    super(router, appService);
  }

  getEntitlementName(): string {
    return 'SIGNATURE_BOOK';
  }

  positive(): boolean {
    return true;
  }
}
