/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExternalRolesDescriptionAccessibleTemplateCountDto } from '../model/externalRolesDescriptionAccessibleTemplateCountDto';
import { ListOfAccessibleTemplateIds } from '../model/listOfAccessibleTemplateIds';
import { TemplateWithAccessibilityStatusDto } from '../model/templateWithAccessibilityStatusDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TemplateToEntitlementMappingService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * listExternalEntitlements
     * 
     * @param tenantId tenantId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listExternalEntitlementsUsingGET(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ExternalRolesDescriptionAccessibleTemplateCountDto>>;
    public listExternalEntitlementsUsingGET(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ExternalRolesDescriptionAccessibleTemplateCountDto>>>;
    public listExternalEntitlementsUsingGET(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ExternalRolesDescriptionAccessibleTemplateCountDto>>>;
    public listExternalEntitlementsUsingGET(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling listExternalEntitlementsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ExternalRolesDescriptionAccessibleTemplateCountDto>>(`${this.basePath}/api/template-to-entitlement-mapping/${encodeURIComponent(String(tenantId))}/list-external-entitlements`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listTemplateWithStatusForAGivenEntitlement
     * 
     * @param entitlementId entitlementId
     * @param tenantId tenantId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listTemplateWithStatusForAGivenEntitlementUsingGET(entitlementId: number, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateWithAccessibilityStatusDto>>;
    public listTemplateWithStatusForAGivenEntitlementUsingGET(entitlementId: number, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateWithAccessibilityStatusDto>>>;
    public listTemplateWithStatusForAGivenEntitlementUsingGET(entitlementId: number, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateWithAccessibilityStatusDto>>>;
    public listTemplateWithStatusForAGivenEntitlementUsingGET(entitlementId: number, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (entitlementId === null || entitlementId === undefined) {
            throw new Error('Required parameter entitlementId was null or undefined when calling listTemplateWithStatusForAGivenEntitlementUsingGET.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling listTemplateWithStatusForAGivenEntitlementUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TemplateWithAccessibilityStatusDto>>(`${this.basePath}/api/template-to-entitlement-mapping/${encodeURIComponent(String(tenantId))}/list-template-for-entitlement/${encodeURIComponent(String(entitlementId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateTemplateAccessibilityForGivenEntitlement
     * 
     * @param entitlementId entitlementId
     * @param listOfAccessibleTemplateIds listOfAccessibleTemplateIds
     * @param tenantId tenantId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTemplateAccessibilityForGivenEntitlementUsingPOST(entitlementId: number, listOfAccessibleTemplateIds: ListOfAccessibleTemplateIds, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateTemplateAccessibilityForGivenEntitlementUsingPOST(entitlementId: number, listOfAccessibleTemplateIds: ListOfAccessibleTemplateIds, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateTemplateAccessibilityForGivenEntitlementUsingPOST(entitlementId: number, listOfAccessibleTemplateIds: ListOfAccessibleTemplateIds, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateTemplateAccessibilityForGivenEntitlementUsingPOST(entitlementId: number, listOfAccessibleTemplateIds: ListOfAccessibleTemplateIds, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (entitlementId === null || entitlementId === undefined) {
            throw new Error('Required parameter entitlementId was null or undefined when calling updateTemplateAccessibilityForGivenEntitlementUsingPOST.');
        }

        if (listOfAccessibleTemplateIds === null || listOfAccessibleTemplateIds === undefined) {
            throw new Error('Required parameter listOfAccessibleTemplateIds was null or undefined when calling updateTemplateAccessibilityForGivenEntitlementUsingPOST.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling updateTemplateAccessibilityForGivenEntitlementUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/template-to-entitlement-mapping/${encodeURIComponent(String(tenantId))}/update-template-accessibilility/${encodeURIComponent(String(entitlementId))}`,
            listOfAccessibleTemplateIds,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
