/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { OrganisationData } from '../model/organisationData';
import { UpdateOrganisationPayload } from '../model/updateOrganisationPayload';
import { UpdateVisualIdentityPayload } from '../model/updateVisualIdentityPayload';
import { VisualIdentityData } from '../model/visualIdentityData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrganisationService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create organisation
     * 
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisation(payload: UpdateOrganisationPayload, observe?: 'body', reportProgress?: boolean): Observable<OrganisationData>;
    public createOrganisation(payload: UpdateOrganisationPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationData>>;
    public createOrganisation(payload: UpdateOrganisationPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationData>>;
    public createOrganisation(payload: UpdateOrganisationPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling createOrganisation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrganisationData>(`${this.basePath}/api/organisations`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete organisation visual identity
     * 
     * @param organisationId Entitlement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationVi(organisationId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteOrganisationVi(organisationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteOrganisationVi(organisationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteOrganisationVi(organisationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationVi.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}/visual-identity`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get organisation
     * 
     * @param organisationId Organisation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisation(organisationId: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationData>;
    public getOrganisation(organisationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationData>>;
    public getOrganisation(organisationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationData>>;
    public getOrganisation(organisationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrganisationData>(`${this.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update organisation
     * 
     * @param organisationId Organisation
     * @param payload Organisation to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateOrganisation(organisationId: number, payload?: UpdateOrganisationPayload, observe?: 'body', reportProgress?: boolean): Observable<OrganisationData>;
    public partialUpdateOrganisation(organisationId: number, payload?: UpdateOrganisationPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationData>>;
    public partialUpdateOrganisation(organisationId: number, payload?: UpdateOrganisationPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationData>>;
    public partialUpdateOrganisation(organisationId: number, payload?: UpdateOrganisationPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling partialUpdateOrganisation.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<OrganisationData>(`${this.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search organisations matching the filter
     * 
     * @param directoryAlias Directory alias
     * @param like Indicate if a &#39;like&#39; search style should be used
     * @param limit Limit
     * @param offset Last enduser id
     * @param tenantId Tenant
     * @param text Content to be filtered
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOrganisations(directoryAlias?: string, like?: boolean, limit?: number, offset?: number, tenantId?: number, text?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationData>>;
    public searchOrganisations(directoryAlias?: string, like?: boolean, limit?: number, offset?: number, tenantId?: number, text?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationData>>>;
    public searchOrganisations(directoryAlias?: string, like?: boolean, limit?: number, offset?: number, tenantId?: number, text?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationData>>>;
    public searchOrganisations(directoryAlias?: string, like?: boolean, limit?: number, offset?: number, tenantId?: number, text?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (directoryAlias !== undefined && directoryAlias !== null) {
            queryParameters = queryParameters.set('directoryAlias', <any>directoryAlias);
        }
        if (like !== undefined && like !== null) {
            queryParameters = queryParameters.set('like', <any>like);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (tenantId !== undefined && tenantId !== null) {
            queryParameters = queryParameters.set('tenantId', <any>tenantId);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('text', <any>text);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OrganisationData>>(`${this.basePath}/api/organisations/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search organisations matching the filter
     * 
     * @param directoryAlias Directory alias
     * @param like Indicate if a &#39;like&#39; search style should be used
     * @param limit Limit
     * @param offset Last enduser id
     * @param tenantId Tenant
     * @param text Content to be filtered
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOrganisations1(directoryAlias?: string, like?: boolean, limit?: number, offset?: number, tenantId?: number, text?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationData>>;
    public searchOrganisations1(directoryAlias?: string, like?: boolean, limit?: number, offset?: number, tenantId?: number, text?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationData>>>;
    public searchOrganisations1(directoryAlias?: string, like?: boolean, limit?: number, offset?: number, tenantId?: number, text?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationData>>>;
    public searchOrganisations1(directoryAlias?: string, like?: boolean, limit?: number, offset?: number, tenantId?: number, text?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (directoryAlias !== undefined && directoryAlias !== null) {
            queryParameters = queryParameters.set('directoryAlias', <any>directoryAlias);
        }
        if (like !== undefined && like !== null) {
            queryParameters = queryParameters.set('like', <any>like);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (tenantId !== undefined && tenantId !== null) {
            queryParameters = queryParameters.set('tenantId', <any>tenantId);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('text', <any>text);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<OrganisationData>>(`${this.basePath}/api/organisations/search`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create or update the visual identity of organisation
     * 
     * @param organisationId Organisation id
     * @param payload Specific variables
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisation(organisationId: number, payload?: UpdateVisualIdentityPayload, observe?: 'body', reportProgress?: boolean): Observable<VisualIdentityData>;
    public updateOrganisation(organisationId: number, payload?: UpdateVisualIdentityPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisualIdentityData>>;
    public updateOrganisation(organisationId: number, payload?: UpdateVisualIdentityPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisualIdentityData>>;
    public updateOrganisation(organisationId: number, payload?: UpdateVisualIdentityPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisation.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VisualIdentityData>(`${this.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}/visual-identity`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
