import {Injectable} from '@angular/core';
import {AccessToken} from '../model/access-token.model';

/**
 * Helper for JWT manipulation.
 */
@Injectable()
export class JwtService {

  /**
   * Decode token and do basic validation (signature is NOT validated)
   *
   * @param token raw encoded JWT token
   */
  decodeAndValidateJwt(token: string): AccessToken {
    const parts: string[] = token ? token.split('.') : [];
    if (parts.length !== 3) {
      throw new Error('Invalid JWT: Should have three parts');
    }
    const decoded: string = atob(parts[1]);
    if (!decoded) {
      throw new Error('Invalid JWT: Cannot decode body');
    }
    const jwt: AccessToken = JSON.parse(decoded);
    jwt.raw = token;

    // Basic JWT validation: verify that required claims are present (no need for signature validation on client side)
    const MANDATORY_JWT_CLAIMS = ['sub', 'roles', 'exp'];
    const missingClaims: string[] = MANDATORY_JWT_CLAIMS.filter(claim => !jwt[claim]);
    if (missingClaims.length > 0) {
      throw new Error('Invalid JWT: Expected claims missing=' + missingClaims);
    }
    return jwt;
  }
}
