import { ElementRef } from '@angular/core';
import { FormElement } from '../form.element';
export class InputFileComponent extends FormElement {
    constructor() {
        super(...arguments);
        this.multiple = false;
        this.leaveEmptyValue = false;
    }
    onClick() {
        this.input.nativeElement.focus();
    }
    getFileListAsArray(fileList) {
        return Object.keys(fileList).map(function (k) {
            return fileList[k];
        });
    }
    // We need to clear the input upon selection, otherwise, reselection of the same file
    // won't trigger the onChange handler (hence you cannot select/delete/reselect the same file)
    clearInput() {
        const i = this.input.nativeElement;
        i.value = '';
    }
}
