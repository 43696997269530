import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

export interface NgbDateTimeStruct extends NgbDateStruct, NgbTimeStruct {
}

export class DateTimeModel implements NgbDateTimeStruct {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;

  timeZoneOffset: number;

  constructor(init?: Partial<DateTimeModel>) {
    Object.assign(this, init);
  }

  static fromLocalString(dateString: string, lang?: string): DateTimeModel {
    let date;
    switch (lang) {
      case 'fr':
      case 'FR':
        const tabDate = dateString.toString().split('/');
        if (tabDate.length > 1) {
          date = new Date(dateString.split('/').reverse().join('/'));
        } else {
          if (dateString.toString().includes('-') && !dateString.toString().includes(':')) {
            date = new Date(dateString.split('-').join('/'));
          } else {
            date = new Date(dateString);
          }
        }
        break;
      default:
        if (dateString.toString().includes('-') && !dateString.toString().includes(':')) {
          date = new Date(dateString.split('-').join('/'));
        } else {
          date = new Date(dateString);
        }
        break;
    }

    const isValidDate = !isNaN(date.valueOf());

    if (!dateString || !isValidDate) {
      return null;
    }

    return new DateTimeModel({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
      timeZoneOffset: new Date(dateString).getTimezoneOffset()
    });
  }

  private static isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }

  toString(): string {
    if (DateTimeModel.isInteger(this.year) && DateTimeModel.isInteger(this.month) && DateTimeModel.isInteger(this.day)) {
      this.adaptFunctIE();
      const year = this.year.toString().padStart(2, '0');
      const month = this.month.toString().padStart(2, '0');
      const day = this.day.toString().padStart(2, '0');

      if (!this.hour) {
        this.hour = 0;
      }
      if (!this.minute) {
        this.minute = 0;
      }
      if (!this.second) {
        this.second = 0;
      }
      if (year) {
        const date = new Date(this.year, this.month - 1, this.day, this.hour, this.minute, this.second);
        this.timeZoneOffset = date.getTimezoneOffset();
      }

      const hour = this.hour.toString().padStart(2, '0');
      const minute = this.minute.toString().padStart(2, '0');
      const second = this.second.toString().padStart(2, '0');

      const tzo = -this.timeZoneOffset;
      const dif = tzo >= 0 ? '+' : '-', pad = function(num) {
        const norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };

      const res = `${pad(year)}-${pad(month)}-${pad(day)}T${pad(hour)}:${pad(minute)}:${pad(second)}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;
      return res;
    }

    return null;
  }

  toDateString(): string {
    if (DateTimeModel.isInteger(this.year) && DateTimeModel.isInteger(this.month) && DateTimeModel.isInteger(this.day)) {
      const year = this.year.toString().padStart(2, '0');
      const month = this.month.toString().padStart(2, '0');
      const day = this.day.toString().padStart(2, '0'), pad = function(num) {
        const norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
      return `${pad(year)}-${pad(month)}-${pad(day)}`;
    }
  }

  adaptFunctIE() {
    if (!String.prototype.padStart) {
      String.prototype.padStart = function padStart(targetLength, padString) {
          targetLength = targetLength >>0;
          padString = String((typeof padString !== 'undefined' ? padString : ' '));
          if (this.length > targetLength) {
              return String(this);
          } else {
              targetLength = targetLength - this.length;
              if (targetLength > padString.length) {
                  padString += padString.repeat(targetLength / padString.length);
              }
              return padString.slice(0, targetLength) + String(this);
          }
      };
    }
  }
}
