import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';

import {WizardConfig} from '../../models/wizard.config';
import {WizardService} from '../../services/wizard.service';

@Component({
  selector: 'app-wizard', templateUrl: './wizard.component.html', styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  constructor(private route: ActivatedRoute, private wizardService: WizardService) {
  }

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe((wizardConfig: WizardConfig) => {
      this.wizardService.wizardConfig = {
        relativeTo: this.route, steps: wizardConfig.steps.sort((a, b) => a.index - b.index)
      };
    });
    this.wizardService.sessionIsTemplate = false;
  }
}
