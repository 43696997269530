export class EnumHelper {
  static toKeyValueArray(enumType: object) {
    return EnumHelper.keys(enumType)
    .map((key) => {
      return {
        key, value: enumType[key]
      };
    });
  }

  static values(enumType: object) {
    return EnumHelper.toKeyValueArray(enumType)
    .map((kv) => kv.value);
  }

  static keys(enumType: object) {
    const members = Object.keys(enumType);
    let keys: string[];
    if (!EnumHelper.isNumeral(enumType)) {
      keys = members;
    } else {
      keys = [];
      members.forEach((x) => {
        const parsedValue = parseInt(x, 10);
        if (Number.isNaN(parsedValue)) {
          keys.push(x);
        }
      });
    }
    // key of enumeration can't be number
    return keys.filter((x) => Number.isNaN(parseInt(x, 10)));
  }

  private static isNumeral(enumType: object) {
    const members = Object.keys(enumType);
    if (!members.length) {
      throw new TypeError('Invalid enumeration type.');
    }
    let parsedCount = 0;
    members.forEach((x) => {
      const parsedValue = parseInt(x, 10);
      if (!Number.isNaN(parsedValue)) {
        parsedCount++;
      }
    });
    return parsedCount === members.length / 2;
  }
}
