<ng-container *ngIf="comments">
  <ul class="list-group">
    <li class="list-group-item">

      <div (click)="handleCollapse()"
           class="header d-flex justify-content-between">
        <h5>{{'STEP.COMMENT.COMMENT_TITLE' | translate}} ({{comments.length}})</h5>
        <i [ngClass]="commentCollapsed ? 'fa-chevron-right' : 'fa-chevron-down'"
           class="fa pull-right">
        </i>
      </div>

      <div [ngbCollapse]="commentCollapsed">
        <div class="form-group">
          <div *ngFor="let item of comments" [ngClass]="{
             'rejected' : item.comment.type === CommentDataTypeEnum.REJECTION,
             'reinstate' : item.comment.type === CommentDataTypeEnum.REINSTATEMENT
             }"
               class="comment"
          >
            <div class = "row">
              <div *ngIf="item.comment.type != 'REMINDER'" class="comment-textarea">
                <p *ngIf="!item.showTextArea" [innerHTML]="item.comment.text | safeHtml" class="content"></p>
                <quill-editor (keydown)="restrictList($event)"
                              (keydown.space)="stopList()"
                              *ngIf="item.showTextArea"
                              [(ngModel)]="addedComment"
                              [modules]="quillConfig"
                              [placeholder]="'STEP.COMMENT.TYPE_COMMENT_HERE' | translate"
                              id="comment-text-add">
                </quill-editor>
              </div>
              <div class="action-buttons">
                <button (click)="editAddedComment(item)" *ngIf="!item.showTextArea && showDeleteButton(item) && checkIsCommentOwner(item) && item.comment.type == CommentDataTypeEnum.SIMPLE"
                        class="icon-button edit"><i class="fas fa-pencil-alt"></i>
                </button>
                <button (click)="editComment(item)" *ngIf="item.showTextArea && item.comment.type == CommentDataTypeEnum.SIMPLE"
                        class="icon-button edit"><i class="fas fa-check"></i>
                </button>
                <button (click)="clearComment(item)" *ngIf="item.showTextArea && item.comment.type == CommentDataTypeEnum.SIMPLE"
                        class="icon-button remove"><i class="fas fa-times"></i>
                </button>
                <button (click)="deleteComment(item)" *ngIf="showDeleteButton(item) && checkIsCommentOwner(item) &&  item.comment.type == CommentDataTypeEnum.SIMPLE"
                        class="icon-button remove"><i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>

            <div class="info">
              <ng-container [ngSwitch]="item.comment.type">
                <ng-container *ngSwitchCase="CommentDataTypeEnum.REJECTION">
                  <i aria-hidden="true" class="fa fa-times-circle fa-fw"></i>
                  <span>
                    {{'STEP.COMMENT.REJECTED_BY' | translate}}
                    {{item.enduser && (item.enduser.firstName + ' ' + item.enduser.lastName + ' ')
                  || '??? '}}
                    {{'STEP.COMMENT.ON_DATE' | translate}}
                    {{item.comment.createdOn | date: 'short' : undefined : getCurrentLang()}}
                    <b>({{item.comment.specificText}})</b>
                </span>
                </ng-container>

                <ng-container *ngSwitchCase="CommentDataTypeEnum.REINSTATEMENT">
                  <i aria-hidden="true" class="fa fa-check-circle fa-fw"></i>
                  <span>
                    {{'STEP.COMMENT.REINSTATED_BY' | translate}}
                    {{item.enduser && (item.enduser.firstName + ' ' + item.enduser.lastName + ' ')
                  || '??? '}}
                    {{'STEP.COMMENT.ON_DATE' | translate}}
                    {{item.comment.createdOn | date: 'short' : undefined : getCurrentLang()}}
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="'REMINDER'">
                  <i aria-hidden="true" class="fa fa-check-circle fa-fw"></i>
                  <span>
                    {{'STEP.COMMENT.REMINDER_SERVICE' | translate}}
                    {{'STEP.COMMENT.ON_DATE' | translate}}
                    {{item.comment.createdOn | date: 'short' : undefined : getCurrentLang()}}
                    <b>({{item.comment.specificText}})</b>
                  </span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <span>
                    {{item.enduser && (item.enduser.firstName + ' ' + item.enduser.lastName + ' ')
                  || '??? '}}
                    {{'STEP.COMMENT.ON_DATE' | translate}}
                    {{item.comment.createdOn | date: 'short' : undefined : getCurrentLang()}}
                  </span>
                </ng-container>
              </ng-container>

            </div>
          </div>
        </div>

        <div class="form-group">
          <quill-editor (keydown)="restrictList($event)"
                        (keydown.space)="stopList()"
                        [(ngModel)]="comment"
                        [placeholder]="'STEP.COMMENT.TYPE_COMMENT_HERE' | translate"
                        [modules]="quillConfig"
                        id="comment-text"></quill-editor>
          <ul *ngIf="showList">
            <li (click)="consumeList(u.enduser)" *ngFor="let u of presentStepEndusers"
                style="cursor: pointer">{{u.enduser.firstName}} {{u.enduser.lastName}}</li>
          </ul>
        </div>

        <div class="form-group">
          <button (click)="addComment()"
                  class="btn btn-primary"> {{'STEP.COMMENT.ADD_COMMENT' | translate}}</button>
        </div>
      </div>

      <div (click)="handleOtherCollapse()"
           *ngIf="otherComments?.length"
           class="header d-flex justify-content-between">
        <h5>{{'STEP.COMMENT.OTHER_COMMENT_TITLE' | translate}} ({{otherComments.length}})</h5>
        <i [ngClass]="commentOtherCollapsed ? 'fa-chevron-right' : 'fa-chevron-down'"
           class="fa pull-right">
        </i>
      </div>

      <div *ngIf="otherComments?.length" [ngbCollapse]="commentOtherCollapsed">
        <div class="form-group">
          <div *ngFor="let item of otherComments" [ngClass]="{
             'rejected' : item.comment.type === CommentDataTypeEnum.REJECTION,
             'reinstate' : item.comment.type === CommentDataTypeEnum.REINSTATEMENT
             }"
               class="comment"
          >
           <p class="content" [innerHTML]="item.comment.text | safeHtml">
                <i>{{item.comment.stepLabel}} </i>: {{item.comment.text}}
           </p>

            <div class="info">
              <ng-container [ngSwitch]="item.comment.type">
                <ng-container *ngSwitchCase="CommentDataTypeEnum.REJECTION">
                  <i aria-hidden="true" class="fa fa-times-circle fa-fw"></i>
                  <span>
                    {{'STEP.COMMENT.REJECTED_BY' | translate}}
                    {{item.enduser && (item.enduser.firstName + ' ' + item.enduser.lastName + ' ')
                  || '??? '}}
                    {{'STEP.COMMENT.ON_DATE' | translate}}
                    {{item.comment.createdOn | date: 'short' : undefined : getCurrentLang()}}
                    <b>({{item.comment.specificText}})</b>
                </span>
                </ng-container>

                <ng-container *ngSwitchCase="CommentDataTypeEnum.REINSTATEMENT">
                  <i aria-hidden="true" class="fa fa-check-circle fa-fw"></i>
                  <span>
                    {{'STEP.COMMENT.REINSTATED_BY' | translate}}
                    {{item.enduser && (item.enduser.firstName + ' ' + item.enduser.lastName + ' ')
                  || '??? '}}
                    {{'STEP.COMMENT.ON_DATE' | translate}}
                    {{item.comment.createdOn | date: 'short' : undefined : getCurrentLang()}}
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="'REMINDER'">
                  <i aria-hidden="true" class="fa fa-check-circle fa-fw"></i>
                  <span>
                    {{'STEP.COMMENT.REMINDER_SERVICE' | translate}}
                    {{'STEP.COMMENT.ON_DATE' | translate}}
                    {{item.comment.createdOn | date: 'short' : undefined : getCurrentLang()}}
                    <b>({{item.comment.specificText}})</b>
                  </span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <span>
                    {{item.enduser && (item.enduser.firstName + ' ' + item.enduser.lastName + ' ')
                  || '??? '}}
                    {{'STEP.COMMENT.ON_DATE' | translate}}
                    {{item.comment.createdOn | date: 'short' : undefined : getCurrentLang()}}
                  </span>
                </ng-container>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</ng-container>
