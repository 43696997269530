import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignDatesCheckService {

  isSignNotBefore(signNotBefore: Date): boolean {
    if (signNotBefore) {
      return this.getConvertedDateTime(signNotBefore) > this.getTodaysDate();
    }
    return false;
  }

  isSignNotAfter(signNotAfter: Date): boolean {
    if (signNotAfter) {
      return this.getConvertedDateTime(signNotAfter) < this.getTodaysDate();
    }
    return false;
  }

  getConvertedDateTime(date: Date) {
    return new Date(date).getTime();
  }

  getTodaysDate() {
    return new Date().getTime();
  }
}
