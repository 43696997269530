import {ActivatedRoute} from '@angular/router';

export interface WizardConfig {
  steps: WizardConfigStep[];
  relativeTo: ActivatedRoute;
}

export interface WizardConfigStep {
  path: string;
  label: string;
  index: number;
}

export enum StepActions {
  NEXT = 'NEXT', PREVIOUS = 'PREVIOUS'
}

export enum StepsName {
  general = 'general', documents = 'documents', users = 'users', signature = 'signature', legal = 'legal',
  metadata = 'metadata', reminders = 'reminders', notifications = 'notifications', comments = 'comments', config = 'configurator'
}
