<ng-container *ngIf="stepEndUsers">
  <div class="text-center">
    <h4 class="title">{{title}}</h4>
  </div>
  <div class="row justify-content-center mt-3">
    <app-items-table *ngIf="stepEndUsers.length === 0 || documents.length===0"
                     noResult="{{noResultFound}}">
    </app-items-table>
  </div>
  <div>
    <div *ngIf="stepEndUsers.length !==0 && documents.length !== 0">
      <table *ngFor="let stepEndUser of stepEndUsers" class="custom-table table-endusers-document">
        <tr class="table-header">
          <th>{{'WIZARD.ENDUSER.DOCUMENT' | translate}}</th>
          <th>
            {{stepEndUser.enduser.firstName + ' ' + stepEndUser.enduser.lastName}}
          </th>
          <th><span
            [ngbTooltip]="'Update only with profile change'">{{'WIZARD.ENDUSER.FORBIDRIGHTS' | translate}}</span>
          </th>
        </tr>
        <tr *ngFor="let documentData of documents" class="table-row">

          <td class="doc_title">
            <div class="doc_title_content_wrapper">
              <div [title]="documentData.name | titleCase" class="doc_title_content align_center_document">
               {{documentData.name | titleCase}}
              </div>
              <div class="doc_placeholder">
                {{'WIZARD.DOCUMENTS.ACROFORM_PLACEHOLDER' | translate}}:
              </div>
              <div class="doc_placeholder" *ngIf="stepEndUser.enduser.circle">
                  {{'WIZARD.DOCUMENTS.EXPECTED' | translate}}:
              </div>
            </div>
          </td>

          <td class="form-group">
            <div class="input-group mb-1">
              <select
                (change)="selected(stepEndUser.enduser.id, documentData.id, $event)"
                class="form-control">
                <option *ngFor="let sprofile of sprofileList"
                        [selected]="sprofile.code === sprofiles[stepEndUser.enduser.id + '-' + documentData.id]"
                        [value]="sprofile.code" [disabled]="selecting || settingAcroform">
                  {{sprofile.label | truncate:{max: 33} }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <input (change)="setAcroform(documentData.id, stepEndUser.enduser.id, $event)"
                     [value]="displayAcroform(documentData.id, stepEndUser.enduser.id)"
                     [disabled]="selecting || settingAcroform"
                     class="form-control"
                     placeholder="{{'WIZARD.DOCUMENTS.ACROFORM_ID' | translate}}"
                     type="text">
            </div>
            <div class="input-group" *ngIf="stepEndUser.enduser.circle">
              <select (change)="selected(stepEndUser.enduser.id, documentData.id, $event)"
                      [(ngModel)]="expected[stepEndUser.enduser.id + '-' + documentData.id]"
                      class="form-control"
                      [disabled]="!stepEndUser.enduser.circle || selecting || settingAcroform">
                <option *ngFor="let nb of stepEndUser.enduser.nbs"
                        [selected]="nb === expected[stepEndUser.enduser.id + '-' + documentData.id]"
                        [value]="nb">
                  {{nb}}
                </option>
              </select>
            </div>
          </td>

          <td>
            <div class="toggle-items">
              <div class="row m1-0">
                <div class="col-md-6 text-left">
                  <div class="switcher switcher-bottom ml-0 p-0">
                    <label
                      [for]="'forbid-download-' + stepEndUser.enduser.id + documentData.id">{{'WIZARD.ENDUSER.DOWNLOAD' | translate}}</label>
                    <input
                      (change)="selected(stepEndUser.enduser.id, documentData.id)"
                      [(ngModel)]="forbidDownloads[stepEndUser.enduser.id + '-' + documentData.id]"
                      [checked]="forbidDownloads[stepEndUser.enduser.id + '-' + documentData.id]"
                      [id]="'forbid-download-' + stepEndUser.enduser.id + documentData.id"
                      [disabled]="selecting || settingAcroform"
                      class="switcher-checkbox"
                      type="checkbox">
                    <div class="switcher-toggle" [class.disabled]="selecting || settingAcroform"></div>
                  </div>
                </div>
                <div class="col-md-6  text-left">
                  <div class="switcher switcher-bottom ml-0 p-0">
                    <label
                      [for]="'forbid-view-' + stepEndUser.enduser.id + documentData.id">{{'WIZARD.ENDUSER.VIEW' | translate}}</label>
                    <input (change)="selected(stepEndUser.enduser.id, documentData.id)"
                           [(ngModel)]="forbidViews[stepEndUser.enduser.id + '-' + documentData.id]"
                           [checked]="forbidViews[stepEndUser.enduser.id + '-' + documentData.id]"
                           [id]="'forbid-view-' + stepEndUser.enduser.id + documentData.id"
                           [disabled]="selecting || settingAcroform"
                           class="switcher-checkbox"
                           type="checkbox">
                    <div class="switcher-toggle" [class.disabled]="selecting || settingAcroform"></div>
                  </div>
                </div>
              </div>
              <div class="row m1-0">
                <div class="col-md-6 text-left">
                  <div class="switcher switcher-bottom ml-0 p-0">
                    <label
                      [for]="'forbid-print-' + stepEndUser.enduser.id + documentData.id">{{'WIZARD.ENDUSER.PRINT' | translate}}</label>
                    <input (change)="selected(stepEndUser.enduser.id, documentData.id)"
                           [(ngModel)]="forbidPrints[stepEndUser.enduser.id + '-' + documentData.id]"
                           [checked]="forbidPrints[stepEndUser.enduser.id + '-' + documentData.id]"
                           [id]="'forbid-print-' + stepEndUser.enduser.id + documentData.id"
                           [disabled]="selecting || settingAcroform"
                           class="switcher-checkbox"
                           type="checkbox">
                    <div class="switcher-toggle" [class.disabled]="selecting || settingAcroform"></div>
                  </div>
                </div>
                <div class="col-md-6 text-left">
                  <div class="switcher switcher-bottom ml-0 p-0 switchRight">
                    <label
                      [for]="'bring-to-step-' + stepEndUser.enduser.id + documentData.id">{{'WIZARD.ENDUSER.TOSTEP' | translate}}</label>
                    <input (change)="selected(stepEndUser.enduser.id, documentData.id)"
                           [(ngModel)]="bringsToStep[stepEndUser.enduser.id + '-' + documentData.id]"
                           [checked]="bringsToStep[stepEndUser.enduser.id + '-' + documentData.id]"
                           [id]="'bring-to-step-' + stepEndUser.enduser.id + documentData.id"
                           [disabled]="selecting || settingAcroform"
                           class="switcher-checkbox"
                           type="checkbox">
                    <div class="switcher-toggle" [class.disabled]="selecting || settingAcroform"></div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</ng-container>
