/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CountData } from '../model/countData';
import { DeleteLabelPayload } from '../model/deleteLabelPayload';
import { LabelData } from '../model/labelData';
import { LabelPayload } from '../model/labelPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LabelService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create label
     * 
     * @param payload Label to create or update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLabel(payload?: LabelPayload, observe?: 'body', reportProgress?: boolean): Observable<LabelData>;
    public createLabel(payload?: LabelPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelData>>;
    public createLabel(payload?: LabelPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelData>>;
    public createLabel(payload?: LabelPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LabelData>(`${this.basePath}/api/labels`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete label
     * 
     * @param payload Label to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLabel(payload?: DeleteLabelPayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteLabel(payload?: DeleteLabelPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteLabel(payload?: DeleteLabelPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteLabel(payload?: DeleteLabelPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<any>(`${this.basePath}/api/labels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of labels
     * 
     * @param limit Number of rows to return from the offset
     * @param localeCode Language code
     * @param offset Offset of the first row to return
     * @param onlyMissing Whether to only include results on which at least one language is missing
     * @param targetIds List of target ids
     * @param type Target type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findLabelList(limit?: number, localeCode?: 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP', offset?: number, onlyMissing?: boolean, targetIds?: Array<number>, type?: 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME', observe?: 'body', reportProgress?: boolean): Observable<Array<LabelData>>;
    public findLabelList(limit?: number, localeCode?: 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP', offset?: number, onlyMissing?: boolean, targetIds?: Array<number>, type?: 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LabelData>>>;
    public findLabelList(limit?: number, localeCode?: 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP', offset?: number, onlyMissing?: boolean, targetIds?: Array<number>, type?: 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LabelData>>>;
    public findLabelList(limit?: number, localeCode?: 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP', offset?: number, onlyMissing?: boolean, targetIds?: Array<number>, type?: 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (localeCode !== undefined && localeCode !== null) {
            queryParameters = queryParameters.set('localeCode', <any>localeCode);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (onlyMissing !== undefined && onlyMissing !== null) {
            queryParameters = queryParameters.set('onlyMissing', <any>onlyMissing);
        }
        if (targetIds) {
            targetIds.forEach((element) => {
                queryParameters = queryParameters.append('targetIds', <any>element);
            })
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LabelData>>(`${this.basePath}/api/labels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get count of list of labels
     * 
     * @param limit Number of rows to return from the offset
     * @param localeCode Language code
     * @param offset Offset of the first row to return
     * @param onlyMissing Whether to only include results on which at least one language is missing
     * @param targetIds List of target ids
     * @param type Target type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findLabelListCount(limit?: number, localeCode?: 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP', offset?: number, onlyMissing?: boolean, targetIds?: Array<number>, type?: 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME', observe?: 'body', reportProgress?: boolean): Observable<CountData>;
    public findLabelListCount(limit?: number, localeCode?: 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP', offset?: number, onlyMissing?: boolean, targetIds?: Array<number>, type?: 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountData>>;
    public findLabelListCount(limit?: number, localeCode?: 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP', offset?: number, onlyMissing?: boolean, targetIds?: Array<number>, type?: 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountData>>;
    public findLabelListCount(limit?: number, localeCode?: 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP', offset?: number, onlyMissing?: boolean, targetIds?: Array<number>, type?: 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (localeCode !== undefined && localeCode !== null) {
            queryParameters = queryParameters.set('localeCode', <any>localeCode);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (onlyMissing !== undefined && onlyMissing !== null) {
            queryParameters = queryParameters.set('onlyMissing', <any>onlyMissing);
        }
        if (targetIds) {
            targetIds.forEach((element) => {
                queryParameters = queryParameters.append('targetIds', <any>element);
            })
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CountData>(`${this.basePath}/api/labels/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update label
     * 
     * @param payload Label to create or update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLabel(payload?: LabelPayload, observe?: 'body', reportProgress?: boolean): Observable<LabelData>;
    public updateLabel(payload?: LabelPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelData>>;
    public updateLabel(payload?: LabelPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelData>>;
    public updateLabel(payload?: LabelPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LabelData>(`${this.basePath}/api/labels`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
