<app-tooltip [tooltipText]="'HELP.DASHBOARD' | translate"></app-tooltip>
<app-tutoriel (launchTutoEmit) = "launchTuto()" ></app-tutoriel>
<app-app-download-pdf></app-app-download-pdf>
<div class="container d-flex justify-content-center justify-content-sm-end flex-wrap">
</div>

<div class="fixed-container mb20 mt10">
  <div class="left w2 marginDashboard">
    <session-search (filterChangedEvent)="filterChange($event)"
                    (templateChanged)="templateChange($event)"
                    (listSessionChanged)="onSearch()"
                    [searchSession]="searchSession"
                    [sessionFilter]="sessionFilter">
    </session-search>
  </div>

  <div class="center w4 marginDashboard">
    <app-status-counter [reset]="reset" (filter)="onStatusFilter($event)"></app-status-counter>
    <div class="top-btns">

      <!-- Template -->
      <button (click)="createSessionFromTemplate()"
              *ngIf="appService.userHasEntitlement(appService.ent.create_from_template)"
              class="btn btn-primary ml-2 mb-2 float-right"
              id="template"
              type="button">
        {{'DASHBOARD.CREATE_SESSION_TEMPLATE' | translate}}
      </button>

      <!-- New advanced -->
      <button (click)="createSession()"
              *ngIf="appService.userHasEntitlement(appService.ent.create_standalone) &&
              !appService.userHasEntitlement(appService.ent.forbid_wizard)"
              class="btn btn-primary ml-2 mb-2 float-right"
              id="adv-session"
              type="button">
        {{'DASHBOARD.CREATE_SESSION_FROM_WIZARD' | translate}}
      </button>

      <!-- Fast session -->
      <button (click)="createFastSession()" *ngIf="appService.userHasEntitlement(appService.ent.create_standalone)"
              [queryParamsHandling]="'preserve'" [routerLink]="['/new-session']"
              class="btn btn-primary ml-2 mb-2 float-right"
              id="createSessionStep1">
        {{'DASHBOARD.CREATE_SESSION' | translate}}
      </button>

    </div>

    <div class="mb-4" id="visiteDashboardStep1">
      <app-table-dashboard (deleteSession)="deleteSession($event)"
                           (disableSession)="disableSession($event)"
                           (openSession)="goToSession($event)"
                           [disabled]="deletingSession || editingSession"
                           [additionalSessions]="sessionsAdditionalDatas"
                           [sessions]="sessions">
      </app-table-dashboard>
    </div>

    <!-- Pagination -->
    <ngb-pagination (pageChange)="pageChange($event)"
                    *ngIf="collectionSize>limit"
                    [(page)]="page"
                    [boundaryLinks]="true"
                    [collectionSize]="collectionSize"
                    [maxSize]="paginateMaxSize"
                    [pageSize]="limit"
                    [rotate]="true">
      <ng-template ngbPaginationFirst>&laquo;</ng-template>
      <ng-template ngbPaginationLast>&raquo;</ng-template>
      <ng-template ngbPaginationPrevious>&lsaquo;</ng-template>
      <ng-template ngbPaginationNext>&rsaquo;</ng-template>
    </ngb-pagination>

    <ngx-spinner class="fixed-spinner"
                 size="large"
                 type="ball-clip-rotate">
      <p style="color: white">{{'NEW_SESSION.SPINNER_TEXT' | translate}}</p>
    </ngx-spinner>
  </div>
</div>

