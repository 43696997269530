/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-templates-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/components/template-editor/template-editor.component.ngfactory";
import * as i4 from "../../../../../shared/components/template-editor/template-editor.component";
import * as i5 from "../../../../../core/pipes/translate.pipe";
import * as i6 from "../../../../../core/service/translate.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i9 from "./notification-templates-editor.component";
import * as i10 from "../../../../../../../luxtrust-cosi-api/api/pattern.service";
import * as i11 from "../../../../../services/services/alert-service";
import * as i12 from "../../../../../services/services/ie.service";
var styles_NotificationTemplatesEditorComponent = [i0.styles];
var RenderType_NotificationTemplatesEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationTemplatesEditorComponent, data: {} });
export { RenderType_NotificationTemplatesEditorComponent as RenderType_NotificationTemplatesEditorComponent };
function View_NotificationTemplatesEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "tab-title"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "current-changed": 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tab-title"; var currVal_1 = _ck(_v, 3, 0, _co.checkIfChanges(_v.parent.context.$implicit.value)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.key; _ck(_v, 4, 0, currVal_2); }); }
function View_NotificationTemplatesEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-template-editor", [], null, null, null, i3.View_TemplateEditorComponent_0, i3.RenderType_TemplateEditorComponent)), i1.ɵdid(2, 49152, null, 0, i4.TemplateEditorComponent, [], { displayCloseButton: [0, "displayCloseButton"], patternData: [1, "patternData"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-danger"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete(_v.parent.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i1.ChangeDetectorRef, i6.TranslateService]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave(_v.parent.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i1.ChangeDetectorRef, i6.TranslateService]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i1.ChangeDetectorRef, i6.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.getPatternByTagValue(_v.parent.parent.context.$implicit.value, _co.patterns); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.removingPattern || _co.savingPattern); _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("MODAL_TEMPLATE_BUILDER.DELETE")); _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.canNotSave(_v.parent.parent.context.$implicit.value); var currVal_5 = (_co.removingPattern || _co.savingPattern); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("MODAL_TEMPLATE_BUILDER.SAVE")); _ck(_v, 8, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("MODAL_TEMPLATE_BUILDER.CLOSE")); _ck(_v, 11, 0, currVal_7); }); }
function View_NotificationTemplatesEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "space-filler"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i1.ChangeDetectorRef, i6.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.findBestSuitedTemplateForTagValue(_v.parent.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i1.ChangeDetectorRef, i6.TranslateService]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i1.ChangeDetectorRef, i6.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("WIZARD.NOTIFICATIONS.TEMPLATE.NOT_FOUND")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.creatingPattern; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("WIZARD.NOTIFICATIONS.TEMPLATE.FIND")); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("MODAL_TEMPLATE_BUILDER.CLOSE")); _ck(_v, 9, 0, currVal_3); }); }
function View_NotificationTemplatesEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationTemplatesEditorComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noTemplate", 2]], null, 0, null, View_NotificationTemplatesEditorComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPatternByTagValue(_v.parent.context.$implicit.value, _co.patterns); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_NotificationTemplatesEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ngb-tab", [], null, null, null, null, null)), i1.ɵdid(1, 2113536, [[1, 4]], 2, i7.NgbTab, [], null, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_NotificationTemplatesEditorComponent_2)), i1.ɵdid(5, 16384, [[2, 4]], 0, i7.NgbTabTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_NotificationTemplatesEditorComponent_3)), i1.ɵdid(7, 16384, [[3, 4]], 0, i7.NgbTabContent, [i1.TemplateRef], null, null)], null, null); }
export function View_NotificationTemplatesEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngb-tabset", [["class", "nav-fill"]], null, null, null, i8.View_NgbTabset_0, i8.RenderType_NgbTabset)), i1.ɵdid(1, 2146304, null, 1, i7.NgbTabset, [i7.NgbTabsetConfig], null, null), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationTemplatesEditorComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.TAG_VALUES; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_NotificationTemplatesEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotificationTemplatesEditorComponent_0, RenderType_NotificationTemplatesEditorComponent)), i1.ɵdid(1, 49152, null, 0, i9.NotificationTemplatesEditorComponent, [i7.NgbActiveModal, i10.PatternService, i11.AlertService, i6.TranslateService, i7.NgbModal, i12.IEService], null, null)], null, null); }
var NotificationTemplatesEditorComponentNgFactory = i1.ɵccf("ng-component", i9.NotificationTemplatesEditorComponent, View_NotificationTemplatesEditorComponent_Host_0, {}, {}, []);
export { NotificationTemplatesEditorComponentNgFactory as NotificationTemplatesEditorComponentNgFactory };
