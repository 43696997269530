import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StepActions} from '../../models/wizard.config';
import {WizardService} from '../../services/wizard.service';

@Component({
  selector: 'app-wizard-step-button', templateUrl: './wizard-step-button.component.html', styleUrls: ['./wizard-step-button.component.scss']
})
export class WizardStepButtonComponent {

  @Input() label: string;
  @Input() action: StepActions;
  @Input() disabled: boolean;
  @Output() onAction: EventEmitter<StepActions> = new EventEmitter();

  StepActions = StepActions;

  constructor(private wizardService: WizardService) {
  }

  handleNextStepButton() {
    this.wizardService.nextStep();
    this.onAction.emit(StepActions.NEXT);
  }

  handlePreviousStepButton() {
    this.wizardService.previousStep();
    this.onAction.emit(StepActions.PREVIOUS);
  }
}
