/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pdf-viewer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../annotation-viewer/annotation-viewer.component.ngfactory";
import * as i3 from "../annotation-viewer/annotation-viewer.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../spinner/spinner.component.ngfactory";
import * as i6 from "../spinner/spinner.component";
import * as i7 from "../../../core/pipes/translate.pipe";
import * as i8 from "../../../core/service/translate.service";
import * as i9 from "@angular/common";
import * as i10 from "./pdf-viewer.component";
import * as i11 from "../../../services/services/app.service";
import * as i12 from "../../../../../luxtrust-cosi-api/api/watermark.service";
import * as i13 from "../../../../../luxtrust-cosi-api/api/document.service";
import * as i14 from "../../../services/services/alert-service";
import * as i15 from "../../../../../luxtrust-cosi-api/api/placeholder.service";
var styles_PdfViewerComponent = [i0.styles];
var RenderType_PdfViewerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PdfViewerComponent, data: {} });
export { RenderType_PdfViewerComponent as RenderType_PdfViewerComponent };
function View_PdfViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-4 p-0 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-annotation-viewer", [], null, [[null, "loadPage"], ["window", "message"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).annotationEventListener($event) !== false);
        ad = (pd_0 && ad);
    } if (("loadPage" === en)) {
        var pd_1 = (_co.loadPage($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AnnotationViewerComponent_0, i2.RenderType_AnnotationViewerComponent)), i1.ɵdid(2, 114688, null, 0, i3.AnnotationViewerComponent, [i4.NgbModal], { annotations: [0, "annotations"] }, { loadPage: "loadPage" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.annotations; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PdfViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i6.SpinnerComponent, [], null, null)], null, null); }
function View_PdfViewerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("STEP.NO_DOCUMENT")); _ck(_v, 1, 0, currVal_0); }); }
export function View_PdfViewerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { documentContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "mb-3 row px-3 full-size"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "offset-lg-4 col-lg-4 col-sm-12 text-center p-0 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "mr-2 btn btn-secondary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadPreviousPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn btn-secondary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadNextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewerComponent_1)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "signature-pad_"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewerComponent_2)), i1.ɵdid(13, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewerComponent_3)), i1.ɵdid(15, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, [[1, 0], ["documentContainer", 1]], null, 0, "div", [["class", "signature-pad--body_"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.disabledAnnotation; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.isLoading; _ck(_v, 13, 0, currVal_5); var currVal_6 = (!_co.isLoading && !_co.pdfBlob); _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldDisablePrevious(); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("DOCUMENT.PREVIOUS_PAGE")); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.shouldDisableNext(); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("DOCUMENT.NEXT_PAGE")); _ck(_v, 7, 0, currVal_3); }); }
export function View_PdfViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-viewer", [], null, null, null, View_PdfViewerComponent_0, RenderType_PdfViewerComponent)), i1.ɵdid(1, 638976, null, 0, i10.PdfViewerComponent, [i11.AppService, i4.NgbModal, i12.WatermarkService, i13.DocumentService, i14.AlertService, i15.PlaceholderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PdfViewerComponentNgFactory = i1.ɵccf("app-pdf-viewer", i10.PdfViewerComponent, View_PdfViewerComponent_Host_0, { forbidDownload: "forbidDownload", forbidReUpload: "forbidReUpload", sessionId: "sessionId", stepId: "stepId", workflows: "workflows", document: "document", annotations: "annotations", askPosition: "askPosition", disabledAnnotation: "disabledAnnotation", disabledDownload: "disabledDownload", action: "action", fileToUpload: "fileToUpload" }, { changePage: "changePage", init: "init", dataOnDocument: "dataOnDocument" }, []);
export { PdfViewerComponentNgFactory as PdfViewerComponentNgFactory };
