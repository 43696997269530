<div class="modal-directories">
  <div class="modal-header">
    <div class="modal-title">{{'USER.DEFAULT_DIRECTORY'| translate}}</div>
    <button (click)="onClose.emit();activeModal.close('Cross click')"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <table class="custom-table table-directories">
    <tr class="table-header">
      <th>{{'USER.ENTITLEMENT' | translate}}</th>
      <th>{{'USER.ACCESS' | translate}}</th>
    </tr>
    <tr *ngFor="let ent of tenantDirectories; let i = index" class="table-row">
      <td class="table-row-i1">
        {{ent}} <i *ngIf="ent === defaultDirectory" class="fas fa-check"></i>
      </td>
      <td class="table-row-i2">
        <div [ngClass]="{'disabled-switcher': (ent === defaultDirectory) && (activeDirectories.length > 1)}"
             class="switcher switcher-right">
          <label for="switch-dir-{{i}}"></label>
          <input (change)="onChange($event.target.checked, ent)"
                 [checked]="isInActiveArray(ent)"
                 [disabled]="(ent === defaultDirectory) && (activeDirectories.length > 1) || disabled"
                 class="switcher-checkbox"
                 id="switch-dir-{{i}}"
                 type="checkbox">
          <div class="switcher-toggle" [class.disabled]="disabled"></div>
        </div>
      </td>
    </tr>
  </table>
</div>

