/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Document to be created
 */
export interface CreateDocumentPayload { 
    /**
     * Source document id
     */
    sourceDocumentId?: number;
    /**
     * Signature profile linked to the document
     */
    sprofileCode?: string;
    /**
     * Alias of the connector where the document is stored, if null the default alias is used
     */
    storageAlias?: string;
    /**
     * Identifier of the data file within the storage
     */
    storageDocumentId?: string;
    typology?: CreateDocumentPayload.TypologyEnum;
}
export namespace CreateDocumentPayload {
    export type TypologyEnum = 'ORIGINAL' | 'SIGNATURE' | 'MUTUAL' | 'TO_VIEW' | 'SPROFILE';
    export const TypologyEnum = {
        ORIGINAL: 'ORIGINAL' as TypologyEnum,
        SIGNATURE: 'SIGNATURE' as TypologyEnum,
        MUTUAL: 'MUTUAL' as TypologyEnum,
        TOVIEW: 'TO_VIEW' as TypologyEnum,
        SPROFILE: 'SPROFILE' as TypologyEnum
    };
}
