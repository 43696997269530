/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortingFilter } from './sortingFilter';


/**
 * Signature book filter (null values ignored)
 */
export interface SignatureBookFilter { 
    /**
     * Ordered by date or default order if null
     */
    asc?: boolean;
    /**
     * Document status
     */
    documentStatus?: SignatureBookFilter.DocumentStatusEnum;
    /**
     * Number of rows to return from the offset
     */
    limit?: number;
    /**
     * Offset of the first row to return
     */
    offset?: number;
    /**
     * Priority
     */
    priority?: number;
    /**
     * List of session identifiers
     */
    sessionIds?: Array<number>;
    /**
     * List of field used to order the data
     */
    sortingFilters?: Array<SortingFilter>;
    /**
     * Document name to be filtered
     */
    text?: string;
}
export namespace SignatureBookFilter {
    export type DocumentStatusEnum = 'STARTED' | 'SIGNED' | 'DECLINED';
    export const DocumentStatusEnum = {
        STARTED: 'STARTED' as DocumentStatusEnum,
        SIGNED: 'SIGNED' as DocumentStatusEnum,
        DECLINED: 'DECLINED' as DocumentStatusEnum
    };
}
