<div class="modal-header">
  <h4 class="modal-title">{{'HEADER.SETTING_PREFERENCES' | translate}}</h4>
  <button id="tutoButtonId" (click)="launchTuto().subscribe()" class="tutoButton" >
    <i class="fas fa-book-open"></i>
  </button>
  <button (click)="activeModal.dismiss()"
          aria-label="Close"
          class="close"
          type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="eventNotificationsForm" class="modal-form">
    <div class="form-group form-top">
      <app-switcher [label]="'EVENTNOTIFICATIONS.SIGNATURE_BOOK' | translate"
                    formControlName="autoSignatureBook"></app-switcher>
    </div>
    <table class="table table-borderless">
      <thead >
      <tr  id="visitPrefStep1">
        <th scope="col">{{'EVENTNOTIFICATIONS.EVENTS' | translate}}</th>
        <th scope="col">{{'EVENTNOTIFICATIONS.MAIL' | translate}}</th>
        <th scope="col">{{'EVENTNOTIFICATIONS.SMS' | translate}}</th>
        <th scope="col">{{'EVENTNOTIFICATIONS.APP' | translate}}</th>
      </tr>
      </thead>
      <tbody formArrayName="eventNotificationTypes">
      <tr *ngFor="let notification of eventNotificationTypesArray.controls; index as i"
          [formGroupName]="i">
        <td >{{notification.value.label}}</td>
        <td>
          <app-switcher formControlName="mail"></app-switcher>
        </td>
        <td>
          <app-switcher formControlName="sms"></app-switcher>
        </td>
        <td>
          <app-switcher formControlName="fetch"></app-switcher>
        </td>
      </tr>
      </tbody>
    </table>
  </form>
</div>
<div class="modal-footer">
  <button (click)="submitNotifications()"
          [disabled]="submitted"
          class="btn btn-primary"
          type="submit">{{'MODAL_CREATE_LEGAL_MOTION.SUBMIT' | translate}}</button>
  <button (click)="activeModal.dismiss()"
          class="btn btn-outline-secondary"
          type="button">{{'MODAL_CREATE_LEGAL_MOTION.CANCEL' | translate}}</button>
</div>
