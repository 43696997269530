import { Router } from '@angular/router';
import { AppService } from '../services/app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/app.service";
export class NotAuthGuard {
    constructor(router, appService) {
        this.router = router;
        this.appService = appService;
    }
    canActivate(route, state) {
        if (!this.appService.get()) {
            const tenantNameQueryParam = route.queryParamMap.get('tenantName');
            const tenantNameParam = route.paramMap.get('tenantName');
            if (tenantNameQueryParam || tenantNameParam) {
                return true;
            }
            else {
                this.router.navigate(['/no-tenant-name']);
                return false;
            }
        }
        this.router.navigate(['/']);
        return false;
    }
}
NotAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotAuthGuard_Factory() { return new NotAuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppService)); }, token: NotAuthGuard, providedIn: "root" });
