import { Router } from '@angular/router';
import { AppService } from '../services/app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/app.service";
export class AuthGuard {
    constructor(router, appService) {
        this.router = router;
        this.appService = appService;
    }
    canActivate(route, state) {
        if (this.appService.isLoggedIn()) {
            if (this.urlToReplace) {
                this.router.navigate([this.urlToReplace]);
                this.urlToReplace = null;
            }
            return true;
        }
        if (route.fragment) {
            this.appService.refresh(route.fragment);
            this.router.navigate([state.url.replace(/[?#].*$/, '')]);
            return true;
        }
        this.urlToReplace = state.url.replace(/[?#].*$/, '');
        if (route.queryParams['tenantName']) {
            if (route.routeConfig.path === 'reset') {
                return true;
            }
            else {
                this.router.navigateByUrl(`/login?tenantName=${route.queryParams['tenantName']}`);
            }
        }
        else {
            this.router.navigateByUrl(`/no-tenant-name`);
        }
        return false;
    }
}
AuthGuard.URL_AUTH_SPECIFIC = '/auth';
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppService)); }, token: AuthGuard, providedIn: "root" });
