/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CloneDocumentPayload } from '../model/cloneDocumentPayload';
import { CreateDocumentPayload } from '../model/createDocumentPayload';
import { DocumentData } from '../model/documentData';
import { UpdateDocumentPayload } from '../model/updateDocumentPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Clone document
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param payload Related payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneDocument(documentId: number, sessionId: number, stepId: number, payload?: CloneDocumentPayload, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public cloneDocument(documentId: number, sessionId: number, stepId: number, payload?: CloneDocumentPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public cloneDocument(documentId: number, sessionId: number, stepId: number, payload?: CloneDocumentPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public cloneDocument(documentId: number, sessionId: number, stepId: number, payload?: CloneDocumentPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling cloneDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling cloneDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling cloneDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/clone/${encodeURIComponent(String(documentId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Clone a document, and upload content (equivalent to create a doc from an existing one)
     * 
     * @param documentId Existing document on which base the upload
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param file File to be uploaded
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneUploadDocument(documentId: number, sessionId: number, stepId: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public cloneUploadDocument(documentId: number, sessionId: number, stepId: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public cloneUploadDocument(documentId: number, sessionId: number, stepId: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public cloneUploadDocument(documentId: number, sessionId: number, stepId: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling cloneUploadDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling cloneUploadDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling cloneUploadDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<string>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/clone-upload/${encodeURIComponent(String(documentId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create document
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the signature profiles will belong
     * @param payload Document to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocument(sessionId: number, stepId: number, payload?: CreateDocumentPayload, observe?: 'body', reportProgress?: boolean): Observable<DocumentData>;
    public createDocument(sessionId: number, stepId: number, payload?: CreateDocumentPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentData>>;
    public createDocument(sessionId: number, stepId: number, payload?: CreateDocumentPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentData>>;
    public createDocument(sessionId: number, stepId: number, payload?: CreateDocumentPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling createDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DocumentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete document and its parents
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocument(documentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDocument(documentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDocument(documentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDocument(documentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling deleteDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling deleteDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download document
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadDocument(documentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadDocument(documentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadDocument(documentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadDocument(documentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling downloadDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling downloadDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling downloadDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/download/${encodeURIComponent(String(documentId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get available mutual documents
     * 
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDocuments(sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentData>>;
    public getAllDocuments(sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentData>>>;
    public getAllDocuments(sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentData>>>;
    public getAllDocuments(sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getAllDocuments.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getAllDocuments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DocumentData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/mutual`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get document
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocument(documentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<DocumentData>;
    public getDocument(documentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentData>>;
    public getDocument(documentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentData>>;
    public getDocument(documentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of documents belonging to the step
     * 
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentList(sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentData>>;
    public getDocumentList(sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentData>>>;
    public getDocumentList(sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentData>>>;
    public getDocumentList(sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getDocumentList.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getDocumentList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DocumentData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get or create and get the mutual document
     * 
     * @param documentId Document for which the mutual document will be created
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMutualDocument(documentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<DocumentData>;
    public getMutualDocument(documentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentData>>;
    public getMutualDocument(documentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentData>>;
    public getMutualDocument(documentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getMutualDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getMutualDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getMutualDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<DocumentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/mutual/${encodeURIComponent(String(documentId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update document
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param payload Document data to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateDocument(documentId: number, sessionId: number, stepId: number, payload?: UpdateDocumentPayload, observe?: 'body', reportProgress?: boolean): Observable<DocumentData>;
    public partialUpdateDocument(documentId: number, sessionId: number, stepId: number, payload?: UpdateDocumentPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentData>>;
    public partialUpdateDocument(documentId: number, sessionId: number, stepId: number, payload?: UpdateDocumentPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentData>>;
    public partialUpdateDocument(documentId: number, sessionId: number, stepId: number, payload?: UpdateDocumentPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling partialUpdateDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling partialUpdateDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling partialUpdateDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<DocumentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reference document
     * 
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param documentName File local name
     * @param storageAlias Storage alias
     * @param storageDocumentId File to be referenced, null to keep a placeholder for templates
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public referenceDocument(sessionId: number, stepId: number, documentName?: string, storageAlias?: string, storageDocumentId?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentData>;
    public referenceDocument(sessionId: number, stepId: number, documentName?: string, storageAlias?: string, storageDocumentId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentData>>;
    public referenceDocument(sessionId: number, stepId: number, documentName?: string, storageAlias?: string, storageDocumentId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentData>>;
    public referenceDocument(sessionId: number, stepId: number, documentName?: string, storageAlias?: string, storageDocumentId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling referenceDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling referenceDocument.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentName !== undefined && documentName !== null) {
            queryParameters = queryParameters.set('documentName', <any>documentName);
        }
        if (storageAlias !== undefined && storageAlias !== null) {
            queryParameters = queryParameters.set('storageAlias', <any>storageAlias);
        }
        if (storageDocumentId !== undefined && storageDocumentId !== null) {
            queryParameters = queryParameters.set('storageDocumentId', <any>storageDocumentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<DocumentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/reference`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Re-upload document
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param file File to be uploaded
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reuploadDocument(documentId: number, sessionId: number, stepId: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public reuploadDocument(documentId: number, sessionId: number, stepId: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public reuploadDocument(documentId: number, sessionId: number, stepId: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public reuploadDocument(documentId: number, sessionId: number, stepId: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling reuploadDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling reuploadDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling reuploadDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.put<string>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/upload/${encodeURIComponent(String(documentId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete document and its parent only if not involved in other steps (MUTUAL)
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public softDeleteDocument(documentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public softDeleteDocument(documentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public softDeleteDocument(documentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public softDeleteDocument(documentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling softDeleteDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling softDeleteDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling softDeleteDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/soft`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update document
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param payload Document data to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocument(documentId: number, sessionId: number, stepId: number, payload?: UpdateDocumentPayload, observe?: 'body', reportProgress?: boolean): Observable<DocumentData>;
    public updateDocument(documentId: number, sessionId: number, stepId: number, payload?: UpdateDocumentPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentData>>;
    public updateDocument(documentId: number, sessionId: number, stepId: number, payload?: UpdateDocumentPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentData>>;
    public updateDocument(documentId: number, sessionId: number, stepId: number, payload?: UpdateDocumentPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling updateDocument.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DocumentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload document
     * 
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the document belongs
     * @param file File to be uploaded
     * @param storageAlias Storage alias
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadDocument(sessionId: number, stepId: number, file?: Blob, storageAlias?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentData>;
    public uploadDocument(sessionId: number, stepId: number, file?: Blob, storageAlias?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentData>>;
    public uploadDocument(sessionId: number, stepId: number, file?: Blob, storageAlias?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentData>>;
    public uploadDocument(sessionId: number, stepId: number, file?: Blob, storageAlias?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling uploadDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling uploadDocument.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (storageAlias !== undefined && storageAlias !== null) {
            queryParameters = queryParameters.set('storageAlias', <any>storageAlias);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<DocumentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
