import {Injectable} from '@angular/core';
import { Subscription } from 'rxjs';
import { EventBus } from 'src/app/core/pipes/eventBus';

@Injectable({
    providedIn: 'root'
})
export class NotifierService {
    private readonly _detectObs2: EventBus<string> = new EventBus<string>();
    private _isDetected = false;
    constructor() {
     }

    public get isDetected() {
        return this._isDetected;
    }
    public set isDetected(value) {
        if (value !== this._isDetected) {
            this._isDetected = value;
        }
    }

    public notifyDetectUpdatePage(actualPage: string) {
        this._detectObs2.publish(actualPage);
    }

    public subscribeToDetectPage(callback: (_actualPage: string) => void): Subscription {
        return this._detectObs2.subscribe(callback);
    }

}
