import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {StepLegalEnduserService} from 'luxtrust-cosi-api/api/stepLegalEnduser.service';
import {StepLegalEnduserData} from 'luxtrust-cosi-api/model/stepLegalEnduserData';
import {take} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';

@Component({
  selector: 'app-legal-motion-check-list',
  templateUrl: './legal-motion-check-list.component.html',
  styleUrls: ['./legal-motion-check-list.component.scss']
})
export class LegalMotionCheckListComponent implements OnChanges {

  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() legalMotions: StepLegalEnduserData[] = [];
  @Input() isSigner: boolean;
  @Input() alertWhenFilled: boolean;
  @Input() alreadySigned: boolean;

  @Output('mandatoriesChecked') checked: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  legalMotionsCheckedCounter = 0;
  legalMotionsMandatoryTotal = 0;

  optionalLegalMotion: StepLegalEnduserData[] = [];
  mandatoryLegalMotion: StepLegalEnduserData[] = [];

  constructor(
    private stepEnduserService: StepEnduserService,
    private stepLegalEnduserService: StepLegalEnduserService,
    private alertService: AlertService
  ) {}

  ngOnChanges() {
    this.legalMotionsCheckedCounter = 0;

    this.mandatoryLegalMotion = this.legalMotions.filter(legalMotion => legalMotion.stepLegal.mandatory);
    this.legalMotionsMandatoryTotal = this.mandatoryLegalMotion.length;

    this.optionalLegalMotion = this.legalMotions.filter(legalMotion => !legalMotion.stepLegal.mandatory);

    this.mandatoryLegalMotion.forEach((legalMotion: StepLegalEnduserData) => {
      if (legalMotion.checked) {
        this.legalMotionsCheckedCounter++;
      }
    });

    if (this.legalMotionsCheckedCounter === this.legalMotionsMandatoryTotal) {
      this.checked.emit(true);
    } else {
      this.checked.emit(false);
    }
  }

  check(stepLegalEnduser: StepLegalEnduserData) {
    this.stepLegalEnduserService.updateStepLegalEnduser(stepLegalEnduser.stepLegal.legal.id, this.sessionId, this.stepId, {
      check: stepLegalEnduser.checked
    }).pipe(take(1)).toPromise().catch((error: ApiError) => this.alertService.errorApi(error));

    if (this.alertWhenFilled && stepLegalEnduser.stepLegal.mandatory) {
      this.legalMotionsCheckedCounter += (stepLegalEnduser.checked) ? +1 : -1;
      const allChecked: boolean = this.legalMotionsCheckedCounter === this.legalMotionsMandatoryTotal;
      this.checked.emit(allChecked);
    }
  }
}
