<app-tooltip [tooltipText]="'HELP.WIZARD_LEGAL_NOTICES' | translate"></app-tooltip>

<app-wizard-step>
  <div class="fixed-md" content>
    <div class="text-center">

      <h3 class="mb-4">{{'WIZARD.LEGAL.TITLE' | translate}}
        <app-input-loader [status]="loaderStatus">
        </app-input-loader>
      </h3>

      <app-items-table [borderBottom]="true"
                       [items]="selectedStepLegalMotions"
                       [noResult]="'WIZARD.LEGAL.NO_RESULT' | translate"
                       [singleList]="false">
        <ng-container action>
          <div class="app-input-text">
            <div #searchList
                 class="form-group">
              <div class="input-group">
                <input (focus)="onFocusSearch()"
                       (input)="handleInputChange($event.target.value)"
                       (keydown)="legalMotionKeydown($event)"
                       (ngModelChange)="onFocusSearch()"
                       [disabled]="handlingAddLegalMotion"
                       [(ngModel)]="inputSearch"
                       autocomplete="off"
                       class="form-control"
                       id="inputSearch"
                       placeholder="{{'WIZARD.LEGAL.SEARCH' | translate}}">
                <div class="input-group-append">
                  <div class="input-group-text">
                    {{'DASHBOARD.SEARCH.SEARCH' | translate}}
                  </div>
                </div>
                <div [hidden]="!searchListOpen"
                     class="search-results">
                  <ul class="list-group list-unstyled">
                    <li *ngIf="!availableLegalMotions || availableLegalMotions?.length === 0"
                        class="empty">
                      {{'NEW_SESSION.SIGNERS.NO_RESULT' | translate}}
                    </li>
                    <li (click)="handleOnAddLegalMotion(legalMotion)"
                        *ngFor="let legalMotion of availableLegalMotions; let i = index"
                        class="row list-group-item result ml-0 mr-0">
                      <a class="col-md-10">{{legalMotion.text.length < 40 ? legalMotion.text
                          : (legalMotion.text.substr(0, 37) + '...')}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #itemsCards
                     let-stepLegalMotion>
          <app-legal-motion-card (onDelete)="handleOnRemoveLegalMotion($event)"
                                 (onUpdate)="handleUpdateLegalMotion($event)"
                                 [disabled]="handlingUpdateLegalMotion || handlingDeleteLegalMotion"
                                 [stepLegalMotion]="stepLegalMotion"></app-legal-motion-card>
        </ng-template>
      </app-items-table>
    </div>
  </div>

  <div class="fixed-container" footer>
    <div class="fixed-md">
      <app-wizard-step-button [action]="stepActions.PREVIOUS"
                              [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>
      <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                              class="float-right"></app-wizard-step-button>
    </div>
  </div>
</app-wizard-step>
