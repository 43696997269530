import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {TranslateService} from 'src/app/core/service/translate.service';
import {CommentService} from 'luxtrust-cosi-api/api/comment.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {CommentData} from 'luxtrust-cosi-api/model/commentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {take} from 'rxjs/operators';
import {ApiError} from 'src/app/error/api-error.model';
import {quillConfig} from 'src/app/services/enum/quill-config';
import {AlertService} from 'src/app/services/services/alert-service';
import {AppService} from 'src/app/services/services/app.service';

interface CommentEnduserData {
  comment: CommentData;
  enduser: EnduserData;
  showTextArea: boolean;
}

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent implements OnChanges {

  @Input() sessionId: number;
  @Input() step: StepData;
  @Input() refreshComment = 0;
  @Output() addNewComment: EventEmitter<void> = new EventEmitter();
  @Output() removeComment: EventEmitter<void> = new EventEmitter();

  quillConfig = quillConfig;

  commentCollapsed = true;
  commentOtherCollapsed = true;
  CommentDataTypeEnum = CommentData.TypeEnum;

  /**
   * Record fetched endusers, like so, if a user is already fetched he is on this array
   * and we do not re-fetch him again. (to avoid useless call)
   */
  endusers: EnduserData[] = [];
  comment: string;
  addedComment: string;
  comments: CommentEnduserData[];
  otherComments: CommentEnduserData[];

  showList = false;
  stepEndusers: StepEnduserData[];
  presentStepEndusers: StepEnduserData[];

  constructor(private readonly commentService: CommentService,
              private readonly translateService: TranslateService,
              private readonly alertService: AlertService,
              private readonly stepEnduserService: StepEnduserService,
              private readonly appService: AppService) {
  }

  ngOnChanges() {
    this.fetchComment();
  }

  /**
   * Get comment related to the sessionId and the stepId
   * once fetched associate comment and enduser
   */
  fetchComment() {
    this.stepEnduserService.stepEnduserList(this.sessionId, this.step.id).toPromise()
      .then(stepEndusers => this.stepEndusers = stepEndusers);
    this.commentService.searchComments(this.sessionId, this.step.id).toPromise().then(comments =>
      Promise.all(comments.map(c => this.fetchCommentEnduser(c))).then(cs => {
        this.comments = cs.filter(c => c.comment.stepId === this.step.id);
        this.commentCollapsed = !(this.comments.length > 0);
        this.otherComments = cs.filter(c => c.comment.stepId !== this.step.id);
        this.commentOtherCollapsed = !(this.otherComments.length > 0);
      })
    ).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  /**
   * Associate comment to his enduser
   * Fetch the user only if the enduser (comment.enduser) is not on the class attributes @endusers
   * @param comment
   */
  fetchCommentEnduser(comment: CommentData): Promise<CommentEnduserData> {
    const enduserFind = comment.enduser;
    return Promise.resolve({comment: comment, enduser: enduserFind, showTextArea: false});
  }

  getCurrentLang(): string {
    return this.translateService.currentLang;
  }

  presentList() {
    this.showList = true;
    this.presentStepEndusers = this.stepEndusers;
  }

  restrictList(event) {
    if (event.key === '@') {
      this.presentList();
      return;
    }
    if (this.showList) {
      const vals = this.comment.split(/@/g);
      const val = vals[vals.length - 1];
      this.presentStepEndusers = this.stepEndusers.filter(se => {
        return se.enduser.lastName && se.enduser.lastName.toLowerCase()
          .startsWith(val) || se.enduser.firstName && se.enduser.firstName.toLowerCase()
          .startsWith(val) || se.enduser.email && se.enduser.email.toLowerCase().startsWith(val);
      });
    }
  }

  stopList() {
    this.showList = false;
  }

  consumeList(u: EnduserData) {
    this.comment = this.comment.replace(/@[^@]*$/, '@|' + u.id + '|' + u.email) + ' ';
    this.showList = false;
    document.getElementById('comment-text').focus();
  }

  addComment() {
    if (this.comment.trim()) {
      this.commentService.createComment(this.sessionId, this.step.id, {
        type: CommentData.TypeEnum.SIMPLE, text: this.comment
      })
        .pipe(take(1))
        .subscribe((comment: CommentData) => {
          this.fetchCommentEnduser(comment)
            .then(comment_ => {
              this.comments.push(comment_);
              this.addNewComment.emit();
            });
          this.comment = '';
        }, (error: ApiError) => this.alertService.errorApiWithCustomMessage('STEP.COMMENT.ERROR.CREATE_COMMENT', error));
    }
  }

  handleCollapse() {
    this.commentCollapsed = !this.commentCollapsed;
  }

  handleOtherCollapse() {
    this.commentOtherCollapsed = !this.commentOtherCollapsed;
  }

  clearComment(comment: CommentEnduserData) {
    comment.showTextArea = false;
  }

  editComment(enduserComment: CommentEnduserData) {
    enduserComment.showTextArea = false;
    if (enduserComment.comment.text.trim()) {
      this.commentService.updateComment(enduserComment.comment.id, this.sessionId, this.step.id, {
        text: this.addedComment
      })
        .pipe(take(1))
        .subscribe((comment: CommentData) => {
          this.comments.find(currentComment => currentComment.comment.id === comment.id).comment.text = comment.text;
          this.addedComment = '';
        }, (error: ApiError) => this.alertService.errorApiWithCustomMessage('STEP.COMMENT.ERROR.EDIT_COMMENT', error));
    }
  }

  deleteComment(comment: CommentEnduserData) {
    this.commentService.deleteComment(comment.comment.id, this.sessionId, this.step.id)
      .pipe(take(1))
      .subscribe(() => {
        const index = this.comments.findIndex(com => com.comment.id === comment.comment.id);
        this.removeComment.emit();
        this.comments.splice(index, 1);
      }, (error: ApiError) => this.alertService.errorApiWithCustomMessage('STEP.COMMENT.ERROR.DELETE_COMMENT', error));
  }

  editAddedComment(comment: CommentEnduserData) {
    comment.showTextArea = true;
    this.addedComment = comment.comment.text;
  }

  showDeleteButton(comment: CommentEnduserData) {
    let showButton;
    switch (comment.comment.type) {
      case this.CommentDataTypeEnum.REJECTION   :
      case this.CommentDataTypeEnum.REINSTATEMENT:
        showButton = false;
        break;
      default:
        showButton = true;
        break;
    }
    return showButton;

  }

  checkIsCommentOwner(enduserComment: CommentEnduserData) {
    let showButton = false;
    if (this.appService.getUser().userId === enduserComment.enduser.userId) {
      showButton = true;
    }
    return showButton;
  }
}
