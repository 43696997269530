import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from './services/alert-service';
import { AppService } from './services/app.service';
import { ConfigurationService } from './services/configuration.service';
import { DownloadService } from './services/download.service';
import { IEService } from './services/ie.service';
import { ItsmeService } from './services/itsme.service';
import {JwtService} from './services/jwt.service';
import { LanguagesService } from './services/languages.service';
import { NexuService } from './services/nexu.service';
import { NgbDateParserFormatterService } from './services/ngb-date-parser-formatter.service';
import { OrelyService } from './services/orely-service';
import { SprofileWrapperService } from './services/sprofile-wrapper.service';
import { VisualIdentityService } from './services/visual-identity.service';

@NgModule({
  imports: [CommonModule, HttpClientModule], exports: [TranslateModule]
})
export class ServiceModule {
  constructor(@Optional() @SkipSelf() parentModule: ServiceModule) {
    if (parentModule) {
      throw new Error('ServiceModule is already loaded. Import your base AppModule only.');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServiceModule,
      providers: [
        AlertService,
        AppService,
        OrelyService,
        LanguagesService,
        DownloadService,
        IEService,
        ItsmeService,
        NexuService,
        NgbDateParserFormatterService,
        VisualIdentityService,
        NgbDateParserFormatterService,
        SprofileWrapperService,
        ConfigurationService,
        JwtService
      ]
    };
  }
}
