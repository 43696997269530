<app-tooltip [tooltipText]="'HELP.WIZARD_GENERAL' | translate"></app-tooltip>

<app-wizard-step>
  <div class="fixed-md" content>
    <div class="text-center">
      <h3 class="mb-4">{{'WIZARD.COMMENTS.TITLE' | translate}}
      </h3>
      <quill-editor [(ngModel)]="comment"
                    [modules]="quillConfig"
                    [placeholder]="'STEP.COMMENT.TYPE_COMMENT_HERE' | translate"
                    id="comment-text-add">
      </quill-editor>
      <div class="form-group">
        <button (click)="addComment()" [disabled]="addingComment"
                class="btn btn-primary mt-2"> {{'STEP.COMMENT.ADD_COMMENT' | translate}}</button>
      </div>
    </div>
    <div *ngFor="let comment of commentData">
    <app-comments-card [comment]="comment"
                       [sessionId]="session.id"
                       [stepId]="step.id"
                       [disabled]="updatingComment || removingComment"
                       (onUpdate)="editComment($event)"
                       (onDelete)="deleteComment($event)">
    </app-comments-card>
    </div>
  </div>


  <div footer>
    <div class="fixed-container">
      <div class="fixed-lg">
        <app-wizard-step-button [action]="stepActions.PREVIOUS"
                                [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>
        <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                                class="float-right" *ngIf="(wizardService.sessionIsTemplate || session.template)"></app-wizard-step-button>

        <button *ngIf="!(wizardService.sessionIsTemplate || session.template)" [queryParamsHandling]="'preserve'" [routerLink]="['/session', session.id]"
        class="btn btn-primary btn-min-width float-right" type="button">{{'WIZARD.VALIDATE' | translate}}  </button>



      </div>
    </div>
  </div>
</app-wizard-step>
