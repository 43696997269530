/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "../../../core/service/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./card-info.component";
var styles_CardInfoComponent = [i0.styles];
var RenderType_CardInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardInfoComponent, data: {} });
export { RenderType_CardInfoComponent as RenderType_CardInfoComponent };
function View_CardInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "span", [["class", "d-inline-block mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "span", [["class", "box-info mt-2 p-2 d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "span", [["class", "align-self-center text-secondary flex-grow-1 left"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "text-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "text-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "text-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "flex-grow-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-sm btn-danger align-self-center ml-2"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteDelegateEvent.emit(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("USER_PROFILE.SIGNATURE_DELEGATION.DELEGATED_TO")); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.circle ? _co.patchCircleName(_v.context.$implicit.lastName) : ((_v.context.$implicit.firstName + " ") + _v.context.$implicit.lastName)); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("USER_PROFILE.SIGNATURE_DELEGATION.FROM")); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.dates[_v.context.index].from; _ck(_v, 11, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("USER_PROFILE.SIGNATURE_DELEGATION.TO")); _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.dates[_v.context.index].to; _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.disabled; _ck(_v, 18, 0, currVal_6); }); }
export function View_CardInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardInfoComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signers; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CardInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-info", [], null, null, null, View_CardInfoComponent_0, RenderType_CardInfoComponent)), i1.ɵdid(1, 49152, null, 0, i5.CardInfoComponent, [], null, null)], null, null); }
var CardInfoComponentNgFactory = i1.ɵccf("app-card-info", i5.CardInfoComponent, View_CardInfoComponent_Host_0, { signers: "signers", dates: "dates", disabled: "disabled" }, { deleteDelegateEvent: "deleteDelegateEvent" }, []);
export { CardInfoComponentNgFactory as CardInfoComponentNgFactory };
