import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';

@Component({
  selector: 'app-card-info', templateUrl: './card-info.component.html', styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent {

  @Input() signers: EnduserData[];
  @Input() dates: {
    from: string; to: string;
  }[];
  @Input() disabled: boolean = false;
  @Output() deleteDelegateEvent = new EventEmitter<number>();

  patchCircleName(name: string) {
    return name.replace(/{[(\w+\-)]+}/g, '');
  }
}
