import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from 'luxtrust-cosi-api/api/authentication.service';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {InvitationPayload} from 'luxtrust-cosi-api/model/invitationPayload';
import { NotifierService } from 'src/app/services/services/notifier.service';
import {REGEXES} from '../../app.constant';
import {AlertService} from '../../services/services/alert-service';
import {AppService} from '../../services/services/app.service';
import {ValidatorHelper} from '../../services/validators/validators.helper';
import {ApiError} from '../../error/api-error.model';

@Component({
  templateUrl: './invite.component.html', styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  user: EnduserData;
  sessionId: number;
  stepId: number;
  registerForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertService: AlertService,
              private appService: AppService,
              private route: ActivatedRoute,
              private notifierService: NotifierService) {
  }

  ngOnInit() {
    const jwt = this.route.snapshot.queryParams['jwt'];
    if (jwt) {
      this.appService.refresh(jwt);
      if (this.appService.isLoggedIn()) {
        this.appService.loadEnduser().then(user => {
          this.user = user;
          this.registerForm.get('firstName').patchValue(this.user.firstName);
          this.registerForm.get('lastName').patchValue(this.user.lastName);
          this.registerForm.get('email').patchValue(this.user.email);
        });
      }
    }
    this.registerForm = this.formBuilder.group({
      firstName: [{value: '', disabled: true}],
      lastName: [{value: '', disabled: true}],
      email: [{value: '', disabled: true}],
      password: ['',
        [
          Validators.required,
          Validators.pattern(REGEXES.PASSWORD.NUMERIC_CHARS),
          Validators.pattern(REGEXES.PASSWORD.SPECIAL_CHARS),
          Validators.pattern(REGEXES.PASSWORD.ALPHA_CASED_CHARS),
          Validators.minLength(6)
        ]
      ],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: ValidatorHelper.matchPassword });

    this.sessionId = parseInt(this.route.snapshot.queryParams['sessionId'], 10);
    this.stepId = parseInt(this.route.snapshot.queryParams['stepId'], 10);
  }

  onSubmit() {
    this.registerForm.markAllAsTouched();
    if (this.registerForm.invalid) {
      return;
    }

    const payload: InvitationPayload = {
      email: this.registerForm.value.email,
      password: this.registerForm.value.password || undefined,
      firstName: this.registerForm.value.firstName,
      lastName: this.registerForm.value.lastName
    };
    this.authenticationService.newUserInvitationSignup(payload).toPromise().then(() => {
      this.notifierService.notifyDetectUpdatePage('displayHome');
      this.router.navigate(['/session', this.sessionId, 'step', this.stepId]);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  onProceed() {
    this.notifierService.notifyDetectUpdatePage('displayHome');
    this.router.navigate(['/session', this.sessionId, 'step', this.stepId]);
  }

  // Needed to display error immediately if any
  markAsTouched(controlName: string) {
    const control = this.registerForm.get(controlName);
    control.markAsTouched();
  }
}
