<app-wizard-step>
  <h3 class="mb-4">{{'WIZARD.CONFIG.TITLE' | translate}} </h3>
  <div class="buttonsRole">
    <div (click)="changeRole(wizardStepAdminEnum.MANAGER)"
         [ngClass]=" {'active' : role === wizardStepAdminEnum.MANAGER}"
         class="buttonRole">
      {{'WIZARD.CONFIG.MANAGER' | translate}}
    </div>
    <div (click)="changeRole(wizardStepAdminEnum.SIGNER)"
         [ngClass]=" {'active' : role === wizardStepAdminEnum.SIGNER}"
         class="buttonRole">
      {{'WIZARD.CONFIG.SIGNER' | translate}}
    </div>
  </div>
  <form id="adminForm">
    <div class="parts">
      <div class="firstPart part users ">
        <h4 class="mb-4"> {{'WIZARD.CONFIG.USERS' | translate}}  </h4>
        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-users-replace-switch">
            {{'WIZARD.CONFIG.REPLACE' | translate}}
          </label>
          <input
                  [(ngModel)]="config.manager.users.replace"
                  name="managerUserReplace"
                  class="switcher-checkbox"
                  id="input-manager-users-replace-switch"
                  type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-users-replace-switch">
            {{'WIZARD.CONFIG.REPLACE' | translate}}
          </label>
          <input
                  [(ngModel)]="config.signer.users.replace"
                  name="signerUserReplace"
                  class="switcher-checkbox"
                  id="input-signer-users-replace-switch"
                  type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-users-add-switch">
            {{'WIZARD.CONFIG.ADD' | translate}}
          </label>
          <input
                 [(ngModel)]="config.signer.users.add"
                 name="signerUserAdd"
                 class="switcher-checkbox"
                 id="input-signer-users-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-users-add-switch">
            {{'WIZARD.CONFIG.ADD' | translate}}
          </label>
          <input
                 [(ngModel)]="config.manager.users.add"
                 name="managerUserAdd"
                 class="switcher-checkbox"
                 id="input-manager-users-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-users-clone-switch">
            {{'WIZARD.CONFIG.CLONE' | translate}}
          </label>
          <input
                  [(ngModel)]="config.signer.users.clone"
                  name = "signerUserClone"
                  class="switcher-checkbox"
                  id="input-signer-users-clone-switch"
                  type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-users-clone-switch">
            {{'WIZARD.CONFIG.CLONE' | translate}}
          </label>
          <input
                  [(ngModel)]="config.manager.users.clone"
                  name = "managerUserClone"
                  class="switcher-checkbox"
                  id="input-manager-users-clone-switch"
                  type="checkbox">
          <div class="switcher-toggle"></div>
        </div>
      </div>
      <div class="part documents">
        <h4 class="mb-4"> Documents </h4>
        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-replace-switch">
            {{'WIZARD.CONFIG.REPLACE' | translate}}
          </label>
          <input
                 [(ngModel)]="config.manager.documents.replace"
                 name = "managerDocReplace"
                 class="switcher-checkbox"
                 id="input-manager-document-replace-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-replace-switch">
            {{'WIZARD.CONFIG.REPLACE' | translate}}
          </label>
          <input
                 [(ngModel)]="config.signer.documents.replace"
                 name = "signerDocReplace"
                 class="switcher-checkbox"
                 id="input-signer-document-replace-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-add-switch">
            {{'WIZARD.CONFIG.ADD' | translate}}
          </label>
          <input
                 [(ngModel)]="config.manager.documents.add"
                 name="managerDocAdd"
                 class="switcher-checkbox"
                 id="input-manager-document-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-add-switch">
            {{'WIZARD.CONFIG.ADD' | translate}}
          </label>
          <input
                 [(ngModel)]="config.signer.documents.add"
                 name="signerDocAdd"
                 class="switcher-checkbox"
                 id="input-signer-document-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-clone-switch">
            {{'WIZARD.CONFIG.CLONE' | translate}}
          </label>
          <input
                 [(ngModel)]="config.manager.documents.clone"
                 name="managerDocClone"
                 class="switcher-checkbox"
                 id="input-manager-document-clone-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-clone-switch">
            {{'WIZARD.CONFIG.CLONE' | translate}}
          </label>
          <input
                 [(ngModel)]="config.signer.documents.clone"
                 name="signerDocClone"
                 class="switcher-checkbox"
                 id="input-signer-document-clone-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-sprofile-switch">
            {{'WIZARD.CONFIG.SPROFILE' | translate}}
          </label>
          <input
                 [(ngModel)]="config.manager.documents.sprofile"
                 name="managerDocSprofile"
                 class="switcher-checkbox"
                 id="input-manager-document-sprofile-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-sprofile-switch">
            {{'WIZARD.CONFIG.SPROFILE' | translate}}
          </label>
          <input
                 [(ngModel)]="config.signer.documents.sprofile"
                 name="signerDocSprofile"
                 class="switcher-checkbox"
                 id="input-signer-document-sprofile-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>


      </div>

      <div class="part metadata">
        <h4 class="mb-4"> {{'WIZARD.CONFIG.METADATA' | translate}}  </h4>
        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-metadata-update-switch">
            {{'WIZARD.CONFIG.UPDATE' | translate}}
          </label>
          <input
                 [(ngModel)]="config.manager.metadata.update"
                 name="managerMetaUpdate"
                 class="switcher-checkbox"
                 id="input-manager-metadata-update-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

      <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
        <label for="input-signer-metadata-update-switch">
          {{'WIZARD.CONFIG.UPDATE' | translate}}
        </label>
        <input
               [(ngModel)]="config.signer.metadata.update"
               name="signerMetaUpdate"
               class="switcher-checkbox"
               id="input-signer-metadata-update-switch"
               type="checkbox">
        <div class="switcher-toggle"></div>
      </div>

    </div>
    </div>
    <div class="flex-space-between">
      <button (click)="handlePreviousStepButton()" [disabled]="submittingForm"
              class="btn btn-primary btn-min-width float-left" type="button" >{{'WIZARD.PREVIOUS_STEP' | translate}} </button>
      <button (click)="deleteValueToggleAdminIntoSessionStorage()" [disabled]="submittingForm" [queryParamsHandling]="'preserve'" [routerLink]="['/session', session.id]"
              class="btn btn-primary btn-min-width float-right" type="button">{{'WIZARD.VALIDATE' | translate}}  </button>
    </div>
  </form>



</app-wizard-step>

