/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EnduserRemotePlatformInfo } from './enduserRemotePlatformInfo';
import { OrganisationData } from './organisationData';


/**
 * Enduser details
 */
export interface EnduserData { 
    /**
     * Enduser attributes
     */
    attributes?: { [key: string]: string; };
    /**
     * Indicates if the enduser is a circle
     */
    circle?: boolean;
    /**
     * Indicates if the circle can be modified
     */
    circleModifiable?: boolean;
    /**
     * Indicates if the circle is mapped into the directory
     */
    circleReentrant?: boolean;
    /**
     * Directories the enduser has access to
     */
    directories?: Array<string>;
    /**
     * Directory alias
     */
    directoryAlias?: string;
    /**
     * Email
     */
    email?: string;
    /**
     * Whether fully enrolled account
     */
    enrolled?: boolean;
    /**
     * Entitlement identifiers
     */
    entitlementNames?: Array<string>;
    /**
     * First name
     */
    firstName?: string;
    /**
     * Enduser identifier if has an account, null otherwise
     */
    id?: any;
    /**
     * Last name
     */
    lastName?: string;
    /**
     * Language
     */
    localeCode?: EnduserData.LocaleCodeEnum;
    /**
     * Organisation
     */
    organisation?: OrganisationData;
    /**
     * Phone number
     */
    phone?: string;
    /**
     * List of linked platforms
     */
    remotePlatforms?: Array<EnduserRemotePlatformInfo>;
    /**
     * Tenant identifier
     */
    tenantId?: number;
    /**
     * Tenant name
     */
    tenantName?: string;
    /**
     * User
     */
    userId?: string;
}
export namespace EnduserData {
    export type LocaleCodeEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LocaleCodeEnum = {
        EN: 'EN' as LocaleCodeEnum,
        FR: 'FR' as LocaleCodeEnum,
        DE: 'DE' as LocaleCodeEnum,
        PT: 'PT' as LocaleCodeEnum,
        IT: 'IT' as LocaleCodeEnum,
        NL: 'NL' as LocaleCodeEnum,
        SP: 'SP' as LocaleCodeEnum
    };
}
