import {Component, EventEmitter, Input, Output} from "@angular/core";
import {CommentData} from "luxtrust-cosi-api/model/commentData";
import {CommentService} from "luxtrust-cosi-api/api/comment.service";
import {AlertService} from "../../../services/services/alert-service";
import {quillConfig} from "../../../services/enum/quill-config";

@Component({
  selector: 'app-comments-card', templateUrl: './comments-card.component.html', styleUrls: ['./comments-card.component.scss']
})
export class CommentsCardComponent {

  @Input() comment: CommentData;
  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() disabled: boolean;
  @Output() onDelete: EventEmitter<CommentData> = new EventEmitter();
  @Output() onUpdate: EventEmitter<{commemtId:number,text:string}> = new EventEmitter();

  quillConfig = quillConfig;
  showTextArea: boolean = false;
  addedComment: string;

  constructor(private commentService: CommentService,
              private alertService: AlertService){}

  deleteComment() {
    this.onDelete.emit(this.comment);
  }

  editAddedComment() {
    this.showTextArea = true;
    this.addedComment = this.comment.text;
  }

  editComment() {
    this.showTextArea = false;
    this.onUpdate.emit({commemtId:this.comment.id,text:this.addedComment});
  }

  clearComment() {
    this.showTextArea = false;
  }

}
