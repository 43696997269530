import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-modal', templateUrl: './modal.component.html', styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() opened = false;
  @Input() titleModal: string;
  @Input() design = 'basic';
  @Input() messageModal = '';
  @Input() disabled = false;
  @Input() varModal: string;
  @Output() delete: EventEmitter<boolean> = new EventEmitter();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
}
