import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PatternData } from 'luxtrust-cosi-api/model/patternData';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent {

  verticalMode = false;
  darkMode = false;

  @Input() displayCloseButton = false;
  @Input() patternData: PatternData;
  @Output() inputEvent = new EventEmitter<PatternData>();
  @Output() closeEvent = new EventEmitter<void>();

  onSubjectInput(value: string) {
    this.patternData.subject = value;
    this.emitPattern();
  }

  onBodyInput(value: string) {
    this.patternData.body = value;
    this.emitPattern();
  }

  emitPattern() {
    this.inputEvent.emit(this.patternData);
  }

  onCloseButton() {
    this.closeEvent.emit();
  }

}
