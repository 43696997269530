import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import find from 'lodash/find';
import {AnnotationService} from 'luxtrust-cosi-api/api/annotation.service';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {PlaceholderService} from 'luxtrust-cosi-api/api/placeholder.service';
import {SprofileService} from 'luxtrust-cosi-api/api/sprofile.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {StepTagService} from 'luxtrust-cosi-api/api/stepTag.service';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {TagData} from 'luxtrust-cosi-api/model/tagData';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';
import {AppService} from '../../../../services/services/app.service';
import {SignerDetails} from '../../../../shared/components/document-item/document-item.component';
import {ModalPdfConsumerComponent} from '../../../../shared/components/modal-pdf/modal-pdf-consumer.component';
import {WizardDocumentState} from '../../model/wizard-document-state';
import {WizardDocumentStoreService} from '../../services/wizard-document-store.service';
import {StepActions} from '../../wizard/models/wizard.config';
import {EnduserService, SignatureWorkflowService} from '../../../../../../luxtrust-cosi-api';

@Component({
  templateUrl: './wizard-step-documents.component.html', styleUrls: ['./wizard-step-documents.component.scss']
})
export class WizardStepDocumentsComponent extends ModalPdfConsumerComponent implements OnInit {

  stepActions = StepActions;
  session: SessionData;
  step: StepData;
  documents: WizardDocumentState;
  sprofiles: SprofileData[] = [];
  hasStepTagPositionSignatureLocation = false;
  hasCheckStepTag = false;
  retakingDocument = false;
  cloningDocument = false;
  cloningAppendix = false;
  creatingMutualDocument = false;
  creatingMutualAppendix = false;
  reuploadingDocument = false;

  constructor(public modal: NgbModal,
              public appService: AppService,
              public alertService: AlertService,
              public annotationService: AnnotationService,
              public placeholderService: PlaceholderService,
              private route: ActivatedRoute,
              public wizardDocumentStoreService: WizardDocumentStoreService,
              public enduserService: EnduserService,
              public stepEnduserService: StepEnduserService,
              private stepTagService: StepTagService,
              private stepService: StepService,
              public signatureWorkflowService: SignatureWorkflowService,
              private sprofileService: SprofileService,
              public documentService: DocumentService) {
    super(modal, appService, annotationService, alertService, documentService, placeholderService, stepEnduserService, signatureWorkflowService, wizardDocumentStoreService);
    this.wizardDocumentStoreService.init();
  }

  selectedSigners(document?: DocumentData): EnduserData[] {
    if (!this.hasStepTagPositionSignatureLocation) {
      return this.signers;
    } else {
      const signerByDocument = find(this.signersByDocument, (docSigners) => docSigners.document.id === document.id);
      if (signerByDocument) {
        return signerByDocument.signers;
      }
      return undefined;
    }
    return undefined;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.wizardDocumentStoreService.reset();
    this.route.parent.data.pipe(take(1)).subscribe((data: Data) => {
      this.session = data['session'];
      this.sessionId = this.session.id;
      this.step = data['step'];
      this.stepId = this.step.id;
      this.stepService.getStepList(this.session.id).subscribe((stepList) => {
        // Refresh step to be sure other wizard changes are fetched.
        // This could be done using the resolvers, and by forcing refresh: runGuardsAndResolvers: 'always'
        // Warning: This increases nb of API calls, as resolver will be called at every wizard page change
        this.step = stepList.find(currentStep => currentStep.id === this.step.id);
      }, (error: ApiError) => this.alertService.errorApi(error));
      this.checkStepTags().subscribe(() => {
        Promise.all([
          this.stepEnduserService.stepEnduserList(this.session.id, this.step.id).toPromise().then(stepEndusers => {
            const stepEnduserFilter = stepEndusers.filter(se => se.signer);
            this.signers = [];
            stepEnduserFilter.forEach(stepEnduser => {
              if (stepEnduser.enduser.circle) {
                this.putSignerCircleIntoList(stepEnduser.expected, stepEnduser.enduser, this.signers);
              } else {
                this.signers.push(stepEnduser.enduser);
              }
            });
            this.checkCurrentUserIntoStepEnduserList(stepEndusers);
          }),
          this.wizardDocumentStoreService.getAllDocuments(this.session.id, this.step.id)
        ]).then(() => {
          this.refreshDocumentData();
        }).catch((error: ApiError) => {
          this.alertService.errorApi(error);
        });
      });
      this.sprofileService.getSprofileList().toPromise().then((res) => {
        this.sprofiles = res.filter(s => s.format !== 'OTHER');
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    });
    super.ngOnInit();
  }

  checkStepTags(): Observable<TagData[]> {
    return this.stepTagService.getStepTagList(this.sessionId, this.step.id).pipe(tap(tags => {
      this.hasStepTagPositionSignatureLocation = tags.some(tag => tag.alias.includes('POSITION_SIGNATURE_LOCATION'));
      this.hasCheckStepTag = true;
    }));
  }


  reuploadDocument(data: { document: DocumentData, file: File }) {
    this.reuploadingDocument = true;
    this.documentService.reuploadDocument(data.document.id, this.session.id, this.step.id, data.file).subscribe(() => {
      this.reuploadingDocument = false
      this.alertService.success('DOCUMENT.REUPLOAD_SUCCESS');
      /*    const oldDoc = find(this.signersByDocument, (signerDoc) => signerDoc.document.name === data.document.name);
          oldDoc.document = data.file.name; */
      this.wizardDocumentStoreService.getAllDocuments(this.session.id, this.step.id).then(() => {
        this.refreshDocumentData();
      });
    }, (error: ApiError) => {
      this.reuploadingDocument = false;
      this.alertService.errorApi(error);
    });
  }

  createMutualDocument(data: { document: DocumentData, isMutual: boolean }) {
    this.creatingMutualDocument = true;
    if (data.isMutual) {
      this.wizardDocumentStoreService.createMutualDocument(this.sessionId, this.stepId, data.document, false).then(value => this.creatingMutualDocument = false);
    } else {
      this.wizardDocumentStoreService.deleteMutualDocument(this.sessionId, this.stepId, data.document, false).then(value => this.creatingMutualDocument = false);
    }
  }


  createMutualAppendix(data: { document: DocumentData, isMutual: boolean }) {
    this.creatingMutualAppendix = true;
    if (data.isMutual) {
      this.wizardDocumentStoreService.createMutualAppendix(this.sessionId, this.stepId, data.document).then(value => this.creatingMutualAppendix = false);
    } else {
      this.wizardDocumentStoreService.deleteMutualAppendix(this.sessionId, this.stepId, data.document).then(value => this.creatingMutualAppendix = false);
    }
  }

  cloneDocument(document: DocumentData) {
    const modalEdit = this.openModal(document);

    // Removing the file extension from the name input
    const dotIndex = document.name.lastIndexOf('.');
    const ext = document.name.substr(dotIndex);
    modalEdit.componentInstance.content = document.name.substr(0, dotIndex) + '-2';

    modalEdit.componentInstance.onAction.pipe(take(1)).subscribe((commentText: string) => {
      const clone: DocumentData = document;

      // Giving the clone the new name with the original extension
      clone.name = commentText + ext;
      this.cloningDocument = true;
      this.wizardDocumentStoreService.cloneDocument(this.session.id, this.step.id, clone).then(() => {
        this.cloningDocument = false;
        this.showSignersAllDocuments();
      });
    });
  }

  cloneAppendix(document: DocumentData) {
    this.cloningAppendix = true;
    this.wizardDocumentStoreService.cloneDocument(this.session.id, this.step.id, document).then(value => this.cloningAppendix = false);
  }


  retakeMutualDocument(data: { document: DocumentData, isMutual: boolean }) {
    if (data.isMutual) {
      this.retakingDocument = true;
      this.wizardDocumentStoreService.retakeMutualDocument(this.session.id, this.step.id, data.document).then(value => this.retakingDocument = false);
    }
  }

  retakeMutualAppendix(data: { document: DocumentData, isMutual: boolean }) {
    if (data.isMutual) {
      this.retakingDocument = true;
      this.wizardDocumentStoreService.retakeMutualAppendix(this.session.id, this.step.id, data.document).then(value => this.retakingDocument = false);
    }
  }

  getSignatures(documents: DocumentData[]): { [id: string]: SignerDetails[] } {
    const res = {};
    if (!this.step.skipAcroforms) {
      documents.forEach(d => res[d.name] = this.getSignersDetails(d.name));
    }
    return res;
  }

  pad(n: number) {
    return String(n).padStart(6, ' ');
  }

  onFailed() {
    this.wizardDocumentStoreService.onFailed();
  }

  get mutualDocumentsSorted() {
    if(this.documents && this.documents.documentMutual) {
      return this.documents.documentMutual.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
    }
    return [];
  }

  get documentsToSignSorted() {
    if(this.documents && this.documents.documentToSign) {
    return this.documents.documentToSign.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
  }
    return [];
  }

  get appendixToSeeSorted() {
    if(this.documents && this.documents.appendixToSee) {
      return this.documents.appendixToSee.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
    }
    return [];
  }
}
