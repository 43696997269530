import {Injectable, NgZone} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {TranslateService} from 'src/app/core/service/translate.service';
import {Observable, Subject} from 'rxjs';

import {Alert, AlertType} from '../../error/alert.model';
import {ApiError} from '../../error/api-error.model';

@Injectable()
export class AlertService {

  private subject = new Subject<Alert>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router, private ngZone: NgZone, private translateService: TranslateService) {

    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  success(message: string, interpolateParams: any = {}, keepAfterNavigationChange = true) {
    this.alert(AlertType.SUCCESS, this.translateService.instant(message, interpolateParams), keepAfterNavigationChange);
  }

  warning(message: string, interpolateParams: any = {}, keepAfterNavigationChange = true) {
    this.alert(AlertType.WARNING, this.translateService.instant(message, interpolateParams), keepAfterNavigationChange);
  }

  info(message: string, interpolateParams: any = {}, keepAfterNavigationChange = true) {
    this.alert(AlertType.INFO, this.translateService.instant(message, interpolateParams), keepAfterNavigationChange);
  }

  /**
   * Call this if you want to alert for:
   * - A frontend related issue
   * - An API issue, but without using the provided error message
   * @param message                     Translation key representing message to be displayed
   * @param interpolateParams           Parameters to be injected into translated message
   * @param keepAfterNavigationChange   Leave toast open even after redirection
   */
  error(message: string, interpolateParams: any = {}, keepAfterNavigationChange = true) {
    this._error(message, undefined, undefined, interpolateParams, keepAfterNavigationChange);
  }

  /**
   * Call this if you want to alert for an API issue.
   * @param apiError                    Full API error object
   * @param interpolateParams           Parameters to be injected into translated message
   * @param keepAfterNavigationChange   Leave toast open even after redirection
   */
  errorApi(apiError: ApiError, interpolateParams: any = {}, keepAfterNavigationChange = true) {
    let apiErrorUID = '';
    if (apiError && apiError.error && apiError.error.parameters) {
      apiErrorUID = apiError.error.parameters.id ? apiError.error.parameters.id : apiError.error.parameters.exceptionId;
    }
    let message = '';
    let code = '';
    if (apiError && apiError.error) {
        message = apiError.error.message;
        code = apiError.error.code;
    }
    this._error(message, code, apiErrorUID, interpolateParams, keepAfterNavigationChange);
  }

  /**
   * This is a convenient method that SHOULD NOT be used.
   * Errors from API should not be overridden by frontend.
   * @deprecated
   */
  errorApiWithCustomMessage(customMessage: string, apiError: ApiError, interpolateParams: any = {}, keepAfterNavigationChange = true) {
    const apiErrorUID = apiError.error.parameters.id ? apiError.error.parameters.id : apiError.error.parameters.exceptionId;
    this._error(customMessage, apiError.error.code, apiErrorUID, interpolateParams, keepAfterNavigationChange);
  }

  /**
   * Build error and alert once done.
   * @param message                   Displayed if present
   * @param apiErrorCode              Displayed only if no message
   * @param apiErrorUID               Displayed if present
   * @param interpolateParams
   * @param keepAfterNavigationChange
   * @private
   */
  private _error(message: string, apiErrorCode?: string, apiErrorUID?: string, interpolateParams: any = {}, keepAfterNavigationChange = true) {
    const translatedMessagePart: string =
      message ? this.translateService.instant(message, interpolateParams) : undefined;
    const codeMessagePart: string =
      (apiErrorCode && !message) ? `Code: ${apiErrorCode}` : undefined;
    const referenceTranslatedMessagePart =
      apiErrorUID ? `(${this.translateService.instant('ERROR_TOAST_REF', {})}: ${apiErrorUID})` : undefined;
    const fullTranslatedMessage = [codeMessagePart, translatedMessagePart].join(' ');
    if (fullTranslatedMessage && fullTranslatedMessage.trim().length > 0) {
      this.alert(AlertType.ERROR, fullTranslatedMessage, keepAfterNavigationChange, referenceTranslatedMessagePart);
    }
  }

  alert(type: AlertType, message: string, keepAfterNavigationChange = true, reference?: string) {
    this.ngZone.run(() => {
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      this.subject.next({ type, message, reference });
    });
  }

  getAlerts(): Observable<Alert> {
    return this.subject.asObservable();
  }
}
