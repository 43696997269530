import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'luxtrust-cosi-api/api/session.service';
import { StepService } from 'luxtrust-cosi-api/api/step.service';
import { CreateSessionPayload } from 'luxtrust-cosi-api/model/createSessionPayload';
import { StepData } from 'luxtrust-cosi-api/model/stepData';
import { Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/internal/operators';
import { ValidatorHelper } from 'src/app/services/validators/validators.helper';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';

@Component({
  templateUrl: './modal-create-session.component.html'
})
export class ModalCreateSessionComponent implements AfterViewInit, OnInit, OnDestroy {

  @Output() inProgress: EventEmitter<void> = new EventEmitter();
  @ViewChild('sessionName', {
    read: ElementRef, static: false
  }) inputSessionName: ElementRef;
  form: FormGroup;
  submitted = false;
  showLengthError = false;
  private createSessionSubscription: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private stepService: StepService,
    private alertService: AlertService
  ) { }

  get sessionName() {
    return this.form.get('sessionName');
  }

  ngOnInit() {
    this.form = this.createFormGroup();
  }

  ngAfterViewInit() {
    this.inputSessionName.nativeElement.focus();
  }

  ngOnDestroy() {
    if (this.createSessionSubscription) {
      this.createSessionSubscription.unsubscribe();
    }
  }

  checkLength() {
    this.showLengthError = this.sessionName.hasError('maxlength');
  }

  createSession() {
    this.inProgress.emit();
    this.submitted = true;
    const payload: CreateSessionPayload = {
      label: this.form.value.sessionName.trim(), templateId: undefined, simple: true
    };

    let newSessionId;
    if (this.createSessionSubscription) {
      this.createSessionSubscription.unsubscribe();
    }
    this.createSessionSubscription = this.sessionService.createSession(payload)
    .pipe(take(1), tap(session => newSessionId = session.id), switchMap(session => this.stepService.getStepList(session.id)),
      map(steps => steps.find(step => step.category === StepData.CategoryEnum.OPERATION)))
    .subscribe(// Backend should create one operation step at least...
      (step: StepData) => this.activeModal.close({
        sessionId: newSessionId, stepId: step.id
      }), (error: ApiError) => {
        this.alertService.errorApi(error);
        this.submitted = false;
      });
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      sessionName: new FormControl('', [Validators.required, Validators.maxLength(255), ValidatorHelper.noWhitespace])
    });
  }

}
