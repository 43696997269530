import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {throwError, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';

@Injectable()
export class StepResolver implements Resolve<StepData> {

  constructor(
    private readonly stepService: StepService,
    private readonly alertService: AlertService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StepData> | Promise<StepData> | StepData {
    const sessionId: number = +route.params['sessionId'];
    const stepId: number = +route.params['stepId'];
    return this.stepService.getStep(sessionId, stepId)
      .pipe(catchError((error: ApiError) => {
        this.alertService.errorApi(error);
        return throwError(error);
      }));
  }
}
