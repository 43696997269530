/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-text.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./input-text.component";
import * as i4 from "@angular/router";
var styles_InputTextComponent = [i0.styles];
var RenderType_InputTextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputTextComponent, data: {} });
export { RenderType_InputTextComponent as RenderType_InputTextComponent };
function View_InputTextComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_1); }); }
function View_InputTextComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "input-group-text cursor-pointer": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.handleNavigation()); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "fas fa-", _co.icon, ""); _ck(_v, 4, 0, currVal_1); }); }
function View_InputTextComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fas fa-", _co.icon, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_InputTextComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputTextComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputTextComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleNavigation(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.handleNavigation(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_InputTextComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFirstError(); _ck(_v, 1, 0, currVal_0); }); }
function View_InputTextComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [["class", "form-text text-muted"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "Hint"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.hint; _ck(_v, 1, 0, currVal_1); }); }
function View_InputTextComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputTextComponent_7)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hint; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InputTextComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputTextComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["input", 1]], null, 0, "input", [["autocomplete", "off"], ["class", "form-control"]], [[1, "aria-describedby", 0], [1, "aria-label", 0], [1, "maxlength", 0], [1, "tabindex", 0], [8, "disabled", 0], [8, "id", 0], [8, "placeholder", 0], [8, "type", 0], [8, "value", 0]], [[null, "blur"], [null, "change"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = ((_co.hasOnBlur ? _co.onTouched($event.target.value) : null) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (((_co.hasOnBlur || _co.hasOnInput) ? null : _co.onChange($event.target.value)) !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = ((_co.hasOnInput ? _co.onChange($event.target.value) : null) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputTextComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputTextComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showHint", 2]], null, 0, null, View_InputTextComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); var currVal_10 = _co.icon; _ck(_v, 7, 0, currVal_10); var currVal_11 = (_co.ngControl.touched && _co.ngControl.invalid); var currVal_12 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.id + "Hint"); var currVal_2 = _co.placeholder; var currVal_3 = _co.maxLength; var currVal_4 = _co.tabindex; var currVal_5 = _co.disabled; var currVal_6 = i1.ɵinlineInterpolate(1, "id", _co.idPostfix, ""); var currVal_7 = (_co.placeholder ? _co.placeholder : ""); var currVal_8 = _co.type; var currVal_9 = (_co.value ? _co.value : _co.ngControl.value); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_InputTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input-text", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_InputTextComponent_0, RenderType_InputTextComponent)), i1.ɵdid(1, 49152, null, 0, i3.InputTextComponent, [[8, null], i4.Router], null, null)], null, null); }
var InputTextComponentNgFactory = i1.ɵccf("app-input-text", i3.InputTextComponent, View_InputTextComponent_Host_0, { id: "id", label: "label", placeholder: "placeholder", icon: "icon", hint: "hint", disabled: "disabled", errors: "errors", navigateOnClick: "navigateOnClick", value: "value", type: "type", maxLength: "maxLength", hasOnBlur: "hasOnBlur", hasOnInput: "hasOnInput", tabindex: "tabindex", idPostfix: "idPostfix" }, {}, []);
export { InputTextComponentNgFactory as InputTextComponentNgFactory };
