import { Router } from '@angular/router';
import { SessionService } from 'luxtrust-cosi-api/api/session.service';
import { SignatureWorkflowService } from 'luxtrust-cosi-api/api/signatureWorkflow.service';
import { StepService } from 'luxtrust-cosi-api/api/step.service';
import { AlertService } from './alert-service';
import { AppService } from './app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./alert-service";
import * as i3 from "../../../../luxtrust-cosi-api/api/signatureWorkflow.service";
import * as i4 from "./app.service";
import * as i5 from "../../../../luxtrust-cosi-api/api/session.service";
import * as i6 from "../../../../luxtrust-cosi-api/api/step.service";
export class NavigationService {
    constructor(router, alertService, workflowService, appService, sessionService, stepService) {
        this.router = router;
        this.alertService = alertService;
        this.workflowService = workflowService;
        this.appService = appService;
        this.sessionService = sessionService;
        this.stepService = stepService;
    }
    goToDocumentOrFirstStartedStepOrSession(sessionId) {
        return this.stepService.getStartedSteps(sessionId).toPromise().then(navigateTo => {
            if (navigateTo === null) {
                this.router.navigate(['/session', sessionId]);
                return;
            }
            if (navigateTo.stepId !== null && navigateTo.signatureId !== null) {
                this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId, 'workflow', navigateTo.signatureId]);
                return;
            }
            if (navigateTo.stepId !== null) {
                this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId]);
                return;
            }
            this.router.navigate(['/session', sessionId]);
        }).catch((error) => {
            this.alertService.errorApi(error);
        });
    }
    goToFirstStartedStepOrDocument(sessionId, stepId) {
        this.stepService.getStartedSteps(sessionId).toPromise().then(navigateTo => {
            if (navigateTo !== null && navigateTo.stepId !== null && navigateTo.signatureId !== null) {
                this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId, 'workflow', navigateTo.signatureId]);
                return;
            }
            this.router.navigate(['/session', sessionId, 'step', stepId]);
        }).catch((error) => {
            this.alertService.errorApi(error);
        });
    }
    goToFirstDocument(sessionId) {
        this.stepService.getStartedSteps(sessionId).toPromise().then(navigateTo => {
            if (navigateTo !== null && navigateTo.stepId !== null && navigateTo.signatureId !== null) {
                this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId, 'workflow', navigateTo.signatureId]);
                return;
            }
            else if (navigateTo !== null && navigateTo.stepId !== null) {
                this.workflowService.getWorkflowList(sessionId, navigateTo.stepId).subscribe(workflows => {
                    this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId, 'workflow', workflows[0].signatureId, 'as', workflows[0].enduserId]);
                    return;
                }, (error) => this.alertService.errorApi(error));
            }
            else {
                this.router.navigate(['/session', sessionId]);
            }
        }).catch((error) => {
            this.alertService.errorApi(error);
        });
    }
    goToFirstStartedStep(sessionId) {
        this.stepService.getStartedSteps(sessionId).toPromise().then(navigateTo => {
            if (navigateTo !== null && navigateTo.stepId !== null) {
                this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId]);
                return;
            }
            this.router.navigate(['/session', sessionId]);
        }).catch((error) => {
            this.alertService.errorApi(error);
        });
    }
}
NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AlertService), i0.ɵɵinject(i3.SignatureWorkflowService), i0.ɵɵinject(i4.AppService), i0.ɵɵinject(i5.SessionService), i0.ɵɵinject(i6.StepService)); }, token: NavigationService, providedIn: "root" });
