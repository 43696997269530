/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CommentData } from '../model/commentData';
import { CreateCommentPayload } from '../model/createCommentPayload';
import { UpdateCommentPayload } from '../model/updateCommentPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CommentService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create comment
     * 
     * @param sessionId Session to which the comment belongs
     * @param stepId Step to which the comment belongs
     * @param payload A comment to be added
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createComment(sessionId: number, stepId: number, payload?: CreateCommentPayload, observe?: 'body', reportProgress?: boolean): Observable<CommentData>;
    public createComment(sessionId: number, stepId: number, payload?: CreateCommentPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommentData>>;
    public createComment(sessionId: number, stepId: number, payload?: CreateCommentPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommentData>>;
    public createComment(sessionId: number, stepId: number, payload?: CreateCommentPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createComment.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling createComment.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/comments`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete comment
     * 
     * @param commentId Comment
     * @param sessionId Session to which the comment belongs
     * @param stepId Step to which the comment belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteComment(commentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteComment(commentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteComment(commentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteComment(commentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commentId === null || commentId === undefined) {
            throw new Error('Required parameter commentId was null or undefined when calling deleteComment.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteComment.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling deleteComment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/comments/${encodeURIComponent(String(commentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get comment
     * 
     * @param commentId Comment
     * @param sessionId Session to which the comment belongs
     * @param stepId Step to which the comment belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComment(commentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<CommentData>;
    public getComment(commentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommentData>>;
    public getComment(commentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommentData>>;
    public getComment(commentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commentId === null || commentId === undefined) {
            throw new Error('Required parameter commentId was null or undefined when calling getComment.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getComment.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getComment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CommentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/comments/${encodeURIComponent(String(commentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search comments matching the filter
     * 
     * @param sessionId Signature session in the context of which the comment was created
     * @param stepId Step in the context of which the comment was created
     * @param ids List of ids
     * @param limit Number of comments to be returned from the search result. This variable works together with offset.
     * @param offset Sets offset which is used as the starting point for fetching the comments. The first comment returned is located just after this identifier. If the offset is null then the first returned comment is the first of the whole result.
     * @param stepId2 Step identifier to limit the scope of the research. This field is mandatory, in fact a research results are always limited to the step.
     * @param text Text to be used during the research of comments
     * @param type Type identifier of the comments to be found. If this field is left null then all comments fulfilling the other criteria are returned no matter the comment type. If this parameter is set then only the comments of this type are taken into account during the research.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchComments(sessionId: number, stepId: number, ids?: Array<number>, limit?: number, offset?: number, stepId2?: number, text?: string, type?: 'SIMPLE' | 'REJECTION' | 'REINSTATEMENT' | 'REUPLOAD' | 'REMINDER', observe?: 'body', reportProgress?: boolean): Observable<Array<CommentData>>;
    public searchComments(sessionId: number, stepId: number, ids?: Array<number>, limit?: number, offset?: number, stepId2?: number, text?: string, type?: 'SIMPLE' | 'REJECTION' | 'REINSTATEMENT' | 'REUPLOAD' | 'REMINDER', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CommentData>>>;
    public searchComments(sessionId: number, stepId: number, ids?: Array<number>, limit?: number, offset?: number, stepId2?: number, text?: string, type?: 'SIMPLE' | 'REJECTION' | 'REINSTATEMENT' | 'REUPLOAD' | 'REMINDER', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CommentData>>>;
    public searchComments(sessionId: number, stepId: number, ids?: Array<number>, limit?: number, offset?: number, stepId2?: number, text?: string, type?: 'SIMPLE' | 'REJECTION' | 'REINSTATEMENT' | 'REUPLOAD' | 'REMINDER', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling searchComments.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling searchComments.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids) {
            ids.forEach((element) => {
                queryParameters = queryParameters.append('ids', <any>element);
            })
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (stepId2 !== undefined && stepId2 !== null) {
            queryParameters = queryParameters.set('stepId', <any>stepId2);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('text', <any>text);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CommentData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/comments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update comment
     * 
     * @param commentId Comment
     * @param sessionId Session to which the comment belongs
     * @param stepId Step to which the comment belongs
     * @param payload Comment data to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateComment(commentId: number, sessionId: number, stepId: number, payload?: UpdateCommentPayload, observe?: 'body', reportProgress?: boolean): Observable<CommentData>;
    public updateComment(commentId: number, sessionId: number, stepId: number, payload?: UpdateCommentPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommentData>>;
    public updateComment(commentId: number, sessionId: number, stepId: number, payload?: UpdateCommentPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommentData>>;
    public updateComment(commentId: number, sessionId: number, stepId: number, payload?: UpdateCommentPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commentId === null || commentId === undefined) {
            throw new Error('Required parameter commentId was null or undefined when calling updateComment.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateComment.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateComment.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommentData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/comments/${encodeURIComponent(String(commentId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
