/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toggle-switch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toggle-switch.component";
var styles_ToggleSwitchComponent = [i0.styles];
var RenderType_ToggleSwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToggleSwitchComponent, data: {} });
export { RenderType_ToggleSwitchComponent as RenderType_ToggleSwitchComponent };
function View_ToggleSwitchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "switch switch-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setValue("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_ToggleSwitchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "toggle-switch"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "toggle-switch-disabled": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "switch-selector"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "toLeft": 0, "toCenter": 1, "toRight": 2 }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "switch switch-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setValue("false") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleSwitchComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "switch switch-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setValue("true") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "toggle-switch"; var currVal_1 = _ck(_v, 3, 0, _co.disabled); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "switch-selector"; var currVal_3 = _ck(_v, 7, 0, (_co.switchValue === "false"), (_co.switchValue === ""), (_co.switchValue === "true")); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = _co.canBeNull; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_ToggleSwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toggle-switch", [], null, null, null, View_ToggleSwitchComponent_0, RenderType_ToggleSwitchComponent)), i1.ɵdid(1, 49152, null, 0, i3.ToggleSwitchComponent, [], null, null)], null, null); }
var ToggleSwitchComponentNgFactory = i1.ɵccf("app-toggle-switch", i3.ToggleSwitchComponent, View_ToggleSwitchComponent_Host_0, { switchValue: "switchValue", disabled: "disabled", canBeNull: "canBeNull" }, { onChange: "onChange" }, []);
export { ToggleSwitchComponentNgFactory as ToggleSwitchComponentNgFactory };
