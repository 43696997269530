<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Orely Signature</h4>
  <button (click)="activeModal.close('CLOSED')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" width="500px">
  <div [hidden]="!allowIFrame">
    <iframe #signaturePdfIframe frameborder="0" height="700px"
            id="signaturePdfIframe" name="orely"
            scrolling="yes" width="100%">
    </iframe>
  </div>
  <div [hidden]="allowIFrame">
    Loading...
  </div>
</div>
<div class="modal-footer">
</div>
