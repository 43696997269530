/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-tenant-name.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/layouts/full-screen/full-screen.component.ngfactory";
import * as i3 from "../../core/layouts/full-screen/full-screen.component";
import * as i4 from "@angular/common";
import * as i5 from "../../core/pipes/translate.pipe";
import * as i6 from "../../core/service/translate.service";
import * as i7 from "./no-tenant-name.component";
import * as i8 from "@angular/router";
import * as i9 from "../../services/services/configuration.service";
import * as i10 from "../../services/services/visual-identity.service";
var styles_NoTenantNameComponent = [i0.styles];
var RenderType_NoTenantNameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoTenantNameComponent, data: {} });
export { RenderType_NoTenantNameComponent as RenderType_NoTenantNameComponent };
function View_NoTenantNameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "w-100"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "data:image/png;base64,", _co.logo, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_NoTenantNameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "app-full-screen", [["mode", "container-s"]], null, null, null, i2.View_FullScreenComponent_0, i2.RenderType_FullScreenComponent)), i1.ɵdid(1, 49152, null, 0, i3.FullScreenComponent, [], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(2, 0, null, 2, 12, "div", [["class", "d-flex flex-column align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoTenantNameComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i1.ChangeDetectorRef, i6.TranslateService]), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "span", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToDefaultApplicationUrl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-home"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i1.ChangeDetectorRef, i6.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container-s"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.logo; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("NO_TENANT_NAME.MESSAGE")); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("NO_TENANT_NAME.BACK_MAIN_SPACE")); _ck(_v, 13, 0, currVal_3); }); }
export function View_NoTenantNameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-tenant-name", [], null, null, null, View_NoTenantNameComponent_0, RenderType_NoTenantNameComponent)), i1.ɵdid(1, 114688, null, 0, i7.NoTenantNameComponent, [i8.Router, i9.ConfigurationService, i10.VisualIdentityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoTenantNameComponentNgFactory = i1.ɵccf("app-no-tenant-name", i7.NoTenantNameComponent, View_NoTenantNameComponent_Host_0, {}, {}, []);
export { NoTenantNameComponentNgFactory as NoTenantNameComponentNgFactory };
