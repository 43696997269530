<app-tooltip [tooltipText]="'HELP.WIZARD_USERS' | translate"></app-tooltip>

<app-wizard-step>
  <div class="fixed-md" content>
    <div *ngIf="selectedUsers && selectedAddressee" class="mt-5 app-wizard-step">

      <!-- Users -->
      <app-endusers-table
        (addEnduser)="addUsers($event)"
        (outputSearch)="onSearchUsers($event)"
        (outputSort)="orderUsers($event)"
        (outputUnsort)="unsortUsers($event)"
        (removeEnduser)="removeUsers($event)"
        (outputUpdateExpected)="onUpdateExpected($event)"
        (replaceUsers)="onReplaceUsers($event.oldId, $event.newUser, selectedUsers)"
        (openModal)="openModal($event.replace, $event.oldEnduserId)"
        [availableEndusers]="availableUsers"
        [disabled]="!!step.startedOn || removingUser || replacingUser"
        [loaderStatus]="loaderStatusUsers"
        [noResult]="'WIZARD.USERS.NO_RESULT' | translate"
        [placeholder]="'WIZARD.USERS.SEARCH' | translate"
        [selectedEndusers]="selectedUsers"
        [singleList]="true"
        [sortable]="true"
        [disabledSelectCircle]="false"
        [canReplace]="true"
        [sortedUsers]="sortedUsers"
        [title]="'WIZARD.USERS.USERS' | translate"
        [idPostfix]="'Users'"
        [isSignerManagement]="true"
        [otherInReplaceMode]="replaceMode?.manager || replaceMode?.watcher"
        (outputReplaceMode)="changeReplaceMode('S')"
        class="fixed-md"
      >
      </app-endusers-table>

      <!--Addressee-->
      <app-endusers-table
        (addEnduser)="addAddressee($event)"
        (outputSearch)="onSearchAddressee($event)"
        (replaceUsers)="onReplaceUsers($event.oldId, $event.newUser, selectedAddressee)"
        (removeEnduser)="removeAddressee($event)"
        (outputUpdateExpected)="onUpdateExpected($event)"
        [availableEndusers]="availableAddressee"
        [disabled]="replacingUser || removingAddresseeOrManager"
        [loaderStatus]="loaderStatusAddressee"
        [noResult]="'WIZARD.USERS.NO_RESULT' | translate"
        [placeholder]="'WIZARD.USERS.SEARCH' | translate"
        [selectedEndusers]="selectedAddressee"
        [singleList]="true"
        [title]="'WIZARD.USERS.WATCHER' | translate"
        [idPostfix]="'Addressee'"
        [canReplace]="true"
        [otherInReplaceMode]="replaceMode?.manager || replaceMode?.signer"
        (outputReplaceMode)="changeReplaceMode('W')"
        class="fixed-md"
      >
      </app-endusers-table>

      <!--Manager-->
      <app-endusers-table
        (addEnduser)="addManager($event)"
        (outputSearch)="onSearchManager($event)"
        (replaceUsers)="onReplaceUsers($event.oldId, $event.newUser, selectedManager)"
        (removeEnduser)="removeManager($event)"
        [availableEndusers]="availableManager"
        [disabled]="replacingUser || removingAddresseeOrManager"
        [loaderStatus]="loaderStatusManager"
        [noResult]="'WIZARD.USERS.NO_RESULT' | translate"
        [placeholder]="'WIZARD.USERS.SEARCH' | translate"
        [selectedEndusers]="selectedManager"
        [singleList]="true"
        [title]="'WIZARD.USERS.MANAGER' | translate"
        [idPostfix]="'Manager'"
        [canReplace]="true"
        [otherInReplaceMode]="replaceMode?.signer || replaceMode?.watcher"
        (outputReplaceMode)="changeReplaceMode('M')"
        class="fixed-md"
      >
      </app-endusers-table>
    </div>
  </div>

  <div footer>
    <div class="fixed-container footer mt-5">
      <div class="fixed-md">
        <app-wizard-step-button [action]="stepActions.PREVIOUS"
                                [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>
        <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                                class="float-right"></app-wizard-step-button>
      </div>
    </div>
  </div>
</app-wizard-step>
