import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';

@Component({
  selector: 'app-modal-confirm-signature',
  templateUrl: './modal-confirm-signature.component.html',
  styleUrls: ['./modal-confirm-signature.component.scss']
})
export class ModalConfirmSignatureComponent {

  constructor(public activeModal: NgbActiveModal,
              public translate: TranslateService) { }

  close(value) {
    return this.activeModal.close(value);
  }

}
