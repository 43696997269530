/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-download-pdf.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-download-pdf.component";
import * as i4 from "../../../services/services/app.service";
var styles_AppDownloadPdfComponent = [i0.styles];
var RenderType_AppDownloadPdfComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppDownloadPdfComponent, data: {} });
export { RenderType_AppDownloadPdfComponent as RenderType_AppDownloadPdfComponent };
function View_AppDownloadPdfComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["downloadPdfButton", 1]], null, 1, "button", [["class", "downloadButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-question"]], null, null, null, null, null))], null, null); }
export function View_AppDownloadPdfComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppDownloadPdfComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.canUploadSignerPdf || _co.canUploadDesignerPdf) || _co.canUploadWatcherPdf) || _co.canUploadADMINPdf); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppDownloadPdfComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-download-pdf", [], null, null, null, View_AppDownloadPdfComponent_0, RenderType_AppDownloadPdfComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppDownloadPdfComponent, [i4.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppDownloadPdfComponentNgFactory = i1.ɵccf("app-app-download-pdf", i3.AppDownloadPdfComponent, View_AppDownloadPdfComponent_Host_0, { rightOfUser: "rightOfUser" }, {}, []);
export { AppDownloadPdfComponentNgFactory as AppDownloadPdfComponentNgFactory };
