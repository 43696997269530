<div *ngIf="!isLoggedIn()" class="noHeader"></div>
<nav (mouseleave)="closeAll()" *ngIf="isLoggedIn() && !appService.isIframe()" class="header">
  <ul class="header-left">
    <li class="header-logo">
      <img (click)="appService?.getUser()?.enrolled? toRoute('/dashboard') : undefined"
           [src]="'data:image/png;base64,' + visualIdentityService.logo"
           alt="Logo">
    </li>
    <li (mouseover)="closeAll();"
        *ngIf="(appService?.getUser()?.enrolled && isLoggedIn()) || displayHome"
        class="header-item">
      <a (click)="toRoute('/dashboard'); closeAll()"><i
        class="fas fa-home"></i>{{'HEADER.HOME' | translate}}</a>
    </li>
    <li (mouseover)="closeAll();"
        *ngIf="appService.userHasEntitlement(appService.ent.signature_book)"
        class="header-item">
      <a (click)="closeAll()" [queryParamsHandling]="'preserve'" [routerLink]="['/signature-book']"><i
        class="fas fa-book"></i>{{'DASHBOARD.SIGNATURE_BOOK' | translate}}</a>
    </li>

    <li (mouseover)="closeAll(); over1 = appService?.getUser()?.enrolled"
        *ngIf="(enduser$ | async) && isLoggedIn()"
        class="header-item"
        [ngClass]="{'not-enrolled': !(enduser$ | async)?.enrolled, 'open': over1}">
      <a>
        <i class="fas fa-user"></i>
        {{((enduser$ | async).firstName + ' ' + ((enduser$ | async).lastName | slice:0:1)).length >= 12 ?
          ((enduser$ | async).firstName + ' ' + ((enduser$ | async).lastName | slice:0:1) + '.' | slice:0:12) + '..' :
          ((enduser$ | async).firstName + ' ' + ((enduser$ | async).lastName | slice:0:1) + '.')}}
      </a>
      <ul *ngIf="(enduser$ | async)?.enrolled"
          (mouseleave)="over1=false"
          [class.open]="over1"
          class="header-submenu">
        <li>
          <a (click)="closeAll(); toRoute('/profile')">{{'HEADER.USER_PROFILE' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.order_certificate)">
          <a (click)="closeAll(); toRoute('/order')">{{'HEADER.ORDER' | translate}}</a>
        </li>
        <li>
          <a (click)="closeAll(); openCreateModal()">{{'HEADER.USER_PREFERENCES' | translate}}</a>
        </li>
      </ul>
    </li>
    <li (mouseover)="closeAll(); over2 = true"
        *ngIf="(enduser$ | async) && (enduser$ | async)?.enrolled && isLoggedIn() && appService.userHasAdminEntitlement()"
        [class.open]="over2"
        class="header-item">
      <a><i class="fas fa-users-cog"></i>{{'HEADER.ADMIN_TITLE' | translate}}</a>
      <ul (mouseleave)="over2=false"
          [class.open]="over2"
          class="header-submenu">
        <li *ngIf="appService.userHasEntitlement(appService.ent.manage_user)">
          <a
            (click)="closeAll(); toRoute('/admin/users')">{{'HEADER.ADMIN_USERS_PROFILE' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.manage_circles)">
          <a
            (click)="closeAll(); toRoute('/admin/circles')">{{'HEADER.ADMIN_CIRCLES' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.manage_entitlements)">
          <a
            (click)="closeAll(); toRoute('/admin/entitlements')">{{'HEADER.ADMIN_USERS' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.manage_translation)">
          <a
            (click)="closeAll(); toRoute('/admin/labels')">{{'HEADER.ADMIN_LABELS' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.manage_layout)">
          <a
            (click)="closeAll(); toRoute('/admin/color-picker')">{{'HEADER.ADMIN_COLOR_PICKER' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.manage_legal_notices)">
          <a
            (click)="closeAll(); toRoute('/admin/legal-motions')">{{'HEADER.ADMIN_LEGAL_MOTIONS' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.manage_notifications)">
          <a
            (click)="closeAll(); toRoute('/admin/notifications')">{{'HEADER.ADMIN_NOTIFICATIONS' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.manage_metadata)">
          <a (click)="closeAll(); toRoute('/admin/tags')">{{'HEADER.ADMIN_TAGS' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.super_admin)">
          <a
            (click)="closeAll(); toRoute('/admin/statistics')">{{'HEADER.ADMIN_STATS' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.organization)">
          <a
            (click)="closeAll(); toRoute('/admin/organizations')">{{'HEADER.ADMIN_ORGANIZATION' | translate}}</a>
        </li>
        <li *ngIf="displayRolesTemplatesButton && appService.userHasEntitlement(appService.ent.template_admin)">
          <a (click)="closeAll(); toRoute('/admin/templates-entitlements')">{{'HEADER.ADMIN_TEMPLATES-ENTITLEMENTS' | translate}}</a>
        </li>
        <li *ngIf="appService.userHasEntitlement(appService.ent.admin) && displayApiButton">
          <a (click)="closeAll(); toApi()">API</a>
        </li>
      </ul>
    </li>
    <li (mouseenter)="!over3 ? closeAll() : null; over3 = true"
        *ngIf="(enduser$ | async) && (enduser$ | async)?.enrolled && isLoggedIn()"
        [class.open]="over3"
        class="header-item">
      <a [class.act1]="emails.length===1"
         [class.act2]="emails.length===2"
         [class.act3]="emails.length>2">
        <i class="fas fa-bell"></i>{{'HEADER.SETTING_NOTIFICATIONS' | translate}}
      </a>
      <div *ngIf="emails.length > 0" class="notifications-scroller">
        <ul (mouseleave)="over3=false"
            [class.open]="over3"
            class="header-submenu header-notifications">
          <li *ngFor="let email of emails; let i = index">
            <a>
              <i (click)="removeNotif(i)" class="fas fa-trash-alt delete-btn"></i>
              <div [innerHTML]="email.subject | truncate:{max: 55}"></div>
              <div [innerHTML]="email.body | truncate:{max: 65}"></div>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li
      *ngIf="appService.userHasEntitlement(appService.ent.signature_validation)"
      (mouseover)="closeAll();"         
      class="header-item">
        <a (click)="toRoute('/validation'); closeAll()">
          <i class="fas fa-check"></i>{{'HEADER.VALIDATION' | translate}}</a>
    </li>

  </ul>
  <ul class="header-right">
    <li (mouseleave)="closeAll()"
        (mouseover)="closeAll(); over4 = true"
        [class.open]="over4"
        [hidden]="!isLoggedIn()"
        class="header-item">
      <a><i class="fas fa-language"></i>
        <span *ngIf="selectedLanguage">{{selectedLanguage | uppercase}}</span>
      </a>
      <ul (mouseleave)="over4=false"
          [class.open]="over4"
          class="header-submenu">
        <li *ngFor="let lang of this.languagesService.languages">
          <a (click)="closeAll(); changeLang(lang)"
             [ngClass]="{'selected': selectedLanguage === lang}">
            {{lang | uppercase}}
          </a>
        </li>
      </ul>
    </li>
    <li [hidden]="!isLoggedIn()"
        class="header-item">
      <a (click)="closeAll(); logout()"><i class="fas fa-sign-out-alt header-icon-btn"></i></a>
    </li>
  </ul>
</nav>
<div (click)="closeAll()" *ngIf="isOpen" id="backdrop"></div>


