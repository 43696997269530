import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CONFIG, IMAGES} from '../../app.constant';
import {ConfigurationService} from '../../services/services/configuration.service';
import {VisualIdentityService} from '../../services/services/visual-identity.service';

@Component({
  selector: 'app-no-tenant-name', templateUrl: './no-tenant-name.component.html', styleUrls: ['./no-tenant-name.component.scss']
})
export class NoTenantNameComponent implements OnInit {

  logo: string;

  constructor(
    private router: Router,
    private readonly configurationService: ConfigurationService,
    private readonly visualIdentityService: VisualIdentityService
  ) {}

  ngOnInit() {
    this.configurationService.platformConfig.then(platformConfig => {
      if (platformConfig.visualIdentity && platformConfig.visualIdentity.logo) {
        this.logo = platformConfig.visualIdentity.logo;
      } else {
        this.setDefaultLogo();
      }
    }).catch(() =>
      this.setDefaultLogo()
    );
  }

  goToDefaultApplicationUrl() {
    const defaultTenantName = CONFIG.TENANT.DEFAULT;
    // FIXME BDX Redo this configurationService
    this.configurationService.initTenantConfiguration(defaultTenantName);
    this.visualIdentityService.initVisualIdentity(defaultTenantName);
    return this.router.navigateByUrl(`/login?tenantName=${defaultTenantName}`);
  }

  private setDefaultLogo() {
    this.logo = IMAGES.DEFAULT_LUXTRUST_COSI_LOGO;
  }
}
