import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import * as introJs from 'intro.js/intro.js';
import {EnduserPreferenceService} from 'luxtrust-cosi-api/api/enduserPreference.service';
import {Observable} from 'rxjs';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';

@Component({
  templateUrl: './modal-event-notifications.component.html', styleUrls: ['./modal-event-notifications.component.scss']
})
export class ModalEventNotificationsComponent implements OnInit {
  eventNotificationsForm: FormGroup;
  submitted = false;
  introJSPrefVisit = introJs();
  currentUser;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private endUserPreferenceService: EnduserPreferenceService,
              private translateService: TranslateService,
              private appService: AppService,
              private alertService: AlertService, private translate: TranslateService) {

  }

  get eventNotificationTypesArray(): FormArray {
    return <FormArray>this.eventNotificationsForm.get('eventNotificationTypes');
  }

  initTuto() {
    this.translateService.get('EVENTNOTIFICATIONS.TUTORIAL.INTRO').subscribe((res) => {
      this.introJSPrefVisitSetup();
    });
  }

  ngOnInit() {
    this.initTuto();
    this.currentUser = this.appService.getCurrentUserId();
    this.getPreferences();
  //  let valueLSPref = localStorage.getItem("tutoPref" + this.currentUser);
   // if (!valueLSPref) {
    //  this.launchTuto().subscribe();
     // localStorage.setItem('tutoPref' + this.currentUser, 'true');
   // }
    this.eventNotificationsForm = this.formBuilder.group({
      eventNotificationTypes: this.formBuilder.array([]), autoSignatureBook: [false]
    });
  }

  introJSPrefVisitSetup() {
    this.introJSPrefVisit.setOptions({
      overlayOpacity: 0.5,
      exitOnOverlayClick: false,
      prevLabel: this.translate.instant('NEW_SESSION.TUTORIAL.PREVLABEL'),
      nextLabel: this.translate.instant('NEW_SESSION.TUTORIAL.NEXTLABEL'),
      skipLabel: this.translate.instant('NEW_SESSION.TUTORIAL.SKIPLABEL'),
      doneLabel: this.translate.instant('NEW_SESSION.TUTORIAL.DONELABEL'),
      steps: [
        {
          intro: this.translateService.instant('EVENTNOTIFICATIONS.TUTORIAL.INTRO')
        },
        {
          element: '#visitPrefStep1',
          position: 'top',
          intro: this.translateService.instant('EVENTNOTIFICATIONS.TUTORIAL.FIRSTSTEP')
        }
      ]
    });
  }

  launchTuto() {
    let tutoObservable: Observable<unknown>;
    tutoObservable = new Observable(observer => {
      setTimeout(() => {
        this.introJSPrefVisit.start();
      }, 300);
    });
    return tutoObservable;
  }

  submitNotifications() {
    this.submitted = true;
    this.endUserPreferenceService.updateEnduserPreferences(this.appService.getCurrentUserId(), {
      preferences: {
        events: this.eventNotificationTypesArray.value, autoSignatureBook: this.eventNotificationsForm.controls.autoSignatureBook.value
      }
    }).subscribe(() => {
      this.alertService.success('EVENTNOTIFICATIONS.SUCCESS');
      this.activeModal.close();
    }, (error: ApiError) => {
      this.submitted = false;
      this.alertService.errorApiWithCustomMessage('EVENTNOTIFICATIONS.ERROR', error);
    });
  }

  getPreferences() {
    this.endUserPreferenceService.getEnduserPreferences(this.appService.getCurrentUserId()).subscribe(preferences => {
      preferences.preferences.events = preferences.preferences.events.sort((a, b) => a.label.localeCompare(b.label));
      preferences.preferences.events.forEach((entd, index) => {
        this.eventNotificationTypesArray.push(this.formBuilder.group({
          label: [entd.label], mail: [entd.mail], sms: [entd.sms], fetch: [entd.fetch], type: [entd.type]
        }));
      });
      this.eventNotificationsForm.controls.autoSignatureBook.patchValue(preferences.preferences.autoSignatureBook);
    }, (error: ApiError) => this.alertService.errorApi(error));
  }
}
