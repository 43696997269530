import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {CreateSessionPayload} from 'luxtrust-cosi-api/model/createSessionPayload';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {Subscription} from 'rxjs';
import {ValidatorHelper} from 'src/app/services/validators/validators.helper';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';

export interface ModalCloneSessionOutput {
  sessionId: number;
  session: SessionData;
  configuration: boolean;
}

@Component({
  templateUrl: './modal-clone-session.component.html'
})
export class ModalCloneSessionComponent implements AfterViewInit, OnInit, OnDestroy {

  @Output() output: EventEmitter<ModalCloneSessionOutput> = new EventEmitter();
  @ViewChild('sessionName', {
    read: ElementRef, static: false
  }) inputSessionName: ElementRef;
  form: FormGroup;
  submitted = false;
  showLengthError = false;
  private createSessionSubscription: Subscription;

  isTemplate: boolean;
  private clonedSessionLabel: string;
  private clonedSessionId: number;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    public activeModal: NgbActiveModal
  ) { }

  get sessionName() {
    return this.form.get('sessionName');
  }

  ngOnInit() {
    this.form = this.createFormGroup();
    this.form.setValue({ sessionName: this.clonedSessionLabel });
  }

  ngAfterViewInit() {
    this.inputSessionName.nativeElement.focus();
  }

  ngOnDestroy() {
    if (this.createSessionSubscription) {
      this.createSessionSubscription.unsubscribe();
    }
  }

  checkLength() {
    this.showLengthError = this.sessionName.hasError('maxlength');
  }

  cloneSession() {
    this.submitted = true;
    const cloneSessionPayload: CreateSessionPayload = {
      label: this.form.value.sessionName.trim(),
      templateId: this.clonedSessionId,
      simple: true
    };

    if (this.createSessionSubscription) {
      this.createSessionSubscription.unsubscribe();
    }
    this.createSessionSubscription = this.sessionService.createSession(cloneSessionPayload)
      .subscribe(
        (newSession: SessionData) => {
          if (this.isTemplate) {
            this.sessionService.makeSessionTemplate(newSession.id).subscribe(() => {
              this.emitCreated(newSession);
            }, (error: ApiError) => {
              this.alertService.errorApi(error);
              this.submitted = false;
              this.output.emit();
            });
          } else {
            this.emitCreated(newSession);
          }
        }, (error: ApiError) => {
          this.alertService.errorApi(error);
          this.submitted = false;
          this.output.emit();
        });
  }

  private emitCreated(newSession: SessionData) {
    this.output.emit({
      sessionId: newSession.id,
      session: newSession,
      configuration: newSession.configuration
    });
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      sessionName: new FormControl(
        '',
        [
          Validators.required,
          Validators.maxLength(255),
          ValidatorHelper.noWhitespace
        ]
      )});
  }

}
