import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';

@Component({
  selector: 'app-tooltip', templateUrl: './tooltip.component.html', styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnChanges {

  showTip = false;
  text: string;
  @Input() tooltipText: any[];

  @ViewChild('toolTipButton', {
    read: ElementRef, static: false
  }) toolTipButton;
  @ViewChild('toolTipWindow', {
    read: ElementRef, static: false
  }) toolTipWindow;

  ngOnChanges() {
    setTimeout(() => {
      this.text = this.tooltipText && this.tooltipText.join('');
    }, 1000);
  }

  popupTip() {
    this.showTip = !this.showTip;
  }
}
