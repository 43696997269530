/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-builder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/template-editor/template-editor.component.ngfactory";
import * as i3 from "../../../shared/components/template-editor/template-editor.component";
import * as i4 from "../../../core/pipes/translate.pipe";
import * as i5 from "../../../core/service/translate.service";
import * as i6 from "./template-builder.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../../luxtrust-cosi-api/api/pattern.service";
import * as i9 from "../../../services/services/alert-service";
var styles_TemplateBuilderComponent = [i0.styles];
var RenderType_TemplateBuilderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplateBuilderComponent, data: {} });
export { RenderType_TemplateBuilderComponent as RenderType_TemplateBuilderComponent };
export function View_TemplateBuilderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-editor", [], null, [[null, "closeEvent"], [null, "inputEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeEvent" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } if (("inputEvent" === en)) {
        var pd_1 = (_co.onInput($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TemplateEditorComponent_0, i2.RenderType_TemplateEditorComponent)), i1.ɵdid(1, 49152, null, 0, i3.TemplateEditorComponent, [], { displayCloseButton: [0, "displayCloseButton"], patternData: [1, "patternData"] }, { inputEvent: "inputEvent", closeEvent: "closeEvent" }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.patternData; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((((_co.patternData == null) ? null : ((_co.patternData.body == null) ? null : _co.patternData.body.length)) < 1) || _co.saving); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("MODAL_TEMPLATE_BUILDER.SAVE")); _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("MODAL_TEMPLATE_BUILDER.CANCEL")); _ck(_v, 7, 0, currVal_4); }); }
export function View_TemplateBuilderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TemplateBuilderComponent_0, RenderType_TemplateBuilderComponent)), i1.ɵdid(1, 49152, null, 0, i6.TemplateBuilderComponent, [i7.NgbActiveModal, i8.PatternService, i9.AlertService], null, null)], null, null); }
var TemplateBuilderComponentNgFactory = i1.ɵccf("ng-component", i6.TemplateBuilderComponent, View_TemplateBuilderComponent_Host_0, {}, {}, []);
export { TemplateBuilderComponentNgFactory as TemplateBuilderComponentNgFactory };
