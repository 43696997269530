import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StepTagService} from 'luxtrust-cosi-api/api/stepTag.service';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {UpdateDocumentPayload} from 'luxtrust-cosi-api/model/updateDocumentPayload';
import {REGEXES} from '../../../app.constant';
import {StepTag} from '../../../services/enum/step-tag';
import {AppService} from '../../../services/services/app.service';
import {Ids} from '../../../services/utils/ids';
import {SignerDetails} from '../document-item/document-item.component';
import {Status} from '../input-loader/input-loader.component';

import { ModalYesNoComponent } from '../modal-yes-no/modal-yes-no.component';

@Component({
  selector: 'app-document-table', templateUrl: './document-table.component.html', styleUrls: ['./document-table.component.css']
})
export class DocumentTableComponent implements OnInit, OnChanges {

  @ViewChild('uploadField', {static: false}) uploadFieldRef: ElementRef;
  @Input() session: SessionData;
  @Input() step: StepData;
  @Input() title: string;
  @Input() noResult: string;
  @Input() buttonLabel: string;
  @Input() annexe = false;
  @Input() mutual = false;
  @Input() documents: DocumentData[];
  @Input() signingUsers: EnduserData[] = [];
  @Input() signatures: {[id: string]: SignerDetails[]} = {};
  @Input() isLoading = false;
  @Input() hasFailed = false;
  @Input() sprofiles: SprofileData[];
  @Input() disabled: boolean;
  @Input() canAdd = true;
  @Input() canUpdate = true;
  @Input() canClone = true;
  @Input() canChangeSprofile = true;
  @Input() isConfigurator = false;
  @Input() signersByDocuments: { document: DocumentData, signers: EnduserData[] }[];
  @Output() onClone: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();
  @Output() onDelete: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();
  @Output() onAddAcroform: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();
  @Output() onUpdate: EventEmitter<{id: number, updateDocumentPayload: UpdateDocumentPayload}> = new EventEmitter<{id: number, updateDocumentPayload: UpdateDocumentPayload}>();
  @Output() onUpload: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() onMutual: EventEmitter<{document: DocumentData, isMutual: boolean, reload: boolean}> = new EventEmitter<{document: DocumentData, isMutual: boolean, reload: boolean}>();
  @Output() onMutualDocumentIn: EventEmitter<{document: DocumentData, isMutual: boolean}> = new EventEmitter<{document: DocumentData, isMutual: boolean}>();
  @Output() onMutualAppendixIn: EventEmitter<{document: DocumentData, isMutual: boolean}> = new EventEmitter<{document: DocumentData, isMutual: boolean}>();
  @Output() onFailed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onCloneInConfigurator: EventEmitter<{ document: DocumentData, file: File }> = new EventEmitter<{ document: DocumentData, file: File }>();

  @Output() openModal: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();
  @Output() onReuploadDoc: EventEmitter<{ document: DocumentData, file: File }> = new EventEmitter<{ document: DocumentData, file: File }>();
  status: Status;
  id: string;
  acceptedMimeTypes: string;
  @Input() hasAtLeast1Signature = false;
  @Input() hasStepTagPositionSignatureLocation = false;
  @Input() oneStepConfigurator = false;

  constructor(public appService: AppService,
              public modal: NgbModal) {
  }

  ngOnInit() {
    this.id = Ids.generateId();
    this.acceptedMimeTypes = this.getAcceptedMimeTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isLoading !== undefined) {
      this.isLoading ? this.status = Status.LOAD : this.status = Status.SUCCESS;
    }
  }

  uploadDocument(event) {
    if (event.target.files && event.target.files.length) {
      const files: FileList = event.target.files;
      this.onUpload.emit(files);
    }
    // Undefined donne une erreur dans la console lorsqu'on upload un fichier dans le wizard - document
    // tslint:disable-next-line:no-null-keyword
    this.uploadFieldRef.nativeElement.value = null;
  }

  addDocumentWithConfirmation() {
    if (!this.oneStepConfigurator) {
      const modalDelete = this.modal.open(ModalYesNoComponent, {
        backdrop: false, centered: true
      });
      modalDelete.componentInstance.message = 'WIZARD.DOCUMENTS.UPLOAD_CONFIGURATOR';
      modalDelete.componentInstance.noIsDanger = true;
      modalDelete.result.then(value => {
        if (value === 'yes') {
          this.uploadFieldRef.nativeElement.click();
        }
        modalDelete.close();
      });
    } else {
      this.uploadFieldRef.nativeElement.click();
    }
  }

  reuploadDocument(document: DocumentData, file: File) {
    this.onReuploadDoc.emit({document, file});
  }

  updateDocument(document: DocumentData, sprofile: string) {
    if (document.sprofileCode !== sprofile) {
      const updateDocumentPayload: UpdateDocumentPayload = {
        sprofileCode: sprofile, shared: document.shared, name: document.name
      };

      this.onUpdate.emit({
        id: document.id, updateDocumentPayload
      });
    }
  }

  canDelete(): boolean {
    return this.canAdd;
  }

  private getAcceptedMimeTypes() {
    const defaultMimeTypesTag =  this.step.tags.find(tag => tag.alias === StepTag.AUTHORISED_MIME_TYPE);
    if (defaultMimeTypesTag && REGEXES.MIME_TYPE_COMMA_SEPARATED_LIST.test(defaultMimeTypesTag.value)) {
      return defaultMimeTypesTag.value;
    } else if (this.appService.config) {
      const defaultConfig = this.appService.config;
      if (defaultConfig) {
        return defaultConfig.allowedMime.join(',');
      }
    }
    return '*';
  }

}
