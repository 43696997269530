/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * General purpose tag applied on signature sessions or steps
 */
export interface TagData { 
    /**
     * Alias or name
     */
    alias?: string;
    /**
     * Indicates if the tag is to show in the configurator
     */
    configurator?: boolean;
    /**
     * Default value
     */
    defaultValue?: string;
    /**
     * Stored encrypted
     */
    encrypted?: boolean;
    /**
     * Unique identifier
     */
    id?: number;
    /**
     * Multilingual label
     */
    label?: string;
    /**
     * Indicates if the tag is mandatory
     */
    mandatory?: boolean;
    /**
     * Input mask
     */
    mask?: string;
    /**
     * Order of the tags (0 if not defined)
     */
    orderIndex?: number;
    /**
     * Possible values for the tag
     */
    possibleValues?: Array<string>;
    readOnly?: boolean;
    /**
     * 'true' if the tag doesn't belong to a tenant or is tagged as
     */
    system?: boolean;
    /**
     * Type
     */
    type?: TagData.TypeEnum;
    /**
     * Value
     */
    value?: string;
}
export namespace TagData {
    export type TypeEnum = 'INPUT' | 'LIST' | 'TOGGLE' | 'DATE' | 'DATETIME' | 'PERIOD' | 'LONG' | 'MEMO' | 'LINK';
    export const TypeEnum = {
        INPUT: 'INPUT' as TypeEnum,
        LIST: 'LIST' as TypeEnum,
        TOGGLE: 'TOGGLE' as TypeEnum,
        DATE: 'DATE' as TypeEnum,
        DATETIME: 'DATETIME' as TypeEnum,
        PERIOD: 'PERIOD' as TypeEnum,
        LONG: 'LONG' as TypeEnum,
        MEMO: 'MEMO' as TypeEnum,
        LINK: 'LINK' as TypeEnum
    };
}
