import {Injectable} from '@angular/core';
import {SprofileService} from 'luxtrust-cosi-api/api/sprofile.service';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {of, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SprofileKey} from '../enum/sprofile-key';
import {AppService} from './app.service';

@Injectable()
export class SprofileWrapperService {

  private cache: SprofileData[];

  constructor(private appService: AppService, private sprofileService: SprofileService) {
  }

  getSprofileList(): Observable<SprofileData[]> {
    if (this.cache) {
      return of(this.cache);
    }
    return this.sprofileService.getSprofileList().pipe(tap(sprofiles => {
      this.cache = sprofiles;
    }));
  }
}
