<app-tooltip [tooltipText]="'HELP.WIZARD_NOTIFICATIONS' | translate"></app-tooltip>

<app-wizard-step>
  <div class="fixed-lg">
    <!-- Reminders -->
    <!-- <h4 class="mb-4">{{'WIZARD.NOTIFICATIONS.TITLE' | translate}}</h4> -->

    <app-items-table [borderBottom]="true"
                     [items]="notificationRules" [singleList]="true">
      <ng-template #itemsCards
                   let-stepNotificationRule>
        <div class="size-row">
          <div class="collapsible-header w-100" (click)="toggleCollapse(stepNotificationRule)">
            <h2 class="col-sm-5 col-form-label text-left step-notification-title">
              {{('WIZARD.NOTIFICATIONS.' + stepNotificationRule.title) | translate}}
            </h2>
            <i class="fa" [ngClass]="{'fa-caret-down': isCollapsed(stepNotificationRule),
                                      'fa-caret-up': !isCollapsed(stepNotificationRule)}">
            </i>
          </div>
          <div [ngbCollapse]="isCollapsed(stepNotificationRule)" class="col-12 collapse-content">
            <div class="table" [ngClass]="{'button-box':!isIE, 'button-box-ie':isIE}">
              <!-- Mail Option -->
              <app-notification-option-card [title]="'WIZARD.NOTIFICATIONS.MAIL'"
                                            [isIE]="isIE"
                                            [stepNotificationRule]="stepNotificationRule"
                                            [patternToCheck]="PatternFilterChannelEnum.EMAIL"
                                            [hasPatternsBound]="hasPatternsBoundOn(stepNotificationRule.value, PatternFilterChannelEnum.EMAIL)"
                                            [disabled]="creatingPattern || savingRules"
                                            (optionUpdateEvent)="updateMailValue(stepNotificationRule, $event)"
                                            (editPatternEvent)="editPattern(stepNotificationRule.value, PatternFilterChannelEnum.EMAIL)"
                                            (removePatternEvent)="removePattern(stepNotificationRule.value, PatternFilterChannelEnum.EMAIL)"
                                            (createPatternEvent)="createPattern(stepNotificationRule.value, PatternFilterChannelEnum.EMAIL)">
              </app-notification-option-card>
              <!-- SMS Options -->
              <app-notification-option-card [title]="'WIZARD.NOTIFICATIONS.SMS'"
                                            [isIE]="isIE"
                                            [stepNotificationRule]="stepNotificationRule"
                                            [patternToCheck]="PatternFilterChannelEnum.SMS"
                                            [hasPatternsBound]="hasPatternsBoundOn(stepNotificationRule.value, PatternFilterChannelEnum.SMS)"
                                            [disabled]="creatingPattern || savingRules"
                                            (optionUpdateEvent)="updateSmsValue(stepNotificationRule, $event)"
                                            (editPatternEvent)="editPattern(stepNotificationRule.value, PatternFilterChannelEnum.SMS)"
                                            (removePatternEvent)="removePattern(stepNotificationRule.value, PatternFilterChannelEnum.SMS)"
                                            (createPatternEvent)="createPattern(stepNotificationRule.value, PatternFilterChannelEnum.SMS)">
              </app-notification-option-card>
              <!-- In App Options -->
              <app-notification-option-card [title]="'WIZARD.NOTIFICATIONS.TO_FETCH'"
                                            [isIE]="isIE"
                                            [stepNotificationRule]="stepNotificationRule"
                                            [patternToCheck]="PatternFilterChannelEnum.APP"
                                            [hasPatternsBound]="hasPatternsBoundOn(stepNotificationRule.value, PatternFilterChannelEnum.APP)"
                                            [disabled]="creatingPattern || savingRules"
                                            (optionUpdateEvent)="updateAppValue(stepNotificationRule, $event)"
                                            (editPatternEvent)="editPattern(stepNotificationRule.value, PatternFilterChannelEnum.APP)"
                                            (removePatternEvent)="removePattern(stepNotificationRule.value, PatternFilterChannelEnum.APP)"
                                            (createPatternEvent)="createPattern(stepNotificationRule.value, PatternFilterChannelEnum.APP)">
              </app-notification-option-card>
            </div>
          </div>
        </div>
      </ng-template>
    </app-items-table>
  </div>

  <div class="fixed-container" footer>
    <div class="fixed-lg">
      <app-wizard-step-button [action]="stepActions.PREVIOUS"
                              [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>
      <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                              class="float-right"></app-wizard-step-button>
    </div>
  </div>
</app-wizard-step>
