import {HostListener, Input, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';

export abstract class FormElement<T> implements ControlValueAccessor {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() hint: string;
  @Input() disabled: boolean;
  @Input() errors: {[key: string]: string; };
  @Input() navigateOnClick = {
    commands: [], params: {}
  };
  @Input() value;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.id = this.id || Date.now().toString() + Math.random().toString();
  }

  @HostListener('click') onClick() {
  }

  onChange = (value: T) => {
  }
  onTouched = (value: T) => {
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value: T) {
    this.onChange(value ? value : null);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  getFirstError(): string {
    if (this.ngControl.errors) {
      return this.errors ? this.errors[Object.keys(this.ngControl.errors)[0]] : Object.keys(this.ngControl.errors)[0];
    }
    return null;
  }
}
