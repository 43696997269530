/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Label to delete
 */
export interface DeleteLabelPayload { 
    /**
     * Label language
     */
    localeCode?: DeleteLabelPayload.LocaleCodeEnum;
    /**
     * Label target id (session_id, step_id...)
     */
    targetId?: number;
    /**
     * Label type
     */
    type?: DeleteLabelPayload.TypeEnum;
}
export namespace DeleteLabelPayload {
    export type LocaleCodeEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LocaleCodeEnum = {
        EN: 'EN' as LocaleCodeEnum,
        FR: 'FR' as LocaleCodeEnum,
        DE: 'DE' as LocaleCodeEnum,
        PT: 'PT' as LocaleCodeEnum,
        IT: 'IT' as LocaleCodeEnum,
        NL: 'NL' as LocaleCodeEnum,
        SP: 'SP' as LocaleCodeEnum
    };
    export type TypeEnum = 'SESSION_NAME' | 'SESSION_DESCRIPTION' | 'STEP_NAME' | 'SEARCH_NAME' | 'SPROFILE_CODE' | 'LEGAL_TITLE' | 'LEGAL_DESCRIPTION' | 'EVENT_TYPE' | 'BACKEND_STRING' | 'RULE_NAME' | 'TAG_NAME';
    export const TypeEnum = {
        SESSIONNAME: 'SESSION_NAME' as TypeEnum,
        SESSIONDESCRIPTION: 'SESSION_DESCRIPTION' as TypeEnum,
        STEPNAME: 'STEP_NAME' as TypeEnum,
        SEARCHNAME: 'SEARCH_NAME' as TypeEnum,
        SPROFILECODE: 'SPROFILE_CODE' as TypeEnum,
        LEGALTITLE: 'LEGAL_TITLE' as TypeEnum,
        LEGALDESCRIPTION: 'LEGAL_DESCRIPTION' as TypeEnum,
        EVENTTYPE: 'EVENT_TYPE' as TypeEnum,
        BACKENDSTRING: 'BACKEND_STRING' as TypeEnum,
        RULENAME: 'RULE_NAME' as TypeEnum,
        TAGNAME: 'TAG_NAME' as TypeEnum
    };
}
