<div class="text-initiale">
  <app-document-table
    (onAddAcroform)="addAcroform($event)"
    (onCloneInConfigurator)="cloneAndReupload($event)"
    (onDelete)="deleteDocument($event)"
    (onUpdate)="updateDocument($event)"
    (onUpload)="uploadDocument($event)"
    (openModal)="updateDocumentName($event)"
    (onReuploadDoc)="reuploadDocument($event)"
    *ngIf="documents && sprofiles && session && step"
    [buttonLabel]="'WIZARD.DOCUMENTS.ADD_DOCUMENT' | translate"
    [disabled]="creatingAcroform || updatingDocument || uploadingDocument || updatingDocumentName
    || cloningDocument || reuploadingDocument || deletingDocument"
    [documents]="documentsToSignSorted"
    [isLoading]="documents?.isLoadingToSign"
    [hasFailed]="documents?.hasFailed"
    (onFailed)="onFailed()"
    [noResult]="'WIZARD.DOCUMENTS.NO_RESULT' | translate"
    [signatures]="getSignatures(documents?.documentToSign)"
    [signingUsers]="signers"
    [sprofiles]="sprofiles"
    [session]="session"
    [step]="step"
    [canChangeSprofile]="canChangeSprofile()"
    [canAdd]="canAdd()"
    [canClone]="canClone()"
    [canUpdate]="canUpdate()"
    [hasAtLeast1Signature]="hasAtLeast1Signature"
    [signersByDocuments]="signersByDocument"
    [isConfigurator]="true"
    [oneStepConfigurator]="oneStepConfigurator"
    [hasStepTagPositionSignatureLocation]="hasStepTagPositionSignatureLocation"
  >
  </app-document-table>
</div>


<div class="attachments separator" *ngIf="canAdd() || documents?.appendixToSee.length > 0 ">
  <div class="text-left pt-3"><h5>{{'WIZARD.DOCUMENTS.ATTACHMENTS' | translate}}</h5></div>
  <div class="text-initiale">
    <app-document-table
      (onCloneInConfigurator)="cloneAndReupload($event)"
      (onDelete)="deleteAppendix($event)"
      (onUpdate)="updateDocument($event)"
      (onUpload)="uploadAppendix($event)"
      (openModal)="updateDocumentName($event)"
      (onReuploadDoc)="reuploadDocument($event)"
      *ngIf="documents"
      [annexe]="true"
      [buttonLabel]="'WIZARD.DOCUMENTS.ADD_DOCUMENT' | translate"
      [disabled]="deletingAppendix || updatingDocument || updatingDocumentName || uploadingAppendix || reuploadingDocument || cloningDocument"
      [documents]="appendixToSeeSorted"
      [isLoading]="documents?.isLoadingAppendix"
      [noResult]="'WIZARD.DOCUMENTS.NO_RESULT' | translate"
      [session]="session"
      [step]="step"
      [canAdd]="canAdd()"
      [canClone]="canClone()"
      [canUpdate]="canUpdate()"
      [isConfigurator]="true"
      [oneStepConfigurator]="oneStepConfigurator"
      [hasStepTagPositionSignatureLocation]="hasStepTagPositionSignatureLocation"
    >
    </app-document-table>
  </div>
</div>
