/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateStepLegalPayload } from '../model/createStepLegalPayload';
import { StepLegalMotion } from '../model/stepLegalMotion';
import { UpdateStepLegalPayload } from '../model/updateStepLegalPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StepLegalMotionService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create legal motion
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the legal motion belongs
     * @param payload Legal notice attribute values to create
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStepLegalNotice(sessionId: number, stepId: number, payload?: CreateStepLegalPayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createStepLegalNotice(sessionId: number, stepId: number, payload?: CreateStepLegalPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createStepLegalNotice(sessionId: number, stepId: number, payload?: CreateStepLegalPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createStepLegalNotice(sessionId: number, stepId: number, payload?: CreateStepLegalPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createStepLegalNotice.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling createStepLegalNotice.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/legals`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete legal motion
     * 
     * @param legalId Legal notice to delete
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the legal motion belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStepLegalNotice(legalId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteStepLegalNotice(legalId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteStepLegalNotice(legalId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteStepLegalNotice(legalId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (legalId === null || legalId === undefined) {
            throw new Error('Required parameter legalId was null or undefined when calling deleteStepLegalNotice.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteStepLegalNotice.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling deleteStepLegalNotice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/legals/${encodeURIComponent(String(legalId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get legal motion list for step
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the legal motions belong
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStepLegalNotices(sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<StepLegalMotion>>;
    public getStepLegalNotices(sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StepLegalMotion>>>;
    public getStepLegalNotices(sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StepLegalMotion>>>;
    public getStepLegalNotices(sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getStepLegalNotices.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getStepLegalNotices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StepLegalMotion>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/legals`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update step legal motion
     * 
     * @param legalId Legal notice to update
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the legal motion belongs
     * @param payload Legal notice attribute values to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStepLegalNotice(legalId: number, sessionId: number, stepId: number, payload?: UpdateStepLegalPayload, observe?: 'body', reportProgress?: boolean): Observable<StepLegalMotion>;
    public updateStepLegalNotice(legalId: number, sessionId: number, stepId: number, payload?: UpdateStepLegalPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepLegalMotion>>;
    public updateStepLegalNotice(legalId: number, sessionId: number, stepId: number, payload?: UpdateStepLegalPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepLegalMotion>>;
    public updateStepLegalNotice(legalId: number, sessionId: number, stepId: number, payload?: UpdateStepLegalPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (legalId === null || legalId === undefined) {
            throw new Error('Required parameter legalId was null or undefined when calling updateStepLegalNotice.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateStepLegalNotice.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateStepLegalNotice.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<StepLegalMotion>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/legals/${encodeURIComponent(String(legalId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
