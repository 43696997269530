<div class="mb-3 row px-3 full-size">
  <div class="offset-lg-4 col-lg-4 col-sm-12 text-center p-0 mt-3">
    <button (click)="loadPreviousPage()" [disabled]="shouldDisablePrevious()"
            class="mr-2 btn btn-secondary">{{'DOCUMENT.PREVIOUS_PAGE' | translate}}
    </button>
    <button (click)="loadNextPage()" [disabled]="shouldDisableNext()"
            class="btn btn-secondary">{{'DOCUMENT.NEXT_PAGE' | translate}}
    </button>
  </div>

  <div *ngIf="!disabledAnnotation" class="col-4 p-0 text-right">
    <app-annotation-viewer
      (loadPage)="loadPage($event)"
      [annotations]="annotations">
    </app-annotation-viewer>
  </div>
</div>

<div class="signature-pad_" >
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div *ngIf="!isLoading && !pdfBlob">{{'STEP.NO_DOCUMENT' | translate}}</div>
  <div #documentContainer class="signature-pad--body_"></div>
</div>
