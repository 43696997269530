import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';

import {SharedModule} from '../../../shared/shared.module';
import {WizardHeaderComponent} from './components/wizard-header/wizard-header.component';
import {WizardStepButtonComponent} from './components/wizard-step-button/wizard-step-button.component';
import {WizardStepComponent} from './components/wizard-step/wizard-step.component';
import {WizardComponent} from './container/wizard/wizard.component';
import {WizardService} from './services/wizard.service';

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule, NgbTooltipModule, SharedModule],
  exports: [WizardComponent, WizardStepButtonComponent, WizardStepComponent],
  declarations: [WizardComponent, WizardHeaderComponent, WizardStepButtonComponent, WizardStepComponent],
  providers: [WizardService, WizardStepButtonComponent]
})
export class WizardModule {
}
