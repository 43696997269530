import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {QuillModule} from 'ngx-quill';
import {SharedModule} from '../shared/shared.module';
import {CommentListComponent} from './components/comment-list/comment-list.component';
import {DocumentCardComponent} from './components/document-card/document-card.component';
import {DocumentListComponent} from './components/document-list/document-list.component';
import {DocumentComponent} from './components/document/document.component';
import {PaginatorComponent} from './components/paginator/paginator.component';
import {SignatureBookComponent} from './container/signature-book/signature-book.component';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbTooltipModule, ReactiveFormsModule, SharedModule, RouterModule, QuillModule, InfiniteScrollModule],
  declarations: [SignatureBookComponent,
    DocumentListComponent,
    DocumentCardComponent,
    DocumentListComponent,
    PaginatorComponent,
    DocumentComponent,
    CommentListComponent]
})
export class SignatureBookModule {
}
