export enum EntitlementsEnum {
  admin = 'ADMIN',
  create_from_template = 'CREATE_FROM_TEMPLATE',
  create_standalone = 'CREATE_STANDALONE',
  designer = 'DESIGNER',
  manage_circles = 'MANAGE_CIRCLES',
  manage_entitlements = 'MANAGE_ENTITLEMENTS',
  manage_layout = 'MANAGE_LAYOUT',
  manage_legal_notices = 'MANAGE_LEGAL_NOTICES',
  manage_metadata = 'MANAGE_METADATA',
  manage_notifications = 'MANAGE_NOTIFICATIONS',
  manage_template = 'MANAGE_TEMPLATE',
  order_certificate = 'ORDER_CERTIFICATE',
  signature_book = 'SIGNATURE_BOOK',
  signer = 'SIGNER',
  super_admin = 'SUPER_ADMIN',
  manage_translation = 'MANAGE_TRANSLATION',
  session_translation = 'SESSION_TRANSLATION',
  manage_user = 'MANAGE_USER',
  watcher = 'WATCHER',
  organization = 'MANAGE_ORGANISATIONS',
  forbid_wizard = 'FORBID_WIZARD',
  forbid_session= 'FORBID_SESSION',
  template_admin = 'TEMPLATE_ADMIN',
  get_audit_trail = 'GET_AUDIT_TRAIL',
  signature_validation = 'SIGNATURE_VALIDATION'
}
