<div *ngIf="loading">
<app-tooltip [tooltipText]="'HELP.SIGNATURE_BOOK' | translate"></app-tooltip>
<!-- If result -->
<ng-container *ngIf="signatureBook?.items?.length; else notResult">
  <div class="document-wrapper">
    <button (click)="showDocument = false" *ngIf="showDocument"
            class="close-document-btn btn btn-danger">
      <i class="fas fa-arrow-left"></i> {{'SIGNATURE_BOOK.CLOSE' | translate}}
    </button>

    <div [ngClass]="!showDocument ? '' : 'hide-element'" class="sidebar">
      <document-list (documentSelected)="setCurrentDocument($event); showDocument = true"
                     (moveToCommentSection)="moveToCommentSection()"
                     (onSearch)="searchDocument($event)"
                     (onSort)="onSort($event)"
                     (filterStatusDocument)="onFilter($event)"
                      (onScroll)="onScrollDown()"
                     *ngIf="currentSignatureBook && session && step"
                     [currentUserId]="this.currentUserId"
                     [selectedSignatureBook]="currentSignatureBook"
                     [stepid]="step.id"
                     [sessionId]="session.id"
                     [signatureBook]="signatureBook">
      </document-list>
    </div>

    <div [ngClass]="showDocument ? '' : 'hide-element'" class="document-view">
      <document (addComment)="incrementStepCommentOnStore()"
                (reinstateDocument)="handleReinstateDocument($event)"
                (cancelDocument)="handleCancelDocument($event)"
                (rejectDocument)="handleRejectDocument($event)"
                (signOrVisaDocument)="handleRemoveDocument($event)"
                (suspendDocument)="handleRemoveDocument($event)"
                *ngIf="currentSignatureBook && session && step"
                [selectedSignatureBook]="currentSignatureBook"
                (deleteComment)="removeComment()"
                [step]="step"
      >
      </document>
    </div>
  </div>
</ng-container>

<!-- If no result -->
<ng-template #notResult>
  <div class="no-result">
    <div class="text-center">
      <p>{{'SIGNATURE_BOOK.NO_RESULT' | translate}}</p>
      <a [queryParamsHandling]="'preserve'"
         [routerLink]="['dashboard']">{{'SIGNATURE_BOOK.BACK_TO_DASHBOARD' | translate}}</a>
    </div>
  </div>
</ng-template>
</div>
