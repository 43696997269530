/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./itsme-signature.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/translate.pipe";
import * as i3 from "../../service/translate.service";
import * as i4 from "./itsme-signature.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../services/services/alert-service";
import * as i7 from "../../../../../luxtrust-cosi-api/api/itsmeSignature.service";
import * as i8 from "../../../services/services/itsme.service";
var styles_ItsmeSignatureComponent = [i0.styles];
var RenderType_ItsmeSignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItsmeSignatureComponent, data: {} });
export { RenderType_ItsmeSignatureComponent as RenderType_ItsmeSignatureComponent };
export function View_ItsmeSignatureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/img/Itsme_logo_hexa_pos_rgb.jpg"], ["style", "width:100%; max-width: 200px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ITSME.LOADING")); _ck(_v, 5, 0, currVal_0); }); }
export function View_ItsmeSignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ItsmeSignatureComponent_0, RenderType_ItsmeSignatureComponent)), i1.ɵdid(1, 114688, null, 0, i4.ItsmeSignatureComponent, [i5.ActivatedRoute, i5.Router, i3.TranslateService, i6.AlertService, i7.ItsmeSignatureService, i8.ItsmeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItsmeSignatureComponentNgFactory = i1.ɵccf("ng-component", i4.ItsmeSignatureComponent, View_ItsmeSignatureComponent_Host_0, {}, {}, []);
export { ItsmeSignatureComponentNgFactory as ItsmeSignatureComponentNgFactory };
