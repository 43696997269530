/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Notification pattern creation payload
 */
export interface CreatePatternPayload { 
    /**
     * Body of the notification
     */
    body?: string;
    /**
     * Chanel of pattern (SMS, EMAIL, ...)
     */
    channel?: CreatePatternPayload.ChannelEnum;
    /**
     * Type of event of the notification pattern 
     */
    eventTypeId?: number;
    /**
     * Language of the message pattern
     */
    language?: CreatePatternPayload.LanguageEnum;
    /**
     * Id of the session that the pattern should override (used for override)
     */
    sessionId?: number;
    /**
     * Id of the step where the pattern will override another (used for override)
     */
    stepId?: number;
    /**
     * Subject of the notification
     */
    subject?: string;
    /**
     * Id of the tag (used pattern override)
     */
    tagId?: number;
    /**
     * Value of the tag (used for override)
     */
    tagValue?: string;
}
export namespace CreatePatternPayload {
    export type ChannelEnum = 'EMAIL' | 'SMS' | 'APP' | 'OTHER';
    export const ChannelEnum = {
        EMAIL: 'EMAIL' as ChannelEnum,
        SMS: 'SMS' as ChannelEnum,
        APP: 'APP' as ChannelEnum,
        OTHER: 'OTHER' as ChannelEnum
    };
    export type LanguageEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LanguageEnum = {
        EN: 'EN' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        DE: 'DE' as LanguageEnum,
        PT: 'PT' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        NL: 'NL' as LanguageEnum,
        SP: 'SP' as LanguageEnum
    };
}
