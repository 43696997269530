import {Component, Input, Output} from '@angular/core';
import {EventEmitter} from 'events';

@Component({
  selector: 'app-card', templateUrl: './card.component.html', styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() collapsible = false;
  @Input() title = '';
  @Input() collapsed = false;
  @Output() collapse = new EventEmitter();
  @Input() customPadding = false;

  handleCollapse() {
    this.collapsed = !this.collapsed;
    this.collapse.emit('');
  }
}
