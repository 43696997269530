/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ResultData } from '../model/resultData';
import { UpdateRejectionPayload } from '../model/updateRejectionPayload';
import { WorkflowData } from '../model/workflowData';
import { WorkflowFilter } from '../model/workflowFilter';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SignatureWorkflowService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Cancel the workflow
     * 
     * @param sessionId Session to which the workflow applies
     * @param stepId Step to which the workflow applies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelWorkflow(sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<ResultData>;
    public cancelWorkflow(sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultData>>;
    public cancelWorkflow(sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultData>>;
    public cancelWorkflow(sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling cancelWorkflow.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling cancelWorkflow.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<ResultData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/workflows/cancel`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get workflows
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the workflows belong to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowList(sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WorkflowData>>;
    public getWorkflowList(sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WorkflowData>>>;
    public getWorkflowList(sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WorkflowData>>>;
    public getWorkflowList(sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getWorkflowList.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getWorkflowList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WorkflowData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/workflows`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Re-upload document into the workflow
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the comment belongs
     * @param comment Related comment
     * @param uploadFile File to be uploaded
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reuploadWorkflow(documentId: number, sessionId: number, stepId: number, comment?: string, uploadFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reuploadWorkflow(documentId: number, sessionId: number, stepId: number, comment?: string, uploadFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reuploadWorkflow(documentId: number, sessionId: number, stepId: number, comment?: string, uploadFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reuploadWorkflow(documentId: number, sessionId: number, stepId: number, comment?: string, uploadFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling reuploadWorkflow.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling reuploadWorkflow.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling reuploadWorkflow.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (uploadFile !== undefined) {
            formParams = formParams.append('uploadFile', <any>uploadFile) || formParams;
        }

        return this.httpClient.put<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/workflows/reupload/${encodeURIComponent(String(documentId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get workflows for enduser
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the workflows belong to
     * @param filter Wokflow filter (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchWorkflows(sessionId: number, stepId: number, filter?: WorkflowFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<WorkflowData>>;
    public searchWorkflows(sessionId: number, stepId: number, filter?: WorkflowFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WorkflowData>>>;
    public searchWorkflows(sessionId: number, stepId: number, filter?: WorkflowFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WorkflowData>>>;
    public searchWorkflows(sessionId: number, stepId: number, filter?: WorkflowFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling searchWorkflows.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling searchWorkflows.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<WorkflowData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/workflows/search`,
            filter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update rejection status for the workflow
     * 
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the comment belongs
     * @param payload Rejection to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRejectionWorkflow(sessionId: number, stepId: number, payload?: UpdateRejectionPayload, observe?: 'body', reportProgress?: boolean): Observable<Array<WorkflowData>>;
    public updateRejectionWorkflow(sessionId: number, stepId: number, payload?: UpdateRejectionPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WorkflowData>>>;
    public updateRejectionWorkflow(sessionId: number, stepId: number, payload?: UpdateRejectionPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WorkflowData>>>;
    public updateRejectionWorkflow(sessionId: number, stepId: number, payload?: UpdateRejectionPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateRejectionWorkflow.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateRejectionWorkflow.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<WorkflowData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/workflows/update-rejection`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
