import { Injectable } from '@angular/core';

@Injectable()
export class IEService {

  constructor() { }

  /**
   * Check if current browser is Internet Explorer
   *
   * @returns {boolean} true, if current browser is IE; false otherwise
   * @memberof IEService
   */
  public checkIfIE(): boolean {
    const ua = window.navigator.userAgent;
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  }

  public adaptEntriesIE() {
    if (!Object.entries) {
      Object.entries = (obj) => {
        const ownProps = Object.keys(obj);
        let i = ownProps.length;
        const resArray = new Array(i); // preallocate the Array
        while (i--) {
          resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }
        return resArray;
      };
    }
  }

}
