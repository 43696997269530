import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SignatureWorkflowService} from 'luxtrust-cosi-api/api/signatureWorkflow.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {ApiError} from '../../error/api-error.model';
import {AlertService} from './alert-service';
import {AppService} from './app.service';
​
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
​
  constructor(private router: Router,
              private alertService: AlertService,
              private workflowService: SignatureWorkflowService,
              private appService: AppService,
              private sessionService: SessionService,
              private stepService: StepService) {
  }
​
  goToDocumentOrFirstStartedStepOrSession(sessionId: number) {
    return this.stepService.getStartedSteps(sessionId).toPromise().then(navigateTo => {
      if (navigateTo === null) {
        this.router.navigate(['/session', sessionId]);
        return;
      }
      if (navigateTo.stepId !== null && navigateTo.signatureId !== null) {
        this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId, 'workflow', navigateTo.signatureId]);
        return;
      }
      if (navigateTo.stepId !== null) {
        this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId]);
        return;
      }
      this.router.navigate(['/session', sessionId]);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }
​
  goToFirstStartedStepOrDocument(sessionId: number, stepId: number) {
    this.stepService.getStartedSteps(sessionId).toPromise().then(navigateTo => {
      if (navigateTo !== null && navigateTo.stepId !== null && navigateTo.signatureId !== null) {
        this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId, 'workflow', navigateTo.signatureId]);
        return;
      }
      this.router.navigate(['/session', sessionId, 'step', stepId]);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  goToFirstDocument(sessionId: number) {
    this.stepService.getStartedSteps(sessionId).toPromise().then(navigateTo => {
      if (navigateTo !== null && navigateTo.stepId !== null && navigateTo.signatureId !== null) {
        this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId, 'workflow', navigateTo.signatureId]);
        return;
      } else if (navigateTo !== null && navigateTo.stepId !== null) {
        this.workflowService.getWorkflowList(sessionId, navigateTo.stepId).subscribe(workflows => {
          this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId, 'workflow', workflows[0].signatureId, 'as', workflows[0].enduserId]);
          return;
        }, (error: ApiError) => this.alertService.errorApi(error));
      } else {
        this.router.navigate(['/session', sessionId]);
      }
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  goToFirstStartedStep(sessionId: number) {
    this.stepService.getStartedSteps(sessionId).toPromise().then(navigateTo => {
      if (navigateTo !== null && navigateTo.stepId !== null) {
        this.router.navigate(['/session', sessionId, 'step', navigateTo.stepId]);
        return;
      }
      this.router.navigate(['/session', sessionId]);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

}
