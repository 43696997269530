/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-confirm-signature.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "../../../core/service/translate.service";
import * as i4 from "./modal-confirm-signature.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalConfirmSignatureComponent = [i0.styles];
var RenderType_ModalConfirmSignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalConfirmSignatureComponent, data: {} });
export { RenderType_ModalConfirmSignatureComponent as RenderType_ModalConfirmSignatureComponent };
export function View_ModalConfirmSignatureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "valid-sign"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["src", "assets/icons/file_doc_to_sign.svg"], ["style", "width:100%; max-width: 125px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "text-center mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close("ok") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" OK "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("MODAL_CONFIRMATION_SIGNATURE.TITLE")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ModalConfirmSignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-confirm-signature", [], null, null, null, View_ModalConfirmSignatureComponent_0, RenderType_ModalConfirmSignatureComponent)), i1.ɵdid(1, 49152, null, 0, i4.ModalConfirmSignatureComponent, [i5.NgbActiveModal, i3.TranslateService], null, null)], null, null); }
var ModalConfirmSignatureComponentNgFactory = i1.ɵccf("app-modal-confirm-signature", i4.ModalConfirmSignatureComponent, View_ModalConfirmSignatureComponent_Host_0, {}, {}, []);
export { ModalConfirmSignatureComponentNgFactory as ModalConfirmSignatureComponentNgFactory };
