<app-full-screen mode="container-s">
  <div class="d-flex flex-column align-items-center">
    <div *ngIf=logo class="mb-5">
      <img class="w-100"
           src="data:image/png;base64,{{logo}}">
    </div>
    <div class="mb-2">
      <span class="w-100">
        {{'NO_TENANT_NAME.MESSAGE' | translate}}
      </span>
    </div>
    <div>
      <span class="w-100">
        <a (click)="goToDefaultApplicationUrl()">
          <i class="fas fa-home"></i>
          {{'NO_TENANT_NAME.BACK_MAIN_SPACE' | translate}}
        </a>
      </span>
    </div>
  </div>
</app-full-screen>
