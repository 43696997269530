/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../core/layouts/full-screen/full-screen.component.ngfactory";
import * as i3 from "../../../../../core/layouts/full-screen/full-screen.component";
import * as i4 from "../../components/wizard-header/wizard-header.component.ngfactory";
import * as i5 from "../../components/wizard-header/wizard-header.component";
import * as i6 from "../../services/wizard.service";
import * as i7 from "@angular/router";
import * as i8 from "./wizard.component";
var styles_WizardComponent = [i0.styles];
var RenderType_WizardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardComponent, data: {} });
export { RenderType_WizardComponent as RenderType_WizardComponent };
export function View_WizardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-full-screen", [], null, null, null, i2.View_FullScreenComponent_0, i2.RenderType_FullScreenComponent)), i1.ɵdid(1, 49152, null, 0, i3.FullScreenComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 1, "app-wizard-header", [], null, null, null, i4.View_WizardHeaderComponent_0, i4.RenderType_WizardHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i5.WizardHeaderComponent, [i6.WizardService, i7.ActivatedRoute], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_WizardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wizard", [], null, null, null, View_WizardComponent_0, RenderType_WizardComponent)), i1.ɵdid(1, 114688, null, 0, i8.WizardComponent, [i7.ActivatedRoute, i6.WizardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WizardComponentNgFactory = i1.ɵccf("app-wizard", i8.WizardComponent, View_WizardComponent_Host_0, {}, {}, []);
export { WizardComponentNgFactory as WizardComponentNgFactory };
