/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard-step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../core/layouts/full-screen/full-screen.component.ngfactory";
import * as i3 from "../../../../../core/layouts/full-screen/full-screen.component";
import * as i4 from "./wizard-step.component";
var styles_WizardStepComponent = [i0.styles];
var RenderType_WizardStepComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardStepComponent, data: {} });
export { RenderType_WizardStepComponent as RenderType_WizardStepComponent };
export function View_WizardStepComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-full-screen", [], null, null, null, i2.View_FullScreenComponent_0, i2.RenderType_FullScreenComponent)), i1.ɵdid(1, 49152, null, 0, i3.FullScreenComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 1, "div", [["class", "wizard-step-container fixed-container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, 2, 1, "div", [["class", "mb-5"]], null, null, null, null, null)), i1.ɵncd(null, 1)], null, null); }
export function View_WizardStepComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wizard-step", [], null, null, null, View_WizardStepComponent_0, RenderType_WizardStepComponent)), i1.ɵdid(1, 49152, null, 0, i4.WizardStepComponent, [], null, null)], null, null); }
var WizardStepComponentNgFactory = i1.ɵccf("app-wizard-step", i4.WizardStepComponent, View_WizardStepComponent_Host_0, {}, {}, ["*", "[footer]"]);
export { WizardStepComponentNgFactory as WizardStepComponentNgFactory };
