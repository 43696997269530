/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "../../../core/service/translate.service";
import * as i4 from "./modal-signer.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../../../luxtrust-cosi-api/api/watermark.service";
import * as i7 from "../../../services/services/alert-service";
var styles_ModalSignerComponent = [];
var RenderType_ModalSignerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalSignerComponent, data: {} });
export { RenderType_ModalSignerComponent as RenderType_ModalSignerComponent };
function View_ModalSignerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " ", " (", ")"]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.firstName); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.lastName); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.email); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ModalSignerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " (", ")"]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.lastName); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.email); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ModalSignerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["class", "mr-2 mb-2 btn btn-success"]], [[8, "id", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSigner(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-user-circle-o"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalSignerComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalSignerComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = !_v.context.$implicit.circle; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.circle; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("id-assigned-user-" + _v.context.index); var currVal_1 = _co.selectingSigner; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ModalSignerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " ", " (", ")"]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.firstName); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.lastName); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.email); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ModalSignerComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " (", ")"]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.lastName); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.email); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ModalSignerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["class", "mr-2 mb-2 btn btn-primary"]], [[8, "id", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSigner(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-user-circle-o"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalSignerComponent_5)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalSignerComponent_6)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = !_v.context.$implicit.circle; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.circle; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("id-user-" + _v.context.index); var currVal_1 = _co.selectingSigner; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ModalSignerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i0.ChangeDetectorRef, i3.TranslateService]), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "modal-body text-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalSignerComponent_1)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalSignerComponent_4)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-outline-danger"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i0.ChangeDetectorRef, i3.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.assignedEndUsers; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.endUsers; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("DOCUMENT.CHOOSE_SIGNER")); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.selectingSigner; _ck(_v, 10, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("DOCUMENT.CANCEL_ACTIONS")); _ck(_v, 11, 0, currVal_4); }); }
export function View_ModalSignerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ModalSignerComponent_0, RenderType_ModalSignerComponent)), i0.ɵdid(1, 49152, null, 0, i4.ModalSignerComponent, [i5.NgbActiveModal, i6.WatermarkService, i7.AlertService], null, null)], null, null); }
var ModalSignerComponentNgFactory = i0.ɵccf("ng-component", i4.ModalSignerComponent, View_ModalSignerComponent_Host_0, { assignedEndUsers: "assignedEndUsers", endUsers: "endUsers" }, {}, []);
export { ModalSignerComponentNgFactory as ModalSignerComponentNgFactory };
