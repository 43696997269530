/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legal-motion-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../core/pipes/translate.pipe";
import * as i4 from "../../../core/service/translate.service";
import * as i5 from "./legal-motion-card.component";
var styles_LegalMotionCardComponent = [i0.styles];
var RenderType_LegalMotionCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegalMotionCardComponent, data: {} });
export { RenderType_LegalMotionCardComponent as RenderType_LegalMotionCardComponent };
export function View_LegalMotionCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "legal-motion-card-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "row align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "btn-group btn-spacing"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["class", "btn btn-left"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleOptional(_co.stepLegalMotion) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService]), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["class", "btn btn-right"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleMandatory(_co.stepLegalMotion) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService]), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn-delete icon-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDeleteLegalMotion(_co.stepLegalMotion) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash-alt"]], [[2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [["class", "mt-2"], ["style", "word-wrap: break-word;"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-left"; var currVal_2 = (!_co.stepLegalMotion.mandatory ? "btn-secondary" : "btn-outline-secondary"); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_5 = "btn btn-right"; var currVal_6 = (_co.stepLegalMotion.mandatory ? "btn-secondary" : "btn-outline-secondary"); _ck(_v, 11, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 4, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("WIZARD.LEGAL.OPTIONAL")); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.disabled; _ck(_v, 9, 0, currVal_4); var currVal_7 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("WIZARD.LEGAL.MANDATORY")); _ck(_v, 12, 0, currVal_7); var currVal_8 = _co.disabled; _ck(_v, 14, 0, currVal_8); var currVal_9 = _co.disabled; _ck(_v, 15, 0, currVal_9); var currVal_10 = _co.stepLegalMotion.legal.text; _ck(_v, 17, 0, currVal_10); }); }
export function View_LegalMotionCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-legal-motion-card", [], null, null, null, View_LegalMotionCardComponent_0, RenderType_LegalMotionCardComponent)), i1.ɵdid(1, 49152, null, 0, i5.LegalMotionCardComponent, [], null, null)], null, null); }
var LegalMotionCardComponentNgFactory = i1.ɵccf("app-legal-motion-card", i5.LegalMotionCardComponent, View_LegalMotionCardComponent_Host_0, { stepLegalMotion: "stepLegalMotion", disabled: "disabled" }, { onDelete: "onDelete", onUpdate: "onUpdate" }, []);
export { LegalMotionCardComponentNgFactory as LegalMotionCardComponentNgFactory };
