<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'STEP.DELEGATE_MODAL.TITLE' | translate}}</h4>
</div>

<div class="modal-body">

  <form *ngIf="delegateForm" [formGroup]="delegateForm">
    <div class="form-row" data-position='top'>
      <span (click)="openInviteUserModal()" class="link">
        {{'STEP.DELEGATE_MODAL.ADD' | translate}}
      </span>

      <app-autocomplete-custom [placeholder]="'STEP.DELEGATE_MODAL.SEARCH' | translate"
                               [datasProviders]="delegatesProvider"
                               [tabIndex]="0"
                               [focusOnInput]="true"
                               [filterWithInput]=false
                               [addEnduser]="true"
                               [errors]="{atLeastOne: 'STEP.DELEGATE_MODAL.ERRORS.AT_LEAST_ONE' | translate}"
                               (valueInputChange)="reloadProviders($event)"
                               class="col-12"
                               formControlName="delegate">
      </app-autocomplete-custom>

      <div class="w-100 col-12" *ngIf="selectedDelegate">
        <app-enduser-item [enduser]="selectedDelegate"
                          [disabledSelectCircle]="false"
                          (onUpdateExpected)="updateNbOfRequiredSignersInCircle($event)"
                          (delete)="removeDelegate()"></app-enduser-item>
      </div>
    </div>
    <div class="form-row" *ngIf="this.displaySwitcher">
      <div class="switcher switcher-right ml-auto w-100 float-right">
        <label for="input-auto-switch">
          {{'STEP.DELEGATE_MODAL.SWITCHER_STEP_ONLY' | translate}}
        </label>
        <input formControlName="stepOnly"
               class="switcher-checkbox"
               id="input-auto-switch"
               type="checkbox">
        <div class="switcher-toggle"></div>
      </div>
    </div>
  </form>

</div>

<div class="modal-footer">
  <button (click)="activeModal.close()" class="btn btn-outline-secondary mr-1"
          type="button"> {{"STEP.DELEGATE_MODAL.CLOSE" | translate}} </button>
  <button
    (click)="handleAction()"
    [disabled]="!selectedDelegate"
    class="btn btn-primary mr-1"
    type="button"
  >{{"STEP.DELEGATE_MODAL.VALIDATE" | translate}}
  </button>
</div>
