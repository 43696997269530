<div class="card">
  <div (click)="handleCollapse()"
       *ngIf="collapsible"
       [ngStyle]="{'cursor': collapsible ? 'pointer' : 'default'}"
       class="card-header">
    <h5 class="card-title">
      {{title}}
      <i [ngClass]="collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"
         class="fa float-right collapsible-control"> </i>
    </h5>
  </div>

  <div [ngbCollapse]="collapsed" class="card-body"  [ngClass]="{'customPadding': customPadding}">
    <ng-content></ng-content>
  </div>
</div>
