import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {StepPropertiesService} from 'luxtrust-cosi-api/api/stepProperties.service';
import {StepPropertyData} from 'luxtrust-cosi-api/model/stepPropertyData';
import {Observable} from 'rxjs';

@Injectable()
export class StepPropertiesResolver implements Resolve<StepPropertyData> {

  static URL_PROPERTY_SPECIFIC = '/properties';

  constructor(private stepPropertiesService: StepPropertiesService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<StepPropertyData> | Promise<StepPropertyData> | StepPropertyData {
    const sessionId: number = +route.params['sessionId'];
    const stepId: number = +route.params['stepId'];
    return this.stepPropertiesService.getStepProperties(sessionId, stepId).toPromise().then(p => {
      p.signNotBefore = p.signNotBefore && new Date(p.signNotBefore) || undefined;
      p.signNotAfter = p.signNotAfter && new Date(p.signNotAfter) || undefined;
      return p;
    }).catch(() => ({
      preferences: {}, priority: 0, signNotAfter: undefined, signNotBefore: undefined, stepId: stepId
    }));
  }
}
