import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CreateTagPayload} from 'luxtrust-cosi-api/model/createTagPayload';

import {StepData} from 'luxtrust-cosi-api/model/stepData';
import TypeEnum = CreateTagPayload.TypeEnum;

// TODO : Factoriser avec l'autre endroit ou on utilise <app-card>
@Component({
  selector: 'app-modal-configurator-metadatas',
  templateUrl: './modal-configurator-metadatas.component.html',
  styleUrls: ['./modal-configurator-metadatas.component.scss']
})
export class ModalConfiguratorMetadatasComponent implements OnChanges {

  @Input() step: StepData;
  @Input() sessionId: number;
  @Output() controlValueTagMandatory: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateTags: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorLinkTag: EventEmitter<boolean> = new EventEmitter<boolean>();
  public tagMandatoryHasNoValue = false;
  public hasErrorTagLink = false;
  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.checkMandatoryHasValue();
    });
  }

  updateStepTagMeta() {
    if (!this.hasErrorTagLink) {
      this.updateTags.emit();
    }
    this.checkMandatoryHasValue();
  }

  checkTagError(error: boolean) {
    this.hasErrorTagLink = error;
    this.errorLinkTag.emit(error);
    this.checkMandatoryHasValue();
  }

  trackSteptag(index: number) {
    return index;
  }

  checkMandatoryHasValue() {
    let error = false;
    for (let i = 0; i < this.step.tags.length; i++) {
      const tag = this.step.tags[i];
      let valueToTest = tag.value;
      if (tag.type === TypeEnum.LINK) {
        if (tag.value.includes('|')) {
          valueToTest = tag.value.replace('|', '');
        }
      }
      if (tag.mandatory && (valueToTest.length === 0)) {
        error = true;
        break;
      }
    }
    this.tagMandatoryHasNoValue = error;
    this.controlValueTagMandatory.emit(this.tagMandatoryHasNoValue);
  }
}
