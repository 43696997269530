import { Router } from '@angular/router';
import { AppService } from '../services/app.service';
import { EntitlementGuard } from './entitlement.guard';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/app.service";
export class ParapheurGuard extends EntitlementGuard {
    constructor(router, appService) {
        super(router, appService);
        this.router = router;
        this.appService = appService;
    }
    getEntitlementName() {
        return 'SIGNATURE_BOOK';
    }
    positive() {
        return true;
    }
}
ParapheurGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParapheurGuard_Factory() { return new ParapheurGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppService)); }, token: ParapheurGuard, providedIn: "root" });
