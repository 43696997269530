import { DocumentService } from 'luxtrust-cosi-api/api/document.service';
import { DocumentData } from 'luxtrust-cosi-api/model/documentData';
import { debounceTime, take } from 'rxjs/operators';
import { SprofileEnum } from '../../../services/constants/signature.constant';
import { AlertService } from '../../../services/services/alert-service';
import { Store } from '../model/store';
var TypologyEnum = DocumentData.TypologyEnum;
import * as i0 from "@angular/core";
import * as i1 from "../../../../../luxtrust-cosi-api/api/document.service";
import * as i2 from "../../../services/services/alert-service";
const DEFAULT_SPROFILE = SprofileEnum.ORELY_PADES;
export class WizardDocumentStoreService extends Store {
    constructor(documentService, alertService) {
        super();
        this.documentService = documentService;
        this.alertService = alertService;
    }
    init() {
        if (this.getAll()) {
            return;
        }
        this.store({
            documents: [], documentToSign: [], appendixToSee: []
        });
    }
    reset() {
        this.store({
            documents: [], documentToSign: [], appendixToSee: []
        });
    }
    getAllDocuments(sessionId, stepId) {
        return this.documentService.getAllDocuments(sessionId, stepId).toPromise()
            .then(documents => {
            this.filterDocuments(documents, stepId);
        }).catch((error) => this.alertService.errorApi(error));
    }
    uploadDocument(sessionId, stepId, files, cb) {
        this.store(Object.assign({}, this.getAll(), { isLoadingToSign: true }));
        const fileArray = [];
        for (let i = 0; i < files.length; i++) {
            fileArray.push(files[i]);
        }
        this.uploadDocumentsInOrder(sessionId, stepId, fileArray, cb);
    }
    uploadDocumentsInOrder(sessionId, stepId, files, cb) {
        if (files.length === 0) {
            cb();
            return;
        }
        if (this.getAll().documentToSign.some(d => files[0] && d.name === files[0].name)) {
            this.store(Object.assign({}, this.getAll(), { isLoadingToSign: false }));
            this.uploadDocumentsInOrder(sessionId, stepId, files.slice(1), cb);
            return;
        }
        this.documentService.uploadDocument(sessionId, stepId, files[0]).toPromise()
            .then((document) => {
            // this document is a document original it'll never be display on the UI so we add it on documents
            const createDocumentPayload = {
                sourceDocumentId: document.id, sprofileCode: DEFAULT_SPROFILE
            };
            // add the new document to the array
            this.store(Object.assign({}, this.getAll(), { documents: [document, ...this.getAll().documents], documentToSign: this.getAll().documentToSign, isLoadingToSign: false }));
            return this.createDocument(sessionId, stepId, createDocumentPayload);
        }).catch((error) => this.alertService.errorApi(error)).then(() => this.uploadDocumentsInOrder(sessionId, stepId, files.slice(1), cb));
    }
    uploadAppendix(sessionId, stepId, files, cb) {
        this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: true }));
        const fileArray = [];
        for (let i = 0; i < files.length; i++) {
            fileArray.push(files[i]);
        }
        this.uploadAppendixInOrder(sessionId, stepId, fileArray, cb);
    }
    uploadAppendixInOrder(sessionId, stepId, files, cb) {
        if (files.length === 0) {
            cb();
            return;
        }
        const i = 0;
        if (this.getAll().appendixToSee.some(d => d.name === files[i].name)) {
            this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: false }));
            this.uploadAppendixInOrder(sessionId, stepId, files.slice(1), cb);
            return;
        }
        this.documentService.uploadDocument(sessionId, stepId, files[i]).toPromise()
            .then((document) => {
            // this document is a document original it'll never be display on the UI so we add it on documents
            const createDocumentPayload = {
                sourceDocumentId: document.id, typology: TypologyEnum.TOVIEW
            };
            // add the new document to the array
            this.store(Object.assign({}, this.getAll(), { documents: [document, ...this.getAll().documents], appendixToSee: this.getAll().appendixToSee, isLoadingAppendix: false }));
            return this.createAppendix(sessionId, stepId, createDocumentPayload);
        }).catch((error) => this.alertService.errorApi(error)).then(() => {
            this.uploadAppendixInOrder(sessionId, stepId, files.slice(1), cb);
        });
    }
    createDocument(sessionId, stepId, createDocumentPayload) {
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
            .then((document) => {
            const toAdd = Object.assign({}, document, { displayArrow: true, parentIsMutual: false });
            this.store(Object.assign({}, this.getAll(), { documents: [document, ...this.getAll().documents], documentToSign: [toAdd, ...this.getAll().documentToSign] }));
            return document;
        }).catch((error) => this.alertService.errorApi(error));
    }
    createAppendix(sessionId, stepId, createDocumentPayload) {
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
            .then((document) => {
            const toAdd = Object.assign({}, document, { displayArrow: true, parentIsMutual: false });
            this.store(Object.assign({}, this.getAll(), { documents: [document, ...this.getAll().documents], appendixToSee: [toAdd, ...this.getAll().appendixToSee] }));
        }).catch((error) => this.alertService.errorApi(error));
    }
    createMutualDocument(sessionId, stepId, document, reload = false) {
        this.store(Object.assign({}, this.getAll(), { isLoadingToSign: true }));
        // We delete the documents
        return this.documentService.deleteDocument(document.id, sessionId, stepId).toPromise()
            .then(() => {
            const documents = this.getAll().documents;
            const documentParent = this.getParent(documents, document);
            // We create a mutual document
            return this.documentService.getMutualDocument(documentParent.id, sessionId, stepId).toPromise()
                .then((documentMutual) => {
                const createDocumentPayload = {
                    sourceDocumentId: documentMutual.id, sprofileCode: document.sprofileCode
                };
                // We create a pades from the mutual
                return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
                    .then((documentChildOfMutual) => {
                    const toAdd = Object.assign({}, documentChildOfMutual, { displayArrow: true, parentIsMutual: true, parentMutualOnCurrentStep: true });
                    // we remove from the local list of documentToSign the old related document which is replaced by a new with a mutual parent
                    const documentToSign = this.getAll().documentToSign;
                    documentToSign.splice((documentToSign.findIndex((documentToDelete) => documentToDelete.id === document.id)), 1);
                    this.store(Object.assign({}, this.getAll(), { documents: [documentMutual, ...this.getAll().documents], documentToSign: [toAdd, ...documentToSign], isLoadingToSign: false }));
                    if (reload) {
                        location.reload();
                    }
                });
            });
        }).catch((error) => this.alertService.errorApi(error));
    }
    createMutualAppendix(sessionId, stepId, document) {
        this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: true }));
        // We delete the documents
        return this.documentService.deleteDocument(document.id, sessionId, stepId).toPromise()
            .then(() => {
            const documents = this.getAll().documents;
            const documentParent = this.getParent(documents, document);
            // We create a mutual document
            return this.documentService.getMutualDocument(documentParent.id, sessionId, stepId).toPromise()
                .then((documentMutual) => {
                const createDocumentPayload = {
                    sourceDocumentId: documentMutual.id, typology: TypologyEnum.TOVIEW
                };
                // We create a to_view from the mutual
                return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
                    .then((documentChildOfMutual) => {
                    const toAdd = Object.assign({}, documentChildOfMutual, { displayArrow: true, parentIsMutual: true, parentMutualOnCurrentStep: true });
                    // we remove from the local list of documentToSign the old related document which is replaced by a new with a mutual parent
                    const appendixToSee = this.getAll().appendixToSee;
                    appendixToSee.splice((appendixToSee.findIndex((documentToDelete) => documentToDelete.id === document.id)), 1);
                    this.store(Object.assign({}, this.getAll(), { documents: [documentMutual, ...this.getAll().documents], appendixToSee: [toAdd, ...appendixToSee], isLoadingAppendix: false }));
                });
            });
        }).catch((error) => this.alertService.errorApi(error));
    }
    retakeMutualDocument(sessionId, stepId, document) {
        const createDocumentPayload = {
            sourceDocumentId: document.id, sprofileCode: DEFAULT_SPROFILE
        };
        this.store(Object.assign({}, this.getAll(), { isLoadingMutual: true }));
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
            .then((documentChildOfMutual) => {
            const toAdd = Object.assign({}, documentChildOfMutual, { parentIsMutual: true });
            const documentToSign = this.getAll().documentToSign;
            const documentMutual = this.getAll().documentMutual;
            documentMutual.splice(documentMutual.findIndex((documentToDelete) => documentToDelete.id === document.id), 1);
            this.store(Object.assign({}, this.getAll(), { documentToSign: [toAdd, ...documentToSign], documentMutual: documentMutual, isLoadingMutual: false }));
        }).catch((error) => this.alertService.errorApi(error));
    }
    retakeMutualAppendix(sessionId, stepId, document) {
        const createDocumentPayload = {
            sourceDocumentId: document.id, typology: TypologyEnum.TOVIEW
        };
        this.store(Object.assign({}, this.getAll(), { isLoadingMutual: true }));
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
            .then((documentChildOfMutual) => {
            const toAdd = Object.assign({}, documentChildOfMutual, { parentIsMutual: true });
            const appendixToSee = this.getAll().appendixToSee;
            const documentMutual = this.getAll().documentMutual;
            documentMutual.splice(documentMutual.findIndex((documentToDelete) => documentToDelete.id === document.id), 1);
            this.store(Object.assign({}, this.getAll(), { appendixToSee: [toAdd, ...appendixToSee], documentMutual: documentMutual, isLoadingMutual: false }));
        }).catch((error) => this.alertService.errorApi(error));
    }
    deleteMutualDocumentParentInCurrentStep(sessionId, stepId, document) {
        this.store(Object.assign({}, this.getAll(), { isLoadingToSign: true }));
        // We delete the chain o, m , p on the back via the soft delete
        return this.documentService.softDeleteDocument(document.id, sessionId, stepId).toPromise()
            .then(() => {
            // document have the original sprofileCode, so we get the mutual one
            const documentMutual = this.getChildren(this.getAll().documents, document);
            // filter the one which is mutual related document
            const docTobeSigned = this.getAll().documentToSign;
            docTobeSigned.splice(docTobeSigned.findIndex((documentToDelete) => documentToDelete && documentMutual && (documentMutual.id === documentToDelete.sourceDocumentId)), 1);
            this.store(Object.assign({}, this.getAll(), { documentToSign: docTobeSigned, isLoadingToSign: false }));
        }).catch(() => {
            this.alertService.warning('WIZARD.DOCUMENTS.INVOLVE_IN_OTHER_STEP');
            this.store(Object.assign({}, this.getAll(), { isLoadingToSign: false }));
        });
    }
    deleteMutualAppendixParentInCurrentStep(sessionId, stepId, document) {
        this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: true }));
        // We delete the chain o, m , p on the back via the soft delete
        return this.documentService.softDeleteDocument(document.id, sessionId, stepId).toPromise()
            .then(() => {
            // document have the original sprofileCode, so we get the mutual one
            const appendixMutual = this.getChildren(this.getAll().documents, document);
            // filter the one which is mutual from list of appendix docs shown on the page
            const temp = this.getAll().appendixToSee;
            temp.splice(temp.findIndex((documentToDelete) => documentToDelete && appendixMutual && (appendixMutual.id === documentToDelete.sourceDocumentId)), 1);
            this.store(Object.assign({}, this.getAll(), { appendixToSee: temp, isLoadingAppendix: false }));
        }).catch(() => {
            this.alertService.warning('WIZARD.DOCUMENTS.INVOLVE_IN_OTHER_STEP');
            this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: false }));
        });
    }
    deleteMutualDocumentParentInAnotherStep(sessionId, stepId, document) {
        this.store(Object.assign({}, this.getAll(), { isLoadingToSign: true }));
        // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
        return this.documentService.deleteDocument(document.id, sessionId, stepId).toPromise()
            .then(() => {
            const documentToSign = this.getAll().documentToSign;
            documentToSign.splice(documentToSign.findIndex((documentToDelete) => documentToDelete.id === document.id), 1);
            const mutual = this.getParent(this.getAll().documents, document);
            const toAddInMutual = Object.assign({}, mutual, { parentIsMutual: false, parentMutualOnCurrentStep: false, isMutualOnCurrentStep: false, displayArrow: false });
            this.store(Object.assign({}, this.getAll(), { documentToSign: documentToSign, documentMutual: [toAddInMutual, ...this.getAll().documentMutual], isLoadingToSign: false }));
        }).catch((error) => this.alertService.errorApi(error));
    }
    deleteMutualAppendixParentInAnotherStep(sessionId, stepId, document) {
        this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: true }));
        // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
        return this.documentService.deleteDocument(document.id, sessionId, stepId).toPromise()
            .then(() => {
            const appendixToSee = this.getAll().appendixToSee;
            appendixToSee.splice(appendixToSee.findIndex((documentToDelete) => documentToDelete.id === document.id), 1);
            const mutual = this.getParent(this.getAll().documents, document);
            const toAddInMutual = Object.assign({}, mutual, { parentIsMutual: false, parentMutualOnCurrentStep: false, isMutualOnCurrentStep: false, displayArrow: false });
            this.store(Object.assign({}, this.getAll(), { appendixToSee: appendixToSee, documentMutual: [toAddInMutual, ...this.getAll().documentMutual], isLoadingAppendix: false }));
        }).catch((error) => this.alertService.errorApi(error));
    }
    deleteMutualDocument(sessionId, stepId, document, reload = false) {
        const documents = this.getAll().documents;
        const parentMutualDocument = this.getParent(documents, document);
        const parentOriginalDocument = this.getParent(documents, parentMutualDocument);
        const createDocumentPayload = {
            sourceDocumentId: parentOriginalDocument.id, sprofileCode: document.sprofileCode
        };
        this.store(Object.assign({}, this.getAll(), { isLoadingToSign: true }));
        // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId)
            .pipe(take(1), debounceTime(300))
            .toPromise()
            .then(() => {
            // We First create the future child of the original
            this.documentService.createDocument(sessionId, stepId, createDocumentPayload).pipe(take(1)).subscribe((newDocument) => {
                const toAdd = Object.assign({}, newDocument, { displayArrow: true, parentIsMutual: false });
                const documentToSign = this.getAll().documentToSign;
                const index = documentToSign.findIndex((documentToDelete) => documentToDelete.id === document.id);
                const documentMutual = this.getAll().documentMutual;
                documentToSign[index] = toAdd;
                this.store(Object.assign({}, this.getAll(), { documentToSign: documentToSign, documentMutual: documentMutual, isLoadingToSign: false }));
                if (reload) {
                    window.location.reload();
                }
            }, (error) => this.alertService.errorApi(error));
        }, (error) => {
            this.alertService.errorApi(error);
            this.store(Object.assign({}, this.getAll(), { isLoadingToSign: true, hasFailed: true }));
        });
    }
    deleteMutualAppendix(sessionId, stepId, document) {
        const documents = this.getAll().documents;
        const parentMutualDocument = this.getParent(documents, document);
        const parentOriginalDocument = this.getParent(documents, parentMutualDocument);
        const createDocumentPayload = {
            sourceDocumentId: parentOriginalDocument.id, typology: TypologyEnum.TOVIEW
        };
        this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: true }));
        // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId)
            .pipe(take(1), debounceTime(300))
            .toPromise()
            .then(() => {
            // We First create the future child of the original
            this.documentService.createDocument(sessionId, stepId, createDocumentPayload).pipe(take(1)).subscribe((newDocument) => {
                const toAdd = Object.assign({}, newDocument, { displayArrow: true, parentIsMutual: false });
                const appendixToSee = this.getAll().appendixToSee;
                const index = appendixToSee.findIndex((documentToDelete) => documentToDelete.id === document.id);
                const documentMutual = this.getAll().documentMutual;
                appendixToSee[index] = toAdd;
                this.store(Object.assign({}, this.getAll(), { appendixToSee: appendixToSee, documentMutual: documentMutual, isLoadingAppendix: false }));
            }, (error) => this.alertService.errorApi(error));
        }, (error) => this.alertService.errorApi(error));
    }
    deleteSimpleDocuments(sessionId, stepId, document) {
        this.store(Object.assign({}, this.getAll(), { isLoadingToSign: true }));
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).toPromise().then(() => {
            const documents = this.deleteDocumentById(this.getAll().documents, document);
            const documentToSign = this.deleteDocumentById(this.getAll().documentToSign, document);
            this.store(Object.assign({}, this.getAll(), { documents: documents, documentToSign: documentToSign, isLoadingToSign: false }));
        }).catch((error) => this.alertService.errorApi(error));
    }
    deleteSimpleAppendix(sessionId, stepId, document) {
        this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: true }));
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).toPromise().then(() => {
            const documents = this.deleteDocumentById(this.getAll().documents, document);
            const appendixToSee = this.deleteDocumentById(this.getAll().appendixToSee, document);
            this.store(Object.assign({}, this.getAll(), { documents: documents, appendixToSee: appendixToSee, isLoadingAppendix: false }));
        }).catch((error) => this.alertService.errorApi(error));
    }
    deleteDocument(sessionId, stepId, document) {
        if (!this.isParentMutual(this.getAll().documents, document)) {
            return this.deleteSimpleDocuments(sessionId, stepId, document);
        }
        else if (this.isParentMutualAndOnCurrentStep(this.getAll().documents, document, stepId)) {
            const mutual = this.getParent(this.getAll().documents, document);
            const original = this.getParent(this.getAll().documents, mutual);
            return this.deleteMutualDocumentParentInCurrentStep(sessionId, stepId, original);
        }
        else {
            return this.deleteMutualDocumentParentInAnotherStep(sessionId, stepId, document);
        }
    }
    deleteAppendix(sessionId, stepId, document) {
        if (!this.isParentMutual(this.getAll().documents, document)) {
            return this.deleteSimpleAppendix(sessionId, stepId, document);
        }
        else if (this.isParentMutualAndOnCurrentStep(this.getAll().documents, document, stepId)) {
            const mutual = this.getParent(this.getAll().documents, document);
            const original = this.getParent(this.getAll().documents, mutual);
            return this.deleteMutualAppendixParentInCurrentStep(sessionId, stepId, original);
        }
        else {
            return this.deleteMutualAppendixParentInAnotherStep(sessionId, stepId, document);
        }
    }
    cloneDocument(sessionId, stepId, document) {
        return this.documentService.cloneDocument(document.id, sessionId, stepId, {
            name: document.name
        }).toPromise().then(() => this.getAllDocuments(sessionId, stepId)).catch((error) => {
            this.alertService.errorApi(error);
        });
    }
    updateDocument(sessionId, stepId, documentId, updateDocumentPayload) {
        return this.documentService.partialUpdateDocument(documentId, sessionId, stepId, updateDocumentPayload).pipe(take(1))
            .toPromise()
            .then((newDocument) => {
            const index = this.getAll().documentToSign.findIndex((document) => document.id === newDocument.id);
            const newArray = this.getAll().documentToSign;
            newArray[index] = Object.assign({}, this.getAll().documentToSign[index], newDocument); // merge
            this.store(Object.assign({}, this.getAll(), { documentToSign: newArray }));
        }, (error) => this.alertService.errorApi(error));
    }
    updateDocumentName(sessionId, stepId, documentId, updateDocumentPayload) {
        return this.documentService.partialUpdateDocument(documentId, sessionId, stepId, updateDocumentPayload).toPromise().then(() => this.getAllDocuments(sessionId, stepId)).catch((error) => {
            this.alertService.errorApi(error);
        });
    }
    onFailed() {
        this.store(Object.assign({}, this.getAll(), { isLoadingToSign: false, hasFailed: false }));
    }
    getParent(documents, document) {
        return documents.find((potentialParent) => potentialParent.id === document.sourceDocumentId);
    }
    getChildren(documents, document) {
        return documents.find((potentialChild) => potentialChild.sourceDocumentId === document.id);
    }
    isMutualWithNoChild(documents, document) {
        return document.typology === TypologyEnum.MUTUAL && !documents.find((potentialChild) => potentialChild.sourceDocumentId === document.id);
    }
    isDocumentToSign(document) {
        return document.typology !== TypologyEnum.ORIGINAL &&
            document.typology !== TypologyEnum.MUTUAL &&
            document.typology !== TypologyEnum.SIGNATURE &&
            document.typology !== TypologyEnum.TOVIEW;
    }
    isDocumentToSee(document) {
        return document.typology === TypologyEnum.TOVIEW;
    }
    isParentMutual(documents, document) {
        const parent = documents.find((potentialParent) => potentialParent.id === document.sourceDocumentId);
        return parent && parent.typology === TypologyEnum.MUTUAL;
    }
    isParentMutualAndOnCurrentStep(documents, document, stepId) {
        if (this.isParentMutual(documents, document)) {
            const parent = documents.find((potentialParent) => potentialParent.id === document.sourceDocumentId);
            return parent.stepId === stepId;
        }
        return false;
    }
    deleteDocumentById(documents, documentToDelete) {
        documents.splice(documents.findIndex((document) => {
            return document.id === documentToDelete.id;
        }), 1);
        return documents;
    }
    filterDocuments(documents, stepId) {
        const documentToSign = [];
        const appendixToSee = [];
        const documentMutual = [];
        documents.forEach((document) => {
            const toSign = this.isDocumentToSign(document);
            const parentMutual = this.isParentMutual(documents, document);
            if (toSign && !parentMutual) {
                documentToSign.push(Object.assign({}, document, { displayArrow: true }));
            }
            else {
                const toSee = this.isDocumentToSee(document);
                if (toSee && !parentMutual) {
                    appendixToSee.push(Object.assign({}, document, { displayArrow: true }));
                }
                else if (toSign && parentMutual) {
                    if (this.isParentMutualAndOnCurrentStep(documents, document, stepId)) {
                        documentToSign.push(Object.assign({}, document, { parentIsMutual: true, parentMutualOnCurrentStep: true, displayArrow: true }));
                    }
                    else {
                        documentToSign.push(Object.assign({}, document, { parentIsMutual: true, parentMutualOnCurrentStep: false, displayArrow: true }));
                    }
                }
                else if (toSee && parentMutual) {
                    if (this.isParentMutualAndOnCurrentStep(documents, document, stepId)) {
                        appendixToSee.push(Object.assign({}, document, { parentIsMutual: true, parentMutualOnCurrentStep: true, displayArrow: true }));
                    }
                    else {
                        appendixToSee.push(Object.assign({}, document, { parentIsMutual: true, parentMutualOnCurrentStep: false, displayArrow: true }));
                    }
                }
                else if (this.isMutualWithNoChild(documents, document)) {
                    if (document.stepId === stepId) {
                        documentMutual.push(Object.assign({}, document, { isMutualOnCurrentStep: true }));
                    }
                    else {
                        documentMutual.push(Object.assign({}, document, { isMutualOnCurrentStep: false }));
                    }
                }
            }
        });
        this.store({
            documents: documents,
            documentToSign: documentToSign,
            appendixToSee: appendixToSee,
            documentMutual: documentMutual
        });
    }
}
WizardDocumentStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WizardDocumentStoreService_Factory() { return new WizardDocumentStoreService(i0.ɵɵinject(i1.DocumentService), i0.ɵɵinject(i2.AlertService)); }, token: WizardDocumentStoreService, providedIn: "root" });
