import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {filter, map, take} from 'rxjs/internal/operators';
import {AppService} from '../services/app.service';

export abstract class EntitlementGuard implements CanActivate {

  private urlToReplace: string;

  constructor(protected router: Router, protected appService: AppService) {
  }

  abstract getEntitlementName(): string;

  abstract positive(): boolean;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    const endUser = this.appService.getUser();

    if (!endUser) {
        return this.appService.getUser$()
          .pipe(filter(e => !!e))
          .pipe(take(1))
          .pipe(map(userData => this.activeOrNotRedirection(userData, route, state))).toPromise();
    } else {
      return this.activeOrNotRedirection(endUser, route, state);
    }
  }

  activeOrNotRedirection(endUser, route, state): boolean {
    if ((this.positive() && endUser && endUser.entitlementNames.some(
      e => e === this.getEntitlementName())) || (!this.positive() && !endUser.entitlementNames.some(
      e => e === this.getEntitlementName()))) {
      if (this.urlToReplace) {
        this.router.navigate([this.urlToReplace]);
        this.urlToReplace = null;
      }
      return true;
    }

    if (route.fragment) {
      this.appService.refresh(route.fragment);
      this.router.navigate([state.url.replace(/[?#].*$/, '')]);
      return true;
    }

    this.router.navigate(['/dashboard']);
    return false;
  }
}
