import {Injectable} from '@angular/core';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {CreateDocumentPayload} from 'luxtrust-cosi-api/model/createDocumentPayload';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {UpdateDocumentPayload} from 'luxtrust-cosi-api/model/updateDocumentPayload';
import {debounceTime, take} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {SprofileEnum} from '../../../services/constants/signature.constant';
import {AlertService} from '../../../services/services/alert-service';
import {Store} from '../model/store';
import {DocumentWithDetails, WizardDocumentState} from '../model/wizard-document-state';
import TypologyEnum = DocumentData.TypologyEnum;
import {Subject, Subscription} from "rxjs";

const DEFAULT_SPROFILE = SprofileEnum.ORELY_PADES;

@Injectable({
  providedIn: 'root'
})
export class WizardDocumentStoreService extends Store<WizardDocumentState> {

  constructor(private documentService: DocumentService,
              private alertService: AlertService) {
    super();
  }

  init() {
    if (this.getAll()) {
      return;
    }
    this.store({
      documents: [], documentToSign: [], appendixToSee: []
    });
  }

  reset() {
    this.store({
      documents: [], documentToSign: [], appendixToSee: []
    });
  }

  getAllDocuments(sessionId: number, stepId: number) {
    return this.documentService.getAllDocuments(sessionId, stepId).toPromise()
      .then(documents => {
        this.filterDocuments(documents, stepId);
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  uploadDocument(sessionId: number, stepId: number, files: FileList | File[], cb: Function): void {
    this.store({
      ...this.getAll(), isLoadingToSign: true
    });

    const fileArray = [];
    for (let i = 0; i < files.length; i++) {
      fileArray.push(files[i]);
    }
    this.uploadDocumentsInOrder(sessionId, stepId, fileArray, cb);
  }

  uploadDocumentsInOrder(sessionId: number, stepId: number, files: File[], cb: Function) {
    if (files.length === 0) {
      cb();
      return;
    }
    if (this.getAll().documentToSign.some(d => files[0] && d.name === files[0].name)) {
      this.store({
        ...this.getAll(), isLoadingToSign: false
      });
      this.uploadDocumentsInOrder(sessionId, stepId, files.slice(1), cb);
      return;
    }

    this.documentService.uploadDocument(sessionId, stepId, files[0]).toPromise()
      .then((document: DocumentData) => {
        // this document is a document original it'll never be display on the UI so we add it on documents
        const createDocumentPayload: CreateDocumentPayload = {
          sourceDocumentId: document.id, sprofileCode: DEFAULT_SPROFILE
        };

        // add the new document to the array
        this.store({
          ...this.getAll(),
          documents: [document, ...this.getAll().documents],
          documentToSign: this.getAll().documentToSign,
          isLoadingToSign: false
        });

        return this.createDocument(sessionId, stepId, createDocumentPayload);
      }).catch((error: ApiError) =>
      this.alertService.errorApi(error)
    ).then(() => this.uploadDocumentsInOrder(sessionId, stepId, files.slice(1), cb));
  }

  uploadAppendix(sessionId: number, stepId: number, files: FileList, cb: Function) {
    this.store({
      ...this.getAll(), isLoadingAppendix: true
    });
    const fileArray = [];
    for (let i = 0; i < files.length; i++) {
      fileArray.push(files[i]);
    }
    this.uploadAppendixInOrder(sessionId, stepId, fileArray, cb);
  }

  uploadAppendixInOrder(sessionId: number, stepId: number, files: File[], cb: Function) {
    if (files.length === 0) {
      cb();
      return;
    }
    const i = 0;

    if (this.getAll().appendixToSee.some(d => d.name === files[i].name)) {
      this.store({
        ...this.getAll(), isLoadingAppendix: false
      });
      this.uploadAppendixInOrder(sessionId, stepId, files.slice(1), cb);
      return;
    }

    this.documentService.uploadDocument(sessionId, stepId, files[i]).toPromise()
      .then((document: DocumentData) => {
        // this document is a document original it'll never be display on the UI so we add it on documents
        const createDocumentPayload: CreateDocumentPayload = {
          sourceDocumentId: document.id, typology: TypologyEnum.TOVIEW
        };

        // add the new document to the array
        this.store({
          ...this.getAll(),
          documents: [document, ...this.getAll().documents],
          appendixToSee: this.getAll().appendixToSee,
          isLoadingAppendix: false
        });

        return this.createAppendix(sessionId, stepId, createDocumentPayload);
      }).catch((error: ApiError) => this.alertService.errorApi(error)).then(() => {
      this.uploadAppendixInOrder(sessionId, stepId, files.slice(1), cb);
    });
  }

  createDocument(sessionId: number, stepId: number, createDocumentPayload: CreateDocumentPayload): Promise<any> {
    return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
      .then((document: DocumentData) => {
        const toAdd: DocumentWithDetails = {
          ...document, displayArrow: true, parentIsMutual: false
        };
        this.store({
          ...this.getAll(),
          documents: [document, ...this.getAll().documents],
          documentToSign: [toAdd, ...this.getAll().documentToSign]
        });
        return document;
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  createAppendix(sessionId: number, stepId: number, createDocumentPayload: CreateDocumentPayload): Promise<any> {
    return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
      .then((document: DocumentData) => {
        const toAdd: DocumentWithDetails = {
          ...document, displayArrow: true, parentIsMutual: false
        };
        this.store({
          ...this.getAll(),
          documents: [document, ...this.getAll().documents],
          appendixToSee: [toAdd, ...this.getAll().appendixToSee]
        });
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  createMutualDocument(sessionId: number, stepId: number, document, reload: boolean = false) {

    this.store({
      ...this.getAll(), isLoadingToSign: true
    });

    // We delete the documents
    return this.documentService.deleteDocument(document.id, sessionId, stepId).toPromise()
      .then(() => {
        const documents = this.getAll().documents;
        const documentParent = this.getParent(documents, document);

        // We create a mutual document
        return this.documentService.getMutualDocument(documentParent.id, sessionId, stepId).toPromise()
          .then((documentMutual: DocumentData) => {
            const createDocumentPayload: CreateDocumentPayload = {
              sourceDocumentId: documentMutual.id, sprofileCode: document.sprofileCode
            };

            // We create a pades from the mutual
            return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
              .then((documentChildOfMutual: DocumentData) => {
                const toAdd: DocumentWithDetails = {
                  ...documentChildOfMutual, displayArrow: true, parentIsMutual: true, parentMutualOnCurrentStep: true
                };

                // we remove from the local list of documentToSign the old related document which is replaced by a new with a mutual parent
                const documentToSign = this.getAll().documentToSign;
                documentToSign.splice((documentToSign.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id)),
                  1);

                this.store({
                  ...this.getAll(),
                  documents: [documentMutual, ...this.getAll().documents],
                  documentToSign: [toAdd, ...documentToSign],
                  isLoadingToSign: false
                });
                if (reload) {
                  location.reload();
                }
              });
          });
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  createMutualAppendix(sessionId: number, stepId: number, document) {

    this.store({
      ...this.getAll(), isLoadingAppendix: true
    });

    // We delete the documents
    return this.documentService.deleteDocument(document.id, sessionId, stepId).toPromise()
      .then(() => {
        const documents = this.getAll().documents;
        const documentParent = this.getParent(documents, document);

        // We create a mutual document
        return this.documentService.getMutualDocument(documentParent.id, sessionId, stepId).toPromise()
          .then((documentMutual: DocumentData) => {
            const createDocumentPayload: CreateDocumentPayload = {
              sourceDocumentId: documentMutual.id, typology: TypologyEnum.TOVIEW
            };

            // We create a to_view from the mutual
            return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
              .then((documentChildOfMutual: DocumentData) => {
                const toAdd: DocumentWithDetails = {
                  ...documentChildOfMutual, displayArrow: true, parentIsMutual: true, parentMutualOnCurrentStep: true
                };

                // we remove from the local list of documentToSign the old related document which is replaced by a new with a mutual parent
                const appendixToSee = this.getAll().appendixToSee;
                appendixToSee.splice((appendixToSee.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id)),
                  1);

                this.store({
                  ...this.getAll(),
                  documents: [documentMutual, ...this.getAll().documents],
                  appendixToSee: [toAdd, ...appendixToSee],
                  isLoadingAppendix: false
                });
              });
          });
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  retakeMutualDocument(sessionId: number, stepId: number, document: DocumentData) {
    const createDocumentPayload: CreateDocumentPayload = {
      sourceDocumentId: document.id, sprofileCode: DEFAULT_SPROFILE
    };

    this.store({
      ...this.getAll(), isLoadingMutual: true
    });

    return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
      .then((documentChildOfMutual: DocumentData) => {

        const toAdd: DocumentWithDetails = {
          ...documentChildOfMutual, parentIsMutual: true
        };
        const documentToSign = this.getAll().documentToSign;
        const documentMutual = this.getAll().documentMutual;
        documentMutual.splice(documentMutual.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id), 1);

        this.store({
          ...this.getAll(),
          documentToSign: [toAdd, ...documentToSign],
          documentMutual: documentMutual,
          isLoadingMutual: false
        });
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  retakeMutualAppendix(sessionId: number, stepId: number, document: DocumentData) {
    const createDocumentPayload: CreateDocumentPayload = {
      sourceDocumentId: document.id, typology: TypologyEnum.TOVIEW
    };

    this.store({
      ...this.getAll(), isLoadingMutual: true
    });

    return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).toPromise()
      .then((documentChildOfMutual: DocumentData) => {

        const toAdd: DocumentWithDetails = {
          ...documentChildOfMutual, parentIsMutual: true
        };
        const appendixToSee = this.getAll().appendixToSee;
        const documentMutual = this.getAll().documentMutual;
        documentMutual.splice(documentMutual.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id), 1);

        this.store({
          ...this.getAll(),
          appendixToSee: [toAdd, ...appendixToSee],
          documentMutual: documentMutual,
          isLoadingMutual: false
        });
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  deleteMutualDocumentParentInCurrentStep(sessionId: number, stepId: number, document: DocumentData) {
    this.store({
      ...this.getAll(), isLoadingToSign: true
    });

    // We delete the chain o, m , p on the back via the soft delete
    return this.documentService.softDeleteDocument(document.id, sessionId, stepId).toPromise()
      .then(() => {
        // document have the original sprofileCode, so we get the mutual one
        const documentMutual = this.getChildren(this.getAll().documents, document);

        // filter the one which is mutual related document
        const docTobeSigned = this.getAll().documentToSign;
        docTobeSigned.splice(docTobeSigned.findIndex(
          (documentToDelete: DocumentWithDetails) => documentToDelete && documentMutual && (documentMutual.id === documentToDelete.sourceDocumentId)),
          1);

        this.store({
          ...this.getAll(), documentToSign: docTobeSigned, isLoadingToSign: false
        });
      }).catch(() => {
        this.alertService.warning('WIZARD.DOCUMENTS.INVOLVE_IN_OTHER_STEP');
        this.store({
          ...this.getAll(), isLoadingToSign: false
        });
      });
  }

  deleteMutualAppendixParentInCurrentStep(sessionId: number, stepId: number, document: DocumentData) {
    this.store({
      ...this.getAll(), isLoadingAppendix: true
    });

    // We delete the chain o, m , p on the back via the soft delete
    return this.documentService.softDeleteDocument(document.id, sessionId, stepId).toPromise()
      .then(() => {
        // document have the original sprofileCode, so we get the mutual one
        const appendixMutual = this.getChildren(this.getAll().documents, document);

        // filter the one which is mutual from list of appendix docs shown on the page
        const temp = this.getAll().appendixToSee;
        temp.splice(temp.findIndex(
          (documentToDelete: DocumentWithDetails) => documentToDelete && appendixMutual && (appendixMutual.id === documentToDelete.sourceDocumentId)),
          1);

        this.store({
          ...this.getAll(), appendixToSee: temp, isLoadingAppendix: false
        });
      }).catch(() => {
        this.alertService.warning('WIZARD.DOCUMENTS.INVOLVE_IN_OTHER_STEP');
        this.store({
          ...this.getAll(), isLoadingAppendix: false
        });
      });
  }

  deleteMutualDocumentParentInAnotherStep(sessionId: number, stepId: number, document: DocumentData) {
    this.store({
      ...this.getAll(), isLoadingToSign: true
    });

    // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
    return this.documentService.deleteDocument(document.id, sessionId, stepId).toPromise()
      .then(() => {
        const documentToSign = this.getAll().documentToSign;
        documentToSign.splice(documentToSign.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id), 1);

        const mutual = this.getParent(this.getAll().documents, document);

        const toAddInMutual = {
          ...mutual,
          parentIsMutual: false,
          parentMutualOnCurrentStep: false,
          isMutualOnCurrentStep: false,
          displayArrow: false
        };

        this.store({
          ...this.getAll(),
          documentToSign: documentToSign,
          documentMutual: [toAddInMutual, ...this.getAll().documentMutual],
          isLoadingToSign: false
        });
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  deleteMutualAppendixParentInAnotherStep(sessionId: number, stepId: number, document: DocumentData) {
    this.store({
      ...this.getAll(), isLoadingAppendix: true
    });

    // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
    return this.documentService.deleteDocument(document.id, sessionId, stepId).toPromise()
      .then(() => {
        const appendixToSee = this.getAll().appendixToSee;
        appendixToSee.splice(appendixToSee.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id), 1);

        const mutual = this.getParent(this.getAll().documents, document);

        const toAddInMutual = {
          ...mutual,
          parentIsMutual: false,
          parentMutualOnCurrentStep: false,
          isMutualOnCurrentStep: false,
          displayArrow: false
        };
        this.store({
          ...this.getAll(),
          appendixToSee: appendixToSee,
          documentMutual: [toAddInMutual, ...this.getAll().documentMutual],
          isLoadingAppendix: false
        });
      }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  deleteMutualDocument(sessionId: number, stepId: number, document: DocumentData, reload: boolean = false) {
    const documents = this.getAll().documents;

    const parentMutualDocument = this.getParent(documents, document);
    const parentOriginalDocument = this.getParent(documents, parentMutualDocument);

    const createDocumentPayload: CreateDocumentPayload = {
      sourceDocumentId: parentOriginalDocument.id, sprofileCode: document.sprofileCode
    };

    this.store({
      ...this.getAll(), isLoadingToSign: true
    });

    // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId)
      .pipe(take(1), debounceTime(300))
      .toPromise()
      .then(() => {

        // We First create the future child of the original
        this.documentService.createDocument(sessionId, stepId, createDocumentPayload).pipe(take(1)).subscribe((newDocument: DocumentData) => {

          const toAdd: DocumentWithDetails = {
            ...newDocument, displayArrow: true, parentIsMutual: false
          };

          const documentToSign = this.getAll().documentToSign;
          const index = documentToSign.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id);
          const documentMutual = this.getAll().documentMutual;

          documentToSign[index] = toAdd;

          this.store({
            ...this.getAll(), documentToSign: documentToSign, documentMutual: documentMutual, isLoadingToSign: false
          });
          if (reload) {
            window.location.reload();
          }
        }, (error: ApiError) => this.alertService.errorApi(error));
      }, (error) => {
        this.alertService.errorApi(error);
        this.store({
          ...this.getAll(), isLoadingToSign: true, hasFailed: true
        });
      });
  }

  deleteMutualAppendix(sessionId: number, stepId: number, document: DocumentData) {
    const documents = this.getAll().documents;

    const parentMutualDocument = this.getParent(documents, document);
    const parentOriginalDocument = this.getParent(documents, parentMutualDocument);

    const createDocumentPayload: CreateDocumentPayload = {
      sourceDocumentId: parentOriginalDocument.id, typology: TypologyEnum.TOVIEW
    };

    this.store({
      ...this.getAll(), isLoadingAppendix: true
    });

    // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId)
      .pipe(take(1), debounceTime(300))
      .toPromise()
      .then(() => {

      // We First create the future child of the original
      this.documentService.createDocument(sessionId, stepId, createDocumentPayload).pipe(take(1)).subscribe((newDocument: DocumentData) => {

        const toAdd: DocumentWithDetails = {
          ...newDocument, displayArrow: true, parentIsMutual: false
        };

        const appendixToSee = this.getAll().appendixToSee;
        const index = appendixToSee.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id);

        const documentMutual = this.getAll().documentMutual;

        appendixToSee[index] = toAdd;

        this.store({
          ...this.getAll(), appendixToSee: appendixToSee, documentMutual: documentMutual, isLoadingAppendix: false
        });
      }, (error: ApiError) => this.alertService.errorApi(error));
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  deleteSimpleDocuments(sessionId: number, stepId: number, document: DocumentData) {
    this.store({
      ...this.getAll(), isLoadingToSign: true
    });

    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).toPromise().then(() => {

      const documents: DocumentData[] = this.deleteDocumentById<DocumentData>(this.getAll().documents, document);
      const documentToSign: DocumentWithDetails[] = this.deleteDocumentById<DocumentWithDetails>(this.getAll().documentToSign, document);

      this.store({
        ...this.getAll(), documents: documents, documentToSign: documentToSign, isLoadingToSign: false
      });
    }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  deleteSimpleAppendix(sessionId: number, stepId: number, document: DocumentData) {
    this.store({
      ...this.getAll(), isLoadingAppendix: true
    });

    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).toPromise().then(() => {

      const documents: DocumentData[] = this.deleteDocumentById<DocumentData>(this.getAll().documents, document);
      const appendixToSee: DocumentWithDetails[] = this.deleteDocumentById<DocumentWithDetails>(this.getAll().appendixToSee, document);

      this.store({
        ...this.getAll(), documents: documents, appendixToSee: appendixToSee, isLoadingAppendix: false
      });
    }).catch((error: ApiError) => this.alertService.errorApi(error));
  }

  deleteDocument(sessionId: number, stepId: number, document: DocumentData) {
    if (!this.isParentMutual(this.getAll().documents, document)) {
      return this.deleteSimpleDocuments(sessionId, stepId, document);
    } else if (this.isParentMutualAndOnCurrentStep(this.getAll().documents, document, stepId)) {
      const mutual = this.getParent(this.getAll().documents, document);
      const original = this.getParent(this.getAll().documents, mutual);
      return this.deleteMutualDocumentParentInCurrentStep(sessionId, stepId, original);
    } else {
      return this.deleteMutualDocumentParentInAnotherStep(sessionId, stepId, document);
    }
  }

  deleteAppendix(sessionId: number, stepId: number, document: DocumentData) {
    if (!this.isParentMutual(this.getAll().documents, document)) {
      return this.deleteSimpleAppendix(sessionId, stepId, document);
    } else if (this.isParentMutualAndOnCurrentStep(this.getAll().documents, document, stepId)) {
      const mutual = this.getParent(this.getAll().documents, document);
      const original = this.getParent(this.getAll().documents, mutual);
      return this.deleteMutualAppendixParentInCurrentStep(sessionId, stepId, original);
    } else {
      return this.deleteMutualAppendixParentInAnotherStep(sessionId, stepId, document);
    }
  }

  cloneDocument(sessionId: number, stepId: number, document: DocumentData) {
    return this.documentService.cloneDocument(document.id, sessionId, stepId, {
      name: document.name
    }).toPromise().then(() =>
      this.getAllDocuments(sessionId, stepId)
    ).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  updateDocument(sessionId: number, stepId: number, documentId, updateDocumentPayload: UpdateDocumentPayload) {
    return this.documentService.partialUpdateDocument(documentId, sessionId, stepId, updateDocumentPayload).pipe(take(1))
      .toPromise()
      .then((newDocument: DocumentData) => {
        const index = this.getAll().documentToSign.findIndex((document: DocumentWithDetails) => document.id === newDocument.id);

        const newArray: DocumentWithDetails[] = this.getAll().documentToSign;
        newArray[index] = {...this.getAll().documentToSign[index], ...newDocument}; // merge
        this.store({
          ...this.getAll(), documentToSign: newArray
        });
      }, (error: ApiError) => this.alertService.errorApi(error));
  }

  updateDocumentName(sessionId: number, stepId: number, documentId, updateDocumentPayload: UpdateDocumentPayload) {
    return this.documentService.partialUpdateDocument(documentId, sessionId, stepId, updateDocumentPayload).toPromise().then(() =>
      this.getAllDocuments(sessionId, stepId)
    ).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  onFailed() {
    this.store({
      ...this.getAll(), isLoadingToSign: false, hasFailed: false
    });
  }

  getParent(documents: DocumentData[], document: DocumentData) {
    return documents.find((potentialParent: DocumentData) => potentialParent.id === document.sourceDocumentId);
  }

  getChildren(documents: DocumentData[], document: DocumentData) {
    return documents.find((potentialChild: DocumentData) => potentialChild.sourceDocumentId === document.id);
  }

  isMutualWithNoChild(documents: DocumentData[], document: DocumentData) {
    return document.typology === TypologyEnum.MUTUAL && !documents.find(
      (potentialChild: DocumentData) => potentialChild.sourceDocumentId === document.id);
  }

  isDocumentToSign(document: DocumentData): boolean {
    return document.typology !== TypologyEnum.ORIGINAL &&
      document.typology !== TypologyEnum.MUTUAL &&
      document.typology !== TypologyEnum.SIGNATURE &&
      document.typology !== TypologyEnum.TOVIEW;
  }

  isDocumentToSee(document: DocumentData): boolean {
    return document.typology === TypologyEnum.TOVIEW;
  }

  isParentMutual(documents: DocumentData[], document: DocumentData): boolean {
    const parent = documents.find((potentialParent: DocumentData) => potentialParent.id === document.sourceDocumentId);
    return parent && parent.typology === TypologyEnum.MUTUAL;
  }

  isParentMutualAndOnCurrentStep(documents: DocumentData[], document: DocumentData, stepId: number): boolean {
    if (this.isParentMutual(documents, document)) {
      const parent = documents.find((potentialParent: DocumentData) => potentialParent.id === document.sourceDocumentId);
      return parent.stepId === stepId;
    }
    return false;
  }

  private deleteDocumentById<T>(documents: T[], documentToDelete: DocumentData): T[] {
    documents.splice(documents.findIndex((document: any) => {
      return document.id === documentToDelete.id;
    }), 1);

    return documents;
  }

  private filterDocuments(documents: DocumentData[], stepId: number) {
    const documentToSign: DocumentWithDetails[] = [];
    const appendixToSee: DocumentWithDetails[] = [];
    const documentMutual: DocumentWithDetails[] = [];

    documents.forEach((document: DocumentData) => {
      const toSign = this.isDocumentToSign(document);
      const parentMutual = this.isParentMutual(documents, document);
      if (toSign && !parentMutual) {
        documentToSign.push({...document, displayArrow: true});
      } else {
        const toSee = this.isDocumentToSee(document);
        if (toSee && !parentMutual) {
          appendixToSee.push({...document, displayArrow: true});
        } else if (toSign && parentMutual) {
          if (this.isParentMutualAndOnCurrentStep(documents, document, stepId)) {
            documentToSign.push({
              ...document, parentIsMutual: true, parentMutualOnCurrentStep: true, displayArrow: true
            });
          } else {
            documentToSign.push({
              ...document, parentIsMutual: true, parentMutualOnCurrentStep: false, displayArrow: true
            });
          }
        } else if (toSee && parentMutual) {
          if (this.isParentMutualAndOnCurrentStep(documents, document, stepId)) {
            appendixToSee.push({
              ...document, parentIsMutual: true, parentMutualOnCurrentStep: true, displayArrow: true
            });
          } else {
            appendixToSee.push({
              ...document, parentIsMutual: true, parentMutualOnCurrentStep: false, displayArrow: true
            });
          }
        } else if (this.isMutualWithNoChild(documents, document)) {
          if (document.stepId === stepId) {
            documentMutual.push({
              ...document, isMutualOnCurrentStep: true
            });
          } else {
            documentMutual.push({
              ...document, isMutualOnCurrentStep: false
            });
          }
        }
      }
    });
    this.store({
      documents: documents,
      documentToSign: documentToSign,
      appendixToSee: appendixToSee,
      documentMutual: documentMutual
    });
  }
}
