import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import some from 'lodash/some';
import {StepPropertyData} from 'luxtrust-cosi-api';
import {ItsmeSignatureService} from 'luxtrust-cosi-api/api/itsmeSignature.service';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SignatureWorkflowService} from 'luxtrust-cosi-api/api/signatureWorkflow.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {ItsmeUserSessionDto} from 'luxtrust-cosi-api/model/itsmeUserSessionDto';
import {SignatureData} from 'luxtrust-cosi-api/model/signatureData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {UpdateRejectionPayload} from 'luxtrust-cosi-api/model/updateRejectionPayload';
import {WorkflowData} from 'luxtrust-cosi-api/model/workflowData';
import {forkJoin} from 'rxjs';
import {take} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {SprofileEnum} from '../../../services/constants/signature.constant';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {ItsmeService, ItsmeServiceSignatureEnum} from '../../../services/services/itsme.service';
import {NexuService} from '../../../services/services/nexu.service';
import {ModalApplyVisaComponent} from '../modal-apply-visa/modal-apply-visa.component';
import {ModalCommentComponent} from '../modal-comment/modal-comment.component';
import SignatureStatusEnum = WorkflowData.SignatureStatusEnum;
import {ModalConfirmSignatureComponent} from '../modal-confirm-signature/modal-confirm-signature.component';
import {ModalOrelyComponent} from '../modal-orely/modal-orely.component';
import {ModalSignWithCodeComponent} from '../modal-sign-with-code/modal-sign-with-code.component';

export enum DocumentActionEnum {
  REJECT = 'REJECT', CANCEL = 'CANCEL', REINSTATE = 'REINSTATE', SUSPEND = 'SUSPEND', CONTINUE = 'CONTINUE', SIGN = 'SIGN', VISA = 'VISA'
}

@Component({
  selector: 'app-document-actions',
  templateUrl: './document-actions.component.html',
  styleUrls: ['./document-actions.component.scss']
})
export class DocumentActionsComponent implements OnInit, OnChanges {
  // come from: workflow.component.html
  @Input() sessionId: number;
  @Input() step: StepData;
  @Input() stepStatus: string;
  @Input() stepProperties: StepPropertyData;
  @Input() document: DocumentData;
  @Input() stepEnduser: StepEnduserData;
  @Input() signatureStatus: SignatureData.StatusEnum;
  @Input() signatureId: number;
  @Input() childSignatureIds: number[] = [];
  @Input() sprofile: string;
  @Input() displayGoToTheDocument = false;
  @Input() displayAfterNoRedirection = true;
  @Input() signDisabled: boolean;
  @Input() rejectDisabled: boolean;
  @Input() disableDownloadDoc = false;
  @Input() displayReuploadDoc = true;
  @Input() hideSign: boolean;
  @Input() hasTags: boolean;
  @Input() isBefore: boolean;
  @Input() isAfter: boolean;
  @Input() canLoadSignature: boolean;
  @Input() isBeforeOrAfterDate: Date;
  @Input() isRejectedOnce: boolean;
  @Input() mustWaitOthers: boolean;
  @Input() hasDocumentsToView = false;
  @Input() hasDocumentsToSign = false;
  @Input() documentRejectActionButtonEnabled: boolean;
  @Input() documentCancelActionButtonEnabled: boolean;
  @Input() documentSuspendActionButtonEnabled: boolean;
  @Input() canCancelAndSuspendSession = true;
  @Input() disabled: boolean;
  @Output() afterAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() reuploadDocEvent: EventEmitter<File> = new EventEmitter<File>();
  @Output() downloadDocument = new EventEmitter<any>();
  @Output() addAcroform = new EventEmitter<any>();
  openOrNotModalCancel = false;
  openOrNotModalSuspend = false;
  actions: string[] = [];
  dateSignDocument;
  untilDateError = false;
  enableReplaceDocument = true;
  showModalSignerConfirmation: boolean;
  signing = false;
  rejecting = false;
  reinstating = false;
  cancelling = false;
  suspending = false;
  continuing = false;

  constructor(private modal: NgbModal,
              private nexuService: NexuService,
              private router: Router,
              private itsmeService: ItsmeService,
              private itsmeSignatureService: ItsmeSignatureService,
              private appService: AppService,
              private workflowService: SignatureWorkflowService,
              private sessionService: SessionService,
              private stepService: StepService,
              private alertService: AlertService,
              public translateService: TranslateService) {
  }

  ngOnInit() {
    this.checkStepTags();
  }

  ngOnChanges() {
    this.documentActions();
    this.functRecupDate();
  }

  checkStepTags() {
    this.showModalSignerConfirmation = some(this.step.tags, tag => tag.alias.trim() === 'SHOW_CONFIRMATION_MODAL');
    this.enableReplaceDocument = !some(this.step.tags, tag => tag.alias.trim() === 'DOCUMENT_REPLACE_BUTTON_DISABLE');
  }

  enableSignatures(): boolean {
    return (this.stepStatus !== 'COMPLETED' && this.signatureStatus !== 'SIGNED' &&
      (this.step.skipAcroforms && ( (this.stepEnduser.signer) || this.stepEnduser.manager)));
  }

  functRecupDate() {
    if (this.stepProperties && this.stepProperties.signNotBefore) {
      this.dateSignDocument = new Date(this.stepProperties.signNotBefore).toLocaleString(this.translateService.currentLang);
      this.untilDateError = true;
    } else {
      this.untilDateError = false;
    }
  }

  sign() {
    if (this.isSigner() && this.signatureStatus !== SignatureStatusEnum.DECLINED && this.signatureStatus !== SignatureStatusEnum.SIGNED) {
      if (this.sprofile === SprofileEnum.ORELY_PADES || this.sprofile === SprofileEnum.ORELY_XADES_MANIFEST) {
        this.signWithOrely();
      } else if (this.sprofile === SprofileEnum.NEXU_PADES || this.sprofile === SprofileEnum.BULK_NEXU_PADES) {
        this.signWithNexuUntil(this.childSignatureIds.concat(this.signatureId), 0, this.childSignatureIds.length + 1)
          .catch((error: ApiError) => this.alertService.errorApi(error));
      } else if (this.sprofile === SprofileEnum.ITSME_PADES) {
        this.signWithItsme();
      } else if (this.sprofile.indexOf('PADES_SEAL') !== -1) {
        this.signWithCode();
        // tslint:disable-next-line:max-line-length
      } else if (this.sprofile.indexOf('VISA') !== -1 || this.sprofile.indexOf('C2S') !== -1 || this.sprofile.indexOf('TIMESTAMP') !== -1) {
        this.applyVisa();
      } else {
        this.alertService.error('DOCUMENT.SPROFILE_NOT_SUPPORTED', {sprofile: this.sprofile});
      }
    }
    this.documentActions();
  }

  reject() {
    this.rejecting = true;
    const modalRef = this.modal.open(ModalCommentComponent, {backdrop: false});

    modalRef.componentInstance.title = 'DOCUMENT.REJECT_MODAL_DOCUMENT';
    modalRef.componentInstance.label = 'DOCUMENT.REJECT_MODAL_LABEL';
    modalRef.componentInstance.close = 'DOCUMENT.REJECT_MODAL_CANCEL';
    modalRef.componentInstance.action = 'DOCUMENT.REJECT_MODAL_ACTION';

    modalRef.componentInstance.onAction.pipe(take(1)).subscribe((commentText: string) => {

      const comment = <UpdateRejectionPayload>{
        comment: commentText, status: UpdateRejectionPayload.StatusEnum.DECLINED, documentId: this.document.id
      };

      this.workflowService.updateRejectionWorkflow(this.sessionId, this.step.id, comment)
        .pipe(take(1))
        .subscribe(() => {
          modalRef.close();
          this.afterAction.emit(DocumentActionEnum.REJECT);
          this.rejecting = false;
        }, (error: ApiError) => {
          this.rejecting = false;
          this.alertService.errorApi(error);
        });
    });
  }

  reinstate() {
    this.reinstating = true;
    const modalRef = this.modal.open(ModalCommentComponent, {backdrop: false});

    modalRef.componentInstance.title = 'DOCUMENT.REINSTATE_MODAL_DOCUMENT';
    modalRef.componentInstance.label = 'DOCUMENT.REINSTATE_MODAL_LABEL';
    modalRef.componentInstance.close = 'DOCUMENT.REINSTATE_MODAL_CANCEL';
    modalRef.componentInstance.action = 'DOCUMENT.REINSTATE_MODAL_ACTION';

    modalRef.componentInstance.onAction.pipe(take(1)).subscribe((commentText: string) => {

      const comment = <UpdateRejectionPayload>{
        comment: commentText, status: UpdateRejectionPayload.StatusEnum.STARTED, documentId: this.document.id
      };

      this.workflowService.updateRejectionWorkflow(this.sessionId, this.step.id, comment)
        .pipe(take(1))
        .subscribe(() => {
          modalRef.close();
          this.afterAction.emit(DocumentActionEnum.REINSTATE);
          this.reinstating = false;
        }, (error: ApiError) => {
          this.alertService.errorApi(error);
          this.reinstating = false;
        });
    });
  }

  cancel() {
    this.cancelling = true;
    forkJoin([this.stepService.cancelStep(this.sessionId, this.step.id), this.sessionService.cancelSession(this.sessionId)]).pipe(take(1))
      .subscribe(() => {
        this.afterAction.emit(DocumentActionEnum.CANCEL);
        this.openOrNotModalCancel = false;
        this.cancelling = false;
      }, (error: ApiError) => {
        this.alertService.errorApi(error);
        this.cancelling = false;
      });
  }

  suspend() {
    this.suspending = true;
    forkJoin([this.stepService.suspendStep(this.sessionId, this.step.id), this.sessionService.suspendSession(this.sessionId)]).pipe(take(1))
      .subscribe(() => {
        this.openOrNotModalSuspend = false;
        this.afterAction.emit(DocumentActionEnum.SUSPEND);
        this.suspending = false;
      }, (error: ApiError) => {
        this.alertService.errorApi(error);
        this.suspending = false;
      });
  }

  continue() {
    this.continuing = true;
    forkJoin([this.stepService.goonStep(this.sessionId, this.step.id), this.sessionService.goonSession(this.sessionId)]).pipe(take(1))
      .subscribe(() => {
        // window.location.reload();
        this.afterAction.emit(DocumentActionEnum.CONTINUE);
        this.continuing = false;
      }, (error: ApiError) => {
        this.alertService.errorApi(error);
        this.continuing = false;
      });
  }

  isManager() {
    return this.stepEnduser.manager;
  }

  isSigner() {
    if (this.stepEnduser) {
      return this.stepEnduser.signer;
    }
  }

  isStepStarted() {
    return this.stepStatus === StepData.StatusEnum.STARTED;
  }

  isStepSuspend() {
    return this.stepStatus === StepData.StatusEnum.SUSPENDED;
  }

  isSignatureStarted() {
    return this.signatureStatus === SignatureData.StatusEnum.STARTED;
  }

  isSignatureDeclined() {
    return this.signatureStatus === SignatureData.StatusEnum.DECLINED;
  }

  isVisa() {
    return this.sprofile === DocumentActionEnum.VISA;
  }

  // define document actions *** ***
  documentActions() {
    this.actions = [];
    if (
      this.isSigner() &&
      this.isStepStarted() &&
      (this.appService.userHasEntitlement(this.appService.ent.signer) ||
        this.appService.userHasEntitlement(this.appService.ent.admin)
      )
    ) {
      if (this.isSignatureStarted()) {
        if (this.isVisa()) {
          this.actions.push(DocumentActionEnum.VISA);
        } else {
          this.actions.push(DocumentActionEnum.SIGN);
        }
        if (this.documentRejectActionButtonEnabled) {
          this.actions.push(DocumentActionEnum.REJECT);
        }
      }
      if (this.isSignatureDeclined()) {
        this.actions.push(DocumentActionEnum.REINSTATE);
      }
    }

    if (this.isManager() && this.canCancelAndSuspendSession) {
      if (this.isStepStarted()) {
        if (this.documentCancelActionButtonEnabled) {
          this.actions.push(DocumentActionEnum.CANCEL);
        }
        if (this.documentSuspendActionButtonEnabled) {
          this.actions.push(DocumentActionEnum.SUSPEND);
        }
      }
      if (this.isStepSuspend()) {
        this.actions.push(DocumentActionEnum.CONTINUE);
      }
    }
  }

  handleActions(action) {
    switch (action) {
      case DocumentActionEnum.SIGN:
        this.sign();
        break;
      case DocumentActionEnum.VISA:
        this.sign();
        break;
      case DocumentActionEnum.CANCEL:
        this.openOrNotModalCancel = true;
        break;
      case DocumentActionEnum.REJECT:
        this.reject();
        break;
      case DocumentActionEnum.REINSTATE:
        this.reinstate();
        break;
      case DocumentActionEnum.SUSPEND:
        this.openOrNotModalSuspend = true;
        break;
      case DocumentActionEnum.CONTINUE:
        this.continue();
        break;
      default:
        console.error('Unknown action: ', action);
        break;
    }
  }

  private signWithOrely() {
    this.signing = true;
    const modalRef = this.modal.open(ModalOrelyComponent, {backdrop: false});
    modalRef.componentInstance.signatureId = this.signatureId;
    this.resultAfterSign(modalRef);
  }

  private passDataIntoModale(modalRef) {
    modalRef.componentInstance.sessionId = this.sessionId;
    modalRef.componentInstance.stepId = this.step.id;
    modalRef.componentInstance.signatureId = this.signatureId;
    modalRef.componentInstance.sprofile = this.sprofile;
  }

  private signWithCode() {
    this.signing = true;
    const ngbModalOptions: NgbModalOptions = {
      backdrop: false, keyboard: false
    };
    const modalRef = this.modal.open(ModalSignWithCodeComponent, ngbModalOptions);
    this.passDataIntoModale(modalRef);
    modalRef.componentInstance.generationEvent.subscribe(
      (event: { message: string, params: Object }) => this.alertService.success(event.message, event.params));
    this.resultAfterSign(modalRef);
  }

  private applyVisa() {
    this.signing = true;
    const ngbModalOptions: NgbModalOptions = {
      backdrop: false, keyboard: false
    };
    const modalRef = this.modal.open(ModalApplyVisaComponent, ngbModalOptions);
    this.passDataIntoModale(modalRef);
    this.resultAfterSign(modalRef, DocumentActionEnum.VISA);
  }

  private signWithNexuUntil(signaturesIds: number[], current: number, until: number) {
    this.signing = true;
    if (current < until) {
      return this.signWithNexu(signaturesIds[current]).then(() => this.signWithNexuUntil(signaturesIds, current + 1, until));
    }
    this.signing = false;
    return Promise.resolve();
  }

  private signWithNexu(signatureId: number) {
    return this.nexuService.sign(String(signatureId)).then(() => {
      this.alertService.success('DOCUMENT.ACTION.SIGNED');
      this.afterAction.emit('SIGN');
    });
  }

  private signWithItsme() {
    this.signing = true;
    const redirectUrl = this.itsmeService.getRedirectUrlForSignature(ItsmeServiceSignatureEnum.IDENTIFICATION);
    const lang = this.translateService.currentLang;

    this.itsmeSignatureService.itsmeUserIdentification(lang, redirectUrl, this.appService.getCurrentUserId(), this.signatureId)
      .pipe(take(1))
      .subscribe((response: ItsmeUserSessionDto) => {
        window.location.href = response.url;
        this.signing = false;
      });
  }

  resultAfterSign(modalRef, afterAction ?) {
    modalRef.result.then((data) => {
      if (data.status === 'SUCCEEDED') {
        if (this.showModalSignerConfirmation) {
          this.modal.open(ModalConfirmSignatureComponent, {backdrop: false, centered: true});
        } else {
          this.alertService.success(data.message);
        }
        const action = afterAction ? afterAction : DocumentActionEnum.SIGN;
        this.afterAction.emit(action);
      } else if (data.status === 'FAILED') {
        this.alertService.error('ALERT.SIGNATURE_FAILED');
      } else if (data.status === 'CANCELLED') {
        this.alertService.warning('ALERT.SIGNATURE_CANCELLED');
      }
      this.signing = false;
    });
  }

  onDownloadDocument() {
    this.downloadDocument.emit();
  }

  reupload(event) {
    const files: FileList = event.target.files;
    if (files && files.length === 1) {
      this.reuploadDocEvent.emit(files[0]);
    }
  }

  goDocuments() {
    sessionStorage.setItem('comToParapheur', 'true');
    this.router.navigate(['/session', this.sessionId, 'step', this.step.id]);
  }

  goToMissingMetadata() {
    sessionStorage.setItem('comeFromMissingTag', 'true');
    this.router.navigate(['/session', this.sessionId, 'step', this.step.id]);
  }

  goToDocumentsToView() {
    sessionStorage.setItem('comeFromDocumentsToView', 'true');
    this.router.navigate(['/session', this.sessionId, 'step', this.step.id]);
  }

  goToDocumentsToSign() {
    this.router.navigate(['/session', this.sessionId, 'step', this.step.id]);
  }
}
