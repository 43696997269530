<app-tooltip [tooltipText]="'HELP.REGISTER' | translate"></app-tooltip>

<app-full-screen [title]="'INVITE.TITLE' | translate"
                 mode="container-m">

  <form (ngSubmit)="onSubmit()"
        [formGroup]="registerForm"
        class="row">

    <!-- top left inputs group -->
    <div class="col-12 col-md-6">

      <!-- password -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('password')"
                      [errors]="{
                      required: 'INVITE.ERRORS.REQUIRED' | translate,
                      pattern: 'INVITE.ERRORS.FORMAT' | translate
                      }"
                        [placeholder]="'SIGN_UP.PASSWORD.PLACEHOLDER' | translate"
                        class="col"
                        [hasOnInput]="true"
                        [idPostfix]="'input-new-password'"
                        formControlName="password"
                        icon="key"
                        type="password"></app-input-text>

      </div>

      <!-- Et re password par derriere -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('confirmPassword')"
        [errors]="{
                  required: 'INVITE.ERRORS.REQUIRED' | translate,
                  matchPassword: 'INVITE.ERRORS.MATCH' | translate
                  }"
                        [placeholder]="'SIGN_UP.CONFIRM_PASSWORD.PLACEHOLDER' | translate"
                        class="col"
                        [hasOnInput]="true"
                        [idPostfix]="'input-confirm-new-password'"
                        formControlName="confirmPassword"
                        icon="check"
                        type="password"></app-input-text>
      </div>
    </div>

    <!-- top right inputs group -->
    <div class="col-12 col-md-6">

      <!-- first name -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('firstName')"
                        class="col"
                        [idPostfix]="'firstName'"
                        formControlName="firstName"
                        icon="user"></app-input-text>
      </div>

      <!-- last name -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('lastName')"
                        class="col"
                        [idPostfix]="'lastName'"
                        formControlName="lastName"
                        icon="user"></app-input-text>
      </div>

      <!-- email -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('email')"
                        class="col"
                        [idPostfix]="'email'"
                        formControlName="email"
                        icon="envelope"
                        type="email"></app-input-text>
      </div>
    </div>

    <!-- submit / cancel -->
    <div class="col-12 col-md-6 mt-2">
      <button class="btn flex-grow-1 btn-primary col-6 float-right"
              type="submit" [disabled]="!registerForm.valid">{{'INVITE.REGISTER' | translate}}</button>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <button (click)="onProceed()"
              class="btn flex-grow-1 btn-danger col-6"
              type="button">{{'INVITE.PROCEED' | translate}}</button>
    </div>

  </form>
</app-full-screen>
