import {Component, EventEmitter, Input, Output} from '@angular/core';

export interface SignerDetails {
  signer: {
    firstName: string, lastName: string, email: string, circle: boolean
  };
  pages: string;
}

@Component({
  selector: 'app-document-item', templateUrl: './document-item.component.html', styleUrls: ['./document-item.component.scss']
})
export class DocumentItemComponent {

  @Input() document: File;
  @Input() canAddAcroform: boolean;
  @Input() signersDetails: SignerDetails[];
  @Output() addAcroform: EventEmitter<File> = new EventEmitter<File>();
  @Output() delete: EventEmitter<File> = new EventEmitter<File>();

}
