/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Handles different information related to the signature
 */
export interface SignatureData { 
    /**
     * Signatory identifier
     */
    enduserId?: string;
    /**
     * Signature id
     */
    id?: number;
    /**
     * Signature profile
     */
    profileCode?: string;
    /**
     * Signed document id
     */
    signatureDocumentId?: number;
    /**
     * Signature field (PDF)
     */
    signatureField?: string;
    /**
     * Signature date
     */
    signedOn?: Date;
    /**
     * Source signature, if several docs at once
     */
    sourceId?: number;
    /**
     * Signature status
     */
    status?: SignatureData.StatusEnum;
}
export namespace SignatureData {
    export type StatusEnum = 'STARTED' | 'DECLINED' | 'SIGNED';
    export const StatusEnum = {
        STARTED: 'STARTED' as StatusEnum,
        DECLINED: 'DECLINED' as StatusEnum,
        SIGNED: 'SIGNED' as StatusEnum
    };
}
