import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tutoriel',
  templateUrl: './tutoriel.component.html',
  styleUrls: ['./tutoriel.component.scss']
})
export class TutorielComponent implements OnInit {

  @Output() launchTutoEmit: EventEmitter<boolean> = new EventEmitter();

  showTuto = true;

  constructor() { }

  ngOnInit() {
  }

  launchTuto(){
    this.launchTutoEmit.emit(true);
  }

}
