import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PatternData } from 'luxtrust-cosi-api/model/patternData';

@Component({
  selector: 'app-template-editor-output-panel',
  templateUrl: './template-editor-output-panel.component.html',
  styleUrls: ['./template-editor-output-panel.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class TemplateEditorOutputPanelComponent {

  @Input() patternData: PatternData;

}
