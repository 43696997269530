/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-orely-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/pipes/translate.pipe";
import * as i3 from "../../core/service/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./modal-orely-link.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../services/services/alert-service";
import * as i9 from "../../services/services/app.service";
var styles_ModalOrelyLinkComponent = [i0.styles];
var RenderType_ModalOrelyLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalOrelyLinkComponent, data: {} });
export { RenderType_ModalOrelyLinkComponent as RenderType_ModalOrelyLinkComponent };
function View_ModalOrelyLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["frameborder", "0"], ["height", "700"], ["name", "orely"], ["scrolling", "yes"], ["target", "_parent"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orelyAuthUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_ModalOrelyLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalOrelyLinkComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "modal-footer"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.iFrameVisible; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("USER_PROFILE.LUXTRUST.FIND_SSN")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ModalOrelyLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).iFrameEventListener($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ModalOrelyLinkComponent_0, RenderType_ModalOrelyLinkComponent)), i1.ɵdid(1, 49152, null, 0, i5.ModalOrelyLinkComponent, [i6.NgbActiveModal, i7.DomSanitizer, i3.TranslateService, i8.AlertService, i9.AppService], null, null)], null, null); }
var ModalOrelyLinkComponentNgFactory = i1.ɵccf("ng-component", i5.ModalOrelyLinkComponent, View_ModalOrelyLinkComponent_Host_0, {}, {}, []);
export { ModalOrelyLinkComponentNgFactory as ModalOrelyLinkComponentNgFactory };
