/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SignatureResponsePayload } from '../model/signatureResponsePayload';
import { SignatureSession } from '../model/signatureSession';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SessionsignatureService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get a signed document based on a signature ID
     * Returns a signed PDF document with associated description
     * @param applicationId application identifier consuming this API
     * @param applicationSecret application secret consuming this API (RSA signed - Public key must be shared with LuxTrust Signature Platform)
     * @param sessionId ID of session to return
     * @param tenantName Name of the tenant related to your subscription
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSignatureSessionById(applicationId: string, applicationSecret: string, sessionId: number, tenantName: string, observe?: 'body', reportProgress?: boolean): Observable<SignatureResponsePayload>;
    public getSignatureSessionById(applicationId: string, applicationSecret: string, sessionId: number, tenantName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignatureResponsePayload>>;
    public getSignatureSessionById(applicationId: string, applicationSecret: string, sessionId: number, tenantName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignatureResponsePayload>>;
    public getSignatureSessionById(applicationId: string, applicationSecret: string, sessionId: number, tenantName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling getSignatureSessionById.');
        }

        if (applicationSecret === null || applicationSecret === undefined) {
            throw new Error('Required parameter applicationSecret was null or undefined when calling getSignatureSessionById.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getSignatureSessionById.');
        }

        if (tenantName === null || tenantName === undefined) {
            throw new Error('Required parameter tenantName was null or undefined when calling getSignatureSessionById.');
        }

        let headers = this.defaultHeaders;
        if (applicationId !== undefined && applicationId !== null) {
            headers = headers.set('applicationId', String(applicationId));
        }
        if (applicationSecret !== undefined && applicationSecret !== null) {
            headers = headers.set('applicationSecret', String(applicationSecret));
        }
        if (tenantName !== undefined && tenantName !== null) {
            headers = headers.set('tenantName', String(tenantName));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SignatureResponsePayload>(`${this.basePath}/api/directsign/v1/sessionsignature/${encodeURIComponent(String(sessionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sign a document based on a pre-existing session
     * 
     * @param applicationId application identifier consuming this API
     * @param applicationSecret application secret consuming this API (RSA signed - Public key must be shared with LuxTrust Signature Platform )
     * @param sessionId ID of session to return
     * @param signaturesession Session object to sign
     * @param tenantName Name of the tenant related to your subscription
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signSession(applicationId: string, applicationSecret: string, sessionId: number, signaturesession: SignatureSession, tenantName: string, observe?: 'body', reportProgress?: boolean): Observable<SignatureResponsePayload>;
    public signSession(applicationId: string, applicationSecret: string, sessionId: number, signaturesession: SignatureSession, tenantName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignatureResponsePayload>>;
    public signSession(applicationId: string, applicationSecret: string, sessionId: number, signaturesession: SignatureSession, tenantName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignatureResponsePayload>>;
    public signSession(applicationId: string, applicationSecret: string, sessionId: number, signaturesession: SignatureSession, tenantName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling signSession.');
        }

        if (applicationSecret === null || applicationSecret === undefined) {
            throw new Error('Required parameter applicationSecret was null or undefined when calling signSession.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling signSession.');
        }

        if (signaturesession === null || signaturesession === undefined) {
            throw new Error('Required parameter signaturesession was null or undefined when calling signSession.');
        }

        if (tenantName === null || tenantName === undefined) {
            throw new Error('Required parameter tenantName was null or undefined when calling signSession.');
        }

        let headers = this.defaultHeaders;
        if (applicationId !== undefined && applicationId !== null) {
            headers = headers.set('applicationId', String(applicationId));
        }
        if (applicationSecret !== undefined && applicationSecret !== null) {
            headers = headers.set('applicationSecret', String(applicationSecret));
        }
        if (tenantName !== undefined && tenantName !== null) {
            headers = headers.set('tenantName', String(tenantName));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SignatureResponsePayload>(`${this.basePath}/api/directsign/v1/sessionsignature/${encodeURIComponent(String(sessionId))}`,
            signaturesession,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
