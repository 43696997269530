<app-tooltip [tooltipText]="'HELP.VALIDATION' | translate"></app-tooltip>

<div class="fixed-container">
    <div *ngIf="!showResult" class="fixed-md justify-content-center d-flex flex-column">    
        <h1 class="mt-4 mb-3">{{'VALIDATION.TITLE' | translate}}</h1>        
        
        <form [formGroup]="validationForm" class="row pb-3">
            <div class="col-12">
                <!-- document -->
                <app-input-file
                    (ngModelChange)="updateInput($event)"
                    [browseLabel]="'VALIDATION.FORM.BROWSELABEL' | translate"
                    [errors]="{                        
                        required: 'VALIDATION.ERRORS.REQUIRED' | translate,
                        sizeLimit: 'VALIDATION.ERRORS.SIZELIMIT_EXCEDEED' | translate : {filename: getFilename(), maxSize: this.FILE_SIZE_LIMIT},
                        supportedFormats: 'VALIDATION.ERRORS.INVALID_FILE_EXTENSION' | translate : {supportedFormats: 'pdf'}
                    }"
                    [id]="'input-document'"
                    [label]="'VALIDATION.FORM.LABEL' | translate"
                    [multiple]="false"
                    [placeholder]=placeHolder
                    [tabindex]="0"
                    class="col-12"
                    formControlName="file"
                    icon="image"></app-input-file>

                
                <div class="d-flex">
                    <!-- submit -->
                    <button [disabled]="validationForm.invalid"
                        (click)="onSubmit()"
                        class="btn flex-grow-1 btn-primary mr-2"
                        tabindex="1"
                        id="submitButton"
                        type="submit">{{'VALIDATION.FORM.SUBMIT' | translate}}</button>
                
                    <!-- clear -->
                    <button [disabled]="false"
                        (click)="onReset()"
                        class="btn flex-grow-1 btn-primary mr-2"
                        tabindex="2"
                        id="resetButton"
                        type="button">{{'VALIDATION.FORM.RESET' | translate}}</button>
                </div>

            </div>
        </form>    
    </div>

    <div *ngIf="showResult" class="fixed-md justify-content-center d-flex flex-column">    
        <h1 class="mt-4 mb-3">{{'VALIDATION.REPORT.TITLE' | translate}}</h1>

        <div class="col-12">        
            <h4 class="alert-heading">{{ validationReport.validationStatus }}</h4>
            <h6><strong>{{ getFilename() }}</strong></h6>
            <hr />
        </div>
        <div *ngIf="!validationReport.signatures" class="col-12 alert alert alert-danger">
            <h6 class="alert-heading"><strong>{{'VALIDATION.REPORT.SIGNATURE_NOT_FOUND' | translate}}</strong></h6>
        </div>
        <div *ngIf="validationReport.signatures">
            <div 
                [ngClass]="['col-12','alert', signature.validationStatus === 'TOTAL-PASSED' ? 'alert-success': 'alert-danger']"
                *ngFor="let signature of validationReport.signatures">                
                <p>
                    <span class="font-weight-bold">{{'VALIDATION.REPORT.STATUS' | translate}}</span>
                    <span>: {{ signature.validationStatus }}</span>
                </p>
                <p>
                    <span class="font-weight-bold">{{'VALIDATION.REPORT.QUALITY' | translate}}</span>
                    <span>: {{ signature.signatureQuality }}</span>
                </p>
                <p>
                    <span class="font-weight-bold">{{'VALIDATION.REPORT.SIGNER' | translate}}</span>
                    <span>: {{ signature.signer }}</span>
                </p>
                <p>
                    <span class="font-weight-bold">{{'VALIDATION.REPORT.SIGNING_TIME' | translate}}</span>
                    <span>: {{ signature.claimedSigningTime }}</span>
                </p>
            </div>        
        </div>
     
        <div class="d-flex mt-4 mb-4">
            <!-- download -->
            <button [disabled]="false"
                (click)="onDownload()"
                class="btn flex-grow-1 btn-primary mr-2"
                tabindex="3"
                id="downloadButton"
                type="button">{{'VALIDATION.REPORT.DOWNLOAD' | translate}}</button>
        
            <!-- close -->
            <button [disabled]="false"
                (click)="onClose()"
                class="btn flex-grow-1 btn-secondary mr-2"
                tabindex="4"
                id="closeButton"
                type="button">{{'VALIDATION.REPORT.CLOSE' | translate}}</button>
        </div>
    </div>
</div>

<!-- loader -->
<ngx-spinner fullScreen="true"
             size="large"
             type="ball-clip-rotate">
  <p style="color: white">{{'VALIDATION.SPINNER_TEXT' | translate}}</p>
</ngx-spinner>