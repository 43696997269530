<ng-container *ngIf="screenSize < XS_BREAKPOINT">
  <!-- xtra small screen -->
  {{(label?.length > xsMaxCharacters) ? (label | slice : 0:xsMaxCharacters) + '...' : label}}
</ng-container>

<ng-container *ngIf="XS_BREAKPOINT <= screenSize && screenSize < SM_BREAKPOINT">
  <!-- small screen -->
  {{(label?.length > smMaxCharacters) ? (label | slice : 0:smMaxCharacters) + '...' : label}}
</ng-container>

<ng-container *ngIf="SM_BREAKPOINT <= screenSize && screenSize < MD_BREAKPOINT">
  <!-- medium screen -->
  {{(label?.length > mdMaxCharacters) ? (label | slice : 0:mdMaxCharacters) + '...' : label}}
</ng-container>

<ng-container *ngIf="MD_BREAKPOINT <= screenSize && screenSize < LG_BREAKPOINT">
  <!-- large screen -->
  {{(label?.length > lgMaxCharacters) ? (label | slice : 0:lgMaxCharacters) + '...' : label}}
</ng-container>

<ng-container *ngIf="LG_BREAKPOINT <= screenSize && screenSize < XL_BREAKPOINT">
  <!-- xtra large screen -->
  {{(label?.length > xlMaxCharacters) ? (label | slice : 0:xlMaxCharacters) + '...' : label}}
</ng-container>

<ng-container *ngIf="XL_BREAKPOINT <= screenSize">
  <!-- cumstom xxtra large screen -->
  {{(label?.length > xxlMaxCharacters) ? (label | slice : 0:xxlMaxCharacters) + '...' : label}}
</ng-container>
