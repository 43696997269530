import {enableProdMode, NgZone} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import "zone.js/dist/task-tracking";

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
// import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(moduleInstance => {
    if (!environment.production) {
      const ngZone = moduleInstance.injector.get(NgZone);
      window['listPendingTasks'] = () => {
        const taskTrackingZone = (<any>ngZone)._inner.getZoneWith("TaskTrackingZone");
        if (!taskTrackingZone) {
          throw new Error("'TaskTrackingZone' zone not found! Have you loaded 'node_modules/zone.js/dist/task-tracking.js'?");
        }
        const tasks: any[] = taskTrackingZone._properties.TaskTrackingZone.getTasksFor("macroTask");
        if (tasks.length == 0) {
          console.log("No pending tasks");
          return null;
        } else {
          const errors = tasks.map(it => it.creationLocation.stack);
          errors.forEach(it => console.log(it));
          return errors;
        }
      };
    }
  })
  .catch(err => console.log(err));
