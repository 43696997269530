/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A comment to be added
 */
export interface CreateCommentPayload { 
    /**
     * Comment content
     */
    specificText?: string;
    /**
     * Comment content
     */
    text?: string;
    /**
     * Comment type
     */
    type?: CreateCommentPayload.TypeEnum;
}
export namespace CreateCommentPayload {
    export type TypeEnum = 'SIMPLE' | 'REJECTION' | 'REINSTATEMENT' | 'REUPLOAD' | 'REMINDER';
    export const TypeEnum = {
        SIMPLE: 'SIMPLE' as TypeEnum,
        REJECTION: 'REJECTION' as TypeEnum,
        REINSTATEMENT: 'REINSTATEMENT' as TypeEnum,
        REUPLOAD: 'REUPLOAD' as TypeEnum,
        REMINDER: 'REMINDER' as TypeEnum
    };
}
