/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../core/pipes/safe-html.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./tooltip.component";
var styles_TooltipComponent = [i0.styles];
var RenderType_TooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
function View_TooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["toolTipButton", 1]], null, 1, "button", [["class", "helpButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.popupTip() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-info"]], null, null, null, null, null))], null, null); }
function View_TooltipComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "r-background"]], null, null, null, null, null))], null, null); }
function View_TooltipComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "closeBtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.popupTip() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null))], null, null); }
function View_TooltipComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["toolTipWindow", 1]], null, 4, "div", [["class", "tooltipBox"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTip; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _co.text)); _ck(_v, 4, 0, currVal_1); }); }
export function View_TooltipComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeHtmlPipe, [i4.DomSanitizer]), i1.ɵqud(671088640, 1, { toolTipButton: 0 }), i1.ɵqud(671088640, 2, { toolTipWindow: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showTip; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.showTip; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.showTip; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_TooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i1.ɵdid(1, 573440, null, 0, i5.TooltipComponent, [], null, null)], null, null); }
var TooltipComponentNgFactory = i1.ɵccf("app-tooltip", i5.TooltipComponent, View_TooltipComponent_Host_0, { tooltipText: "tooltipText" }, {}, []);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
