import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ItsmeAuthLinkComponent } from './components/itsme-auth/itsme-auth-link/itsme-auth-link.component';
import { ItsmeAuthLoginComponent } from './components/itsme-auth/itsme-auth-login/itsme-auth-login.component';
import { ItsmeAuthSignupComponent } from './components/itsme-auth/itsme-auth-signup/itsme-auth-signup.component';
import { ItsmeAuthUnlinkComponent } from './components/itsme-auth/itsme-auth-unlink/itsme-auth-unlink.component';
import { ItsmeSignatureComponent } from './components/itsme-signature/itsme-signature.component';

const routes: Routes = [
  {
    path: 'auth', component: ItsmeAuthLoginComponent, pathMatch: 'full'
  },
  {
    path: 'signup', component: ItsmeAuthSignupComponent, pathMatch: 'full'
  },
  {
    path: 'link', component: ItsmeAuthLinkComponent, pathMatch: 'full'
  },
  {
    path: 'unlink', component: ItsmeAuthUnlinkComponent, pathMatch: 'full'
  },
  {
    path: 'sign', component: ItsmeSignatureComponent, pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)], exports: [RouterModule]
})
export class CoreRoutingModule {
}
