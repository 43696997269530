<app-tooltip [tooltipText]="'HELP.WIZARD_REMINDERS' | translate"></app-tooltip>

<app-wizard-step>
  <div class="fixed-md" content>
    <div class="text-center">
      <h4 class="mb-4">{{'WIZARD.REMINDERS.TITLE' | translate}}</h4>

      <app-items-table [borderBottom]="true"
                       [items]="reminders"
                       [noResult]="'WIZARD.REMINDERS.NO_RESULT' | translate">
        <ng-container action>
          <h2 class="mt-2">{{'WIZARD.REMINDERS.ONE_TIME_REMINDER' | translate}}</h2>
          <div *ngIf="signers?.length > 0" class="flex-grid-2 cardList">
            <div *ngFor="let signer of signers" class="meta-card d-flex p-2 pl-3 mb-1" class="cardListItem">
              <div class="form-group reminder-switcher">
                <div class="switcher switcher-right ml-0">
                  <input [(ngModel)]="signerIds[signer.id]"
                         [checked]="signerIds[signer.id]"
                         class="switcher-checkbox"
                         id="signer-id-reminder-{{signer.id}}"
                         type="checkbox">
                  <div class="switcher-toggle"></div>
                 <label for="signer-id-reminder-{{signer.id}}">&nbsp;</label>
                </div>
              </div>
              <div class="align_text flex-grow-1 alignWithSwitcher">
                {{signer.firstName}} {{signer.lastName}}
              </div>
            </div>
          </div>

          <div class="form-group mt-3 row picker-row">
            <div class="col-10 pr-3">
              <app-date-time-picker
                [(ngModel)]="reminderOnceDate" [default]="defaultDateTime">
              </app-date-time-picker>
            </div>
            <div class="col-2 pl-0 d-flex align-items-end">
              <button (click)="addReminder()"
                      [disabled]="noSigner() || addingReminder"
                      class="btn btn-primary mb-3"
                      type="button">{{'WIZARD.REMINDERS.ADD' | translate}}</button>
            </div>
          </div>

          <span class="text-danger form-text pb-2" *ngIf="signers?.length === 0">{{'WIZARD.REMINDERS.NO_SIGNER' | translate}}</span>

          <hr class="separator">
          <h2 class="mt-2">{{'WIZARD.REMINDERS.PERIODIC_REMINDER' | translate}}</h2>
          <div class="form-group mt-3 row freq-row">
            <div class="col-10 row pr-0">
              <div class="app-input-text col-12 pr-0 text-left">
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend fill-group">
                      <div class="input-group-text">
                        <span class="center-text">{{'WIZARD.REMINDERS.FREQUENCY_BEFORE' | translate}}</span>
                      </div>
                      <select [(ngModel)]="frequency" class="form-control">
                        <option value="6">6 {{'WIZARD.REMINDERS.HOURS' | translate}}</option>
                        <option value="12">12 {{'WIZARD.REMINDERS.HOURS' | translate}}</option>
                        <option value="24">1 {{'WIZARD.REMINDERS.DAY' | translate}}</option>
                        <option value="48">2 {{'WIZARD.REMINDERS.DAYS' | translate}}</option>
                        <option value="168">7 {{'WIZARD.REMINDERS.DAYS' | translate}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 row pr-0">
              <div class="app-input-text col-12 pr-0 text-left">
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend fill-group">
                      <div class="input-group-text">
                        <span class="center-text">{{'WIZARD.REMINDERS.FREQUENCY_AFTER' | translate}}</span>
                      </div>
                      <select [(ngModel)]="start" class="form-control">
                        <option value="20">20 {{'WIZARD.REMINDERS.DAYS' | translate}}</option>
                        <option value="10">10 {{'WIZARD.REMINDERS.DAYS' | translate}}</option>
                        <option value="5">5 {{'WIZARD.REMINDERS.DAYS' | translate}}</option>
                        <option value="3">3 {{'WIZARD.REMINDERS.DAYS' | translate}}</option>
                        <option value="2">2 {{'WIZARD.REMINDERS.DAYS' | translate}}</option>
                        <option value="1">1 {{'WIZARD.REMINDERS.DAY' | translate}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 pr-0 ml-3 mb-3 d-flex align-items-end">
              <!-- {{'WIZARD.REMINDERS.START_AFTER' | translate}} -->
              <button (click)="addRecurrentReminder()"
                      [disabled]="!properties?.signNotAfter || !frequency || !start || noSigner() || addingReminder"
                      class="btn btn-primary"
                      type="button">{{'WIZARD.REMINDERS.ADD' | translate}}</button>
            </div>
          </div>
          <span class="text-danger form-text pb-2" *ngIf="!properties?.signNotAfter && signers?.length > 0">{{'WIZARD.REMINDERS.NO_DATE' | translate}}</span>
          <span class="text-danger form-text pb-2" *ngIf="properties?.signNotAfter && signers?.length === 0">{{'WIZARD.REMINDERS.NO_SIGNER' | translate}}</span>
          <span class="text-danger form-text pb-2" *ngIf="!properties?.signNotAfter && signers?.length === 0">{{'WIZARD.REMINDERS.NO_DATE_SIGNER' | translate}}</span>

          <hr class="separator">
          <h2 class="mt-2">{{'WIZARD.REMINDERS.REMINDERS_LIST' | translate}}</h2>

          <div class="form-group mt-3" *ngIf="reminders?.length > 0">
            <button (click)="deleteAllReminders()"
                    [disabled]="deletingAllReminder"
                    class="btn btn-danger"
                    type="button">{{'WIZARD.REMINDERS.DELETE' | translate}}</button>
          </div>
        </ng-container>

        <ng-template #itemsCards let-stepReminder>
          <div
            [style.background-color]="'rgb(' + stepReminder.color[0] + ',' + stepReminder.color[1] + ',' + stepReminder.color[2] + ')'"
            class="meta-card row p-2 pl-3 mb-2 ml-0">
            <div class="col-10 p-0 text-left pr-1">
              <div class="mb-0">
               <span *ngIf="!stepReminder.startingDate"> {{stepReminder.sendOn | date:'dd/MM/yyyy HH:mm'}}</span>
               <span *ngIf="stepReminder.startingDate">{{'WIZARD.REMINDERS.STARTING_DATE' | translate}} {{stepReminder.day}} {{'WIZARD.REMINDERS.DAY_REMINDER' | translate}} {{stepReminder.hour}}</span>
              </div>
              <label class="mb-0">
                {{getSignerName(stepReminder.enduserId)}}
              </label>
            </div>
            <div class="col-2 p-0">
              <button  *ngIf="!stepReminder.sourceId" (click)="deleteReminder(stepReminder)"
                       [disabled]="deletingReminder"
                class="btn btn-danger pull-right">
                <i aria-hidden="true" class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </ng-template>
      </app-items-table>

    </div>
  </div>

  <div class="fixed-container" footer>
    <div class="fixed-md">
      <app-wizard-step-button [action]="stepActions.PREVIOUS"
                              [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>
      <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                              class="float-right"></app-wizard-step-button>
    </div>
  </div>
</app-wizard-step>
