/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StepEnduserData } from './stepEnduserData';


/**
 * Signature book signer details
 */
export interface SignatureBookSignerData { 
    /**
     * Signature id
     */
    signatureId?: number;
    /**
     * Status of the signature
     */
    signatureStatus?: SignatureBookSignerData.SignatureStatusEnum;
    /**
     * Date of signature
     */
    signedOn?: Date;
    /**
     * StepEnduser involved in the step
     */
    stepEnduser?: StepEnduserData;
}
export namespace SignatureBookSignerData {
    export type SignatureStatusEnum = 'STARTED' | 'DECLINED' | 'SIGNED';
    export const SignatureStatusEnum = {
        STARTED: 'STARTED' as SignatureStatusEnum,
        DECLINED: 'DECLINED' as SignatureStatusEnum,
        SIGNED: 'SIGNED' as SignatureStatusEnum
    };
}
