import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/internal/operators';
import {AccessToken} from '../model/access-token.model';
import {AlertService} from '../services/alert-service';
import {AppService} from '../services/app.service';
import {NexuService} from '../services/nexu.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private alertService: AlertService,
              private appService: AppService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: AccessToken = this.appService.get();
    if (request.url.indexOf(NexuService.URL_NEXU_SPECIFIC) === -1 && token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token.raw}`
        }
      });
    }
    return next.handle(request).pipe(tap(() => {
    }, (error: Error) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this.logout();
          this.alertService.info('ALERT.TOKEN_EXPIRED');
        }
        if (error.status === 500 && error.error.message === 'JWT token verification failed') {
          this.logout();
          this.alertService.info('ALERT.TOKEN_EXPIRED');
        }
      }
    }));
  }

  private logout() {
    const tenantName = this.appService.getUser().tenantName;
    this.appService.removeTokenFromStorage();
    this.appService.logout();
    this.router.navigateByUrl(`/login?tenantName=${tenantName}`);
  }
}
