<div class="modal-header">
  <h4 class="modal-title">{{'MODAL_CREATE_SESSION.CREATE_SESSION' | translate}}</h4>
  <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form (ngSubmit)="createSession()" [formGroup]="form">
  <div class="modal-body">
    <div class="form-group">
      <label for="validationCodeInput">{{'MODAL_CREATE_SESSION.TITLE' | translate}}</label>
      <input #sessionName
             (keyup)="checkLength()"
             aria-describedby="sessionNameHelp"
             class="form-control"
             formControlName="sessionName"
             id="sessionNameInput"
             placeholder="{{'MODAL_CREATE_SESSION.INPUT' | translate}}"
             type="text">
      <div *ngIf="!sessionName.valid">
        <small
          *ngIf="(sessionName.dirty || sessionName.touched) && sessionName.hasError('required')"
          class="text-danger form-text">
          {{'MODAL_CREATE_SESSION.INVALID_REQUIRED' | translate}}
        </small>
        <small
          *ngIf="showLengthError"
          class="text-danger form-text">
          {{'MODAL_CREATE_SESSION.INVALID_MAX_LENGTH' | translate}}
        </small>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button id="id-create-session-button" [disabled]="submitted || form.invalid" class="btn btn-primary"
            type="submit">{{'MODAL_CREATE_SESSION.SUBMIT' | translate}}</button>
    <button (click)="activeModal.dismiss()" class="btn btn-outline-secondary"
            type="button">{{'MODAL_CREATE_SESSION.CANCEL' | translate}}</button>
  </div>
</form>
