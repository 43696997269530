import { Pipe, PipeTransform } from '@angular/core';
import findIndex from 'lodash/findIndex';

@Pipe({
  name: 'orderLanguage'
})
export class OrderLanguagePipe implements PipeTransform {

  transform(langueList: any[], ...args: any[]): any {

    if (!args[0]) {
      return langueList;
    }

    const indexFR = findIndex(langueList, langue => langue.value === 'FR');
    if (indexFR === -1) {
      return langueList;
    }
    const french = langueList[indexFR];
    langueList = [french, ...langueList];
    langueList.splice((indexFR + 1), 1);

    const indexEN = findIndex(langueList, langue => langue.value === 'EN');
    if (indexEN === -1) {
      return langueList;
    }
    const english = langueList[indexFR];
    langueList = [english, ...langueList];
    langueList.splice((indexEN + 1), 1);

    const emptyIndex = findIndex(langueList, langue => langue.value === 'none');
    if (emptyIndex === -1) {
      return langueList;
    }

    const empty = langueList[emptyIndex];
    langueList = [empty, ...langueList];
    langueList.splice((emptyIndex + 1), 1);

    return langueList;
  }

}
