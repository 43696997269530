/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Circle creation information
 */
export interface CreateCirclePayload { 
    /**
     * Name of the organisation of the circle
     */
    businessName?: string;
    /**
     * Whether circle is stored in directory, default value depends on the configuration
     */
    circleReentrant?: boolean;
    /**
     * Alias of the directory where the circle information is stored
     */
    directoryAlias?: string;
    /**
     * Email of the circle
     */
    email?: string;
    /**
     * Locale code
     */
    localeCode?: CreateCirclePayload.LocaleCodeEnum;
    /**
     * Name of the circle
     */
    name?: string;
}
export namespace CreateCirclePayload {
    export type LocaleCodeEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LocaleCodeEnum = {
        EN: 'EN' as LocaleCodeEnum,
        FR: 'FR' as LocaleCodeEnum,
        DE: 'DE' as LocaleCodeEnum,
        PT: 'PT' as LocaleCodeEnum,
        IT: 'IT' as LocaleCodeEnum,
        NL: 'NL' as LocaleCodeEnum,
        SP: 'SP' as LocaleCodeEnum
    };
}
