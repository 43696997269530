/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateSessionEnduserPayload } from '../model/createSessionEnduserPayload';
import { SessionEnduserData } from '../model/sessionEnduserData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SessionEnduserService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Link manager and session
     * 
     * @param sessionId Session
     * @param payload Session enduser to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSessionManager(sessionId: number, payload?: CreateSessionEnduserPayload, observe?: 'body', reportProgress?: boolean): Observable<SessionEnduserData>;
    public createSessionManager(sessionId: number, payload?: CreateSessionEnduserPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionEnduserData>>;
    public createSessionManager(sessionId: number, payload?: CreateSessionEnduserPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionEnduserData>>;
    public createSessionManager(sessionId: number, payload?: CreateSessionEnduserPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createSessionManager.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SessionEnduserData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/endusers`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlink manager and session
     * 
     * @param enduserId Enduser
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSessionManager(enduserId: string, sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSessionManager(enduserId: string, sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSessionManager(enduserId: string, sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSessionManager(enduserId: string, sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling deleteSessionManager.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteSessionManager.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/endusers/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlink managers and session
     * 
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSessionManagers(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSessionManagers(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSessionManagers(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSessionManagers(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteSessionManagers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/endusers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get manager of session
     * 
     * @param enduserId Enduser
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSessionManager(enduserId: string, sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<SessionEnduserData>;
    public getSessionManager(enduserId: string, sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionEnduserData>>;
    public getSessionManager(enduserId: string, sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionEnduserData>>;
    public getSessionManager(enduserId: string, sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling getSessionManager.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getSessionManager.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SessionEnduserData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/endusers/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of session managers
     * 
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSessionManagerList(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SessionEnduserData>>;
    public getSessionManagerList(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SessionEnduserData>>>;
    public getSessionManagerList(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SessionEnduserData>>>;
    public getSessionManagerList(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getSessionManagerList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SessionEnduserData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/endusers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
