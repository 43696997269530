import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {PipesModule} from '../../pipes/pipes.module';
import {AutoFocusDirective} from './auto-focus.directive';
import { AutocompleteCustomComponent } from './autocomplete/autocomplete-custom/autocomplete-custom.component';
import {AutocompleteComponent} from './autocomplete/autocomplete.component';
import {InputFileComponent} from './input-file/input-file.component';
import {InputTextComponent} from './input-text/input-text.component';
import {SwitcherComponent} from './switcher/switcher.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgbTooltipModule, MatInputModule, MatFormFieldModule, MatAutocompleteModule, PipesModule, TranslateModule],
  declarations: [InputTextComponent, InputFileComponent, AutocompleteComponent, SwitcherComponent, AutoFocusDirective, AutocompleteCustomComponent],
  exports: [InputTextComponent, InputFileComponent, AutocompleteComponent, SwitcherComponent, AutoFocusDirective, AutocompleteCustomComponent]
})
export class FormModule {
}
