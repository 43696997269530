import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import {NoTenantNameComponent} from './error/no-tenant-name/no-tenant-name.component';
import { InviteComponent } from './login/invite/invite.component';
import { LoginComponent } from './login/login/login.component';
import { LogoutComponent } from './login/logout/logout.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { SignupComponent } from './login/signup/signup.component';
import { NewSessionComponent } from './new-session/new-session.component';
import { AuthGuard } from './services/guard/auth.guard';
import {InviteGuard} from './services/guard/invite.guard';
import { NotAuthGuard } from './services/guard/not-auth.guard';
import { ParapheurGuard } from './services/guard/parapheur.guard';
import { OrderComponent } from './shared/components/order/order.component';
import { UserProfileComponent } from './shared/components/user-profile/user-profile.component';
import { SignatureBookComponent } from './signature-book/container/signature-book/signature-book.component';
import { ValidationComponent } from './core/components/validation/validation.component';

const appRoutes: Routes = [{
  path: 'login', component: LoginComponent, canActivate: [NotAuthGuard], pathMatch: 'full'
}, {
  path: 'logout', component: LogoutComponent, canActivate: [AuthGuard]
}, {
  path: 'no-tenant-name', component: NoTenantNameComponent, pathMatch: 'full'
}, {
  path: 'login/invite', component: InviteComponent, canActivate: [InviteGuard]
}, {
  path: 'signup', component: SignupComponent, canActivate: [NotAuthGuard]
}, {
  path: 'signup/:tenantName', component: SignupComponent, canActivate: [NotAuthGuard]
}, {
  path: 'itsme', loadChildren: './core/core.module#CoreModule'
}, {
  path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]
}, {
  path: 'signature-book', component: SignatureBookComponent, canActivate: [AuthGuard, ParapheurGuard]
}, {
  path: 'new-session', component: NewSessionComponent, canActivate: [AuthGuard]
}, {
  path: 'session/:sessionId', loadChildren: './session/session.module#SessionModule', canActivate: [AuthGuard]
}, {
  path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard]
}, {
  path: 'order', component: OrderComponent, canActivate: [AuthGuard]
}, {
  path: 'reset', component: ResetPasswordComponent, canActivate: [AuthGuard]
}, {
  path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard]
}, {
  path: 'validation', component: ValidationComponent, canActivate: [AuthGuard]
},{
  path: '**', redirectTo: 'dashboard', pathMatch: 'full'
}];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'})], exports: [RouterModule]
})
export class AppRoutingModule {
}
