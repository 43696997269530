/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Addressee { 
    address?: string;
    enduserId?: number;
    fetch?: boolean;
    local?: Addressee.LocalEnum;
    mail?: boolean;
    role?: string;
    sms?: boolean;
    stepId?: number;
}
export namespace Addressee {
    export type LocalEnum = 'EN' | 'FR' | 'DE' | 'PT' | 'IT' | 'NL' | 'SP';
    export const LocalEnum = {
        EN: 'EN' as LocalEnum,
        FR: 'FR' as LocalEnum,
        DE: 'DE' as LocalEnum,
        PT: 'PT' as LocalEnum,
        IT: 'IT' as LocalEnum,
        NL: 'NL' as LocalEnum,
        SP: 'SP' as LocalEnum
    };
}
