/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ValidationResponse { 
    message?: string;
    status?: ValidationResponse.StatusEnum;
}
export namespace ValidationResponse {
    export type StatusEnum = 'SUCCESS' | 'ERROR';
    export const StatusEnum = {
        SUCCESS: 'SUCCESS' as StatusEnum,
        ERROR: 'ERROR' as StatusEnum
    };
}
