/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateAcroformsPayload } from '../model/createAcroformsPayload';
import { SignaturePlaceholderData } from '../model/signaturePlaceholderData';
import { SignaturePlaceholderPayload } from '../model/signaturePlaceholderPayload';
import { UpdateSignaturePlaceholderPayload } from '../model/updateSignaturePlaceholderPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PlaceholderService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create acroform before signing
     * 
     * @param documentId Document
     * @param sessionId Related signature session
     * @param stepId Related step
     * @param payload Visual signature location
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAcroformSign(documentId: number, sessionId: number, stepId: number, payload?: CreateAcroformsPayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createAcroformSign(documentId: number, sessionId: number, stepId: number, payload?: CreateAcroformsPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createAcroformSign(documentId: number, sessionId: number, stepId: number, payload?: CreateAcroformsPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createAcroformSign(documentId: number, sessionId: number, stepId: number, payload?: CreateAcroformsPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling createAcroformSign.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createAcroformSign.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling createAcroformSign.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/placeholders/endusers/current/acroforms`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create acroforms at pre-defined location into the PDF
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the comment belongs
     * @param payload Visual signature location
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAcroforms(documentId: number, sessionId: number, stepId: number, payload?: CreateAcroformsPayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createAcroforms(documentId: number, sessionId: number, stepId: number, payload?: CreateAcroformsPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createAcroforms(documentId: number, sessionId: number, stepId: number, payload?: CreateAcroformsPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createAcroforms(documentId: number, sessionId: number, stepId: number, payload?: CreateAcroformsPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling createAcroforms.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createAcroforms.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling createAcroforms.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/placeholders/acroforms`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create signature placeholder
     * 
     * @param documentId Document to which the placeholder belongs
     * @param sessionId Session in which the placeholder is defined
     * @param stepId Step in which the placeholder is defined
     * @param payload Placeholder to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, payload?: SignaturePlaceholderPayload, observe?: 'body', reportProgress?: boolean): Observable<SignaturePlaceholderData>;
    public createSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, payload?: SignaturePlaceholderPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignaturePlaceholderData>>;
    public createSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, payload?: SignaturePlaceholderPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignaturePlaceholderData>>;
    public createSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, payload?: SignaturePlaceholderPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling createSignaturePlaceholder.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createSignaturePlaceholder.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling createSignaturePlaceholder.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SignaturePlaceholderData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/placeholders`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete acroforms
     * 
     * @param documentId Document
     * @param sessionId Session to which the document belongs
     * @param stepId Step to which the comment belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAcroforms(documentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAcroforms(documentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAcroforms(documentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAcroforms(documentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling deleteAcroforms.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteAcroforms.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling deleteAcroforms.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/placeholders/acroforms`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete signature placeholder
     * 
     * @param documentId Document to which the placeholder belongs
     * @param sessionId Session in which the placeholder is defined
     * @param stepId Step in which the placeholder is defined
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling deleteSignaturePlaceholder.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteSignaturePlaceholder.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling deleteSignaturePlaceholder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/placeholders`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get placeholders for an enduser
     * 
     * @param documentId Document to which the placeholder belongs
     * @param enduserId Enduser to which the placeholder belongs
     * @param sessionId Session in which the placeholder is defined
     * @param stepId Step in which the placeholder is defined
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSignaturePlaceholderEnduserList(documentId: number, enduserId: string, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SignaturePlaceholderData>>;
    public getSignaturePlaceholderEnduserList(documentId: number, enduserId: string, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SignaturePlaceholderData>>>;
    public getSignaturePlaceholderEnduserList(documentId: number, enduserId: string, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SignaturePlaceholderData>>>;
    public getSignaturePlaceholderEnduserList(documentId: number, enduserId: string, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getSignaturePlaceholderEnduserList.');
        }

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling getSignaturePlaceholderEnduserList.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getSignaturePlaceholderEnduserList.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getSignaturePlaceholderEnduserList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SignaturePlaceholderData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/placeholders/endusers/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get signature placeholders for a document
     * 
     * @param documentId Document to which the placeholder belongs
     * @param sessionId Session in which the placeholder is defined
     * @param stepId Step in which the placeholder is defined
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSignaturePlaceholderList(documentId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SignaturePlaceholderData>>;
    public getSignaturePlaceholderList(documentId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SignaturePlaceholderData>>>;
    public getSignaturePlaceholderList(documentId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SignaturePlaceholderData>>>;
    public getSignaturePlaceholderList(documentId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getSignaturePlaceholderList.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getSignaturePlaceholderList.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getSignaturePlaceholderList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SignaturePlaceholderData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/placeholders`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update signature placeholder
     * 
     * @param documentId Document to which the placeholder belongs
     * @param sessionId Session to which the comment belongs
     * @param stepId Step to which the comment belongs
     * @param payload Placeholder to update (null ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, payload?: UpdateSignaturePlaceholderPayload, observe?: 'body', reportProgress?: boolean): Observable<SignaturePlaceholderData>;
    public updateSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, payload?: UpdateSignaturePlaceholderPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignaturePlaceholderData>>;
    public updateSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, payload?: UpdateSignaturePlaceholderPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignaturePlaceholderData>>;
    public updateSignaturePlaceholder(documentId: number, sessionId: number, stepId: number, payload?: UpdateSignaturePlaceholderPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling updateSignaturePlaceholder.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateSignaturePlaceholder.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateSignaturePlaceholder.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SignaturePlaceholderData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/placeholders`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
