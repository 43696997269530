<div class="modal-header">
  <h4 class="modal-title">
    <input #subjectInput 
            (input)="onSubjectInput(subjectInput.value)"
            [value]="patternData?.subject"
            placeholder="{{'MODAL_TEMPLATE_BUILDER.TITLE' | translate}}"
            class="modal-title-input" type="text"/>
  </h4>
  <button (click)="onCloseButton()" class="close" *ngIf="displayCloseButton">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div [ngClass]="{'vertical': verticalMode}" class="code-content">
    <div class="buttons">
      <button (click)="verticalMode = !verticalMode" class="to-vertical">
        <i *ngIf="verticalMode" class="fas fa-arrows-alt-h"></i>
        <i *ngIf="!verticalMode" class="fas fa-arrows-alt-v"></i>
      </button>
      <button (click)="darkMode = !darkMode" class="darkmode">
        <i *ngIf="darkMode" class="far fa-moon"></i>
        <i *ngIf="!darkMode" class="fas fa-moon"></i>
      </button>
    </div>
    <div class="code-output">
      <app-template-editor-output-panel *ngIf="patternData?.body" [patternData]="patternData"></app-template-editor-output-panel>
      <span *ngIf="!patternData?.body" class="code-output-empty">
        <ng-container *ngIf="verticalMode; else horizontalMode">
          {{'MODAL_TEMPLATE_BUILDER.OUTPUT_PLACEHOLDER_VERTICAL' | translate}}
        </ng-container>
        <ng-template #horizontalMode>
          {{'MODAL_TEMPLATE_BUILDER.OUTPUT_PLACEHOLDER_HORIZONTAL' | translate}}
        </ng-template>
      </span>
    </div>
    <div [ngClass]="{'darkMode': darkMode}" class="code-input">
      <textarea #bodyInput
                (input)="onBodyInput(bodyInput.value)"
                [value]="patternData?.body"
                placeholder="{{'MODAL_TEMPLATE_BUILDER.CODE_PLACEHOLDER' | translate}}">
      </textarea>
    </div>
  </div>
</div>
