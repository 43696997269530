<button *ngIf="delegationEnable"
  [disabled]="delegated"
  (click)="!delegated ? openDelegationModal() : undefined"
  [ngClass]="delegated ? 'btn-outline-success' : 'btn-primary'"
        [disableTooltip]="textButton"
        [ngbTooltip]= "delegated ? ('STEP.DELEGATED' | translate) : 'STEP.DELEGATE' | translate"
  class="ml-2 mb-1 mt-1 btn"
>
  <i class="fas fa-people-arrows"></i>
  <span *ngIf="textButton"> {{delegated ? ('STEP.DELEGATED' | translate) : 'STEP.DELEGATE' | translate }} </span>
</button>

