<app-tooltip [tooltipText]="'HELP.WIZARD_SIGNERS' | translate"></app-tooltip>

<app-wizard-step>
  <div class="fixed-container" content>
    <app-endusers-document-table
      [documents]="documents"
      [noResultFound]="'WIZARD.SIGNERS.NO_RESULT_FOUND' | translate"
      [sessionId]="sessionId"
      [sprofileList]="sprofileList"
      [stepEndUsers]="stepEndUsers"
      [stepId]="stepId"
      [title]="'WIZARD.SIGNERS.TITLE' | translate">
    </app-endusers-document-table>
  </div>

  <div *ngIf="documents.length && sprofileKeys.length" class="fixed-container" content>
    <table class="custom-table table-signature">
      <tr class="table-header">
        <th>{{'WIZARD.SIGNERS.SPROFILE_KEY' | translate}}</th>
        <th>{{'WIZARD.SIGNERS.SPROFILE_VAL' | translate}}</th>
      </tr>
      <tr *ngFor="let key of sprofileKeys" class="table-row">
        <td class="table-row-i1">
          {{key}}
        </td>
        <td class="table-row-i2 form-group">
          <div class="input-group">
            <input (change)="updateSprofileKeyVal(key)" *ngIf="!sprofileKeyValues[key]" [disabled]="updatingSprofile"
                   [(ngModel)]="sprofileValues[key]"
                   class="form-control" type="text">

            <select (change)="updateSprofileKeyVal(key)" *ngIf="sprofileKeyValues[key]" [disabled]="updatingSprofile"
                    [(ngModel)]="sprofileValues[key]" class="form-control">
              <option *ngFor="let o of sprofileKeyValues[key]"
                      [selected]="sprofileValues[key]===o">{{o}}</option>
            </select>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="bottom-nav-btns mt-3">
    <app-wizard-step-button [action]="stepActions.PREVIOUS"
                            [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>
    <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                            class="float-right"></app-wizard-step-button>
  </div>
</app-wizard-step>
