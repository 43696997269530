import {Component, HostListener} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../../environments/environment';

@Component({
  templateUrl: './modal-orely-login.component.html'
})
export class ModalOrelyLoginComponent {

  orelyAuthUrl: SafeResourceUrl;
  iFrameVisible = true;

  constructor(public activeModal: NgbActiveModal, private domSanitizer: DomSanitizer) {
    this.orelyAuthUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.ORELY_URL + '/auth/login');
  }

  @HostListener('window:message', ['$event']) iFrameEventListener(event: MessageEvent) {
    const data = event.data;
    if (data) {
      if (data.flowStatus === 'USER_CLICKED_CANCELLED') {
        this.activeModal.close();
      } else {
        if (data && data.ssn) {
          this.activeModal.close(data.ssn);
        } else {
          this.activeModal.dismiss();
        }
      }
    }
  }
}
