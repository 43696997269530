/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../core/pipes/translate.pipe";
import * as i4 from "../../../core/service/translate.service";
import * as i5 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\"", "\""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.varModal; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "float-left pb-3 w-100 break-words"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["?"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.varModal; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageModal; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "custom-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 27, "div", [["class", "custom-modal-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "padding": 0 }), (_l()(), i1.ɵeld(5, 0, [["header", 1]], null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "customDesign pb-5": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(15, 0, null, null, 13, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(17, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "button", [["class", "btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(20, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(21, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService]), (_l()(), i1.ɵeld(23, 0, null, null, 5, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(25, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(26, { "mr-1": 0 }), (_l()(), i1.ɵted(27, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "custom-modal-content"; var currVal_1 = _ck(_v, 4, 0, (_co.design === "basic")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = "modal-body"; var currVal_4 = _ck(_v, 11, 0, (_co.design === "custom")); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = (_co.messageModal.length > 0); _ck(_v, 13, 0, currVal_5); var currVal_6 = ((_co.design === "custom") ? "footerCustom" : "modal-footer"); _ck(_v, 17, 0, currVal_6); var currVal_7 = "btn"; var currVal_8 = ((_co.design === "custom") ? "btn-light mr-1 paddingRight" : "btn-danger mr-5"); _ck(_v, 20, 0, currVal_7, currVal_8); var currVal_11 = "btn btn-primary"; var currVal_12 = _ck(_v, 26, 0, (_co.design === "custom")); _ck(_v, 25, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.titleModal; _ck(_v, 7, 0, currVal_2); var currVal_9 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform(((_co.design === "custom") ? "DOCUMENT.ANNULATION" : "CIRCLE.NO"))); _ck(_v, 21, 0, currVal_9); var currVal_10 = _co.disabled; _ck(_v, 23, 0, currVal_10); var currVal_13 = i1.ɵunv(_v, 27, 0, i1.ɵnov(_v, 28).transform(((_co.design === "custom") ? "DOCUMENT.CONFIRM" : "CIRCLE.YES"))); _ck(_v, 27, 0, currVal_13); }); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opened; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.ModalComponent, [], null, null)], null, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i5.ModalComponent, View_ModalComponent_Host_0, { opened: "opened", titleModal: "titleModal", design: "design", messageModal: "messageModal", disabled: "disabled", varModal: "varModal" }, { delete: "delete", closeModal: "closeModal" }, ["*"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
