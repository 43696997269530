/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Signature profile
 */
export interface SprofileData { 
    /**
     * Indicates if the profile is applied automatically when step starts
     */
    auto?: boolean;
    /**
     * Code of signature profile
     */
    code?: string;
    /**
     * Cost in credits related to the signature profile
     */
    cost?: number;
    /**
     * If 'detached', apply to a new document
     */
    detached?: boolean;
    /**
     * Format of the signature profile
     */
    format?: SprofileData.FormatEnum;
    /**
     * Sprofile label (in the enduser language)
     */
    label?: string;
    /**
     * Applies to multiple documents in one go
     */
    multi?: boolean;
    /**
     * Whether acroform is added for such signatures
     */
    needsAcroform?: boolean;
    /**
     * Class-for-name of the plugin that would handle the signature
     */
    plugin?: string;
    /**
     * The list of tag aliases to give to this profile
     */
    tagAliases?: Array<string>;
    tenantId?: number;
}
export namespace SprofileData {
    export type FormatEnum = 'PADES' | 'XADES' | 'XADES_MANIFEST' | 'OTHER';
    export const FormatEnum = {
        PADES: 'PADES' as FormatEnum,
        XADES: 'XADES' as FormatEnum,
        XADESMANIFEST: 'XADES_MANIFEST' as FormatEnum,
        OTHER: 'OTHER' as FormatEnum
    };
}
