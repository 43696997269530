<div class="modal-header">
  <div class="modal-title">{{title| translate}}</div>
  <button (click)="activeModal.close()"
          aria-label="Close"
          class="close"
          type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div class="row">
      <app-input-text [formControl]="comment"
                      (keyup.enter)="handleAction()"
                      autoFocus
                      class="col"
                      icon="edit"
                      type="text">
      </app-input-text>
  </div>
</div>

<div class="modal-footer">
  <button (click)="activeModal.close()" class="btn btn-light mr-1"
          type="button"> {{close | translate}} </button>
  <button
    (click)="handleAction(); activeModal.close()"
    class="btn btn-primary mr-1"
    type="button"
  >{{action | translate}}
  </button>
</div>