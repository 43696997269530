import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AppService} from '../../../services/services/app.service';

export enum Status {
  LOAD = 'LOAD', SUCCESS = 'SUCCESS', ERROR = 'ERROR'
}

@Component({
  selector: 'app-input-loader', templateUrl: './input-loader.component.html', styleUrls: ['./input-loader.component.scss']
})
export class InputLoaderComponent implements OnChanges {

  STATUS = Status;
  @Input() status: Status = Status.LOAD;
  localStatus: Status;
  loaded: boolean;
  futureStatus = null;
  loadTimeOut;

  constructor(public appService: AppService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.status.currentValue === Status.LOAD) && this.appService.config && this.appService.config.showSpinner) {
      clearTimeout(this.loadTimeOut);
      this.localStatus = Status.LOAD;
      this.loaded = false;
      this.loadTimeOut = setTimeout(() => {
        this.loaded = true;
        if (this.futureStatus && this.futureStatus !== Status.LOAD) {
          this.localStatus = this.futureStatus;
          setTimeout(() => {
            this.localStatus = null;
            this.futureStatus = null;
          }, 2000);
        }
      }, 1000);
    } else if (!this.loaded) {
      this.futureStatus = changes.status.currentValue;
    } else if (this.loaded) {
      this.localStatus = changes.status.currentValue;
      setTimeout(() => {
        this.localStatus = null;
        this.futureStatus = null;
      }, 2000);
    }
  }
}
