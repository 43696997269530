import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {SignatureBookData} from 'luxtrust-cosi-api/model/signatureBookData';
import {SignatureBookSignerData} from 'luxtrust-cosi-api/model/signatureBookSignerData';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {SignatureBookState} from '../../services/signature-book-store.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'document-list', templateUrl: './document-list.component.html', styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit, OnDestroy {

  constructor(private router: Router, private route: ActivatedRoute) {}
  @Input() currentUserId: number;

  private blueGreenArray: boolean[];
  @Input() set signatureBook(i){
    this._signatureBook = i;
    this.blueGreenArray = [];
    if(this._signatureBook.items.length) {
      this.blueGreenArray.push(false);
      let lastSessionId = this._signatureBook.items[0].sessionId;
      for (let id = 1; id < this._signatureBook.items.length; id++) {
        const thisSessionId = this._signatureBook.items[id].sessionId;
        if (lastSessionId === thisSessionId) {
          this.blueGreenArray.push(this.blueGreenArray[this.blueGreenArray.length-1]);
        }
        else{
          this.blueGreenArray.push(!this.blueGreenArray[this.blueGreenArray.length-1]);
        }
        lastSessionId = thisSessionId;
      }
    }
  }

  _signatureBook: SignatureBookState;
  @Input() stepid;
  @Input() sessionId;
  public listOfDocumentIsFilter = false;
  @Input() selectedSignatureBook: SignatureBookData;
  @Output() documentSelected: EventEmitter<DocumentData> = new EventEmitter();
  @Output() moveToCommentSection: EventEmitter<DocumentData> = new EventEmitter();
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  @Output() filterStatusDocument: EventEmitter<boolean> = new EventEmitter();
  @Output() onSort: EventEmitter<boolean> = new EventEmitter();
  @Output() onScroll: EventEmitter<any> = new EventEmitter<any>();
  keyUp: Subject<string> = new Subject();
  sortAsc_=false;

  get sortAsc(){
    return this.sortAsc_;
  }

  set sortAsc(value){
    this.sortAsc_ = value;
    this.onSort.emit(this.sortAsc_);
  }

  throttle = 300;
  scrollDistance = 2;

  @ViewChild('filterByStatus', {static: true}) filterByStatus: ElementRef;
  ngOnInit() {
    if (sessionStorage.getItem('filterDocumentByStatus') === 'true') {
      this.filterByStatus.nativeElement.checked = true;
      this.listOfDocumentIsFilter = true;
    }
    this.filterStatusDocument.emit(this.listOfDocumentIsFilter);
    this.registerSearchDebounce();
  }

  ngOnDestroy() {
    this.keyUp.complete();
  }

  registerSearchDebounce() {
    this.keyUp.pipe(debounceTime(300)).subscribe(text => this.handleOnSearch(text));
  }

  handleOnSearch(text) {
    this.onSearch.emit(text);
  }

  isSelected(signatureBook: SignatureBookData): boolean {
    return this.selectedSignatureBook.document && signatureBook.document.id === this.selectedSignatureBook.document.id;
  }

  currentSignerDataInSignaturBookData(signatureBookData: SignatureBookData): SignatureBookSignerData {
    return signatureBookData.signatureBookSignerList.find(signerData => {
      return signerData.stepEnduser.enduser.id === this.currentUserId;
    });
  }
  filterDocumentByStatus() {
    this.listOfDocumentIsFilter = !this.listOfDocumentIsFilter;
    this.filterStatusDocument.emit(this.listOfDocumentIsFilter);
    sessionStorage.setItem('filterDocumentByStatus', this.listOfDocumentIsFilter.toString());
  }

  onScrollDown(event) {
    this.onScroll.emit(event);
  }
}
