import { IEService } from 'src/app/services/services/ie.service';
import {Component} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {PatternService} from 'luxtrust-cosi-api/api/pattern.service';
import {CreatePatternPayload} from 'luxtrust-cosi-api/model/createPatternPayload';
import {PatternData} from 'luxtrust-cosi-api/model/patternData';
import {ApiError} from 'src/app/error/api-error.model';
import {AlertService} from 'src/app/services/services/alert-service';
import {CloneUtils} from 'src/app/services/utils/clone.utils';
import {WizardStepNotificationsTag} from 'src/app/session/step/components/wizard-step-notifications/wizard-step-notifications-tag.enum';
import {ModalYesNoComponent} from 'src/app/shared/components/modal-yes-no/modal-yes-no.component';
import LanguageEnum = CreatePatternPayload.LanguageEnum;
import TagEnum = WizardStepNotificationsTag.TagEnum;

@Component({
  templateUrl: './notification-templates-editor.component.html',
  styleUrls: ['./notification-templates-editor.component.scss']
})
export class NotificationTemplatesEditorComponent {

  readonly TAG_VALUES: { key: string, value: TagEnum }[];

  patterns: PatternData[];
  patternsCheck: PatternData[];
  templateChannel: PatternData.ChannelEnum;
  templateEventTypeId: number;
  stepId: number;
  patternRoleId: number;
  removingPattern = false;
  savingPattern = false;
  creatingPattern = false;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly patternService: PatternService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly modal: NgbModal,
    private readonly ieService: IEService
  ) {
    if (this.ieService.checkIfIE) {
      this.ieService.adaptEntriesIE();
    }
    this.TAG_VALUES = Object.entries(WizardStepNotificationsTag.TagEnum).map(([key, value]) => {
      return {key: this.translateService.instant('WIZARD.NOTIFICATIONS.ITEM.' + key), value};
    });
  }

  setData(stepId: number, patterns: PatternData[], eventTypeId: number, channel: PatternData.ChannelEnum, patternRoleId: number) {
    this.stepId = stepId;
    this.patterns = patterns;
    this.patternsCheck = CloneUtils.cloneArray(patterns);
    this.templateEventTypeId = eventTypeId;
    this.templateChannel = channel;
    this.patternRoleId = patternRoleId;
  }

  getPatternByTagValue(tagValue: string, patterns: PatternData[]): PatternData {
    if (tagValue) {
      return patterns.find(pattern => pattern.tagValue === tagValue);
    } else {
      return patterns.find(pattern => pattern.tagValue === null || pattern.tagValue === '');
    }
  }

  findBestSuitedTemplateForTagValue(tagValue: string) {
    this.patternService.getBestSuitedNotificationPattern({
      channel: this.templateChannel,
      language: LanguageEnum[this.translateService.currentLang.toUpperCase()],
      eventTypeId: this.templateEventTypeId,
      tagId: this.patternRoleId,
      tagValue
    }).toPromise().then(pattern => {
      this.creatingPattern = true;
      return this.patternService.createSpecificNotificationPattern({
        body: pattern && pattern.body || '',
        subject: pattern && pattern.subject || '',
        channel: this.templateChannel,
        eventTypeId: this.templateEventTypeId,
        language: LanguageEnum[this.translateService.currentLang.toUpperCase()],
        stepId: this.stepId,
        tagId: (!tagValue ? undefined : this.patternRoleId),
        tagValue
      }).toPromise().then(newPattern => {
        this.alertService.success('MODAL_TEMPLATE_BUILDER.SUCCESS');
        this.patterns.push(newPattern);
        this.patternsCheck.push(CloneUtils.cloneObject(newPattern));
      }).then(value => this.creatingPattern = false);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  onSave(tagValue: string): void {
    const patternToSave = this.getPatternByTagValue(tagValue, this.patterns);
    if (patternToSave) {
      this.savingPattern = true;
      this.patternService.updateSpecificNotificationPattern(patternToSave.id, {
        subject: patternToSave.subject, body: patternToSave.body, tagValue: patternToSave.tagValue
      }).toPromise().then((updatedPattern) => {
        this.patternsCheck.splice(this.patternsCheck.findIndex(p => p.id === patternToSave.id), 1, CloneUtils.cloneObject(updatedPattern));
        this.alertService.success('MODAL_TEMPLATE_BUILDER.SUCCESS');
      }).catch((error: ApiError) => {
        this.alertService.errorApiWithCustomMessage('MODAL_TEMPLATE_BUILDER.ERROR', error);
      }).then(value => this.savingPattern = false);
    }
  }

  onClose() {
    const hasChanges = this.TAG_VALUES.some((tag) => (this.checkIfChanges(tag.value.toString())));
    if (hasChanges) {
      const modalMutual = this.modal.open(ModalYesNoComponent, {
        backdrop: false, centered: true
      });
      modalMutual.componentInstance.message = 'WIZARD.NOTIFICATIONS.TEMPLATE.DISCARD_CHANGES';
      modalMutual.componentInstance.yesIsDanger = true;
      modalMutual.result.then(value => {
        if (value === 'yes') {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  onDelete(tagValue: string) {
    const modalMutual = this.modal.open(ModalYesNoComponent, {
      backdrop: false, centered: true
    });
    modalMutual.componentInstance.message = 'WIZARD.NOTIFICATIONS.TEMPLATE.CONFIRM_DELETE';
    modalMutual.componentInstance.yesIsDanger = true;
    modalMutual.result.then(value => {
      if (value === 'yes') {
        this.removePattern(tagValue);
      }
    });
  }

  private removePattern(tagValue: string) {
    const patternToRemove: PatternData = this.getPatternByTagValue(tagValue, this.patterns);
    this.removingPattern = true;
    this.patternService.deleteSpecificNotificationPattern(patternToRemove.id).toPromise().then(() => {
      this.patterns.splice(this.patterns.findIndex(p => p.id === patternToRemove.id), 1);
      this.patternsCheck.splice(this.patternsCheck.findIndex(p => p.id === patternToRemove.id), 1);
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    }).then(value => this.removingPattern = false);
  }

  canNotSave(tagValue: string): boolean {
    const patternToCheck = this.getPatternByTagValue(tagValue, this.patterns);
    if (patternToCheck && patternToCheck.body) {
      return patternToCheck.body.length === 0;
    }
    return true;
  }

  checkIfChanges(tagValue: string): boolean {
    const patternEdited = this.getPatternByTagValue(tagValue, this.patterns);
    const patternToCheck = this.getPatternByTagValue(tagValue, this.patternsCheck);
    if (patternEdited && patternToCheck) {
      if (patternEdited.body !== patternToCheck.body) {
        return true;
      }
      if (patternEdited.subject !== patternToCheck.subject) {
        return true;
      }
    }
    return false;
  }

}
