/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Information related to the document
 */
export interface DocumentData { 
    /**
     * Comment id
     */
    commentId?: number;
    /**
     * Document identifier
     */
    id?: number;
    /**
     * Document name
     */
    name?: string;
    /**
     * Original document id
     */
    originalDocumentId?: number;
    /**
     * Indicates if the document was revised, if zero no modified, if value contains part < 1000 only VISA applied (no modification), if part > 1000 document modified
     */
    revised?: number;
    /**
     * Document is public
     */
    shared?: boolean;
    /**
     * Source document id
     */
    sourceDocumentId?: number;
    /**
     * Signature profile code
     */
    sprofileCode?: string;
    /**
     * Step
     */
    stepId?: number;
    /**
     * Storage alias
     */
    storageAlias?: string;
    /**
     * Storage document id
     */
    storageDocumentId?: string;
    /**
     * Typology
     */
    typology?: DocumentData.TypologyEnum;
    /**
     * Document is based on MUTUAL in the same step and is used in at least one other step
     */
    used?: boolean;
}
export namespace DocumentData {
    export type TypologyEnum = 'ORIGINAL' | 'SIGNATURE' | 'MUTUAL' | 'TO_VIEW' | 'SPROFILE';
    export const TypologyEnum = {
        ORIGINAL: 'ORIGINAL' as TypologyEnum,
        SIGNATURE: 'SIGNATURE' as TypologyEnum,
        MUTUAL: 'MUTUAL' as TypologyEnum,
        TOVIEW: 'TO_VIEW' as TypologyEnum,
        SPROFILE: 'SPROFILE' as TypologyEnum
    };
}
