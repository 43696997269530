import * as i0 from "@angular/core";
export class SignDatesCheckService {
    isSignNotBefore(signNotBefore) {
        if (signNotBefore) {
            return this.getConvertedDateTime(signNotBefore) > this.getTodaysDate();
        }
        return false;
    }
    isSignNotAfter(signNotAfter) {
        if (signNotAfter) {
            return this.getConvertedDateTime(signNotAfter) < this.getTodaysDate();
        }
        return false;
    }
    getConvertedDateTime(date) {
        return new Date(date).getTime();
    }
    getTodaysDate() {
        return new Date().getTime();
    }
}
SignDatesCheckService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignDatesCheckService_Factory() { return new SignDatesCheckService(); }, token: SignDatesCheckService, providedIn: "root" });
