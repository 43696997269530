import {Component, ElementRef, Input, ViewChild} from '@angular/core';

import {FormElement} from '../form.element';

@Component({
  selector: 'app-input-file', templateUrl: './input-file.component.html', styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent extends FormElement<File[]> {

  @ViewChild('input', {static: true}) input: ElementRef;

  @Input() browseLabel: string;
  @Input() multiple = false;
  @Input() accept: string[];
  @Input() tabindex: number;
  @Input() leaveEmptyValue: boolean = false;

  onClick() {
    this.input.nativeElement.focus();
  }

  getFileListAsArray(fileList: FileList): File[] {
    return Object.keys(fileList).map(function (k) {
      return fileList[k];
    });
  }

  // We need to clear the input upon selection, otherwise, reselection of the same file
  // won't trigger the onChange handler (hence you cannot select/delete/reselect the same file)
  clearInput() {
    const i = this.input.nativeElement;
    i.value = '';
  }
}
