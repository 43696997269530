<div class="form-group">
  <label *ngIf="label"
         [for]="id">{{label}}</label>
  <div class="input-group">
    <input #input
           (blur)="onTouched(getFileListAsArray($event.target.files))"
           (change)="onChange(getFileListAsArray($event.target.files));clearInput()"
           [accept]="accept?.join(',')"
           [attr.tabindex]="tabindex"
           [id]="id"
           [multiple]="multiple ? 'multiple' : null"
           autocomplete="none"
           class="custom-file-input"
           type="file">
    <input
           [placeholder]="placeholder"
           class="form-control"
           tabindex="-1"
           type="text">
    <div class="input-group-append">
      <span class="input-group-text"><i *ngIf="icon"
                                        aria-hidden="true"
                                        class="fas fa-{{icon}}"></i>&nbsp;{{browseLabel}}</span>
    </div>
  </div>
  <div *ngIf="ngControl.touched && ngControl.invalid; else showHint"
       class="invalid-feedback">
    {{getFirstError()}}
  </div>
  <ng-template #showHint>
    <small *ngIf="hint"
           [id]="id+'Hint'"
           class="form-text text-muted">{{hint}}</small>
  </ng-template>
</div>
