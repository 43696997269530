import {EventEmitter, Output} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {PlaceholderService} from 'luxtrust-cosi-api/api/placeholder.service';
import {WatermarkService} from 'luxtrust-cosi-api/api/watermark.service';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {WatermarkData} from 'luxtrust-cosi-api/model/watermarkData';
import {forkJoin, of, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {IMAGES} from '../../../app.constant';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {ModalSignerComponent} from '../modal-signer/modal-signer.component';

export abstract class PdfPositionCapturerComponent {

  readonly largeCenteredNotClosableModal: NgbModalOptions = {
    centered: true, size: 'lg', keyboard: false, backdrop: false
  };

  signerNumber: number;
  assignedSigners: EnduserData[] = [];
  assignedCircles: EnduserData[] = [];
  signers: EnduserData[] = []; // come from: WorkflowComponent.getSigners | ...
  selectedSigner: EnduserData;
  filesAcroformsPositions;
  fileName;
  currentPage;
  totalPage;
  savingSignature = false;
  loadingSignature = false;
  disableSignature = true;
  protected digitalSignature: any;
  @Output() changePage = new EventEmitter<number>();

  // fixme: to be fixed by Intech, to be removed

  constructor(public appService: AppService,
              public modal: NgbModal,
              public watermarkService: WatermarkService,
              public placeholderService: PlaceholderService,
              public alertService: AlertService
  ) {}

  static dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    if (dataURL) {
      const parts = dataURL.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {type: contentType});
    }
  }

  abstract disableSignatureSetSlider(img: any);

  abstract disableSignatureResetSlider();

  shouldDisablePrevious() {
    return this.digitalSignature ? this.digitalSignature.getCurrentPage() === 1 : false;
  }

  shouldDisableNext() {
    return this.digitalSignature ? this.digitalSignature.getCurrentPage() === this.digitalSignature.getTotalPages() : false;
  }

  cancelSignature() {
    this.digitalSignature.cancelImage();
    this.disableSignatureResetSlider();
    this.loadingSignature = false;
  }

  resetSignature() {
    this.digitalSignature.cancelImage();
    this.digitalSignature.reset();
  }

  loadPreviousPage() {
    this.currentPage--;
    this.changePage.emit(this.currentPage);
    this.digitalSignature.loadPreviousPage();
  }

  loadNextPage() {
    this.currentPage++;
    this.changePage.emit(this.currentPage);
    this.digitalSignature.loadNextPage();
  }

  loadLastPage() {
    this.currentPage = this.digitalSignature.getTotalPages();
    this.changePage.emit(this.currentPage);
    this.digitalSignature.loadLastPage();
  }

  loadFirstPage() {
    this.currentPage = 1;
    this.changePage.emit(this.currentPage);
    this.digitalSignature.loadPage(1);
  }

  loadPage(page: number) {
    this.currentPage = page;
    this.changePage.emit(this.currentPage);
    this.digitalSignature.loadPage(page);
  }

  repositionSignature() {
    const keys = Object.keys(this.digitalSignature.history);
    for (let i = 0; i < keys.length; i++) {
      const subKeys = Object.keys(this.digitalSignature.history[keys[i]].images);
      for (let j = 0; j < subKeys.length; j++) {
        let matchingSigners = false;
        // Circles
        if (this.selectedSigner.circle) {
          if (this.selectedSigner['acroFormName'] === this.digitalSignature.history[keys[i]].images[subKeys[j]].signer['acroFormName']) {
            matchingSigners = true;
          }
        } else {
          if (this.selectedSigner.id && this.digitalSignature.history[keys[i]].images[subKeys[j]].signer.id) {
            if (this.selectedSigner.id === this.digitalSignature.history[keys[i]].images[subKeys[j]].signer.id) {
              matchingSigners = true;
            }
          } else if (this.selectedSigner.email === this.digitalSignature.history[keys[i]].images[subKeys[j]].signer.email) {
            matchingSigners = true;
          }
        }
        if (matchingSigners) {
          for (; j < subKeys.length - 1; j++) {
            this.digitalSignature.history[keys[i]].images[subKeys[j]] = this.digitalSignature.history[keys[i]].images[subKeys[j + 1]];
          }
          delete this.digitalSignature.history[keys[i]].images[subKeys[j]];
        }
      }
    }
  }

  saveSignature() {
    // Was the guy already assigned??
    // The method does not exist :(
    this.savingSignature = true;
    let unAssignedIndex;
    if (this.selectedSigner.circle) {
       unAssignedIndex = this.signers.findIndex(s => s['acroFormName'] === this.selectedSigner['acroFormName']);
    } else {
       unAssignedIndex = this.signers.findIndex(s => s.id === this.selectedSigner.id);
    }
    if (unAssignedIndex === -1) { // Si l'acroform est déjà posé
      this.repositionSignature();
      this.digitalSignature.acroforms[this.digitalSignature.currentPage] = [];
    } else { // Si l'acroform n'est pas encore posé
      this.signers.splice(unAssignedIndex, 1);
      if (this.selectedSigner.circle) {
        this.assignedCircles.push(this.selectedSigner);
      } else {
        this.assignedSigners.push(this.selectedSigner);
      }
    }

    this.digitalSignature.saveImage(this.selectedSigner);
    this.disableSignatureResetSlider();
    this.selectedSigner = undefined;
    this.savingSignature = false;
    this.loadingSignature = false;
  }

  loadSignatureImage() {
    this.loadingSignature = true;
    if (this.signerNumber === 1) {
      let signer = this.signers.length && this.signers[0] || this.assignedSigners[0] || this.assignedCircles[0];
      if (signer === undefined) { // fixme: to be fixed by Intech & to be removed
        signer = this.appService.currentEnduser;
        this.signers[0] = signer;
      }
      this.watermarkService.getWatermarkList(undefined, signer.id, WatermarkData.TypeEnum.USER)
        .pipe(take(1), map(
          (watermarks: WatermarkData[]) => watermarks.filter(watermark => watermark.targetId === signer.id && watermark.image !== undefined)),
          map((matchingWatermarks: WatermarkData[]) => matchingWatermarks.length === 0 ? undefined : matchingWatermarks.shift().image))
        .subscribe(
          (watermark: string) => this.loadSignatureFromWatermark(watermark, signer),
          (error: ApiError) => this.alertService.errorApi(error)
        );
    } else {
      const signerComponentRef = this.modal.open(ModalSignerComponent, this.largeCenteredNotClosableModal);
      signerComponentRef.componentInstance.assignedEndUsers = this.assignedSigners.concat(this.assignedCircles);
      signerComponentRef.componentInstance.endUsers = this.signers;
      signerComponentRef.result
        .then(signer => {
          if (signer) {
            this.loadSignatureFromWatermark(signer.watermark, signer.identity);
          }
        });
    }
  }

  loadSignatureFromWatermark(watermark: string, signer: EnduserData) {
    if (watermark) {
      this.loadSignature(IMAGES.BASE_64_PREFIX + watermark, undefined);
    } else {
      this.onDefaultSignatureChange(undefined);
    }
    this.selectedSigner = signer;
  }

  onDefaultSignatureChange($event) {
    this.loadSignature(
      IMAGES.BASE_64_PREFIX + IMAGES.DEFAULT_WATERMARK_IMAGE,
      $event
    );
  }

  digitalSignatureLoaded(a: any, filesAcroformsPositions?, fileName?, page?) {
    this.filesAcroformsPositions = filesAcroformsPositions;
    this.fileName = fileName;
    this.digitalSignature = a;
    this.digitalSignature.registerDeviceOrientationEvents(window);
    this.digitalSignature.disableAnnotation();
    this.currentPage = 1;
    this.loadOldSignatures().subscribe((result: { acrof: any, numberPage: number, watermark: any }[]) => {
      result.map(r => this.getWatermarkFromSigner(r.watermark, r.acrof, r.numberPage));
      if (!page) {
        this.loadPage(1);
      } else {
        this.loadPage(page);
    }
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  getWatermarkFromSigner(watermark, acrof, numberPage) {
    const newEnduser = JSON.parse(JSON.stringify(acrof.signer));
    if (newEnduser.circle) {
      newEnduser['acroFormName'] = acrof.name;
    }
    this.selectedSigner = newEnduser;
    const image = this.drawSignature(watermark);
    if (this.digitalSignature.history[numberPage] != undefined) {
      const length = Object.keys(this.digitalSignature.history[numberPage].images).length;
      this.digitalSignature.history[numberPage].images[length] = {
        image: image,
        offsetX: acrof.x + 100000,
        offsetY: acrof.y + 100000,
        acroformWidth: acrof.width,
        signer: newEnduser
      };
    }
  }

  private pad(n: number) {
    return String(n).padStart(6, ' ');
  }

  loadOldSignatures(): Observable<any> {
    const requests = [];
    if (this.filesAcroformsPositions) {
      this.filesAcroformsPositions.forEach((page, docName) => {
        if (docName === this.fileName) {
          page.forEach((acrofList, numberPage) => {
            this.totalPage = acrofList.length;
            acrofList.forEach((acrof, acrofIndex) => {
              requests.push(
                this.watermarkService.getWatermarkList(undefined, acrof.signerId, WatermarkData.TypeEnum.USER)
                  .pipe(
                    map(result => {
                      let watermarkData: WatermarkData;
                      if (result.length === 0) {
                        watermarkData = {
                          id: acrofIndex,
                          targetId: acrof.signerId,
                          image: IMAGES.DEFAULT_WATERMARK_IMAGE,
                          name: IMAGES.DEFAULT_WATERMARK_NAME
                        };
                      } else {
                        watermarkData = result[0];
                      }
                      return {acrof, numberPage, watermark: watermarkData};
                    })
                  )
              );
            });
          });
        }
      });
    }
    if (requests.length === 0) {
      return of(requests);
    } else {
      return forkJoin(requests);
    }
  }

  private drawSignature(watermark: WatermarkData): HTMLImageElement {
    const image = new Image();
    if (watermark) {
      image.src = `data:image/png;base64, ${watermark.image}`;
    } else {
      image.src = 'data:image/png;base64';
    }

    return image;
  }

  loadSignature(file: any, $event: any) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        const target: any = e.target;
        const img = new Image;
        img.src = target.result;
        this.digitalSignature.drawImage(target.result);
        this.disableSignatureSetSlider(img);
      };
      if (!(file instanceof Blob)) {
        reader.readAsDataURL(PdfPositionCapturerComponent.dataURLToBlob(file));
      } else {
        reader.readAsDataURL(file);
      }
      this.disableSignature = false;
      if ($event) {
        $event.target.value = '';
      }
    }
  }
}
