import { EventBus } from 'src/app/core/pipes/eventBus';
import * as i0 from "@angular/core";
export class NotifierService {
    constructor() {
        this._detectObs2 = new EventBus();
        this._isDetected = false;
    }
    get isDetected() {
        return this._isDetected;
    }
    set isDetected(value) {
        if (value !== this._isDetected) {
            this._isDetected = value;
        }
    }
    notifyDetectUpdatePage(actualPage) {
        this._detectObs2.publish(actualPage);
    }
    subscribeToDetectPage(callback) {
        return this._detectObs2.subscribe(callback);
    }
}
NotifierService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotifierService_Factory() { return new NotifierService(); }, token: NotifierService, providedIn: "root" });
