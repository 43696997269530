import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbDatepickerModule, NgbModalModule, NgbTimepickerModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../../shared/shared.module';
import { WizardStepCommentsComponent } from './components/wizard-step-comments/wizard-step-comments.component';
import { ModalConfiguratorDocumentsComponent } from './components/wizard-step-configurator/modal-configurator/modal-configurator-documents/modal-configurator-documents.component';
import { ModalConfiguratorMetadatasComponent } from './components/wizard-step-configurator/modal-configurator/modal-configurator-metadatas/modal-configurator-metadatas.component';
import { ModalConfiguratorSignersComponent } from './components/wizard-step-configurator/modal-configurator/modal-configurator-signers/modal-configurator-signers.component';
import { WizardStepConfiguratorComponent } from './components/wizard-step-configurator/wizard-step-configurator.component';
import { WizardStepDocumentsComponent } from './components/wizard-step-documents/wizard-step-documents.component';
import { WizardStepGeneralComponent } from './components/wizard-step-general/wizard-step-general.component';
import { WizardStepLegal } from './components/wizard-step-legal/wizard-step-legal';
import { WizardStepMetadataComponent } from './components/wizard-step-metadata/wizard-step-metadata.component';
import { NotificationOptionCardComponent } from './components/wizard-step-notifications/notification-option-card/notification-option-card.component';
import { NotificationTemplatesEditorComponent } from './components/wizard-step-notifications/notification-templates-editor/notification-templates-editor.component';
import { WizardStepNotificationsComponent } from './components/wizard-step-notifications/wizard-step-notifications.component';
import { WizardStepRemindersComponent } from './components/wizard-step-reminders/wizard-step-reminders.component';
import { WizardStepSignatureComponent } from './components/wizard-step-signature/wizard-step-signature.component';
import { WizardStepSummaryComponent } from './components/wizard-step-summary/wizard-step-summary.component';
import { WizardStepUsersComponent } from './components/wizard-step-users/wizard-step-users.component';
import { StepComponent } from './container/step.component';
import { SprofileResolver } from './services/sprofile-resolver.service';
import { StepPropertiesResolver } from './services/step-property-resolver-service';
import { StepResolver } from './services/step-resolver.service';
import { StepRoutingModule } from './step-routing.module';
import { WizardModule } from './wizard/wizard.module';

@NgModule({
  declarations: [
    StepComponent,
    WizardStepGeneralComponent,
    WizardStepDocumentsComponent,
    WizardStepUsersComponent,
    WizardStepSignatureComponent,
    WizardStepSummaryComponent,
    WizardStepLegal,
    WizardStepMetadataComponent,
    WizardStepNotificationsComponent,
    WizardStepRemindersComponent,
    WizardStepCommentsComponent,
    WizardStepConfiguratorComponent,
    ModalConfiguratorSignersComponent,
    ModalConfiguratorDocumentsComponent,
    ModalConfiguratorMetadatasComponent,
    NotificationOptionCardComponent,
    NotificationTemplatesEditorComponent
  ],
  imports: [
    CommonModule,
    StepRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgxSpinnerModule,
    NgbModalModule,
    NgbModule,
    SharedModule,
    FormsModule,
    WizardModule,
    QuillModule
  ],
  exports: [
    ModalConfiguratorSignersComponent,
    ModalConfiguratorDocumentsComponent,
    ModalConfiguratorMetadatasComponent
  ],
  providers: [
    StepResolver,
    StepPropertiesResolver,
    SprofileResolver
  ],
  entryComponents: [
    NotificationTemplatesEditorComponent
  ]
})
export class StepModule {
}
