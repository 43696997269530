/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Nexu configuration
 */
export interface NexuConfiguration { 
    /**
     * Digest
     */
    digestAlgorithm?: NexuConfiguration.DigestAlgorithmEnum;
    /**
     * HTTP port
     */
    port?: number;
    /**
     * HTTP scheme
     */
    scheme?: string;
}
export namespace NexuConfiguration {
    export type DigestAlgorithmEnum = 'SHA1' | 'SHA224' | 'SHA256' | 'SHA384' | 'SHA512' | 'SHA3_224' | 'SHA3_256' | 'SHA3_384' | 'SHA3_512' | 'RIPEMD160' | 'MD2' | 'MD5' | 'WHIRLPOOL';
    export const DigestAlgorithmEnum = {
        SHA1: 'SHA1' as DigestAlgorithmEnum,
        SHA224: 'SHA224' as DigestAlgorithmEnum,
        SHA256: 'SHA256' as DigestAlgorithmEnum,
        SHA384: 'SHA384' as DigestAlgorithmEnum,
        SHA512: 'SHA512' as DigestAlgorithmEnum,
        SHA3224: 'SHA3_224' as DigestAlgorithmEnum,
        SHA3256: 'SHA3_256' as DigestAlgorithmEnum,
        SHA3384: 'SHA3_384' as DigestAlgorithmEnum,
        SHA3512: 'SHA3_512' as DigestAlgorithmEnum,
        RIPEMD160: 'RIPEMD160' as DigestAlgorithmEnum,
        MD2: 'MD2' as DigestAlgorithmEnum,
        MD5: 'MD5' as DigestAlgorithmEnum,
        WHIRLPOOL: 'WHIRLPOOL' as DigestAlgorithmEnum
    };
}
