<app-tooltip [tooltipText]="'HELP.WIZARD_GENERAL' | translate"></app-tooltip>

<app-wizard-step>
  <div class="step-general-container mb-4 center fixed-md"
       content>
    <!-- Step name -->
    <div class="app-input-text">
      <div class="form-group">
        <label for="input-step-name">{{'WIZARD.GENERAL.STEP_LABEL' | translate}}</label>
        <app-input-loader [status]="labelStatus"></app-input-loader>
        <div class="input-group">
          <input #labelInput
                 (input)="handleInputLabel($event.target.value)"
                 [value]="step.label"
                 autofocus
                 class="form-control"
                 id="input-step-name"
                 required
                 type="text"/>
          <div class="input-group-append"
               *ngIf="appService.userHasEntitlement(appService.ent.session_translation)">
            <div
              (click)="router.navigate(['/admin', 'labels'], {queryParams: {targets: step.id, type: 'STEPNAME'}})"
              [ngbTooltip]="'TRANSLATE' | translate"
              class="input-group-text cursor-pointer">
              <i aria-hidden="true"
                 class="fa fa-fw fa-book"></i>
            </div>
          </div>
        </div>
        <div *ngIf="stepNameError" class="error-message">
          {{'WIZARD.GENERAL.STEP_NAME_REQUIRED' | translate}}
        </div>
        <div *ngIf="stepNameLengthLimit" class="error-message">
          {{'WIZARD.GENERAL.STEP_MAX_LEN_EXCEED' | translate}}
        </div>
      </div>
    </div>

    <div *ngIf="!session.template && !wizardService.sessionIsTemplate && !showDayPicker" class="date-picker">
      <!-- Not sign before -->
      <div class="select mb-2 form-group">
        <div class="input-group">
          <div class="input-group-prepend fill-group flex-1">
            <div class="input-group-text">
              {{'WIZARD.GENERAL.DATE.NOT_BEFORE' | translate}}
            </div>
          </div>
          <app-input-loader [status]="signatureDateNotBeforeStatus"
                            class="flex-1"></app-input-loader>
          <app-date-time-picker (ngModelChange)="handleDate('signnotBefore')" *ngIf="stepProperties"
                                [(ngModel)]="signNotBefore"
                                [idPostfix]="'dt-before'"
                                [manualDisable]="true"
                                [noMargin]="true"
                                class="flex-90">
          </app-date-time-picker>
          <div (click)="clearBefore()"
               class="input-group-append fill-group flex-1 cursor-pointer">
            <div class="input-group-text icon-button clearBtn">
              <i class="fas fa-backspace"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- Not sign after -->
      <div class="select mb-2 form-group">
        <div class="input-group">
          <div class="input-group-prepend fill-group flex-1">
            <div class="input-group-text">
              {{'WIZARD.GENERAL.DATE.NOT_AFTER' | translate}}
            </div>
          </div>
          <app-input-loader [status]="signatureDateNotAfterStatus"
                            class="flex-1"></app-input-loader>
          <app-date-time-picker (ngModelChange)="handleDate('signnotAfter')" *ngIf="stepProperties"
                                [(ngModel)]="signNotAfter"
                                [idPostfix]="'dt-after'"
                                [manualDisable]="true"
                                [noMargin]="true"
                                class="flex-90">
          </app-date-time-picker>
          <div (click)="clearAfter()"
               class="input-group-append fill-group flex-1 cursor-pointer">
            <div class="input-group-text icon-button clearBtn">
              <i class="fas fa-backspace"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="session.template || wizardService.sessionIsTemplate || showDayPicker" class="date-picker">
      <div class="select mb-2 form-group">
        <div class="input-group">
          <div class="input-group-prepend fill-group">
            <div class="input-group-text">
              {{'WIZARD.GENERAL.DAYS.NOT_BEFORE' | translate}}
            </div>
          </div>
          <app-input-day-number
            (onChange)="handleTemplateDate($event, 'signNotBefore')"
            [defaultValue]="diffDate(signNotBefore)"
            [max]="maxDay"
            [notBefore] = "notBefore"
            [min]="0"
            [type]="'minus'">
          </app-input-day-number>
        </div>
      </div>
      <div class="select mb-2 form-group">
        <div class="input-group">
          <div class="input-group-prepend fill-group">
            <div class="input-group-text">
              {{'WIZARD.GENERAL.DAYS.NOT_AFTER' | translate}}
            </div>
          </div>
          <app-input-day-number
            (onChange)="handleTemplateDate($event, 'signNotAfter')"
            [defaultValue]="diffDate(signNotAfter)"
            [min]="minDay"
            [type]="'plus'">
          </app-input-day-number>
        </div>
      </div>
    </div>
    <span *ngIf="showSmallerThanError" class="error-message">{{'WIZARD.GENERAL.DATE_SMALLER_ERROR' | translate}}</span>

    <div class="date-picker d-flex justify-content-start app-input-text mt-4">
      <!-- Turn into a Template -->
      <button (click)="generateTemplate()"
              [disabled]="generatingTemplate"
              *ngIf="session.requesterManager
                      && !session.template
                      && appService.userHasEntitlement(appService.ent.manage_template)
                      && !wizardService.sessionIsTemplate
                      && this.session.status === 'CREATED'"
              class="btn btn-primary">
        <i aria-hidden="true" class="fa fa-save fa-fw"></i>
        {{'SESSION.SAVE_TEMPLATE' | translate}}
      </button>
      <!-- Auto start -->
      <div class="switcher switcher-right ml-auto">
        <label for="input-auto-switch">
          {{'WIZARD.GENERAL.AUTO_START' | translate}}
        </label>
        <input (change)="handleInputAutoStart()"
               [(ngModel)]="step.auto"
               class="switcher-checkbox"
               id="input-auto-switch"
               type="checkbox">
        <div class="switcher-toggle"></div>
      </div>
      <!-- Create acroforms -->
      <div class="switcher switcher-right">
        <label for="input-skip-switch">
          {{'WIZARD.GENERAL.SKIP_ACRO' | translate}}
        </label>
        <input (change)="handleInputSkipAcro()"
               [(ngModel)]="step.skipAcroforms"
               class="switcher-checkbox"
               id="input-skip-switch"
               type="checkbox">
        <div class="switcher-toggle"></div>
      </div>
    </div>

    <div class="form-group col text-center justify-content-center mt-4">
      <div class="mt-3">
        <!-- PRIORITY -->
        <label class="d-block">{{'WIZARD.GENERAL.PRIORITY' | translate}}</label>
        <div class="d-inline-block">
          <div (click)="handlePriority(0)"
               [ngClass]=" {'active' : stepProperties.priority === 0}"
               class="priority-bubble low">
            <i class="fa fa-star"></i>
          </div>
          <label class="d-block priority-label">{{'WIZARD.GENERAL.NORMAL' | translate}}</label>
        </div>
        <div class="d-inline-block">
          <div (click)="handlePriority(1)"
               [ngClass]=" {'active' : stepProperties.priority === 1}"
               class="priority-bubble medium">
            <i class="fa fa-star-half-alt"></i>
          </div>
          <label class="d-block priority-label">{{'WIZARD.GENERAL.MEDIUM' | translate}}</label>
        </div>
        <div class="d-inline-block">
          <div (click)="handlePriority(2)"
               [ngClass]=" {'active' : stepProperties.priority === 2}"
               class="priority-bubble high">
            <i class="fa fa-star"></i>
          </div>
          <label class="d-block priority-label">{{'WIZARD.GENERAL.HIGH' | translate}}</label>
        </div>
        <div class="d-inline-block text-center">
          <div (click)="handlePriority(3)"
               [ngClass]=" {'active' : stepProperties.priority === 3}"
               class="priority-bubble critic">
            <i class="fa fa-exclamation"></i>
          </div>
          <label
            class="d-block priority-label">{{'WIZARD.GENERAL.CRITICAL' | translate}}</label>
        </div>
      </div>
    </div>


    <ng-container *ngIf="confidentialityForm">
      <label class="title">{{'WIZARD.GENERAL.CONFIDENTIALITY' | translate}}</label>
      <form [formGroup]="confidentialityForm" class="confidentiality">
        <input type="radio" value="C1" id="c1" formControlName="confidentialityLevel"/>
        <label for="c1">C1</label>
        <input type="radio" value="C2" id="c2" formControlName="confidentialityLevel"/>
        <label for="c2">C2</label>
        <input type="radio" value="C3" id="c3" formControlName="confidentialityLevel"/>
        <label for="c3">C3</label>
      </form>
    </ng-container>


    <form [formGroup]="organisationForm">
      <div *ngIf="currentOrganisation">
      <!--Organisation-->
      <app-autocomplete-custom [placeholder]=" 'Organisation' "
                               [filterWithInput]="false"
                               (valueInputChange)="reloadProvider($event)"
                               [errors]="{required: 'NEW_SESSION.SIGNERS.NEW_SIGNERS.ORGANISATION.REQUIRED' | translate}"
                               [datasProviders]="organisationProvider"
                               class="col-12"
                               formControlName="selectedOrganisation">
      </app-autocomplete-custom>
      </div>
    </form>

    <!--Manager-->
    <app-endusers-table
      (addEnduser)="addManager($event)"
      (outputSearch)="onSearchManager($event)"
      (removeEnduser)="removeManager($event)"
      [availableEndusers]="availableManager"
      [loaderStatus]="loaderStatusManager"
      [noResult]="'WIZARD.MANAGERS.NO_RESULT' | translate"
      [placeholder]="'WIZARD.MANAGERS.SEARCH' | translate"
      [selectedEndusers]="selectedManager"
      [intoWizardGeneral]="true"
      [singleList]="true"
      [title]="'WIZARD.GENERAL.MANAGER' | translate" class="fixed-md">
    </app-endusers-table>

  </div>
  <div footer>
    <div class="fixed-container">
      <div class="right fixed-md text-right">
        <app-wizard-step-button *ngIf="!stepNameError && !stepNameLengthLimit" [action]="stepActions.NEXT"
                                [disabled]="wizardStepActionDisabled"
                                [label]="'WIZARD.NEXT_STEP' | translate">
        </app-wizard-step-button>
      </div>
    </div>
  </div>
</app-wizard-step>
