<div class="modal-header">
  <h4 class="modal-title">{{'VALIDATION_CODE.TITLE' | translate}}</h4>
  <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form (ngSubmit)="validateCode()" [formGroup]="form">
  <div class="modal-body">
    <div class="form-group">
      <label for="validationCodeInput">{{'VALIDATION_CODE.TITLE' | translate}}</label>
      <input #inputValidationCode
            (ngModelChange)="updateCanGenerateCodeFlag($event)"
             aria-describedby="validationCodeHelp" class="form-control"
             formControlName="validationCode"
             id="validationCodeInput"
             mask="000 000"
             placeholder="{{'VALIDATION_CODE.INPUT' | translate:{channel: channel} }}"
             type="text">
      <div *ngIf="validationCode.errors">
        <small
          *ngIf="(validationCode.dirty || validationCode.touched) && validationCode.hasError('required')"
          class="text-danger form-text">
          {{'VALIDATION_CODE.INVALID_REQUIRED' | translate}}
        </small>
        <small
          *ngIf="(validationCode.dirty || validationCode.touched) && validationCode.hasError('pattern')"
          class="text-danger form-text">
          {{'VALIDATION_CODE.INVALID_PATTERN' | translate}}
        </small>
      </div>
      <div *ngIf="signatureFailed">
        <div class="alert alert-danger">
          {{'VALIDATION_CODE.INCORRECT' | translate}}
        </div>
      </div>
      <div class="mt-2">
        <button (click)="generateCode()" class="btn btn-sm btn-secondary"
                [disabled]="!canGenerateCode"
                type="button">{{'VALIDATION_CODE.GENERATE' | translate}}</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="submitted || form.invalid" class="btn btn-primary"
            type="submit" id="id-submit-validation-code">{{'VALIDATION_CODE.SEND' | translate}}</button>
    <button (click)="activeModal.dismiss()" class="btn btn-outline-secondary"
            type="button">{{'VALIDATION_CODE.CANCEL' | translate}}</button>
  </div>
</form>
