import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild
} from '@angular/core';
import {SignatureWorkflowService} from 'luxtrust-cosi-api/api/signatureWorkflow.service';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {WorkflowData} from 'luxtrust-cosi-api/model/workflowData';
import {StepData} from 'luxtrust-cosi-api';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {KEYBOARD} from '../../../app.constant';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {Status} from '../input-loader/input-loader.component';
import SignatureStatusEnum = WorkflowData.SignatureStatusEnum;

@Component({
  selector: 'app-endusers-table', templateUrl: './endusers-table.component.html', styleUrls: ['./endusers-table.component.scss']
})
export class EndusersTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() title: string;
  @Input() noResult: string;
  @Input() placeholder: string;
  @Input() replaceAloneSigner = false;
  @Input() selectedEndusers: EnduserData[] = [];
  @Input() availableEndusers: EnduserData[] = [];
  @Input() loaderStatus: Status;
  @Input() singleList: boolean;
  @Input() sortable = false;
  @Input() borderBottom = true;
  @Input() sortedUsers: boolean;
  @Input() disabled = false;
  @Input() disabledSelectCircle = true;
  @Input() canAdd = true;
  @Input() intoWizardGeneral = false;
  @Input() search = false;
  @Input() idPostfix = '';
  @Input() canReplace = false;
  @Input() page: number; // Page into modal configurator
  @Input() isConfigurator = false;
  @Input() sessionId: number;
  @Input() step: StepData;
  @Input() isSignerManagement = false;
  @Input() enableInviteNewSigner = true;

  @Output() addEnduser: EventEmitter<EnduserData> = new EventEmitter<EnduserData>();
  @Output() removeEnduser: EventEmitter<EnduserData> = new EventEmitter<EnduserData>();
  @Output() outputSort: EventEmitter<EnduserData[]> = new EventEmitter<EnduserData[]>();
  @Output() outputUnsort: EventEmitter<EnduserData[]> = new EventEmitter<EnduserData[]>();
  @Output() outputSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() outputUpdateExpected: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() replaceUsers: EventEmitter<{ oldId: number, newUser: EnduserData }> = new EventEmitter<{ oldId: number, newUser: EnduserData }>();
  @Output() openModal: EventEmitter<{ replace: boolean, oldEnduserId: number }> = new EventEmitter<{ replace: boolean, oldEnduserId: number }>();
  @Output() outputReplaceMode: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() sortMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  replaceEnduserId: number;
  replaceMode: boolean;
  @Input() otherInReplaceMode = false;

  @ViewChild('searchList', {
    read: ElementRef, static: false
  }) searchList;
  @ViewChild('inputSearchUser', {
    read: ElementRef, static: false
  }) inputSearchUser;

  inputSearch: string;
  debouncer: Subject<string> = new Subject();
  searchListOpen = false;
  activeSignerIndex = -1;
  workflowsList: WorkflowData[];
  checkAlreadySign = false;

  constructor(
    private workflowService: SignatureWorkflowService,
    private alertService: AlertService
  ) {
    this.registerInputDebouncer();
  }

  ngOnInit() {
    this.replaceMode = this.replaceAloneSigner;
    this.storeWorkflowList();
  }

  storeWorkflowList() {
    if (this.sessionId && this.step.id) {
      this.workflowService.getWorkflowList(this.sessionId, this.step.id).subscribe(workflowsList => {
        this.workflowsList = workflowsList;
        this.checkAlreadySign = true;
      }, (error: ApiError) => this.alertService.errorApi(error));
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['page'] && changes['page'].previousValue !== changes['page'].currentValue) {
      this.replaceMode = false;
      this.replaceEnduserId = undefined;
    }
    if (changes['otherInReplaceMode'] && !changes['otherInReplaceMode'].firstChange) {
      if (this.otherInReplaceMode) {
        this.replaceMode = false;
        this.replaceEnduserId = undefined;
      }
    }
  }

  ngOnDestroy() {
    this.debouncer.complete();
  }

  /**
   * listen where the user is clicking. If he is clicking outside this component
   * the list box will close if it's opened.
   * @param targetElement
   */
  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    let insideSearchList;
    if (this.searchList) {
      insideSearchList = this.searchList.nativeElement.contains(targetElement);
    }
    if (!insideSearchList) {
      this.onBlurSearch();
    }
  }

  up(u: EnduserData) {
    const index = this.selectedEndusers.findIndex(su => su.id === u.id);
    this.selectedEndusers.splice(index - 1, 0, this.selectedEndusers.splice(index, 1)[0]);
    this.sortedUsers = true;
    this.outputSort.emit(this.selectedEndusers);
  }

  down(u: EnduserData) {
    const index = this.selectedEndusers.findIndex(su => su.id === u.id);
    this.selectedEndusers.splice(index + 1, 0, this.selectedEndusers.splice(index, 1)[0]);
    this.sortedUsers = true;
    this.outputSort.emit(this.selectedEndusers);
  }

  unsort() {
    this.sortedUsers = false;
    this.outputUnsort.emit(this.selectedEndusers);
    this.sortMode.emit(false);
  }

  sort() {
    this.sortedUsers = true;
    this.sortMode.emit(true);
    this.outputSort.emit(this.selectedEndusers);
  }

  noUp(u: EnduserData) {
    const index = this.selectedEndusers.findIndex(su => su.id === u.id);
    return this.disabled || index === 0;
  }

  noDown(u: EnduserData) {
    const index = this.selectedEndusers.findIndex(su => su.id === u.id);
    return this.disabled || index >= this.selectedEndusers.length - 1;
  }

  registerInputDebouncer() {
    this.debouncer.pipe(debounceTime(300))
      .subscribe((value) => this.outputSearch.emit(value));
  }

  handleInputChange(value: string) {
    const trimmedValue = value.trim();
    if (trimmedValue) {
      this.debouncer.next(value);
    }
  }

  onFocusSearch() {
    this.searchListOpen = true;
    this.outputSearch.emit('');
  }

  onBlurSearch() {
    this.searchListOpen = false;
    this.inputSearch = '';
  }

  handleOnAddOrReplaceEnduser(enduser: EnduserData) {
    if (this.replaceMode) {
      this.replaceUsers.emit({oldId: this.replaceEnduserId, newUser: enduser});
    } else {
      this.addEnduser.emit(enduser);
    }

    if (!this.replaceAloneSigner) {
      this.replaceMode = false;
      this.replaceEnduserId = undefined;
    }
    this.inputSearch = '';
    this.searchListOpen = false;
  }

  handleOnAddRemove(enduser: EnduserData) {
    if (this.selectedEndusers.length < 3) {
      this.sortedUsers = false;
      this.sortMode.emit(false);
    }
    this.removeEnduser.emit(enduser);
  }

  handleOnUpdateExpected(event) {
    this.outputUpdateExpected.emit(event);
  }

  signerKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEYBOARD.ARROW_UP:
        if (this.activeSignerIndex > -1) {
          this.activeSignerIndex--;
        }
        break;
      case KEYBOARD.ARROW_DOWN:
        if (this.activeSignerIndex < this.availableEndusers.length - 1) {
          this.activeSignerIndex++;
        }
        break;
      case KEYBOARD.ENTER:
        if (this.activeSignerIndex !== -1) {
          this.handleOnAddOrReplaceEnduser(this.availableEndusers[this.activeSignerIndex]);
        }
        break;
      default:
        break;
    }
  }

  onClickReplaceEnduser(enduserId) {
    setTimeout(() => {
      this.inputSearchUser.nativeElement.focus();
    });
    if (enduserId === this.replaceEnduserId && !this.replaceAloneSigner) {
      this.replaceMode = false;
      this.replaceEnduserId = undefined;
    } else {
      this.replaceMode = true;
      this.replaceEnduserId = enduserId;
      this.otherInReplaceMode = false;
      this.outputReplaceMode.emit();
    }
  }

  onOpenModal() {
    this.openModal.emit({replace: this.replaceMode, oldEnduserId: this.replaceEnduserId});
    this.replaceMode = false;
    this.replaceEnduserId = undefined;
  }

  // for Configurator when update started step
  hasAlreadySign(enduser: EnduserData): boolean {
    if (!this.workflowsList) {
      return false;
    }
    const workflow = this.workflowsList.find(w => w.enduserId === enduser.id);
    return (workflow && workflow.signatureStatus === SignatureStatusEnum.SIGNED);
  }
}
