<div class="comments-card-container">
  <quill-editor *ngIf="showTextArea"
                [(ngModel)]="addedComment"
                [modules]="quillConfig"
                [placeholder]="'STEP.COMMENT.TYPE_COMMENT_HERE' | translate"
                id="comment-text-add">
  </quill-editor>
  <div class="col">
    <div class="row justify-content-between">
      <p *ngIf="!showTextArea" [innerHTML]="comment.text" class="mt-2 ml-3"></p>
      <div class="btn-right">
        <button (click)="editAddedComment()" *ngIf="!showTextArea" [disabled]="disabled"
                class="icon-button edit"><i class="fas fa-pencil-alt"></i>
        </button>
        <button (click)="editComment()" *ngIf="showTextArea" [disabled]="disabled"
                class="icon-button edit"><i class="fas fa-check"></i>
        </button>
        <button (click)="clearComment()" *ngIf="showTextArea" [disabled]="disabled"
                class="icon-button remove "><i class="fas fa-times"></i>
        </button>
        <button (click)="deleteComment()" [disabled]="disabled" class="remove icon-button">
          <i aria-hidden="true" class="fa fa-trash-alt"></i>
        </button>
      </div>
    </div>

  </div>
</div>
