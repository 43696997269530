/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FinalizeSignatureRequest { 
    certificate?: string;
    certificateChain?: Array<string>;
    signatureAlgorithm?: FinalizeSignatureRequest.SignatureAlgorithmEnum;
    signatureValue?: string;
    signingDate?: Date;
}
export namespace FinalizeSignatureRequest {
    export type SignatureAlgorithmEnum = 'RSA_RAW' | 'RSA_SHA1' | 'RSA_SHA224' | 'RSA_SHA256' | 'RSA_SHA384' | 'RSA_SHA512' | 'RSA_SHA3_224' | 'RSA_SHA3_256' | 'RSA_SHA3_384' | 'RSA_SHA3_512' | 'RSA_SSA_PSS_RAW_MGF1' | 'RSA_SSA_PSS_SHA1_MGF1' | 'RSA_SSA_PSS_SHA224_MGF1' | 'RSA_SSA_PSS_SHA256_MGF1' | 'RSA_SSA_PSS_SHA384_MGF1' | 'RSA_SSA_PSS_SHA512_MGF1' | 'RSA_SSA_PSS_SHA3_224_MGF1' | 'RSA_SSA_PSS_SHA3_256_MGF1' | 'RSA_SSA_PSS_SHA3_384_MGF1' | 'RSA_SSA_PSS_SHA3_512_MGF1' | 'RSA_RIPEMD160' | 'RSA_MD5' | 'RSA_MD2' | 'ECDSA_RAW' | 'ECDSA_SHA1' | 'ECDSA_SHA224' | 'ECDSA_SHA256' | 'ECDSA_SHA384' | 'ECDSA_SHA512' | 'ECDSA_SHA3_224' | 'ECDSA_SHA3_256' | 'ECDSA_SHA3_384' | 'ECDSA_SHA3_512' | 'ECDSA_RIPEMD160' | 'PLAIN_ECDSA_SHA1' | 'PLAIN_ECDSA_SHA224' | 'PLAIN_ECDSA_SHA256' | 'PLAIN_ECDSA_SHA384' | 'PLAIN_ECDSA_SHA512' | 'PLAIN_ECDSA_RIPEMD160' | 'DSA_RAW' | 'DSA_SHA1' | 'DSA_SHA224' | 'DSA_SHA256' | 'DSA_SHA384' | 'DSA_SHA512' | 'DSA_SHA3_224' | 'DSA_SHA3_256' | 'DSA_SHA3_384' | 'DSA_SHA3_512' | 'HMAC_SHA1' | 'HMAC_SHA224' | 'HMAC_SHA256' | 'HMAC_SHA384' | 'HMAC_SHA512' | 'HMAC_SHA3_224' | 'HMAC_SHA3_256' | 'HMAC_SHA3_384' | 'HMAC_SHA3_512' | 'HMAC_RIPEMD160';
    export const SignatureAlgorithmEnum = {
        RSARAW: 'RSA_RAW' as SignatureAlgorithmEnum,
        RSASHA1: 'RSA_SHA1' as SignatureAlgorithmEnum,
        RSASHA224: 'RSA_SHA224' as SignatureAlgorithmEnum,
        RSASHA256: 'RSA_SHA256' as SignatureAlgorithmEnum,
        RSASHA384: 'RSA_SHA384' as SignatureAlgorithmEnum,
        RSASHA512: 'RSA_SHA512' as SignatureAlgorithmEnum,
        RSASHA3224: 'RSA_SHA3_224' as SignatureAlgorithmEnum,
        RSASHA3256: 'RSA_SHA3_256' as SignatureAlgorithmEnum,
        RSASHA3384: 'RSA_SHA3_384' as SignatureAlgorithmEnum,
        RSASHA3512: 'RSA_SHA3_512' as SignatureAlgorithmEnum,
        RSASSAPSSRAWMGF1: 'RSA_SSA_PSS_RAW_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA1MGF1: 'RSA_SSA_PSS_SHA1_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA224MGF1: 'RSA_SSA_PSS_SHA224_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA256MGF1: 'RSA_SSA_PSS_SHA256_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA384MGF1: 'RSA_SSA_PSS_SHA384_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA512MGF1: 'RSA_SSA_PSS_SHA512_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA3224MGF1: 'RSA_SSA_PSS_SHA3_224_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA3256MGF1: 'RSA_SSA_PSS_SHA3_256_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA3384MGF1: 'RSA_SSA_PSS_SHA3_384_MGF1' as SignatureAlgorithmEnum,
        RSASSAPSSSHA3512MGF1: 'RSA_SSA_PSS_SHA3_512_MGF1' as SignatureAlgorithmEnum,
        RSARIPEMD160: 'RSA_RIPEMD160' as SignatureAlgorithmEnum,
        RSAMD5: 'RSA_MD5' as SignatureAlgorithmEnum,
        RSAMD2: 'RSA_MD2' as SignatureAlgorithmEnum,
        ECDSARAW: 'ECDSA_RAW' as SignatureAlgorithmEnum,
        ECDSASHA1: 'ECDSA_SHA1' as SignatureAlgorithmEnum,
        ECDSASHA224: 'ECDSA_SHA224' as SignatureAlgorithmEnum,
        ECDSASHA256: 'ECDSA_SHA256' as SignatureAlgorithmEnum,
        ECDSASHA384: 'ECDSA_SHA384' as SignatureAlgorithmEnum,
        ECDSASHA512: 'ECDSA_SHA512' as SignatureAlgorithmEnum,
        ECDSASHA3224: 'ECDSA_SHA3_224' as SignatureAlgorithmEnum,
        ECDSASHA3256: 'ECDSA_SHA3_256' as SignatureAlgorithmEnum,
        ECDSASHA3384: 'ECDSA_SHA3_384' as SignatureAlgorithmEnum,
        ECDSASHA3512: 'ECDSA_SHA3_512' as SignatureAlgorithmEnum,
        ECDSARIPEMD160: 'ECDSA_RIPEMD160' as SignatureAlgorithmEnum,
        PLAINECDSASHA1: 'PLAIN_ECDSA_SHA1' as SignatureAlgorithmEnum,
        PLAINECDSASHA224: 'PLAIN_ECDSA_SHA224' as SignatureAlgorithmEnum,
        PLAINECDSASHA256: 'PLAIN_ECDSA_SHA256' as SignatureAlgorithmEnum,
        PLAINECDSASHA384: 'PLAIN_ECDSA_SHA384' as SignatureAlgorithmEnum,
        PLAINECDSASHA512: 'PLAIN_ECDSA_SHA512' as SignatureAlgorithmEnum,
        PLAINECDSARIPEMD160: 'PLAIN_ECDSA_RIPEMD160' as SignatureAlgorithmEnum,
        DSARAW: 'DSA_RAW' as SignatureAlgorithmEnum,
        DSASHA1: 'DSA_SHA1' as SignatureAlgorithmEnum,
        DSASHA224: 'DSA_SHA224' as SignatureAlgorithmEnum,
        DSASHA256: 'DSA_SHA256' as SignatureAlgorithmEnum,
        DSASHA384: 'DSA_SHA384' as SignatureAlgorithmEnum,
        DSASHA512: 'DSA_SHA512' as SignatureAlgorithmEnum,
        DSASHA3224: 'DSA_SHA3_224' as SignatureAlgorithmEnum,
        DSASHA3256: 'DSA_SHA3_256' as SignatureAlgorithmEnum,
        DSASHA3384: 'DSA_SHA3_384' as SignatureAlgorithmEnum,
        DSASHA3512: 'DSA_SHA3_512' as SignatureAlgorithmEnum,
        HMACSHA1: 'HMAC_SHA1' as SignatureAlgorithmEnum,
        HMACSHA224: 'HMAC_SHA224' as SignatureAlgorithmEnum,
        HMACSHA256: 'HMAC_SHA256' as SignatureAlgorithmEnum,
        HMACSHA384: 'HMAC_SHA384' as SignatureAlgorithmEnum,
        HMACSHA512: 'HMAC_SHA512' as SignatureAlgorithmEnum,
        HMACSHA3224: 'HMAC_SHA3_224' as SignatureAlgorithmEnum,
        HMACSHA3256: 'HMAC_SHA3_256' as SignatureAlgorithmEnum,
        HMACSHA3384: 'HMAC_SHA3_384' as SignatureAlgorithmEnum,
        HMACSHA3512: 'HMAC_SHA3_512' as SignatureAlgorithmEnum,
        HMACRIPEMD160: 'HMAC_RIPEMD160' as SignatureAlgorithmEnum
    };
}
