import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from 'src/app/core/service/translate.service';
import {ItsmeSignatureService} from 'luxtrust-cosi-api/api/itsmeSignature.service';
import {ItsmeSignSessionDto} from 'luxtrust-cosi-api/model/itsmeSignSessionDto';
import {take} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {ItsmeService, ItsmeServiceSignatureEnum} from '../../../services/services/itsme.service';

@Component({
  templateUrl: './itsme-signature.component.html', styleUrls: ['./itsme-signature.component.scss']
})
export class ItsmeSignatureComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private translateService: TranslateService,
              private alertService: AlertService,
              private itsmeSignatureService: ItsmeSignatureService,
              private itsmeService: ItsmeService) {
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(take(1)).subscribe((params: ParamMap) => {
      const itsmeService = <ItsmeServiceSignatureEnum>params.get('service');
      const transactionId = +params.get('transactionId');
      const signerId = +params.get('signerId');
      if (transactionId && signerId) {
        return this.takeAction(itsmeService, transactionId, signerId);
      }
    });
  }

  private takeAction(itsmeService: ItsmeServiceSignatureEnum, transactionId: number, signerId: any) {
    switch (itsmeService) {
      case ItsmeServiceSignatureEnum.IDENTIFICATION:
        const redirectUrl = this.itsmeService.getRedirectUrlForSignature(ItsmeServiceSignatureEnum.SIGN);
        const lang = this.translateService.currentLang;
        this.initSignSession(redirectUrl, lang, transactionId, signerId);
        break;
      case ItsmeServiceSignatureEnum.SIGN:
        this.applySignature(transactionId, signerId);
        break;
      default:
        break;
    }
  }

  private initSignSession(redirectUrl: string, lang: string, transactionId: number, signerId: any) {
    this.itsmeSignatureService.itsmeSignSession(lang, redirectUrl, signerId, transactionId)
    .pipe(take(1))
    .subscribe((response: ItsmeSignSessionDto) => window.location.href = response.url, (error: ApiError) => this.alertService.errorApi(error));
  }

  private applySignature(transactionId: number, signerId: any) {
    this.itsmeSignatureService.itsmeApplySignature(signerId, transactionId)
    .pipe(take(1))
    .subscribe(() => {
      this.router.navigate(['/dashboard']);
    }, (error: ApiError) => this.alertService.errorApi(error));
  }
}
