<app-template-editor [patternData]="patternData"
                      [displayCloseButton]="true"
                      (closeEvent)="onClose()"
                      (inputEvent)="onInput($event)">
</app-template-editor>
<div class="modal-footer">
  <button (click)="save()"
          [disabled]="(patternData?.body?.length < 1) || saving"
          class="btn btn-primary"
          type="button">{{'MODAL_TEMPLATE_BUILDER.SAVE' | translate}}</button>
  <button (click)="onClose()"
          class="btn btn-cancel"
          type="button">{{'MODAL_TEMPLATE_BUILDER.CANCEL' | translate}}</button>
</div>
