/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal-orely.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../services/services/app.service";
import * as i4 from "../../../services/services/orely-service";
import * as i5 from "../../../services/services/alert-service";
var styles_ModalOrelyComponent = [];
var RenderType_ModalOrelyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalOrelyComponent, data: {} });
export { RenderType_ModalOrelyComponent as RenderType_ModalOrelyComponent };
export function View_ModalOrelyComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { iframe: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Orely Signature"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("CLOSED") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "modal-body"], ["width", "500px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, [[1, 0], ["signaturePdfIframe", 1]], null, 0, "iframe", [["frameborder", "0"], ["height", "700px"], ["id", "signaturePdfIframe"], ["name", "orely"], ["scrolling", "yes"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Loading... "])), (_l()(), i0.ɵeld(12, 0, null, null, 0, "div", [["class", "modal-footer"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.allowIFrame; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.allowIFrame; _ck(_v, 10, 0, currVal_1); }); }
export function View_ModalOrelyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).iFrameEventListener($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ModalOrelyComponent_0, RenderType_ModalOrelyComponent)), i0.ɵdid(1, 245760, null, 0, i1.ModalOrelyComponent, [i2.NgbActiveModal, i3.AppService, i4.OrelyService, i5.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalOrelyComponentNgFactory = i0.ɵccf("ng-component", i1.ModalOrelyComponent, View_ModalOrelyComponent_Host_0, {}, {}, []);
export { ModalOrelyComponentNgFactory as ModalOrelyComponentNgFactory };
