<app-card [collapsible]="true"
          [title]="'WIZARD.TAGS.REVIEW' | translate">
  <ul class="list-group mt-2">
    <ng-container *ngFor="let tag of stepTags ; trackBy: trackSteptag">
      <li *ngIf="tag?.system === false" class="list-group-item">
        <app-metadata-selector
          #metadataSelector
          [showMode]="true"
          [mask]="tag.mask"
          [canHaveNullToggle]="false"
          [disableAll]="disableAllMetadata"
          [isWatcher]="isWatcher"
          (tagInError)="tagLinkHasError = true"
          [tag]="tag"
          (onUpdate)="updateStepTagMeta($event)">
        </app-metadata-selector>
      </li>
    </ng-container>
  </ul>
</app-card>
