/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * FlowChart operation details
 */
export interface FlowOperationData { 
    /**
     * Operation category
     */
    category?: FlowOperationData.CategoryEnum;
    /**
     * Operation (= stepId)
     */
    id?: number;
    /**
     * Operation name
     */
    name?: string;
    /**
     * Operation status
     */
    status?: FlowOperationData.StatusEnum;
    /**
     * Operation type
     */
    type?: FlowOperationData.TypeEnum;
    /**
     * Operation position on X axis
     */
    x?: number;
    /**
     * Operation position on Y axis
     */
    y?: number;
}
export namespace FlowOperationData {
    export type CategoryEnum = 'OPERATION' | 'OPERATOR' | 'SPOT';
    export const CategoryEnum = {
        OPERATION: 'OPERATION' as CategoryEnum,
        OPERATOR: 'OPERATOR' as CategoryEnum,
        SPOT: 'SPOT' as CategoryEnum
    };
    export type StatusEnum = 'CREATED' | 'STARTED' | 'SKIPPED' | 'COMPLETED' | 'DECLINED' | 'CANCELED' | 'SUSPENDED';
    export const StatusEnum = {
        CREATED: 'CREATED' as StatusEnum,
        STARTED: 'STARTED' as StatusEnum,
        SKIPPED: 'SKIPPED' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        DECLINED: 'DECLINED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        SUSPENDED: 'SUSPENDED' as StatusEnum
    };
    export type TypeEnum = 'END' | 'START' | 'GATE' | 'PREPARATION' | 'STEP_OPERATION' | 'SIGNATURE' | 'VALIDATION';
    export const TypeEnum = {
        END: 'END' as TypeEnum,
        START: 'START' as TypeEnum,
        GATE: 'GATE' as TypeEnum,
        PREPARATION: 'PREPARATION' as TypeEnum,
        STEPOPERATION: 'STEP_OPERATION' as TypeEnum,
        SIGNATURE: 'SIGNATURE' as TypeEnum,
        VALIDATION: 'VALIDATION' as TypeEnum
    };
}
