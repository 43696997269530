import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import find from 'lodash/find';
import {DocumentData, OrganisationData, OrganisationService, StepData} from 'luxtrust-cosi-api';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {Subscription} from 'rxjs';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AutocompleteComponent} from '../form/autocomplete/autocomplete.component';
import {AppService} from '../../../services/services/app.service';

@Component({
  templateUrl: './modal-new-signer.component.html'
})
export class ModalNewSignerComponent implements OnInit {

  constructor(
    private appService: AppService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private organisationService: OrganisationService,
    private alertService: AlertService
  ) {}

  title: string;
  newSignerForm: FormGroup;
  @ViewChild('organisationAutocomplete', { static: false }) organisationAutocomplete: AutocompleteComponent<DocumentData>;
  @Output() newSignerModalResult: EventEmitter<EnduserData> = new EventEmitter<EnduserData>();
  @Input() public userDirectories: string[];
  @Input() step: StepData;
  directoryProvider: { key: string, value: string }[];
  organisationProvider: { key: string, value: number }[];
  valueOrgaSelected = {} ;
  @Input() public organisationSigner: OrganisationData;
  useOrgnisationSigner = true;
  directoryAlias: string;
  formSub: Subscription;

  ngOnInit(): void {
    this.userDirectories = this.appService.getUser().directories;
    this.organisationSigner = this.appService.getUser().organisation;

    if (!this.title) {
      this.title = 'NEW_SESSION.SIGNERS.NEW_SIGNERS.TITLE';
    }
    this.initForm();
    this.initData();
    this.manageValuesFormChanged();
  }

  private initForm() {
    this.newSignerForm = this.formBuilder.group({
      newSignerFirstName: ['', Validators.required],
      newSignerLastName: ['', Validators.required],
      newSignerDirectory: [(this.userDirectories && this.userDirectories.length) ? this.userDirectories[0] : '', Validators.required],
      newSignerEmail: ['', [Validators.required, Validators.email]],
      newSignerOrganisation: [''],
      newSignerPhone: ['', [Validators.required, Validators.pattern('[+]?[0-9]+$')]]
    });
  }

  private initData() {
    // console.log('input', this.step);
    if (this.step) {
      for (const tag of this.step.tags) {
        // console.log('tag', tag);
        if (tag.alias === 'STEP_CREATE_USER_DIRECTORY') {
          this.directoryProvider = tag.value.split('|').map(directory => {
            // console.log('directory', directory);
            // console.log('this.directoryAlias', this.directoryAlias);
            if (!this.directoryAlias) {
              this.directoryAlias = directory;
            }
            // console.log('this.directoryAlias', this.directoryAlias);
            return {
              key: directory,
              value: ''
            };
          });
        }
      }
    }
    // console.log('directoryProvider', this.directoryProvider);
    if (!this.directoryProvider) {
      this.directoryAlias = this.userDirectories[0];
      this.directoryProvider = this.userDirectories.map(directory => {
        return {
          key: directory,
          value: ''
        };
      });
    }
    const toSelectDirectoryAlias = find(this.directoryProvider, (currentDirectory => currentDirectory.key === this.directoryAlias));
    // console.log('toSelectDirectoryAlias', toSelectDirectoryAlias);
    this.reloadProvider(this.directoryAlias);
    this.newSignerForm.get('newSignerDirectory').setValue(toSelectDirectoryAlias);
  }

  private manageValuesFormChanged() {
    this.formSub = this.newSignerForm.valueChanges.subscribe(values => {
      if (values.newSignerDirectory && this.directoryAlias !== values.newSignerDirectory.key) {
        this.reloadProvider(values.newSignerDirectory.key);
        this.directoryAlias = values.newSignerDirectory.key;
        this.valueOrgaSelected = {key : '', value: ''};
      }
    });
  }

  reloadProvider(directory: string) {
    this.organisationService.searchOrganisations(directory, undefined,  1000).subscribe(organisation => {
      if(!this.newSignerForm.get('newSignerOrganisation').value && organisation) {
        this.initOrganisation(organisation[0]);
      }
      this.organisationProvider = organisation.map(orga => {
        return {
          key: orga.name,
          value: orga.organisationId
        };
      });
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  initOrganisation(organistion: OrganisationData){
    const orgaMostImportant = {
      key: organistion.name,
      value: organistion.organisationId
    };
    this.newSignerForm.get('newSignerOrganisation').setValue(orgaMostImportant);
  }
  onSubmit() {
    this.newSignerForm.markAllAsTouched();
    if (this.newSignerForm.invalid) {
      return;
    }
    const data: EnduserData = {
      email: this.newSignerForm.get('newSignerEmail').value,
      firstName: this.newSignerForm.get('newSignerFirstName').value,
      lastName: this.newSignerForm.get('newSignerLastName').value,
      organisation: {name: this.useOrgnisationSigner ? this.organisationSigner.name : this.newSignerForm.get('newSignerOrganisation').value['key'],
                     organisationId: this.useOrgnisationSigner ? this.organisationSigner.organisationId : this.newSignerForm.get('newSignerOrganisation').value['value']},
      directoryAlias: this.newSignerForm.get('newSignerDirectory').value.key,
      phone: this.newSignerForm.get('newSignerPhone').value
    };
    this.newSignerModalResult.emit(data);
    this.activeModal.close('Cross click');
  }
  changeOrganisation() {
    this.useOrgnisationSigner = !this.useOrgnisationSigner;
  }

  // Needed to display error immediately if any
  markAsTouched(controlName: string) {
    const control = this.newSignerForm.get(controlName);
    control.markAsTouched();
  }
}
