/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { StepDocumentEndUserPayload } from '../model/stepDocumentEndUserPayload';
import { StepDocumentEnduserData } from '../model/stepDocumentEnduserData';
import { UpdateStepDocumentEndUserByDocumentPayload } from '../model/updateStepDocumentEndUserByDocumentPayload';
import { UpdateStepDocumentEnduserByEnduserPayload } from '../model/updateStepDocumentEnduserByEnduserPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StepEndUserDocumentService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get link between step end users and documents
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the document to be linked to the user belongs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStepEnduserDocument(sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<StepDocumentEnduserData>>;
    public getStepEnduserDocument(sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StepDocumentEnduserData>>>;
    public getStepEnduserDocument(sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StepDocumentEnduserData>>>;
    public getStepEnduserDocument(sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getStepEnduserDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getStepEnduserDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StepDocumentEnduserData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents-link`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update link between step end users and documents
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the document to be linked to the user belongs
     * @param payload Session filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStepEnduserDocument(sessionId: number, stepId: number, payload?: StepDocumentEndUserPayload, observe?: 'body', reportProgress?: boolean): Observable<StepDocumentEnduserData>;
    public updateStepEnduserDocument(sessionId: number, stepId: number, payload?: StepDocumentEndUserPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepDocumentEnduserData>>;
    public updateStepEnduserDocument(sessionId: number, stepId: number, payload?: StepDocumentEndUserPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepDocumentEnduserData>>;
    public updateStepEnduserDocument(sessionId: number, stepId: number, payload?: StepDocumentEndUserPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateStepEnduserDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateStepEnduserDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<StepDocumentEnduserData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents-link`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update link between a step, a document and endusers
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the document have to be linked
     * @param payload Session filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStepEnduserDocumentByDocument(sessionId: number, stepId: number, payload?: UpdateStepDocumentEndUserByDocumentPayload, observe?: 'body', reportProgress?: boolean): Observable<Array<StepDocumentEnduserData>>;
    public updateStepEnduserDocumentByDocument(sessionId: number, stepId: number, payload?: UpdateStepDocumentEndUserByDocumentPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StepDocumentEnduserData>>>;
    public updateStepEnduserDocumentByDocument(sessionId: number, stepId: number, payload?: UpdateStepDocumentEndUserByDocumentPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StepDocumentEnduserData>>>;
    public updateStepEnduserDocumentByDocument(sessionId: number, stepId: number, payload?: UpdateStepDocumentEndUserByDocumentPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateStepEnduserDocumentByDocument.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateStepEnduserDocumentByDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<StepDocumentEnduserData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents-link/by-document`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update link between a step, an enduser and documents
     * 
     * @param sessionId Session to which the step belongs
     * @param stepId Step to which the documents have to be linked
     * @param payload Session filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStepEnduserDocumentByEnduser(sessionId: number, stepId: number, payload?: UpdateStepDocumentEnduserByEnduserPayload, observe?: 'body', reportProgress?: boolean): Observable<Array<StepDocumentEnduserData>>;
    public updateStepEnduserDocumentByEnduser(sessionId: number, stepId: number, payload?: UpdateStepDocumentEnduserByEnduserPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StepDocumentEnduserData>>>;
    public updateStepEnduserDocumentByEnduser(sessionId: number, stepId: number, payload?: UpdateStepDocumentEnduserByEnduserPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StepDocumentEnduserData>>>;
    public updateStepEnduserDocumentByEnduser(sessionId: number, stepId: number, payload?: UpdateStepDocumentEnduserByEnduserPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateStepEnduserDocumentByEnduser.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateStepEnduserDocumentByEnduser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<StepDocumentEnduserData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents-link/by-enduser`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
