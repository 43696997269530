import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SessionFilter} from 'luxtrust-cosi-api/model/sessionFilter';
import {SessionData} from "../../../../luxtrust-cosi-api";
import StatusEnum = SessionData.StatusEnum;

@Component({
  selector: 'app-status-counter', templateUrl: './status-counter.component.html', styleUrls: ['./status-counter.component.scss']
})
export class StatusCounterComponent implements OnInit {

  @Input() reset: EventEmitter<undefined>;
  @Output() filter = new EventEmitter<StatusEnum>();
  statusCounter: any;

  sessionStatusEnum = StatusEnum;

  constructor(private sessionService: SessionService) {
    this.fetch();
  }

  ngOnInit() {
    if (this.reset) { this.reset.subscribe(() => this.fetch()); }
  }

  fetch() {
    Promise.all([this.sessionService.getSessionSearchCount({
      status: SessionFilter.StatusEnum.STARTED
    }).toPromise(), this.sessionService.getSessionSearchCount({
      status: SessionFilter.StatusEnum.COMPLETED
    }).toPromise(), this.sessionService.getSessionSearchCount({
      signatureStatus: SessionFilter.SignatureStatusEnum.DECLINED
    }).toPromise(), this.sessionService.getSessionSearchCount({
      expired: true
    }).toPromise()]).then(([started, signed, declined, expired]) => {
      this.statusCounter = {
        created: started.count, completed: signed.count, refused: declined.count, canceled: expired.count
      };
    });
  }

  filterBy(statusEnum: StatusEnum) {
    this.filter.emit(statusEnum);
  }
}
