import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from 'src/app/core/service/translate.service';
import {DocumentData, DocumentService} from 'luxtrust-cosi-api';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {SignatureBookSignerData} from 'luxtrust-cosi-api/model/signatureBookSignerData';
import {SignatureData} from 'luxtrust-cosi-api/model/signatureData';
import {take} from 'rxjs/operators';
import {AppService} from 'src/app/services/services/app.service';
import TypologyEnum = DocumentData.TypologyEnum;
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';

@Component({
  selector: 'document-card', templateUrl: './document-card.component.html', styleUrls: ['./document-card.component.scss']
})
export class DocumentCardComponent implements OnInit {

  @Input() documentName: string;
  @Input() priority: number;
  @Input() numberComment: number;
  @Input() numberAnnotation: number;
  @Input() stepName: string;
  @Input() stepId: number;
  @Input() sessionName: string;
  @Input() sessionId: number;
  @Input() signatures: SignatureBookSignerData[] = [];
  @Input() createdOn: string;
  @Input() isActive: boolean;
  @Input() alternateColor: boolean = false;
  @Input() status: SessionData.StatusEnum | string;
  @Output() moveToCommentSection: EventEmitter<string> = new EventEmitter();
  viewDocuments: DocumentData[];
  usersExpanded = false;
  circleRegexp = /{.*$/;

  SIGNATURE_STATUS = SignatureData.StatusEnum;

  constructor(
    public translateService: TranslateService,
    private documentService: DocumentService,
    private alertService: AlertService,
    private router: Router,
    private appService: AppService
  ) {}

  ngOnInit() {
    if (this.sessionId && this.stepId) {
      this.documentService.getAllDocuments(this.sessionId, this.stepId).pipe(take(1)).subscribe(documents => {
        this.viewDocuments = documents.filter(d => d.typology === TypologyEnum.TOVIEW);
      }, (error: ApiError) => this.alertService.errorApi(error));
    }
    this.changeInFirstPositionCurrentUser();
  }

  changeInFirstPositionCurrentUser() {
    const userIDIndex = this.signatures.findIndex(signature => signature.stepEnduser.enduser.id === this.appService.getUser().id);
    this.signatures.unshift(this.signatures.splice(userIDIndex, 1)[0]);
  }

  get isStepNameDiffSessionName(): boolean {
    if (this.sessionName && this.stepName) {
      return this.stepName !== this.sessionName;
    }
    return true;
  }

  get twoFirstSigner(): SignatureBookSignerData[] {
    return this.signatures.slice(0, 2);
  }

  get offsetTowFirstSigner(): SignatureBookSignerData[] {
    return this.signatures.slice(2, this.signatures.length);
  }

  get getActualDisplayedSignaturesNb(): number {
    return this.usersExpanded ? this.signatures.length : this.signatures.slice(0, 2).length;
  }

  get isUserCountDisplayed(): boolean {
    return this.signatures.length > 2;
  }

  userSignatureStatus(user: SignatureBookSignerData): SignatureData.StatusEnum {
    return user.signatureStatus;
  }
  goToTheCurrentStep() {
    this.router.navigate(['/session', this.sessionId, 'step', this.stepId]);
  }

  goToComment() {
    this.moveToCommentSection.emit();
  }
}
