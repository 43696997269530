export enum wizardStepConfiguratorEnum {
  MANAGER = 'MANAGER',
  SIGNER = 'SIGNER',
  USER = 'USER',
  DOCUMENT = 'DOCUMENT',
  TAG = 'TAG',
  REPLACE = 'REPLACE',
  ADD= 'ADD',
  CLONE = 'CLONE',
  UPDATE = 'UPDATE',
  SPROFILE = 'SPROFILE'
}
