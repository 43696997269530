import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PatternData } from 'luxtrust-cosi-api/model/patternData';
import { PatternFilter } from 'luxtrust-cosi-api/model/patternFilter';
import { StepNotificationRules } from './../step-notification-rules';
import ChannelEnum = PatternFilter.ChannelEnum;

@Component({
  selector: 'app-notification-option-card',
  templateUrl: './notification-option-card.component.html',
  styleUrls: ['./notification-option-card.component.scss']
})
export class NotificationOptionCardComponent {

  public PatternFilterChannelEnum = ChannelEnum;

  @Input() title: string;
  @Input() isIE: boolean;
  @Input() stepNotificationRule: StepNotificationRules;
  @Input() patternToCheck: ChannelEnum;
  @Input() hasPatternsBound: boolean;
  @Input() disabled: boolean;
  @Output() editPatternEvent = new EventEmitter();
  @Output() removePatternEvent = new EventEmitter();
  @Output() createPatternEvent = new EventEmitter();
  @Output() optionUpdateEvent = new EventEmitter<boolean>();

  editPattern() {
    this.editPatternEvent.emit();
  }

  removePattern() {
    this.removePatternEvent.emit();
  }

  createPattern() {
    this.createPatternEvent.emit();
  }

  onOptionUpdate(value: boolean) {
    this.optionUpdateEvent.emit(value);
  }

  idSuffix(): string {
    return `_${this.title}_${this.stepNotificationRule.value}`;
  }

  private getCurrentValue() {
    switch (this.patternToCheck) {
      case this.PatternFilterChannelEnum.EMAIL:
        return this.stepNotificationRule.isMail;

      case this.PatternFilterChannelEnum.SMS:
        return this.stepNotificationRule.isSms;

      case this.PatternFilterChannelEnum.APP:
        return this.stepNotificationRule.isApp;

      default:
        return undefined;
    }
  }

  checkValueIs(value: boolean) {
    return this.getCurrentValue() === value;
  }

  onSwitchClick() {
    if (this.getCurrentValue() === false) {
      this.optionUpdateEvent.emit();
    } else {
      this.optionUpdateEvent.emit(false);
    }
  }
}
