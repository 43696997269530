import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EnduserData} from "luxtrust-cosi-api/model/enduserData";

@Component({
  templateUrl: './modal-tenant-directories.component.html' , styleUrls: ['./modal-tenant-directories.component.scss']
})
export class ModalTenantDirectoriesComponent implements OnInit {

  tenantForm: FormGroup;
  @Input() public tenantDirectories: Array<string>;
  @Input() public activeTenantDirectories: Array<string>;
  @Input() public defaultDirectory: string;
  @Input() disabled: boolean;
  @Output() tenantDirectoryResult: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  @Output() onClose = new EventEmitter();

  activeDirectories = [];

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.tenantForm = this.formBuilder.group({
      tenantDirectory: ['', Validators.required],
    });
    this.addCheckboxes();
    this.getActiveDirectories();
  }

  private addCheckboxes() {
    this.tenantDirectories.forEach((o, i) => {
      const control = new FormControl(i === 0); // if first item set to true, else false
      this.tenantForm.controls.directories ? (this.tenantForm.controls.directories as FormArray).push(control) : null;
    });

  }

  onChange(event, value) {
    if (event) {
      this.activeDirectories.push(value);
    } else if (!event) {
      this.activeDirectories.splice(this.activeDirectories.findIndex(x => x == value), 1);
    }
    this.tenantDirectoryResult.emit(this.activeDirectories);
  }

  getActiveDirectories() {
    this.activeDirectories = this.tenantDirectories.filter(element => this.activeTenantDirectories.includes(element));
  }

  isInActiveArray(value) {
    return this.activeDirectories.includes(value);
  }
}
