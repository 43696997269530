<!-- Users -->
<div class="signers">
  <app-endusers-table
    (addEnduser)="addUsers($event)"
    (outputSearch)="onSearchUsers($event)"
    (outputSort)="orderUsers($event, step.id)"
    (outputUnsort)="unsortUsers($event,step.id)"
    (removeEnduser)="removeUsers($event, step.id)"
    (sortMode)="detectSortMode($event)"
    (replaceUsers)="onReplaceUsers($event.oldId, $event.newUser, selectedUsers)"
    (openModal)="openModal($event.replace, $event.oldEnduserId)"
    (outputUpdateExpected)="onUpdateExpected($event)"
    [borderBottom]="false"
    [disabledSelectCircle]="false"
    [disabled]="replacingUser || addingUSer || removingUser || orderingUser"
    [availableEndusers]="availableUsers"
    [loaderStatus]="loaderStatusUsers"
    [noResult]="'WIZARD.USERS.NO_RESULT' | translate"
    [placeholder]="'WIZARD.USERS.SEARCH' | translate"
    [selectedEndusers]="selectedUsers"
    [singleList]="true"
    [sortable]="true"
    [enableInviteNewSigner]="enableInviteNewSigner"
    [sortedUsers]="sortedUsers"
    [canAdd]="canAdd()"
    [canReplace]="canReplace()"
    [replaceAloneSigner]="replaceAloneSigner"
    [page]="page"
    [sessionId]="sessionId"
    [step]="step"
    [isConfigurator]="true"
    class="fixed-md">
  </app-endusers-table>
</div>
