import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AnnotationData} from 'luxtrust-cosi-api/model/annotationData';
import {ModalAnnotationComponent} from '../modal-annotation/modal-annotation.component';

@Component({
  selector: 'app-annotation-viewer', templateUrl: './annotation-viewer.component.html'
})
export class AnnotationViewerComponent implements OnInit {

  @Input() currentPage: number;
  @Input() annotations: AnnotationData[];
  @Output() loadPage: EventEmitter<number> = new EventEmitter();
  readonly maxAnnotationCharacter = 36;

  constructor(private modal: NgbModal) {
  }

  @HostListener('window:message', ['$event']) annotationEventListener(event: MessageEvent) {
    const data = event.data;
    if (data && !data.status && data.type === 'ANNOTATION_CLICKED') {
      const text = this.annotations.some(annotation => data.annotation.idx === annotation.idx) ? this.annotations.filter(
        annotation => data.annotation.idx === annotation.idx)[0].text : '';
      this.openAnnotationModal(text);
    }
  }

  ngOnInit() {
  }

  openAnnotationFromDropDown(annotation: AnnotationData) {
    if (this.currentPage !== annotation.page) {
      this.loadPage.emit(annotation.page);
    }

    this.openAnnotationModal(annotation.text);
  }

  openAnnotationModal(text: string) {
    const modalRef = this.modal.open(ModalAnnotationComponent, {
      size: 'lg', centered: true, backdrop: false
    });
    modalRef.componentInstance.text = text;
    modalRef.componentInstance.readOnly = true;
    modalRef.componentInstance.showTitle = false;
    modalRef.componentInstance.showDescription = false;
  }
}
