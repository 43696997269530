import {Pipe, PipeTransform} from "@angular/core";
import {SessionData, SessionTagService, TagData} from "../../../../luxtrust-cosi-api";
import {Observable, of, Subject} from "rxjs";


@Pipe({
  name: 'sessionTitle',
  pure: false
})
export class SessionTitlePipe implements PipeTransform  {

  constructor(private sessionTagService: SessionTagService) {
  }

  transform(session: SessionData, ...args: any[]): string {
    if(!session){
      return 'no session'
    }
    if(session.tags){
      return this.makeTitle(session, session.tags);
    }
    else{
      return session.label;

    }
  }

  private makeTitle(sessionData: SessionData, tags: TagData[]): string {
    const confidentialityLevelTags = tags.filter(it => it.alias === 'CONFIDENTIALITY_LEVEL');
    if (confidentialityLevelTags.length) {
      return sessionData.label + " (" + confidentialityLevelTags[0].value + ")";
    } else {
      return sessionData.label;
    }
  }

}
