import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import {StepTagService, TagData, UpdateStepTagPayload} from 'luxtrust-cosi-api';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {ConfigurationData} from 'luxtrust-cosi-api/model/configurationData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {forkJoin, of, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {CONFIG} from 'src/app/app.constant';
import {ConfigurationService} from 'src/app/services/services/configuration.service';
import {ApiError} from '../../../../../error/api-error.model';
import {AlertService} from '../../../../../services/services/alert-service';
import {AppService} from '../../../../../services/services/app.service';
import {NavigationService} from '../../../../../services/services/navigation.service';
import {ModalYesNoComponent} from '../../../../../shared/components/modal-yes-no/modal-yes-no.component';
import {wizardStepConfiguratorEnum} from '../wizardStepConfigurator.enum';
import {ConfigByStep} from './configByStep';
import StatusEnum = StepData.StatusEnum;

@Component({
  selector: 'app-modal-configurator',
  templateUrl: './modal-configurator.component.html',
  styleUrls: ['./modal-configurator.component.scss']
})
export class ModalConfiguratorComponent implements OnInit {

  page;
  step: StepData;
  session: SessionData;
  // in case of delegation, because it's handled by a circle
  currentUserId: string;
  currentStepEndusers: StepEnduserData[];
  currentUser: StepEnduserData;
  redirectLocation: string;
  comingFromSession: boolean ;
  comingFromStep: boolean;
  public disabledSaveAndCreate = false;
  configByStep: ConfigByStep[] = [];
  currentConfigStep: ConfigurationData;
  public listStepWithAccessMeta = [];
  public listStepWithoutError = [];
  public listStepWithError = [];
  public listNameStepWithError = [];
  public tagMandatoryHasNoValue = false;
  public hasErrorWithLinkTag = false;
  sortMode = false;
  mustSaveTags = false;
  configuratorCreationMode = true;

  public hasVisitedAllStepWithAccess = true;
  public listStepWithAccessNovisited = [];
  public oneStepConfigurator = false;
  public stepTagList: TagData[];
  deletingSession = false;

  constructor(
    public activeModal: NgbActiveModal,
    private stepService: StepService,
    private sessionService: SessionService,
    private appService: AppService,
    private router: Router,
    private alertService: AlertService,
    private configurationService: ConfigurationService,
    private navigationService: NavigationService,
    private modal: NgbModal,
    private translate: TranslateService,
    private stepEnduserService: StepEnduserService,
    private stepTagService: StepTagService) {
  }

  ngOnInit() {
    if (!this.page) {
      this.page = 0;
    }
    if (this.step) {
      this.getStepTagList();
    }
    this.getAllConfigAllSteps(true);
    this.configuratorCreationMode = !(this.comingFromSession || this.comingFromStep);
  }

  getStepTagList() {
    this.stepTagService.getStepTagList(this.session.id, this.step.id).subscribe((tagList) => {
      this.stepTagList = tagList;
    });
  }

  getAllStepEndusers(stepId): Observable<StepEnduserData[]> {
    return this.stepEnduserService.stepEnduserList(this.session.id, stepId).pipe(map((stepEndusers: StepEnduserData[]) => {
      return stepEndusers;
    }));
  }

  getAllSteps(): Observable<StepData[]> {
    return this.stepService.getStepList(this.session.id).pipe(map((steps: StepData[]) => {
      return steps.filter(step => step.category === 'OPERATION');
    }));
  }

  // TODO Simplify all of this
  getAllConfigAllSteps(init: boolean) {
    const requests: Observable<any>[] = [];
    this.listStepWithAccessMeta = [];
    const endusersByStep: {stepId: number, endusers: StepEnduserData[]}[] = [];
    let configStepsMap: Map<number, ConfigByStep>; // Used to preserve backend order

    let stepsObservable: Observable<StepData[]>;
    if (this.comingFromSession || this.comingFromStep) {
      stepsObservable = this.stepService.getStep(this.session.id, this.step.id).pipe(map(stepData => [stepData]));
    } else {
      stepsObservable = this.getAllSteps();
    }

    stepsObservable.subscribe((steps) => {
        configStepsMap = new Map(steps.map(step => [step.id, undefined]));
        steps.forEach((step) => {
          if (init) {
            requests.push(
              this.stepService.getStepConfiguration(step.sessionId, step.id).pipe(map(config => {
                if (config.managerConfig) {
                  configStepsMap.set(step.id, {config: config, stepId: step.id, stepName: step.label, stepEndusers: undefined});
                }
              }))
            );
          }
          requests.push(
            this.getAllStepEndusers(step.id).pipe(map(stepEndusers => {
                endusersByStep.push({stepId: step.id, endusers: stepEndusers});
              })
            )
          );
        });
        forkJoin(requests).subscribe(() => {
          configStepsMap.forEach(configStep => {
            if (configStep != undefined) {
              this.configByStep.push(configStep);
            }
          });
          if (!this.step) {
            this.stepService.getStep(this.session.id, this.configByStep[this.page].stepId).subscribe((step) => {
              this.step = step;
              this.getStepTagList();
              this.nextGetAllConfigsAllSteps(endusersByStep);
            });
          } else {
            this.nextGetAllConfigsAllSteps(endusersByStep);
          }
        }, (error: ApiError) => this.alertService.errorApi(error));
      }, (error: ApiError) => this.alertService.errorApi(error));
  }

  private nextGetAllConfigsAllSteps(endusersByStep: {stepId: number, endusers: StepEnduserData[]}[]) {
    const currentUserId = this.currentUserId ? this.currentUserId : this.appService.getCurrentUserId();
    this.stepEnduserService.getStepEndUser(currentUserId, this.session.id, this.step.id)
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
        this.currentConfigStep = this.getConfig();
        this.configByStep.forEach(configByStep => {
          if (this.canSeeMetadatas(configByStep.stepId)) {
            this.listStepWithAccessMeta.push(configByStep.stepId);
          }
        });
        this.oneStepConfigurator = this.configByStep.length === 1
          || this.configByStep
            .filter(currentConfig => this.canSee(currentConfig.stepId, wizardStepConfiguratorEnum.DOCUMENT))
            .length === 1;
        this.stockEndusersIntoConfigByStep(endusersByStep);
        this.getStepEndusers();
      }, (error: ApiError) => this.alertService.errorApi(error));
  }

  private stockEndusersIntoConfigByStep(endusersByStep: {stepId: number, endusers: StepEnduserData[]}[]) {
    this.configByStep.forEach( (confByStep) => {
      confByStep.stepEndusers = find(endusersByStep, endByStep => confByStep.stepId === endByStep.stepId).endusers;
    });
  }

  private reloadData() {
    this.saveTags().subscribe(() => {
      this.stepService.getStep(this.session.id, this.configByStep[this.page].stepId).subscribe((step) => {
        this.step = step;
        this.getStepEndusers();
        this.getStepTagList();
      });
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  changePage(event) {
    this.page = findIndex(this.configByStep, {stepId: event});
    this.reloadData();
  }

  previousStep() {
    this.page--;
    this.reloadData();
  }

  nextStep() {
    this.page++;
    this.reloadData();
  }

  // EventEmitter from component modal-configurator-signers
  addUser(stepEnduser: StepEnduserData) {
    const changeStepUser = find(this.currentStepEndusers, (stepUser => stepUser.enduser.id === stepEnduser.enduser.id));
    if (changeStepUser) {
      changeStepUser.signer = true;
      if (this.sortMode) {
        changeStepUser.orderIndex = this.currentStepEndusers.length - 1 ;
      }
      remove(this.currentStepEndusers, (stepUser => stepUser.enduser.id === changeStepUser.enduser.id));
      this.currentStepEndusers = [...this.currentStepEndusers, changeStepUser];
    } else {
      if (this.sortMode) {
        stepEnduser.orderIndex = this.currentStepEndusers.length ;
      }
      this.currentStepEndusers = [...this.currentStepEndusers, stepEnduser];
    }
    this.configByStep[this.page].stepEndusers = this.currentStepEndusers;
  }

  saveTags(): Observable<TagData[]> {
    const requests = [];
    if (this.mustSaveTags) {
    this.step.tags.forEach(tag => {
      const body: UpdateStepTagPayload = {
        tagId: tag.id,
        value: tag.value,
        encrypt: tag.encrypted,
        orderIndex: tag.orderIndex,
        configurator: tag.configurator
      } as UpdateStepTagPayload;
      requests.push(this.stepTagService.updateStepTag(this.session.id, this.step.id, body));
    });
    this.mustSaveTags = false;
    return forkJoin(requests);
    }
    return of(requests);
  }

  updateTags() {
    this.mustSaveTags = true;
  }

  orderSigner() {
    // TODO (SSC) : Voir pour récuperer la liste des stepEndusers avec orderIndex avec output de modal-configurator-signer et sans call http ( si possible)
    this.getAllStepEndusers(this.step.id).subscribe(stepEndusers => {
      this.currentStepEndusers = stepEndusers;
      this.configByStep[this.page].stepEndusers = this.currentStepEndusers;
    });

  }

  updateCircleSigner(circle: {expected: number, idCircle: number}) {
    const circle_ = this.currentStepEndusers.find(stepEnduser => stepEnduser.enduser.id === circle.idCircle);
    circle_.expected = circle.expected;
    this.currentStepEndusers = [...this.currentStepEndusers];
}

  // EventEmitter from component modal-configurator-signers
  deleteUser(stepEnduserId: number) {
    remove(this.currentStepEndusers, (user) => user.enduser.id === stepEnduserId);
    this.currentStepEndusers = [...this.currentStepEndusers];
    this.configByStep[this.page].stepEndusers = this.currentStepEndusers;
  }

  private canSee(stepId: number, rule: wizardStepConfiguratorEnum) {
    const findC = find(this.configByStep, {stepId: stepId});
    if (findC) {
      const config = findC.config;
      return !!((
          (this.currentUser.manager &&
            config &&
            config.configurationMap &&
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER] &&
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap &&
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule] &&
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap
          ) &&
          (
            // Update for TAG
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.UPDATE] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.ADD] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.REPLACE] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.CLONE] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.SPROFILE]
          )
        ) ||
        (
          (this.currentUser.signer &&
            config &&
            config.configurationMap &&
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER] &&
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap &&
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule] &&
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap
          ) &&
          (
            // Update for TAG
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.ADD] ||
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.REPLACE] ||
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.CLONE] ||
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.SPROFILE]
          )
        ));
    }
    return false;
  }

  canSeeDocuments(): boolean {
    return this.canSee(this.step.id, wizardStepConfiguratorEnum.DOCUMENT);
  }

  canSeeSigners(): boolean {
    return this.canSee(this.step.id, wizardStepConfiguratorEnum.USER);
  }

  canSeeMetadatas(stepId: number): boolean {
    return  this.canSee(stepId, wizardStepConfiguratorEnum.TAG);
  }

  closeModal(start: boolean) {
    this.disabledSaveAndCreate = true;
    if (start) {
      this.saveTags().subscribe(() => {
      this.sessionService.startSession(this.session.id)
        .pipe(switchMap(() => this.sessionService.getSession(this.session.id)), tap((_session: SessionData) => this.session = _session))
        .subscribe(() => {
          if (this.session.status !== StatusEnum.STARTED && this.session.status !== StatusEnum.COMPLETED) {
            this.alertService.error('SESSION.CANNOT_START');
            this.disabledSaveAndCreate = false;
          } else {
            this.activeModal.close();
            this.redirectAfterClose(true);
          }
        }, (error: ApiError) => {
          this.alertService.errorApiWithCustomMessage('SESSION.CANNOT_START', error);
          this.disabledSaveAndCreate = false;
        });
    }, (error) => {
        this.alertService.errorApi(error);
        this.disabledSaveAndCreate = false;
      });
    } else {
      this.saveTags().subscribe(() => {
      this.activeModal.close();
      this.redirectAfterClose(false);
      });
    }
  }

  openModalDeleteSession() {
    const modalDelete = this.modal.open(ModalYesNoComponent, {
      backdrop: false, centered: true
    });
    modalDelete.componentInstance.message = 'MODAL_TEMPLATE_SESSION.CONFIGURATOR.DELETE_SESSION_CONFIRMATION';
    modalDelete.componentInstance.noIsDanger = true;
    modalDelete.result.then(value => {
      if (value === 'yes') {
        this.deleteSessionBeforeCloseModal();
        modalDelete.close();
      } else {
        this.saveTags().subscribe(() => {
          modalDelete.close();
          this.activeModal.close();
        });
      }
    });
  }

  redirectAfterClose(start: boolean) {
    if (!this.comingFromSession) {
      this.configurationService.tenantConfig
        .then(tenantConfig => {
          const redirectConfiguration = tenantConfig[CONFIG.TENANT.CONFIGURATOR_REDIRECT_LOCATION_AFTER_CREATION.KEY];
          this.redirectLocation = (typeof redirectConfiguration === 'string' || redirectConfiguration instanceof String) ? redirectConfiguration.toLowerCase() : undefined;
          if (this.redirectLocation && this.redirectLocation.includes(CONFIG.TENANT.CONFIGURATOR_REDIRECT_LOCATION_AFTER_CREATION.VALUES.DASHBOARD)) {
            this.router.navigate(['/dashboard']);
          } else if (this.redirectLocation && this.redirectLocation.includes(CONFIG.TENANT.CONFIGURATOR_REDIRECT_LOCATION_AFTER_CREATION.VALUES.STEP)) {
            this.navigationService.goToFirstStartedStep(this.session.id);
          } else if (this.redirectLocation && this.redirectLocation.includes(CONFIG.TENANT.CONFIGURATOR_REDIRECT_LOCATION_AFTER_CREATION.VALUES.DOCUMENT)) {
            this.navigationService.goToFirstDocument(this.session.id);
          } else {
            this.router.navigate(['/session', this.session.id]);
          }
        });
    } else {
      if (start) {
        // FIXME : Reload data in case to reload page
        window.location.reload();
      }
    }
  }

  getConfig(): ConfigurationData {
    return find(this.configByStep, {stepId: this.step.id}).config;
  }

  getStepEndusers() {
    this.currentStepEndusers = find(this.configByStep, { stepId: this.step.id }).stepEndusers;
  }

  deleteSessionBeforeCloseModal() {
    this.deletingSession = true;
    this.sessionService.deleteSession(this.session.id).toPromise()
      .then(() => {
        this.alertService.success('ALERT.SESSION_DELETED', { sessionName: this.session.label });
        this.activeModal.close();
      }, (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.deletingSession = false);
  }

  controlValueTagMandatory(mandatoryTagWithoutValue: boolean) {
    if (mandatoryTagWithoutValue) {
      if (!this.listStepWithError.find(stepId => stepId === this.step.id)) {
        this.listStepWithError.push(this.step.id);
      }
      this.spliceListAccordingToTagError(this.listStepWithoutError, this.step.id);
    } else if (!this.listStepWithoutError.find(stepId => stepId === this.step.id)) {
      this.listStepWithoutError.push(this.step.id);
      this.spliceListAccordingToTagError(this.listStepWithError, this.step.id);
    }
    this.listStepWithErrorMetadata();
    this.hasVisitedAllStepWithAccess = (this.listNameStepWithError.length + this.listStepWithoutError.length) === this.listStepWithAccessMeta.length;
    this.tagMandatoryHasNoValue = mandatoryTagWithoutValue;
    this.checkVisitAllPage();
  }

  canStartSession(): boolean {
    return this.listStepWithAccessMeta.length === this.listStepWithoutError.length;
  }

  listStepWithErrorMetadata() {
    this.configByStep.forEach(configStep => {
      if (this.listStepWithError.find(stepId => stepId === configStep.stepId) && !this.listNameStepWithError.find(stepName => stepName === configStep.stepName)) {
        this.listNameStepWithError.push(configStep.stepName);
      }
      if (this.listStepWithoutError.find(stepId => stepId === configStep.stepId)) {
        this.spliceListAccordingToTagError(this.listNameStepWithError, configStep.stepName);
      }
    });
  }

  spliceListAccordingToTagError(listTag = [], elementSearch) {
    const index: number = listTag.indexOf(elementSearch);
    if (index !== -1) {listTag.splice(index, 1); }
  }

  checkVisitAllPage() {
    this.configByStep.forEach(config => {
      if (!this.listStepWithError.some(step => step === config.stepId) &&
        !this.listStepWithoutError.some(step => step === config.stepId)) {
        if (!this.listStepWithAccessNovisited.some(stepVisited => stepVisited === config.stepName)
          && config.stepId !== this.configByStep[this.configByStep.length - 1].stepId) {
          this.listStepWithAccessNovisited.push(config.stepName);
        }
      } else {
        this.spliceListAccordingToTagError(this.listStepWithAccessNovisited, config.stepName);
      }
    });
  }

  orderAfterReplace(users) {
 /*   if (this.sortMode) {
      this.stepRuleOrderService.updateStepEnduserOrder(this.sessionId, this.currentStepId, {
        orders: users.map((u, i) => ({
          enduserId: u.id, orderIndex: i
        }))
      }).subscribe(() => {
        this.getAllConfigAllSteps(false);
      });
    } else { */
      this.getAllConfigAllSteps(false);
 //   }
  }

  detectSortMode(sort: boolean) {
    this.sortMode = sort;
  }

  closeConfigurator() {
    this.saveTags().toPromise()
      .then(() => this.activeModal.close())
      .catch((error: ApiError) => this.alertService.errorApi(error));
  }

  checkErrorLinkTag(error: boolean) {
    this.hasErrorWithLinkTag = error;
  }
}
