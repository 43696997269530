import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../services/guard/auth.guard';
import {SessionResolver} from '../session-resolver.service';
import {WizardStepCommentsComponent} from './components/wizard-step-comments/wizard-step-comments.component';
import {WizardStepConfiguratorComponent} from './components/wizard-step-configurator/wizard-step-configurator.component';
import {WizardStepDocumentsComponent} from './components/wizard-step-documents/wizard-step-documents.component';
import {WizardStepGeneralComponent} from './components/wizard-step-general/wizard-step-general.component';
import {WizardStepLegal} from './components/wizard-step-legal/wizard-step-legal';
import {WizardStepMetadataComponent} from './components/wizard-step-metadata/wizard-step-metadata.component';
import {WizardStepNotificationsComponent} from './components/wizard-step-notifications/wizard-step-notifications.component';
import {WizardStepRemindersComponent} from './components/wizard-step-reminders/wizard-step-reminders.component';
import {WizardStepSignatureComponent} from './components/wizard-step-signature/wizard-step-signature.component';
import {WizardStepUsersComponent} from './components/wizard-step-users/wizard-step-users.component';
import {StepComponent} from './container/step.component';
import {SprofileResolver} from './services/sprofile-resolver.service';
import {StepPropertiesResolver} from './services/step-property-resolver-service';
import {StepResolver} from './services/step-resolver.service';
import {WizardComponent} from './wizard/container/wizard/wizard.component';
import {StepsName} from './wizard/models/wizard.config';

const routes: Routes = [{
  path: '', resolve: {
    session: SessionResolver, step: StepResolver
  }, component: StepComponent, canActivate: [AuthGuard]
}, {
  path: 'workflow/:workflowId', loadChildren: './workflow/workflow.module#WorkflowModule'
}, {
  path: 'workflow/document/view', loadChildren: './workflow/workflow.module#WorkflowModule'
}, {
  path: 'workflow/:workflowId/as/:enduserId', loadChildren: './workflow/workflow.module#WorkflowModule'
}, {
  path: 'wizard', component: WizardComponent, resolve: {
    session: SessionResolver, step: StepResolver, stepProperties: StepPropertiesResolver, sprofiles: SprofileResolver
  }, runGuardsAndResolvers: 'paramsChange', data: {
    steps: [{
      path: StepsName.general, label: 'WIZARD.GENERAL.TITLE', index: 0
    }, {
      path: StepsName.users, label: 'WIZARD.USERS.TITLE', index: 1
    }, {
      path: StepsName.documents, label: 'WIZARD.DOCUMENTS.TITLE', index: 2
    }, {
      path: StepsName.signature, label: 'WIZARD.SIGNATURE', index: 3
    }, {
      path: StepsName.legal, label: 'WIZARD.LEGAL.TITLE', index: 4
    }, {
      path: StepsName.metadata, label: 'WIZARD.TAGS.TITLE', index: 5
    }, {
      path: StepsName.reminders, label: 'WIZARD.REMINDERS.TITLE', index: 6
    }, {
      path: StepsName.notifications, label: 'WIZARD.NOTIFICATIONS.TITLE', index: 7
    }, {
      path: StepsName.comments, label: 'WIZARD.COMMENTS.TITLE', index: 8
    },{
      path: StepsName.config, label: 'WIZARD.CONFIG.TITLE', index: 9
    }]
  }, children: [{
    path: '', redirectTo: 'general', pathMatch: 'full'
  }, {
    path: StepsName.general, component: WizardStepGeneralComponent
  }, {
    path: StepsName.documents, component: WizardStepDocumentsComponent
  }, {
    path: StepsName.users, component: WizardStepUsersComponent
  }, {
    path: StepsName.signature, component: WizardStepSignatureComponent
  }, {
    path: StepsName.legal, component: WizardStepLegal
  }, {
    path: StepsName.metadata, component: WizardStepMetadataComponent
  }, {
    path: StepsName.reminders, component: WizardStepRemindersComponent
  }, {
    path: StepsName.notifications, component: WizardStepNotificationsComponent
  }, {
    path: StepsName.comments, component: WizardStepCommentsComponent
  },{
    path: StepsName.config, component: WizardStepConfiguratorComponent
  }
    // {path: 'summary',  component: WizardStepSummaryComponent}
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)], exports: [RouterModule]
})
export class StepRoutingModule {
}
