/**
 * Utils class handling JSON value extraction.
 * Fluent.
 */
export class Objects {

  private readonly object: any;

  constructor(object: any) {
    this.object = object;
  }

  valueForKey(key: string) {
    if (!this.object) {
      return undefined;
    }
    const results = Object.getOwnPropertyNames(this.object)
      .filter(currentKey => currentKey === key)
      .map(currentKey => this.object[currentKey]);
    if (results.length > 1) {
      throw new Error(`Cannot have multiple same keys: ${key}`);
    }
    if (results.length === 0) {
      return undefined;
    }
    return results[0];
  }
}
